import {debounce} from 'lodash';
import React, {useMemo} from 'react';

import {
  SettingLevel,
  SettingType,
  trackSetting,
} from '../../../../services/analytics/workspaceSettingsEvents';
import {maybePluralize} from '../../../../util/uihelpers';

type ClearSettingsRowProps = {
  count?: number;
  setting: SettingType;
  type: string;
  clearSetting: () => void;
};

export const ClearSettingsRow = ({
  count,
  type,
  setting,
  clearSetting,
}: ClearSettingsRowProps) => {
  const debouncedClearSetting = useMemo(
    () => debounce(clearSetting, 500),
    [clearSetting]
  );

  return (
    <div>
      {count != null && count > 0 && (
        <span>
          {maybePluralize(count, type)}
          {count === 1 ? ' has ' : ' have '}
          overrides.{' '}
        </span>
      )}
      <span
        className="font-semibold text-teal-600 hover:text-teal-550"
        role="button"
        onClick={() => {
          debouncedClearSetting();
          trackSetting({
            action: 'clear',
            page: type === 'report' ? 'report' : 'workspace',
            settingLevel: type as SettingLevel,
            setting,
          });
        }}>
        Clear
        {count != null && count > 1 && ' all'}
      </span>
    </div>
  );
};
