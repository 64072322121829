import {IconRetry} from '@wandb/weave/components/Icon';
import React from 'react';

import {AccountIconLocation} from '../../../pages/Billing/AccountSettings/AccountIcon';
import {AccountSelectorAccountInfo} from './AccountSelectorAccountInfo';
import * as S from './ProfileMenu.styles';
import {Account} from './types';

type AccountSelectorMenuItemProps = {
  account: Account | null;
  isSelectedAccount: boolean;
};

export function AccountSelectorMenuItem({
  account,
  isSelectedAccount,
}: AccountSelectorMenuItemProps): JSX.Element {
  if (account == null) {
    return <></>;
  }
  return (
    <>
      <S.ProfileMenuAccountContainer data-test={account?.name}>
        <AccountSelectorAccountInfo
          account={account}
          isSelectedAccount={isSelectedAccount}
          location={AccountIconLocation.ProfileMenu}
        />
      </S.ProfileMenuAccountContainer>
      {!isSelectedAccount && (
        <S.ProfileMenuRetryIcon>
          <IconRetry />
        </S.ProfileMenuRetryIcon>
      )}
    </>
  );
}
