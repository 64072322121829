import {
  getCookie,
  setCookie,
  unsetCookie,
} from '@wandb/weave/common/util/cookie';

import {OrganizationSubscriptionStatus} from '../generated/graphql';

const USE_STORAGE_GB_LIMIT_COOKIE_KEY = 'storage_gb_limit';
const USE_TRACKED_GB_LIMIT_COOKIE_KEY = 'tracked_gb_limit';
const USE_COMPUTE_HOUR_LIMIT_COOKIE_KEY = 'compute_hour_limit';
const USE_DAYS_UNTIL_EXPIRE_COOKIE_KEY = 'days_until_expire';
const USE_AGGRESSIVE_COOKIE_KEY = 'use_aggressive';
const SUBSCRIPTION_TYPE_COOKIE_KEY = 'subscription_type';
const SUBSCRIPTION_STATUS_COOKIE_KEY = 'subscription_status';
const ORGANIZATION_ID_COOKIE_KEY = 'organization_id';
const USAGE_LIMIT_COOKIE_KEYS = [
  USE_STORAGE_GB_LIMIT_COOKIE_KEY,
  USE_TRACKED_GB_LIMIT_COOKIE_KEY,
  USE_COMPUTE_HOUR_LIMIT_COOKIE_KEY,
  USE_DAYS_UNTIL_EXPIRE_COOKIE_KEY,
  USE_AGGRESSIVE_COOKIE_KEY,
  SUBSCRIPTION_TYPE_COOKIE_KEY,
  ORGANIZATION_ID_COOKIE_KEY,
];

export const FREE_PERSONAL_ACCOUNT = 'free_personal';
export const FREE_ACADEMIC_TRIAL = 'free_academic_trial';
export const FREE_STARTER_PLAN_TRIAL = 'free_starter_plan_trial';
export const PAID_STARTER_TIER_1_PLAN = 'starter_tier_1_plan';
export const PAID_STARTER_TIER_2_PLAN = 'starter_tier_2_plan';
export const PAID_STARTER_TIER_3_PLAN = 'starter_tier_3_plan';
export const PAID_MONTHLY_TEAMS_PLAN = 'monthly_teams_2023_plan';
export const PAID_YEARLY_TEAMS_PLAN = 'yearly_teams_2023_plan';
export const FREE_ENTERPRISE_PLAN_TRIAL = 'free_enterprise_plan_trial';
export const PAID_ENTERPRISE_PLAN = 'enterprise_plan';
export type SubscriptionType =
  | 'free_personal'
  | 'free_academic_trial'
  | 'free_starter_plan_trial'
  | 'starter_tier_1_plan'
  | 'starter_tier_2_plan'
  | 'starter_tier_3_plan'
  | 'free_enterprise_plan_trial'
  | 'enterprise_plan';

export const COMPUTE_HOUR_AGGRESSIVE_DAYS_THRESHOLD = 14;

export function getOrganizationIDCookie(): string {
  return getCookie(ORGANIZATION_ID_COOKIE_KEY);
}

export function setOrganizationIDCookie(id: string): void {
  setCookie(ORGANIZATION_ID_COOKIE_KEY, id);
}

export function getSubscriptionTypeCookie(): SubscriptionType | '' {
  return getCookie(SUBSCRIPTION_TYPE_COOKIE_KEY) as SubscriptionType | '';
}

export function setSubscriptionTypeCookie(type: SubscriptionType) {
  setCookie(SUBSCRIPTION_TYPE_COOKIE_KEY, type);
}

export function getSubscriptionStatusCookie(): OrganizationSubscriptionStatus {
  return getCookie(SUBSCRIPTION_STATUS_COOKIE_KEY) === 'DISABLED'
    ? OrganizationSubscriptionStatus.Disabled
    : OrganizationSubscriptionStatus.Enabled; // value is 'ENABLED' or empty string
}

export function setSubscriptionStatusCookie(
  status: OrganizationSubscriptionStatus
): void {
  const value =
    status === OrganizationSubscriptionStatus.Disabled ? 'DISABLED' : 'ENABLED';
  setCookie(SUBSCRIPTION_STATUS_COOKIE_KEY, value);
}

function getLimitInNumber(limitKey: string): number | null {
  if (limitKey === '') {
    return 0;
  }

  const limit = getCookie(limitKey);
  if (limit === '') {
    return null;
  }

  return Number(limit);
}

export function getStorageGBLimitCookie(): number | null {
  return getLimitInNumber(USE_STORAGE_GB_LIMIT_COOKIE_KEY);
}

export function getTrackedGBLimitCookie(): number | null {
  return getLimitInNumber(USE_TRACKED_GB_LIMIT_COOKIE_KEY);
}

export function getComputeHourLimitCookie(): number | null {
  return getLimitInNumber(USE_COMPUTE_HOUR_LIMIT_COOKIE_KEY);
}

export function getDaysUntilExpireCookie(): number | null {
  return getLimitInNumber(USE_DAYS_UNTIL_EXPIRE_COOKIE_KEY);
}

function getLimitCookie(limitKey: string): boolean {
  return getCookie(limitKey) === 'true';
}

export function getAggressiveCookie(): boolean {
  return getLimitCookie(USE_AGGRESSIVE_COOKIE_KEY);
}

function setLimitInNumber(value: number | null, limitKey: string): void {
  if (value == null) {
    unsetCookie(limitKey);
    return;
  }
  setCookie(limitKey, value.toString());
}

export function setStorageGBLimitCookie(storageGB: number | null): void {
  return setLimitInNumber(storageGB, USE_STORAGE_GB_LIMIT_COOKIE_KEY);
}

export function setTrackedGBLimitCookie(trackedGB: number | null): void {
  return setLimitInNumber(trackedGB, USE_TRACKED_GB_LIMIT_COOKIE_KEY);
}

export function setComputeHourLimitCookie(computeHour: number | null): void {
  return setLimitInNumber(computeHour, USE_COMPUTE_HOUR_LIMIT_COOKIE_KEY);
}

export function setDaysUntilExpireCookie(days: number | null): void {
  return setLimitInNumber(days, USE_DAYS_UNTIL_EXPIRE_COOKIE_KEY);
}

function setLimitCookie(enable: boolean, limitKey: string): void {
  if (enable) {
    setCookie(limitKey, 'true');
  } else {
    unsetCookie(limitKey);
  }
}

export function setAggressiveCookie(enable: boolean): void {
  return setLimitCookie(enable, USE_AGGRESSIVE_COOKIE_KEY);
}

export function unsetAllUsageLimitCookies(): void {
  for (const key of USAGE_LIMIT_COOKIE_KEYS) {
    unsetCookie(key);
  }
}

export function getLimitString(
  limit: number | undefined | null,
  unit: string
): string {
  if (limit == null) {
    return 'unlimited';
  }
  return `${limit} ${unit}`;
}
