// A user can pass in any combination of x/y min/max values through the panel configuration

import {DomainMaybe} from '../../vis/types';

// Domains of [200, null] or [null, 400] etc... and set upper or lower bounds for that axis
export const getDomain = (
  axisMin: number | undefined,
  axisMax: number | undefined
): DomainMaybe | undefined => {
  if (Number.isFinite(axisMin) || Number.isFinite(axisMax)) {
    return [axisMin ?? null, axisMax ?? null];
  }

  return undefined;
};
