import {useMemo} from 'react';

import {useProjectDetailsQuery} from '../../generated/graphql';

export function useProjectIsV2({
  entityName,
  projectName,
}: {
  entityName: string;
  projectName: string;
}): {
  fromV2: Boolean | undefined;
} {
  const {data} = useProjectDetailsQuery({
    variables: {
      entityName,
      projectName,
    },
  });

  return useMemo(
    () => ({
      fromV2: data?.project?.fromV2 ?? undefined,
    }),
    [data]
  );
}
