import classNames from 'classnames';
import React from 'react';

import {usePanelSearchDebouncedContext} from '../../state/panelbank/PanelSearchContextProvider';
import {PlotFontSize} from '../../util/plotHelpers/plotFontSize';
import {TruncateTextTooltip} from '../common/TruncateText/TruncateTextTooltip';
import {TruncationType} from '../common/TruncateText/TruncateTextTypes';
import {usePanelContext} from '../Panel/PanelContextProvider';
import {PanelPopupMode} from '../Panel/types';

interface PanelTitleProps {
  title: string;
  fontSize?: PlotFontSize | 'auto';
  disallowSelect?: boolean;
}

export const PanelTitle: React.FC<PanelTitleProps> = ({
  title,
  fontSize,
  disallowSelect,
}) => {
  const {makeHighlightSearchTitle} = usePanelSearchDebouncedContext();
  const {setPanelPopupMode} = usePanelContext();

  return (
    <TruncateTextTooltip
      className={classNames('PanelTitle', fontSize, {
        'select-none': disallowSelect,
      })}
      truncationType={TruncationType.Middle}
      fullText={title}
      highlight={makeHighlightSearchTitle}
      onClick={() => setPanelPopupMode(PanelPopupMode.EDIT)}
      noLink>
      title
    </TruncateTextTooltip>
  );
};
