import React, {createContext, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {ReportViewRef} from '../../state/reports/types';
import * as ViewActions from '../../state/views/actions';
import {LoadableView} from '../../state/views/types';
import {ReportMode} from './types';

export type LoadedReportContextType = {
  reportViewRef: ReportViewRef;
  view: LoadableView;
};

export const LoadedReportContext = createContext<
  LoadedReportContextType | undefined
>(undefined);
LoadedReportContext.displayName = 'LoadedReportContext';

type LoadedReportContextProviderProps = {
  reportViewRef: ReportViewRef;
  view: LoadableView;
  reportMode: ReportMode;
  children: React.ReactNode;
};

export const LoadedReportContextProvider = ({
  reportViewRef,
  view,
  reportMode,
  children,
}: LoadedReportContextProviderProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (reportMode === ReportMode.IS_EDITING) {
      // Always enable autosave in edit mode.
      // If this is not our own report, we'll redirect to view mode below.
      dispatch(ViewActions.setAutosave(reportViewRef, true));
    }
  }, [dispatch, reportViewRef, reportMode]);

  return (
    <LoadedReportContext.Provider
      value={{
        reportViewRef,
        view,
      }}>
      {children}
    </LoadedReportContext.Provider>
  );
};

export const useLoadedReportContext = (): LoadedReportContextType => {
  const value = useContext(LoadedReportContext);

  if (value == null) {
    throw new Error(
      'useLoadedReportContext must be used within a LoadedReportContextProvider'
    );
  }

  return value;
};

export const useIsInLoadedReportContext = (): boolean => {
  return useContext(LoadedReportContext) !== undefined;
};
