import {RemovableTag, RemoveAction, Tag} from '@wandb/weave/components/Tag';
import classNames from 'classnames';
import * as React from 'react';

import {ProjectTags2Document, RunTag} from './../../generated/graphql';
import {makeInfiniteDropdown} from './InfiniteDropdown';

const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
  }
>(({children}, ref) => {
  return (
    <div ref={ref} className="relative h-full">
      {children}
    </div>
  );
});

const Display = ({
  isOpen,
  selectedItems,
  removeFilter,
  toggleMenu,
  ...props
}: {
  isOpen: boolean;
  selectedItems: string[];
  removeFilter: (item: string) => void;
  toggleMenu: () => void;
}) => {
  const classes = classNames(
    'bg-[rgba(46,120,199,0.08)]',
    'h-full w-full py-[8px] px-[13px] sticky flex flex-wrap items-center gap-4 justify-items-start'
  );

  return (
    <div className={classes} onClick={toggleMenu} {...props}>
      {selectedItems.length > 0 ? (
        selectedItems.map(selItem => {
          return (
            <RemovableTag
              color="blue"
              key={`selected-item-${selItem}`}
              label={selItem ?? ''}
              removeAction={
                <RemoveAction
                  onClick={(e: any) => {
                    e.stopPropagation();
                    removeFilter(selItem);
                  }}
                />
              }
              Wrapper={null}></RemovableTag>
          );
        })
      ) : (
        <div className="self-center">Select a tag...</div>
      )}
    </div>
  );
};
const ListWrapper = React.forwardRef<
  HTMLUListElement,
  {
    children: React.ReactNode;
    isOpen: boolean;
    toggleOpen: (isOpen?: boolean) => void;
  }
>((props, ref) => {
  const {children, isOpen, toggleOpen, ...otherProps} = props;
  const classes = classNames(
    'absolute mt-8 w-full list-none overflow-auto rounder p-0 max-h-[300] z-[10]',
    'border-[1px] border-solid border-[rgba(34,36,38,.15)]',
    'bg-[#fff]',
    'shadow-[0_2px_4px_0-rgba(34,36,38,.12),0_2px_10px_0-rgba(34,36,38,.15)]'
  );

  if (!isOpen) {
    return null;
  }

  return (
    <ul className={classes} ref={ref} {...otherProps}>
      {props.children}
    </ul>
  );
});

const ListItem = React.forwardRef<
  HTMLLIElement,
  {
    isHighlighted: boolean;
    isSelected: boolean;
    value: string;
  }
>((props, ref) => {
  const {isHighlighted, isSelected, value, ...otherProps} = props;
  const classes = classNames(
    'hover:bg-[rgba(0,0,0,.05)] hover:text-[rgba(0,0,0,.95)]',
    'truncate leading-18 m-0 px-12 py-[13px] cursor-pointer',
    {'bg-[rgba(0,0,0,.05)] text-[rgba(0,0,0,.95)]': isHighlighted}
  );
  return <li ref={ref} className={classes} {...otherProps} />;
});

const ItemLabel = ({
  isSelected,
  label,
}: {
  isSelected: boolean;
  label: string;
}) => {
  return (
    <Tag
      color={isSelected ? 'red' : 'blue'}
      label={label}
      showIcon
      Wrapper={null}
    />
  );
};

const InputElement = React.forwardRef((props: any, ref) => {
  const {className, ...otherProps} = props;

  return (
    <input
      className="m-0 w-full cursor-text overflow-visible border-0 px-12 py-5 text-base leading-none shadow-none focus-visible:border-0"
      ref={ref}
      placeholder={`Search tags`}
      {...otherProps}
      // this has to come after the spread to avoid accidental overwriting
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
});

export const FilterTagInfiniteDropdown = makeInfiniteDropdown(
  {
    Container,
    Display,
    Input: InputElement,
    ListItem,
    ItemLabel,
    ListWrapper,
  },
  {
    extractItems: (data: any): string[] => {
      if (!data) {
        return [];
      }
      return (
        data?.project?.runTags?.edges?.map(
          (edge: {node: RunTag}) => edge.node.name
        ) ?? []
      );
    },
    extractPageInfo: (data: any) => {
      if (!data) {
        return {};
      }

      return data?.project?.runTags?.pageInfo ?? {};
    },
    query: ProjectTags2Document,
  }
);
