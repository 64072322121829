import _ from 'lodash';
import {useCallback, useEffect, useState} from 'react';

import {safeLocalStorage} from '../../util/localStorage';
import {isWithinLastWeek} from './utils';

type SearchWithTime = {
  timeMS: number;
  value: string;
};

type RecentSearchesResults = {
  recentSearches: SearchWithTime[];
  registerRecentSearch: (s: string) => void;
  deleteRecentSearch: (s: string) => void;
};

export function useRecentSearches(): RecentSearchesResults {
  const [recentSearches, setRecentSearches] = useState<SearchWithTime[]>(
    loadRecentSearches()
  );

  const registerRecentSearch = useCallback((s: string) => {
    setRecentSearches(prev =>
      _.uniqBy([{timeMS: Date.now(), value: s}, ...prev], x => x.value)
    );
  }, []);
  const deleteRecentSearch = useCallback((s: string) => {
    setRecentSearches(prev => prev.filter(x => x.value !== s));
  }, []);
  useEffect(() => {
    saveRecentSearches(recentSearches);
  }, [recentSearches]);

  return {
    recentSearches,
    registerRecentSearch,
    deleteRecentSearch,
  };
}

const RECENT_SEARCHES_KEY = `__WB_LOCAL_SEARCH_recentSearches`;

function saveRecentSearches(recentSearches: SearchWithTime[]): void {
  try {
    safeLocalStorage.setItem(
      RECENT_SEARCHES_KEY,
      JSON.stringify(recentSearches)
    );
  } catch {
    // do nothing
  }
}

function loadRecentSearches(): SearchWithTime[] {
  try {
    const recentSearchesJSON = safeLocalStorage.getItem(RECENT_SEARCHES_KEY);
    return recentSearchesJSON
      ? JSON.parse(recentSearchesJSON).filter(isWithinLastWeek)
      : [];
  } catch {
    return [];
  }
}
