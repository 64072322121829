import {
  getCookieBool,
  setCookie,
  unsetCookie,
} from '@wandb/weave/common/util/cookie';
import {useEffect} from 'react';

import {Viewer} from '../state/viewer/types';

const generateCookieString = (length: number = 32) => {
  // Generate 32 random bytes
  const randomBytes = new Uint8Array(length);
  window.crypto.getRandomValues(randomBytes);
  // Convert bytes to hexadecimal strings
  return Array.from(randomBytes)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
};

export const useAnonUserSession = (viewer: Viewer | undefined) => {
  useEffect(() => {
    // Generate a random cookie for anonymous users
    // This cookie is used for cache session for the query engine
    if (viewer == null && !getCookieBool('anon_session_id')) {
      setCookie('anon_session_id', 'anon-' + generateCookieString());
    } else {
      // Clear the cookie if the user logs in
      if (viewer != null && getCookieBool('anon_session_id')) {
        unsetCookie('anon_session_id');
      }
    }
  }, [viewer]);
};
