import React, {createContext, useContext, useMemo} from 'react';

import {runsLinePlotConfigDefaults} from '../defaults';
import {RunsLinePlotConfig} from '../types';
import {
  useKeyInfoQueryContext,
  useMetricsIncludeHistogram,
} from './../../../components/MultiRunWorkspace/KeyInfoQueryContext';
import {
  LineAggregations,
  useDetermineAggregationType,
} from './useDetermineAggregationType';
import {usePanelGroupingSettings} from './usePanelGroupingSettings';
import {useRunsLinePlotSettings} from './useRunsLinePlotSettings';

type RunsLinePlotContextType = {
  /**
   * If the x-axis is monotonic (always increasing)
   */
  isMonotonic: boolean;
  /**
   * If the panel is a histogram. This is determined by checking if any of the metrics are typed as histograms
   */
  isHistogram: boolean;
  /**
   * The aggregation type to use for the primary line in the line plot. See comments on the type itself for how it controls what kind of underlying query we do.
   */
  primaryAggregation: LineAggregations;
  /**
   * Flag if a render error has occurred. This is currently a bespoke handler for the bucketing query (which does not have full parity with an RSDQ) and any unexpected condition inside that handler will eject the panel to a sampled plot.
   */
  setRenderError: (error: boolean) => void;
  /**
   * Temporary updater to save things to the workspace spec
   */
  updateWorkspaceConfig: (config: Partial<RunsLinePlotConfig>) => void;
  /**
   * Granular detail on the configuration settings to display when forcing a fallback to sampling mode. The existence of any messages in this list also is used to control the fallback when we hit known conditions that aren't supported in a bucketed plot
   */
  warningMessages: string[];
  /**
   * Whether the windowing is enabled for the line plot - this only applies to sampled plots currently. Controls whether the data will be stiched together or aggregated over "buckets"
   * e.g. windowing true turns points on different lines into [[1,2]], [[2,4]] => [1.5, 3]
   * e.g. windowing false turns points on different lines into  [[1,2]], [[2,4]] => [[1,2], [2,4]]
   */
  windowing: boolean;
};

/**
 * The goal here is to consolidate the logic for determining the state of the line plot
 *
 * At a high level this will influence whether we do the query through an RSDQ or a b(ucketing)RSDQ, and hopefully in the future these options will feed into the construction of the line itself so that we're not doing a string of memoized mutations all the way down the component tree to manipulate the data (and suffer the render/perf cost of doing so) in order to render the UI.
 */

export const RunsLinePlotContext = createContext<RunsLinePlotContextType>({
  isHistogram: false,
  isMonotonic: true,
  primaryAggregation: 'SAMPLED',
  setRenderError: () => {},
  updateWorkspaceConfig: () => {},
  warningMessages: [],
  windowing: true,
});

export const RunsLinePlotContextProvider = ({
  children,
  config,
  updateConfig,
}: {
  children: React.ReactNode;
  config: RunsLinePlotConfig;
  updateConfig: (config: Partial<RunsLinePlotConfig>) => void;
}) => {
  const [hasRenderError, setRenderError] = React.useState(false);

  const {
    keyInfoQuery: {historyKeyInfo},
  } = useKeyInfoQueryContext();

  const isMonotonic =
    historyKeyInfo?.keys[config.xAxis ?? '']?.monotonic === true;

  const {pointVisualizationMethod} = useRunsLinePlotSettings(config);

  const {isGrouped} = usePanelGroupingSettings();
  const isHistogram = useMetricsIncludeHistogram(config.metrics ?? []);

  const {lineType, warningMessages} = useDetermineAggregationType(config, {
    hasRenderError,
    isGrouped,
    isMonotonic,
    pointVisualizationMethod,
  });

  const value = useMemo(
    () => ({
      isHistogram,
      isMonotonic,
      primaryAggregation: lineType,
      setRenderError,
      updateWorkspaceConfig: updateConfig,
      warningMessages,
      // defaults to true
      // https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1725552052483829?thread_ts=1725551993.730519&cid=C062UKHTAHG
      windowing: config.windowing ?? runsLinePlotConfigDefaults.windowing,
    }),
    [
      config.windowing,
      isHistogram,
      isMonotonic,
      lineType,
      updateConfig,
      warningMessages,
    ]
  );

  return (
    <RunsLinePlotContext.Provider value={value}>
      {children}
    </RunsLinePlotContext.Provider>
  );
};
RunsLinePlotContext.displayName = 'RunsLinePlotContext';

export const useRunsLinePlotContext = () => {
  return useContext(RunsLinePlotContext);
};
