import {envIsPublicCloud} from '../../config';
import {useOrgDashboardEnablementQuery} from '../../generated/graphql';

export function useIsOrgDashboardEnabled() {
  const {loading, error, data} = useOrgDashboardEnablementQuery({
    skip: envIsPublicCloud,
  });

  if (loading || error || envIsPublicCloud) {
    return {isOrgDashboardEnabled: false, orgName: '', loading};
  }

  /**
   * Org name is not available for W&B server instances but backend returns
   * a hard-coded string "server" in this scenario. Realistically org name
   * should just be `undefined` because that data is not actually available.
   */
  const orgName =
    data?.organization?.name === 'server'
      ? undefined
      : data?.organization?.name;
  const activity = data?.organization?.activity ?? [];

  return {
    loading,
    isOrgDashboardEnabled: activity.length > 0,
    orgName,
  };
}
