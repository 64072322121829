import gql from 'graphql-tag';
import {useLocation} from 'react-router';

import {getRouteMatch, isBillingPath, isUsagePath} from '../routes/utils';
import {useQuery} from '../state/graphql/query';

export const TEAM_ORG_QUERY = gql`
  query TeamOrg($entityName: String!) {
    entity(name: $entityName) {
      id
      organization {
        id
        name
      }
    }
  }
`;

export interface TeamOrgQueryResult {
  entity: {
    id: string;
    name: string;
    organization?: {
      id: string;
      name: string;
    };
  };
}

interface TeamOrgQueryVariables {
  entityName?: string;
  orgName?: string;
}

export function useCurrentTeamOrg(): TeamOrgQueryResult | null {
  const location = useLocation();
  const routeMatch = getRouteMatch(location.pathname);
  const params = routeMatch?.params as TeamOrgQueryVariables | undefined;
  const entityName = params?.entityName;
  const orgName = params?.orgName;

  const skipQuery = orgName != null || entityName == null;
  const teamOrgQuery = useQuery<TeamOrgQueryResult, TeamOrgQueryVariables>(
    TEAM_ORG_QUERY,
    {
      variables: {
        entityName: entityName as string,
      },
      skip: skipQuery,
    }
  );

  // We use Organization name as "/:{entity name}/" when setting the Route/URL for account setting pages & subpages
  // (frontends/app/src/util/urls/index.ts). Legacy orgs can have the same name as entity/team but new orgs follow the norm of "team/entity-org"
  // In those cases query above will fail, so we just return TeamOrgQueryResult item w/ Organization name, which we know is in the path.
  if (orgName != null) {
    return {
      entity: {
        id: '',
        name: '',
        organization: {
          id: '',
          name: orgName,
        },
      },
    };
  }

  if (entityName == null || !teamOrgQuery.data?.entity) {
    return null;
  }

  if (teamOrgQuery.loading) {
    return null;
  }

  return {...teamOrgQuery, entity: teamOrgQuery.data.entity};
}

export function useShowNudgeBar(): boolean {
  const location = useLocation();
  const onUsagePage = isUsagePath(location.pathname);
  const onAccountSettingPage = isBillingPath(location.pathname);

  if (onUsagePage || onAccountSettingPage) {
    return false;
  }

  return true;
}
