import * as _ from 'lodash';

import {
  isAreaPoint,
  type SafeAreaPoint,
  type SafePoint,
} from '../PanelRunsLinePlot/componentOutliers/types';

function isInBounds(v: number, min: number, max: number) {
  return v >= min && v <= max;
}

export function makeClampPoint(
  [lowerBound, upperBound]: [number, number],
  [expandedLowerBound, expandedUpperBound]: [number, number]
) {
  return (pt: SafePoint | SafeAreaPoint) => {
    const areaPoint = isAreaPoint(pt);
    // we don't need to get a new reference if we're not clamping
    if (!areaPoint && isInBounds(pt.y, lowerBound, upperBound)) {
      return pt;
    }
    if (
      areaPoint &&
      isInBounds(pt.y, lowerBound, upperBound) &&
      isInBounds(pt.y0 as number, lowerBound, upperBound)
    ) {
      return pt;
    }

    const clampedY = isFinite(pt.y)
      ? _.clamp(pt.y, expandedLowerBound, expandedUpperBound)
      : pt.y === Infinity
      ? upperBound
      : pt.y === -Infinity
      ? lowerBound
      : pt.y;

    if (!areaPoint) {
      return {
        ...pt,
        y: clampedY,
      } as SafePoint;
    }

    const clampedY0 = isFinite(pt.y0 as number)
      ? _.clamp(pt.y0 as number, expandedLowerBound, expandedUpperBound)
      : pt.y0 === Infinity
      ? upperBound
      : pt.y0 === -Infinity
      ? lowerBound
      : pt.y0;

    const r = {
      ...pt,
      y: clampedY,
      y0: clampedY0,
    } as SafeAreaPoint;

    return r;
  };
}
