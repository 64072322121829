import * as QueryString from 'query-string';
import {useEffect, useMemo} from 'react';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ViewType} from '../../components/Workspace/multiRunWorkspaceHooks';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {RunPagePersistentState} from '../../components/Workspace/singleRunWorkspaceHooks';
import history from '../../util/history';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useDispatch, usePropsSelector} from '../hooks';
import {ProjectPageViewSpec} from '../views/projectPage/types';
import {makeViewListSelector} from '../views/selectors';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useWorkspaceInit} from './hooks';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {loadNamedWorkspaceForUrl} from './namedWorkspacesActions';
import {
  getSafeNwPart,
  getWorkspaceType,
  viewIsDeletedNW,
  viewIsLegacyWorkspace,
} from './names';
import {
  getDataMaybe,
  getStatus,
  makeWorkspaceReduxStateWithViewSelector,
} from './selectors';
import {
  getIsPersonalWorkspace,
  getUsernameFromId,
  NamedWorkspaceId,
  WorkspaceTypes,
} from './types';

/** This is the data that we parse out of a workspace URL */
export type WorkspaceUrl =
  // User and default are legacy URLs that should no longer be created.
  // After named workspaces is fully rolled out, we should treat these as legacy
  // values that will result in a migration.
  | {type: 'user'; user: string}
  | {type: 'default'}
  // empty is deprecated as of now - it's bad to have a GET url perform an action,
  // and the code now should not be generating any of these URLs
  | {type: 'empty'}
  // when user lands on the base project URL
  | {type: 'none'}
  | {
      type: 'named';
      id: string;
      nwType: 'shared workspace';
    }
  | {
      type: 'named';
      id: string;
      nwType: 'personal workspace';
      username: string;
    };

// These are all legacy and not used in named workspaces
/** @deprecated */
const WorkspaceParamValues = {
  empty: 'empty',
  default: 'default',
  userPrefix: 'user-',
} as const;

export const ParamNames = {
  workspace: 'workspace',
  nwId: 'nw',
  username: 'user',
} as const;
export type ParamNameType = (typeof ParamNames)[keyof typeof ParamNames];
/**
 * This function is responsible for
 * 1. populating redux with workspace information so we can display specified workspace and list of views
 * 2. handles url changes
 *    a. if specified url is invalid, redirect to a valid url
 *    b. if url param changed and the corresponding data is available, go load it
 */
export function useInitWorkspaceAndHandleUrlChanges(args: {
  entityName: string;
  projectName: string;
  viewType: ViewType | 'run-view';
  defaultSpec: ProjectPageViewSpec | RunPagePersistentState;
  workspaceObjectID?: string;
}) {
  const workspaceUrl = parsedOrRedirectedWorkspaceUrl(history.location.search);
  const workspaceStateId = useWorkspaceInit(
    args.entityName,
    args.projectName,
    args.viewType,
    args.defaultSpec,
    workspaceUrl,
    args.workspaceObjectID
  );

  useHandleNwUrl({
    entityName: args.entityName,
    projectName: args.projectName,
    viewType: args.viewType,
    workspaceStateId,
    workspaceUrl,
  });

  return workspaceStateId;
}

export function handleNwHistoryNavigation(
  action: 'push' | 'replace',
  nwId: NamedWorkspaceId | 'legacy'
) {
  // Don't do anything for legacy workspaces since we're removing logic soon anyways
  if (nwId === 'legacy') {
    return;
  }

  const newSearch = getNewNwUrlSearch({
    [ParamNames.nwId]: nwId.id,
  });
  if (newSearch === '') {
    // We hopefully shouldn't hit this scenario, but log if we do
    // to understand why and when.
    console.error('Unable to find new nw search for navigation.', action, nwId);
    return;
  }

  if (action === 'push') {
    history.push({search: newSearch});
  } else if (action === 'replace') {
    history.replace({search: newSearch});
  }
}

export function getNewNwUrlSearch(
  params: Partial<Record<ParamNameType, null | string>>
) {
  const parsedSearch = QueryString.parse(window.location.search);

  // Keep other params like PERF_LOGGING in workspaces
  const {
    [ParamNames.workspace]: discard1, // we don't want any of these params, but can't use _ more than once
    [ParamNames.username]: discard2,
    [ParamNames.nwId]: discard3,
    ...rest
  } = parsedSearch;
  const newParams = {...rest, ...params};

  return Object.keys(newParams).length > 0
    ? '?' + new URLSearchParams(QueryString.stringify(newParams)).toString()
    : '';
}

/**
 *
 * Responsible for updating redux when the browser's URL bar changes.
 */
export function useHandleNwUrl({
  entityName,
  projectName,
  viewType,
  workspaceStateId,
  workspaceUrl,
}: {
  entityName: string;
  projectName: string;
  viewType: ViewType | 'run-view';
  workspaceStateId: string;
  workspaceUrl: WorkspaceUrl;
}) {
  const dispatch = useDispatch();

  const workspaceResult = usePropsSelector(
    makeWorkspaceReduxStateWithViewSelector,
    workspaceStateId
  );

  const workspaceData = getDataMaybe(workspaceResult);
  const view = workspaceData?.view;
  const nwId = workspaceData?.nwId;

  const viewListResult = usePropsSelector(
    makeViewListSelector,
    entityName,
    projectName,
    viewType
  );

  const newSearch = useMemo(() => {
    const nwParam = getNWUrlParams(nwId, view?.name, view?.user.username);
    return getNewNwUrlSearch(nwParam);
  }, [nwId, view?.name, view?.user.username]);

  useEffect(() => {
    const isDataReady =
      !viewListResult.loading && getStatus(workspaceResult) === 'ready';

    const hasSearchChanged =
      history.location.pathname === `/${entityName}/${projectName}` &&
      history.location.search !== newSearch;

    // Action.Pop - A change to an arbitrary index in the stack, such as a back or forward navigation.
    // This does not describe the direction of the navigation, only that the index changed.
    // This is the default action for newly created history objects.
    // From npm history package docs - https://github.com/remix-run/history/blob/dev/docs/api-reference.md#action
    const didHistoryChange = history.action === 'POP';

    // We want to re-load workspace in redux because user triggered the browser back or forward button
    if (isDataReady && hasSearchChanged && didHistoryChange) {
      dispatch(
        loadNamedWorkspaceForUrl(
          workspaceStateId,
          workspaceUrl,
          viewListResult.views,
          viewType
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);
}

export function parsedOrRedirectedWorkspaceUrl(
  searchParams: URL['search']
): WorkspaceUrl {
  const parsed = QueryString.parse(searchParams);

  const workspaceParam = parsed[ParamNames.workspace];
  const namedParam = parsed[ParamNames.nwId];

  if (!parsed || Object.keys(parsed).length === 0) {
    return {type: 'none'};
  }

  if (namedParam != null) {
    return readOrRedirectNamedWorkspaceUrl(parsed, namedParam);
  }

  if (workspaceParam != null) {
    const paramValue = firstVal(workspaceParam);
    if (paramValue === WorkspaceParamValues.empty) {
      // This will load an empty workspace. Useful for getting out of broken states
      return {
        type: 'empty',
      };
    }
    if (paramValue === WorkspaceParamValues.default) {
      return {
        type: 'default',
      };
    }
    if (paramValue?.startsWith(WorkspaceParamValues.userPrefix)) {
      return {
        type: 'user',
        user: paramValue?.slice(WorkspaceParamValues.userPrefix.length),
      };
    }
  }

  return {
    type: 'none',
  };
}

const firstVal = (paramVal: string | string[]): string => {
  return Array.isArray(paramVal) ? paramVal[0] : paramVal;
};

// nw=<id>&published => (legacy) published workspace
// nw=<id>&user=<username> => (legacy) draft workspace
// nw=<id> => (new) shared and personal workspaces
const readOrRedirectNamedWorkspaceUrl = (
  parsed: QueryString.ParsedQuery<string>,
  namedParam: string | string[]
): WorkspaceUrl => {
  const usernameParam = parsed[ParamNames.username];
  const id = firstVal(namedParam);

  const username = usernameParam ? firstVal(usernameParam) : undefined;
  const parsedUsername = username == null ? getUsernameFromId(id) : username;

  if (getIsPersonalWorkspace(id) && parsedUsername != null) {
    return {
      type: 'named',
      id,
      nwType: 'personal workspace',
      // we don't use safeNwUsername here because the URL should not have been created with the `-` in the first place
      username: parsedUsername,
    };
  }

  if (parsedUsername == null) {
    return {
      type: 'named',
      id,
      nwType: 'shared workspace',
    };
  }

  return {type: 'none'};
};

/**
 * calculate the correct URL string for named workspaces
 */
export function getNWUrlParams(
  workspaceNwId: NamedWorkspaceId | undefined,
  viewName: string | undefined,
  viewUsername: string | undefined
): Partial<Record<ParamNameType, null | string>> {
  if (viewName == null || viewUsername == null || viewIsDeletedNW(viewName)) {
    return {};
  }

  if (workspaceNwId) {
    if (
      viewIsLegacyWorkspace(workspaceNwId.id) &&
      workspaceNwId.nwType === 'personal workspace'
    ) {
      /**
       * This creates the equivalent nwId from a legacy view. The view.name value is different
       * for NW and legacy views.
       */
      return {
        [ParamNames.nwId]: getNWParamForLegacyUserWorkspace(
          workspaceNwId.username,
          workspaceNwId.relatedId
        ),
      };
    }
    return {
      [ParamNames.nwId]: workspaceNwId.id,
    };
  }

  // Handles updating to a legacy workspace url
  const type = getWorkspaceType(viewName);
  return {
    [ParamNames.workspace]:
      type === WorkspaceTypes.default
        ? WorkspaceParamValues.default
        : WorkspaceParamValues.userPrefix + viewUsername,
  };
}

const getNWParamForLegacyUserWorkspace = (
  username: string,
  relatedId?: string
) => {
  const workspaceNameSuffix = relatedId != null ? `-${relatedId}` : '';
  return getSafeNwPart(`nwuser${username}`) + workspaceNameSuffix;
};
