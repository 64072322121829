import {fuzzyMatchScore} from '@wandb/weave/common/util/fuzzyMatch';
import {Button} from '@wandb/weave/components';
import {Alert} from '@wandb/weave/components/Alert';
import {Icon} from '@wandb/weave/components/Icon';
import {capitalizeFirst} from '@wandb/weave/core';
import classNames from 'classnames';
import {useCombobox} from 'downshift';
import l from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router';

import {Skeleton} from '../../../components/Skeleton';
import {orgRoles} from '../../../util/urls';
import {useOrgRolesContext} from './OrgRolesContext';
import {
  CustomRole,
  DefaultRole,
  DefaultRoleType,
  Permission,
} from './useOrgRolesContextResult';

const PermissionGroup: React.FC<{
  inheritedRole?: DefaultRole;
  groupName: string;
  permissions: Permission[];
  onRemovePermission: (perm: Permission) => void;
}> = props => {
  const {groupName, permissions, onRemovePermission, inheritedRole} = props;
  const inheritedPermissions = new Set<string>(
    inheritedRole?.permissions.map(p => p.name)
  );
  return (
    <div className="rounded border border-moon-250 ">
      <div className="flex items-center rounded border-b border-moon-250 bg-white px-16 py-12">
        <div className="flex-grow">
          <div className="text-base font-semibold">{groupName} Permissions</div>
        </div>
      </div>
      {permissions.map(p => {
        const inherited = inheritedPermissions.has(p.name);
        return (
          <div
            key={p.name}
            className="flex items-center border-b border-moon-250 px-16 py-12 last:border-transparent">
            <div className="flex-grow">{p.displayName}</div>
            {inherited ? (
              <p className="text-moon-500">
                (inherited from {inheritedRole?.roleType})
              </p>
            ) : (
              <button
                className="rounded-md text-moon-600 hover:text-moon-900"
                onClick={() => onRemovePermission(p)}>
                <Icon name="delete" />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

type PermissionSelectorProps = {
  selectedPermissions: Permission[];
  setSelectedPermissions: (permissions: Permission[]) => void;
  permissions: Permission[];
};

const filterPermissions = (
  perms: Permission[],
  filter: string
): Permission[] => {
  return filter === ''
    ? perms
    : perms
        .map(permission => ({
          permission,
          score: fuzzyMatchScore(
            filter,
            `${permission.name} ${permission.groupName}`
          ),
        }))
        .sort((a, b) => b.score - a.score)
        .filter(({score}) => score > 0.7)
        .map(({permission}) => permission);
};

const PermissionSelector: React.FC<PermissionSelectorProps> = props => {
  const {permissions, selectedPermissions, setSelectedPermissions} = props;

  const [inputValue, setInputValue] = React.useState('');
  const filteredPermissions = filterPermissions(permissions, inputValue);

  const selected = (perm: Permission) =>
    setSelectedPermissions([...selectedPermissions, perm]);
  const unselect = (perm: Permission) =>
    setSelectedPermissions(
      selectedPermissions.filter(
        p => p.name !== perm.name || p.groupName !== perm.groupName
      )
    );

  const isSelected = (permission: Permission) =>
    selectedPermissions.find(
      p => p.name === permission.name && p.groupName === permission.groupName
    ) != null;
  const toggleSelect = (perm: Permission) =>
    isSelected(perm) ? unselect(perm) : selected(perm);

  const {getInputProps, isOpen, getMenuProps, getItemProps, highlightedIndex} =
    useCombobox({
      items: filteredPermissions,
      selectedItem: null,
      inputValue,
      itemToString: (item: any) =>
        item == null ? '' : `${item.groupName} / ${item.name}`,
      stateReducer: (_, actionAndChanges) => {
        const {changes, type} = actionAndChanges;

        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection.
            };

          default:
            return changes;
        }
      },
      onStateChange: ({inputValue: newInputValue, type}) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            break;
          case useCombobox.stateChangeTypes.InputBlur:
            setInputValue('');
            break;
          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(newInputValue ?? '');
            break;
          default:
            break;
        }
      },
    });

  return (
    <div>
      <input
        placeholder="Search permissions"
        className="w-[600px] rounded-md border border-moon-400 px-8 py-4"
        {...getInputProps()}
      />
      <ul
        {...getMenuProps()}
        className={`absolute z-10 mt-8 h-[250px] w-[600px] overflow-y-scroll rounded-md bg-white p-0 shadow-md ${
          !(isOpen && permissions.length) && 'hidden'
        }`}>
        {isOpen &&
          filteredPermissions.map((item, index) => (
            <li
              className={classNames(
                highlightedIndex === index && 'bg-moon-300',
                isSelected(item) && 'font-bold',
                'shadow-sm flex items-center px-12 py-8'
              )}
              key={`${item.groupName}${item.name}`}
              {...getItemProps({item, index})}>
              <input
                type="checkbox"
                checked={isSelected(item)}
                onChange={() => toggleSelect(item)}
              />
              <span className="ml-8 flex-grow font-semibold">
                {item.displayName}
              </span>
              <span className="font-semibold text-moon-500">
                {item.groupName}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

type CustomRoleFormProps = {
  customRole?: CustomRole;
  handleSubmit: (role: Omit<CustomRole, 'id'>) => void;
};

export const CustomRoleForm: React.FC<CustomRoleFormProps> = ({
  customRole,
  handleSubmit,
}) => {
  const history = useHistory();
  const {loading, error, data} = useOrgRolesContext();

  const viewer = data.defaultRoles.find(
    r => r.roleType === DefaultRoleType.VIEWER
  );
  const member = data.defaultRoles.find(
    r => r.roleType === DefaultRoleType.MEMBER
  );

  const [roleName, setRoleName] = useState<string>(customRole?.name ?? '');
  const [roleNameInputState, setRoleNameInputState] = useState<{
    isActive: boolean;
    touched: boolean;
  }>({isActive: false, touched: false});

  const [description, setDescription] = useState<string>(
    customRole?.description ?? ''
  );
  const [inheritedRole, setInheritedRole] = useState<DefaultRole>();
  useEffect(() => {
    if (!inheritedRole) {
      const role = data.defaultRoles.find(
        r => r.roleType === customRole?.inheritedFrom
      );
      if (role) {
        setInheritedRole(role);
      } else {
        setInheritedRole(viewer);
      }
    }
  }, [
    data.defaultRoles,
    viewer,
    inheritedRole,
    customRole?.inheritedFrom,
    setInheritedRole,
  ]);

  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    customRole?.permissions ?? []
  );

  const selectablePermissions = useMemo(() => {
    return l
      .chain(data.defaultRoles)
      .flatMap(r => r.permissions)
      .uniqBy(p => p.name)
      .differenceBy(inheritedRole?.permissions ?? [], 'name')
      .sortBy(['groupName', 'name'], 'asc')
      .value();
  }, [data.defaultRoles, inheritedRole]);

  const grantedPermissionsGrouped = useMemo(() => {
    return (
      l
        .chain(selectedPermissions)
        .concat(inheritedRole?.permissions ?? [])
        // selected permissions and inherited permissions can intersect if the inherited role changes
        // so unique by name
        .uniqBy('name')
        .groupBy(g => g.groupName)
        .map((perms, groupName) => ({
          groupName,
          permissions: l.orderBy(perms, 'name', 'asc'),
        }))
        .orderBy('groupName', 'asc')
        .value()
    );
  }, [inheritedRole, selectedPermissions]);

  const isSubmitDisabled =
    roleName === '' || !inheritedRole || selectedPermissions.length === 0;
  const onSubmitClick = () => {
    if (isSubmitDisabled) {
      return;
    }
    const newPermissions = l
      .chain(selectedPermissions)
      .differenceBy(inheritedRole?.permissions ?? [])
      .value();
    handleSubmit({
      name: roleName,
      description,
      inheritedFrom: inheritedRole?.roleType,
      permissions: newPermissions,
    });
  };

  if (error) {
    return <Alert severity="error">Error loading roles</Alert>;
  }

  return (
    <div className="flex max-w-2xl flex-col gap-16">
      <div>
        <h3 className="flex flex-row text-xl font-semibold">
          <span className="pr-8">
            {customRole ? 'Edit role' : 'Create a role'}
          </span>
        </h3>
        <p className="flex text-moon-500">
          {customRole
            ? `Editing a custom role will result in a change of permissions to all
            users and invites that have been assigned this role. We recommend notifying users before making this change.`
            : `Roles are used to grant access and permissions for teams and members.
          Begin by choosing a role to inherit and adding permissions to create a
          role that fits your needs.`}
        </p>
      </div>

      <form className="grid gap-24">
        <div className="grid gap-8">
          <div className="flex max-w-sm flex-col">
            <label>Role</label>
            <input
              className="rounded-md border border-moon-400 px-8 py-4"
              value={roleName}
              onChange={e => {
                setRoleName(e.target.value);
              }}
              placeholder="Role name"
              onBlur={() => {
                setRoleNameInputState({
                  isActive: false,
                  touched: true,
                });
              }}
              onFocus={() => {
                setRoleNameInputState({
                  isActive: true,
                  touched: false,
                });
              }}
              required
            />
            {roleNameInputState.touched &&
              !roleNameInputState.isActive &&
              roleName === '' && (
                <p className="mt-2 text-xs text-red-500">
                  Please choose a role name
                </p>
              )}
          </div>

          <div className="flex max-w-sm flex-col">
            <label>Description</label>
            <input
              className="rounded-md border border-moon-400 px-8 py-4"
              placeholder="What is this role all about?"
              value={description}
              onChange={e => {
                setDescription(e.target.value);
              }}
            />
            <p className="mt-2 text-xs text-moon-500">
              A short description who this role is for or what permissions it
              grants
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-lg">Choose a role to inherit</h3>
          <p className="text-moon-500">
            All custom roles must inherit the permissions of a default role.
          </p>
          {loading ? (
            <Skeleton className="h-96 max-w-2xl" />
          ) : (
            <div className="mt-8 flex max-w-lg cursor-pointer items-center gap-8 rounded border border-moon-300 bg-white text-lg">
              {[viewer, member].map(r => {
                if (!r) {
                  return null;
                }
                return (
                  <div
                    key={r.id}
                    className="flex flex-grow items-center justify-center rounded p-8">
                    <input
                      type="radio"
                      checked={inheritedRole?.roleType === r.roleType}
                      onChange={() => {
                        setInheritedRole(r);
                      }}
                    />
                    <label className="flex items-center justify-center rounded p-8">
                      <Icon name="book-dictionary" className="mr-8" />
                      {capitalizeFirst(r.roleType)}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div>
          <div>
            <h3 className="text-lg">Add permissions</h3>
            <p className="mb-8 text-moon-500">
              Add permissions to create a role that fits your needs.
            </p>

            <PermissionSelector
              setSelectedPermissions={setSelectedPermissions}
              selectedPermissions={selectedPermissions}
              permissions={selectablePermissions}
            />
          </div>
        </div>
        {grantedPermissionsGrouped.length > 0 && (
          <div>
            <h3 className="mb-8">Custom role permissions</h3>
            <div className="grid gap-8">
              {grantedPermissionsGrouped.map(gp => (
                <PermissionGroup
                  key={gp.groupName}
                  groupName={gp.groupName}
                  inheritedRole={inheritedRole}
                  permissions={gp.permissions}
                  onRemovePermission={perm =>
                    setSelectedPermissions(
                      selectedPermissions.filter(p => p.name !== perm.name)
                    )
                  }
                />
              ))}
            </div>
          </div>
        )}

        <div className="flex items-center gap-8">
          <Button
            size="large"
            variant="secondary"
            onClick={() => history.push(orgRoles(data.orgName))}>
            Cancel
          </Button>
          <Button
            size="large"
            disabled={isSubmitDisabled}
            onClick={onSubmitClick}>
            {customRole ? 'Save Role' : 'Create Role'}
          </Button>
        </div>
      </form>
    </div>
  );
};
