import Color from 'color';

import {Mark} from './../../util/plotHelpers/types';

export function getStrokeDetail(
  color?: string,
  isHighlighted?: boolean,
  lineWidth?: number
) {
  const areaColor = isHighlighted
    ? Color(color).alpha(0.3).rgb().string()
    : color;

  const areaStrokeColor = isHighlighted
    ? Color(color).darken(1).rgb().string()
    : Color(color).alpha(0).rgb().string();

  const strokeWidth = (lineWidth ?? 1) * 1.5;

  return {
    areaColor,
    areaStrokeColor,
    strokeWidth: isHighlighted ? strokeWidth + 0.5 : strokeWidth,
  };
}

const strokeDashByMarkType: {
  [k in Mark]?: number[];
} = {
  dashed: [4, 2],
  dotted: [1, 1],
  dotdash: [5, 2, 1, 2],
  dotdotdash: [5, 2, 1, 2, 1, 2],
};

/**
 * The strokeDashArray property takes an array of numbers as its value. Each number in the array represents the length of either a dash or a gap in pixels. The pattern is repeated along the entire length of the path.
 *
 * For example, if you set strokeDashArray to [5, 3], it means that there will be a dash of 5 pixels followed by a gap of 3 pixels, and this pattern will repeat along the path.
 * @param isSvg
 * @returns
 */
export function getStrokeDashArray(mark: Mark | undefined, isSvg: boolean) {
  const strokeDashArray = mark ? strokeDashByMarkType[mark] : undefined;

  return isSvg ? strokeDashArray?.join(' ') : strokeDashArray;
}
