import {Link} from '@wandb/weave/common/util/links';
import React from 'react';

import * as S from './HomePageSidebar.styles';

type SidebarSectionHeaderProps = {
  headerText: string;
  linkUrl?: string;
  linkText?: string;
};

export const SidebarSectionHeader = ({
  headerText,
  linkUrl,
  linkText,
}: SidebarSectionHeaderProps) => {
  return (
    <S.SidebarListSubHeader>
      <S.SidebarListSubHeaderText className="flex" disableTypography>
        <div className="flex-1">{headerText}</div>
        {linkUrl && linkText && (
          <Link
            to={linkUrl}
            className="flex-0 text-base font-semibold leading-10 text-teal-600 hover:text-teal-500">
            {linkText}
          </Link>
        )}
      </S.SidebarListSubHeaderText>
    </S.SidebarListSubHeader>
  );
};
