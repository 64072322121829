import {Tag} from '@wandb/weave/common/types/graphql';
import {MediaString} from '@wandb/weave/common/types/media';

import {BenchmarkRun, RunInfo} from '../generated/graphql';
import {RunHistoryKeyInfo, RunHistoryRow} from '../types/run';

export type SimpleKey = string;
export type DeepKey = string[];
export type LookupKey = SimpleKey | DeepKey;

interface Sweep {
  readonly name: string;
  readonly displayName: string | null;
}

interface Agent {
  readonly name: string;
}

interface ServicesAvailable {
  readonly tensorboard: boolean;
}

export interface Run {
  readonly _wandb: KeyVal;
  readonly agent?: Agent;
  readonly aggregations: Aggregations;
  readonly benchmarkRun?: BenchmarkRun;
  readonly commit?: string;
  readonly computeSeconds?: number;
  readonly config: KeyVal;
  readonly createdAt: string;
  readonly defaultColorIndex?: number;
  readonly description?: string;
  readonly displayName: string;
  readonly github?: string;
  readonly group: string;
  readonly groupCounts?: number[];
  readonly heartbeatAt: string;
  readonly historyKeys?: RunHistoryKeyInfo;
  readonly host: string;
  readonly id: string;
  readonly inputArtifactsCount?: number;
  readonly jobType: string;
  readonly logLineCount?: number;
  readonly name: string;
  readonly notes: string;
  readonly outputArtifactsCount?: number;
  readonly pendingUpdates?: boolean;
  readonly projectId?: number;
  readonly runInfo?: RunInfo;
  readonly sampledHistory?: RunHistoryRow[][];
  readonly selectedRunName?: string;
  readonly servicesAvailable?: ServicesAvailable;
  readonly state: string; // TODO: narrow this type
  readonly stopped: boolean;
  readonly summary: KeyVal;
  readonly sweep?: Sweep;
  readonly systemMetrics?: Record<string, unknown>;
  readonly tags: Tag[];
  readonly updatedAt: string;
  readonly user: User;

  // Original nested config key structure. See comment on ConfigKeyTree type
  // for more information.
  readonly _nestedConfigKeys?: ConfigKeyTree;
}

/**
 * The ConfigKeyTree type is used to track the hierarchical structure of config keys
 * in run configs. Since the actual configs are flattened in the parseConfig function,
 * their original nested structure is lost. The _unnestedConfigKeys attribute of a Run uses this type
 * to potentially recreate the original nested structure when needed.
 *
 * Below are examples of valid ConfigKeyTrees:
 *
 * Example of an Empty Object:
 * const example1: ConfigKeyTree = {};
 *
 * Example of a Nested Object:
 * const example2: ConfigKeyTree = {
 *   optimizer: {
 *     settings: {
 *       parameters: {
 *         learning_rate: null,
 *         momentum: null
 *       }
 *     }
 *   }
 * };
 * // This corresponds to a configuration like:
 * // {optimizer: {settings: {parameters: {learning_rate: 0.1, momentum: 3}}}}
 *
 * Example of a Null Value:
 * const example3: ConfigKeyTree = null;
 */

export type ConfigKeyTree = {
  [key: string]: ConfigKeyTree;
} | null;

// Use this key to group all records together. Grouping by a non-existent key
// results in a single group.
export const GROUP_BY_ALL_KEY: Key = {
  section: 'config',
  name: '__wb_group_by_all',
};

// These two must match
// The special keys_info section is not actually present on returned runs, but
// we can construct filters using it, to find runs that have specific history
// keys.
export const runKeySections = [
  'run',
  'tags',
  'config',
  'summary',
  'keys_info',
  'aggregations_min',
  'aggregations_max',
];
export type RunKeySection =
  | 'run'
  | 'tags'
  | 'config'
  | 'summary'
  | 'keys_info'
  | 'aggregations_min'
  | 'aggregations_max';

// TODO: Is there a way to constrain name when section is run?
export interface Key {
  section: RunKeySection;
  name: string;
}

export interface Counts {
  runs?: number; // total number of runs
  filtered?: number; // number of visible runs (based on current filters)
  selected?: number; // number of currently-selected runs
}

export interface WBValue {
  _type: MediaString;
  [key: string]: any;
}

export interface Media extends WBValue {
  path: string;
  sha256: string;
  size: number;
  entity: string;
  project: string;
  run: string;
}

// These aren't quite correct, since values can also be fully nested objects and arrays
export type BasicValue = string | number | boolean | null;
export type Value = BasicValue | BasicValue[] | WBValue;

export type DomValue = string | number;

// config and summary are stored as KeyVal
export interface KeyVal {
  // The compiler doesn't like when we an array of runs that have different config keys (in tests),
  // unless we allow undefined here.
  readonly [key: string]: Value | undefined;
}

export interface User {
  username: string;
  photoUrl: string;
}

export interface Aggregations {
  readonly min: KeyVal;
  readonly max: KeyVal;
}
