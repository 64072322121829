import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox} from 'semantic-ui-react';

import {
  InspectorPropertyLabel,
  InspectorPropertyWrapper,
} from '../Inspector.styles';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {PropertyEditorComponent} from './property-editors';

/**
 * A simple checkbox editor for a boolean property.
 * Displays indeterminate state for mixed values.
 * Default is required.
 * @param props
 */
const CheckboxPropertyEditor: PropertyEditorComponent<'checkbox'> = props => {
  const displayedVal = props.values.length === 1 ? props.values[0] : undefined;
  return (
    <InspectorPropertyWrapper>
      <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
      <Checkbox
        indeterminate={props.values.length > 1}
        checked={displayedVal}
        onChange={(e, {value, checked}) => {
          props.save(checked);
        }}></Checkbox>
    </InspectorPropertyWrapper>
  );
};

export default CheckboxPropertyEditor;
