import {useContext, useMemo} from 'react';

import {envIsLocal, envIsPublicCloud} from '../../../config';
import {
  useOrganizationRolesQuery,
  useTeamOrganizationRolesQuery,
} from '../../../generated/graphql';
import {DEFAULT_ONPREM_ORGANIZATION_NAME} from '../../../pages/OrgDashboard/OrgRoles/RolesDashboard';
import {useIsRolesDashboardEnabled} from '../../../pages/OrgDashboard/OrgRoles/useIsRolesDashboardEnabled';
import {useViewer} from '../../../state/viewer/hooks';
import {useAccountPrivileges} from '../../../util/accounts/pricing';
import {useAdminModeActive} from '../../../util/admin';
import {AccountSelectorContext} from '../../Search/SearchNav/AccountSelectorContextProvider';
import {RoleOption} from '../TeamOrOrganizationRoleOption';
import {TeamMemberRole} from './utils';

export function useGetRoleOptions(entityName: string) {
  const viewer = useViewer();

  const {selectedAccount} = useContext(AccountSelectorContext);
  const {loading: privilegesLoading, privileges} =
    useAccountPrivileges(selectedAccount);

  const {data, loading: isTeamOrgQueryLoading} = useTeamOrganizationRolesQuery({
    variables: {entityName},
  });

  // Need special handling on server to query the custom roles off of the server org.
  const {data: roleData, loading: isServerOrgRolesLoading} =
    useOrganizationRolesQuery({
      variables: {
        orgName: DEFAULT_ONPREM_ORGANIZATION_NAME,
      },
      skip: envIsPublicCloud,
    });

  const isEnterprise = selectedAccount?.isEnterprise;

  // View only seats on teams are available on any enterprise plan. We don't check
  // number of view only seats here because that's only on the org level.
  const hasViewOnlySeats = isEnterprise || envIsLocal;

  const viewerMember = data?.entity?.members?.find(
    m => m?.username === viewer?.username
  );
  // TODO(elaina): role has been deprecated, fetch memberRole instead
  const viewerRole = viewerMember?.role;

  const isViewerInAdminMode =
    useAdminModeActive() && !viewer?.instanceAdminTeamAccessDisabled;
  const isViewerOrgAdmin = useMemo(() => {
    if (isTeamOrgQueryLoading || data?.entity?.organization?.members == null) {
      return false;
    }

    return (
      data?.entity?.organization?.members.find(
        mem => mem.username != null && mem.username === viewer?.username
      )?.role === 'admin'
    );
  }, [
    data?.entity?.organization?.members,
    viewer?.username,
    isTeamOrgQueryLoading,
  ]);
  const isViewerTeamAdmin = viewerRole === TeamMemberRole.ADMIN;
  const isViewerAnyAdmin =
    isViewerInAdminMode || isViewerTeamAdmin || isViewerOrgAdmin;

  const canViewerInviteAsMember =
    (data?.entity?.settings?.membersCanInvite &&
      viewerRole === TeamMemberRole.MEMBER) ??
    false;

  const roleManagementEnabled =
    !privilegesLoading && privileges.roleManagementEnabled;

  const isRolesDashboardEnabled = useIsRolesDashboardEnabled();

  const isLoading =
    isTeamOrgQueryLoading || privilegesLoading || isServerOrgRolesLoading;

  const roleOptions: {isLoading: boolean; roleOptions: RoleOption[]} =
    useMemo(() => {
      const predefinedRoles: RoleOption[] = [
        {
          text: 'Admin',
          value: TeamMemberRole.ADMIN,
          isPremiumOption: false, // Admin is available on all plans, not a premium option
          viewerCanInviteOtherAsRole: isViewerInAdminMode || isViewerTeamAdmin, // Only admins can add other users as admin
        },
        {
          text: 'Member',
          value: TeamMemberRole.MEMBER,
          isPremiumOption: true,
          isPremiumFeatureDisabled: !roleManagementEnabled, // Member is only available on certain plans
          isDisabled: !roleManagementEnabled,
          viewerCanInviteOtherAsRole:
            isViewerAnyAdmin || canViewerInviteAsMember, // Some teams don't allow members to invite other team members
        },
        {
          text: 'View-Only',
          value: TeamMemberRole.VIEW_ONLY,
          isPremiumOption: true,
          isPremiumFeatureDisabled: !roleManagementEnabled || !hasViewOnlySeats, // View-only is only available if the license/subscription allows it
          isDisabled: !roleManagementEnabled || !hasViewOnlySeats,
          viewerCanInviteOtherAsRole:
            isViewerAnyAdmin || canViewerInviteAsMember,
        },
      ];

      const customRoleOptions: RoleOption[] =
        (envIsPublicCloud
          ? data?.entity?.organization?.roles
          : roleData?.organization?.roles
        )
          ?.filter(role => role.inheritedFrom)
          ?.map(role => {
            return {
              text: role.name,
              value: role.name,
              description: role.description,
              isPremiumOption: true,
              isPremiumFeatureDisabled:
                !roleManagementEnabled || !isRolesDashboardEnabled, // Custom roles are only available on enterprise plans
              isDisabled: !roleManagementEnabled || !isRolesDashboardEnabled,
              viewerCanInviteOtherAsRole:
                isViewerAnyAdmin || canViewerInviteAsMember,
            };
          }) ?? [];

      return {
        isLoading,
        roleOptions: predefinedRoles.concat(customRoleOptions),
      };
    }, [
      canViewerInviteAsMember,
      hasViewOnlySeats,
      isLoading,
      isRolesDashboardEnabled,
      isViewerInAdminMode,
      roleManagementEnabled,
      isViewerAnyAdmin,
      data?.entity?.organization?.roles,
      isViewerTeamAdmin,
      roleData?.organization?.roles,
    ]);

  return roleOptions;
}
