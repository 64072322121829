import * as _ from 'lodash';
import * as React from 'react';
import {ReactNode, useEffect} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Container, Icon, Message} from 'semantic-ui-react';

import config, {envIsProd, urlPrefixed} from '../config';
import {captureError, shouldReloadOnError} from '../integrations';
import {NoAccessPage} from '../pages/NoAccessPage';
import {auth} from '../setup';
import {resetError} from '../state/global/actions';
import Login from './Login';
import VerifyEmail from './VerifyEmail';

// Expected incoming errors to ErrorPage
interface ErrorWithCode {
  message?: string | ReactNode;
  code: number;
  error_description?: string;
}

const PERMISSION_ERROR_MESSAGE =
  'You do not have permission to view this page.';

/**
 * Ensure an arbitrary error class is normalized into one error with a code.
 * This function needs to handle *any* kind of error, including an array or
 * null, even though we're not sure how these situations might originate.
 */
function normalizeError(error: any): ErrorWithCode {
  if (_.isArray(error)) {
    error = error.length > 0 ? error[0] : null;
  }
  // If we have multiple GQL errors get the first.
  if (error?.graphQLErrors?.length > 0) {
    error = error.graphQLErrors[0];
  }
  // If we have a permission error from Gorilla, generate a message.
  if (error?.extensions?.code === 'PERMISSION_ERROR') {
    if (error.extensions.username === '') {
      // If we have no username, we're unauthorized / anonymous. The 'please
      // log in' message will be displayed.
      error.code = 401;
    } else {
      // If we do have a username, it's a permission error.
      error.code = 403;
      error.message = PERMISSION_ERROR_MESSAGE;
    }
  }
  // Get defaults for code and message if not present.
  const isStealth404 =
    error && !error.code && error.message?.indexOf('not found') >= 0;
  // Use zero for the error code if there is no code recognized.
  const errorCode = error?.code || (isStealth404 ? 404 : 0);
  const errorMessage = (error?.message as string) || undefined;
  // Construct a normalized error object.
  return {
    code: errorCode,
    message: errorMessage,
    // Not sure where these attributes are set -- presumably scattered around
    // the codebase.
    error_description: error?.error_description,
  };
}

type ErrorIcon =
  | 'frown'
  | 'ban'
  | 'user'
  | 'hide'
  | 'stop circle outline'
  | 'broken chain'
  | 'bug';

interface ErrorInfo {
  icon: ErrorIcon;
  title: string;
  message: string | React.ReactFragment;
}

interface ErrorModal {
  modal: React.ReactElement;
}

function messageInfoForError(
  error: ErrorWithCode,
  dispatch: (_: AnyAction) => void
): ErrorInfo | ErrorModal {
  if (
    error.message &&
    typeof error.message === 'string' &&
    error.message.match('is a reserved word')
  ) {
    // this is a 400 when the name is on our banned list
    return {
      icon: 'frown',
      title: 'Bad Name',
      message: "You've chosen a reserved word, please choose another name.",
    };
  }
  if (error.code === 400) {
    return {
      icon: 'ban',
      title: 'Invalid Record',
      message: error.message || 'Unknown error',
    };
  }
  if (error.code === 403) {
    return {
      icon: 'hide',
      title: 'No Access',
      message:
        error.error_description === 'Signup disabled'
          ? 'You must signup with a private invitation link to see this page.'
          : error.message || 'You do not have permission to access this page.',
    };
  }
  if (error.code === 404) {
    return {
      icon: 'stop circle outline',
      title: 'Not Found',
      message: error.message ?? "We couldn't find what you're looking for.",
    };
  }
  if (error.code === 503) {
    return {
      icon: 'broken chain',
      title: 'Uh-oh, something is down.',
      message: 'Try refreshing the page.',
    };
  }
  // explicit 500 or unhandled error code (0): report
  return {
    icon: 'bug',
    title:
      (typeof error.message === 'string' && error.message) ||
      'Application Error',
    message: (
      <div>
        <p style={{marginTop: 24}}>An application error occurred.</p>
        <p style={{marginTop: 24}}>
          <Button
            icon="refresh"
            size="small"
            onClick={() => {
              dispatch(resetError());
              window.location.href = window.location.href.split('?')[0]; // eslint-disable-line wandb/no-unprefixed-urls
            }}
          />{' '}
          Click to refresh the page.{' '}
          {shouldReloadOnError() &&
            "We'll try reloading this page again shortly."}
        </p>
      </div>
    ),
  };
}

interface ErrorAlertProps {
  handleDismiss?: () => void;
  icon: ErrorIcon;
  title: string;
  message: string | {} | React.ReactNodeArray;
}

export const ErrorAlert = ({
  handleDismiss,
  icon,
  title,
  message,
}: ErrorAlertProps) => {
  React.useEffect(() => {
    if (envIsProd) {
      // For some reason, sometimes this track call causes an infinite loop when an error is thrown.
      // This is very easy to trigger in development, so for now, let's do this only in prod.
      // TODO(axel): Actually fix the infinite loop, which may actually be affecting real users in prod.
      window.analytics?.track('Error Page', {errorMessage: message});
    }
  }, [message]);
  return (
    <Container text>
      <div style={{height: 100}} />
      <Message
        data-test="error-message"
        error={true}
        size="small"
        icon
        onDismiss={handleDismiss}>
        <Icon name={icon} />
        <Message.Content>
          <Message.Header>{title}</Message.Header>
          <div>{message}</div>
        </Message.Content>
      </Message>
    </Container>
  );
};

interface ErrorPageProps {
  error: any;
  history: any;
  dispatch: (_: AnyAction) => void;
}

// Rendered when App receives the errors prop, or sets
// internal errors state.
const ErrorPage = ({error, history, dispatch}: ErrorPageProps) => {
  useEffect(() => {
    if (envIsProd) {
      captureError(error, 'ErrorPage');
    }
  }, [error]);

  const normalizedError = normalizeError(error);
  // We have an invalid token or other auth issue
  // clear auth and render our login page
  if (normalizedError.code === 401) {
    auth.logout(false);
    const location = history.location;
    location.state = {prompt: true, from: window.location};

    return <Login history={history} location={location} />;
  }

  // We reload on any history changes, since we're now in an undefined state.
  history.listen((location: any) => {
    // To reduce the chance of internal redirects causing an infinite
    // loop we check if this history change was triggered internally.
    // See App.tsx -> history.push('/signup', {state: {internal: true}})
    if (location?.state?.internal) {
      return;
    }
    console.log('Application is an error state, manually redirecting');
    if (
      location &&
      location.pathname != null &&
      location.hash != null &&
      location.search != null
    ) {
      window.location.href = `${location.pathname}${location.hash}${location.search}`; // eslint-disable-line wandb/no-unprefixed-urls
    } else {
      window.location.href = urlPrefixed();
    }
  });

  // Check for errors due to email verification challenge.
  if (
    error.code === 403 &&
    error.message &&
    typeof error.message === 'string' &&
    error.message.match('Email must be verified')
  ) {
    // this is a 400 when the user signs up with email/password after signing up with an auth provider
    captureError(error, 'ErrorPage', {fingerprint: ['errUnverifiedEmail']});
    return <VerifyEmail />;
  }

  // Check for other kinds of common issues.
  const messageInfo = messageInfoForError(normalizedError, dispatch);

  if (normalizedError?.message === PERMISSION_ERROR_MESSAGE) {
    dispatch(resetError());
    return <NoAccessPage />;
  }

  // If production, auto-reload 500's after 20s
  if (
    config.ENVIRONMENT_NAME === 'production' &&
    error.code >= 500 &&
    shouldReloadOnError()
  ) {
    setTimeout(
      () => (window.location.href = window.location.href.split('?')[0]), // eslint-disable-line wandb/no-unprefixed-urls
      20000
    );
  }
  // 5XX errors are intermittent so should be dismissable. 4XX errors are
  // bad requests and should not change with another attempt, so you should
  // not be able to reset them.
  const handleDismiss = () => {
    dispatch(resetError());
    if (normalizedError.code > 400) {
      window.location.href = window.location.href.split('?')[0]; // eslint-disable-line wandb/no-unprefixed-urls
    }
  };

  if ('modal' in messageInfo) {
    return messageInfo.modal;
  }

  const {icon, title, message} = messageInfo;
  return (
    <ErrorAlert
      icon={icon}
      handleDismiss={handleDismiss}
      title={title}
      message={message}
    />
  );
};

export default connect()(ErrorPage);
