import {WBMenuOnSelectHandler} from '@wandb/ui';
import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {SetState} from '@wandb/weave/common/util/types';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Menu} from 'semantic-ui-react';

import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  getShownRowsRange,
  usePageEffects,
} from './util';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {WBReactTableProps} from './WBReactTable';
import * as S from './WBReactTable.styles';

type WBReactTableHeaderProps = Pick<
  WBReactTableProps,
  | `pageSizeOptions`
  | `noSearch`
  | `noPaging`
  | `extraActions`
  | `headerInfo`
  | `searchPlaceholder`
  | `hasNextPage`
  | `fetchingNextPage`
  | `onFetchNextPage`
> & {
  page: number;
  pageSize: number;
  rowCount: number;
  changePageSize: (newPageSize: number) => void;
  setPage: SetState<number>;
  onSearch: (query: string) => void;
};

export const WBReactTableHeader: React.FC<WBReactTableHeaderProps> = React.memo(
  ({
    page,
    pageSize,
    pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
    rowCount,
    noSearch = false,
    noPaging = false,
    extraActions,
    headerInfo,
    searchPlaceholder,
    changePageSize,
    hasNextPage = false,
    fetchingNextPage = false,
    onFetchNextPage,
    setPage,
    onSearch,
  }) => {
    const {setAwaitingMoreData} = usePageEffects({
      rowCount,
      pageSize,
      setPage,
    });

    const debouncedSearch = useMemo(() => {
      return _.debounce(value => {
        onSearch(value);
      }, 200);
    }, [onSearch]);

    const handleInputChange = (event: any) => {
      const value = event.target.value;
      setSearchQuery(value);
      debouncedSearch(value);
    };

    // We need to manually calculate the pagination canPrevious and canNext
    // values because we don't use ReactTable's provided pagination component
    const totalPages = Math.ceil(rowCount / pageSize);
    const pageWithMax = Math.min(page, totalPages - 1);
    const canPrevious = pageWithMax > 0;
    const canNext = pageWithMax < totalPages - 1;
    const [searchQuery, setSearchQuery] = useState('');
    return (
      <Tailwind>
        <Menu
          secondary
          style={{margin: '0px', overflowX: 'auto', alignItems: 'center'}}>
          {!noSearch && (
            <>
              <Icon
                className="ml-12 mr-4 h-[16px] text-[#363a3d]"
                name="search"
              />
              <input
                className="mr-20 block flex-auto px-4 py-6 text-[13px] focus:outline-teal-400"
                onChange={handleInputChange}
                placeholder={searchPlaceholder ?? 'Search'}
                value={searchQuery}
                type="text"
              />
            </>
          )}
          {headerInfo}
          {!noPaging && (
            <Menu.Menu position="right">
              {extraActions && <S.MenuItem>{extraActions}</S.MenuItem>}
              <S.MenuItem>
                <S.RowCountSelectWrapper>
                  <S.RowCountSelect
                    menuTheme="light"
                    menuFontSize={13}
                    options={pageSizeOptions.map(size => ({
                      name: `${size}`,
                      value: size,
                      icon: null,
                    }))}
                    value={pageSize}
                    displayedValue={getShownRowsRange({
                      rowCount,
                      page,
                      pageSize,
                    })}
                    autoMenuWidth
                    onSelect={changePageSize as WBMenuOnSelectHandler}
                  />
                  {' of '}
                  {rowCount}
                  {hasNextPage && '+'}
                </S.RowCountSelectWrapper>
              </S.MenuItem>
              <S.MenuItem>
                <S.PageNav>
                  <Button.Group>
                    <Button
                      size="tiny"
                      className="wb-icon-button only-icon"
                      disabled={!canPrevious || fetchingNextPage}
                      onClick={() => setPage(prev => prev - 1)}>
                      <LegacyWBIcon name="previous" />
                    </Button>
                    <Button
                      size="tiny"
                      className="wb-icon-button only-icon"
                      disabled={
                        !(
                          canNext ||
                          (onFetchNextPage != null && hasNextPage)
                        ) || fetchingNextPage
                      }
                      loading={fetchingNextPage}
                      onClick={() => {
                        if (canNext) {
                          setPage(prev => prev + 1);
                        } else if (onFetchNextPage != null && hasNextPage) {
                          onFetchNextPage();
                          setAwaitingMoreData(true);
                        }
                      }}>
                      <LegacyWBIcon
                        name="next"
                        style={{
                          visibility: fetchingNextPage ? 'hidden' : 'visible',
                        }}
                      />
                    </Button>
                  </Button.Group>
                </S.PageNav>
              </S.MenuItem>
            </Menu.Menu>
          )}
        </Menu>
      </Tailwind>
    );
  }
);
