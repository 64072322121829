import React, {FC, memo} from 'react';

import {LinePlotPlot, LinePlotPlotProps} from './LinePlotPlot';

// This wrapper is used to control run highlighting via InteractState
const LinePlotPlotWithHighlightingComp: FC<
  LinePlotPlotProps & {
    isHovered?: boolean;
    singleRun?: boolean;
    drawing: boolean;
  }
> = ({isHovered, lines, singleRun, ...rest}) => {
  // because of rendering performance improvements we're restoring run highlighting in the new
  // workspace mode - also, we no longer render highlights in companion plots which saves load
  const turnOffHighlightingThreshold = Infinity;

  const isHighlightingDisabled = React.useMemo(() => {
    return [
      lines.length < 2, // Disable run highlighting in single-run panels
      lines.length > turnOffHighlightingThreshold, // disable highlighting with too many runs
    ].find(p => !p);
  }, [lines.length, turnOffHighlightingThreshold]);

  if (isHighlightingDisabled) {
    return null;
  }

  // const disableCompanionPlotHighlights =
  //   !isHovered && reduceRenderDemands && !activeHoveredRun;

  return (
    <LinePlotPlot {...rest} lines={lines} highlightEverything={lines != null} />
  );
};

export const LinePlotPlotWithHighlighting = memo(
  LinePlotPlotWithHighlightingComp
);
