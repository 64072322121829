import React from 'react';

import {useKeyInfoQueryContext} from '../MultiRunWorkspace/KeyInfoQueryContext';
import {RunsLinePlotConfig} from './types';
import {getTruncatedSelectedMetrics} from './utils';

type MetricsConfig = {
  metricRegex?: string;
  metrics?: string[];
  useMetricRegex?: boolean;
};

// If we're using a regex to determine y-axis metrics,
// update the chart when new historyKeys come in that match the regex.
export const useRegexMetrics = (
  config: MetricsConfig,
  updateConfig: (arg: Partial<RunsLinePlotConfig>) => void
) => {
  const {
    keyInfoQuery: {historyKeyInfo},
  } = useKeyInfoQueryContext();

  React.useEffect(() => {
    if (
      !config.useMetricRegex ||
      !config.metricRegex ||
      historyKeyInfo == null
    ) {
      return;
    }

    const regexMatchMetrics = getTruncatedSelectedMetrics({
      metricRegex: config.metricRegex,
      keyInfo: historyKeyInfo,
      sortedKeys: historyKeyInfo.sortedKeys,
    });
    const arraysDifferentLength =
      config.metrics?.length !== regexMatchMetrics.length;
    const arraysMatch = (config.metrics ?? []).every(metric =>
      regexMatchMetrics.includes(metric)
    );
    if (arraysDifferentLength || !arraysMatch) {
      updateConfig({
        metrics: regexMatchMetrics,
      });
    }
  }, [
    config.metricRegex,
    config.metrics,
    config.useMetricRegex,
    historyKeyInfo,
    updateConfig,
  ]);
};
