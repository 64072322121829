import React from 'react';

import * as S from './InlineNumberEditor.styles';
import * as QueryEditorStyles from './QueryEditor.styles';

interface InlineNumberEditorProps {
  value: number;
  min: number;
  max: number;
  setValue(val: number): void;
}

const InlineNumberEditor: React.FC<InlineNumberEditorProps> = props => {
  const ref = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (document.querySelector(':focus') == null) {
      ref.current?.focus();
    }
  }, []);
  return (
    <QueryEditorStyles.ArgSpan spellCheck="false">
      <S.InlineNumberContentEditable
        innerRef={ref}
        value={props.value}
        onChange={v => props.setValue(v)}></S.InlineNumberContentEditable>
    </QueryEditorStyles.ArgSpan>
  );
};

export default InlineNumberEditor;
