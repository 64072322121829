import React from 'react';
import {twMerge} from 'tailwind-merge';

export const SettingLabel = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  return (
    <h3
      className={twMerge(
        'w-[7rem]',
        'text-base font-semibold leading-[32px]',
        'group-[.is-panel-config]:text-sm', // account for panel config - font size is smaller in there than slider
        className
      )}>
      {text}
    </h3>
  );
};
