// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
  background: ${props => props.theme.clickable};
  color: ${props => props.theme.text};
  /* background: #777;
  color: white; */
  border-radius: 100%;
  border: 2px solid ${props => props.theme.background};
  width: 19px;
  height: 19px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .wbic-ic-close {
    margin: 0;
  }
  &:hover {
    background: ${props => props.theme.focused};
  }
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.6); */
`;
