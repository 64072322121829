import {ApolloError} from 'apollo-client';
import {useContext} from 'react';

import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {useAccountTeamsQuery} from '../../../generated/graphql';
import {Override} from '../../../graphql/teamQuery';
import {Graph, Team} from '../../../types/graphql';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';

type accountTeams = {
  accountTeams: Graph<Team>;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<any>;
};

export function useAccountTeamsList(): accountTeams {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const {data, loading, error, refetch} = useAccountTeamsQuery({
    skip:
      enableAccountSelector &&
      selectedAccount?.accountType === AccountType.Personal,
  });

  const username = data?.viewer?.username;
  const accountTeamsList =
    data?.viewer?.teams?.edges.filter(a => a.node?.name !== username) ?? [];
  const filteredAccountTeamsList =
    accountTeamsList.filter(
      team => team?.node?.organizationId === selectedAccount?.id
    ) ?? [];
  const accountTeamsListForUser = {
    edges:
      enableAccountSelector && selectedAccount
        ? filteredAccountTeamsList
        : accountTeamsList,
  };

  return {
    accountTeams: accountTeamsListForUser as Override<
      typeof accountTeamsListForUser,
      Graph<Team>
    >,
    loading,
    error,
    refetch,
  };
}
