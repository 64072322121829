import React, {createContext, useContext} from 'react';

type PanelSamplingContextType = {
  isSampled: boolean;
};

export const PanelSamplingContext = createContext<PanelSamplingContextType>({
  isSampled: false,
});

export const PanelSamplingContextProvider = React.memo(
  ({children, isSampled}: {children: React.ReactNode; isSampled: boolean}) => {
    const value = React.useMemo(() => ({isSampled}), [isSampled]);

    return (
      <PanelSamplingContext.Provider value={value}>
        {children}
      </PanelSamplingContext.Provider>
    );
  }
);
PanelSamplingContextProvider.displayName = 'PanelSamplingContextProvider';

export const usePanelSamplingContext = () => {
  return useContext(PanelSamplingContext);
};
