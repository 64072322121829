import classNames from 'classnames';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button} from 'semantic-ui-react';

interface MultiButtonProps {
  keys: string[];
  labels?: string[];
  selected?: string;
  onChange?: (selected: string) => void;
  disabled?: boolean;
}
const MultiButton: React.FC<MultiButtonProps> = props => {
  return (
    <Button.Group size="tiny">
      {props.keys.map((key, i) => (
        <Button
          key={'button option ' + i}
          size="small"
          disabled={props.disabled}
          className={classNames({
            'action-button--active': key === props.selected,
          })}
          onClick={() => {
            if (props.onChange != null) {
              props.onChange(key);
            }
          }}>
          {props.labels ? props.labels[i] : props.keys[i]}
        </Button>
      ))}
    </Button.Group>
  );
};

export default MultiButton;
