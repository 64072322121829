import React, {createContext, FC, useMemo} from 'react';

import config from '../../config';
import {
  ActiveExperiment,
  useActiveExperimentsQuery,
} from '../../generated/graphql';
import {captureError} from '../../integrations';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../util/errors';
import {updateLocalStorageExperiments} from './localStorage';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {updateActiveExperimentsGlobal} from './utils';

type ExperimentContextProps = {
  activeExperiments: ActiveExperiment[];
  isExperimentsQueryLoading: boolean;
};

export const ExperimentContext = createContext<ExperimentContextProps>({
  activeExperiments: [],
  isExperimentsQueryLoading: false,
});
ExperimentContext.displayName = 'ExperimentContext';

export const ExperimentContextProvider: FC = ({children}) => {
  const {
    data,
    networkStatus,
    loading: isExperimentsQueryLoading,
    error,
  } = useActiveExperimentsQuery({
    context: propagateErrorsContext(),
    skip: config.ENVIRONMENT_IS_PRIVATE,
    pollInterval: 1000 * 60 * 15, // poll every 15 minutes
  });

  const activeExperiments: ActiveExperiment[] = useMemo(() => {
    if (!config.ENVIRONMENT_IS_PRIVATE && !isExperimentsQueryLoading) {
      if (error == null) {
        const experiments = data?.activeExperiments ?? [];
        updateLocalStorageExperiments(experiments);
        updateActiveExperimentsGlobal(experiments);
        return experiments;
      }
      const errMsg = extractErrorMessageFromApolloError(error);
      captureError(
        `Apollo response error: ${errMsg}`,
        'useFetchAllExperimentsQuery',
        {
          extra: {error, networkStatus},
        }
      );
    }
    return [];
  }, [data, networkStatus, isExperimentsQueryLoading, error]);

  const state = useMemo(
    () => ({
      activeExperiments,
      isExperimentsQueryLoading,
    }),
    [activeExperiments, isExperimentsQueryLoading]
  );

  return (
    <ExperimentContext.Provider value={state}>
      {children}
    </ExperimentContext.Provider>
  );
};
