import {randID} from '@wandb/weave/common/util/data';
import {TargetBlank} from '@wandb/weave/common/util/links';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {Tooltip} from '@wandb/weave/components/Tooltip';
import * as querystring from 'querystring';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Link} from 'react-router-dom';

import {
  Alert,
  SlackIntegration as Slack,
  useCreateStoppedRunAlertMutation,
  useServerInfoQuery,
  useUpdateStoppedRunAlertMutation,
} from '../generated/graphql';
import {withViewer} from '../graphql/users_get_viewer';
import {User} from '../types/graphql';
import {useGetTotalCommunityEditionOrgs} from '../util/accounts/pricing';
import {useIsAdminModeActiveAndIsNotLocalEnv, viewingAs} from '../util/admin';
import {safeLocalStorage} from '../util/localStorage';
import {
  defaultScriptDurationMilliseconds,
  defaultUnit,
  EmailSubscription,
  fromMilliseconds,
  SlackChannelSubscription,
  timeUnits,
  toMilliseconds,
  Unit,
} from './Alerts';
import * as S from './AlertsManagementModal.styles';
import {Highlight, Python} from './Code';
import {
  isSlackIntegration,
  SLACK_CODE,
  STATE_STORE_KEY,
  toSlackIntegration,
} from './SlackIntegration';

const sampleAlertCode = `from datetime import timedelta
import wandb
from wandb import AlertLevel

if acc < threshold:
  wandb.alert(
    title='Low accuracy',
    text=f'Accuracy {acc} is below the acceptable threshold {threshold}',
    level=AlertLevel.WARN,
    wait_duration=timedelta(minutes=5)
  )
`;

type AlertsManagementModalProps = {
  viewer: User; // from withViewer
  refetch: () => void; // from withViewer
  location: 'search nav' | 'runs table' | 'single run page';
  displayIcon?: 'bell' | 'active run indicator'; // Defaults to "bell"
  onClose?: () => void;
  color?: string; // Defaults to globals.gray400
};

const AlertsManagementModal: React.FC<AlertsManagementModalProps> = React.memo(
  ({location, viewer, refetch, displayIcon = 'bell', onClose}) => {
    const {userEntity} = viewer ?? {};
    const defaultDurationMilliseconds: number | undefined = userEntity
      ? defaultScriptDurationMilliseconds(userEntity)
      : toMilliseconds(45, 'minutes');
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [durationUnit, setDurationUnit] = useState<Unit>(
      defaultDurationMilliseconds != null
        ? defaultUnit(defaultDurationMilliseconds)
        : 'minutes'
    );
    const [durationMilliseconds, setDurationMilliseconds] = useState(
      defaultDurationMilliseconds ?? toMilliseconds(45, 'minutes')
    );
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 710);
    const screenResizeHandler = (): void => {
      setIsMobile(window.innerWidth < 710);
    };

    const {numberOfCommunityEditionOrgs, totalOrgs} =
      useGetTotalCommunityEditionOrgs();

    const shouldHideAlertsModal =
      numberOfCommunityEditionOrgs > 0 &&
      numberOfCommunityEditionOrgs === totalOrgs;

    useEffect(() => {
      if (popupOpen) {
        window.addEventListener('resize', screenResizeHandler);
      } else {
        window.removeEventListener('resize', screenResizeHandler);
      }
      return () => {
        window.removeEventListener('resize', screenResizeHandler);
      };
    }, [popupOpen]);
    const [createStoppedRunAlert] = useCreateStoppedRunAlertMutation();
    const [updateStoppedRunAlert] = useUpdateStoppedRunAlertMutation();

    const serverInfo = useServerInfoQuery();

    let slackIntegration: Slack | undefined;
    if (userEntity) {
      for (const integrationEdge of userEntity.integrations.edges) {
        if (isSlackIntegration(integrationEdge.node)) {
          slackIntegration = toSlackIntegration(integrationEdge.node);
        }
      }
    }

    const handleClickOpen = (): void => {
      setPopupOpen(true);
      setTooltipOpen(false);
      safeLocalStorage.setItem('alertsModalViewed', 'true');
      window.analytics?.track(`Alert Popover Viewed`, {location});
    };

    const handleIconMouseOver = (): void => {
      if (!popupOpen) {
        setTooltipOpen(true);
      }
    };

    const stoppedRunAlert: Alert | undefined =
      userEntity?.defaultAlerts?.find?.(
        alert => (alert.condition as any).__typename === 'StoppedRunCondition'
      );

    const stoppedRunAlertCreator = () => {
      return createStoppedRunAlert({
        variables: {
          entityName: userEntity?.name,
          minimumRunDuration:
            durationMilliseconds !== undefined
              ? durationMilliseconds
              : toMilliseconds(10, 'minutes'),
        },
      }).then(alert => {
        return alert.data!.createStoppedRunAlert!.alert.id;
      });
    };

    const redirectUriRef: MutableRefObject<string> = useRef<string>(
      window.location.origin + window.location.pathname
    );

    const startSlackOAuth = useCallback(() => {
      window.analytics?.track('Connect Slack Clicked', {
        location: 'alert popover',
      });
      const state = `${SLACK_CODE}:wandb:` + randID(20);
      safeLocalStorage.setItem(STATE_STORE_KEY, state);
      if (!serverInfo.data) {
        alert('unable to access slack configuration');
        return;
      }

      // eslint-disable-next-line wandb/no-unprefixed-urls
      window.open(
        `https://slack.com/oauth/authorize?${querystring.stringify({
          scope: 'incoming-webhook',
          client_id: serverInfo.data?.serverInfo?.slackClientID,
          state,
          redirect_uri: redirectUriRef.current,
        })}`,
        '_self'
      );
    }, [serverInfo.data]);

    const isEmailAlertEnabled: boolean = userEntity?.defaultAlerts?.some(
      alert =>
        alert?.condition?.__typename === 'StoppedRunCondition' &&
        alert.subscriptions.some(
          sub => (sub as any)?.__typename === 'EmailSubscription'
        )
    );

    const slackAlertsEnabled = userEntity?.defaultAlerts?.some(
      alert =>
        alert?.condition?.__typename === 'StoppedRunCondition' &&
        alert.subscriptions.some(
          sub => (sub as any)?.__typename === 'SlackChannelSubscription'
        )
    );
    const isAdminMode =
      useIsAdminModeActiveAndIsNotLocalEnv() || viewingAs() !== '';

    return (
      <S.StyledPopup
        basic
        trigger={
          <S.IconContainer
            onClick={shouldHideAlertsModal ? () => null : handleClickOpen}
            location={location}>
            <Tooltip
              position="bottom center"
              open={tooltipOpen}
              onOpen={handleIconMouseOver}
              onClose={() => setTooltipOpen(false)}
              trigger={
                displayIcon === 'bell' ? (
                  <S.IconWrapper
                    aria-label="Set up run alerts in Slack or email"
                    role="img"
                    $isInNav={location === 'search nav'}
                    $showAdminBanner={isAdminMode}>
                    <S.Icon />
                  </S.IconWrapper>
                ) : (
                  <S.ActiveRunDot />
                )
              }>
              {shouldHideAlertsModal ? (
                <Tailwind>
                  <div className="flex">
                    <div className="mr-5">
                      <Icon name="crown-pro" />
                    </div>
                    Upgrade to access slack and email alerts
                  </div>
                </Tailwind>
              ) : (
                'Set up run alerts in Slack or email'
              )}
            </Tooltip>
          </S.IconContainer>
        }
        position={location === 'search nav' ? 'bottom right' : 'right center'}
        open={popupOpen}
        onClose={() => {
          setPopupOpen(false);
          onClose?.();
        }}
        on="click"
        inverted>
        <S.Content>
          <S.Header>Get an alert if a run crashes.</S.Header>
          <S.Row>
            <S.th wide>Alert</S.th>
            {!isMobile && (
              <>
                <S.th>Email</S.th>
                <S.th>Slack</S.th>
              </>
            )}
          </S.Row>
          <S.Row isMobile={isMobile}>
            <S.td wide isMobile={isMobile}>
              <b>Runs crashed after&nbsp;</b>
              <S.TextInput
                className="alerts--crashed-duration"
                size="mini"
                type="number"
                value={fromMilliseconds(durationMilliseconds, durationUnit)}
                onChange={(e: any) => {
                  const durationInMilliseconds = toMilliseconds(
                    parseFloat(e.target.value),
                    durationUnit
                  );
                  setDurationMilliseconds(durationInMilliseconds);

                  const alertUpdater = (id: string) => {
                    return updateStoppedRunAlert({
                      variables: {
                        id,
                        minimumRunDuration: durationInMilliseconds,
                      },
                    }).then();
                  };

                  if (stoppedRunAlert !== undefined) {
                    alertUpdater(stoppedRunAlert.id).then();
                  } else {
                    stoppedRunAlertCreator()
                      .then(alertID => {
                        return alertUpdater(alertID);
                      })
                      .then(refetch);
                  }
                }}
                placeholder="45"
                label={
                  <S.DropdownInput
                    defaultValue={durationUnit}
                    options={timeUnits}
                    onChange={(_: any, data: any) => {
                      setDurationUnit(data.value as Unit);
                    }}
                  />
                }
                labelPosition="right"
              />
            </S.td>
            <S.td>
              {isMobile && (
                <S.MobileAlertTypeLabel>
                  <b>Email</b>
                </S.MobileAlertTypeLabel>
              )}
              <EmailSubscription
                alert={stoppedRunAlert}
                alertCreator={stoppedRunAlertCreator}
                refetch={refetch}
                location="alert popover"
                colorTheme="dark"
              />
              {isEmailAlertEnabled ? 'On' : 'Off'}
            </S.td>
            <S.td>
              {isMobile && (
                <S.MobileAlertTypeLabel>
                  <b>Slack</b>
                </S.MobileAlertTypeLabel>
              )}
              {slackIntegration != null ? (
                <>
                  <SlackChannelSubscription
                    alert={stoppedRunAlert}
                    alertCreator={stoppedRunAlertCreator}
                    refetch={refetch}
                    integration={slackIntegration}
                    location="alert popover"
                    colorTheme="dark"
                  />
                  {slackAlertsEnabled ? 'On' : 'Off'}
                </>
              ) : (
                <S.BlueButton
                  positive
                  size="tiny"
                  className="slack--connect-button"
                  onClick={() => startSlackOAuth()}>
                  Connect Slack
                </S.BlueButton>
              )}
            </S.td>
          </S.Row>
          <S.RowSimple>
            <S.Div>
              Try <b>wandb.alert()</b> in your next run, for example:
            </S.Div>
            <S.Div
              onClick={() => {
                window.analytics?.track('Alert Code Copied', {
                  location: 'alert popover',
                });
              }}>
              <Python>
                <Highlight>{sampleAlertCode}</Highlight>
              </Python>
            </S.Div>
            <S.LinksContainer>
              <S.LinkDiv>
                <TargetBlank href="https://docs.wandb.ai/guides/runs/alert">
                  Go to Docs
                </TargetBlank>
              </S.LinkDiv>
              <S.LinkDiv>
                <Link to={'/settings'}>Go to Settings</Link>
              </S.LinkDiv>
            </S.LinksContainer>
          </S.RowSimple>
        </S.Content>
      </S.StyledPopup>
    );
  }
);

export default withViewer(AlertsManagementModal);
