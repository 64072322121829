import {denormFn, normFn} from '../normalizerSupport';
import * as Types from './types';

export const normalize = normFn<Types.MarkdownBlockObjSchema>(
  'markdown-block',
  (whole, ctx) => whole
);

export const denormalize = denormFn<Types.MarkdownBlockObjSchema>(
  (part, ctx) => part
);
