import {StateType} from './stateType';

export const getExpectedSpec = (
  expectedPanels: StateType,
  key: string | null
) => {
  if (!expectedPanels) {
    return;
  }
  if (!key) {
    return;
  }
  return expectedPanels.defaultSpecs[key] ?? expectedPanels.apiAddedSpecs[key];
};
