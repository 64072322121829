import {denormFn, normFn} from '../normalizerSupport';
import * as Types from './types';

export const normalize = normFn<Types.GroupSelectionsObjSchema>(
  'group-selections',
  whole => whole
);

export const denormalize = denormFn<Types.GroupSelectionsObjSchema>(
  part => part
);
