import Drawer from '@material-ui/core/Drawer';
import {TargetBlank} from '@wandb/weave/common/util/links';
import {isValidEmail} from '@wandb/weave/common/util/string';
import {Button} from '@wandb/weave/components/Button';
import {TextField} from '@wandb/weave/components/Form/TextField';
import React, {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router';

import {BannerFlexWrapper} from '../../../components/Banner';
import {
  useAvailability,
  useStorageBucketConfig,
} from '../../../components/CreateTeam/hooks';
import {
  StorageBucketInfoFormValue,
  TeamType,
} from '../../../components/CreateTeam/types';
import {ResizableDrawer} from '../../../components/Launch/ResizableDrawer';
import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {envIsLocal} from '../../../config';
import {
  useCreateAcademicOrReverseTrialMutation,
  useCreateInviteMutation,
  useCreateTeamMutation,
  useCreateUserLedTrialMutation,
  useUserOrganizationsQuery,
} from '../../../generated/graphql';
import {captureError} from '../../../integrations';
import {ACADEMIC_APPLICATION_PATH} from '../../../routes/paths';
import {reloadCurrentViewer} from '../../../state/viewer/actions';
import {useViewer} from '../../../state/viewer/hooks';
import {
  isAcademicOrg,
  isAcademicTrialOrg,
  PremiumFeatureTooltip,
} from '../../../util/accounts/pricing';
import {
  doNotRetryContext,
  getErrMsgFromError,
  propagateErrorsContext,
} from '../../../util/errors';
import history from '../../../util/history';
import * as urls from '../../../util/urls';
import {termsOfService} from '../../../util/urls';
import * as C from './CommonDrawer.styles';
import * as S from './CreateTeamDrawer.styles';
import {useCreateTeamDrawerAnalytics} from './CreateTeamDrawerAnalytics';
import {handleGQLErrors, handleMutationError} from './CreateTeamDrawerUtil';
import StorageBucketInfoInput from './StorageBucketInfoInput';
import {useHandleScroll} from './useHandleScroll';

const TEAM_NAME_TAKEN_MESSAGE =
  'Team name already taken. Please choose a different name.';

type CreateTeamData = {
  teamEntityName: string;
  orgName: string;
  teamType: TeamType;
  storageBucketInfo?: StorageBucketInfoFormValue;
};

type CreateTeamProps = {
  open: boolean;
  toggleTeamDrawer: () => void;
  analyticsLocation: string;
};

export const CreateTeamDrawerComp = ({
  open,
  toggleTeamDrawer,
  analyticsLocation,
}: CreateTeamProps) => {
  const track = useCreateTeamDrawerAnalytics(analyticsLocation);

  useEffect(() => {
    track.createTeamViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {scrolled} = useHandleScroll();
  const dispatch = useDispatch();
  const viewer = useViewer();
  const [orgName, setOrgName] = useState('');
  const [width, setWidth] = useState(600);
  const onSetWidth = (newWidth: number) => {
    setWidth(newWidth);
  };
  const onClose = (e: React.MouseEvent) => {
    toggleTeamDrawer();
  };

  const userOrganizationsQueryResult = useUserOrganizationsQuery();
  const userOrganizations = useMemo(() => {
    return userOrganizationsQueryResult?.data?.viewer?.organizations ?? [];
  }, [userOrganizationsQueryResult?.data]);

  // 'Academic' uses trial team
  // 'Work' uses trial team unless a user specifies an existing org
  const [createUserLedTrialMutation] = useCreateUserLedTrialMutation({
    context: {...propagateErrorsContext(), ...doNotRetryContext()},
  });

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [selectedTeamType, setSelectedTeamType] = useState<TeamType>('work');
  const [entityName, setEntityName] = useState('');
  const [agreedToAcademicPolicy, setAgreedToAcademicPolicy] =
    useState<boolean>(false);
  const [creatingTeam, setCreatingTeam] = useState(false);
  const [createTeamIsSuccess, setCreateTeamIsSuccess] = useState(false);

  const entityNameFilled = !!entityName;
  const orgNameFilled = !!orgName;
  const {setSelectedAccount, selectedAccount} = useContext(
    AccountSelectorContext
  );
  const isCreatingNewAccount =
    selectedAccount?.accountType === AccountType.Personal;
  const isEnterprise = selectedAccount?.isEnterprise ?? false;
  const {
    usingExternalStorage,
    setUsingExternalStorage,
    isExternalStorageEnabled,
    bucketInfo,
    setBucketInfo,
    getInitialBucketInfo,
    isValidState,
    storageBucketInfoOptions,
    cloudProviderOptions,
    formIsReady: formIsReadyProp,
    setOrganizationId,
    setIsExternalStorageEnabled,
    resetBucketInfo,
  } = useStorageBucketConfig();

  useEffect(() => {
    setOrganizationId(isCreatingNewAccount ? null : selectedAccount?.id ?? '');
    setUsingExternalStorage(false);
  }, [
    selectedAccount?.id,
    isCreatingNewAccount,
    setOrganizationId,
    setUsingExternalStorage,
  ]);
  const selectedUserOrg = useMemo(
    () => userOrganizations.find(o => o.id === selectedAccount?.id) ?? null,
    [selectedAccount?.id, userOrganizations]
  );
  const viewerNotAdminOfSelectedOrg =
    selectedUserOrg != null &&
    viewer != null &&
    !selectedUserOrg.members.some(m => m.id === viewer.id && m.admin);

  useEffect(() => {
    if (!isEnterprise) {
      setIsExternalStorageEnabled(false);
      setUsingExternalStorage(false);
      return;
    }
    setIsExternalStorageEnabled(isEnterprise);
  }, [
    setIsExternalStorageEnabled,
    setUsingExternalStorage,
    isEnterprise,
    selectedAccount,
  ]);

  useEffect(() => {
    if (entityName && isCreatingNewAccount) {
      setOrgName(`${entityName}-org`);
    }
  }, [entityName, isCreatingNewAccount]);

  useEffect(() => {
    setCreateTeamIsSuccess(false);
    setNewTeamOrgId(undefined);
    setSuccessfulInviteNames(new Set([]));
  }, [entityName]);

  useEffect(() => {
    if (!isCreatingNewAccount) {
      setOrgName(selectedAccount?.name ?? '');
    }
  }, [selectedAccount, isCreatingNewAccount]);

  useEffect(() => {
    if (!isCreatingNewAccount) {
      const teamType =
        selectedAccount?.accountType === AccountType.Academic
          ? 'academic'
          : 'work';
      setSelectedTeamType(teamType);
    }
  }, [selectedAccount, isCreatingNewAccount, selectedTeamType]);

  const {
    loading: loadingAvailability,
    isEntityAvailable,
    isOrgAvailable,
  } = useAvailability({
    entityName,
    orgName,
  });

  const isTeamNameAvailable =
    isEntityAvailable && (isOrgAvailable || !isCreatingNewAccount);

  useEffect(() => {
    if (!loadingAvailability && entityNameFilled && !isTeamNameAvailable) {
      setErrorMsg(TEAM_NAME_TAKEN_MESSAGE);
      return;
    }
    setErrorMsg(null);
  }, [
    selectedTeamType,
    entityNameFilled,
    isEntityAvailable,
    orgNameFilled,
    isOrgAvailable,
    loadingAvailability,
    isTeamNameAvailable,
  ]);

  const academicTeamLink: ReactNode = useMemo(() => {
    const teamName = userOrganizations.find(
      o => (isAcademicOrg(o) || isAcademicTrialOrg(o)) && o.teams.length > 0
    )?.teams[0].name;

    if (teamName == null) {
      return null;
    }

    return [
      <TargetBlank key={0} href={`/${teamName}`}>
        {teamName}
      </TargetBlank>,
    ];
  }, [userOrganizations]);

  useEffect(() => {
    const noTeamsLeft =
      selectedTeamType === 'academic' && academicTeamLink != null;
    if (noTeamsLeft) {
      track.noTeamsLeftViewed({teamType: selectedTeamType});
    }
  }, [selectedTeamType, academicTeamLink, analyticsLocation, track]);

  const submittedOrgName = useMemo(() => {
    if (orgNameFilled) {
      return orgName;
    }
    return null;
  }, [orgNameFilled, orgName]);

  const onClickAcademic = useCallback(() => {
    track.teamTypeSelected({teamType: 'academic'});
    setSelectedTeamType('academic');
  }, [track]);

  const onClickWork = useCallback(() => {
    track.teamTypeSelected({teamType: 'work'});
    setSelectedTeamType('work');
  }, [track]);
  const orgsWithNoRemainingTeamSlotsMap: Map<string, ReactNode[]> =
    useMemo(() => {
      const orgsWithNoRemainingTeamSlots = userOrganizations.filter(o => {
        const maxTeams: number =
          o.subscriptions[0]?.privileges?.num_private_teams ?? 1;

        const teamCount = o.teams.length;
        return teamCount >= maxTeams;
      });
      return new Map(
        orgsWithNoRemainingTeamSlots.map(o => [
          o.id,
          o.teams.map((t, i) => (
            <React.Fragment key={t.id}>
              {i > 0 && `, `}
              <TargetBlank href={`/${t.name}`}>{t.name}</TargetBlank>
            </React.Fragment>
          )),
        ])
      );
    }, [userOrganizations]);
  const noRemainingTeamSlotsTeamLinks =
    selectedAccount?.id != null
      ? orgsWithNoRemainingTeamSlotsMap.get(selectedAccount?.id) ?? null
      : null;

  const [createAcademicOrReverseTrial] =
    useCreateAcademicOrReverseTrialMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });
  const createReverseTrialTeam = useCallback(async () => {
    try {
      const res = await createAcademicOrReverseTrial({
        variables: {
          newOrganizationName: orgName ?? '',
          newTeamName: entityName ?? '',
          isAcademic: false,
        },
      });

      // Handle possible graphql errors. This is less common than the exception case below,
      // but can, in theory, still happen.
      if (res.errors != null) {
        handleGQLErrors({
          analyticsLocation,
          errors: res.errors,
          errLabel: 'CreateTeam-submitWorkTrialTeam',
          errExtra: {orgName, entityName},
          trackingEventName: `Create Team Error Viewed`,
          trackingOrgName: submittedOrgName,
          setErrorMsg,
        });
        return '';
      }
      window.analytics?.track('Trial Team Submitted', {
        organizationName: submittedOrgName,
        entityName,
        location: analyticsLocation,
      });
      return res.data?.createAcademicOrReverseTrial?.orgID ?? '';
    } catch (err) {
      handleMutationError({
        analyticsLocation,
        err,
        errLabel: 'CreateTeam-submitWorkTrialTeam',
        errExtra: {orgName, entityName},
        trackingEventName: `Create Team Error Viewed`,
        trackingOrgName: submittedOrgName,
        setErrorMsg,
      });
      throw err;
    }
  }, [
    entityName,
    orgName,
    createAcademicOrReverseTrial,
    submittedOrgName,
    analyticsLocation,
  ]);

  const [createInviteMutation] = useCreateInviteMutation({
    context: {...propagateErrorsContext(), ...doNotRetryContext()},
  });

  const textareaInputRef = useRef<HTMLTextAreaElement>(null);
  const [bulkTextInput, setBulkTextInput] = useState<string>('');
  const [newTeamOrgId, setNewTeamOrgId] = useState<string | undefined>();
  const [successfulInviteNames, setSuccessfulInviteNames] = useState<
    Set<string>
  >(new Set([]));

  const onNoTeamsLeftClick = useCallback(() => {
    track.noTeamsLeftClicked({teamType: selectedTeamType});
  }, [selectedTeamType, track]);

  const createInvites = useCallback(async () => {
    const trimmedInputs =
      bulkTextInput
        .split(/[,\n]/)
        .map(e => e.trim())
        .filter(e => e !== '') ?? null;

    const inputs = Array.from(new Set(trimmedInputs));

    if (inputs == null || inputs.length === 0) {
      return;
    }

    try {
      const createInvitePromises = inputs
        .filter(input => !successfulInviteNames.has(input))
        .map(input => {
          const key = isValidEmail(input) ? 'email' : 'username';
          return createInviteMutation({
            variables: {
              entityName,
              [key]: input,
              admin: false,
            },
          });
        });

      const errors: string[] = [];
      const createInviteResps = await Promise.allSettled(createInvitePromises);
      const validInviteNames: string[] = [];
      createInviteResps.forEach((resp, i) => {
        if (resp.status === 'rejected') {
          const error = getErrMsgFromError(resp.reason);
          if (error) {
            errors.push(error);
          }
        } else {
          validInviteNames.push(inputs[i]);
        }
      });

      if (validInviteNames.length < inputs.length) {
        setErrorMsg(errors.join(', '));
        setSuccessfulInviteNames(
          new Set([...successfulInviteNames, ...validInviteNames])
        );
      }
      if (errors.length > 0) {
        const collatedErrMsg = errors.join(', ');
        captureError(collatedErrMsg, 'CreateTeam-inviteTeammates', {
          extra: {inputs, entityName},
        });
        track.inviteErrorViewed({
          organizationName: orgName,
          error: collatedErrMsg,
        });
        throw new Error(collatedErrMsg);
      }
      track.inviteSubmitted({
        organizationName: orgName,
        entityName,
      });
    } catch (err) {
      captureError(err as Error, 'CreateTeam-inviteTeammates', {
        extra: {inputs, entityName},
      });
      const errMsg = getErrMsgFromError(err);
      setErrorMsg(
        `Error inviting team members ${
          errMsg != null ? ` (${errMsg})` : ''
        }. Please try again.`
      );
      track.inviteErrorViewed({organizationName: orgName, error: errMsg});
      throw err;
    }
  }, [
    bulkTextInput,
    successfulInviteNames,
    orgName,
    entityName,
    createInviteMutation,
    track,
  ]);

  const createTeamDisabled =
    loadingAvailability ||
    !entityNameFilled ||
    (!loadingAvailability && !isEntityAvailable) ||
    (selectedTeamType === 'work' &&
      isCreatingNewAccount &&
      (!orgNameFilled || (!loadingAvailability && !isOrgAvailable))) ||
    (selectedTeamType === 'work' &&
      !isCreatingNewAccount &&
      (noRemainingTeamSlotsTeamLinks != null || viewerNotAdminOfSelectedOrg)) ||
    (selectedTeamType === 'academic' &&
      isCreatingNewAccount &&
      ((!loadingAvailability && !isTeamNameAvailable) ||
        !agreedToAcademicPolicy ||
        academicTeamLink != null));

  const [isStorageValid, setIsStorageValid] = useState(true);
  useEffect(() => {
    if (!usingExternalStorage) {
      setIsStorageValid(true);
      return;
    }
    setIsStorageValid(isValidState.state === 'valid');
  }, [setUsingExternalStorage, usingExternalStorage, isValidState]);

  const [createTeamMutation] = useCreateTeamMutation({
    context: {...propagateErrorsContext(), ...doNotRetryContext()},
  });
  const createTeam = useCallback(async () => {
    try {
      const res = await createTeamMutation({
        variables: {
          teamName: entityName ?? '',
          organizationId: selectedAccount?.id ?? '',
          storageBucketInfo: bucketInfo,
        },
      });

      // Handle possible graphql errors. This is less common than the exception case below,
      // but can, in theory, still happen.
      if (res.errors != null) {
        handleGQLErrors({
          analyticsLocation,
          errors: res.errors,
          errLabel: 'CreateTeam-submitTeam',
          errExtra: {orgName: submittedOrgName, entityName},
          trackingEventName: `Create Team Error Viewed`,
          trackingOrgName: submittedOrgName,
          setErrorMsg,
        });
        return;
      }
      track.teamSubmitted({
        organizationName: submittedOrgName,
        entityName,
      });
    } catch (err) {
      handleMutationError({
        analyticsLocation,
        err,
        errLabel: 'CreateTeam-submitTeam',
        errExtra: {orgName: submittedOrgName, entityName},
        trackingEventName: `Create Team Error Viewed`,
        trackingOrgName: submittedOrgName,
        setErrorMsg,
      });
      throw err;
    }

    setErrorMsg(null);
  }, [
    entityName,
    selectedAccount?.id,
    submittedOrgName,
    bucketInfo,
    createTeamMutation,
    analyticsLocation,
    track,
  ]);
  const onClickCreateTeam = useCallback(async () => {
    track.createTeamClicked({teamType: selectedTeamType});
    if (creatingTeam) {
      return;
    }
    if (!entityNameFilled || !entityName) {
      setErrorMsg('You must set a team name');
      return;
    }
    if (!isEntityAvailable || !isTeamNameAvailable) {
      setErrorMsg(TEAM_NAME_TAKEN_MESSAGE);
      return;
    }

    setCreatingTeam(true);
    try {
      let newOrgId = selectedAccount?.id;
      if (!createTeamIsSuccess) {
        // create the appropriate team type
        if (isCreatingNewAccount && orgNameFilled) {
          newOrgId = await createReverseTrialTeam();
        } else if (!isCreatingNewAccount) {
          await createTeam();
        } else {
          setErrorMsg('Error creating team');
          return;
        }
        if (newOrgId === '') {
          setErrorMsg('Error creating team');
          return;
        }
        setCreateTeamIsSuccess(true);
        setNewTeamOrgId(newOrgId);
      }
      try {
        await createInvites();
      } catch (err) {
        captureError(err as Error, 'CreateTeam-inviteTeammates');
        return;
      }

      // Update viewer in redux state with newly created team
      dispatch(reloadCurrentViewer());
      const createTeamData: CreateTeamData = {
        teamEntityName: entityName,
        orgName: submittedOrgName ?? '',
        teamType: selectedTeamType,
        storageBucketInfo: bucketInfo,
      };
      if (isCreatingNewAccount) {
        const newAccount = {
          name: submittedOrgName ?? '',
          accountType: AccountType.Corporate,
          id: newTeamOrgId ?? newOrgId, // if we failed createInvite before and already created team
          memberCount: 1,
          username: viewer?.username ?? '',
        };
        toggleTeamDrawer();
        setSelectedAccount(newAccount);
        if (selectedTeamType === 'academic') {
          history.push(ACADEMIC_APPLICATION_PATH);
        }
        return createTeamData;
      }
      toggleTeamDrawer();
      history.push(`/${entityName}?newTeam=true`);
      return createTeamData;
    } catch (err) {
      captureError(err, 'CreateTeam-createTeam');
      return;
    } finally {
      setCreatingTeam(false);
    }
  }, [
    isTeamNameAvailable,
    selectedTeamType,
    creatingTeam,
    entityNameFilled,
    entityName,
    isEntityAvailable,
    createTeamIsSuccess,
    orgNameFilled,
    dispatch,
    toggleTeamDrawer,
    submittedOrgName,
    createReverseTrialTeam,
    bucketInfo,
    createInvites,
    setSelectedAccount,
    track,
    createTeam,
    isCreatingNewAccount,
    selectedAccount?.id,
    newTeamOrgId,
    setNewTeamOrgId,
    viewer?.username,
  ]);

  if (envIsLocal) {
    return <Redirect to={'/home'} />;
  }

  const accountTypeCard = (
    <S.AccountTypeCard
      isActive={academicTeamLink == null}
      isSelected={selectedTeamType === 'academic'}
      onClick={academicTeamLink == null ? onClickAcademic : undefined}>
      <S.AccountTypeInnerContent>
        <S.AccountTypeTitle isDisabled={academicTeamLink != null}>
          Academic
          <S.AccountTypeItalic isDisabled={academicTeamLink != null}>
            {' (limit 1 per user)'}
          </S.AccountTypeItalic>
        </S.AccountTypeTitle>
        <S.AccountTypeSubtitle isDisabled={academicTeamLink != null}>
          For academic research and projects outside of corporate use.
        </S.AccountTypeSubtitle>
      </S.AccountTypeInnerContent>
      {selectedTeamType === 'academic' && <S.AccountTypeIcon name="check" />}
    </S.AccountTypeCard>
  );

  const externalStorageCard = (
    <S.AccountTypeCard
      data-test="external-storage-radio"
      isSelected={usingExternalStorage}
      onClick={() => {
        if (isExternalStorageEnabled) {
          setUsingExternalStorage(true);
        }
      }}
      isActive={isExternalStorageEnabled}>
      <S.AccountTypeInnerContent>
        <S.ExternalStorageTitle>
          <S.AccountTypeTitle>External storage</S.AccountTypeTitle>
          <PremiumFeatureTooltip
            isPremiumFeatureDisabled={!isExternalStorageEnabled}
          />
        </S.ExternalStorageTitle>
        <S.AccountTypeSubtitle>
          Use another cloud provider as storage{' '}
        </S.AccountTypeSubtitle>
      </S.AccountTypeInnerContent>
      {usingExternalStorage && <S.AccountTypeIcon name="check" />}
      {!isEnterprise && <S.IconLock />}
    </S.AccountTypeCard>
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <ResizableDrawer
        startWidth={width}
        minWidth={400}
        onSetWidth={onSetWidth}>
        <C.DrawerContents scrolled={scrolled}>
          <C.TopPanel>
            <C.HeaderRow>
              <C.Header>
                <div data-test={loadingAvailability ? 'loading' : ''}> </div>
                {isCreatingNewAccount
                  ? 'Create a new account'
                  : 'Create a new team'}
              </C.Header>
              <C.Close>
                <Button
                  onClick={onClose}
                  icon="close"
                  size="large"
                  variant="ghost"
                />
              </C.Close>
            </C.HeaderRow>
            <C.UnderLine />
            {errorMsg && (
              <S.BannerWrapper
                variant="error"
                data-test="create-team-errors"
                eventData={{location: analyticsLocation}}>
                <BannerFlexWrapper>
                  <div className="text-xs">
                    <S.Icon name="warning" />
                  </div>
                  <S.BannerTextWrapper>{errorMsg}</S.BannerTextWrapper>
                </BannerFlexWrapper>
              </S.BannerWrapper>
            )}
            {noRemainingTeamSlotsTeamLinks != null && (
              <S.BannerWrapper
                variant="error"
                eventData={{location: analyticsLocation}}>
                <BannerFlexWrapper>
                  <div className="text-xs">
                    <S.Icon name="warning" />
                  </div>
                  <S.BannerTextWrapper>
                    {`Your organization ${
                      selectedUserOrg?.name ?? ''
                    } already has
                    ${noRemainingTeamSlotsTeamLinks.length} team(s) (`}
                    {noRemainingTeamSlotsTeamLinks}
                    {`), and on the
                    ${selectedUserOrg?.subscriptions[0]?.plan.displayName ?? ''}
                    plan you can only create
                    ${
                      selectedUserOrg?.subscriptions[0]?.privileges
                        ?.num_private_teams ?? 1
                    }
                    team(s). To unlock more teams, `}
                    <S.ContactSalesLink
                      href={urls.pricing()}
                      onClick={onNoTeamsLeftClick}>
                      upgrade
                      <S.ContactSalesIcon name="forward-next" />
                    </S.ContactSalesLink>
                  </S.BannerTextWrapper>
                </BannerFlexWrapper>
              </S.BannerWrapper>
            )}
            {viewerNotAdminOfSelectedOrg && (
              <S.BannerWrapper
                variant="error"
                eventData={{location: analyticsLocation}}>
                <BannerFlexWrapper>
                  <div className="text-xs">
                    <S.Icon name="warning" />
                  </div>
                  <S.BannerTextWrapper>
                    Please ask your organization administrator to create a new
                    team.
                  </S.BannerTextWrapper>
                </BannerFlexWrapper>
              </S.BannerWrapper>
            )}

            <S.FormRow>
              <C.Label>
                {isCreatingNewAccount ? 'Account Name' : 'Team Name'}
              </C.Label>
              <div data-test="team-name-input">
                <TextField
                  value={entityName ?? ''}
                  onChange={(value: string) => setEntityName(value)}
                  placeholder={
                    isCreatingNewAccount ? 'account-name' : 'team-name'
                  }
                  prefix="wandb.ai/"
                />
              </div>
            </S.FormRow>
            <S.WarningMessage>
              Choose your team name carefully! It cannot be changed after
              creation.
            </S.WarningMessage>
            {isCreatingNewAccount && (
              <S.FormRow>
                <C.Label>Account Type</C.Label>
                <S.AccountTypeCards>
                  <S.AccountTypeCard
                    isSelected={selectedTeamType === 'work'}
                    isActive={true}
                    onClick={onClickWork}>
                    <S.AccountTypeInnerContent>
                      <S.AccountTypeTitle>Work</S.AccountTypeTitle>
                      <S.AccountTypeSubtitle>
                        For users at companies, collaborating on ML projects at
                        work.
                      </S.AccountTypeSubtitle>
                    </S.AccountTypeInnerContent>
                    {selectedTeamType === 'work' && (
                      <S.AccountTypeIcon name="check" />
                    )}
                  </S.AccountTypeCard>
                  {academicTeamLink != null ? (
                    <S.Tooltip
                      content={
                        "You're already part of an academic account. Please leave or delete your existing academic account if you wish to create a new one."
                      }
                      basic
                      inverted
                      position="top center"
                      wide
                      trigger={accountTypeCard}
                    />
                  ) : (
                    accountTypeCard
                  )}
                </S.AccountTypeCards>
              </S.FormRow>
            )}
            {!isCreatingNewAccount && (
              <StorageBucketInfoInput
                usingExternalStorage={usingExternalStorage}
                setUsingExternalStorage={setUsingExternalStorage}
                setIsExternalStorageEnabled={setIsExternalStorageEnabled}
                isExternalStorageEnabled={isExternalStorageEnabled}
                bucketInfo={bucketInfo}
                setBucketInfo={setBucketInfo}
                getInitialBucketInfo={getInitialBucketInfo}
                isValidState={isValidState}
                storageBucketInfoOptions={storageBucketInfoOptions}
                cloudProviderOptions={cloudProviderOptions}
                formIsReady={formIsReadyProp}
                resetBucketInfo={resetBucketInfo}
                setOrganizationId={setOrganizationId}
                storageLabelOptions={
                  <S.FormRow>
                    <C.Label>Storage Type</C.Label>
                    <S.StorageTypeCards>
                      <S.AccountTypeCard
                        isSelected={!usingExternalStorage}
                        onClick={() => setUsingExternalStorage(false)}
                        isActive={true}>
                        <S.AccountTypeInnerContent>
                          <S.AccountTypeTitle>
                            W&B cloud storage
                          </S.AccountTypeTitle>
                          <S.AccountTypeSubtitle>
                            Store this team’s data in W&B cloud storage
                          </S.AccountTypeSubtitle>
                        </S.AccountTypeInnerContent>
                        {!usingExternalStorage && (
                          <S.AccountTypeIcon name="check" />
                        )}
                      </S.AccountTypeCard>
                      {externalStorageCard}
                    </S.StorageTypeCards>
                    {isEnterprise && (
                      <S.StorageWarningMessage>
                        Storage settings cannot be changed after team creation.
                        <Button
                          variant="ghost"
                          size="small"
                          endIcon="chevron-next"
                          onClick={e => {
                            e.preventDefault();
                            window.open(
                              'https://docs.wandb.ai/guides/app/features/teams#secure-storage-connector'
                            );
                          }}>
                          See documentation
                        </Button>
                      </S.StorageWarningMessage>
                    )}
                  </S.FormRow>
                }
              />
            )}
            <S.FormRow>
              <C.Label>
                Invite team members
                <C.OptionalLabel>{' (optional)'}</C.OptionalLabel>
              </C.Label>
              <S.InputWrapper visible>
                <S.MultilineInputText
                  data-test="invite-teammates-textarea"
                  ref={textareaInputRef}
                  placeholder="Separate emails or usernames with commas"
                  onChange={event => {
                    setBulkTextInput(event.target.value);
                  }}
                  value={bulkTextInput}
                />
              </S.InputWrapper>
            </S.FormRow>
            {selectedTeamType === 'academic' && academicTeamLink == null && (
              <>
                <S.AgreeCheckbox
                  label={
                    <label>
                      I understand that academic and open-source teams are only
                      available for non-corporate use.{' '}
                      <TargetBlank
                        href={termsOfService()}
                        onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                        See Terms of Service
                      </TargetBlank>
                    </label>
                  }
                  checked={agreedToAcademicPolicy}
                  onChange={() => setAgreedToAcademicPolicy(prev => !prev)}
                />
              </>
            )}
          </C.TopPanel>
          <Button
            className="w-full"
            data-test="submit-create-team"
            variant="primary"
            size="large"
            onClick={onClickCreateTeam}
            disabled={creatingTeam || createTeamDisabled || !isStorageValid}>
            Create team
          </Button>
        </C.DrawerContents>
      </ResizableDrawer>
    </Drawer>
  );
};

export const CreateTeamDrawer = memo(CreateTeamDrawerComp);
CreateTeamDrawer.displayName = 'CreateTeamDrawer';
