import * as String from '@wandb/weave/common/util/string';
import React, {FC, memo, MouseEvent, useCallback, useMemo} from 'react';
import {useLocation} from 'react-router';

import {envIsPublicCloud} from '../../../../config';
import {
  useFetchJobQuery,
  useUpsertModelMutation,
} from '../../../../generated/graphql';
import history from '../../../../util/history';
import {trackBreadcrumbClicked} from '../../../../util/navigation';
import {AccessOption} from '../../../../util/permissions';
import {cleanTabName} from '../../../../util/string';
import {getSweepDisplayName} from '../../../../util/sweeps';
import * as links from '../../../../util/urls';
import {removeNullUndefinedOrFalse} from '../../../../util/utility';
import AccessSettingOptions from '../../../AccessSettingOptions';
import Breadcrumbs, {BreadcrumbPart} from '../../../Breadcrumbs';
import {useNavContext} from '../../../NavContextProvider';
import {ProjObjType} from './utils';

type NavBreadcrumbsDefaultProps = {
  entityName: string;
  reportName: string;
  reportID: string;
  projObjType: ProjObjType | undefined;
};

const NavBreadcrumbsDefaultComp: FC<NavBreadcrumbsDefaultProps> = ({
  entityName,
  reportName,
  reportID,
  projObjType,
}) => {
  const {
    searchData: data,
    params: {
      projectName,
      tab,
      artifactTypeName,
      artifactCollectionName,
      artifactCommitHash,
      artifactTab,
      filePath,
      groupName,
      jobId,
      jobTab,
      queueId,
      reportNameAndID,
      runName,
    },
  } = useNavContext();
  const {pathname} = useLocation();
  const isEditingReport =
    pathname.includes('/reports/') && pathname.endsWith('/edit');

  const draftId = new URLSearchParams(window.location.search).get('draftId');
  const queryJob = useFetchJobQuery({
    variables: {
      jobId: jobId ?? '',
    },
    skip: jobId == null,
  });

  const project = data?.project;
  const sweepName = project?.sweep?.name;
  const sweepDisplayName = getSweepDisplayName(project?.sweep);
  const jobDisplayName = queryJob?.data?.artifactCollection?.name;
  const isBenchmark = project?.isBenchmark;
  const runDisplayName = project?.run?.displayName;

  const [upsertModelMutation] = useUpsertModelMutation();
  const updateProjectAccess = useCallback(
    async (access: AccessOption) => {
      if (projectName != null && project != null) {
        await upsertModelMutation({
          variables: {
            entityName,
            name: projectName,
            access,
          },
        });
      }
    },
    [upsertModelMutation, entityName, projectName, project]
  );

  // HAXX: would be better if we have a way to get the user
  //       with the entityName and get the accountType of the user
  const isAnonymous =
    entityName.lastIndexOf('anony-moose-', 0) === 0 ||
    entityName.lastIndexOf('anony-mouse-', 0) === 0;

  const projObjName =
    groupName || sweepName || jobId || reportNameAndID || runName;

  const tabName = tab === 'reportlist' ? 'reports' : tab;
  const filePathParts = links.parseRunTabPath(filePath);

  const parts: BreadcrumbPart[] = useMemo(
    () =>
      removeNullUndefinedOrFalse([
        isBenchmark && {
          label: `Benchmarks`,
          href: envIsPublicCloud ? `/site/benchmarks` : undefined,
        },

        {
          label: entityName,
          to: !isAnonymous ? `/${entityName}` : undefined,
          onClick: !isAnonymous
            ? () => trackBreadcrumbClicked('Entity')
            : undefined,
        },

        queueId != null && {
          label: `Launch`,
          to: links.launchOverview(),
          onClick: () => trackBreadcrumbClicked('Launch'),
        },

        projectName != null && {
          label: `Projects`,
          to: `/${entityName}/projects`,
          onClick: () => trackBreadcrumbClicked('Projects'),
        },

        projectName != null && {
          beforeLabel: project != null && updateProjectAccess != null && (
            <AccessSettingOptions
              accessSettings={{
                project: {
                  id: project.id,
                  name: project.name,
                  access: project.access,
                  user: project.user,
                },
                entity: project.entity,
              }}
              compact
              updateProjectAccess={updateProjectAccess}
            />
          ),
          label: projectName,
          to: links.project({entityName, name: projectName}),
          onClick: () => trackBreadcrumbClicked('Project'),
        },

        ...getLaunchBreadcrumbParts({
          entityName,
          projectName,
          sweepName,
          sweepDisplayName,
          jobId,
          jobDisplayName,
          jobTab,
        }),

        projectName != null &&
          runName != null && {
            label: `Runs`,
            to: links.project({entityName, name: projectName}),
            onClick: () => trackBreadcrumbClicked('Runs'),
          },
        projectName != null &&
          runName != null && {
            label: runDisplayName || runName,
            to: links.run({entityName, projectName, name: runName}),
            onClick: () => {
              let itemClicked = 'Run';
              if (groupName != null) {
                itemClicked = 'Group run';
              } else if (sweepName != null) {
                itemClicked = 'Sweep run';
              }
              trackBreadcrumbClicked(itemClicked);
            },
          },

        projectName != null &&
          groupName != null && {
            label: `Run groups`,
          },
        projectName != null &&
          groupName != null && {
            label: groupName,
            to: links.runGroup({entityName, projectName, name: groupName}),
            onClick: () => trackBreadcrumbClicked('Group'),
          },

        projectName != null &&
          reportNameAndID != null && {
            label: `Reports`,
            to: links.reportList({entityName, projectName}),
            onClick: () => trackBreadcrumbClicked('Reports'),
          },
        projectName != null &&
          reportNameAndID != null && {
            label: reportName,
            to:
              isEditingReport && draftId != null
                ? links.reportEdit(
                    {
                      entityName,
                      projectName,
                      reportID,
                      reportName,
                    },
                    `?draftId=${draftId}`
                  )
                : links.reportView({
                    entityName,
                    projectName,
                    reportID,
                    reportName,
                  }),
            onClick: () => trackBreadcrumbClicked('Report'),
          },

        projectName != null &&
          tabName != null &&
          tab != null &&
          jobTab == null && {
            label: cleanTabName(tabName),
            to: links.projectObjTab(
              entityName,
              projectName,
              projObjType,
              projObjName,
              tab
            ),
            onClick: () => {
              if (tabName === 'artifacts') {
                trackBreadcrumbClicked(String.capitalizeFirst(tabName));
              }
            },
          },

        ...getArtifactBreadcrumbParts({
          entityName,
          projectName,
          artifactTypeName,
          artifactCollectionName,
          artifactCommitHash,
          artifactTab,
          filePathParts,
        }),

        ...(runName != null && filePathParts != null
          ? filePathParts.map((p, i) => ({
              label: p,
              // We have to explicitly use an 'a' element here.
              // React-router's NavLink element runs the inconsistent URI decode when creating
              // an 'a' element under the hood, and when a user clicks the 'a' element, then
              // it decodes again because NavLink uses history.push().
              // So, we have double decoding going on

              // We want the link to appear correctly when hovered over. If we don't
              // disable the historyHax here then we get our jank string-replaced link.
              href: links.runTabFile(
                entityName,
                projectName,
                runName!,
                filePathParts.slice(0, i + 1),
                true
              ),

              onClick: (e: MouseEvent) => {
                e.preventDefault();
                history.push(
                  links.runTabFile(
                    entityName,
                    projectName,
                    runName!,
                    filePathParts.slice(0, i + 1)
                  )
                );
              },
            }))
          : []),
      ]),
    [
      isBenchmark,
      entityName,
      isAnonymous,
      queueId,
      projectName,
      project,
      updateProjectAccess,
      sweepName,
      sweepDisplayName,
      jobId,
      jobDisplayName,
      jobTab,
      runName,
      runDisplayName,
      groupName,
      reportNameAndID,
      reportName,
      isEditingReport,
      reportID,
      tabName,
      tab,
      projObjType,
      projObjName,
      artifactTypeName,
      artifactCollectionName,
      artifactCommitHash,
      artifactTab,
      filePathParts,
      draftId,
    ]
  );

  return <Breadcrumbs parts={parts} />;
};

const NavBreadcrumbsDefault = memo(NavBreadcrumbsDefaultComp);

export default NavBreadcrumbsDefault;

type GetLaunchBreadcrumbPartsParams = {
  entityName: string;
  projectName: string | undefined;
  sweepName: string | undefined;
  sweepDisplayName: string;
  jobId: string | undefined;
  jobDisplayName: string | undefined;
  jobTab: string | undefined;
};

function getLaunchBreadcrumbParts({
  entityName,
  projectName,
  sweepName,
  sweepDisplayName,
  jobId,
  jobDisplayName,
  jobTab,
}: GetLaunchBreadcrumbPartsParams): BreadcrumbPart[] {
  if (projectName == null) {
    return [];
  }

  return removeNullUndefinedOrFalse([
    sweepName != null && {
      label: `Sweeps`,
      to: links.projectSweeps({entityName, name: projectName}),
      onClick: () => trackBreadcrumbClicked('Sweeps'),
    },
    sweepName != null &&
      sweepDisplayName != null && {
        label: sweepDisplayName,
        to: links.sweep({entityName, projectName, sweepName}),
        onClick: () => trackBreadcrumbClicked('Sweep'),
      },

    jobId != null && {
      label: `Jobs`,
      to: links.jobs({entityName, projectName}),
      onClick: () => trackBreadcrumbClicked('Jobs'),
    },
    jobId != null &&
      jobDisplayName != null && {
        label: jobDisplayName,
        to: links.job({entityName, projectName, jobId}),
        onClick: () => trackBreadcrumbClicked('Job'),
      },
    jobId != null &&
      jobDisplayName != null &&
      jobTab != null && {
        label: cleanTabName(jobTab),
        to: links.job({entityName, projectName, jobId, jobTab}),
        onClick: () => trackBreadcrumbClicked('JobTab'),
      },
  ]);
}

type GetArtifactBreadcrumbPartsParams = {
  entityName: string;
  projectName: string | undefined;
  artifactTypeName: string | undefined;
  artifactCollectionName: string | undefined;
  artifactCommitHash: string | undefined;
  artifactTab: string | undefined;
  filePathParts: string[];
};

function getArtifactBreadcrumbParts({
  entityName,
  projectName,
  artifactTypeName,
  artifactCollectionName,
  artifactCommitHash,
  artifactTab,
  filePathParts,
}: GetArtifactBreadcrumbPartsParams): BreadcrumbPart[] {
  if (projectName == null || artifactTypeName == null) {
    return [];
  }

  return removeNullUndefinedOrFalse([
    {
      label: artifactTypeName,
      to: links.artifactType({entityName, projectName, artifactTypeName}),
      onClick: () => trackBreadcrumbClicked('Artifact type'),
    },

    artifactCollectionName != null && {
      label: artifactCollectionName,
      to: links.artifactSequence({
        entityName,
        projectName,
        artifactTypeName,
        artifactSequenceName: artifactCollectionName,
      }),
      onClick: () => trackBreadcrumbClicked('Artifact collection'),
    },

    artifactCollectionName != null &&
      artifactCommitHash != null && {
        label: artifactCommitHash.slice(0, 9),
        to: links.artifact({
          entityName,
          projectName,
          artifactTypeName,
          artifactCollectionName,
          artifactCommitHash,
        }),
        onClick: () => trackBreadcrumbClicked('Artifact'),
      },

    artifactCollectionName != null &&
      artifactCommitHash != null &&
      artifactTab != null && {
        label: artifactTab,
        to: links.artifactTab({
          entityName,
          projectName,
          artifactTypeName,
          artifactCollectionName,
          artifactCommitHash,
          tabName: artifactTab,
        }),
      },

    ...(artifactCollectionName != null &&
    artifactCommitHash != null &&
    filePathParts != null
      ? filePathParts.map((p, i) => ({
          label: p,
          to: links.artifactFile({
            entityName,
            projectName,
            artifactTypeName,
            artifactSequenceName: artifactCollectionName,
            artifactCommitHash,
            path: filePathParts.slice(0, i + 1),
          }),
        }))
      : []),
  ]);
}
