import {Switch} from '@wandb/weave/components';
import {Button} from '@wandb/weave/components/Button';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import classNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import {useLayoutEffect, useRef} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Popup} from 'semantic-ui-react';

import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as RunTypes from '../../util/runTypes';
import {maybePluralize} from '../../util/uihelpers';
import {FilterKeySelectorCreatorProps} from '../Filters/FilterKeySelector';
import FilterList from '../Filters/FilterList';
import {FilterValueSelectorCreatorProps} from '../Filters/FilterValueSelector';

// Count of the active filters for the root filter\
export const activeFilterCount = (
  filter: FilterTypes.RootFilter<RunTypes.Key>
) => {
  if (filter.op === 'OR' && filter.filters) {
    return filter.filters[0].filters.filter(
      // TODO: display compound filters
      f => !f.disabled && Filter.isIndividual(f)
    ).length;
  } else {
    return 0;
  }
};

function filterCompare(
  v: FilterTypes.Filter<RunTypes.Key>,
  e: FilterTypes.Filter<RunTypes.Key>
) {
  return _.isEqual(_.omit(v, ['disabled']), _.omit(e, ['disabled']));
}

// Finds the index of the individual filter in the group filter
// NOTE: 1 depth of children only.
const filterIndex = (
  filters: FilterTypes.GroupFilter<RunTypes.Key>,
  checkFilter: FilterTypes.IndividualFilter<RunTypes.Key>
) => {
  if (filters.filters) {
    const index = _.findIndex(filters.filters, filter =>
      _.isEqualWith(filter, checkFilter, filterCompare)
    );
    return index;
  } else {
    return -1;
  }
};

// Checks if a group filter has a direct child that
// matches the individual filter that is enabled.
const hasEnabledFilter = (
  filters: FilterTypes.GroupFilter<RunTypes.Key>,
  checkFilter: FilterTypes.IndividualFilter<RunTypes.Key>
) => {
  const index = filterIndex(filters, checkFilter);
  if (index === -1) {
    return false;
  }

  return !filters.filters[index].disabled;
};

interface WBTableActionFilterButtonProps {
  className?: string;
  compact?: boolean;
  notificationOpen?: boolean;
  filtersOpen: boolean;
  filters: FilterTypes.RootFilter<RunTypes.Key>;
  isInReport?: boolean;
  onClick?(): void;
}

export const WBTableActionFilterButton = (
  props: WBTableActionFilterButtonProps
) => {
  const {filters, filtersOpen, notificationOpen, isInReport, ...rest} = props;
  const filterCount = activeFilterCount(filters);
  const passThroughProps = _.omit(props, 'compact', 'filtersOpen', 'filters');
  const buttonText =
    filterCount > 0 ? maybePluralize(filterCount, 'filter') : 'Filter';
  return (
    <span>
      <Popup
        open={notificationOpen}
        position="bottom left"
        basic
        pinned
        style={{
          top: 24,
          marginLeft: 1,
          padding: '4px 8px',
          cursor: 'pointer',
        }}
        inverted
        on="click"
        content="New filter applied"
        trigger={
          <Button
            active={filtersOpen || filterCount > 0}
            variant="secondary"
            icon="filter-alt"
            {...rest}
            data-test="filter-popup"
            aria-label="Filters"
            onClick={() => {
              passThroughProps.onClick?.();
              const actionLocation = isInReport ? 'report' : 'runs table';
              window.analytics?.track('Filter dropdown clicked', {
                location: actionLocation,
              });
            }}
            tooltip={props.compact ? 'Filter' : ''}
            tooltipProps={{className: 'py-8 px-12'}}
            className={classNames(props.className)}>
            {props.compact ? '' : buttonText}
          </Button>
        }
      />
    </span>
  );
};

interface WBTableActionFilterPickerProps {
  filters: FilterTypes.RootFilter<RunTypes.Key>;

  defaultToggleFilters?: FilterTypes.DefaultToggleFilter[];

  overflowFunc?: () => void;

  setFilters(filters: FilterTypes.RootFilter<RunTypes.Key>): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;

  filterValueSelector(
    props: FilterValueSelectorCreatorProps<RunTypes.Key>
  ): React.ReactNode;
}

export const WBTableActionFilterPicker = (
  props: WBTableActionFilterPickerProps
) => {
  const {filters, defaultToggleFilters, overflowFunc} = props;

  // Filter dropdown is a little jank in the custom charts editor.
  // We need to remount the dropdown when it juts out of the viewport,
  // so that we bring it back into view.
  const filterRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (filterRef.current == null || overflowFunc == null) {
      return;
    }
    const {top, left, right, bottom} =
      filterRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    if (
      right > viewportWidth ||
      bottom > viewportHeight ||
      left < 0 ||
      top < 0
    ) {
      overflowFunc();
    }
  });
  return (
    <div className="filter-popup" ref={filterRef}>
      {filters.filters.map((filter, i) => (
        <FilterList
          key={i}
          filters={filter}
          canAdd={true}
          index={i}
          pushFilter={(newFilter: FilterTypes.Filter<RunTypes.Key>) => {
            props.setFilters(Filter.Update.groupPush(filters, [i], newFilter));
          }}
          deleteFilter={(index: number) =>
            props.setFilters(Filter.Update.groupRemove(filters, [i], index))
          }
          setFilter={(
            index: number,
            f: FilterTypes.IndividualFilter<RunTypes.Key>
          ) =>
            props.setFilters(Filter.Update.setFilter(filters, [i, index], f))
          }
          filterKeySelector={props.filterKeySelector}
          filterValueSelector={props.filterValueSelector}
        />
      ))}
      {defaultToggleFilters && (
        <Tailwind>
          <div className="flex flex-col gap-8">
            {defaultToggleFilters.map((defaultFilter, i) => (
              <div key={i} className="flex justify-between">
                <span>{defaultFilter.label}</span>
                <Switch.Root
                  size="medium"
                  checked={hasEnabledFilter(
                    filters.filters[0],
                    defaultFilter.filter
                  )}
                  onCheckedChange={() => {
                    const index = filterIndex(
                      filters.filters[0],
                      defaultFilter.filter
                    );
                    const filter = filters.filters[0].filters[index];

                    if (index === -1) {
                      props.setFilters(
                        Filter.Update.groupPush(
                          filters,
                          [0],
                          defaultFilter.filter
                        )
                      );
                    } else {
                      props.setFilters(
                        Filter.Update.setFilter(filters, [0, index], {
                          ...defaultFilter.filter,
                          disabled: !filter.disabled,
                        } as FilterTypes.IndividualFilter<RunTypes.Key>)
                      );
                    }
                  }}>
                  <Switch.Thumb
                    size="medium"
                    checked={hasEnabledFilter(
                      filters.filters[0],
                      defaultFilter.filter
                    )}
                  />
                </Switch.Root>
              </div>
            ))}
          </div>
        </Tailwind>
      )}
    </div>
  );
};

interface WBTableActionFilterProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  trigger: (isOpen: boolean) => React.ReactNode;
  content: React.ReactNode;
  preventOverflow?: boolean;
}

export const WBTableActionFilter = (props: WBTableActionFilterProps) => {
  return (
    <Popup
      basic
      className="wb-table-action-popup"
      style={{
        maxWidth: 'none',
      }}
      on="click"
      position="bottom left"
      open={props.open}
      onOpen={() => props.setOpen(true)}
      onClose={() => props.setOpen(false)}
      trigger={props.trigger(props.open)}
      content={props.content}
      popperModifiers={{
        preventOverflow: {enabled: props.preventOverflow ?? false},
        flip: {enabled: false},
      }}
    />
  );
};
