import {avgPointsByBucket} from './buckets/avgPointsByBucket';
import {Line, Point} from './types';

/* This is for chunking the x axis and merging points in small windows of X */
export interface BucketSpec {
  minX: number;
  maxX: number;
  bucketCount: number;
  alignedWithPoints: boolean;
}

type BucketLinesResult = {
  bucketXValues: number[];
  mergedBuckets: number[][];
};

/**
 * We aggregate lines by first bucketing them.  This is important when there
 * is sampling or when the x values don't line up.
 */
export function bucketLines(
  lines: Line[],
  bucketSpec: BucketSpec
): BucketLinesResult {
  // Potential future refactoring: This way of passing around data is awful. These two
  // arrays should be stored together (eg type {xValue: number, mergedY: number[]})
  // this makes it less likely to make mistake when indexing into arrays.
  const bucketXValues: number[] = []; // midpoint of each bucket
  let mergedBuckets: number[][] = []; // array of values for each bucket

  if (lines.length === 0) {
    return {bucketXValues, mergedBuckets};
  }

  const {minX, maxX, bucketCount, alignedWithPoints} = bucketSpec;

  if (alignedWithPoints) {
    // Treat each x value as a bucket with 1 point from each line
    const firstLine = lines[0];
    for (let i = 0; i < firstLine.data.length; i++) {
      bucketXValues.push(firstLine.data[i].x);
      const yValueFromAllLines = lines.map(line => line.data[i].y);
      mergedBuckets.push(yValueFromAllLines);
    }
    return {
      bucketXValues,
      mergedBuckets,
    };
  }

  // get all the data points in aligned buckets
  const bucketedLines: number[][] = lines.map((line, j) =>
    avgPointsByBucket(line.data, bucketCount, minX, maxX)
  );

  // do a manual zip because lodash's zip is not like python
  bucketedLines.map((bucket, i) =>
    bucket.forEach((b, j) => {
      mergedBuckets[j] ? mergedBuckets[j].push(b) : (mergedBuckets[j] = [b]);
    })
  );

  // remove NaNs
  mergedBuckets = mergedBuckets.map((xBucket, i) =>
    xBucket.filter(y => isFinite(y))
  );

  const inc = (maxX - minX) / bucketCount;
  mergedBuckets.forEach(
    (xBucket, i) => (bucketXValues[i] = minX + (i + 0.5) * inc)
  );

  return {bucketXValues, mergedBuckets};
}

export function bucketLine(line: Line, bucketSpec: BucketSpec): Line {
  /* Takes a single line and buckets the xAxis */
  // TODO: Bucket aggregate vars
  const {minX, maxX, bucketCount, alignedWithPoints} = bucketSpec;

  if (alignedWithPoints) {
    return line;
  }

  const bucketedVals = avgPointsByBucket(line.data, bucketCount, minX, maxX);
  const bucketedMaxVals = avgPointsByBucket(
    line.data,
    bucketCount,
    minX,
    maxX,
    'y0'
  );

  const inc = (maxX - minX) / bucketCount;
  const bucketedData: Point[] = bucketedVals.map((val, i) => ({
    x: minX + (i + 0.5) * inc,
    y: val,
    y0: bucketedMaxVals[i],
  }));

  return {
    ...line,
    data: bucketedData,
  };
}
