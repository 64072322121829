import {getType} from 'typesafe-actions';

import {setInShallowClone} from '../../util/utility';
import * as ActionsInternal from './actionsInternal';
import * as GroupSelectionsActions from './groupSelections/actions';
import * as GroupSelectionsUtils from './groupSelections/utils';
import {handleUpdateViewSpec} from './handleUpdateViewSpec';
import {markSectionPanelsAutoImmutable} from './markSectionPanelsAuto';
import * as Normalize from './normalize';
import * as PanelActions from './panel/actions';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as ClearPanelsUtils from './panelBankConfig/clearPanelsUtils';
import * as PanelBankConfigUtils from './panelBankConfig/utils';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import * as PanelBankSectionConfigUtils from './panelBankSectionConfig/utils';
import {ActionType, ViewReducerState} from './reducerSupport';
import * as SectionActions from './section/actions';
import * as SectionUtils from './section/utils';
import * as WorkspaceSettingsActions from './workspaceSettings/actions';
import * as PanelGenUtils from './workspaceSettings/panelGenUtils';
import * as WorkspaceSettingsUtils from './workspaceSettings/utils';

const applyAndMakeInverseActionImmutableInner = (
  state: ViewReducerState,
  action: ActionType
): [ViewReducerState, ActionType] => {
  switch (action.type) {
    case getType(ActionsInternal.undoableUpdateViewSpec): {
      const {ref, prevSpec, newSpec} = action.payload;
      const newState = handleUpdateViewSpec(state, ref.id, newSpec);
      const inverseAction = ActionsInternal.undoableUpdateViewSpec(ref, {
        prevSpec: newSpec, //  Note how the specs are swapped for undo action
        newSpec: prevSpec,
      });
      return [newState, inverseAction];
    }

    case getType(GroupSelectionsActions.toggleSelection): {
      const {ref, run, depth} = action.payload;
      return GroupSelectionsUtils.toggleSelection(state, ref, run, depth);
    }

    case getType(PanelActions.setConfig): {
      const {ref, config, key} = action.payload;
      const prevPanel = state.parts[ref.type][ref.id];
      const newPanel = Object.assign({}, prevPanel, {config, key});
      const newState = setInShallowClone(
        state,
        ['parts', ref.type, ref.id],
        newPanel
      );
      const inverseAction = PanelActions.setConfig(
        action.payload.ref,
        prevPanel.config,
        prevPanel.key
      );
      return [newState, inverseAction];
    }

    case getType(PanelActions.updateConfig): {
      const {ref, configUpdate} = action.payload;
      const prevPanel = state.parts[ref.type][ref.id];
      const newPanelConfig = Object.assign({}, prevPanel.config, configUpdate);
      const newPanel = Object.assign({}, prevPanel, {
        config: newPanelConfig,
        key: undefined,
      });
      const newState = setInShallowClone(
        state,
        ['parts', ref.type, ref.id],
        newPanel
      );
      const inverseAction = PanelActions.setConfig(
        action.payload.ref,
        prevPanel.config,
        prevPanel.key
      );
      return [newState, inverseAction];
    }

    case getType(PanelBankConfigActions.addPanelsBySpec): {
      const {ref, specs} = action.payload;
      return PanelBankConfigUtils.addPanelsBySpec(state, ref, specs);
    }

    case getType(PanelBankConfigActions.clearAllPanels): {
      const {ref, workspaceSettingsRef} = action.payload;
      return ClearPanelsUtils.clearAllPanels(state, ref, workspaceSettingsRef);
    }

    case getType(PanelBankConfigActions.clearAllPanelsUndo): {
      const {ref, workspaceSettingsRef, sectionRefs, shouldAutoGeneratePanels} =
        action.payload;
      return ClearPanelsUtils.clearAllPanelsUndo(
        state,
        ref,
        workspaceSettingsRef,
        sectionRefs,
        shouldAutoGeneratePanels
      );
    }

    case getType(PanelBankConfigActions.clearAutoPanels): {
      const {ref, workspaceSettingsRef, expectedPanels} = action.payload;
      return ClearPanelsUtils.clearAutoPanels(
        state,
        ref,
        workspaceSettingsRef,
        expectedPanels
      );
    }

    case getType(PanelBankConfigActions.clearAutoPanelsUndo): {
      const {
        ref,
        workspaceSettingsRef,
        expectedPanels,
        sectionsAndPanels,
        shouldAutoGeneratePanels,
      } = action.payload;
      return ClearPanelsUtils.clearAutoPanelsUndo(
        state,
        ref,
        workspaceSettingsRef,
        expectedPanels,
        sectionsAndPanels,
        shouldAutoGeneratePanels
      );
    }

    case getType(PanelBankConfigActions.openOrCloseAllSections): {
      const {ref, isOpen} = action.payload;
      return PanelBankConfigUtils.updateSections(
        state,
        ref,
        curSectionConfig => {
          if (curSectionConfig.isOpen === isOpen) {
            return curSectionConfig;
          }
          return Object.assign({}, curSectionConfig, {isOpen});
        }
      );
    }

    case getType(PanelBankConfigActions.updateAllLinePlotSectionSettings): {
      const {ref, settings} = action.payload;
      return PanelBankConfigUtils.updateAllLinePlotSectionSettings(
        state,
        ref,
        settings
      );
    }

    case getType(PanelBankConfigActionsInternal.setAllSections): {
      const {ref, newSectionsNormalized} = action.payload;
      return PanelBankConfigUtils.updateSections(
        state,
        ref,
        (curSectionConfig, i) => newSectionsNormalized[i]
      );
    }

    case getType(PanelBankSectionConfigActions.addPanelByConfig): {
      const {ref, panel, fatPanel, callbackFn} = action.payload;
      return PanelBankSectionConfigUtils.addPanel(
        state,
        ref,
        panel,
        fatPanel,
        callbackFn
      );
    }

    case getType(PanelBankSectionConfigActions.addPanelByRef): {
      const {ref, panelRef, fatPanel, callbackFn} = action.payload;
      const panel = Normalize.denormalize(state.parts, panelRef);
      return PanelBankSectionConfigUtils.addPanel(
        state,
        ref,
        panel,
        fatPanel,
        callbackFn
      );
    }

    case getType(PanelBankSectionConfigActions.addPanelsByConfig): {
      const {ref, panels} = action.payload;
      const {newState, newPanelRefs, prevIsPanelsAuto} =
        PanelBankSectionConfigUtils.addPanels(state, ref, panels);
      const inverseAction = PanelActions.deletePanels(
        newPanelRefs,
        ref,
        undefined,
        undefined,
        {value: prevIsPanelsAuto}
      );
      return [newState, inverseAction];
    }

    case getType(PanelBankSectionConfigActions.toggleIsOpen): {
      const {ref} = action.payload;
      return PanelBankSectionConfigUtils.toggleIsOpen(state, ref);
    }

    case getType(PanelBankSectionConfigActions.updateFlowConfig): {
      const {ref, newFlowConfig, operation} = action.payload;
      const prevFlowConfig = state.parts[ref.type][ref.id].flowConfig;
      const newState = setInShallowClone(
        state,
        ['parts', ref.type, ref.id, 'flowConfig'],
        operation === 'PATCH'
          ? {...prevFlowConfig, ...(newFlowConfig ?? {})}
          : newFlowConfig
      );
      return [
        newState,
        PanelBankSectionConfigActions.updateFlowConfig(
          ref,
          prevFlowConfig,
          'PUT'
        ),
      ];
    }

    case getType(PanelBankSectionConfigActions.updateLinePlotSectionSettings): {
      const {ref, settings} = action.payload;
      return PanelBankSectionConfigUtils.updateLinePlotSectionSettings(
        state,
        ref,
        settings
      );
    }

    case getType(PanelBankSectionConfigActions.updateName): {
      const {ref, newName, panelAutoVals} = action.payload;
      return PanelBankSectionConfigUtils.updateName(
        state,
        ref,
        newName,
        panelAutoVals
      );
    }

    case getType(WorkspaceSettingsActions.disableAutoGeneratePanels): {
      const {ref, panelBankConfigRef, expectedPanels} = action.payload;
      return PanelGenUtils.disableAutoGeneratePanels(
        state,
        ref,
        panelBankConfigRef,
        expectedPanels
      );
    }

    case getType(WorkspaceSettingsActions.enableAutoGeneratePanels): {
      const {ref, panelBankConfigRef, expectedPanels} = action.payload;
      return PanelGenUtils.enableAutoGeneratePanels(
        state,
        ref,
        panelBankConfigRef,
        expectedPanels
      );
    }

    case getType(WorkspaceSettingsActions.updateAutoOrganizePrefix): {
      const {workspaceSettingsRef, autoOrganizePrefix, panelBankConfigRef} =
        action.payload;
      return WorkspaceSettingsUtils.updateAutoOrganizePrefix(
        state,
        workspaceSettingsRef,
        panelBankConfigRef,
        autoOrganizePrefix
      );
    }

    case getType(WorkspaceSettingsActions.updateAutoOrganizePrefixUndo): {
      const {
        workspaceSettingsRef,
        autoOrganizePrefix,
        panelBankConfigRef,
        sectionRefs,
      } = action.payload;
      return WorkspaceSettingsUtils.updateAutoOrganizePrefixUndo(
        state,
        workspaceSettingsRef,
        panelBankConfigRef,
        sectionRefs,
        autoOrganizePrefix
      );
    }

    case getType(WorkspaceSettingsActions.updateLinePlotWorkspaceSettings): {
      const {ref, settings} = action.payload;
      return WorkspaceSettingsUtils.updateLinePlotWorkspaceSettings(
        state,
        ref,
        settings
      );
    }

    case getType(WorkspaceSettingsActions.setWorkspaceSettings): {
      const {ref, settings} = action.payload;
      return WorkspaceSettingsUtils.setWorkspaceSettings(state, ref, settings);
    }

    case getType(WorkspaceSettingsActions.updateWorkspaceLayoutSettings): {
      const {ref, settings} = action.payload;
      return WorkspaceSettingsUtils.updateWorkspaceLayoutSettings(
        state,
        ref,
        settings
      );
    }

    case getType(PanelBankConfigActions.updateSettings): {
      const {ref, panelBankSettings} = action.payload;
      return PanelBankConfigUtils.updateSettings(state, ref, panelBankSettings);
    }

    case getType(SectionActions.addNewRunSet): {
      return SectionUtils.addNewRunSet(state, action.payload.ref);
    }
  }
  throw new Error('Action not undoable');
};

export const applyAndMakeInverseActionImmutable = (
  state: ViewReducerState,
  action: ActionType
): [ViewReducerState, ActionType] => {
  const [stateAfterApplyingAction, inverseAction] =
    applyAndMakeInverseActionImmutableInner(state, action);
  const stateAfterMarkingIsPanelsAuto = markSectionPanelsAutoImmutable(
    stateAfterApplyingAction,
    action,
    inverseAction
  );
  return [stateAfterMarkingIsPanelsAuto, inverseAction];
};

export const applyUndoableActionImmutable = (
  state: ViewReducerState,
  action: ActionType
) => {
  const [newState, inverseAction] = applyAndMakeInverseActionImmutable(
    state,
    action
  );
  return Object.assign(newState, {
    undoActions: state.undoActions.concat(inverseAction),
    redoActions: [],
  });
};
