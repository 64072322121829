import React from 'react';

export const RoleName: React.FC<{children: React.ReactNode}> = ({children}) => (
  <div className="pb-4 font-semibold">{children}</div>
);

export const RoleDescription: React.FC<{
  children: React.ReactNode;
}> = ({children}) => <div className="text-sm text-moon-500">{children}</div>;

export const RoleItem: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <div className="flex items-center">{children}</div>;
};
