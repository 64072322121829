import {useContext} from 'react';

import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {
  Project,
  useEntityProjectsQuery,
  useOrganizationProjectsQuery,
} from '../../../generated/graphql';
import {Override} from '../../../graphql/teamQuery';
import {Graph} from '../../../types/graphql';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';

type accountProjects = {
  accountProjectsList: Graph<Project>;
  loading: boolean;
};

export function useAccountProjectsList(userID: string | null): accountProjects {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const {data: orgProjectsData, loading: isOrgProjectsLoading} =
    useOrganizationProjectsQuery({
      variables: {orgID: selectedAccount?.id ?? '', userID, first: 50},
      skip:
        !enableAccountSelector ||
        selectedAccount == null ||
        selectedAccount?.accountType === AccountType.Personal,
    });
  const {data: personalEntityProjectsData, loading: isEntityProjectsLoading} =
    useEntityProjectsQuery({
      variables: {entityName: selectedAccount?.name ?? '', first: 50},
      skip:
        !enableAccountSelector ||
        selectedAccount == null ||
        selectedAccount?.accountType !== AccountType.Personal,
    });
  const personalEntityProjects = personalEntityProjectsData?.entity
    ?.projects ?? {edges: []};
  const orgProjects = orgProjectsData?.organization?.projects ?? {edges: []};
  if (selectedAccount?.accountType === AccountType.Personal) {
    return {
      accountProjectsList: personalEntityProjects as Override<
        typeof personalEntityProjects,
        Graph<Project>
      >,
      loading: isEntityProjectsLoading,
    };
  }
  return {
    accountProjectsList: orgProjects as Override<
      typeof orgProjects,
      Graph<Project>
    >,
    loading: isOrgProjectsLoading,
  };
}
