import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

const StyledChartPreview = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 270px;
  padding: 24px 12px;
  width: 100%;
  overflow: hidden;
`;

export const PanelChartPreview: React.FC = ({children}) => {
  return (
    <StyledChartPreview
      className="chart-preview"
      data-test="panel-chart-preview">
      {children}
    </StyledChartPreview>
  );
};
