import * as globals from '@wandb/weave/common/css/globals.styles';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

const StyledChartSettings = styled.div`
  // background: ${globals.gray50}; // disabled as parallel coordinates plot has issues
  overflow: auto;

  @media only screen and (max-width: 767px) {
    margin-left: -16px;
    margin-right: -16px;
  }
  @media only screen and (max-width: 1199px) {
    border-top: solid 1px ${globals.gray400};
    height: 400px;
    margin-bottom: -16px;
    overflow: auto;

    .chart-settings-tab-menu {
      // background: ${globals.gray50} !important; // disabled until all views can handle background color
      background: white !important; // required to keep scrolling from peeking through
      margin-bottom: 0px;
      position: sticky;
      top: 0px;
      z-index: 1;
    }

    .form-grid {
      padding: 0 20px;
    }
  }

  @media only screen and (min-width: 1200px) {
    border-left: solid 1px ${globals.gray400};

    .form-grid.tab {
      padding-top: 16px;
    }

    && {
      padding-left: 0px;
      padding-right: 0px;
    }

    .chart-settings-tab-menu {
      background-color: transparent;
      margin-bottom: 0;
    }

    .tab {
      padding: 0 1.25em;
    }
  }
`;

export const PanelChartSettings: React.FC = ({children}) => {
  return (
    <StyledChartSettings
      className="chart-settings"
      data-test="panel-chart-settings">
      {children}
    </StyledChartSettings>
  );
};
