import {useCallback} from 'react';
import {useInView} from 'react-intersection-observer';
import {useDispatch, useSelector} from 'react-redux';

import * as Redux from '../../types/redux';
import {RootState} from '../../types/redux';
import {setHighlight, setHighlights, setPanelSelection} from './actions';
import {EMPTY_STATE} from './reducer';
import {InteractState} from './types';

// Do not expose this. You must use useInteractStateWhenOnScreen instead.
function useNouveauInteractState() {
  return useSelector((state: RootState) => state.interactState);
}

// Performant way to get notified when interactState relevant to your component
// changes.
// Pass a mapFn and get back a tuple [domRef, value]. Assign the domRef to the
// target element in your component.
// Value will only update when domRef is onscreen, and you'll only receive
// updates when the result of your mapFn changes.
export function useInteractStateWhenOnScreen<R>(
  mapFn: (interactState: InteractState) => R
) {
  const interactState = useNouveauInteractState();
  const [domRef, onScreen] = useInView();
  return [domRef, mapFn(onScreen ? interactState : EMPTY_STATE)] as const;
}

export function useInteractStateAction<ArgType extends any[]>(
  fn: (...fnArgs: ArgType) => Redux.DispatchableAction
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(...args)),
    [dispatch, fn]
  );
}

export function useSetHighlight() {
  return useInteractStateAction(setHighlight);
}

export function useSetHighlights() {
  return useInteractStateAction(setHighlights);
}

export function useSetPanelSelection() {
  return useInteractStateAction(setPanelSelection);
}
