import * as _ from 'lodash';

import {
  QueryToRunsDataQueryParams,
  toRunsDataQuery,
} from '../../containers/RunsDataLoader';
import {Expression} from '../../util/expr';
import * as Filters from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import {legendTemplateFieldNames} from '../../util/legend';
import {isGrouped} from '../../util/panelHelpers';
import {Query} from '../../util/queryTypes';
import * as Run from '../../util/runs';
import * as RunTypes from '../../util/runTypes';
import {getExpressionFields} from '../PanelExpressionOptions';
import {BarChartConfig, PlotStyle} from './types';

export const DEFAULT_BAR_CHART_MAX_GROUP_RUNS = 1000;

export function isPlotStyle(s: string): s is PlotStyle {
  return s === 'bar' || s === 'boxplot' || s === 'violin';
}
export const defaultTitle = (config: BarChartConfig) => {
  if (config.metrics == null) {
    return '';
  }

  return config.metrics
    .map(m => {
      const key = Run.keyFromString(m);
      return key != null ? Run.keyDisplayName(key, true) : m;
    })
    .join(', ');
};

export const metricStringsToKeys = (metrics: string[]) => {
  // metrics should be in the form config:metricName, if not we assume they
  // are summary metrics.
  const metricKeys = (metrics || []).map(metricStr => {
    if (metricStr.includes(':')) {
      return (
        Run.keyFromString(metricStr) ||
        ({section: 'summary', name: metricStr} as RunTypes.Key) // shouldn't happen
      );
    } else {
      return {section: 'summary', name: metricStr} as RunTypes.Key;
    }
  });
  return metricKeys;
};

export const barChartTransformQuery = (
  query: Query,
  config: BarChartConfig,
  parsedExpressions: {
    expressions?: Expression[];
    xExpressions?: Expression;
  }
) => {
  const queryToDataQueryParams: QueryToRunsDataQueryParams = {
    selectionsAsFilters: true,
  };

  const expressionFields = getExpressionFields(parsedExpressions);

  const metricKeys = metricStringsToKeys(config.metrics || []);

  if (config.metrics && config.metrics.length > 0) {
    const filters: Array<FilterTypes.Filter<RunTypes.Key>> = metricKeys.map(
      metricKey => ({
        key: metricKey,
        op: '!=',
        value: null,
      })
    );
    const mergeFilters: FilterTypes.Filter<RunTypes.Key> = Filters.Or(filters);
    queryToDataQueryParams.mergeFilters = mergeFilters;
  }

  const transformed = toRunsDataQuery(query, queryToDataQueryParams);

  let legendFields = config.legendFields || [];
  if (config.legendTemplate != null) {
    const templateFields = legendTemplateFieldNames(config.legendTemplate);
    const extraLegendFields = _.difference(
      templateFields,
      config.legendFields || []
    );
    legendFields = legendFields.concat(extraLegendFields);
  }

  let displayFields = [
    ...legendFields.map(Run.keyFromString),
    ...(query.grouping || []),
    config.aggregate && config.groupBy
      ? Run.key('config', config.groupBy)
      : null,
  ];

  if (query.runSets != null) {
    query.runSets.forEach(rs => {
      if (rs.grouping) {
        displayFields = displayFields.concat(rs.grouping);
      }
    });
  }

  const extraFields = _.uniq(_.concat(displayFields, expressionFields));

  // And then add them into the correct query fields.
  transformed.configKeys = extraFields
    .concat(metricKeys || [])
    .filter(key => key != null && key.section === 'config')
    .map(key => key!.name);
  // Have to concat here to preserve the config key we added above.
  transformed.summaryKeys = extraFields
    .concat(metricKeys ?? [])
    .filter(key => key != null && key.section === 'summary')
    .map(key => key!.name);

  transformed.page = {
    size: config.limit ?? 10,
  };

  if (isGrouped(query, config)) {
    // We need the metadata for grouping because we do it locally
    // TODO: move grouping to server
    // result.disableMeta = false;

    // optionally compute group statistics over all runs instead of sub-sampling
    transformed.page.size =
      config.groupRunsLimit ?? DEFAULT_BAR_CHART_MAX_GROUP_RUNS;
    // Disable grouping for this query, we'll do it ourselves.
    transformed.queries = transformed.queries.map(q => ({...q, grouping: []}));
  }

  return transformed;
};

export function getTitleFromConfig(config: BarChartConfig): string {
  return config.chartTitle || defaultTitle(config);
}
