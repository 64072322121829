import {
  constFunction,
  constString,
  escapeDots,
  list,
  opConcat,
  opMap,
  opPick,
  opRunHistory,
  opRunSummary,
  typedDict,
  varNode,
} from '@wandb/weave/core';
import {isEqual, isUndefined, omit, omitBy} from 'lodash';

import {AddPanel, panelViews} from '../components/PanelBank/types';
import {RunsLinePlotConfig} from '../components/PanelRunsLinePlot/types';
import {WeaveConfig} from '../components/PanelWeave';
import {OrganizationPrefix} from '../components/WorkspaceDrawer/Settings/types';
import {RunHistoryKeyType} from '../types/run';
import {getPrefixSectionName} from './panelbank';
import {
  PANEL_BANK_CHARTS_NAME,
  PANEL_BANK_MEDIA_NAME,
  PANEL_BANK_SYSTEM_NAME,
  PANEL_BANK_TABLES_NAME,
} from './panelbankTypes';
import {PanelTemplate} from './panels';
import {LayedOutPanel, PanelType} from './panelTypes';

const {runHistoryLinePlot, mediaBrowser, weave} = panelViews;

export function isAutoPanel(
  panel: LayedOutPanel,
  sectionName: string,
  key: string | null,
  spec: AddPanel['spec'] | undefined,
  autoOrgPrefix?: OrganizationPrefix
): boolean {
  // use isAuto value if it's explicitly set; otherwise,
  // it's an older panel where we must infer auto state
  if (typeof panel.isAuto === 'boolean') {
    return panel.isAuto;
  }

  // auto panels are always single-key; no key means not auto
  if (!key) {
    return false;
  }

  // panel is auto if it's in the right section and matches default panel config
  if (spec) {
    return (
      sectionName === spec.defaultSection &&
      isDefaultPanelConfig(panel, key, spec)
    );
  }

  // if actual spec is not available, try to guess what it might've been
  // this only works for line plots, media, and weave panels
  if (
    !spec &&
    panel.viewType !== runHistoryLinePlot &&
    panel.viewType !== mediaBrowser &&
    panel.viewType !== weave
  ) {
    return false;
  }

  const defaultSection = getDefaultSection(panel.viewType, key, autoOrgPrefix);
  if (sectionName !== defaultSection) {
    return false;
  }

  const potentialSpecs = makePotentialSpecs(panel, key, defaultSection);
  return potentialSpecs.some(spec => isDefaultPanelConfig(panel, key, spec));
}

function getDefaultSection(
  viewType: PanelType,
  key: string,
  autoOrgPrefix?: OrganizationPrefix
) {
  const prefixedName = getPrefixSectionName(key, autoOrgPrefix);
  if (prefixedName) {
    const isSystem = viewType !== mediaBrowser && prefixedName === 'system';
    return isSystem ? PANEL_BANK_SYSTEM_NAME : prefixedName;
  }
  switch (viewType) {
    case runHistoryLinePlot:
      return PANEL_BANK_CHARTS_NAME;
    case mediaBrowser:
      return PANEL_BANK_MEDIA_NAME;
    case weave:
      return PANEL_BANK_TABLES_NAME;
    default:
      return;
  }
}

function makePotentialSpecs(
  panel: LayedOutPanel,
  key: string,
  defaultSection: string
): AddPanel['spec'][] {
  const isLinePlot = panel.viewType === runHistoryLinePlot;
  const isMedia = panel.viewType === mediaBrowser;
  const isWeave = panel.viewType === weave;

  const metrics = isLinePlot ? panel.config.metrics ?? [] : [key];
  let potentialKeyTypes: RunHistoryKeyType[] = [];
  if (isLinePlot) {
    // default line plots are created from one of these key types: number, histogram, unknown
    // they produce the same default config, so we can just check one of them
    potentialKeyTypes = ['number'];
  } else if (isMedia) {
    // all media key types produce the same default config
    potentialKeyTypes = ['images'];
  } else if (isWeave) {
    // default weave panels are created from one of these key types:
    // table-file, partitioned-table, joined-table, wb_trace_tree
    // the first three produce the same default config, while the last one produces something else
    potentialKeyTypes = [
      'table-file',
      'wb_trace_tree' as RunHistoryKeyType, // wb_trace_tree is not in official key types for some reason
    ];
  }

  return potentialKeyTypes.map(keyType => ({
    type: 'default-panel',
    metrics,
    keyType,
    defaultSection,
  }));
}

/**
 * This helper makes a best guess at whether a panel is using a
 * "default" config from before `panel.isAuto` flag was introduced
 */
function isDefaultPanelConfig(
  panel: LayedOutPanel,
  key: string,
  spec: AddPanel['spec']
): boolean {
  const strippedPanel = {
    ...omit(panel, '__id__', 'isAuto', 'layout', 'ref'),
    config: omitBy(
      {
        ...panel.config,
        // clear out deprecated properties
        useLocalSmoothing: undefined,
        useGlobalSmoothingWeight: undefined,
        // not deprecated, but finicky and can't be
        // predicted for panels without a real spec
        startingXAxis: undefined,
      },
      // must strip `undefined` properties because isEqual treats
      // explict undefined and missing properties as different,
      // e.g. isEqual({a: undefined}, {}) === false
      isUndefined
    ),
  };

  const defaultConfig = getLegacyDefaultPanelConfig(key, spec);
  const strippedDefault = {
    ...defaultConfig,
    config: omitBy(
      {...defaultConfig.config, startingXAxis: undefined},
      isUndefined
    ),
  };

  if (isEqual(strippedPanel, strippedDefault)) {
    return true;
  }

  // panel defaults have changed over time, but the check above
  // only captures defaults from a specific point in time. we
  // must make additional checks for known differences that
  // should still be considered "default".

  if (strippedPanel.viewType === 'Run History Line Plot') {
    const defaultWithOldSmoothingSettings = {
      ...strippedDefault,
      config: {
        ...strippedDefault.config,
        smoothingType: 'exponentialTimeWeighted',
        smoothingWeight: 0,
      },
    };
    if (isEqual(strippedPanel, defaultWithOldSmoothingSettings)) {
      return true;
    }
  }

  // still no match; probably not a default panel!
  return false;
}

// The helper functions below are snapshots/copies from before the `panel.isAuto`
// flag was introduced. We use these to infer the "auto" status of panels that
// aren't explicitly marked with `isAuto`. Using a snapshot ensures our "auto"
// inference stays reliable even if default values change in the future.

const legacySystemPanelTemplates: {[key: string]: PanelTemplate} = {
  'system/cpu-V2': {
    yAxis: 'Process CPU Utilization (%)',
    regex: /system\/cpu$/,
    key: 'system/cpu-V2',
    percentage: true,
  },
  'system/cpu.cpu-V2': {
    yAxis: 'System CPU Utilization (per core) (%)',
    regex: /system\/cpu\.\d+\.cpu/,
    key: 'system/cpu.cpu-V2',
    percentage: true,
  },
  'system/tpu.duty_cycle-V2': {
    yAxis: 'TPU Duty Cycle (%)',
    regex: /system\/tpu\.\d+\.dutyCycle/,
    key: 'system/tpu.duty_cycle-V2',
    percentage: true,
  },
  'system/tpu.memory_usage-V2': {
    yAxis: 'TPU Memory Usage (%)',
    regex: /system\/tpu\.\d+\.memory_?[uU]sage$/,
    key: 'system/tpu.memory_usage-V2',
    percentage: true,
  },
  'system/tpu.memory_usage_bytes-V2': {
    yAxis: 'TPU Memory Usage (bytes)',
    regex: /system\/tpu\.\d+\.memory_?[uU]sage_?[bB]ytes$/,
    key: 'system/tpu.memory_usage_bytes-V2',
    percentage: false,
  },
  'system/ipu.averageBoardTemperature-V2': {
    yAxis: 'IPU Average Board Temperature (\u2103)',
    regex: /system\/ipu\.\d+\.average board temp/,
    key: 'system/ipu.averageBoardTemperature-V2',
    percentage: false,
  },
  'system/ipu.averageDieTemperature-V2': {
    yAxis: 'IPU Average Die Temperature (\u2103)',
    regex: /system\/ipu\.\d+\.average die temp/,
    key: 'system/ipu.averageDieTemperature-V2',
    percentage: false,
  },
  'system/ipu.clock-V2': {
    yAxis: 'IPU Clock (MHz)',
    regex: /system\/ipu\.\d+\.clock/,
    key: 'system/ipu.clock-V2',
    percentage: false,
  },
  'system/ipu.power-V2': {
    yAxis: 'IPU Power (W)',
    regex: /system\/ipu\.\d+\.ipu power/,
    key: 'system/ipu.power-V2',
    percentage: false,
  },
  'system/ipu.utilization-V2': {
    yAxis: 'IPU Utilization (%)',
    regex: /system\/ipu\.\d+\.ipu utilisation \(%\)$/,
    key: 'system/ipu.utilization-V2',
    percentage: true,
  },
  'system/ipu.utilizationSession-V2': {
    yAxis: 'IPU Utilization (session) (%)',
    regex: /system\/ipu\.\d+\.ipu utilisation \(session\)/,
    key: 'system/ipu.utilizationSession-V2',
    percentage: true,
  },
  'system/memory-V2': {
    yAxis: 'System Memory Utilization (%)',
    regex: /system\/memory/,
    key: 'system/memory-V2',
    percentage: true,
  },
  'system/proc.memory.rssMB-V2': {
    yAxis: 'Process Memory In Use (non-swap) (MB)',
    regex: /system\/proc\.memory\.rssMB/,
    key: 'system/proc.memory.rssMB-V2',
    percentage: false,
  },
  'system/proc.memory.percent-V2': {
    yAxis: 'Process Memory In Use (non-swap) (%)',
    regex: /system\/proc\.memory\.percent/,
    key: 'system/proc.memory.percent-V2',
    percentage: true,
  },
  'system/proc.memory.availableMB-V2': {
    yAxis: 'Process Memory Available (non-swap) (MB)',
    regex: /system\/proc\.memory\.availableMB/,
    key: 'system/proc.memory.availableMB-V2',
    percentage: false,
  },
  'system/proc.cpu.threads-V2': {
    yAxis: 'Process CPU Threads In Use',
    regex: /system\/proc\.cpu\.threads/,
    key: 'system/proc.cpu.threads-V2',
    percentage: false,
  },
  'system/disk-V2': {
    yAxis: 'Disk Utilization (%)',
    regex: /system\/disk$/,
    key: 'system/disk-V2',
    percentage: true,
  },
  'system/disk.usage_percent-V2': {
    yAxis: 'Disk Utilization (%)',
    regex: /system\/disk\.((.)+)\.usagePercent/,
    key: 'system/disk.usage_percent-V2',
    percentage: true,
  },
  'system/disk.usage_gb-V2': {
    yAxis: 'Disk Utilization (GB)',
    regex: /system\/disk\.((.)+)\.usageGB/,
    key: 'system/disk.usage_gb-V2',
    percentage: false,
  },
  'system/disk.io-V2': {
    yAxis: 'Disk I/O Utilization (MB)',
    regex: /system\/disk\.(in|out)/,
    key: 'system/disk.io-V2',
    percentage: false,
  },
  'system/network-V2': {
    yAxis: 'Network Traffic (bytes)',
    regex: /system\/network.(recv|sent)/,
    key: 'system/network-V2',
    percentage: false,
  },
  'system/system.power_usage_watts-V2': {
    yAxis: 'System Power Usage (W)',
    regex: /system\/system\.powerWatts$/,
    key: 'system/system.power_usage_watts-V2',
    percentage: false,
  },
  'system/gpu.gpu-V2': {
    yAxis: 'GPU Utilization (%)',
    regex: /system\/gpu\.\d+\.gpu/,
    key: 'system/gpu.gpu-V2',
    percentage: true,
  },
  'system/gpu.temp-V2': {
    yAxis: 'GPU Temp (℃)',
    regex: /system\/gpu\.\d+\.temp/,
    key: 'system/gpu.temp-V2',
    percentage: false,
  },
  'system/gpu.memory-V2': {
    yAxis: 'GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.\d+\.memory$/,
    key: 'system/gpu.memory-V2',
    percentage: true,
  },
  'system/gpu.memory_allocated-V2': {
    yAxis: 'GPU Memory Allocated (%)',
    regex: /system\/gpu\.\d+\.memory_?[aA]llocated$/,
    key: 'system/gpu.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.memory_allocated_bytes-V2': {
    yAxis: 'GPU Memory Allocated (bytes)',
    regex: /system\/gpu\.\d+\.memory_?[aA]llocated_?[bB]ytes$/,
    key: 'system/gpu.memory_allocated_bytes-V2',
    percentage: false,
  },
  'system/gpu.memory_used_bytes-V2': {
    yAxis: 'GPU Memory Used (bytes)',
    regex: /system\/gpu\.\d+\.memory_?[uU]sed$/,
    key: 'system/gpu.memory_used_bytes-V2',
    percentage: false,
  },
  'system/gpu.recovery_count-V2': {
    yAxis: 'GPU Recovery Count',
    regex: /system\/gpu\.\d+\.recovery_?[cC]ount$/,
    key: 'system/gpu.recovery_count-V2',
    percentage: false,
  },
  'system/gpu.enforced_power_limit_watts-V2': {
    yAxis: 'GPU Enforced Power Limit (W)',
    regex: /system\/gpu\.\d+\.enforcedPowerLimitWatts$/,
    key: 'system/gpu.enforced_power_limit_watts-V2',
    percentage: false,
  },
  'system/gpu.powerPercent-V2': {
    yAxis: 'GPU Power Usage (%)',
    regex: /system\/gpu\.\d+\.powerPercent$/,
    key: 'system/gpu.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.powerWatts-V2': {
    yAxis: 'GPU Power Usage (W)',
    regex: /system\/gpu\.\d+\.powerWatts$/,
    key: 'system/gpu.powerWatts-V2',
    percentage: false,
  },
  'system/gpu.SMClockSpeed-V2': {
    yAxis: 'GPU Streaming Multiprocessor (SM) Clock Speed (MHz)',
    regex: /system\/gpu\.\d+\.smClock$/,
    key: 'system/gpu.SMClockSpeed-V2',
    percentage: false,
  },
  'system/gpu.graphicsClockSpeed-V2': {
    yAxis: 'GPU Graphics Clock Speed (MHz)',
    regex: /system\/gpu\.\d+\.graphicsClock$/,
    key: 'system/gpu.graphicsClockSpeed-V2',
    percentage: false,
  },
  'system/gpu.memoryClockSpeed-V2': {
    yAxis: 'GPU Memory Clock Speed (MHz)',
    regex: /system\/gpu\.\d+\.memoryClock$/,
    key: 'system/gpu.memoryClockSpeed-V2',
    percentage: false,
  },
  'system/gpu.correctedMemoryErrors-V2': {
    yAxis: 'GPU Corrected Memory Errors',
    regex: /system\/gpu\.\d+\.correctedMemoryErrors$/,
    key: 'system/gpu.correctedMemoryErrors-V2',
    percentage: false,
  },
  'system/gpu.uncorrectedMemoryErrors-V2': {
    yAxis: 'GPU Uncorrected Memory Errors',
    regex: /system\/gpu\.\d+\.uncorrectedMemoryErrors$/,
    key: 'system/gpu.uncorrectedMemoryErrors-V2',
    percentage: false,
  },
  'system/gpu.encoderUtilization-V2': {
    yAxis: 'GPU Encoder Utilization (%)',
    regex: /system\/gpu\.\d+\.encoderUtilization$/,
    key: 'system/gpu.encoderUtilization-V2',
    percentage: true,
  },
  'system/gpu.process.gpu-V2': {
    yAxis: 'Process GPU Utilization (%)',
    regex: /system\/gpu\.process\.\d+\.gpu/,
    key: 'system/gpu.process.gpu-V2',
    percentage: true,
  },
  'system/gpu.process.temp-V2': {
    yAxis: 'Process GPU Temp (℃)',
    regex: /system\/gpu\.process\.\d+\.temp/,
    key: 'system/gpu.process.temp-V2',
    percentage: false,
  },
  'system/gpu.process.memory-V2': {
    yAxis: 'Process GPU Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.process\.\d+\.memory$/,
    key: 'system/gpu.process.memory-V2',
    percentage: true,
  },
  'system/gpu.process.memory_allocated-V2': {
    yAxis: 'Process GPU Memory Allocated (%)',
    regex: /system\/gpu\.process\.\d+\.memory_?[aA]llocated$/,
    key: 'system/gpu.process.memory_allocated-V2',
    percentage: true,
  },
  'system/gpu.process.memory_allocated_bytes-V2': {
    yAxis: 'Process GPU Memory Allocated (bytes)',
    regex: /system\/gpu\.process\.\d+\.memory_?[aA]llocated_?[bB]ytes$/,
    key: 'system/gpu.process.memory_allocated_bytes-V2',
    percentage: false,
  },
  'system/gpu.process.memory_used_bytes-V2': {
    yAxis: 'Process GPU Memory Used (bytes)',
    regex: /system\/gpu\.process\.\d+\.memory_?[uU]sed_?[bB]ytes$/,
    key: 'system/gpu.process.memory_used_bytes-V2',
    percentage: false,
  },
  'system/gpu.process.enforced_power_limit_watts-V2': {
    yAxis: 'Process GPU Enforced Power Limit (W)',
    regex: /system\/gpu\.process\.\d+\.enforcedPowerLimitWatts$/,
    key: 'system/gpu.process.enforced_power_limit_watts-V2',
    percentage: false,
  },
  'system/gpu.process.powerPercent-V2': {
    yAxis: 'Process GPU Power Usage (%)',
    regex: /system\/gpu\.process\.\d+\.powerPercent$/,
    key: 'system/gpu.process.powerPercent-V2',
    percentage: true,
  },
  'system/gpu.process.powerWatts-V2': {
    yAxis: 'Process GPU Power Usage (W)',
    regex: /system\/gpu\.process\.\d+\.powerWatts$/,
    key: 'system/gpu.process.powerWatts-V2',
    percentage: false,
  },
  'system/trn.utilization-V2': {
    yAxis: 'Trainium Neuron Core Utilization (%)',
    regex: /system\/trn\.\d+\.neuroncore_utilization$/,
    key: 'system/trn.utilization-V2',
    percentage: true,
  },
  'system/trn.host_total_memory_usage-V2': {
    yAxis: 'Trainium Host Memory Usage, total (bytes)',
    regex: /system\/trn\.host_total_memory_usage$/,
    key: 'system/trn.host_total_memory_usage-V2',
    percentage: false,
  },
  'system/trn.neuron_device_total_memory_usage-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, total (bytes)',
    regex: /system\/trn\.neuron_device_total_memory_usage$/,
    key: 'system/trn.neuron_device_total_memory_usage-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.application_memory-V2': {
    yAxis: 'Trainium Host Memory Usage, application memory (bytes)',
    regex: /system\/trn\.host_memory_usage\.application_memory$/,
    key: 'system/trn.host_memory_usage.application_memory-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.constants-V2': {
    yAxis: 'Trainium Host Memory Usage, constants (bytes)',
    regex: /system\/trn\.host_memory_usage\.constants$/,
    key: 'system/trn.host_memory_usage.constants-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.dma_buffers-V2': {
    yAxis: 'Trainium Host Memory Usage, DMA buffers (bytes)',
    regex: /system\/trn\.host_memory_usage\.dma_buffers$/,
    key: 'system/trn.host_memory_usage.dma_buffers-V2',
    percentage: false,
  },
  'system/trn.host_memory_usage.tensors-V2': {
    yAxis: 'Trainium Host Memory Usage, tensors (bytes)',
    regex: /system\/trn\.host_memory_usage\.tensors$/,
    key: 'system/trn.host_memory_usage.tensors-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.constants-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, constants (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.constants$/,
    key: 'system/trn.neuroncore_memory_usage.constants-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.model_code-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, model code (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.model_code$/,
    key: 'system/trn.neuroncore_memory_usage.model_code-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.model_shared_scratchpad-V2': {
    yAxis:
      'Trainium Neuron Device Memory Usage, model shared scratchpad (bytes)',
    regex:
      /system\/trn\.\d+\.neuroncore_memory_usage\.model_shared_scratchpad$/,
    key: 'system/trn.neuroncore_memory_usage.model_shared_scratchpad-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.runtime_memory-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, runtime_memory (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.runtime_memory$/,
    key: 'system/trn.neuroncore_memory_usage.runtime_memory-V2',
    percentage: false,
  },
  'system/trn.neuroncore_memory_usage.tensors-V2': {
    yAxis: 'Trainium Neuron Device Memory Usage, tensors (bytes)',
    regex: /system\/trn\.\d+\.neuroncore_memory_usage\.tensors$/,
    key: 'system/trn.neuroncore_memory_usage.tensors-V2',
    percentage: false,
  },
};

// exported just for unit tests
export function getLegacyDefaultPanelConfig(
  keyName: string,
  addPanelSpec: AddPanel['spec']
): LayedOutPanel {
  if (addPanelSpec.type === 'legacy-vega') {
    return {
      key: keyName,
      viewType: 'Vega',
      config: addPanelSpec.config,
    } as LayedOutPanel;
  } else if (addPanelSpec.type === 'api-added-panel') {
    return {
      key: keyName,
      viewType: addPanelSpec.viewType,
      config: addPanelSpec.config,
    } as LayedOutPanel;
  }

  const metrics = addPanelSpec.metrics.sort();
  const openMetricsPrefix = 'system/openmetrics.';
  if (keyName.startsWith(openMetricsPrefix)) {
    return {
      key: keyName,
      viewType: 'Run History Line Plot',
      config: {
        metrics,
        groupBy: 'None',
        chartTitle: keyName.slice(
          openMetricsPrefix.length,
          keyName.lastIndexOf('.')
        ),
        yAxisMin: undefined,
        yAxisMax: undefined,
      } as RunsLinePlotConfig,
    } as LayedOutPanel;
  }
  if (keyName in legacySystemPanelTemplates) {
    const template = legacySystemPanelTemplates[keyName];
    return {
      key: keyName,
      viewType: 'Run History Line Plot',
      config: {
        metrics,
        groupBy: 'None',
        chartTitle: template.yAxis,
        yAxisMin: template.percentage ? 0 : undefined,
        yAxisMax: template.percentage ? 100 : undefined,
      } as RunsLinePlotConfig,
    } as LayedOutPanel;
  }

  const defaultxAxis = addPanelSpec.defaultXAxis ?? '_step';

  if (
    [
      'table-file',
      'partitioned-table',
      'joined-table',
      'wb_trace_tree',
    ].includes(addPanelSpec.keyType)
  ) {
    return {
      viewType: 'Weave',
      config: legacyWeavePanelForWeaveKey(keyName, addPanelSpec.keyType),
    } as LayedOutPanel;
  }

  return {
    viewType:
      addPanelSpec.keyType === 'histogram' ||
      addPanelSpec.keyType === 'number' ||
      addPanelSpec.keyType === 'unknown'
        ? 'Run History Line Plot'
        : 'Media Browser',
    config:
      addPanelSpec.keyType === 'histogram' ||
      addPanelSpec.keyType === 'number' ||
      addPanelSpec.keyType === 'unknown'
        ? {
            metrics: [keyName],
            groupBy: 'None',
            legendFields: ['run:displayName'],
            yAxisAutoRange: false,
            yLogScale: false,
            startingXAxis: defaultxAxis,
          }
        : {
            mediaKeys: [keyName],
          },
  } as LayedOutPanel;
}

function legacyWeavePanelForWeaveKey(
  key: string,
  keyType: string
): WeaveConfig {
  // var name does not matter, PanelExpression will auto-replace.
  // Using something unique & ugly to force out errors.
  const panel2Config: any = {};
  const vNode = varNode(list('run'), 'runs');
  if (keyType === 'wb_trace_tree') {
    panel2Config.exprAndPanelLocked = true;
    panel2Config.exp = opConcat({
      arr: opMap({
        arr: vNode,
        mapFn: constFunction({row: typedDict({})}, ({row}) => {
          return opRunHistory({
            run: row,
          });
        }),
      }),
    });
    panel2Config.panelId = 'wb_trace_tree-traceDebuggerFromRunHistory';
    panel2Config.panelConfig = {
      traceKey: key,
    };
  } else {
    const tableNode = opPick({
      obj: opRunSummary({run: vNode}),
      key: constString(escapeDots(key)),
    });
    panel2Config.exp = tableNode;
  }

  return {
    panel2Config,
    defaultWorkspaceState: {
      panel2Config,
      key,
      keyType,
    },
  };
}
