import _ from 'lodash';
import memoizeOne from 'memoize-one';
import React from 'react';

type MouseEventHandler = (e: MouseEvent) => void;

type Coordinate = {
  x: number;
  y: number;
};

type MousePosition = {
  client: Coordinate;
  screen: Coordinate;
  page: Coordinate;
  offset: Coordinate;
};

let upListenerId = 0;
const upListeners: {[id: number]: MouseEventHandler} = {};

export function mouseListenersStart() {
  document.addEventListener('mouseup', e => {
    for (const listener of _.values(upListeners)) {
      listener(e);
    }
  });
}

export function registerMouseUpListener(fn: MouseEventHandler) {
  const id = upListenerId;
  upListenerId++;
  upListeners[id] = fn;
  return id;
}

export function unregisterMouseUpListener(id: number) {
  if (upListeners[id]) {
    delete upListeners[id];
  }
}

// Normalizes mouse position across browsers.
// (Firefox calculates offset differently than Chrome does.)
export function getMousePosition(evt: React.MouseEvent): MousePosition {
  let pageX = evt.pageX;
  let pageY = evt.pageY;

  let clientX = evt.clientX;
  let clientY = evt.clientY;

  let screenX = evt.screenX;
  let screenY = evt.screenY;

  if (evt.type.includes('touch')) {
    const touchEvt = evt as unknown as React.TouchEvent;
    pageX = touchEvt.changedTouches[0].pageX;
    pageY = touchEvt.changedTouches[0].pageY;

    clientX = touchEvt.changedTouches[0].clientX;
    clientY = touchEvt.changedTouches[0].clientY;

    screenX = touchEvt.changedTouches[0].screenX;
    screenY = touchEvt.changedTouches[0].screenY;
  }

  if (pageX === undefined) {
    pageX =
      clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
    pageY =
      clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  const rect = (evt.currentTarget || evt.target).getBoundingClientRect();
  const offsetX = clientX - rect.left;
  const offsetY = clientY - rect.top;

  const ret = {
    client: {x: clientX, y: clientY}, // relative to the viewport
    screen: {x: screenX, y: screenY}, // relative to the physical screen
    offset: {x: offsetX, y: offsetY}, // relative to the event target
    page: {x: pageX, y: pageY}, // relative to the html document
  };

  return ret;
}

// prevents recomputing offset, may cause bugs caused by the fact that it's
// only memoizing one
export const getMousePositionMemoized = memoizeOne(getMousePosition);

export function getMousePositionRelativeTo(
  evt: React.MouseEvent,
  element: HTMLElement
): MousePosition {
  const absoluteMousePosition = getMousePositionMemoized(evt);
  const elementRect = element.getBoundingClientRect();

  const ret = {
    client: {
      x: absoluteMousePosition.client.x - elementRect.x,
      y: absoluteMousePosition.client.y - elementRect.y,
    }, // relative to element under the viewport
    screen: {
      x: absoluteMousePosition.screen.x - elementRect.x,
      y: absoluteMousePosition.screen.y - elementRect.y,
    }, // relative to element in the physical screen
    offset: {
      x: absoluteMousePosition.offset.x,
      y: absoluteMousePosition.offset.y,
    }, // relative to the event target
    page: {
      x: absoluteMousePosition.page.x - elementRect.x,
      y: absoluteMousePosition.page.y - elementRect.y,
    }, // relative to element in the html document
  };

  return ret;
}
