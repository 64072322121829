import React from 'react';

import {StripePaymentMethodCard} from '../../../../generated/graphql';
import {CardBrands} from '../util';
import * as S from './PaymentCardInfo.styles';

export const PaymentCardInfo = ({card}: {card: StripePaymentMethodCard}) => {
  const cardBrand = card.brand;
  const endingIn = card.last4;
  const {displayName: cardBrandDisplayName, logoName} = CardBrands[cardBrand];
  return (
    <S.CardBrandInfoRow>
      <S.CardBrandIcon name={logoName} />
      <S.CardInfo>
        <S.CardBrand>{cardBrandDisplayName}</S.CardBrand>
        {endingIn && <S.EndingIn>ending in {endingIn}</S.EndingIn>}
      </S.CardInfo>
    </S.CardBrandInfoRow>
  );
};
