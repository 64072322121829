import {InputBase} from '@material-ui/core';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {fuzzyMatchWithMapping} from '@wandb/weave/common/util/fuzzyMatch';
import {Button} from '@wandb/weave/components/Button';
import {Icon, IconAddNew, IconSearch} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import classNames from 'classnames';
import React, {memo, useContext, useState} from 'react';

import {Banner} from '../../../components/Banner';
import {
  useNavContext,
  useNavUpdaterContext,
} from '../../../components/NavContextProvider';
import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {Skeleton} from '../../../components/Skeleton';
import {
  useHomePageViewerProjectsQuery,
  useViewerUserInfoQuery,
} from '../../../generated/graphql';
import {useViewer} from '../../../state/viewer/hooks';
import {
  useRampFlagAccountSelector,
  useRampFlagStarredProjects,
} from '../../../util/rampFeatureFlags';
import {StarredProjectsContext} from '../../../util/StarredProjectsContextProvider';
import {allProjects} from '../../../util/urls';
import {AccountProjectData} from '../HomePageContent/util';
import CreateProjectDrawer from './CreateProjectDrawer';
import * as S from './HomePageSidebar.styles';
import {SidebarItem} from './SidebarItem';
import {SidebarLink} from './SidebarLink';
import {SidebarSectionHeader} from './SidebarSectionHeader';
import {useAccountProjectsList} from './useAccountProjectsList';

type ListProject = {
  searchString: string;
  row: AccountProjectData;
};

const ProjectsListComp = () => {
  const viewer = useViewer();
  const {isCreatingProject} = useNavContext();
  const {toggleCreateProjectDrawer} = useNavUpdaterContext();
  const [searchQuery, setSearchQuery] = useState('');
  const enableAccountSelector = useRampFlagAccountSelector();
  const {accountProjectsList} = useAccountProjectsList(viewer?.id ?? '');
  const {starredProjects, onStarClick} = React.useContext(
    StarredProjectsContext
  );
  const {selectedAccount} = useContext(AccountSelectorContext);

  const viewerProjects = useHomePageViewerProjectsQuery({
    variables: {
      entityName: viewer?.username ?? '',
      first: 50,
    },
    skip: enableAccountSelector,
  });

  const data = viewerProjects?.data?.projects ?? {edges: []};
  const projectsList = enableAccountSelector ? accountProjectsList : data;

  const viewerUserInfo = useViewerUserInfoQuery();
  const userInfo = viewerUserInfo?.data?.viewer?.userInfo;
  const shouldHidePersonalEntity = userInfo?.isPersonalEntityHidden ?? false;
  const isPersonalEntitySelectedAccount =
    selectedAccount !== null &&
    selectedAccount.accountType === AccountType.Personal;
  const canNotCreateNewProject =
    shouldHidePersonalEntity && isPersonalEntitySelectedAccount;

  const isStarredProjectsEnabled = useRampFlagStarredProjects();

  let projects: ListProject[] = [];

  if (isStarredProjectsEnabled) {
    projects = Object.values(starredProjects).map(p => {
      return {
        searchString: p.name,
        row: {
          id: p.id,
          entityName: p.entityName,
          name: p.name,
          access: p.access ?? '',
          createdAt: p.createdAt ?? '',
        },
      };
    });
  }

  projects = [
    ...projects,
    ...projectsList.edges.map(n => {
      return {
        searchString: n.node?.name ?? '',
        row: {
          id: n.node?.id ?? '',
          entityName: n.node?.entityName ?? '',
          name: n.node?.name ?? '',
          access: n.node?.access ?? '',
          createdAt: n.node?.createdAt ?? '',
        },
      };
    }),
  ].reduce((acc: ListProject[], curr: ListProject) => {
    // Remove duplicates
    if (!acc.some((p: ListProject) => p.row.id === curr.row.id)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const haveProjects = projects.length > 0;
  if (searchQuery != null) {
    projects = fuzzyMatchWithMapping(
      projects,
      searchQuery,
      d => d.searchString
    );
  }

  const [projectsListSize, setProjectsListSize] = useState(10);

  const onLoadMoreProjects = () => {
    setProjectsListSize(projectsListSize + 10);
  };

  return (
    <S.SidebarList>
      <SidebarSectionHeader
        headerText="Projects"
        linkUrl={
          viewer && viewer.username ? allProjects(viewer.username) : undefined
        }
        linkText="View all"
      />
      {viewerProjects.loading && !haveProjects && (
        <>
          {[...Array(4)].map((val, idx) => (
            <Skeleton key={idx} className="mb-6 h-30 rounded opacity-50" />
          ))}
        </>
      )}
      {viewerProjects.error != null && !haveProjects && (
        <Banner
          variant="error"
          eventData={{location: 'home sidebar projects list'}}
          className="px-12 py-8 text-sm">
          We were unable to load your projects.
        </Banner>
      )}

      {haveProjects && (
        <S.ProjectSearchInput button>
          <InputBase
            startAdornment={<IconSearch style={{color: globals.MOON_500}} />}
            placeholder="Search"
            value={searchQuery}
            onChange={e => {
              setSearchQuery(e.target.value);
            }}
          />
        </S.ProjectSearchInput>
      )}

      {projects.slice(0, projectsListSize).map((project, idx) => {
        const projectAccess = project.row.access;
        const iconName =
          projectAccess === 'USER_WRITE' || projectAccess === 'USER_READ'
            ? 'lock-open'
            : 'lock-closed';
        const linkText = `${project.row.entityName}/${project.row.name}`;
        const isStarred = starredProjects[project.row.id] !== undefined;
        const unstarredStyle = isStarred
          ? ''
          : 'hidden group-hover:flex hover:text-moon-500';

        return (
          <div
            className="group flex flex-row items-center"
            key={`${project.row.name}-${idx}`}>
            <div className="flex-1">
              <SidebarLink
                dataTest={`home-sidebar-project-link-${project.row.name}`}
                icon={<Icon name={iconName} className="flex-0 min-w-[20px]" />}
                prefixText={project.row.entityName}
                labelText={project.row.name}
                url={`/${linkText}`}
              />
            </div>
            {isStarredProjectsEnabled && (
              <Button
                data-test={
                  isStarred
                    ? `sidebar-star-filled-${project.row.name}`
                    : `sidebar-star-empty-${project.row.name}`
                }
                className={classNames(
                  unstarredStyle,
                  'absolute right-12 w-24 -translate-y-1/2 hover:bg-[#15181F] hover:bg-opacity-5'
                )}
                size="small"
                variant="quiet"
                icon={isStarred ? 'star-filled' : 'star'}
                aria-label={isStarred ? 'Unstar project' : 'Star project'}
                onClick={() => {
                  onStarClick(project.row, !isStarred, 'navigation sidebar');
                }}
              />
            )}
          </div>
        );
      })}
      {searchQuery != null &&
        searchQuery !== '' &&
        projects.length === 0 &&
        enableAccountSelector && (
          <Tailwind>
            <div className="rounded-lg bg-moon-50 p-12 text-center text-sm font-semibold text-moon-450">
              No results found. Try searching again or check a different
              account.
            </div>
          </Tailwind>
        )}
      <div>
        <SidebarItem
          icon={<IconAddNew />}
          labelText="Create a new project"
          dataTest="home-create-project-link"
          onClick={toggleCreateProjectDrawer}
          isDisabled={canNotCreateNewProject}
        />
        <CreateProjectDrawer
          open={isCreatingProject}
          toggleProjectDrawer={toggleCreateProjectDrawer}
        />
      </div>
      {projects.length > projectsListSize && (
        <div
          className="ml-40 font-semibold leading-8 text-teal-600 hover:cursor-pointer hover:text-teal-500"
          onClick={onLoadMoreProjects}>
          View more
        </div>
      )}
    </S.SidebarList>
  );
};

export const ProjectsList = memo(ProjectsListComp);
