import {Link} from '@wandb/weave/common/util/links';
import {Button} from '@wandb/weave/components';
import React from 'react';
import {Redirect} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Header} from 'semantic-ui-react';

import AccessDenied from '../components/AccessDenied';
import config from '../config';
import {isInIframe} from '../setup';
import {useViewer} from '../state/viewer/hooks';
import {setAuthRedirect} from '../util/auth';
import {login} from '../util/urls';

interface NoMatchProps {
  unrecoverable?: boolean;
}

// We are deprecating NoMatch in favor of the pages/NoAccessPage
const NoMatch: React.FC<NoMatchProps> = ({unrecoverable}) => {
  const viewer = useViewer();
  const loggedInOrOnPrem = viewer != null || config.ENVIRONMENT_IS_PRIVATE;

  const helpMessage = loggedInOrOnPrem
    ? 'If this is the right URL, you might need to ask the page owner to change the privacy permissions or invite you to the team.'
    : 'If this is the right URL, you might need to log in or ask the page owner to change the privacy permissions.';

  const buttonText = loggedInOrOnPrem ? 'Go to home page' : 'Log in to W&B';

  const buttonLink = loggedInOrOnPrem ? `/` : login({signup: true});

  const queryParams = new URLSearchParams(window.location.search);
  const authRedirect = queryParams.get('authRedirect');
  const redirectTo = window.location.href.replace(window.location.origin, '');
  if (!loggedInOrOnPrem && authRedirect) {
    setAuthRedirect(redirectTo);
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            prompt: true,
            from: {pathname: redirectTo},
            internal: true,
          },
        }}
      />
    );
  }

  return isInIframe() ? (
    <AccessDenied unrecoverable={unrecoverable} />
  ) : (
    <div className="nomatch">
      <Header>404</Header>
      <h2>Looks like you stumbled on an empty page.</h2>
      <p>{helpMessage}</p>
      <Link to={buttonLink} newTab={false}>
        <Button variant="primary">{buttonText}</Button>
      </Link>
    </div>
  );
};

export default NoMatch;
