import React, {FC, memo, useMemo} from 'react';

import {trackBreadcrumbClicked} from '../../../../util/navigation';
import * as links from '../../../../util/urls';
import Breadcrumbs, {BreadcrumbPart} from '../../../Breadcrumbs';

type NavBreadcrumbsPublishedReportProps = {
  entityName: string;
  reportID: string;
  reportName: string;
};

const NavBreadcrumbsPublishedReportComp: FC<
  NavBreadcrumbsPublishedReportProps
> = ({entityName, reportID, reportName}) => {
  const parts: BreadcrumbPart[] = useMemo(
    () => [
      {
        label: entityName,
        to: `/${entityName}`,
        onClick: () => trackBreadcrumbClicked('Entity'),
      },
      {
        label: `Published Work`,
        to: `/${entityName}`,
      },
      {
        label: reportName,
        to: `${links.publishedReportView({
          entityName,
          reportID,
          reportName,
        })}${window.location.search}`,
        onClick: () => trackBreadcrumbClicked('Report'),
      },
    ],
    [entityName, reportID, reportName]
  );

  return <Breadcrumbs parts={parts} />;
};

const NavBreadcrumbsPublishedReport = memo(NavBreadcrumbsPublishedReportComp);

export default NavBreadcrumbsPublishedReport;
