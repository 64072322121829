// this file includes utility functions for tracked hour

// calculates the total tracked hour over the list of entities
export function getTotalTrackedHourSeconds(
  entities?: Array<{computeHours?: number}>
): number | undefined {
  if (entities == null) {
    return undefined;
  }

  let trackedHourInSeconds = 0;

  entities.forEach(entity => {
    trackedHourInSeconds += entity.computeHours ?? 0;
  });

  return trackedHourInSeconds;
}

export function getTrackedHourUsageSeconds(
  teamsPlanTrackedHoursInSecs: number | null,
  teams: Array<{computeHours?: number}>
) {
  // Check if tracked hours for Teams Plan exist
  return teamsPlanTrackedHoursInSecs != null
    ? teamsPlanTrackedHoursInSecs
    : getTotalTrackedHourSeconds(teams);
}
