import * as globals from '@wandb/weave/common/css/globals.styles';
import {hexToRGB} from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const BreadcrumbsWrapper = styled.div<{
  showAdminBanner?: boolean;
}>`
  a:link, a:visited {
    color: ${props =>
      props.showAdminBanner ? hexToRGB(globals.WHITE, 0.5) : globals.gray500};
   &:hover {
    color: ${props =>
      props.showAdminBanner ? hexToRGB(globals.WHITE, 0.8) : globals.gray400};
     }
    }
  }
`;
BreadcrumbsWrapper.displayName = 'BreadcrumbsWrapper';
