import ModifiedDropdown from '@wandb/weave/common/components/elements/ModifiedDropdown';
import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox} from 'semantic-ui-react';

import DOC_URLS from '../../util/doc_urls';
import {LabeledOptionBlock as LabeledOption} from '../elements/LabeledOptionBlock';
import {runsLinePlotConfigDefaults} from '../PanelRunsLinePlot/defaults';
import {MultiRunAggOptions, SingleRunAggOptions} from './AggOptions';
import {AggRange} from './AggRange';
import {AggregationToggle} from './AggregationToggle';
import {AggType} from './AggType';
import {PlotType} from './PlotType';
import {type BarChartConfig, type PanelGroupingOptionsProps} from './types';

export const PanelGroupingOptions = (props: PanelGroupingOptionsProps) => {
  const {
    absoluteMaxGroupRuns,
    config,
    defaultMaxGroupRuns,
    disabled,
    isGrouped,
    singleRun,
    totalRuns,
    updateConfig,
    usingExpressions,
  } = props;

  const showAggregationOptions =
    (props.type === 'bars' &&
      (config as BarChartConfig).plotStyle !== 'boxplot' &&
      (config as BarChartConfig).plotStyle !== 'violin') ||
    props.type === 'lines';
  const disableAggregationOptions = props.type === 'lines' && usingExpressions;

  return (
    <>
      {singleRun ? (
        <SingleRunAggOptions {...props} />
      ) : (
        <MultiRunAggOptions {...props} />
      )}
      {(config.aggregateMetrics === true ||
        ((props.type === 'bars' || props.type === 'lines') &&
          config.metrics != null &&
          config.metrics.length > 1)) && (
        <LabeledOption
          label="Metrics"
          helpText="Aggregate over all metrics"
          option={
            <Checkbox
              toggle
              disabled={disabled}
              checked={config.aggregateMetrics}
              name="aggregate"
              onChange={(e, value) =>
                updateConfig({
                  aggregateMetrics: value.checked,
                })
              }
            />
          }
        />
      )}
      {isGrouped && (
        <>
          <div className="group-options">
            <div className="group-option">
              {usingExpressions && (
                <div className="hint-text">
                  <p>
                    Grouping functions are disabled while using expressions. In
                    order to reenable, remove all expressions.
                  </p>
                </div>
              )}
              {props.type === 'bars' && (
                <PlotType
                  config={config as BarChartConfig}
                  updateConfig={updateConfig}
                  usingExpressions={usingExpressions}
                />
              )}
              {showAggregationOptions && (
                <AggType
                  config={config}
                  updateConfig={updateConfig}
                  options={{
                    allowMedian: true,
                    defaultGroupAgg: 'mean',
                    disabled: disableAggregationOptions,
                    includeSum: props.type === 'scalar',
                  }}
                />
              )}

              {showAggregationOptions && (
                <AggRange
                  config={config}
                  disabled={disableAggregationOptions}
                  updateConfig={updateConfig}
                  // scalar plot doesn't include samples
                  includeSamples={props.type !== 'scalar'}
                />
              )}
            </div>
          </div>
        </>
      )}
      {isGrouped && totalRuns >= defaultMaxGroupRuns && (
        <LabeledOption
          label="Sampled Runs"
          helpText="Number of runs to sample from for grouping (large numbers may impact performance)."
          docUrl={DOC_URLS.compareMetrics + '#grouping'}
          option={
            <ModifiedDropdown
              lazyLoad
              className="no-wrap-options"
              value={config.groupRunsLimit || defaultMaxGroupRuns}
              style={{minWidth: 60}}
              selection
              options={[
                defaultMaxGroupRuns,
                // limit the absolute maximum number of runs to group so we don't hit performance issues
                Math.min(totalRuns, absoluteMaxGroupRuns),
              ]
                .sort()
                .map(n => ({
                  value: n,
                  text: `${n}`,
                }))}
              onChange={(_, {value}) => {
                if (typeof value === 'number') {
                  updateConfig({
                    groupRunsLimit: value,
                  });
                }
              }}
            />
          }
        />
      )}
      {isGrouped && props.type === 'lines' && (
        <AggregationToggle
          // @ts-ignore this will work even if it doesn't exist, and TS can't infer the narrowing here properly
          checked={config.windowing ?? runsLinePlotConfigDefaults.windowing} // this defaults to true
          disabled={false}
          updateConfig={updateConfig}
        />
      )}
    </>
  );
};
