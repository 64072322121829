export enum AccountType {
  Personal = 'Personal',
  Corporate = 'Corporate',
  Academic = 'Academic',
}

export type Account = {
  name: string;
  accountType: AccountType;
  id?: string;
  memberCount?: number;
  isEnterprise?: boolean;
  username: string;
  personalOrgId?: string;
  isDummyAccount?: boolean;
};
