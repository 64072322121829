import {omit as _omit} from 'lodash';

import {DEFAULT_ORGANZED_SETTINGS} from '../../../components/WorkspaceDrawer/Settings/runLinePlots/linePlotDefaults';
import {denormFn, normFn} from '../normalizerSupport';
import * as PanelBankConfigNormalize from '../panelBankConfig/normalize';
import * as PanelsNormalize from '../panels/normalize';
import * as PanelSettingsNormalize from '../panelSettings/normalize';
import * as WorkspaceSettingsNormalize from '../workspaceSettings/normalize';
import * as Types from './types';

// TODO(views): Probably move this somewhere central.
function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]) {
  return _omit(obj, keys);
}

export const normalize = normFn<Types.RunPageObjSchema>(
  'run-view',
  (whole, ctx) => {
    return {
      ...omit(
        whole,
        'panels',
        'settings',
        'panelBankConfig',
        'workspaceSettings'
      ),
      panelsRef: PanelsNormalize.normalize(whole.panels, ctx),
      panelBankConfigRef: PanelBankConfigNormalize.normalize(
        whole.panelBankConfig,
        ctx
      ),
      panelSettingsRef: PanelSettingsNormalize.normalize(whole.settings, ctx),
      workspaceSettingsRef: WorkspaceSettingsNormalize.normalize(
        whole.workspaceSettings || DEFAULT_ORGANZED_SETTINGS,
        ctx
      ),
    };
  }
);

export const denormalize = denormFn<Types.RunPageObjSchema>((part, ctx) => ({
  ...omit(
    part,
    'panelsRef',
    'panelSettingsRef',
    'panelBankConfigRef',
    'workspaceSettingsRef'
  ),
  panels: PanelsNormalize.denormalize(part.panelsRef, ctx),
  panelBankConfig: PanelBankConfigNormalize.denormalize(
    part.panelBankConfigRef,
    ctx
  ),
  settings: PanelSettingsNormalize.denormalize(part.panelSettingsRef, ctx),
  workspaceSettings: WorkspaceSettingsNormalize.denormalize(
    part.workspaceSettingsRef,
    ctx
  ),
}));
