import React, {createContext, useContext, useMemo} from 'react';

import type {
  ChartAggOption,
  ChartAreaOption,
} from './../../../util/plotHelpers/chartTypes';
import type {RunSetQuery} from './../../../util/queryTypes';
import {key} from './../../../util/runs';
import type {Key} from './../../../util/runTypes';

type PanelGroupingSettingsType = {
  aggregatePanelRuns: boolean;
  aggregatePanelMetrics: boolean;
  groupAgg: ChartAggOption | undefined;
  groupArea: ChartAreaOption | undefined;
  isGrouped: boolean;
  groupKeys: Key[];
};

const PanelGroupingSettings = createContext<PanelGroupingSettingsType>({
  aggregatePanelRuns: false,
  aggregatePanelMetrics: false,
  groupAgg: 'mean',
  groupArea: 'minmax',
  isGrouped: false,
  groupKeys: [],
});

type PanelGroupingSettingsProps = {
  aggregate: boolean;
  aggregateMetrics: boolean;
  children: React.ReactNode;
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  groupBy: string;
  runSets: RunSetQuery[];
  useRunsTableGroupingInPanels: boolean;
};

/**
 * Panel grouping can be configured by a few different options:
 * 1: if there is grouping applied in the workspace (runset.grouping)
 * 2: if there is grouping applied in the panel (config.aggregate)
 * 3: if there is grouping on the metrics (config.aggregateMetrics)
 * 4: workspace grouping can be turned on/off for panels with a button on the workspace
 *
 * If grouping is applied at the panel level it needs to overwrite the grouping applied at the workspace level. Disabling grouping from the workspace shouldn't affect the grouping configured at the panel level.
 */
export const PanelGroupingContextProvider = ({
  aggregate,
  aggregateMetrics,
  children,
  groupAgg,
  groupArea,
  groupBy,
  runSets,
  useRunsTableGroupingInPanels,
}: PanelGroupingSettingsProps) => {
  const panelGroupKeys = useMemo(() => {
    return groupBy != null ? [key('config', groupBy)] : [];
  }, [groupBy]);

  const groupKeysByRunsetId = useMemo(() => {
    return runSets.reduce((acc, runset) => {
      acc[runset.id] = runset?.grouping ?? [];
      return acc;
    }, {} as Record<string, Key[]>);
  }, [runSets]);

  const activeGroupKeys = useMemo(() => {
    return Object.values(groupKeysByRunsetId).flat();
  }, [groupKeysByRunsetId]);

  const groupKeys = useMemo(() => {
    if (aggregate) {
      return panelGroupKeys ?? [];
    }
    if (useRunsTableGroupingInPanels) {
      return activeGroupKeys;
    }

    return [];
  }, [
    aggregate,
    activeGroupKeys,
    panelGroupKeys,
    useRunsTableGroupingInPanels,
  ]);

  const value = useMemo(
    () => ({
      aggregatePanelRuns: aggregate,
      aggregatePanelMetrics: aggregateMetrics,
      isGrouped: groupKeys.length > 0 || aggregateMetrics,
      groupAgg: panelGroupKeys.length > 0 ? groupAgg : undefined,
      groupArea: panelGroupKeys.length > 0 ? groupArea : undefined,
      groupKeys,
    }),
    [
      aggregate,
      aggregateMetrics,
      groupAgg,
      groupArea,
      groupKeys,
      panelGroupKeys,
    ]
  );

  return (
    <PanelGroupingSettings.Provider value={value}>
      {children}
    </PanelGroupingSettings.Provider>
  );
};

export const usePanelGroupingSettings = () => {
  const value = useContext(PanelGroupingSettings);

  return value;
};
