import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Popup} from 'semantic-ui-react';

import {Mark} from '../util/plotHelpers/types';

export interface LineStylePickerProps {
  activeLineStyle: Mark;
  setLineStyle(mark: Mark): void;
}

export const markToIconNameMap: Record<Mark, string> = {
  dashed: 'line-dash',
  dotdash: 'line-dash-dot',
  dotdotdash: 'line-dash-dot-dot',
  points: 'line-disconnected',
  solid: 'line-solid',
  dotted: 'line-dot',
};

const LineStylePicker = (props: LineStylePickerProps) => {
  const markOptions = [
    {icon: 'line-solid', value: 'solid', key: 'solid', text: 'Solid'},
    {icon: 'line-dash', value: 'dashed', key: 'dashed', text: 'Dashed'},
    {
      icon: 'line-dash-dot',
      value: 'dotdash',
      key: 'dotdash',
      text: 'Dot Dashed',
    },
    {
      icon: 'line-dash-dot-dot',
      value: 'dotdotdash',
      key: 'dotdotsdash',
      text: 'Dot Dot Dashed',
    },
    {
      icon: 'line-dot',
      value: 'dotted',
      key: 'dotted',
      text: 'Dotted',
    },
    {
      icon: 'line-disconnected',
      value: 'points',
      key: 'points',
      text: 'Points',
    },
  ];

  const activeMarkOption =
    markOptions.find(
      markOption => markOption.value === props.activeLineStyle
    ) || markOptions[0];

  return (
    <Popup
      className="line-style-picker-popup"
      on="click"
      trigger={
        <LegacyWBIcon
          name={activeMarkOption.icon}
          className="line-style-picker"
        />
      }>
      <Button.Group>
        {markOptions.map(markOption => (
          <Button
            size="tiny"
            className="wb-icon-button only-icon"
            key={markOption.key}
            onClick={() => {
              const newMark: Mark = markOption.value as Mark;
              props.setLineStyle(newMark);
            }}>
            <LegacyWBIcon name={markOption.icon} title={markOption.text} />
          </Button>
        ))}
      </Button.Group>
    </Popup>
  );
};

export default LineStylePicker;
