import {fontName} from '@wandb/weave/common/css/globals_deprecated.styles';

export type PlotFontSize = 'xsmall' | 'small' | 'medium' | 'large';
export type PlotFontSizeOrAuto = PlotFontSize | 'auto';

export function isFontSizeOrAuto(s: string): s is PlotFontSizeOrAuto {
  return s === 'small' || s === 'medium' || s === 'large' || s === 'auto';
}

type PlotFontSizeToPx = {[K in PlotFontSize]: number};

// These are the font sizes for the plot axes and the plot legend
export const plotFontSizeToPx: {
  axis: PlotFontSizeToPx;
  legend: PlotFontSizeToPx;
} = {
  axis: {
    xsmall: 8,
    small: 12,
    medium: 14,
    large: 18,
  },
  legend: {
    xsmall: 10,
    small: 12,
    medium: 12,
    large: 14,
  },
};

export function getAxisStyleForFontSize(plotFontSize: PlotFontSize = 'small') {
  return {
    fontFamily: fontName,
    fill: '#6b6b76',
    fontSize: `${plotFontSizeToPx.axis[plotFontSize]}px`,
  };
}
export function getLegendMaxHeightForFontSize(fontSize: PlotFontSize): number {
  const fontSizePx = plotFontSizeToPx.legend[fontSize];
  return Math.round(fontSizePx * 4.33);
}

export const getPlotFontSize = (
  fontSize: PlotFontSize | 'auto',
  panelHeight: number
): PlotFontSize => {
  if (fontSize === 'auto') {
    return getDefaultPlotFontSizeFromHeight(panelHeight);
  }
  return fontSize;
};

const getDefaultPlotFontSizeFromHeight = (
  panelHeight: number
): PlotFontSize => {
  if (panelHeight < 300) {
    return 'small';
  }
  if (panelHeight < 600) {
    return 'medium';
  }
  return 'large';
};
