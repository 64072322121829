import {evaluateExpression, Expression} from '../expr';
import {Line} from './types';

export function evaluateLineExpressionX(
  // the x-axis expression to evaluate
  expression: Expression,
  // the lines plotted on the graph
  lines: Line[],
  // the metrics detected in the expressions
  xExpressionsMetricIds: string[],
  // the value on the point to target with the result
  target: 'x' | 'y' | 'y0'
) {
  const xValsToY = new Map<number, {[key: string]: number}>();
  // we don't need to evaluate any lines that aren't lines for the metrics specified in the expression
  lines
    .filter(
      line => line.metricName && xExpressionsMetricIds.includes(line.metricName)
    )
    .forEach(line => {
      // for each line map all it's values by x-axis
      line.data.forEach(p => {
        const existingRecord = xValsToY.get(p.x);
        if (existingRecord) {
          // we filtered off lines w/out metric names above
          existingRecord[line.metricName!] = p.y;
        } else {
          xValsToY.set(p.x, {[line.metricName!]: p.y});
        }
      });
    });
  // now that we've got the map let's go through and mutate the values
  lines.forEach(line => {
    line.data.forEach(p => {
      if (xValsToY.get(p.x) != null) {
        p[target] = evaluateExpression(
          expression as Expression, // this is safe because we checked for null above
          Object.assign(line.vars ?? {}, xValsToY.get(p.x) ?? {})
        );
      }
    });
  });
}
