import {getType} from 'typesafe-actions';

import {setInShallowClone} from '../../util/utility';
import * as PanelActions from './panel/actions';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import * as PanelBankSectionConfigTypes from './panelBankSectionConfig/types';
import {ActionType, ViewReducerState} from './reducerSupport';

// These functions handle actions that should flag section(s) as `isPanelsAuto: false`.
// There's one for actions processed by immer, and another for ones processed immutably.
// This occurs after the action has been processed in the main action handler.
//
// If an action is also used to undo/redo itself or another action, we must revert the
// flag to its previous value rather than always setting to false; in these cases, an
// `isPanelsAutoForUndoRedo?: {value: boolean | undefined}` must be provided in the
// action payload. Note that `undefined` is a valid value for `isPanelsAuto`, and we
// must distinguish between "set to undefined" and "not provided, set to false by
// default". Actions that have dedicated undo actions (e.g. `undoDeletePanels`) will
// handle reverting the flag directly in the main action handler.
//
// The `addPanelsBySpec` action (for global quick add) is a special case that handles
// setting `isPanelsAuto` in the main action handler. It's similar to `diffAndInitPanels`
// (for auto panel gen), and it's easier to embed `isPanelsAuto` logic directly there.

export const markSectionPanelsAutoImmer = (
  draft: ViewReducerState,
  action: ActionType,
  inverseAction: ActionType
) => {
  let sectionRefs: PanelBankSectionConfigTypes.Ref[] = []; // sections that need to be marked
  let isPanelsAuto: boolean | undefined = false; // new isPanelsAuto value to use

  // get section refs
  switch (action.type) {
    case getType(PanelBankConfigActions.addSection): {
      if (
        inverseAction.type === getType(PanelBankConfigActions.deleteSection)
      ) {
        sectionRefs = [inverseAction.payload.sectionRef];
      }
      break;
    }
    case getType(PanelBankSectionConfigActions.duplicatePanel):
    case getType(PanelBankSectionConfigActions.insertUpdatedPanel): {
      sectionRefs = [action.payload.ref];
      break;
    }
    case getType(PanelBankSectionConfigActions.deletePanel): {
      sectionRefs = [action.payload.ref];
      if (action.payload.isPanelsAutoForUndoRedo) {
        isPanelsAuto = action.payload.isPanelsAutoForUndoRedo.value;
      }
      break;
    }
    case getType(PanelActions.deletePanels): {
      if (inverseAction.type === getType(PanelActions.undoDeletePanels)) {
        const {deletePanelsResult} = inverseAction.payload;
        sectionRefs = deletePanelsResult.map(({sectionRef}) => sectionRef);
      }
      if (action.payload.isPanelsAutoForUndoRedo) {
        isPanelsAuto = action.payload.isPanelsAutoForUndoRedo.value;
      }
      break;
    }
    case getType(PanelBankConfigActions.movePanel):
    case getType(PanelBankConfigActions.movePanelToNewSection): {
      if (
        inverseAction.type ===
        getType(PanelBankConfigActionsInternal.undoMovePanel)
      ) {
        const {toSectionRef, fromSectionRef} = inverseAction.payload;
        sectionRefs = [toSectionRef, fromSectionRef];
      }
      break;
    }
  }

  // update isPanelAuto values
  sectionRefs.forEach(sectionRef => {
    draft.parts[sectionRef.type][sectionRef.id].isPanelsAuto = isPanelsAuto;
  });
};

export const markSectionPanelsAutoImmutable = (
  state: ViewReducerState,
  action: ActionType,
  inverseAction: ActionType
): ViewReducerState => {
  let sectionRefs: PanelBankSectionConfigTypes.Ref[] = []; // sections that need to be marked
  let isPanelsAuto: boolean | undefined = false; // new isPanelsAuto value to use

  // get section refs
  switch (action.type) {
    case getType(PanelBankSectionConfigActions.addPanelByRef):
    case getType(PanelBankSectionConfigActions.addPanelByConfig):
    case getType(PanelBankSectionConfigActions.addPanelsByConfig): {
      sectionRefs = [action.payload.ref];
      break;
    }
    case getType(PanelBankSectionConfigActions.updateName): {
      sectionRefs = [action.payload.ref];
      if (action.payload.isPanelsAutoForUndoRedo) {
        isPanelsAuto = action.payload.isPanelsAutoForUndoRedo.value;
      }
      break;
    }
  }

  // update isPanelAuto values
  let newState = state;
  sectionRefs.forEach(sectionRef => {
    newState = setInShallowClone(
      newState,
      ['parts', sectionRef.type, sectionRef.id, 'isPanelsAuto'],
      isPanelsAuto
    );
  });

  return newState;
};
