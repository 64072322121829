import {
  AreaPoint,
  isSafeAreaPoint,
  isSafePoint,
  Point,
  SafeAreaPoint,
} from './types';

export function distributePoints(points: Point[]) {
  const safePoints: Map<number, {y: number[]}> = new Map();

  points.forEach(p => {
    if (!isSafePoint(p)) {
      return;
    }

    const po = safePoints.get(p.x);
    if (po) {
      po.y.push(Number(p.y));
    } else {
      safePoints.set(p.x, {y: [Number(p.y)]});
    }
  });

  return Array.from(safePoints)
    .map(([x, b]) => ({x, y: b.y}))
    .sort((a, b) => a.x - b.x);
}

export function distributeAreaPoints(areaPoints: AreaPoint[]) {
  const points: Map<number, {min: number[]; max: number[]}> = new Map();

  areaPoints.forEach(p => {
    if (!isSafeAreaPoint(p)) {
      return;
    }

    const point = points.get(p.x);
    if (point) {
      point.min.push(p.y);
      point.max.push(p.y0);
    } else {
      points.set(p.x, {min: [p.y], max: [p.y0]});
    }
  });

  return Array.from(points)
    .reduce((acc, [i, p]) => {
      if (p) {
        acc.push({
          x: i,
          y: Math.min(...p.min),
          y0: Math.max(...p.max),
        });
      }
      return acc;
    }, [] as SafeAreaPoint[])
    .sort((a, b) => a.x - b.x);
}
