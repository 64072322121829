import {makeTrackEvent} from '../makeTrackEvent';
import {
  ReportEventNames,
  ReportMode,
  ReportRedirectPath,
  ReportType,
} from './types';

type CreateReportStartedData = {
  entityName: string;
  location: string;
  projectName: string;
};
export const trackCreateReportStarted = makeTrackEvent<
  CreateReportStartedData,
  {
    entityName: {
      description: 'The name of the entity that the report is being created for.';
      exampleValues: ['team-one'];
    };
    location: {
      description: 'The location in the app where the report is being created.';
      exampleValues: ['Team overview page', 'Profile page'];
    };
    projectName: {
      description: 'The name of the project that the report is being created for.';
      exampleValues: ['project-one'];
    };
  } & {
    _description: 'A user has clicked a prompt to create a report';
    _location: 'The create report prompt appears on the home, profile, team, and workspace pages';
    _motivation: 'To track report creation usage based on location';
  }
>(ReportEventNames.CreateReportStarted);

type ReportWdithOptionClickedData = {
  width: string;
  isPublicProject: boolean;
};
export const reportWidthOptionClicked = makeTrackEvent<
  ReportWdithOptionClickedData,
  {
    width: {
      description: 'The report width option the user clicked on in the report header.';
      exampleValues: ['readable', 'fixed', 'fluid', 'profile'];
    };
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
  } & {
    _description: 'A user has clicked on one of the options to change the width of the report.';
    _location: 'The resize options appear as icon buttons on the report page when in edit mode.';
    _motivation: 'To track the report width options a user selects while editing the report.';
  }
>(ReportEventNames.ReportWidthOptionClicked);

type ReportTipsSidebarClickedData = {
  isPublicProject: boolean;
};
export const reportTipsSidebarClicked = makeTrackEvent<
  ReportTipsSidebarClickedData,
  {
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
  } & {
    _description: 'A user has clicked on the icon to "Get started with reports".';
    _location: 'The icon appears on the report page header in edit mode.';
    _motivation: 'To track how often a user wants to view the report tips sidebar.';
  }
>(ReportEventNames.ReportTipsSidebarClicked);

type ReportGrammarlyNoticeDisplayedData = {
  isPublicProject: boolean;
};
export const reportGrammarlyNoticeDisplayed = makeTrackEvent<
  ReportGrammarlyNoticeDisplayedData,
  {
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
  } & {
    _description: 'If a user has the grammarly plugin turned on, it will trigger a warning banner.';
    _location: 'The banner is displayed in the edit report page.';
    _motivation: 'Grammarly and similar browser plugins can cause reports to be slow.';
  }
>(ReportEventNames.ReportGrammarlyNoticeDisplayed);

type ReportSubmittedData = {
  viewId?: string;
  entityName: string;
  projectName?: string;
  reportType: ReportType;
  isPublicProject: boolean;
};
export const reportSubmitted = makeTrackEvent<
  ReportSubmittedData,
  {
    viewId: {
      description: "The current report view object's id.";
      exampleValues: ['Vmlldzo0MDM0MzM2', undefined];
    };
    entityName: {
      description: 'The name of the entity that the report is being submitted under.';
      exampleValues: ['team-one'];
    };
    projectName: {
      description: 'The name of the project that the report is being submitted under.';
      exampleValues: ['project-one', undefined];
    };
    reportType: {
      description: 'The value indicates the state of the report before it was submitted.';
      exampleValues: ['gallery post edit', 'report draft', 'published report'];
    };
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
  } & {
    _description: 'A user clicked on the submit button on the report page.';
    _location: 'The submit button is rendered in the edit report page header.';
    _motivation: 'To track how many people are hitting the submit button in the report page.';
  }
>(ReportEventNames.ReportSubmitted);

type ReportPageVisitedData = {
  reportId?: string;
  entityName?: string;
  projectName?: string;
  reportMode: ReportMode;
  isPublicProject?: boolean;
  redirectedTo?: ReportRedirectPath;
};
// Note: submitting a report will cause this event to fire
export const reportPageVisited = makeTrackEvent<
  ReportPageVisitedData,
  {
    reportId: {
      description: 'The unique identifier for a published report.';
      exampleValues: ['some-test-report-id'];
    };
    reportMode: {
      description: 'The value indicates if this action occurred while user was editing or viewing a report.';
      exampleValues: ['view', 'edit'];
    };
    entityName: {
      description: 'The name of the entity that the report is being created for.';
      exampleValues: ['team-one'];
    };
    projectName: {
      description: 'The name of the project that the report is being created for.';
      exampleValues: ['project-one'];
    };
  } & {
    _description: 'This event is fired when a report url is visited.';
    _location: 'This is in the report page router component.';
    _motivation: 'To track how often a report url is visited and use other events to determine if there is page redirection.';
  }
>(ReportEventNames.ReportPageVisited);

type EditReportStartedWithImportPanelOpenData = {
  entityName?: string;
  projectName?: string;
};
export const editReportStartedWithImportPanelOpen = makeTrackEvent<
  EditReportStartedWithImportPanelOpenData,
  {
    entityName: {
      description: 'The name of the entity that the report is being created for';
      exampleValues: ['team-one'];
    };
    projectName: {
      description: 'The name of the project that the report is being created for';
      exampleValues: ['project-one'];
    };
  } & {
    _description: 'A user is redirected to a first draft report and the import panel popup is opened by default.';
    _location: 'This is currently triggered when a clicks on the "Create report" button in the Create Report Prompt.';
    _motivation: 'To track how often the user sees the import panel popup open by default.';
  }
>(ReportEventNames.EditReportStartedWithImportPanelOpen);

type ReportPageErrorData = {
  reportMode: ReportMode;
  reportNameAndID: string;
  entityName?: string;
  projectName?: string;
  errorMessage: string;
  errorName: string;
  errorStack?: string;
};
export const reportPageError = makeTrackEvent<
  ReportPageErrorData,
  {
    reportMode: {
      description: 'The value indicates if this action occurred while user was editing or viewing a report.';
      exampleValues: ['view', 'edit'];
    };
    reportNameAndID: {
      description: 'The reportNameAndID param in the report url.';
      exampleValues: ['Untitled-Report--Vmlldzo0MTA2MjEw'];
    };
    entityName: {
      description: 'The name of the entity that the report is being created for.';
      exampleValues: ['team-one'];
    };
    projectName: {
      description: 'The name of the project that the report is being created for.';
      exampleValues: ['project-one'];
    };
    errorMessage: {
      description: 'The message of the error that occurred.';
      exampleValues: [
        '’Failed to execute "insertBefore" on "Node": The node before which the new node is to be inserted is not a child of this node.’'
      ];
    };
    errorName: {
      description: 'The name of the error that occurred.';
      exampleValues: ['project-one'];
    };
    errorStack: {
      description: 'This error stack that occurred. ';
      exampleValues: [
        'Error: Failed to execute "insertBefore" on "Node": The node before which the new node is to be inserted is not a child of this node. a'
      ];
    };
  } & {
    _description: 'An error occurred when a user visited a report url.';
    _location: 'This is in the report files.';
    _motivation: 'To track how often users see a reports related error.';
  }
>(ReportEventNames.ReportPageError);
