// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  > * + * {
    margin-left: 10px;
  }
`;
