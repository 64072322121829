import {Button} from '@wandb/weave/components';
import React, {useCallback, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {toast} from 'react-toastify';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Icon, Modal} from 'semantic-ui-react';

import NoMatch from '../../../components/NoMatch';
import {Skeleton} from '../../../components/Skeleton';
import {
  useAddRolePermissionsMutation,
  useDeleteRolePermissionsMutation,
  useUpdateRoleMutation,
} from '../../../generated/graphql';
import {extractErrorMessageFromApolloError} from '../../../util/errors';
import {orgRoles} from '../../../util/urls';
import {CustomRoleForm} from './CustomRoleForm';
import {useOrgRolesContext} from './OrgRolesContext';
import {CustomRole, Permission} from './useOrgRolesContextResult';

export const OrgRolesEdit: React.FC = () => {
  const history = useHistory();
  const {loading, refetch, data} = useOrgRolesContext();

  const {roleId} = useParams<{roleId: string}>();
  const customRole = data.customRoles.find(r => r.id === roleId);
  const [editedRole, setEditedRole] = useState<Omit<CustomRole, 'id'>>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [updateRoleMutation] = useUpdateRoleMutation();
  const [addPermission] = useAddRolePermissionsMutation();
  const [deletePermission] = useDeleteRolePermissionsMutation();
  const updateRole = useCallback(
    async (role: Omit<CustomRole, 'id'>) => {
      if (!customRole?.id || !customRole.permissions) {
        return;
      }

      const oldPermissions: {
        [key: string]: Permission;
      } = {};
      for (const p of customRole.permissions) {
        oldPermissions[p.name] = p;
      }
      const newPermissions = role.permissions.map(p => p.name);

      const permissionsToAdd: string[] = [];
      for (const p of newPermissions) {
        if (!oldPermissions[p]) {
          permissionsToAdd.push(p);
        } else {
          delete oldPermissions[p];
        }
      }
      const permissionsToDelete = Object.keys(oldPermissions);

      try {
        const promises: Array<Promise<any>> = [
          updateRoleMutation({
            variables: {
              roleID: customRole.id,
              name: role.name,
              description: role.description,
              inheritedFrom: role.inheritedFrom,
            },
          }),
        ];
        if (permissionsToAdd.length > 0) {
          promises.push(
            addPermission({
              variables: {
                roleID: customRole.id,
                permissions: permissionsToAdd,
              },
            })
          );
        }
        if (permissionsToDelete.length > 0) {
          promises.push(
            deletePermission({
              variables: {
                roleID: customRole.id,
                permissions: permissionsToDelete,
              },
            })
          );
        }
        await Promise.all(promises);
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err);
        toast(`Error updating role: ${errMsg}`);
        setIsModalOpen(false);
      }
      refetch();
      setIsModalOpen(false);
      history.push(orgRoles(data.orgName));
      toast('Successfully updated role');
    },
    [
      customRole?.id,
      customRole?.permissions,
      updateRoleMutation,
      addPermission,
      deletePermission,
      data.orgName,
      history,
      refetch,
    ]
  );

  if (loading) {
    return <Skeleton className="h-screen max-w-5xl" />;
  }

  return !loading && !customRole ? (
    <NoMatch />
  ) : (
    <>
      <CustomRoleForm
        customRole={customRole}
        handleSubmit={role => {
          setEditedRole(role);
          if (role.inheritedFrom !== customRole?.inheritedFrom) {
            setIsModalOpen(true);
          } else {
            updateRole(role);
          }
        }}
      />
      <Modal size="small" open={isModalOpen}>
        <Modal.Header>
          <Icon name="exclamation triangle" />
          Warning
        </Modal.Header>
        <Modal.Content>
          <p>
            You are changing the inherited role from{' '}
            <b>{customRole?.inheritedFrom}</b> to{' '}
            <b>{editedRole?.inheritedFrom}</b>. This will change the custom role
            in a major way. We recommend notifying users before making the
            change.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="mr-8 hover:bg-moon-100 hover:outline-none"
            variant="secondary"
            onClick={() => {
              setIsModalOpen(false);
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!editedRole) {
                return;
              }
              updateRole(editedRole);
            }}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
