import {debounce} from 'lodash';
import {useMemo, useState} from 'react';
import type {ObservedSize} from 'use-resize-observer';
import useResizeObserver from 'use-resize-observer';

export function useResizeObserverDebounced<T extends HTMLElement>(
  waitTime: number
): ReturnType<typeof useResizeObserver<T>> {
  const [size, setSize] = useState<ObservedSize>({
    width: 0,
    height: 0,
  });

  const onResize = useMemo(
    () => debounce(setSize, waitTime, {leading: true}),
    [waitTime]
  );

  const {ref} = useResizeObserver<T>({onResize});

  return {ref, ...size};
}
