import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const MAX_CHARS_PER_LINE = 70;

export const themes = {
  dark: {
    background: '#202020',
    popupBackground: '#3a3a3a',
    popupHover: '#484848',
    popupBorder: '#202020',
    clickable: '#323232',
    focused: '#424242',
    text: globals.white,
    query: '#56acfc',
    type: '#d68400',
    arg: '#50d8b7',
    param: '#a9ddf1',
    delete: '#777',
    indenter: '#4a4a4a',
  },
  light: {
    background: globals.white,
    popupBackground: '#fafafa',
    popupHover: globals.white,
    popupBorder: '#ccc',
    clickable: '#f2f2f2',
    focused: '#eaeaea',
    text: '#000',
    query: '#006cd1',
    type: '#d68400',
    arg: '#009926',
    param: '#910a71',
    delete: '#777',
    indenter: '#dadada',
  },
  carey: {
    background: '#fafafa',
    popupBackground: globals.white,
    popupHover: '#f8f8f8',
    popupBorder: '#ccc',
    clickable: '#f0f0f0',
    focused: '#eaeaea',
    text: '#1a1a1a',
    query: globals.blue,
    arg: '#008a4b',
    type: '#858100',
    param: '#8031ad',
    delete: '#777',
    indenter: '#dadada',
  },
};

export const Wrapper = styled.div`
  white-space: nowrap;
  background: ${props => props.theme.background};
  color: ${props => props.theme.text};
  font-family: Inconsolata;
  line-height: 26px;
  overflow: auto;
`;

export const InnerWrapper = styled.div`
  padding: 16px;
  width: fit-content;
`;

export const QuerySpan = styled.span`
  color: ${props => props.theme.query};
`;

export const TypeSpan = styled.span`
  color: ${props => props.theme.type};
`;

export const ArgSpan = styled.span`
  color: ${props => props.theme.arg};
`;

export const ParamSpan = styled.span`
  line-height: 20px;
  color: ${props => props.theme.param};
`;

export const Fadeable = styled.span<{fade: boolean}>`
  opacity: ${props => (props.fade ? 0.4 : 1)};
`;
