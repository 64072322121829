import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const CallToActionButton = styled.a`
  display: flex;
  align-items: center;
  color: ${globals.SIENNA_600};
  font-weight: 800;
  &:hover {
    color: ${globals.SIENNA_500};
  }
`;

export const IconWrapper = styled.div`
  font-size: 24px;
`;
