import {createAction} from 'typesafe-actions';

import {
  PanelBankFlowSectionConfig,
  SectionPanelSorting,
} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {GridLayout} from '../../../util/panelbankGrid';
import {LayedOutPanel} from '../../../util/panelTypes';
import * as PanelTypes from '../panel/types';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as WorkspaceSettingsTypes from '../workspaceSettings/types';
import * as Types from './types';

// Toggle between 'flow' and 'grid' modes
export const toggleType = createAction(
  '@view/panelBankSectionConfig/toggleType',
  action => (ref: Types.Ref) => action({ref})
);

// Toggle expanded/collapsed state
export const toggleIsOpen = createAction(
  '@view/panelBankSectionConfig/toggleIsOpen',
  action => (ref: Types.Ref) => action({ref})
);

// Rename the section
export const updateName = createAction(
  '@view/panelBankSectionConfig/updateName',
  action =>
    (
      ref: Types.Ref,
      newName: string,
      isPanelsAutoForUndoRedo?: {value: boolean | undefined},
      /**
       * for undo, prev panel.isAuto vals must be provided to allow setting
       * panels back to their original values before the section was renamed
       */
      panelAutoVals?: Array<boolean | undefined>
    ) =>
      action({ref, newName, isPanelsAutoForUndoRedo, panelAutoVals})
);

// Handles box-resizing and changing rows per page in flow sections ('standard layout')
export const updateFlowConfig = createAction(
  '@view/panelBankSectionConfig/updateFlowConfig',
  action =>
    (
      ref: Types.Ref,
      newFlowConfig?: Partial<PanelBankFlowSectionConfig>,
      /**
       * whether to PATCH (merge changes) or PUT (replace whole config);
       * PUT is required on undo in order to revert values to undefined
       */
      operation: 'PATCH' | 'PUT' = 'PATCH'
    ) =>
      action({ref, newFlowConfig, operation})
);

// Handles saving box layout in grid sections ('custom layout')
export const setGridLayout = createAction(
  '@view/panelBankSectionConfig/setGridLayout',
  action => (ref: Types.Ref, newGridLayout: GridLayout) =>
    action({ref, newGridLayout})
);

// Add a panel to this section by ref
export const addPanelByRef = createAction(
  '@view/panelBankSectionConfig/addPanelByRef',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref, // the panel we're adding
      fatPanel?: boolean,
      callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
    ) =>
      action({ref, panelRef, fatPanel, callbackFn})
);

// Add a panel to this section by panel config
export const addPanelByConfig = createAction(
  '@view/panelBankSectionConfig/addPanelByConfig',
  action =>
    (
      ref: Types.Ref,
      panel: LayedOutPanel, // the panel we're adding
      fatPanel?: boolean,
      callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
    ) =>
      action({ref, panel, fatPanel, callbackFn})
);

// Add multiple panels to this section by panel config
export const addPanelsByConfig = createAction(
  '@view/panelBankSectionConfig/addPanelsByConfig',
  action => (ref: Types.Ref, panels: LayedOutPanel[]) => action({ref, panels})
);

export const deletePanel = createAction(
  '@view/panelBankSectionConfig/deletePanel',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref, // the panel we're deleting
      panelBankConfigRef?: PanelBankConfigTypes.Ref, // the panel bank config ref (optional, only neccessary if we're dealing with hidden panels)
      workspaceSettingsRef?: WorkspaceSettingsTypes.Ref, // to check panel auto-gen setting; don't need to use hidden panels if auto-gen off
      isPanelsAutoForUndoRedo?: {value: boolean | undefined}
    ) =>
      action({
        ref,
        panelRef,
        panelBankConfigRef,
        workspaceSettingsRef,
        isPanelsAutoForUndoRedo,
      })
);

export const duplicatePanel = createAction(
  '@view/panelBankSectionConfig/duplicatePanel',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref // the panel we're duplicating
    ) =>
      action({ref, panelRef})
);

export const sortPanels = createAction(
  '@view/panelBankSectionConfig/sortPanels',
  action => (refs: Types.Ref[]) => action(refs)
);

export const setSectionPanelRefsAndUndoSortingSetting = createAction(
  '@view/panelBankSectionConfig/setSectionPanelRefsAndUndoSortingSetting',
  action =>
    (
      refs: Types.Ref[],
      orderedPanelRefs: PanelTypes.Ref[][],
      sectionSortings: SectionPanelSorting[]
    ) =>
      action({refs, orderedPanelRefs, sectionSortings})
);

export const insertUpdatedPanel = createAction(
  '@view/panelBankSectionConfig/insertUpdatedPanel',
  action =>
    (ref: Types.Ref, fromPanelRef: PanelTypes.Ref, panelRef: PanelTypes.Ref) =>
      action({ref, fromPanelRef, panelRef})
);

export const undoInsertUpdatedPanel = createAction(
  '@view/panelBankSectionConfig/undoInsertUpdatedPanel',
  action =>
    (
      ref: Types.Ref,
      panel: PanelTypes.Panel,
      panelRef: PanelTypes.Ref,
      isPanelsAuto: boolean | undefined
    ) =>
      action({ref, panel, panelRef, isPanelsAuto})
);

export const updateLinePlotSectionSettings = createAction(
  '@view/sectionSettings/linePlot/update',
  action => (ref: Types.Ref, settings: Partial<LinePlotSettings> | undefined) =>
    action({ref, settings})
);
