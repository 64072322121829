import gql from 'graphql-tag';

export const ACCOUNT_PRIVILEGES = gql`
  query AccountPrivileges($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      subscriptions {
        id
        privileges
        plan {
          id
          planType
        }
      }
    }
  }
`;
