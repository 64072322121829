import {Omit} from '@wandb/weave/common/types/base';

import * as RunTypes from './runTypes';

// The current config definition. This should alias the most recent version.
export type Config = ConfigV2;

export const EMPTY_CONFIG = {
  version: 2,
  columnVisible: {
    'run:name': false,
  },
  columnPinned: {},
  columnWidths: {},
  columnOrder: [],
  pageSize: 20,
  onlyShowSelected: false,
};

// We need to preserve the entire evolution of the config type as not all
// configs have been upgraded in the database. Until then, the frontend needs
// the historical config types so it can upgrade them to the latest version.

export type ConfigV0 = Partial<Config> & {
  baseCols?: RunTypes.Key[];
  configColsAuto?: boolean;
  configCols?: RunTypes.Key[];
  summaryColsAuto?: boolean;
  summaryCols?: RunTypes.Key[];
  pinnedColumnKeys?: string[];
  userOrder?: RunTypes.Key[];
};

export type ConfigV1 = Omit<ConfigV2, 'version'>;

export interface ConfigV2 {
  // Metadata
  version: number;

  // Column configuration
  columnVisible: {[key: string]: boolean};
  columnPinned: {[key: string]: boolean};
  columnWidths: {[key: string]: number}; // column.accessor => width
  columnOrder: string[]; // column.accessor[]
  columnConfigModified?: boolean;

  // Other stuff
  onlyShowSelected?: boolean;
  pageSize?: number;
  pinnedColumnsShown?: number;
  aggregationVisible?: {[keyName: string]: RunTypes.RunKeySection};
}
