import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import {UserAccountType} from '../../generated/graphql';
import {BenchmarkProject} from '../../types/graphql';
import {AccessOptions} from '../../util/permissions';
import {useQuery, UseQueryOptions} from './query';

// Weaveflow check fragment
// Looks for the existence of the "stream" run, to determine if the project is a weaveflow project
const WEAVEFLOW_CHECK_FRAGMENT = gql`
  fragment WeaveflowCheckFragment on Project {
    weaveRun: run(name: "__weave_trace_server__") {
      id
    }
  }
`;

///// Query definition
export const PROJECT_PAGE_QUERY = gql`
  query ProjectPage($projectName: String!, $entityName: String) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      entityName
      lastActive
      access
      description
      readOnly
      totalRuns: runCount
      fromV2
      computeHours
      totalSweeps
      totalUsers
      isBenchmark
      views
      reports: allViews(viewType: "runs") {
        totalCount
      }
      user {
        id
        name
        username
        photoUrl
        accountType
      }
      linkedBenchmark {
        id
        entityName
        name
        gitHubSubmissionRepo
        views
      }
      entity {
        id
        name
        defaultAccess
        readOnlyAdmin
        privateOnly
        isTeam
        claimingEntity {
          id
          name
        }
      }
      ...WeaveflowCheckFragment
    }
  }

  ${WEAVEFLOW_CHECK_FRAGMENT}
`;

export interface ProjectData {
  id: string;
  name: string;
  entityName: string;
  description: string;
  lastActive: string;
  readOnly: boolean;
  access: AccessOptions;
  totalRuns: number;
  computeHours: number;
  totalSweeps: number;
  totalUsers: number;
  selectedCount: number;
  isBenchmark: boolean;
  linkedBenchmark: BenchmarkProject;
  views: string;
  user: {
    id: string;
    username: string;
    name: string;
    photoUrl: string | null;
    accountType: UserAccountType | null;
  } | null;
  reports: {
    totalCount: number;
  };
  entity: {
    id: string;
    name: string;
    defaultAccess: AccessOptions;
    readOnlyAdmin: boolean;
    privateOnly: boolean;
    isTeam: boolean;
    claimingEntity: {
      id: string;
      name: string;
    };
  };
}

export interface ProjectPageQueryData {
  project: ProjectData;
}

export function useProjectPageQuery(
  variables: Generated.ProjectPageQueryVariables,
  options: UseQueryOptions
) {
  return useQuery<ProjectPageQueryData, typeof variables>(PROJECT_PAGE_QUERY, {
    ...options,
    variables,
  });
}
