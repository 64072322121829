import {WBMenuOption} from '@wandb/ui';
import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {WBSuggesterOptionFetcher} from '@wandb/weave/common/components/elements/WBSuggester';
import React, {useContext} from 'react';

import * as ApiSchemaTypes from '../../generated/apiSchema';
import {RunQueryContext} from '../../state/runs/context';
import * as Filter from '../../util/filters';
import {RunFilters} from '../RunSelector/RunsFiltersNoRef';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import InlineListEditor from './InlineListEditor';
import InlineNumberEditor from './InlineNumberEditor';
import InlineStringEditor from './InlineStringEditor';
import * as S from './QueryInputValueEditor.styles';

interface QueryInputValueEditorProps {
  type: ApiSchemaTypes.__Type;
  val: any;
  disabled?: boolean;
  isTemplate?: boolean;
  autocompleteOptions?: WBMenuOption[] | WBSuggesterOptionFetcher;
  indentLevel: number;
  charsBefore: number;
  setVal(newVal: any): void;
}

const QueryInputValueEditor: React.FC<QueryInputValueEditorProps> = ({
  val,
  disabled,
  isTemplate,
  type: propsType,
  autocompleteOptions,
  indentLevel,
  charsBefore,
  setVal,
}) => {
  let type = propsType;
  if (type.kind === 'NON_NULL') {
    type = type.ofType!;
  }

  const {entityName, projectName} = useContext(RunQueryContext);
  return (
    <span>
      {isTemplate ? (
        <InlineStringEditor
          value={val || ''}
          disabled={true}
          setValue={v => setVal(v)}
        />
      ) : type.kind === 'LIST' ? (
        <InlineListEditor
          type={type.ofType!}
          val={val}
          setVal={(v: any) => setVal(v)}
          indentLevel={indentLevel}
          charsBefore={charsBefore}
          autocompleteOptions={autocompleteOptions}
        />
      ) : type.kind === 'SCALAR' ? (
        type.name! === 'String' ? (
          <InlineStringEditor
            value={val || ''}
            disabled={disabled}
            setValue={v => setVal(v)}
            autocompleteOptions={autocompleteOptions}
          />
        ) : type.name! === 'Int' ? (
          // This doesn't render inline
          <InlineNumberEditor
            value={val}
            setValue={v => setVal(v)}
            min={1}
            max={500}
          />
        ) : type.name! === 'RunFilter' ? (
          entityName != null && projectName != null ? (
            <RunFilters
              entityName={entityName}
              projectName={projectName}
              filters={val || Filter.EMPTY_FILTERS}
              setFilters={v => setVal(v)}
              onFiltersChanged={() => console.log('onFiltersChanged')}
              preventOverflow
              trigger={(open, filterCount) => (
                <S.InlineRunFiltersButton open={open}>
                  {filterCount} filters
                  <LegacyWBIcon name="next" />
                </S.InlineRunFiltersButton>
              )}
            />
          ) : (
            'INVALID'
          )
        ) : (
          // <input />
          'UNKNOWN_SCALAR'
        )
      ) : (
        'UNKNOWN'
      )}
    </span>
  );
};

export default QueryInputValueEditor;
