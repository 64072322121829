import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {useContext} from 'react';
import {Route, Switch} from 'react-router';

import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {envIsPublicCloud} from '../../../config';
import {
  ORG_ROLE_EDIT,
  ORG_ROLES_NEW,
  ORG_SETTINGS,
} from '../../../routes/paths';
import {OrganizationRolesContext} from './OrgRolesContext';
import {OrgRolesDashboard} from './OrgRolesDashboard';
import {OrgRolesEdit} from './OrgRolesEdit';
import {OrgRolesNew} from './OrgRolesNew';
import {useOrgRolesContextResult} from './useOrgRolesContextResult';

export const DEFAULT_ONPREM_ORGANIZATION_NAME = 'server';

export const RolesDashboard = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const orgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;
  const orgRolesContextResult = useOrgRolesContextResult(orgName);
  return (
    <Tailwind>
      <OrganizationRolesContext.Provider value={orgRolesContextResult}>
        <Switch>
          <Route path={ORG_SETTINGS} exact>
            <OrgRolesDashboard />
          </Route>
          <Route path={ORG_ROLES_NEW} exact>
            <OrgRolesNew />
          </Route>
          <Route path={ORG_ROLE_EDIT} exact>
            <OrgRolesEdit />
          </Route>
        </Switch>
      </OrganizationRolesContext.Provider>
    </Tailwind>
  );
};
