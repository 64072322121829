import React, {createContext, useContext} from 'react';

import {Timestep} from '../../util/plotHelpers/types';
import {logError} from './../../services/errors/errorReporting';

type PanelTimeContextType = {
  setTimeFactor: (timeFactor: Timestep | null) => void;
  timeFactor: Timestep | null;
};

export const PanelTimeContext = createContext<PanelTimeContextType | null>(
  null
);

export const PanelTimeProvider = React.memo(
  ({children}: {children: React.ReactNode}) => {
    const [timeFactor, setTimeFactorState] = React.useState<Timestep | null>(
      null
    );

    const value = React.useMemo(
      () => ({
        setTimeFactor: setTimeFactorState,
        timeFactor,
      }),
      [timeFactor]
    );
    return (
      <PanelTimeContext.Provider value={value}>
        {children}
      </PanelTimeContext.Provider>
    );
  }
);
PanelTimeProvider.displayName = 'PanelTimeProvider';

export function usePanelTimeContext() {
  const context = useContext(PanelTimeContext);

  if (!context) {
    logError(
      new Error('usePanelTimeContext must be used within a PanelTimeProvider')
    );

    return {
      setTimeFactor: () => {},
      timeFactor: null,
    };
  }

  return context;
}
