import {ReactNode} from 'react';
import {createAction} from 'typesafe-actions';

import {Tag} from '../../util/gallery/shared';

export const displayError = createAction(
  '@global/displayError',
  action => (error: any) => action({error})
);

export const resetError = createAction(
  '@global/resetError',
  action => () => action({})
);

export const displayErrorPortal = createAction(
  '@global/displayErrorPortal',
  action => (content: ReactNode) => action({content})
);

export const resetErrorPortal = createAction(
  '@global/resetErrorPortal',
  action => () => action({})
);

export const setFlash = createAction(
  '@global/setFlash',
  action => (flash: any) => action({flash})
);

export const setFullScreen = createAction(
  '@global/setFullScreen',
  action => (fullScreen: boolean) => action({fullScreen})
);

export const setGalleryTags = createAction(
  '@global/setGalleryTags',
  action => (tags: Tag[]) => action({tags})
);
