import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {TransitionablePortal} from 'semantic-ui-react';

interface ErrorPortalProps {
  open: boolean;
}

const ErrorPortal: React.FC<ErrorPortalProps> = ({open, children}) => {
  return (
    <TransitionablePortal
      open={open}
      closeOnDocumentClick={false}
      closeOnEscape={false}>
      <div className="error-portal">
        <div className="error-portal-content">{children}</div>
      </div>
    </TransitionablePortal>
  );
};

export default ErrorPortal;
