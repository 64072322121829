import {omit as _omit} from 'lodash';

import {denormFn, normFn} from '../normalizerSupport';
import * as PanelBankSectionConfigNormalize from '../panelBankSectionConfig/normalize';
import * as Types from './types';

const omit = _omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize = normFn<Types.PanelBankConfigObjSchema>(
  'panel-bank-config',
  (whole, ctx) => {
    return {
      ...omit(whole, 'sections'),
      sectionRefs:
        whole.sections != null
          ? whole.sections.map(section =>
              PanelBankSectionConfigNormalize.normalize(section, ctx)
            )
          : [],
    };
  }
);

export const denormalize = denormFn<Types.PanelBankConfigObjSchema>(
  (part, ctx) => {
    return {
      ...omit(part, 'sectionRefs'),
      sections:
        part.sectionRefs != null
          ? part.sectionRefs.map((ref, i) =>
              PanelBankSectionConfigNormalize.denormalize(ref, ctx, i)
            )
          : [],
    };
  }
);
