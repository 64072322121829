import {useCallback} from 'react';

import {
  envIsDev,
  envIsIntegration,
  envIsLocal,
  envIsPublicCloud,
} from '../config';
import {
  useUpdateUserInfoMutation,
  useViewerUserInfoQuery,
} from '../generated/graphql';
import {Analytics} from '../services/analytics';
import {useViewer} from '../state/viewer/hooks';
import {Viewer} from '../state/viewer/types';
import {UserInfo} from '../types/graphql';
import {BetaFeature, BetaFeatureKey} from './betaFeatures';

export function useBetaFeatureUnicornModeEnabled(): boolean {
  return useBetaFeature('unicorn-plot').isEnabled;
}

export function useBetaFeatureNightModeEnabled(): boolean {
  return useBetaFeature('night').isEnabled;
}

export function useBetaFeatureVega3Enabled(): boolean {
  return useBetaFeature('vega3').isEnabled;
}

export function useBetaFeatureWeave1(): boolean {
  // We previously had a 'weave1' flag, allowing users to opt into the new execution engine.
  // It had some rules for default value that depended on whether the user was an employee.
  // Now, everyone gets weave1 unless they are an employee who specifically enabled weave0.
  const {userSelectedValue: isWeave0} = useBetaFeature('weave0');
  if (isWeave0 === undefined) {
    return true;
  }
  return !isWeave0;
}

export function useBetaFeatureRunOverviewTree(): boolean {
  return useBetaFeature('run-overview-tree').isEnabled;
}

export function useBetaFeatureMakeContextPerfLogging(): boolean {
  return useBetaFeature('makecontext-perf-logging').isEnabled && envIsDev;
}

export function useBetaFeatureAccountSettingsTabsSelector(): boolean {
  // Rolled out to non local
  return !envIsLocal;
}

export function useBetaFeatureWeavePythonEcosystem(): boolean {
  return useBetaFeature('weave-python-ecosystem').isEnabled;
}

export function useBetaFeatureDisableAccountEnforcementBanners(): boolean {
  return useBetaFeature('disable-account-enforcement-banners').isEnabled;
}

export function useViewerIsWandbEmployee() {
  const viewer = useViewer();
  return getViewerIsWandbEmployee(viewer);
}

export function getViewerIsWandbEmployee(viewer: Viewer | undefined): boolean {
  if (viewer == null) {
    return false;
  }
  return (
    envIsPublicCloud &&
    (viewer.admin ||
      viewer.email?.endsWith('@wandb.ai') ||
      viewer.email?.endsWith('@wandb.com'))
  );
}

export const isBetaFeatureEnabled = (
  featureKey: BetaFeatureKey,
  viewer: Viewer | undefined,
  userInfo: {betaFeatures?: UserInfo['betaFeatures']} | undefined
) => {
  const userInfoBetaFeatures = userInfo?.betaFeatures;
  const viewerIsWandbEmployee = getViewerIsWandbEmployee(viewer);

  let userSelectedValue = userInfoBetaFeatures?.[featureKey];

  const enableForCI =
    envIsIntegration &&
    viewer != null &&
    featureKey !== 'weave-devpopup' &&
    // Please do not change this flag without talking to the Weave team.
    (featureKey !== 'weave0' || envIsLocal) &&
    featureKey !== 'disable-account-enforcement-banners' &&
    featureKey !== 'unicorn-plot' &&
    featureKey !== 'lazy-table';

  // If the user is a wandb employee, then we enable these features by default.
  // However, they should be able to explicitly disable it, so we only enable it if the user hasn't set a value.
  const wandbDefaultFeatureKeys: FeatureKeyTuple[] = [
    // don't do enforcement banners for integration tests
    ['disable-account-enforcement-banners', !envIsIntegration],
  ];
  const featureKeyIsEnabledByDefaultForWandB = isFeatureEnabledByDefault(
    featureKey,
    wandbDefaultFeatureKeys
  );
  if (
    viewerIsWandbEmployee &&
    userSelectedValue === undefined &&
    featureKeyIsEnabledByDefaultForWandB
  ) {
    userSelectedValue = true;
  }

  const globalDefaultFeatureKeys: FeatureKeyTuple[] = [['run-overview-tree']];
  const featureKeyIsEnabledByDefaultForAllUsers = isFeatureEnabledByDefault(
    featureKey,
    globalDefaultFeatureKeys
  );

  // If the user hasn't set a value, but the feature is enabled by default, then we enable it.
  if (
    featureKeyIsEnabledByDefaultForAllUsers &&
    userSelectedValue === undefined
  ) {
    userSelectedValue = true;
  }

  const isEnabled = (enableForCI || userSelectedValue) ?? false;

  return {
    isEnabled,
    userSelectedValue,
    userInfoBetaFeatures,
  };
};

export function useBetaFeature(featureKey: BetaFeatureKey): BetaFeature {
  const viewerUserInfo = useViewerUserInfoQuery();
  const viewer = useViewer();
  const userInfo = viewerUserInfo?.data?.viewer?.userInfo;
  const [updateUserInfo] = useUpdateUserInfoMutation();

  const {isEnabled, userSelectedValue, userInfoBetaFeatures} =
    isBetaFeatureEnabled(featureKey, viewer, userInfo);

  const toggleBetaFeature = useCallback(() => {
    if (userInfo == null) {
      return;
    }
    if (!isEnabled) {
      Analytics.track(`User enabled beta feature ${featureKey}`, {featureKey});
    }

    updateUserInfo({
      variables: {
        userInfo: JSON.stringify({
          ...userInfo,
          betaFeatures: {
            ...(userInfoBetaFeatures ?? {}),
            [featureKey]: !isEnabled,
          },
        }),
      },
    });
  }, [featureKey, isEnabled, updateUserInfo, userInfo, userInfoBetaFeatures]);

  return {
    isEnabled,
    toggleBetaFeature,
    userSelectedValue,
  };
}

export type FeatureKeyTuple = [BetaFeatureKey, boolean?];

/**
 * Given a feature key, and a tuple of defaults, check if the feature is currently enabled by default
 */
export function isFeatureEnabledByDefault(
  featureKey: BetaFeatureKey,
  defaultFeatures: FeatureKeyTuple[]
): boolean {
  return defaultFeatures.some(featureKeyTuple => {
    return (
      featureKeyTuple[0] === featureKey &&
      (featureKeyTuple[1] == null || featureKeyTuple[1])
    );
  });
}
