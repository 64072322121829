import React, {FC} from 'react';

import * as urls from '../../../../util/urls';
import {removeNullUndefinedOrFalse} from '../../../../util/utility';
import Breadcrumbs, {BreadcrumbPart} from '../../../Breadcrumbs';

export type NavBreadcrumbsRegistriesProps = {
  orgName: string;
  registryName?: string;
};

export const NavBreadcrumbsRegistries: FC<NavBreadcrumbsRegistriesProps> = ({
  orgName,
  registryName,
}) => {
  const parts: BreadcrumbPart[] = removeNullUndefinedOrFalse([
    {
      label: 'Registry',
      to: urls.globalRegistry(orgName),
    },
    registryName != null && {
      label: registryName ?? '',
      to: urls.globalRegistry(orgName, registryName),
    },
  ]);

  return <Breadcrumbs parts={parts} />;
};
