import gql from 'graphql-tag';
import {useMemo} from 'react';

import * as Generated from '../../generated/graphql';
import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as QueryTS from '../../util/queryts';
import * as QueryTypes from '../../util/queryTypes';
import * as RunTypes from '../../util/runTypes';
import {useQuery} from './query';

export interface WandbConfigQueryVars {
  entityName: string;
  projectName: string;
  filters: FilterTypes.Filter<RunTypes.Key>;
  sort: QueryTypes.Sort;
}

export const WANDB_CONFIG_QUERY = gql`
  query WandbConfig(
    $projectName: String!
    $entityName: String!
    $filters: JSONString
    $limit: Int = 100
    $order: String
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      runs(filters: $filters, first: $limit, order: $order) {
        edges {
          node {
            id
            wandbConfig
          }
        }
      }
    }
  }
`;

interface Data {
  project: {
    runs: {
      edges: Array<{
        node: {
          wandbConfig: any;
        };
      }>;
    };
  };
}

export type WandbConfig = Array<{[key: string]: any}>;

type UseWandbConfigQueryResult =
  | {loading: true; error: null}
  | {loading: false; error: true}
  | {
      loading: false;
      error: null;
      wandbConfig?: WandbConfig;
    };

// This query is polled, respecting the user polling settings and the page poll
// interval (stored in redux). If the query is expensive (determined by a simple
// threshold on the result size), we raise the poll interval to a much larger
// value, to reduce network overhead for users.
export function useWandbConfigQuery(
  queryVars: WandbConfigQueryVars
): UseWandbConfigQueryResult {
  const {entityName, projectName, filters, sort} = queryVars;
  const pollMultiplier = 1;

  const query = useQuery<Data, Generated.WandbConfigQueryVariables>(
    Generated.WandbConfigDocument,
    {
      variables: {
        entityName,
        projectName,
        filters: JSON.stringify(Filter.toMongo(filters)),
        order: QueryTS.sortToOrderString(sort),
      },
      enablePolling: true,
      pollMultiplier,
    }
  );

  const initialLoading = query.initialLoading;
  const project = query.initialLoading ? null : query.data?.project;

  return useMemo(() => {
    if (initialLoading) {
      return {loading: true, error: null};
    }
    if (project == null) {
      return {
        loading: false,
        error: true,
      };
    }
    return {
      loading: false,
      error: null,
      wandbConfig: project.runs?.edges.map(e => {
        return JSON.parse(e.node.wandbConfig);
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading, project]);
}
