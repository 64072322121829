const BUFFER_LENGTH = 10000;

type LogType = 'log' | 'info' | 'warn' | 'error';

interface LogRecord {
  type: LogType;
  timestamp: number;
  data: string[];
}

const stringify = (data: any) => {
  if (typeof data === 'string') {
    return data;
  }

  try {
    return JSON.stringify(data);
  } catch {
    try {
      return data.toString();
    } catch {
      return '__unserializable__';
    }
  }
};

export default class DebugCollector {
  logBuffer: Array<LogRecord | undefined> = new Array(BUFFER_LENGTH);
  currentLogIndex: number = 0;

  origLog?: typeof console.log;
  origInfo?: typeof console.info;
  origWarn?: typeof console.warn;
  origError?: typeof console.error;

  instrument = () => {
    this.origLog = console.log;
    this.origInfo = console.info;
    this.origWarn = console.warn;
    this.origError = console.error;

    const instrumentedLogFunction: (
      type: LogType,
      origFunction: (...data: any[]) => void
    ) => (...data: any[]) => void = (type, origFunction) => {
      return (...data: any[]) => {
        this.writeLog({
          type,
          timestamp: Date.now().valueOf(),
          data: data.map(stringify),
        });

        origFunction(...data);
      };
    };

    console.log = instrumentedLogFunction('log', this.origLog);
    console.info = instrumentedLogFunction('info', this.origInfo);
    console.warn = instrumentedLogFunction('warn', this.origWarn);
    console.error = instrumentedLogFunction('error', this.origError);
  };

  deinstrument = () => {
    if (!this.origLog || !this.origInfo || !this.origWarn || !this.origError) {
      throw new Error('Tried to call deinstrument() before instrument()');
    }

    console.log = this.origLog;
    console.info = this.origInfo;
    console.warn = this.origWarn;
    console.error = this.origError;
  };

  writeLog = (record: LogRecord) => {
    this.logBuffer[this.currentLogIndex] = record;
    this.currentLogIndex = (this.currentLogIndex + 1) % BUFFER_LENGTH;
  };

  dump = () => {
    const logs = [
      ...this.logBuffer.slice(this.currentLogIndex),
      ...this.logBuffer.slice(0, this.currentLogIndex),
    ].filter(item => item !== undefined);

    return {logs};
  };
}
