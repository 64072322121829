import {REPORT_PAGE_EDIT_PATH, REPORT_PAGE_VIEW_PATH} from '../../routes/paths';

export enum ReportMode {
  IS_EDITING,
  IS_VIEWING,
}

export type ReportPagePath =
  | typeof REPORT_PAGE_VIEW_PATH
  | typeof REPORT_PAGE_EDIT_PATH;
