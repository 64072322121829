import {Select} from '@wandb/weave/components/Form/Select';
import React, {FC} from 'react';
import {SingleValue} from 'react-select';

import {UserAccountType} from '../../../generated/graphql';
import {TeamMember} from '../../../types/graphql';
import {
  SingleUserInviteOptionComp,
  UserInviteOption,
} from './UserInviteOptions';

interface ProjectOwnerOptionsProps {
  users: TeamMember[];
  selectedOwner: string;
  setSelectedOwner: (user: string) => void;
}

export const ProjectOwnerOptions: FC<ProjectOwnerOptionsProps> = ({
  users,
  selectedOwner,
  setSelectedOwner,
}) => {
  const options: UserInviteOption[] = users.map(u => {
    return {
      value: u.id as string,
      label: u.name,
      username: u.username,
      photoUrl: u.photoUrl,
      name: u.name,
      email: u.email,
      accountType: u.accountType as UserAccountType,
    };
  });

  const currentValue = options.filter(u => selectedOwner.includes(u.value));

  return (
    <Select<UserInviteOption>
      className={'w-full'}
      size={'variable'}
      value={currentValue}
      options={options}
      placeholder={'Select project owner'}
      closeMenuOnSelect={false}
      onChange={(opt: SingleValue<UserInviteOption>) => {
        if (opt?.value) {
          setSelectedOwner(opt.value);
        }
      }}
      components={{
        Option: SingleUserInviteOptionComp,
      }}
    />
  );
};
