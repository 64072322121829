import memoize from 'memoize-one';

// eslint-disable-next-line import/no-cycle
import * as PanelBarChart from '../components/PanelBarChart';
import * as PanelCodeComparer from '../components/PanelCodeComparer';
import * as PanelMarkdown from '../components/PanelMarkdown';
import * as PanelMediaBrowser from '../components/PanelMediaBrowser';
import * as PanelMultiRunTable from '../components/PanelMultiRunTable';
import * as PanelParallelCoord from '../components/PanelParallelCoord';
import * as PanelParameterImportance from '../components/PanelParameterImportance';
import * as PanelRunComparer from '../components/PanelRunComparer';
import * as PanelRunsLinePlot from '../components/PanelRunsLinePlot';
import * as PanelScalarChart from '../components/PanelScalarChart';
import * as PanelScatterPlot from '../components/PanelScatterPlot';
import * as PanelVega from '../components/PanelVega';
import * as PanelVega2 from '../components/PanelVega2';
import * as PanelVega3 from '../components/PanelVega3';
import * as PanelWeave from '../components/PanelWeave';
// eslint-disable-next-line import/no-cycle
import * as TableExport from '../components/TableExport';

export const getPanelSpecs = memoize(() => [
  PanelMediaBrowser.Spec,
  PanelRunsLinePlot.Spec,
  PanelMultiRunTable.Spec,
  PanelMarkdown.Spec,
  PanelScalarChart.Spec,
  PanelVega.Spec,
  PanelVega2.Spec,
  PanelVega3.Spec,
  PanelScatterPlot.Spec,
  PanelWeave.Spec,
  PanelParallelCoord.Spec,
  PanelRunComparer.Spec,
  PanelCodeComparer.Spec,
  PanelBarChart.Spec,
  PanelParameterImportance.Spec,
  // include fake TableExport Run Selector spec here so we can access useTableData
  TableExport.Spec,
]);
