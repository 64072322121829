// To add a new built-in Vega panel, add it to the builtinPanels sub-directory,
// then import it here and add it to PANEL_DEFS.
import * as VegaLib2 from '../../util/vega2';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import BarLite from './builtinPanels/barLite';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import BoxLite from './builtinPanels/boxLite';
// import Histogram from './builtinPanels/histogram';
// import Violin from './builtinPanels/violin';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import Contour from './builtinPanels/contour';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import Pacman from './builtinPanels/pacman';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import ScatterLite from './builtinPanels/scatterLite';

export interface BuiltinPanelDef {
  id: string;
  displayName: string;
  description: string;
  specObj: any;
}

// Panels are listed in this order.
const PANEL_DEFS: BuiltinPanelDef[] = [
  BarLite,
  ScatterLite,
  BoxLite,
  // Disabled for now
  // Histogram,
  // Violin,
  Contour,
  Pacman,
];

function builtinID(pd: BuiltinPanelDef): string {
  return 'builtin:' + pd.id;
}

const PANEL_DEF_LOOKUP: {[key: string]: VegaLib2.VegaPanelDef} = {};
for (const pd of PANEL_DEFS) {
  PANEL_DEF_LOOKUP[builtinID(pd)] = {
    displayName: pd.displayName,
    description: pd.description,
    spec: JSON.stringify(pd.specObj, undefined, 2),
  };
}

export const IDS = PANEL_DEFS.map(pd => builtinID(pd));

export function getPanelDef(id: string): VegaLib2.VegaPanelDef | null {
  const result = PANEL_DEF_LOOKUP[id];
  if (result != null) {
    return result;
  }
  return null;
}
