import {Icon} from '@wandb/weave/components/Icon';
import React from 'react';
import {Redirect, useLocation} from 'react-router';

import {
  Banner,
  BannerBoldText,
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../../../../../components/Banner';
import {useViewer} from '../../../../../../state/viewer/hooks';
import {DateFormat, format} from '../../../../../../util/date';
import * as Urls from '../../../../../../util/urls';
import {
  getUserLockdownDate,
  getUserLockdownStateFromDate,
  useIsLockablePersonalEntityLocation,
  UserLockdownState,
} from '../../../../../../util/userLockdown';
import * as S from './UserLockdownWarningBanner.styles';

export const UserLockdownWarningBanner = () => {
  const location = useLocation();
  const viewer = useViewer();
  const isLockableLocation = useIsLockablePersonalEntityLocation(
    location.pathname
  );

  if (!isLockableLocation || !viewer) {
    return null;
  }

  const hasTeams = viewer.teams?.edges?.some(edge => edge.node?.isTeam);

  const lockdownDate = getUserLockdownDate(viewer);
  const lockdownState = getUserLockdownStateFromDate(lockdownDate);

  if (lockdownState === UserLockdownState.Locked) {
    return <Redirect to={Urls.accountSettings()} />;
  }

  if (lockdownState !== UserLockdownState.Warned) {
    return null;
  }

  if (!lockdownDate) {
    return null;
  }

  const formattedDate = format(lockdownDate, DateFormat.MONTH_DAYTH_YEAR);

  return (
    <Banner
      variant={'warning'}
      eventData={{
        location: 'user lockdown warning banner',
      }}>
      <BannerFlexWrapper>
        <S.IconWrapper>
          <Icon name="info" />
        </S.IconWrapper>
        <BannerTextWrapper>
          <span>
            <BannerBoldText>
              For security and collaboration, we recommend logging commercial
              work to a team.
            </BannerBoldText>{' '}
            You will not be able to access runs in personal projects after{' '}
            {formattedDate}.{' '}
          </span>
        </BannerTextWrapper>
      </BannerFlexWrapper>
      {hasTeams ? (
        <S.CallToActionButton
          href={Urls.settings()}
          onClick={() => {
            // Insert analytics here when agreed on
          }}>
          Change default team
        </S.CallToActionButton>
      ) : (
        <S.CallToActionButton
          href={Urls.createTeamPage()}
          onClick={() => {
            // Insert analytics here when agreed on
          }}>
          Create a team
        </S.CallToActionButton>
      )}
    </Banner>
  );
};
