import classNames from 'classnames';
import * as React from 'react';

import {useIsAdminModeActiveAndIsNotLocalEnv} from '../util/admin';

const WBLogo = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({className, ...btnAttributes}, ref) => {
  const isAdminMode = useIsAdminModeActiveAndIsNotLocalEnv();
  return (
    <button
      ref={ref}
      className={classNames('wandb-logo', className, {
        'admin-mode': isAdminMode,
      })}
      {...btnAttributes}>
      <div className="dots-column">
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot big-dot" />
        <div className="dot big-dot" />
      </div>
      <div className="dots-column">
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
      </div>
      <div className="dots-column">
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot small-dot" />
        <div className="dot big-dot" />
        <div className="dot small-dot" />
      </div>
    </button>
  );
});

export default WBLogo;
