import {Tooltip as VisxTooltip, TooltipWithBounds} from '@visx/tooltip';
import React from 'react';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {legendTemplateInsertCrosshairValues} from './../../../util/legend';

type TooltipProps = {
  left?: number;
  top?: number;
  data: any;
  tooltipClassName?: string;
  withTooltipBounds?: boolean;
  valueFormatter?: (value: number) => string;
};

const s = {
  backgroundColor: 'transparent',
};

export const Tooltip = React.memo(
  ({
    data,
    left,
    top,
    tooltipClassName,
    withTooltipBounds = true,
    valueFormatter,
  }: TooltipProps) => {
    // eslint-disable-next-line no-prototype-builtins
    if (data == null || !data.hasOwnProperty('key')) {
      return null;
    }

    const TooltipComponent = withTooltipBounds
      ? TooltipWithBounds
      : VisxTooltip;

    return (
      <TooltipComponent
        // set this to random so it correctly updates with parent bounds key
        key={Math.random()}
        className="rv-hint"
        top={top}
        left={left}
        style={s}>
        <div className={tooltipClassName ?? 'rv-hint__content'}>
          {legendTemplateInsertCrosshairValues({
            key: data.key,
            legendTemplate: data.title ?? '',
            addDefaultTemplateIfCrosshairValuesMissing: true,
            values: {
              x: {
                xAxis: data.metricName ?? '',
                val:
                  data.hideValue === true
                    ? ''
                    : valueFormatter != null
                    ? valueFormatter(data.value)
                    : data.value,
              },
              mean: data.mean,
              min:
                data.quartiles != null ? data.quartiles[0] : data?.range?.[0],
              max:
                data.quartiles != null ? data.quartiles[4] : data?.range?.[1],
              stddev: data.stddev,
            },
            type: 'bar',
          })}
        </div>
      </TooltipComponent>
    );
  }
);
Tooltip.displayName = 'VisxTooltip';
