export type SlowEvent = {
  subscriberId: string;
  duration: number;
};

let slowEventsBuffer: SlowEvent[] = [];

export function addSlowEvent(slowEvent: SlowEvent) {
  slowEventsBuffer = [...slowEventsBuffer, slowEvent];
}

export function getSlowEvents() {
  return slowEventsBuffer;
}

export function clearSlowEvents() {
  slowEventsBuffer = [];
}
