import {useMemo} from 'react';

import {OrganizedSettings} from '../../../../PanelBank/types';
import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {Complete, LinePlotSettings} from '../../types';
import {getLinePlotSettingWithDefault} from '../linePlotDefaults';
import {getCascadingMaxRuns} from './getCascadingMaxRuns';
import {
  getDerivedPointVisualizationOption,
  getDerivedSafeMinMaxOnHoverValue,
  useCascadingPointAggregationMethod,
} from './getCascadingPointAggregation';
import {getCascadingShowLegend} from './getCascadingShowLegend';

/**
 * This hook used by both the workspace and section level context providers.
 * This hook helps with typing the settings object.We want to make sure the
 * setting fields exist, but still allow them to be undefined.
 * When setting value is null, it indicates that a user hasn't modified the setting yet.
 */
// All fields in the type should exist, but can be undefined
export const useLinePlotSettings = (
  organizedSettings?: OrganizedSettings
): Complete<
  Omit<
    LinePlotSettings,
    // These two settings are behind a feature flag. This can be removed once feature if fully released.
    'pointVisualizationMethod' | 'showMinMaxOnHover'
  >
> => {
  const linePlotSettings = organizedSettings?.linePlot;

  // This feature is still under development, so we can't directly use the value in workspaceSettings. We need to check for additional values.
  // Note: once feature is fully released on prod, we can remove this hook
  const {pointVisualizationMethod, showMinMaxOnHover} =
    useCascadingPointAggregationMethod(linePlotSettings);

  return useMemo(
    () => ({
      // These settings are at workspace, section, and panel level.
      smoothingType: linePlotSettings?.smoothingType,
      smoothingWeight: linePlotSettings?.smoothingWeight,
      xAxis: linePlotSettings?.xAxis,
      xAxisMax: linePlotSettings?.xAxisMax,
      xAxisMin: linePlotSettings?.xAxisMin,
      yAxisMax: linePlotSettings?.yAxisMax,
      yAxisMin: linePlotSettings?.yAxisMin,

      // These settings are both at workspace and panel level.
      ignoreOutliers: linePlotSettings?.ignoreOutliers,
      maxRuns: linePlotSettings?.maxRuns,
      limit: linePlotSettings?.limit,
      pointVisualizationMethod,
      showMinMaxOnHover,

      // These settings are only at workspace settings at the moment.
      colorRunNames: linePlotSettings?.colorRunNames,
      displayFullRunName: linePlotSettings?.displayFullRunName,
      highlightedCompanionRunOnly:
        linePlotSettings?.highlightedCompanionRunOnly,
      suppressLegends: linePlotSettings?.suppressLegends,
      showLegend: linePlotSettings?.showLegend,
      tooltipNumberOfRuns: linePlotSettings?.tooltipNumberOfRuns,
      useRunsTableGroupingInPanels:
        linePlotSettings?.useRunsTableGroupingInPanels,
    }),
    [linePlotSettings, pointVisualizationMethod, showMinMaxOnHover]
  );
};

/**
 * @param settingsType indicates what level the settings are
 *
 * This is used at the panel/most specific level. It will take the current value or the default value.
 * We want to resolve at the panel level, so that we can easily add or remove settings
 * at both the workspace and section levels.
 */
export const getLinePlotSettingsWithDefaults = (
  settingsObj: LinePlotSettings | RunsLinePlotConfig | undefined
) => {
  const showLegend = getCascadingShowLegend({
    suppressLegends:
      settingsObj != null && 'suppressLegends' in settingsObj
        ? settingsObj?.suppressLegends
        : undefined,
    showLegend: settingsObj?.showLegend,
  });

  const pointVisualizationMethod = getDerivedPointVisualizationOption(
    settingsObj?.pointVisualizationMethod
  );

  const limit = getCascadingMaxRuns({
    pointVisualizationMethod,
    maxRuns:
      settingsObj != null && 'maxRuns' in settingsObj
        ? settingsObj?.maxRuns
        : undefined,
    limit:
      settingsObj != null && 'limit' in settingsObj
        ? settingsObj?.limit
        : undefined,
  });

  return {
    colorRunNames: getLinePlotSettingWithDefault(
      settingsObj?.colorRunNames,
      'colorRunNames'
    ),
    displayFullRunName: getLinePlotSettingWithDefault(
      settingsObj?.displayFullRunName,
      'displayFullRunName'
    ),
    highlightedCompanionRunOnly: getLinePlotSettingWithDefault(
      settingsObj?.highlightedCompanionRunOnly,
      'highlightedCompanionRunOnly'
    ),
    ignoreOutliers: getLinePlotSettingWithDefault(
      settingsObj?.ignoreOutliers,
      'ignoreOutliers'
    ),
    limit: getLinePlotSettingWithDefault(limit, 'limit'),
    pointVisualizationMethod,
    showLegend: getLinePlotSettingWithDefault(showLegend, 'showLegend'),
    showMinMaxOnHover: getLinePlotSettingWithDefault(
      getDerivedSafeMinMaxOnHoverValue(settingsObj?.showMinMaxOnHover),
      'showMinMaxOnHover'
    ),
    smoothingType: getLinePlotSettingWithDefault(
      settingsObj?.smoothingType,
      'smoothingType'
    ),
    smoothingWeight: getLinePlotSettingWithDefault(
      settingsObj?.smoothingWeight,
      'smoothingWeight'
    ),
    tooltipNumberOfRuns: getLinePlotSettingWithDefault(
      settingsObj?.tooltipNumberOfRuns,
      'tooltipNumberOfRuns'
    ),
    useRunsTableGroupingInPanels: getLinePlotSettingWithDefault(
      settingsObj?.useRunsTableGroupingInPanels,
      'useRunsTableGroupingInPanels'
    ),
    xAxis: getLinePlotSettingWithDefault(settingsObj?.xAxis, 'xAxis'),
    xAxisMax: settingsObj?.xAxisMax,
    xAxisMin: settingsObj?.xAxisMin,
    yAxisMax: settingsObj?.yAxisMax,
    yAxisMin: settingsObj?.yAxisMin,
  };
};
