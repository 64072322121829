import classNames from 'classnames';
import React from 'react';

import {SettingsHeader} from './SettingsHeader';

export const SettingsCard = ({
  children,
  title,
  description,
  isPremiumFeatureDisabled,
  sideContent,
}: {
  children: React.ReactNode;
  title?: string;
  description?: string | React.ReactElement;
  isPremiumFeatureDisabled?: boolean;
  sideContent?: React.JSX.Element;
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col first:mt-0',
        title != null ? 'mt-32' : 'mt-12'
      )}
      key={title}>
      {title != null && description != null && (
        <div className="flex items-end justify-between pb-12">
          <div className="w-full">
            <SettingsHeader
              title={title}
              description={description}
              isPremiumFeatureDisabled={isPremiumFeatureDisabled}
            />
          </div>
          {sideContent}
        </div>
      )}
      <div className="rounded-lg border border-moon-250 bg-white px-32 py-24">
        {children}
      </div>
    </div>
  );
};
