import {Buffer} from 'buffer';

// parse graphql organization id to backend id (mysql table id)
export function parseOrganizationID(graphqlOrgId: string): string {
  return Buffer.from(graphqlOrgId, 'base64')
    .toString()
    .replace('Organization:', '');
}

// make backend id (mysql table id) to graphql organization id
export function createGraphqlOrgId(orgId: number): string {
  return btoa(`Organization:${orgId}`);
}
