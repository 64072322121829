import {ApolloError, ApolloQueryResult} from 'apollo-client';
import React, {createContext, useContext, useMemo} from 'react';

import {
  HomePageViewerDetailsQuery,
  useHomePageViewerDetailsQuery,
  useViewerReportsQuery,
  ViewerReportsQuery,
} from '../../generated/graphql';
import {HomeViewerDetails, Node} from '../../graphql/homePage';
import {Override} from '../../graphql/teamQuery';
import {ReportsTableReport} from '../EntityPage/ReportsTable';

export type HomePageContextType = {
  homePageViewerDetailsQuery: {
    data?: HomeViewerDetails;
    isLoading: boolean;
    refetch: () => Promise<ApolloQueryResult<HomePageViewerDetailsQuery>>;
    error?: ApolloError;
  };
  viewerReportsQuery: {
    data?: Array<Node<ReportsTableReport>>;
    isLoading: boolean;
    refetch: () => Promise<ApolloQueryResult<ViewerReportsQuery>>;
  };
};

export const HomePageContext = createContext<HomePageContextType | undefined>(
  undefined
);

type HomePageContextProviderProps = {
  children: React.ReactNode;
};

export const HomePageContextProvider = ({
  children,
}: HomePageContextProviderProps) => {
  const homePageViewerDetailsQuery = useHomePageViewerDetailsQuery();
  const viewerReportsQuery = useViewerReportsQuery();

  const homePageViewer = homePageViewerDetailsQuery?.data?.viewer;

  const viewerReportEdges = useMemo(
    () =>
      viewerReportsQuery?.data?.viewer?.views?.edges.map(e => ({
        node: e.node as Override<typeof e.node, ReportsTableReport>,
      })) ?? [],
    [viewerReportsQuery]
  );

  const values = useMemo(
    () => ({
      homePageViewerDetailsQuery: {
        data: homePageViewer as Override<
          typeof homePageViewer,
          HomeViewerDetails
        >,
        isLoading: homePageViewerDetailsQuery?.loading,
        refetch: homePageViewerDetailsQuery?.refetch,
        error: homePageViewerDetailsQuery?.error,
      },
      viewerReportsQuery: {
        data: viewerReportEdges,
        isLoading: viewerReportsQuery?.loading,
        refetch: viewerReportsQuery?.refetch,
      },
    }),
    [
      homePageViewer,
      homePageViewerDetailsQuery,
      viewerReportEdges,
      viewerReportsQuery,
    ]
  );

  return (
    <HomePageContext.Provider value={values}>
      {children}
    </HomePageContext.Provider>
  );
};

export function useHomePageContext(): HomePageContextType {
  const value = useContext(HomePageContext);

  if (value == null) {
    throw new Error(
      'useHomePageContext must be used within a HomePageContextProvider'
    );
  }

  return value;
}
