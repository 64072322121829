type NameAndRegex = {
  prettyName: string;
  regex: RegExp;
};

const trainiumRegex: NameAndRegex[] = [
  {
    prettyName: 'Trainium Neuron Core $1 Utilization (%)',
    regex: /system\/trn\.((\d)+)\.neuroncore_utilization$/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, total (bytes)',
    regex: /system\/trn\.host_total_memory_usage$/,
  },
  {
    prettyName: 'Trainium Neuron Device Total Memory Usage (bytes)',
    regex: /system\/trn\.neuron_device_total_memory_usage$/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, application memory (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.application_memory$/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, constants (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.constants$/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, DMA buffers (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.dma_buffers$/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, tensors (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.tensors$/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, constants (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.constants$/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, model code (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.model_code$/,
  },
  {
    prettyName:
      'Trainium Neuron Core $1 Memory Usage, model shared scratchpad (bytes)',
    regex:
      /system\/trn\.((\d)+)\.neuroncore_memory_usage\.model_shared_scratchpad$/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, runtime memory (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.runtime_memory$/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, tensors (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.tensors$/,
  },
];

const IPURegex: NameAndRegex[] = [
  {
    prettyName: 'IPU $1 Avg Board Temp (\u2103)',
    regex: /system\.ipu\.((\d)+)\.average board temp.*/,
  },
  {
    prettyName: 'IPU $1 Avg Die Temp (\u2103)',
    regex: /system\.ipu\.((\d)+)\.average die temp.*/,
  },
  {
    prettyName: 'IPU $1 Clock (MHz)',
    regex: /system\/ipu\.((\d)+)\.clock/,
  },
  {
    prettyName: 'IPU $1 Power (W)',
    regex: /system\/ipu\.((\d)+)\.power/,
  },
  {
    prettyName: 'IPU $1 Utilization (%)',
    regex: /system\/ipu\.((\d)+)\.ipu utilisation \(%\)$/,
  },
  {
    prettyName: 'IPU $1 Utilization (session) (%)',
    regex: /system\/ipu\.((\d)+)\.ipu utilisation \(session\)/,
  },
];

const GPURegex: NameAndRegex[] = [
  {
    prettyName: 'GPU $1 Utilization (%)',
    regex: /system\/gpu\.((\d)+)\.gpu/,
  },
  {
    prettyName: 'GPU $1 Temp (℃)',
    regex: /system\/gpu\.((\d)+)\.temp/,
  },
  {
    prettyName: 'GPU $1 Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.((\d)+)\.memory$/,
  },
  {
    prettyName: 'GPU $1 Memory Allocated (%)',
    regex: /system\/gpu\.((\d)+)\.memory_?[aA]llocated$/,
  },
  {
    prettyName: 'GPU $1 Power Usage (%)',
    regex: /system\/gpu\.((\d)+)\.powerPercent$/,
  },
  {
    prettyName: 'GPU $1 Power Usage (W)',
    regex: /system\/gpu\.((\d)+)\.powerWatts$/,
  },
  {
    prettyName: 'Process GPU $1 Utilization (%)',
    regex: /system\/gpu\.process\.((\d)+)\.gpu/,
  },
  {
    prettyName: 'Process GPU $1 Temp (℃)',
    regex: /system\/gpu\.process\.((\d)+)\.temp/,
  },
  {
    prettyName: 'Process GPU $1 Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.process\.((\d)+)\.memory$/,
  },
  {
    prettyName: 'Process GPU $1 Memory Allocated (%)',
    regex: /system\/gpu\.process\.((\d)+)\.memory_?[aA]llocated$/,
  },
  {
    prettyName: 'Process GPU $1 Power Usage (%)',
    regex: /system\/gpu\.process\.((\d)+)\.powerPercent$/,
  },
  {
    prettyName: 'Process GPU $1 Power Usage (W)',
    regex: /system\/gpu\.process\.((\d)+)\.powerWatts$/,
  },
];

const diskRegex: NameAndRegex[] = [
  {
    prettyName: 'Disk Utilization (%)',
    regex: /system\/disk$/,
  },
  {
    prettyName: '$1 Utilization (%)',
    regex: /system\/disk\.((.)+)\.usagePercent$/,
  },
  {
    prettyName: '$1 Utilization (GB)',
    regex: /system\/disk\.((.)+)\.usageGB$/,
  },
  {
    prettyName: 'MB written to disk',
    regex: /system\/disk\.out$/,
  },
  {
    prettyName: 'MB read from disk',
    regex: /system\/disk\.in$/,
  },
];

const mainRegex = [
  {
    prettyName: 'Process CPU Utilization (%)',
    regex: /system\/cpu$/,
  },
  {
    prettyName: 'System CPU $1 Utilization (%)',
    regex: /system\/cpu\.((\d)+)\.cpu/,
  },
  {
    prettyName: 'TPU Utilization (%)',
    regex: /system\/tpu/,
  },
  {
    prettyName: 'System Memory Utilization (%)',
    regex: /system\/memory/,
  },
  {
    prettyName: 'Network Traffic Sent (bytes)',
    regex: /system\/network\.sent/,
  },
  {
    prettyName: 'Network Traffic Received (bytes)',
    regex: /system\/network\.recv/,
  },
];

type NameAndRegexGroup = {
  regex?: RegExp;
  detailedRegexes: NameAndRegex[];
};

const prettyMetricReplacements: NameAndRegexGroup[] = [
  {
    regex: /trn/,
    detailedRegexes: trainiumRegex,
  },
  {
    regex: /ipu/,
    detailedRegexes: IPURegex,
  },
  {
    regex: /gpu/,
    detailedRegexes: GPURegex,
  },
  {
    regex: /disk/,
    detailedRegexes: diskRegex,
  },
  {
    // default to running these
    detailedRegexes: mainRegex,
  },
];

const sysRegex = /system/;

export function prettifyMetricName(originalMetricName: string): string {
  // if not system metric, return early
  if (!sysRegex.test(originalMetricName)) {
    return originalMetricName;
  }

  for (const {regex, detailedRegexes} of prettyMetricReplacements) {
    if (!regex || regex.test(originalMetricName)) {
      for (const {prettyName, regex: subRegex} of detailedRegexes) {
        const match = originalMetricName.match(subRegex);
        if (match == null) {
          continue;
        }
        if (match.length <= 1) {
          return prettyName;
        }
        if (prettyName.startsWith('$1 Utilization')) {
          return prettyName.replace('$1', match[1]).replace(/\\/g, '/');
        }
        return prettyName.replace('$1', match[1]);
      }
    }
  }

  return originalMetricName;
}
