import * as globals from '@wandb/weave/common/css/globals.styles';
import {fuzzyMatchHighlight} from '@wandb/weave/common/util/fuzzyMatch';
import {Link} from '@wandb/weave/common/util/links';
import {
  IconClose,
  IconFolderProject,
  IconRecentClock,
  IconReport,
} from '@wandb/weave/components/Icon';
import {capitalize} from 'lodash';
import React, {FC, memo} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import {imgSrcWithDefault} from '../../util/images';
import {useBetaFeatureNightModeEnabled} from '../../util/useBetaFeature';
import {UnstyledButton} from '../UnstyledButton';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {LocalSearchResult, NightAwareProps} from '.';

type ResultItemProps = {
  searchTerm: string;
  resultItem: LocalSearchResult;
  rightLabel?: string;
  onClick?: () => void;
};

const ResultItemComp: FC<ResultItemProps> = ({
  searchTerm,
  resultItem: {url, name, type, entityName, imageURL},
  rightLabel,
  onClick,
}) => {
  const isNightMode = useBetaFeatureNightModeEnabled();

  return (
    <ResultItemContainer onClick={onClick} to={url}>
      {type === `team` ? (
        <ResultItemImg src={imgSrcWithDefault(imageURL)} />
      ) : (
        <ResultItemIconContainer isNightMode={isNightMode}>
          {type === `project` ? (
            <IconFolderProject />
          ) : type === `report` ? (
            <IconReport />
          ) : null}
        </ResultItemIconContainer>
      )}
      <ResultItemText>
        <ResultItemHeader>
          {searchTerm ? fuzzyMatchHighlight(name, searchTerm) : name}
        </ResultItemHeader>
        <ResultItemInfo>
          <ResultItemInfoText noShrink>{capitalize(type)}</ResultItemInfoText>
          {entityName && (
            <>
              <ResultItemInfoSeparator />
              <ResultItemInfoText>{entityName}</ResultItemInfoText>
            </>
          )}
        </ResultItemInfo>
      </ResultItemText>
      {rightLabel && <ResultRightLabel>{rightLabel}</ResultRightLabel>}
    </ResultItemContainer>
  );
};

export const ResultItem = memo(ResultItemComp);

type RecentSearchProps = {
  value: string;
  onSelect: () => void;
  onDelete: () => void;
};

const RecentSearchComp: FC<RecentSearchProps> = ({
  value,
  onSelect,
  onDelete,
}) => {
  return (
    <RecentSearchContainer onClick={onSelect}>
      <RecentSearchIconContainer>
        <IconRecentClock />
      </RecentSearchIconContainer>
      <ResultItemText>
        <ResultItemHeader>{value}</ResultItemHeader>
      </ResultItemText>
      <RecentSearchDeleteButton
        onClick={e => {
          e.stopPropagation();
          onDelete();
        }}>
        <IconClose />
      </RecentSearchDeleteButton>
    </RecentSearchContainer>
  );
};

export const RecentSearch = memo(RecentSearchComp);

const resultItemContainerStyles = css`
  display: flex;
  align-items: center;
  padding: 10px 8px;
  border-radius: 8px;
  cursor: pointer;

  color: ${globals.TEXT_PRIMARY_COLOR};
  &:hover {
    color: ${globals.TEXT_PRIMARY_COLOR};
    background-color: ${globals.MOON_100};
  }
`;

const ResultItemContainer = styled(Link)`
  ${resultItemContainerStyles}
`;

const RecentSearchContainer = styled.div`
  ${resultItemContainerStyles}
`;

const RecentSearchDeleteButton = styled(UnstyledButton)`
  flex-shrink: 0;
  padding: 4px;
  color: ${globals.MOON_500};
  &:hover {
    color: ${globals.MOON_650};
  }
`;

const resultItemImgContainerStyles = css`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`;

const resultItemIconContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResultItemImg = styled.img`
  ${resultItemImgContainerStyles}
`;

const ResultItemIconContainer = styled.div.attrs({
  className: `night-aware`,
})<NightAwareProps>`
  ${resultItemImgContainerStyles}
  ${resultItemIconContainerStyles}
  color: ${p => (!p.isNightMode ? globals.TEAL_600 : globals.TEAL_450)};
  background-color: ${globals.hexToRGB(globals.TEAL_550, 0.14)};
`;

const RecentSearchIconContainer = styled.div`
  ${resultItemImgContainerStyles}
  ${resultItemIconContainerStyles}
  color: ${globals.MOON_500};
  background-color: ${globals.hexToRGB(globals.MOON_500, 0.16)};
`;

const ResultItemText = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const ResultItemHeader = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  .fuzzy-match {
    font-weight: 600 !important;
    background: none;
  }
`;

const ResultItemInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: ${globals.MOON_500};
`;

const ResultItemInfoText = styled.span<{noShrink?: boolean}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${p => p.noShrink && `flex-shrink: 0;`}
`;

const ResultItemInfoSeparator = styled.div`
  flex-shrink: 0;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background-color: ${globals.MOON_500};
  margin: 0 6px;
`;

const ResultRightLabel = styled.div`
  flex-shrink: 0;
  white-space: nowrap;
  font-size: 14px;
  color: ${globals.MOON_500};
  margin-left: 12px;
`;
