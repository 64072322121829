import React, {memo, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Dropdown} from 'semantic-ui-react';

import {Account, AccountType} from '../../../components/Search/SearchNav/types';
import {useAccountList} from '../../../components/Search/SearchNav/useAccountList';
import {useViewer} from '../../../state/viewer/hooks';
import {maybePluralize} from '../../../util/uihelpers';
import {accountSettings} from '../../../util/urls';
import {AccountIcon, AccountIconLocation} from './AccountIcon';
import * as S from './AccountPickerSection.styles';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useAccountSettingsContext} from './AccountSettingsContextProvider';

const AccountPickerSectionComp = () => {
  const {selectedAccount, loading} = useAccountSettingsContext();

  const {accountList, isLoading} = useAccountList();

  if (loading || isLoading) {
    return <S.LoadingAccountPicker />;
  }

  return (
    <S.SectionWrapper>
      <S.Description>
        You have {maybePluralize(accountList.length, 'account')}.
      </S.Description>
      <AccountDropdown
        accountList={accountList}
        selectedAccount={selectedAccount}
      />
    </S.SectionWrapper>
  );
};

type AccountDropdownProps = {
  accountList: Account[];
  selectedAccount: Account | null;
};

const AccountDropdown = ({
  accountList,
  selectedAccount,
}: AccountDropdownProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  if (selectedAccount == null) {
    return null;
  }
  return (
    <Dropdown
      icon={null}
      trigger={
        <AccountItem
          account={selectedAccount}
          isDisplay={true}
          isOpen={menuOpen}
        />
      }
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
      open={menuOpen}>
      <S.AccountDropdownMenu>
        {accountList.map((account, i) => {
          return (
            <React.Fragment key={account.name}>
              {i !== 0 && <S.ItemDivider />}
              <S.DropdownItem>
                <S.AccountLink to={accountSettings(account.name)}>
                  <AccountItem
                    account={account}
                    selected={selectedAccount.name === account.name}
                  />
                </S.AccountLink>
              </S.DropdownItem>
            </React.Fragment>
          );
        })}
      </S.AccountDropdownMenu>
    </Dropdown>
  );
};

type AccountItemProps = {
  account: Account;
  selected?: boolean;
  isDisplay?: boolean;
  isOpen?: boolean;
};

const AccountItem = ({
  account,
  selected = false,
  isDisplay = false,
  isOpen = false,
}: AccountItemProps) => {
  const accountName =
    account?.accountType === AccountType.Personal
      ? prettifyPersonalAccountName(account.name)
      : account.name;

  return (
    <S.AccountDropdown isDisplay={isDisplay} isOpen={isOpen}>
      <S.ItemWrapper>
        <AccountIcon
          accountType={account?.accountType}
          location={AccountIconLocation.AccountSettingsHeaderLegacy}
          isSelected={selected}
        />
        <S.AccountInfoWrapper>
          <S.AccountName>{accountName}</S.AccountName>
          <S.AccountType>{account?.accountType}</S.AccountType>
        </S.AccountInfoWrapper>
      </S.ItemWrapper>
      {selected && <S.CheckIcon />}
      {isDisplay && <S.ClosedCarrotIcon />}
    </S.AccountDropdown>
  );
};

// helper function that throws error when there is no personal account
export function useDefaultAccountFromAccountList(): Account {
  const viewer = useViewer();
  const {accountList} = useAccountList();
  const defaultEntityOrganization = viewer?.teams.edges.find(
    org => org.node.id === viewer?.defaultEntity?.id
  );
  const defaultOrganization = accountList.find(
    account => defaultEntityOrganization?.node.organizationId === account.id
  );
  const personalAccount = accountList.find(
    a => a?.accountType === AccountType.Personal
  );
  let selectedAccount = defaultOrganization ?? personalAccount;

  if (selectedAccount == null) {
    if (
      accountList &&
      accountList?.length > 0 &&
      viewer &&
      viewer?.userInfo?.isPersonalEntityHidden
    ) {
      // Handles edge case where personal entity is hidden and user has it set as their default entity,
      // since useAccountList() does not return personal entity when hidden, defaultOrganization && personalAccount will be null
      // we set the next available account on the account selector.
      selectedAccount = accountList[0];
    } else {
      throw new Error(
        'invalid state. account list should always include personal account.'
      );
    }
  }

  return selectedAccount;
}

export const AccountPickerSection = memo(AccountPickerSectionComp);

// helper function to remove random characters from personal organization name
// e.g. input: "username_v1zxctms" => output: "username"
function prettifyPersonalAccountName(name: string): string {
  const splitName = name.split('_'); // => ['username', 'v1zxctms']
  return splitName.length > 0 ? splitName[0] : '';
}
