import {TailwindContents} from '@wandb/weave/components/Tailwind';
import {debounce} from 'lodash';
import React, {useCallback, useMemo, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Input} from 'semantic-ui-react';

import {trackSetting} from '../../services/analytics/workspaceSettingsEvents';
import {RunHistoryKeyInfo} from '../../types/run';
import {RunsLinePlotConfig} from '../PanelRunsLinePlot/types';
import {
  getSelectedMetrics,
  getTruncatedSelectedMetrics,
  REGEX_METRIC_MAX_LEN,
} from '../PanelRunsLinePlot/utils';
import {useTrackSettingPage} from '../WorkspaceDrawer/Settings/hooks/useTrackSettingEvent';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {MetricsPickerProps, MetricsRegexToggle} from './MetricsPicker';

export const MetricsPickerRegexInput = (props: MetricsPickerProps) => {
  const {keyInfo, config, updateConfig} = props;
  const trackSettingPage = useTrackSettingPage();

  const [inputRegex, setInputRegex] = useState(config.metricRegex);

  const updateConfigDebounced = useMemo(
    () =>
      debounce((newConfig: Partial<RunsLinePlotConfig>) => {
        // intentionally omitting `useMetricRegex` since that gets populated
        // when  user clicks on the regex button already
        updateConfig(newConfig);
      }, 250),
    [updateConfig]
  );

  const onInputChanged = useCallback(
    (_, {value: newRegex}) => {
      setInputRegex(newRegex);
      updateConfigDebounced({
        metrics: getTruncatedSelectedMetrics({
          metricRegex: newRegex,
          keyInfo,
        }),
        metricRegex: newRegex,
      });
    },
    [keyInfo, updateConfigDebounced]
  );

  const onInputBlurred = useCallback(() => {
    trackSetting({
      action: 'change',
      page: trackSettingPage,
      settingLevel: 'panel',
      setting: 'yAxis',
      settingValue: inputRegex?.toString(),
      additionalProperties: {
        isInputRegexEmpty: !inputRegex?.length,
      },
    });
  }, [inputRegex, trackSettingPage]);

  // TODO - fix jumping height change
  return (
    <>
      <div className="metrics-picker">
        <Input
          style={{flexGrow: 1}}
          className="with-button regex-input"
          placeholder="Select metrics with a regular expression"
          value={inputRegex}
          onChange={onInputChanged}
          onBlur={onInputBlurred}
        />
        <MetricsRegexToggle {...props} />
      </div>
      <RegexMetricMessage keyInfo={keyInfo} config={config} />
    </>
  );
};

export const RegexMetricMessage = ({
  keyInfo,
  config,
}: {
  keyInfo: RunHistoryKeyInfo;
  config: RunsLinePlotConfig;
}) => {
  const regexMatchCount = useMemo(
    () => getSelectedMetrics({metricRegex: config.metricRegex, keyInfo}).length,
    [config.metricRegex, keyInfo]
  );

  if (config.useMetricRegex) {
    return (
      <TailwindContents>
        <p>
          {regexMatchCount} metrics matched
          {regexMatchCount > REGEX_METRIC_MAX_LEN && (
            <>
              .{' '}
              <span className="text-red-600">
                Only first {REGEX_METRIC_MAX_LEN} can be shown
              </span>
            </>
          )}
        </p>
      </TailwindContents>
    );
  }
  return null;
};
