import {useEffect} from 'react';

import {datadogSetUserInfo} from '../integrations';
import {useSelector} from '../state/hooks';
import {useViewer} from '../state/viewer/hooks';
import {getViewerLoading} from '../state/viewer/selectors';

export const useConsentAwareAnalyticsInjection = () => {
  const viewerIsLoading = useSelector(getViewerLoading);
  const viewer = useViewer();
  useEffect(() => {
    if (viewerIsLoading) {
      return;
    }
    const injector = (window as any).WBAnalyticsInjector;
    if (injector) {
      const authenticated = !!viewer?.username;
      try {
        injector.initializeTrackingScripts(authenticated);
        // Since the Datadog setup script relies on the username, we
        // cannot add it to the `consentAwareAnalyticsInjector` script.
        // Instead, we call the `datadogSetUserInfo` function here after
        // such script sets the `DatadogEnabled` flag.
        datadogSetUserInfo(viewer ?? {username: 'anonymous'});
      } catch (e) {
        // console.error('Failed to inject analytics', e);
      }
    }
  }, [viewer, viewerIsLoading]);
};
