import React, {FC, memo, useMemo} from 'react';

import {Tag} from '../../../../util/gallery';
import {trackBreadcrumbClicked} from '../../../../util/navigation';
import * as links from '../../../../util/urls';
import Breadcrumbs, {BreadcrumbPart} from '../../../Breadcrumbs';

type NavBreadcrumbsFCPostProps = {
  entityName: string;
  reportID: string;
  reportName: string;
  tag: Tag;
  isDiscussion: boolean;
};

const NavBreadcrumbsFCPostComp: FC<NavBreadcrumbsFCPostProps> = ({
  entityName,
  reportID,
  reportName,
  tag,
  isDiscussion,
}) => {
  const tagLinkForm = tag.linkForm;
  const label = isDiscussion ? `Discussions` : `Posts`;

  const parts: BreadcrumbPart[] = useMemo(
    () => [
      {label: `Fully Connected`, to: links.reportGallery()},
      {label, to: links.reportGallery({tag: tagLinkForm})},
      {
        label: reportName,
        to: `${(isDiscussion
          ? links.galleryDiscussionView
          : links.galleryPostView)({
          entityName,
          reportID,
          reportName,
        })}${window.location.search}`,
        onClick: () => trackBreadcrumbClicked('Report'),
      },
    ],
    [entityName, reportID, reportName, isDiscussion, tagLinkForm, label]
  );

  return <Breadcrumbs parts={parts} />;
};

const NavBreadcrumbsFCPost = memo(NavBreadcrumbsFCPostComp);

export default NavBreadcrumbsFCPost;
