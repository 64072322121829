import {useContext} from 'react';

import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {envIsPublicCloud} from '../../../config';
import {useUserLimitReachedQuery} from '../../../generated/graphql';

export function useIsRolesDashboardEnabled() {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const userLimitReachedQuery = useUserLimitReachedQuery({
    skip: envIsPublicCloud,
  });

  if (envIsPublicCloud) {
    return selectedAccount?.isEnterprise;
  }

  const rbacEnabled =
    userLimitReachedQuery.data?.serverInfo?.licenseFlags.includes('rbac');
  return (window.CONFIG as any)?.ENABLE_RBAC_UI ?? rbacEnabled;
}
