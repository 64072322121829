import {useMemo} from 'react';

import {Organization} from '../generated/graphql';
import {OrganizationFlags} from '../util/accounts/pricing';
import {jsonParseSafe} from '../util/json';

export type MatchingTeamType = {
  id: string;
  name: string;
  orgName: string;
  memberCount: number;
};

export function useMatchedTeams(
  openMatchingOrganizations: Organization[],
  isOnboardingFlow: boolean,
  userTeamIDs: Set<string>
): {
  matchingTeams: MatchingTeamType[];
  defaultMatchingTeams: MatchingTeamType[];
} {
  return useMemo(() => {
    const matches: MatchingTeamType[] = [];
    const defaultMatches: MatchingTeamType[] = [];
    openMatchingOrganizations.forEach(({name: orgName, teams, flags}) => {
      const orgFlags: OrganizationFlags =
        flags != null ? jsonParseSafe(flags) : {};
      const defaultEntityID = orgFlags.default_entity_id_for_domain_matching;

      teams.forEach(({name, id, memberCount, settings}) => {
        if (
          (isOnboardingFlow || !userTeamIDs.has(id)) &&
          settings.openToMatchingOrgEmailDomainUsers
        ) {
          if (id === defaultEntityID) {
            defaultMatches.push({name, orgName, memberCount, id});
          } else {
            matches.push({name, orgName, memberCount, id});
          }
        }
      });
    });
    return {
      matchingTeams: matches.sort((a, b) => b.memberCount - a.memberCount),
      defaultMatchingTeams: defaultMatches.sort(
        (a, b) => b.memberCount - a.memberCount
      ),
    };
  }, [openMatchingOrganizations, userTeamIDs, isOnboardingFlow]);
}
