import {useSelector} from '../../../../state/hooks';
import {parseReportParams} from '../../../../util/report';
import {useNavContext} from '../../../NavContextProvider';

export type ProjObjType = 'runs' | 'sweeps' | 'jobs' | 'reports' | 'groups';

export function useProjObjType(): ProjObjType | undefined {
  const {
    params: {groupName, sweepName, jobId, reportNameAndID, runName},
  } = useNavContext();
  return groupName != null
    ? 'groups'
    : sweepName != null
    ? 'sweeps'
    : jobId != null
    ? 'jobs'
    : reportNameAndID != null
    ? 'reports'
    : runName != null
    ? 'runs'
    : undefined;
}

type UseReportNameAndIDParams = {
  reportNameAndID: string | undefined;
  projObjType: ProjObjType | undefined;
};

type UseReportNameAndIDResult = {
  reportName: string;
  reportID: string;
};

export function useReportNameAndID({
  reportNameAndID,
  projObjType,
}: UseReportNameAndIDParams): UseReportNameAndIDResult {
  const parsed = parseReportParams(reportNameAndID ?? ``);
  const reportName = parsed.reportName ?? '';
  const reportID = parsed.reportId ?? '';

  // Special characters in international report names are stripped from the url.
  // Need to access the report view in redux to fetch the display name for nav breadcrumbs.
  const view = useSelector(state => {
    if (projObjType !== 'reports') {
      return null;
    }
    return Object.values(state.views.views).find(v => v.id === reportID);
  });

  return {
    reportName: view?.displayName ?? reportName,
    reportID,
  };
}

export type SEOBreadcrumb = {
  name: string;
  path: string;
};

// HAX: this works with host/src/report.ts to ensure the app does not override custom SEO breadcrumbs
export function getSEOBreadcrumbs(): SEOBreadcrumb[] {
  return (window as any).__WB_SEO_BREADCRUMBS;
}
