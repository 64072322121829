import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import DOMPurify from 'dompurify';
import React from 'react';

import {
  Banner,
  BannerBoldText,
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../../../components/Banner';
import config from '../../../../config';
import {
  useDismissedBannerQuery,
  useSetDismissedBannerMutation,
} from '../../../../generated/graphql';
import {useViewer} from '../../../../state/viewer/hooks';

const DEFAULT_BANNER_SHOW_AFTER = 1000 * 60 * 60 * 24 * 7; // 7 days

type BannerComponent<T> = React.FC<T & {onDismiss: () => Promise<void>}>;

type DismissibleBanner<T> = {
  Component: BannerComponent<T>;
  id: (props: T) => string;
  showAfter?: number;
};

const useDismissedBannerCheck = (
  bannerId: string,
  customShowAfter?: number
) => {
  const showAfter = customShowAfter ?? DEFAULT_BANNER_SHOW_AFTER;
  const [setDismissedBanner] = useSetDismissedBannerMutation({
    variables: {
      id: bannerId,
    },
  });

  const {data, loading, refetch} = useDismissedBannerQuery({
    variables: {
      id: bannerId,
    },
  });

  const onDismiss = async () => {
    await setDismissedBanner();
    await refetch();
  };

  if (loading) {
    return {
      dismissed: false,
      loading,
      onDismiss,
    };
  }

  if (data?.dismissedBanner?.dismissedAt) {
    const today = new Date().getTime();
    const bannerDismissedAt = new Date(
      data.dismissedBanner.dismissedAt
    ).getTime();
    const timeAgoBannerWasDismissed = today - bannerDismissedAt;
    if (timeAgoBannerWasDismissed < showAfter) {
      return {
        dismissed: true,
        loading,
        onDismiss,
      };
    }
  }

  return {
    dismissed: false,
    loading,
    onDismiss,
  };
};

export const createDismissibleBanner = <T extends object>(
  b: DismissibleBanner<T>
): React.FC<T> => {
  return (props: T) => {
    const bannerId = typeof b.id === 'function' ? b.id(props) : b.id;

    const {dismissed, loading, onDismiss} = useDismissedBannerCheck(bannerId);

    if (loading || dismissed) {
      return null;
    }

    return (
      <b.Component
        {...props}
        onDismiss={async () => {
          await onDismiss();
        }}
      />
    );
  };
};

type SystemBannerProps = {
  id: string;
  type: 'warning' | 'info' | 'error';
  message?: string;
  heading?: string;
  dismissable?: boolean;
};

const SystemBanner = createDismissibleBanner<SystemBannerProps>({
  id: props => props.id,
  Component: props => {
    const {
      heading,
      type = 'info',
      message,
      dismissable = true,
      onDismiss,
    } = props;
    if (message == null) {
      return null;
    }
    return (
      <Tailwind>
        <Banner variant={type} className="mb-4">
          <BannerFlexWrapper>
            <Icon name={type === 'info' ? 'info' : 'warning'} />
            <BannerTextWrapper>
              {heading && <BannerBoldText>{heading}</BannerBoldText>}
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(message),
                }}
              />
            </BannerTextWrapper>
          </BannerFlexWrapper>
          {dismissable && (
            <button
              className="flex items-center border-none bg-transparent font-semibold text-moon-600 hover:cursor-pointer hover:text-moon-900"
              onClick={onDismiss}>
              <Icon name="close" />
            </button>
          )}
        </Banner>
      </Tailwind>
    );
  },
});

export const ServerBanners = () => {
  const viewer = useViewer();
  if (viewer == null) {
    return null;
  }

  return (
    <>
      {Object.entries(config.BANNERS ?? {})
        .filter(([_, banner]) => !banner.adminOnly || viewer?.admin)
        .map(([id, banner]) => (
          <SystemBanner key={id} {...(banner as SystemBannerProps)} id={id} />
        ))}
    </>
  );
};
