import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox} from 'semantic-ui-react';

import DOC_URLS from '../../util/doc_urls';
import {LabeledOptionBlock as LabeledOption} from '../elements/LabeledOptionBlock';
import ProjectFieldSelector from '../ProjectFieldSelector';
import {StyledWrapper} from './common';
import {type PanelGroupingOptionsProps} from './types';

export function MultiRunAggOptions(props: PanelGroupingOptionsProps) {
  // show user options to aggregate over runs
  const {config, updateConfig, pageQuery, disabled} = props;
  return (
    <LabeledOption
      label="Runs"
      helpText="Aggregate over runs"
      docUrl={DOC_URLS.compareMetrics + '#grouping'}
      option={
        <StyledWrapper data-test="styled-wrapper">
          <Checkbox
            toggle
            disabled={disabled}
            checked={config.aggregate}
            name="aggregate"
            onChange={(e, value) =>
              updateConfig({
                aggregate: value.checked,
              })
            }
          />
          {config.aggregate && (
            <>
              <span>Group by</span>
              <ProjectFieldSelector
                className={'groupby-picker'}
                disabled={!props.config.aggregate}
                query={pageQuery}
                selection
                columns={['config']}
                defaultKeys={['config:None']}
                value={`config:${config.groupBy ?? 'None'}`}
                multi={false}
                searchByKeyAndText
                setValue={value =>
                  updateConfig({
                    groupBy: value.split(':')[1],
                  })
                }
              />
            </>
          )}
        </StyledWrapper>
      }
    />
  );
}

export function SingleRunAggOptions(props: PanelGroupingOptionsProps) {
  // user will always aggregate over metrics
  // if that's not possible show warning
  const {config} = props;
  return (
    <>
      {(config.metrics == null || config.metrics.length <= 1) && (
        <>
          <p>Select multiple metrics to enable grouping</p>
        </>
      )}
    </>
  );
}
