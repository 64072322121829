import {createAction} from 'typesafe-actions';

import * as Api from './api';
import * as Types from './types';

/*
 *                     HERE THERE BE DRAGONS
 *
 * Note that the payload of view actions matters to the save saga. Specifically,
 * in isSaveableActions' usage of isViewPartUpdateAction,isViewPartsUpdateAction
 * and isViewUpdateAction it examines the payloads of actions to see if they have
 * specifically named fields like ref and id. Whether they are present or not
 * indication whether or not it should trigger a save.
 */

// Internal Actions
export const loadMetadataListFinished = createAction(
  '@view/loadMetadataListFinished',
  action => (id: string, result: Api.LoadMetadataListResultType) =>
    action({id, result})
);

export const unloadMetadataList = createAction(
  '@view/unloadMetadatalist',
  action => (id: string) => action({id})
);

export const unloadView = createAction(
  '@view/unloadView',
  action => (id: string) => action({id})
);

export const clearReportViews = createAction(
  '@view/clearReportViews',
  action => () => action()
);

export const loadStarted = createAction(
  '@view/loadStarted',
  action => () => action({})
);

export const unloadNoMatch = createAction(
  '@view/unloadNoMatch',
  action => () => action({})
);

export const loadFinished = createAction(
  '@view/loadFinished',
  action => (cid: string, result: Api.LoadResultType, autoSave: boolean) =>
    action({cid, result, autoSave})
);

export const addNormalizedPanelGrid = createAction(
  '@view/addNormalizedPanelGrid',
  action => (id: string, partsWithRefs: any) => action({id, partsWithRefs})
);

export const removeNormalizedPanelGrid = createAction(
  '@view/removeNormalizedPanelGrid',
  action => (id: string, sectionRef: any) => action({id, sectionRef})
);

export const markModified = createAction(
  '@view/markModified',
  action => (id: string) => action({id})
);

export const saveStarted = createAction(
  '@view/saveStarted',
  action => (cid: string) => action({cid})
);

export const saveFinished = createAction(
  '@view/saveFinished',
  action => (cid: string, result: Api.SaveResultType) => action({cid, result})
);

export const saveFailed = createAction(
  '@view/saveFailed',
  action => (cid: string) => action({cid})
);

// This replaces the whole view, it's used to copy other workspaces into the
// user's current workspace. It's in this internal file because other modules
// should use the thunk instead.
export const updateViewSpec = createAction(
  '@view/updateViewSpec',
  action => (id: string, spec: any) => action({id, spec})
);

export const undoableUpdateViewSpec = createAction(
  '@view/undoableUpdateViewSpec',
  action =>
    (
      ref: {id: string}, // we need viewID to be in this structure to trigger save saga
      args: {
        prevSpec: any; // prevSpec is the one we want to come back to when user clicks on undo
        newSpec: any;
      }
    ) =>
      action({...args, ref})
);

export const updateViewName = createAction(
  '@view/updateViewName',
  action => (ref: Types.ViewRef, name: string) => action({ref, name})
);

// Similarly, deleting objects is dangerous, so most modules should use the thunk instead.
export const deleteObject = createAction(
  '@view/deleteObject',
  action => (ref: Types.AllPartRefs) => action({ref})
);

export const deleteHistoryForObject = createAction(
  '@view/deleteHistoryForObject',
  action => (ref: Types.AllPartRefs) => action({ref})
);

// Note: This probably isn't strictly necessary and might be covered already by other cleanup
// logic, but in case we mess up cleanup somewhere along the line, having bad undo/redo state
// is more pernicious than orphaned view parts.
export const deleteUndoRedoHistory = createAction(
  '@view/deleteUndoRedoHistory',
  action => () => action()
);

export const starViewStarted = createAction(
  '@view/starViewStarted',
  action => (id: string) => action({id})
);

export const starViewFinished = createAction(
  '@view/starViewFinished',
  action => (id: string, starCount: number) => action({id, starCount})
);

export const unstarViewStarted = createAction(
  '@view/unstarViewStarted',
  action => (id: string) => action({id})
);

export const unstarViewFinished = createAction(
  '@view/unstarViewFinished',
  action => (id: string, starCount: number) => action({id, starCount})
);
