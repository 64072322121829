import {Expression, identifiersInExpression} from '../expr';

/**
 * Check if any of the expressions fed into the panel are multi-metric expressions. Multi-metric expressions are more complicated to evalute because there's no guarantee that every metric is logged at the same interval, so tripping this means further processing is required before we can evaluate the expression.
 */
export function hasMultiMetricExpression(expressions: Expression[]) {
  let isMultiMetricExpression = false;
  expressions.forEach(expr => {
    const metrics = getIdentifiers(expr);

    if (metrics.length > 1) {
      isMultiMetricExpression = true;
    }
  });

  return isMultiMetricExpression;
}

export function getIdentifiers(expression: Expression) {
  const metrics = identifiersInExpression(expression, {ignoreCallee: true});

  // config metrics are excluded because the config values are constants and apply at every point in the chart
  return metrics.filter(m => !m.startsWith('config:'));
}
