import React, {useEffect} from 'react';

import {isInJupyterNotebook} from '../setup';
import {useDispatch, useSelector} from '../state/hooks';
import {setUserPollingOK} from '../state/polling/actions';
import {useAutoRefreshContext} from '../util/userContext';

interface AutoRefreshSettingsProps {
  children: (
    autoRefreshEnabled: boolean,
    setAutorefreshEnabled: (enabled: boolean) => void
  ) => React.ReactNode;
}

const AutoRefreshSettings: React.FC<AutoRefreshSettingsProps> = React.memo(
  ({children}) => {
    const {autoRefreshEnabled, setAutoRefreshEnabled} =
      useAutoRefreshSettings();
    return <div>{children(autoRefreshEnabled, setAutoRefreshEnabled)}</div>;
  }
);

export default AutoRefreshSettings;

type AutoRefreshSettingsResult = {
  autoRefreshEnabled: boolean;
  setAutoRefreshEnabled: (enabled: boolean) => void;
};

export function useAutoRefreshSettings(): AutoRefreshSettingsResult {
  const {state, setState} = useAutoRefreshContext();
  const userAutoRefreshDisabled = state.disabled ?? false;
  const setUserAutoRefreshDisabled = (d: boolean) => setState({disabled: d});

  const userPollingOK = useSelector(s => s.polling.userPollingOK);
  const dispatch = useDispatch();

  // Mirror UserContext state into redux.
  useEffect(() => {
    dispatch(setUserPollingOK(!userAutoRefreshDisabled));
  }, [dispatch, userAutoRefreshDisabled]);

  return {
    // Enable auto-refresh by default in Jupyter notebooks.
    autoRefreshEnabled: userPollingOK || isInJupyterNotebook(),
    setAutoRefreshEnabled: enabled => setUserAutoRefreshDisabled(!enabled),
  };
}
