import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Input as SemanticInput} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const CheckoutForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  padding: 0px;
  width: 400px;
  height: 100%;
`;

export const CheckoutFields = styled.div`
  flex: 1 0 auto;
`;

export const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  color: ${globals.MOON_800};
`;

export const Input = styled(SemanticInput)`
  &&&&& {
    &.disabled {
      opacity: 1;
    }
    input:disabled {
      background-color: ${globals.MOON_200};
      color: ${globals.MOON_600};
    }
  }
`;

export const ProcessingMessage = styled.div`
  padding: 12px 0;
  line-height: 20px;
  background-color: ${globals.GREEN_500};
  color: ${globals.WHITE};
`;

export const AgreementLabel = styled.div`
  &&& {
    font-size: 14px;
    color: ${globals.MOON_600};
  }
  text-align: left;
`;
