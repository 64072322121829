import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {get} from 'lodash';

import {envIsDev, envIsIntegration} from './config';
import introspectionQueryResultData from './generated/fragmentTypes.json';
import {apolloLink} from './util/apollo';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const appendCustomId = (
  defaultId: string | null,
  customIdType: string,
  customId: string | undefined
) => {
  if (customId == null || defaultId == null) {
    return defaultId;
  }
  return `${defaultId},${customIdType}:${customId}`;
};

// Default on; disable with `GQL_NO_QUERY_DEDUP` query param
// e.g., https://wandb.ai/?GQL_NO_QUERY_DEDUP
const shouldEnableQueryDeduplication = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return !urlParams.has('GQL_NO_QUERY_DEDUP');
};

/**
 * Using `fetch-policy: no-cache` apparently doesn't keep Apollo from writing to the cache. This can get extremely resource intensive for certain queries that we don't intend to cache anyway. This client can be injected into `useQuery` from `@apollo/react-hooks` to avoid this behavior on a one-off basis without creating regressions elsewhere
 */
export const apolloClientNoCache = new ApolloClient({
  link: apolloLink,
  connectToDevTools: envIsDev || envIsIntegration,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    dataIdFromObject: object => null,
  }),
  // This is necessary because we abort inflight queries when components unmount.
  // Without this option, Apollo will never fire an aborted query again, even on remount.
  queryDeduplication: shouldEnableQueryDeduplication(),
});

// Create apollo client
export const apolloClient = new ApolloClient({
  link: apolloLink,
  connectToDevTools: envIsDev || envIsIntegration,
  cache: new InMemoryCache({
    fragmentMatcher,
    // Search for "Apollo Custom Cache Ids" in Notion for implemenation details
    dataIdFromObject: object => {
      const defaultId = defaultDataIdFromObject(object);
      if (object == null || defaultId == null) {
        return defaultId;
      }
      switch (object.__typename) {
        case 'Member':
          return appendCustomId(defaultId, 'role', get(object, 'role'));
        case 'OrganizationMember': {
          const adminId = appendCustomId(
            defaultId,
            'admin',
            get(object, 'admin')
          );
          return appendCustomId(adminId, 'orgID', get(object, 'orgID'));
        }
        default:
          return defaultId;
      }
    },
  }),

  // Uncomment to log helpful messages when trying to find objects
  //     that don't have IDs. Don't do this in production since this
  //     function is called for each cache entry for each query.
  // cache: new InMemoryCache({
  //   fragmentMatcher,
  //   dataIdFromObject: object => {
  //     if (
  //       object.__typename != null &&
  //       !object.__typename.endsWith('Edge') &&
  //       !object.__typename.endsWith('Connection')
  //     ) {
  //       if (object.id == null) {
  //         console.warn('Trying to cache object with no ID', object);
  //       }
  //     }
  //     return object.id || null;
  //   },
  // }),
  assumeImmutableResults: true,

  // This is necessary because we abort inflight queries when components unmount.
  // Without this option, Apollo will never fire an aborted query again, even on remount.
  queryDeduplication: shouldEnableQueryDeduplication(),
});

// apollo-client caches all mutations permanently, just so it
// can display them in the devtools, even if the devtools don't exist.
// It causes a huge memory leak, especially when we auto-save view specs
// which can be very large.
// This is completely braindead and is just of many indicators of poor
// engineering quality from Apollo.
// The problem was reported a long time ago with a nice repro, but never
// addressed: https://github.com/apollographql/apollo-client/issues/2302
// It may be fixed in apollo3, but I have little trust in Apollo and think
// upgrading is pretty risky. We'll need to do a lot of testing whenever
// we decide to do it.
//
// We monkey patch Apollo's mutationStore to disable this behavior.
const mutationStore = apolloClient.queryManager.mutationStore;
const mutationStoreInitMutation: typeof mutationStore.initMutation = function (
  this: any,
  mutationId
) {
  this.store[mutationId] = {
    mutation: 'removed, see WB-3579',
    variables: {},
    loading: true,
    error: null,
  };
};
mutationStore.initMutation = mutationStoreInitMutation;
