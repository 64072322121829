/* eslint-disable no-template-curly-in-string */

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'contour',
  displayName: 'Contour (Vega)',
  description: 'Contour plot using Vega',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    description:
      'A contour plot example, overlaying a density estimate on scatter plot points.',
    width: 500,
    height: 400,
    padding: 5,
    autosize: 'pad',
    signals: [
      {
        name: 'bandwidth',
        value: -1,
        bind: {
          input: 'range',
          min: -1,
          max: 100,
          step: 1,
        },
      },
      {
        name: 'resolve',
        value: 'shared',
        bind: {
          input: 'select',
          options: ['independent', 'shared'],
        },
      },
      {
        name: 'counts',
        value: true,
        bind: {
          input: 'checkbox',
        },
      },
      {
        name: 'binOffset',
        value: 0,
        bind: {input: 'range', min: -0.1, max: 0.1},
      },
      {
        name: 'binStep',
        value: 0.1,
        bind: {input: 'range', min: 0.001, max: 0.4, step: 0.001},
      },
    ],
    data: [
      {
        name: 'wandb',
        transform: [
          {
            type: 'filter',
            expr: 'datum.${field:x} != null && datum.${field:y} != null && datum.${field:group} != null',
          },
        ],
      },
      {
        name: 'binned',
        source: 'wandb',
        transform: [
          {
            type: 'bin',
            field: '${field:group}',
            extent: [0, 1],
            anchor: {signal: 'binOffset'},
            step: {signal: 'binStep'},
            nice: false,
          },
        ],
      },
      {
        name: 'density',
        source: 'binned',
        transform: [
          {
            type: 'kde2d',
            groupby: ['bin0'],
            size: [
              {
                signal: 'width',
              },
              {
                signal: 'height',
              },
            ],
            x: {
              expr: "scale('x', datum.${field:x})",
            },
            y: {
              expr: "scale('y', datum.${field:y})",
            },
            bandwidth: {
              signal: '[bandwidth, bandwidth]',
            },
            counts: {
              signal: 'counts',
            },
          },
        ],
      },
      {
        name: 'contours',
        source: 'density',
        transform: [
          {
            type: 'isocontour',
            field: 'grid',
            resolve: {
              signal: 'resolve',
            },
            levels: 3,
          },
        ],
      },
    ],
    scales: [
      {
        name: 'x',
        type: 'linear',
        round: true,
        nice: true,
        zero: true,
        domain: {
          data: 'wandb',
          field: '${field:x}',
        },
        range: 'width',
      },
      {
        name: 'y',
        type: 'linear',
        round: true,
        nice: true,
        zero: true,
        domain: {
          data: 'wandb',
          field: '${field:y}',
        },
        range: 'height',
      },
      {
        name: 'color',
        type: 'ordinal',
        domain: {
          data: 'binned',
          field: 'bin0',
          sort: {
            order: 'descending',
          },
        },
        range: 'category',
      },
    ],
    axes: [
      {
        scale: 'x',
        grid: true,
        domain: false,
        orient: 'bottom',
        tickCount: 5,
        title: '${field:x}',
      },
      {
        scale: 'y',
        grid: true,
        domain: false,
        orient: 'left',
        titlePadding: 5,
        title: '${field:y}',
      },
    ],
    legends: [
      {
        stroke: 'color',
        symbolType: 'stroke',
      },
    ],
    marks: [
      {
        name: 'marks',
        type: 'symbol',
        from: {
          data: 'wandb',
        },
        encode: {
          update: {
            x: {
              scale: 'x',
              field: '${field:x}',
            },
            y: {
              scale: 'y',
              field: '${field:y}',
            },
            size: {
              value: 10,
            },
            fill: {
              value: '#ccc',
            },
          },
        },
      },
      {
        type: 'image',
        from: {
          data: 'density',
        },
        encode: {
          update: {
            x: {
              value: 0,
            },
            y: {
              value: 0,
            },
            width: {
              signal: 'width',
            },
            height: {
              signal: 'height',
            },
            aspect: {
              value: false,
            },
          },
        },
        transform: [
          {
            type: 'heatmap',
            field: 'datum.grid',
            resolve: {
              signal: 'resolve',
            },
            color: {
              expr: "scale('color', datum.datum.bin0)",
            },
          },
        ],
      },
      {
        type: 'path',
        clip: true,
        from: {
          data: 'contours',
        },
        encode: {
          enter: {
            strokeWidth: {
              value: 1,
            },
            strokeOpacity: {
              value: 1,
            },
            stroke: {
              scale: 'color',
              field: 'bin0',
            },
          },
        },
        transform: [
          {
            type: 'geopath',
            field: 'datum.contour',
          },
        ],
      },
    ],
  },
};

export default def;
