import {ActionType as TSActionType, getType} from 'typesafe-actions';

import * as InteractStateActions from './actions';
import {Highlight, HighlightUpdate, InteractState} from './types';

export const EMPTY_STATE = {
  highlight: {},
  panelSelection: [],
};

function updateHighlight(
  highlight: Highlight,
  {axis, value}: HighlightUpdate
): Highlight {
  const newHighlight = {...highlight};
  if (value == null) {
    delete newHighlight[axis];
  } else {
    newHighlight[axis] = value;
  }
  return newHighlight;
}

export default function reducer(
  state: InteractState = EMPTY_STATE,
  action: TSActionType<typeof InteractStateActions>
) {
  switch (action.type) {
    case getType(InteractStateActions.setHighlight): {
      return {
        ...state,
        highlight: updateHighlight(state.highlight, action.payload),
      };
    }
    case getType(InteractStateActions.setHighlights): {
      const {highlights} = action.payload;
      return {
        ...state,
        highlight: highlights.reduce(updateHighlight, state.highlight),
      };
    }
    case getType(InteractStateActions.setPanelSelection): {
      return {
        ...state,
        panelSelection: action.payload.value,
      };
    }
  }
  return state;
}
