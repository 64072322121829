import {AnyAction} from 'redux';

import {UPDATE_LOCATION_PARAMS} from '../actions/location';

export default function location(state = {}, action: AnyAction) {
  switch (action.type) {
    case UPDATE_LOCATION_PARAMS:
      return {...state, params: action.params};
    default:
      return state;
  }
}
