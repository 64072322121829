import {useCallback} from 'react';

import {TeamType} from '../../../components/CreateTeam/types';
import {Analytics} from '../../../services/analytics';

type EventData = {
  teamType?: TeamType;
  organizationName?: string | null;
  entityName?: string;
  error?: string | null;
};

export const useCreateTeamDrawerAnalytics = (location: string) => {
  const makeTrack = useCallback(
    (eventName: string) => (eventData?: EventData) => {
      Analytics.track(eventName, {location, ...eventData});
    },
    [location]
  );

  return {
    teamTypeSelected: makeTrack('Team Type Selected'),
    createTeamViewed: makeTrack('Create Team Viewed'),
    createTeamClicked: makeTrack('Create Team Clicked'),
    noTeamsLeftViewed: makeTrack('No teams left viewed'),
    noTeamsLeftClicked: makeTrack('No teams left clicked'),
    trialTeamSubmitted: makeTrack('Trial Team Submitted'),
    inviteErrorViewed: makeTrack('Invite Error Viewed'),
    inviteSubmitted: makeTrack('Invite Submitted'),
    teamSubmitted: makeTrack('Team Submitted'),
  };
};
