/* Just used for passing args to the Create Sweep page for now */
import {ActionType as TSActionType, getType} from 'typesafe-actions';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as Sweeps from '../../util/sweeps';
import {immerReducer} from '../reducer';
import * as Actions from './actions';

export interface StateType {
  createSweepArgs?: Sweeps.CreateSweepArgs;
}

export type ActionType = TSActionType<typeof Actions>;

function sweepsReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case getType(Actions.clearCreateSweepArgs):
      state.createSweepArgs = undefined;
      break;
    case getType(Actions.setCreateSweepArgs):
      state.createSweepArgs = action.payload.createSweepArgs;
      break;
  }
}

export default immerReducer<StateType, ActionType>(sweepsReducer, {});
