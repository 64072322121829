import * as Types from './types';
import {WholeFromType} from './types';

export type StateType = {
  [T in Types.ObjType]: {
    [id: string]: Types.PartFromType<T>;
  };
};

type IdFn<T> = (it: T) => string;

interface NormContext {
  viewID: string;
  result: Array<PartWithRef<Types.ObjType>>;
  idFn: IdFn<WholeFromType<Types.ObjType>>;
}

export type NormFn<S extends Types.ObjSchema> = (
  whole: S['whole'],
  ctx: NormContext
) => S['part'];

export type FullNormFn<S extends Types.ObjSchema> = (
  whole: S['whole'],
  ctx: NormContext
) => Types.PartRefFromType<S['type']>;

export interface PartWithRef<T extends Types.ObjType> {
  ref: Types.PartRefFromType<T>;
  part: Types.PartFromType<T>;
}

export type NormFunctionMap = {
  [T in Types.ObjType]: FullNormFn<Types.ObjSchemaFromType<T>>;
};

export interface DenormalizationOptions {
  /**
   * if this is true, include refs in the denormalized output. otherwise, omit
   * them.
   */
  includeRefs: boolean;
}

type AnyRef = Types.PartRefFromObjSchema<Types.ObjSchema>;
type StackMember = {ref: AnyRef; index?: number};

interface DenormContext {
  state: StateType;
  partsWithRef: Array<PartWithRef<Types.ObjType>>;
  options: DenormalizationOptions;
  stack: Array<StackMember>;
}

export type DenormFn<S extends Types.ObjSchema> = (
  part: S['part'],
  ctx: DenormContext
) => Types.WholeFromType<S['type']>;

export type FullDenormFn<S extends Types.ObjSchema> = (
  ref: Types.PartRefFromType<S['type']>,
  ctx: DenormContext,
  index?: number
) => Types.WholeFromTypeWithRef<S['type']>;

export type DenormFunctionMap = {
  [T in Types.ObjType]: FullDenormFn<Types.ObjSchemaFromType<T>>;
};

function makeRef<S extends Types.ObjSchema>(
  type: S['type'],
  id: string,
  viewID: string
): Types.PartRefFromType<S['type']> {
  return {
    type,
    viewID,
    id,
  } as Types.PartRefFromType<S['type']>;
}

export function normFn<S extends Types.ObjSchema>(
  type: S['type'],
  userNormFn: NormFn<S>
): FullNormFn<S> {
  return (whole, ctx) => {
    const part = userNormFn(whole, ctx);
    const ref = makeRef(type, ctx.idFn(whole), ctx.viewID);
    ctx.result.push({ref, part});

    return ref;
  };
}

export function lookupPart<R extends Types.AllPartRefs>(
  state: StateType,
  partRef: R
): Types.PartFromType<R['type']> {
  const partsOfType = state[partRef.type];
  if (partsOfType == null) {
    throw new RangeError(
      [`Invalid entity state`, `${partRef.type} is not declared`].join('\n')
    );
  }
  const partNorm = partsOfType[partRef.id];
  if (partNorm == null) {
    throw new RangeError(
      [
        'Invalid part state',
        `${partRef.type}:${partRef.id} could not be found in state`,
      ].join('\n')
    );
  }
  return partNorm as Types.PartFromType<R['type']>;
}

function printDenormStack(stack: StackMember[]): string[] {
  const out = [];
  for (const {ref, index} of stack) {
    if (typeof index === 'number') {
      out.push(`  ${ref.type}:${ref.id} (idx:${index})`);
    } else {
      out.push(`  ${ref.type}:${ref.id}`);
    }
  }
  return out;
}

class DenormalizationError extends Error {}

function catchError<S extends Types.ObjSchema>(
  fn: FullDenormFn<S>
): FullDenormFn<S> {
  return (ref, ctx, index) => {
    try {
      // To save unnecessary object copies, we are storing the stack as a
      // mutable value on the context. This _requires_ the stack to be popped
      // after the denorm fn is run for each entity. Otherwise we will get bad
      // stack traces if denormalization fails.
      ctx.stack.push({ref, index});
      return fn(ref, ctx);
    } catch (e) {
      if (e instanceof DenormalizationError) {
        // already hit a denorm error, don't catch just rethrow
        throw e;
      }
      if (e instanceof Error) {
        const msg = [e.message, 'Denormalization stack:'].concat(
          printDenormStack(ctx.stack)
        );
        // cause param allows errors to be chained together. Well-supported in
        // all modern browsers.
        // @ts-expect-error The typedefs haven't caught up with usage yet.
        throw new DenormalizationError(msg.join('\n'), {cause: e});
      }
      // We were thrown garbage. Just rethrow.
      throw e;
    } finally {
      ctx.stack.pop();
    }
  };
}

export function denormFn<S extends Types.ObjSchema>(
  userDenormFn: DenormFn<S>
): FullDenormFn<S> {
  return catchError((ref, ctx) => {
    const part = lookupPart(ctx.state, ref);
    const includeRefs = ctx.options.includeRefs;
    ctx.partsWithRef.push({part, ref});
    const whole = userDenormFn(part, ctx);

    if (includeRefs) {
      return {
        ...whole,
        ref,
      } as any;
    } else {
      const refless = {...whole};
      delete (refless as any).ref;
      return refless;
    }
  });
}
