import {TeamMemberRole} from '../../components/MembersTable/TeamMembersTable/utils';
import {envIsLocal} from '../../config';
import * as Generated from '../../generated/graphql';
import {useProjectParams} from '../../pages/ProjectPage/useProjectParams';
import {viewerIsWbAdminUsingPrivileges} from '../../util/admin';
import {AccessOption, getProjectAccess} from '../../util/permissions';
import {
  getEntityPermissionResult,
  getProjectPermissionResult,
  NwProjectPermissionData,
  useNwEntityPermissionResult,
  useNwProjectPermissionResult,
} from '../graphql/namedWorkspaces';
import {
  getTeamMemberInfo,
  TeamMemberInfo,
  useTeamMember,
} from '../graphql/teamMembers';
import {ApolloClient} from '../types';
import {useViewer} from '../viewer/hooks';
import {Viewer} from '../viewer/types';
import {isAnonymousEntity} from './utils';

export const ALL_NAMED_WORKSPACE_PERMISSION: NamedWorkspacePermission[] = [
  'all',
]; // This represents viewer has permissions to perform all available NW actions.
export const NO_NAMED_WORKSPACE_PERMISSION: NamedWorkspacePermission[] = [
  'none',
];
export type NamedWorkspacePermission =
  | 'view.readwrite'
  | 'view.read'
  | 'view.personal.own.write' // Indicator to create a personal workspace and allow viewer to write to it
  | 'all'
  | 'none';
export type ProjectAccessType = 'Private' | 'Public' | 'Open' | 'Restricted';

// Determines if viewer can write to their own personal workspace (example: w&b admins using privileges)
export function viewerCanOnlyWriteToOwnPersonal(
  nwPermissions: NamedWorkspacePermission[]
) {
  return (
    nwPermissions.some(val => val === 'view.personal.own.write') &&
    viewerCanReadNw(nwPermissions)
  );
}
function viewerCanReadNw(nwPermissions: NamedWorkspacePermission[]) {
  return (
    viewerCanWriteToNw(nwPermissions) ||
    nwPermissions.some(val => val === 'view.read')
  );
}

export function viewerCanWriteToNw(nwPermissions: NamedWorkspacePermission[]) {
  return nwPermissions.some(val => val === 'view.readwrite' || val === 'all');
}
export function viewerCanOnlyReadNw(nwPermissions: NamedWorkspacePermission[]) {
  // Reading is the only action they can do
  return nwPermissions.length === 1 && nwPermissions[0] === 'view.read';
}
export function viewerHasNoNwPerms(nwPermissions: NamedWorkspacePermission[]) {
  return nwPermissions.some(val => val === 'none');
}

export type LoadPermsResult = LoadNwPermissionsResultType &
  LoadEntityAndProjectResultType;

export async function loadPerms(
  client: ApolloClient,
  viewer: Viewer | undefined,
  entityName: string,
  projectName: string
): Promise<LoadPermsResult> {
  const {entityResult, projectResult} = await loadEntityAndProjectPermissions(
    client,
    entityName,
    projectName
  );
  const {nwPermissions, teamMembers} = await loadNwPermissions(
    client,
    entityName,
    viewer,
    entityResult,
    projectResult
  );

  return Promise.resolve({
    nwPermissions,
    teamMembers,
    entityResult,
    projectResult,
  });
}

type LoadEntityAndProjectResultType = {
  entityResult: ReturnType<typeof getEntityPermissionResult>;
  projectResult: ReturnType<typeof getProjectPermissionResult>;
};
async function loadEntityAndProjectPermissions(
  client: ApolloClient,
  entityName: string,
  projectName: string
): Promise<LoadEntityAndProjectResultType> {
  const entityResult = await client.query<Generated.NwEntityPermissionQuery>({
    query: Generated.NwEntityPermissionDocument,
    variables: {entityName},
  });

  const projectResult = await client.query<Generated.NwProjectPermissionQuery>({
    query: Generated.NwProjectPermissionDocument,
    variables: {entityName, projectName},
  });

  return {
    entityResult: getEntityPermissionResult(
      entityResult.data,
      entityResult.loading,
      entityResult.errors?.[0]
    ),
    projectResult: getProjectPermissionResult(
      projectResult.data,
      projectResult.loading,
      projectResult.errors?.[0]
    ),
  };
}

type LoadNwPermissionsResultType = {
  nwPermissions: ReturnType<typeof getNwPermissions>;
  teamMembers: Partial<Generated.Member[]>;
};
async function loadNwPermissions(
  client: ApolloClient,
  entityName: string,
  viewer: Viewer | undefined,
  entityResult: LoadEntityAndProjectResultType['entityResult'],
  projectResult: LoadEntityAndProjectResultType['projectResult']
) {
  let teamMemberInfo: TeamMemberInfo | undefined;
  let teamMembers: Partial<Generated.Member[]> = [];

  if (entityResult?.data?.isTeam) {
    const teamMembersResult = await client.query<Generated.TeamMembersQuery>({
      query: Generated.TeamMembersDocument,
      variables: {entityName, viewer},
    });
    teamMembers =
      (teamMembersResult.data.entity?.members as Partial<Generated.Member[]>) ??
      [];
    teamMemberInfo = getTeamMemberInfo(viewer, teamMembers);
  }

  return {
    nwPermissions: getNwPermissions({
      viewerId: viewer?.id,
      viewerUsername: viewer?.username,
      isUsingAdminPowers: envIsLocal
        ? viewer?.admin ?? false
        : viewerIsWbAdminUsingPrivileges(viewer),
      entityResult,
      projectResult,
      teamMemberInfo,
    }),
    teamMembers,
  };
}

export type NwPermissionResult = {
  data: NamedWorkspacePermission[];
  loading: boolean;
  error: Error | undefined;
};

// Determines the view actions the current viewer can do
export function useNwPermissions(): NwPermissionResult {
  const viewer = useViewer();
  const {entityName, projectName} = useProjectParams();
  const entityResult = useNwEntityPermissionResult({entityName});
  const projectResult = useNwProjectPermissionResult({
    entityName,
    projectName,
  });

  // Only fetch if we know it's a team entity
  const teamMemberInfo = useTeamMember({
    viewer,
    entityName,
    skip: entityResult == null || !entityResult?.data?.isTeam,
  });

  return getNwPermissions({
    viewerId: viewer?.id,
    viewerUsername: viewer?.username,
    isUsingAdminPowers: envIsLocal
      ? viewer?.admin ?? false
      : viewerIsWbAdminUsingPrivileges(viewer),
    entityResult,
    projectResult,
    teamMemberInfo,
  });
}

export const INVALID_PROJECT_ACCESS_VAL_ERROR = {
  name: 'Unable to determine project access permissions.',
  message: 'Invalid project access value.',
};
export const NO_NW_PERMISSIONS_ERROR = {
  name: 'Unable to view this named workspace',
  message: 'No permissions granted.',
};

export function getNwPermissions({
  viewerId,
  viewerUsername,
  isUsingAdminPowers,
  entityResult,
  projectResult,
  teamMemberInfo,
}: {
  viewerId: string | undefined;
  viewerUsername: string | undefined;
  isUsingAdminPowers: boolean;
  entityResult: ReturnType<typeof useNwEntityPermissionResult>;
  projectResult: ReturnType<typeof useNwProjectPermissionResult>;
  teamMemberInfo: TeamMemberInfo | undefined;
}): NwPermissionResult {
  const {
    data: entityData,
    loading: entityLoading,
    error: entityError,
  } = entityResult;
  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = projectResult;
  if (
    entityLoading ||
    projectLoading ||
    entityError ||
    projectError ||
    entityData == null ||
    projectData == null
  ) {
    return {
      data: NO_NAMED_WORKSPACE_PERMISSION,
      loading: entityLoading || projectLoading,
      error: entityError ?? projectError,
    };
  }

  const projectAccess = getProjectAccess(projectData.projectAccess);
  if (projectAccess == null) {
    return {
      data: NO_NAMED_WORKSPACE_PERMISSION,
      loading: false,
      error: INVALID_PROJECT_ACCESS_VAL_ERROR,
    };
  }

  if (
    projectData?.projectUsername &&
    isAnonymousEntity(projectData.projectUsername)
  ) {
    return {
      data: ['view.read'],
      loading: false,
      error: undefined,
    };
  }

  // If WB admin powers are on (which only applies on saas), only allow
  // employees to view workspaces and write to their own personal workspace.
  if (!envIsLocal && isUsingAdminPowers) {
    return {
      data: ['view.read', 'view.personal.own.write'],
      loading: false,
      error: undefined,
    };
  }

  if (entityData.isTeam && projectAccess === AccessOption.Restricted) {
    return getRestrictedProjectPermissionResult(
      projectData,
      teamMemberInfo,
      viewerId,
      isUsingAdminPowers,
      entityData.isTeamHidden
    );
  }

  if (entityData.isTeam) {
    return getTeamProjectPermissionsResult(
      teamMemberInfo,
      projectAccess,
      isUsingAdminPowers,
      entityData.isTeamHidden
    );
  }

  // Handle personal entity permissions based on viewer
  const personalEntityPerms = getPersonalEntityPermissions(
    projectAccess,
    viewerUsername,
    projectData.projectUsername,
    isUsingAdminPowers
  );

  return {
    data: personalEntityPerms,
    loading: false,
    error: viewerHasNoNwPerms(personalEntityPerms)
      ? NO_NW_PERMISSIONS_ERROR
      : undefined,
  };
}

function getRestrictedProjectPermissionResult(
  projectData: NwProjectPermissionData,
  teamMemberInfo: TeamMemberInfo | undefined,
  viewerId: string | undefined,
  isUsingAdminPowers: boolean,
  isTeamHidden: boolean
): NwPermissionResult {
  const foundProjectMember = projectData.restrictedProjectMembers.find(
    m => m.id === viewerId
  );

  // server instance admins can only have read access if they are not
  // invited to a restricted project
  if (envIsLocal && isUsingAdminPowers && foundProjectMember == null) {
    return {
      data: ['view.read', 'view.personal.own.write'],
      loading: false,
      error: undefined,
    };
  }

  if (foundProjectMember != null) {
    return getTeamProjectPermissionsResult(
      teamMemberInfo,
      AccessOption.Restricted,
      isUsingAdminPowers,
      isTeamHidden
    );
  }

  return {
    data: NO_NAMED_WORKSPACE_PERMISSION,
    loading: false,
    error: NO_NW_PERMISSIONS_ERROR,
  };
}

function getPersonalEntityPermissions(
  projectAccess: AccessOption,
  viewerUsername?: string,
  projectUsername?: string,
  isUsingAdminPowers?: boolean
): NamedWorkspacePermission[] {
  if (projectAccess === AccessOption.Private) {
    return viewerUsername === projectUsername ||
      (isUsingAdminPowers && envIsLocal)
      ? ALL_NAMED_WORKSPACE_PERMISSION
      : NO_NAMED_WORKSPACE_PERMISSION;
  }
  if (projectAccess === AccessOption.Public) {
    return viewerUsername === projectUsername
      ? ALL_NAMED_WORKSPACE_PERMISSION
      : ['view.read'];
  }
  if (projectAccess === AccessOption.Open) {
    return ALL_NAMED_WORKSPACE_PERMISSION; // Everyone can contribute to personal entity
  }
  if (projectAccess === AccessOption.Restricted) {
    return viewerUsername === projectUsername
      ? ALL_NAMED_WORKSPACE_PERMISSION
      : NO_NAMED_WORKSPACE_PERMISSION;
  }
  return NO_NAMED_WORKSPACE_PERMISSION;
}

function getTeamProjectPermissionsResult(
  teamMemberInfo: TeamMemberInfo | undefined,
  projectAccess: AccessOption,
  isUsingAdminPowers: boolean,
  isTeamHidden: boolean
): NwPermissionResult {
  // server instance admins have write access to non-restricted projects
  // they are not a part of
  if (
    envIsLocal &&
    isUsingAdminPowers &&
    teamMemberInfo?.foundTeamMember == null
  ) {
    return {
      data: ALL_NAMED_WORKSPACE_PERMISSION,
      loading: false,
      error: undefined,
    };
  }

  // Hidden is set in team settings and can be toggled by team admins.
  // This flag hides the team from all non-members, so should take precedence.
  if (isTeamHidden) {
    const hiddenTeamPerms = getHiddenTeamRolePermissions(teamMemberInfo);
    return {
      data: hiddenTeamPerms,
      loading: false,
      error: viewerHasNoNwPerms(hiddenTeamPerms)
        ? NO_NW_PERMISSIONS_ERROR
        : undefined,
    };
  }

  const teamRolePerms = getTeamRolePermissions(projectAccess, teamMemberInfo);
  return {
    data: teamRolePerms,
    loading: false,
    error: viewerHasNoNwPerms(teamRolePerms)
      ? NO_NW_PERMISSIONS_ERROR
      : undefined,
  };
}

function getHiddenTeamRolePermissions(
  teamMemberInfo?: TeamMemberInfo
): NamedWorkspacePermission[] {
  const role = teamMemberInfo?.foundTeamMember?.role;
  if (teamMemberInfo == null || role === TeamMemberRole.SERVICE) {
    return NO_NAMED_WORKSPACE_PERMISSION;
  }

  if (role === TeamMemberRole.VIEW_ONLY) {
    return ['view.read'];
  }

  if (teamMemberInfo?.viewerIsTeamMember) {
    return ALL_NAMED_WORKSPACE_PERMISSION;
  }

  return NO_NAMED_WORKSPACE_PERMISSION;
}

function getTeamRolePermissions(
  projectAccess: AccessOption,
  teamMemberInfo?: TeamMemberInfo
): NamedWorkspacePermission[] {
  if (projectAccess === AccessOption.Open) {
    return ALL_NAMED_WORKSPACE_PERMISSION; // all users have read and write access, we ignore team roles in this case
  }

  const role = teamMemberInfo?.foundTeamMember?.role;
  const isViewOnlyTeamMember = role === TeamMemberRole.VIEW_ONLY;

  if (role === TeamMemberRole.SERVICE) {
    return NO_NAMED_WORKSPACE_PERMISSION; // TODO - confirm
  }

  if (projectAccess === AccessOption.Public) {
    // View-only and external viewers do not have write permission in workspaces
    return teamMemberInfo?.viewerIsTeamMember && !isViewOnlyTeamMember
      ? ALL_NAMED_WORKSPACE_PERMISSION
      : ['view.read'];
  }

  if (
    projectAccess === AccessOption.Private ||
    projectAccess === AccessOption.Restricted
  ) {
    if (isViewOnlyTeamMember) {
      return ['view.read'];
    }
    return teamMemberInfo?.viewerIsTeamMember
      ? ALL_NAMED_WORKSPACE_PERMISSION
      : NO_NAMED_WORKSPACE_PERMISSION; // External viewers should not be able to do anything in a private workspace
  }

  return NO_NAMED_WORKSPACE_PERMISSION;
}
