import React, {FC, memo, useMemo} from 'react';

import Breadcrumbs, {BreadcrumbPart} from '../../../Breadcrumbs';
import {SEOBreadcrumb} from './utils';

type NavBreadcrumbsSEOProps = {
  breadcrumbs: SEOBreadcrumb[];
};

const NavBreadcrumbsSEOComp: FC<NavBreadcrumbsSEOProps> = ({breadcrumbs}) => {
  const parts: BreadcrumbPart[] = useMemo(
    () => breadcrumbs.map(({name, path}) => ({label: name, to: path})),
    [breadcrumbs]
  );

  return <Breadcrumbs parts={parts} />;
};

const NavBreadcrumbsSEO = memo(NavBreadcrumbsSEOComp);

export default NavBreadcrumbsSEO;
