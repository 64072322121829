import React from 'react';

import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
import type {PanelSpec} from '../../util/panelTypes';
import {Query} from '../../util/queryTypes';
import {ParameterImportanceConfig} from './common';

const PANEL_TYPE = 'Parameter Importance';

function transformQuery(query: Query, config: any): RunsDataQuery {
  const result = toRunsDataQuery(
    query,
    {selectionsAsFilters: true},
    {fullConfig: true, fullSummary: true}
  );
  return result;
}

export const Spec: PanelSpec<typeof PANEL_TYPE, ParameterImportanceConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./Component')),
  transformQuery,
  noEditMode: true,
  icon: 'panel-importance',
};
