import {difference} from 'lodash';

import {ActiveExperiment} from '../../generated/graphql';
import {safeLocalStorage} from '../../util/localStorage';
import {ExperimentData} from './types';

export const WANDB_EXPERIMENTS_LOCAL_STORAGE_KEY = 'experiments';

export function initializeLocalStorageExperiments() {
  const storedExperiments = getLocalStorageExperiments();

  if (storedExperiments === undefined) {
    setLocalStorageExperiments({});
  }
}

export function updateLocalStorageExperiments(
  activeExperiments: ActiveExperiment[]
) {
  const storedExperiments = getLocalStorageExperiments() ?? {};
  const activeExperimentIds = activeExperiments.map(e => e.id);

  // Remove experiments that are no longer active from local storage
  const oldExperimentIds = difference(
    Object.keys(storedExperiments),
    activeExperimentIds
  );
  if (oldExperimentIds.length) {
    for (const id of oldExperimentIds) {
      delete storedExperiments[id];
    }
    setLocalStorageExperiments(storedExperiments);
  }
}

function getLocalStorageExperiments(): Record<string, string[]> {
  const storedExperiments = safeLocalStorage.getItem(
    WANDB_EXPERIMENTS_LOCAL_STORAGE_KEY
  );
  return storedExperiments ? JSON.parse(storedExperiments) : undefined;
}

export function setLocalStorageExperiments(
  experimentIdMap: Record<string, string[]>
): void {
  safeLocalStorage.setItem(
    WANDB_EXPERIMENTS_LOCAL_STORAGE_KEY,
    JSON.stringify(experimentIdMap)
  );
}

export function checkExperimentLocalStorage(experimentData: ExperimentData): {
  isAlreadyLogged: boolean;
  storedExperiments: Record<string, string[]>;
} {
  const storedExperiments = getLocalStorageExperiments();
  if (storedExperiments === undefined) {
    return {isAlreadyLogged: true, storedExperiments: {}};
  }

  const {graphqlExperimentId, observationalUnitId} = experimentData;
  const newStoredExperiments: Record<string, string[]> = {...storedExperiments};

  /*
    Structure of experiment in local storage:
    {
      experimentId: list of observational units
    }

    We store the list of observational units because one person can belong to
    mulitple organizations and/or teams (when the observational unit is
    organization or team). 
  */
  if (graphqlExperimentId in storedExperiments) {
    // Note: might want to make this check more specific to just
    // team and organization observational units in the future
    if (
      !storedExperiments[graphqlExperimentId].find(
        val => val === observationalUnitId
      )
    ) {
      newStoredExperiments[graphqlExperimentId].push(observationalUnitId);
      return {isAlreadyLogged: false, storedExperiments: newStoredExperiments};
    }
  } else {
    newStoredExperiments[graphqlExperimentId] = [observationalUnitId];
    return {isAlreadyLogged: false, storedExperiments: newStoredExperiments};
  }

  return {isAlreadyLogged: true, storedExperiments};
}
