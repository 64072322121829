import {
  fuzzyMatchRegex,
  fuzzyMatchScore,
  fuzzyMatchWithMapping,
} from '@wandb/weave/common/util/fuzzyMatch';
import classNames from 'classnames';
import React, {useCallback, useMemo, useState} from 'react';
import ReactTable, {Column, TableProps} from 'react-table';

import {DEFAULT_PAGE_SIZE_OPTIONS, useWBReactTablePageSize} from './util';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {WBReactTableHeader} from './WBReactTableHeader';

type PassProps = Partial<
  Pick<
    TableProps,
    | `loading`
    | `getTrProps`
    | `sortable`
    | `defaultSorted`
    | `onSortedChange`
    | `defaultSortMethod`
  >
>;

export type DataRow = {
  searchString?: string;
  row: any;
};

export type WBReactTableProps = {
  /**
   * `data` is a dictionary that has string values of each key for each row.
   *
   * `getSearchResultColumns` is a function that returns a different set of
   * column renderers to use when there is an active search query.
   *
   * `onSearch`, if supplied, fires when the user enters input into the search bar.
   * No local search will be performed if specified.
   */
  className?: string;
  data: DataRow[];
  columns: Column[];
  getSearchResultColumns?: (searchQuery: string) => Column[];
  noPaging?: boolean;
  noSearch?: boolean;
  noHeader?: boolean;
  pageSize?: number;
  onChangePageSize?: (pageSize: number) => void;
  hasNextPage?: boolean;
  onFetchNextPage?: () => void;
  onSearch?: (query: string) => void;
  fetchingNextPage?: boolean;
  pageSizeOptions?: number[];
  extraActions?: React.ReactNode;
  headerInfo?: JSX.Element;
  style?: React.CSSProperties;
  resizable?: boolean;
  dataTest?: string;
  searchPlaceholder?: string;
  renderHiddenRow?: (rowData: DataRow['row'], i: number) => React.ReactNode;
  noDataComponent?: () => React.JSX.Element;
  getSearchOptions?: (row: DataRow['row']) => string[]; // Use when wanting to search multiple columns of the row at once
} & PassProps;

// A wrapper around react-table that adds our paging and search.
const WBReactTable: React.FC<WBReactTableProps> = React.memo(
  ({
    className,
    data,
    columns,
    getSearchResultColumns,
    noPaging = false,
    noSearch = false,
    noHeader = false,
    pageSize: defaultPageSize,
    onChangePageSize,
    hasNextPage = false,
    onFetchNextPage,
    onSearch,
    fetchingNextPage = false,
    pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
    extraActions,
    headerInfo,
    style,
    resizable = false,
    dataTest,
    searchPlaceholder,
    renderHiddenRow,
    noDataComponent,
    getSearchOptions,
    ...passProps
  }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);

    // Rows

    const rows = useMemo(() => {
      if (getSearchOptions && searchQuery) {
        const filteredData = data
          .map(({row}) => ({row, searchOptions: getSearchOptions(row)}))
          .filter(
            ({searchOptions}) =>
              searchOptions.find(
                str => str != null && str.match(fuzzyMatchRegex(searchQuery))
              ) != null
          );

        return filteredData
          .sort(
            (
              {searchOptions: searchOptions1},
              {searchOptions: searchOptions2}
            ) => {
              // Prefer exact match
              if (searchOptions1.includes(searchQuery)) {
                return 1;
              }
              if (searchOptions2.includes(searchQuery)) {
                return -1;
              }

              // Else sort by fuzzy match score
              const fuzzyMatchScore1 = Math.max(
                ...searchOptions1.map(str => fuzzyMatchScore(str, searchQuery))
              );
              const fuzzyMatchScore2 = Math.max(
                ...searchOptions2.map(str => fuzzyMatchScore(str, searchQuery))
              );
              return fuzzyMatchScore1 - fuzzyMatchScore2;
            }
          )
          .map(({row}) => row);
      }
      const selectedData = searchQuery
        ? fuzzyMatchWithMapping(data, searchQuery, d => d.searchString ?? '')
        : data;
      return selectedData.map(x => x.row);
    }, [data, searchQuery, getSearchOptions]);

    const rowCount = rows.length;

    // Columns

    const modifiedColumns = useMemo(() => {
      if (!searchQuery || getSearchResultColumns == null) {
        return columns;
      }
      return getSearchResultColumns(searchQuery);
    }, [columns, searchQuery, getSearchResultColumns]);

    // Page size

    const {pageSize, setPageSize} = useWBReactTablePageSize({
      rowCount,
      defaultPageSize,
      noPaging,
    });

    const changePageSize = useCallback(
      (newPageSize: number) => {
        setPageSize(newPageSize);
        onChangePageSize?.(newPageSize);
      },
      [setPageSize, onChangePageSize]
    );

    // Hidden rows (we may want to render them for SEO purposes)

    const renderHiddenRows = useCallback(
      (shownRowsStart: number, shownRowsEnd: number) => {
        if (renderHiddenRow == null) {
          return null;
        }
        const hiddenRows = [
          ...rows.slice(0, shownRowsStart),
          ...rows.slice(shownRowsEnd),
        ];
        return hiddenRows.map(renderHiddenRow);
      },
      [rows, renderHiddenRow]
    );

    return (
      <div
        className={classNames('wb-react-table', className)}
        data-test={dataTest}
        style={style ?? {}}>
        <ReactTable
          {...passProps}
          data={rows}
          columns={modifiedColumns}
          page={page}
          pageSize={pageSize}
          resizable={resizable}
          pageSizeOptions={pageSizeOptions}
          showPaginationTop={false}
          showPaginationBottom={false}
          NoDataComponent={noDataComponent}
          showPageJump={false}>
          {/* ReactTable takes a render function as `children` so that
          it can make `reactTableState` and `makeTable` available
          when constructing the contents. */}
          {(reactTableState, makeTable) => {
            return (
              <>
                {!noHeader && (
                  <WBReactTableHeader
                    {...{
                      page,
                      pageSize,
                      pageSizeOptions,
                      rowCount,
                      noSearch,
                      noPaging,
                      extraActions,
                      headerInfo,
                      searchPlaceholder,
                      changePageSize,
                      hasNextPage,
                      fetchingNextPage,
                      onFetchNextPage,
                      setPage,
                      onSearch: onSearch ?? setSearchQuery,
                      setSearchQuery,
                    }}
                  />
                )}
                {makeTable()}
                {renderHiddenRows(
                  reactTableState.startRow,
                  reactTableState.endRow
                )}
              </>
            );
          }}
        </ReactTable>
      </div>
    );
  }
);

export default WBReactTable;
