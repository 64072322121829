import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Dropdown, Image} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

const MOBILE_WIDTH_PX = 880;

const headerStyles = css`
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  &:after {
    color: inherit;
    margin-top: 0;
  }
`;

export const Header = styled.div`
  ${headerStyles}
`;
Header.displayName = 'S.Header';

export const HeaderWithBottomMargin = styled.div`
  ${headerStyles}
  margin-bottom: 5px;
`;
HeaderWithBottomMargin.displayName = 'S.HeaderWithBottomMargin';

export const FormRow = styled.div`
  position: relative;
  margin: 24px 0;

  @media (max-width: ${MOBILE_WIDTH_PX}px) {
    margin: 16px 0;
  }
`;
FormRow.displayName = 'S.FormRow';

export const FormHint = styled.div<{error?: boolean}>`
  margin-top: 2px;
  color: ${p => (p.error ? globals.errorText : globals.gray600)};
  font-size: 12px;
  line-height: 15px;
`;
FormHint.displayName = 'S.FormHint';

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
`;
HeaderDiv.displayName = 'S.HeaderDiv';

export const OptionalHeader = styled.div`
  color: ${globals.MOON_500};
  font-family: 'Source Sans Pro';
  margin-left: 6px;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
`;
OptionalHeader.displayName = 'S.OptionalHeader';

export const PhoneInputWrapper = styled.div`
  box-shadow: 0 0 0 1px ${globals.MOON_250};
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  &:hover {
    box-shadow: 0 0 0 2px ${globals.hexToRGB(globals.TEAL_500, 0.4)};
  }
  .PhoneInputInput {
    border: transparent;
    :focus {
      outline: none;
    }
  }
`;
PhoneInputWrapper.displayName = 'S.PhoneInputWrapper';

export const SingleDialogButton = styled(Button)`
  display: block;
  width: 100%;
`;
SingleDialogButton.displayName = 'S.SingleDialogButton';

export const DialogButtons = styled.div`
  display: flex;
  margin-top: 32px;
`;
DialogButtons.displayName = 'S.DialogButtons';

export const NavDropdownSection = styled.div`
  display: flex;
  align-items: center;
`;
NavDropdownSection.displayName = 'S.NavDropdownSection';

export const DialogButton = styled(Button)`
  &&&&& {
    flex-grow: 1;
    margin-right: 8px;
  }
`;
DialogButton.displayName = 'S.DialogButton';

export const DialogSkipButton = styled(Button)`
  &&&&& {
    width: 107px;
    border: none;
    color: ${globals.primary};
    background-color: ${globals.white};
  }
`;
DialogSkipButton.displayName = 'S.DialogSkipButton';

export const NavDropdown = styled(Dropdown)`
  &&&&& {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;

    i.dropdown.icon {
      display: none;
    }
  }
`;
NavDropdown.displayName = 'S.NavDropdown';

export const NavDropdownMenu = styled(Dropdown.Menu)`
  &&&&& {
    background-color: ${globals.WHITE};
  }
`;
NavDropdownMenu.displayName = 'S.NavDropdownMenu';

export const NavDropdownItem = styled(Dropdown.Item)`
  &&&&& {
    color: ${globals.MOON_800};
  }
`;
NavDropdownItem.displayName = 'S.NavDropdownItem';

export const NavDropdownDivider = styled(Dropdown.Divider)`
  &&&&& {
    border-color: ${globals.MOON_300};
    opacity: 0.5;
    margin: 0;
  }
`;
NavDropdownDivider.displayName = 'S.NavDropdownDivider';

export const Avatar = styled(Image)`
  &&&&& {
    margin: 0;
  }
`;
Avatar.displayName = 'S.Avatar';
