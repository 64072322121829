/**
 * Return the items in a list that are neighbors of the given index. If there aren't equal numbers of neighbors on both sides return a minimum group (a minimum group for 4 neighbors per side is 9 as it's the item at the index plus 4 on each side. If the index is the first item the "minimum group" would be the first nine items)
 *
 * @template T The type of the list elements.
 * @param {Object} options The options object
 * @param {number} options.index The index of the element to find neighbors for.
 * @param {T[]} options.list The list of elements in descending order
 * @param {number} options.neighborsPerSide The number of neighbors to find on each side.
 */
export function getNeighborsIndexRange<T>({
  index,
  list,
  neighborsPerSide,
}: {
  index: number;
  list: T[];
  neighborsPerSide: number;
}): [maxIndex: number, minIndex: number] {
  if (neighborsPerSide === 0) {
    return [index, index];
  }
  const possibleNeighbors = Math.floor((list.length - 1) / 2);

  if (neighborsPerSide >= possibleNeighbors || neighborsPerSide === Infinity) {
    return [0, list.length - 1]; // return the full index
  }

  if (
    index - neighborsPerSide >= 0 &&
    index + neighborsPerSide <= list.length - 1
  ) {
    return [index - neighborsPerSide, index + neighborsPerSide];
  }

  if (index - neighborsPerSide < 0) {
    return [0, neighborsPerSide * 2];
  }
  return [list.length - neighborsPerSide * 2 - 1, list.length - 1];
}

export function decimateArray<T>(array: T[], decimateToLength: number = 5) {
  if (array.length <= decimateToLength) {
    return array;
  }
  const gap = Math.floor(array.length / decimateToLength);
  return Array(decimateToLength)
    .fill({})
    .map((_, i) => array[Math.floor(i * gap)])
    .filter(i => !!i);
}
