import {matchPath} from 'react-router';

import {
  GALLERY_CREATE_POST,
  GALLERY_PATH,
  GALLERY_PATH_SEGMENT,
  GALLERY_POST_EDIT_PATH,
  GALLERY_POST_VIEW_PATH,
  OLD_GALLERY_DISCUSSION_EDIT_PATH,
  OLD_GALLERY_DISCUSSION_VIEW_PATH,
  PUBLISHED_WORK_PATH_SEGMENT,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
} from '../../routes/paths';
import {PUBLISHED_PROJECT_NAME} from '../constants';
import {makeNameAndID, parseReportURL} from '../report/urls';
import {removeHashFromURL} from './utils';

export type ReportParams = {
  entityName: string;
  projectName: string;
  reportID: string;
  reportName?: string;
};

export type GalleryPostParams = {
  entityName: string;
  reportID: string;
  reportName?: string;
  isDiscussion?: boolean;
};

export function reportEdit(r: ReportParams, queryString?: string) {
  const extra = queryString || '';
  return `${reportViewWithoutPublished(r)}/edit${extra}`;
}

export function reportView(r: ReportParams | GalleryPostParams) {
  if (isReportParams(r)) {
    return toPublishedReportURL(reportViewWithoutPublished(r));
  }
  if (r.isDiscussion) {
    return galleryDiscussionView(r);
  }
  return galleryPostView(r);
}

function isReportParams(
  r: ReportParams | GalleryPostParams
): r is ReportParams {
  return 'projectName' in r && !!r.projectName;
}

export function reportViewWithoutPublished(r: ReportParams) {
  return `/${r.entityName}/${r.projectName}/reports/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function toPublishedReportURL(url: string): string {
  const searchStr = `${PUBLISHED_PROJECT_NAME}/reports`;
  const i = url.indexOf(searchStr);

  if (i === -1) {
    return url;
  }

  return (
    url.slice(0, i) +
    PUBLISHED_WORK_PATH_SEGMENT +
    url.slice(i + searchStr.length)
  );
}

type PublishedReportParams = Omit<ReportParams, 'projectName'>;

export function publishedReportView(r: PublishedReportParams): string {
  return `/${r.entityName}/published-work/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function galleryPostEdit(
  r: GalleryPostParams,
  queryString?: string
): string {
  const extra = queryString || '';
  return `${galleryPostView(r)}/edit${extra}`;
}

export function galleryPostView(r: GalleryPostParams): string {
  return `/${r.entityName}/posts/${makeNameAndID(r.reportID, r.reportName)}`;
}

export function galleryDiscussionEdit(
  r: GalleryPostParams,
  queryString?: string
): string {
  const extra = queryString || '';
  return `${galleryDiscussionView(r)}/edit${extra}`;
}

export function galleryDiscussionView(r: GalleryPostParams): string {
  return `/${r.entityName}/discussions/${makeNameAndID(
    r.reportID,
    r.reportName
  )}`;
}

export function fullyConnected(): string {
  return `/${GALLERY_PATH_SEGMENT}`;
}

export function createFCPost(): string {
  return GALLERY_CREATE_POST;
}

export function isOnReportView(pathname = window.location.pathname): boolean {
  const match = matchPath(pathname, {
    path: REPORT_PAGE_VIEW_PATH,
    exact: true,
  });
  if (match != null) {
    return true;
  }
  if (isOnPublishedReportView(pathname)) {
    return true;
  }
  return false;
}

export function isOnPublishedReportView(
  pathname = window.location.pathname
): boolean {
  const publishedMatch = matchPath(pathname, {
    path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
    exact: true,
  });
  return publishedMatch != null;
}

export function isOnGalleryPostPage(
  pathname = window.location.pathname
): boolean {
  return isOnGalleryPostView(pathname) || isOnGalleryPostEdit(pathname);
}

export function isOnGalleryPostView(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: GALLERY_POST_VIEW_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnGalleryPostEdit(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: GALLERY_POST_EDIT_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnGalleryDiscussionPage(
  pathname = window.location.pathname
): boolean {
  return (
    isOnGalleryDiscussionView(pathname) || isOnGalleryDiscussionEdit(pathname)
  );
}

export function isOnGalleryDiscussionView(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: OLD_GALLERY_DISCUSSION_VIEW_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnGalleryDiscussionEdit(
  pathname = window.location.pathname
): boolean {
  const match = matchPath(pathname, {
    path: OLD_GALLERY_DISCUSSION_EDIT_PATH,
    exact: true,
  });
  return match != null;
}

export function isOnFCPage(pathname = window.location.pathname): boolean {
  const match = matchPath(pathname, {path: GALLERY_PATH});
  return match != null;
}

export function checkURLPointToCurrentReport(url: string): boolean {
  const currentReportURL = removeHashFromURL(window.location.href);
  const {reportId: currentId} = parseReportURL(currentReportURL);
  if (currentId == null) {
    return false;
  }

  const targetURL = removeHashFromURL(url);
  const {reportId: targetId} = parseReportURL(targetURL);
  if (targetId == null) {
    return false;
  }

  return currentId === targetId;
}

export function getURLAndSection(url: string): {
  reportURL: string;
  section?: string;
} {
  const splitURLWithHash = url.split('#');
  if (splitURLWithHash.length === 1) {
    return {reportURL: splitURLWithHash[0]};
  }
  return {reportURL: splitURLWithHash[0], section: '#' + splitURLWithHash[1]};
}
