import _ from 'lodash';
import {useCallback, useEffect, useState} from 'react';

import {safeLocalStorage} from '../../util/localStorage';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {LocalSearchResult} from '.';
import {isWithinLastWeek} from './utils';

type ResultWithTime = {
  timeMS: number;
  result: LocalSearchResult;
};

type RecentlyViewedResults = {
  recentlyViewed: ResultWithTime[];
  registerRecentlyViewed: (r: LocalSearchResult) => void;
};

export function useRecentlyViewed(): RecentlyViewedResults {
  const [recentlyViewed, setRecentlyViewed] = useState<ResultWithTime[]>(
    loadRecentlyViewed()
  );

  const registerRecentlyViewed = useCallback((r: LocalSearchResult) => {
    setRecentlyViewed(prev =>
      _.uniqBy([{timeMS: Date.now(), result: r}, ...prev], x => x.result.url)
    );
  }, []);

  useEffect(() => {
    saveRecentlyViewed(recentlyViewed);
  }, [recentlyViewed]);

  return {
    recentlyViewed,
    registerRecentlyViewed,
  };
}

const RECENTLY_VIEWED_KEY = `__WB_LOCAL_SEARCH_recentlyViewed`;

function saveRecentlyViewed(recentlyViewed: ResultWithTime[]): void {
  try {
    safeLocalStorage.setItem(
      RECENTLY_VIEWED_KEY,
      JSON.stringify(recentlyViewed)
    );
  } catch {
    // do nothing
  }
}

function loadRecentlyViewed(): ResultWithTime[] {
  try {
    const recentlyViewedJSON = safeLocalStorage.getItem(RECENTLY_VIEWED_KEY);
    return recentlyViewedJSON
      ? JSON.parse(recentlyViewedJSON).filter(isWithinLastWeek)
      : [];
  } catch {
    return [];
  }
}
