import * as Sentry from '@sentry/react';
import gql from 'graphql-tag';
import {useMemo} from 'react';

import * as Generated from '../../generated/graphql';
import {toMongo} from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as Run from '../../util/runs';
import * as RunTypes from '../../util/runTypes';

export const FILTER_VALUE_SUGGESTIONS = gql`
  query RunValueSuggestions(
    $projectName: String!
    $entityName: String
    $keyPath: String!
    $filters: JSONString
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      name
      runKeySuggestions(keyPath: $keyPath, filters: $filters) {
        value
        displayValue
        count
      }
    }
  }
`;

interface InputProps {
  entityName: string;
  projectName: string;
  keyPath: string;
  filters: FilterTypes.Filter<RunTypes.Key>;
}

export function useRunValueSuggestionsQuery(inputProps: InputProps) {
  const variables = {
    ...inputProps,
    filters: JSON.stringify(toMongo(inputProps.filters)),
  };

  const result = Generated.useRunValueSuggestionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: inputProps.keyPath === '',
    variables,
  });

  const runKeySuggestions = !result.loading
    ? result.data?.project?.runKeySuggestions
    : null;

  const valueSuggestions = useMemo(() => {
    if (runKeySuggestions == null) {
      return [];
    }

    return runKeySuggestions.map(suggestion => {
      let value;

      switch (suggestion.value) {
        // Number-like strings that cannot be parsed by JSON.parse:
        case 'Infinity':
        case '-Infinity':
        case 'NaN':
          value = Number(suggestion.value);
          break;
        default:
          try {
            value = JSON.parse(suggestion.value);
          } catch (err) {
            Sentry.captureException(err);
          }
      }

      return {
        text: Run.displayValue(suggestion.displayValue, 'null'),
        key: Run.domValue(suggestion.value),
        value,
        count: suggestion.count,
      };
    });
  }, [runKeySuggestions]);

  if (result.loading) {
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    return {loading: true as true};
  }
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  return {loading: false as false, valueSuggestions};
}
