import {rgb} from 'd3-color';
import * as d3Shape from 'd3-shape';
import {AreaSeries, ScaleUtils} from 'react-vis';

/**
 * This is an extension of the AreaSeries component from React-Vis with the `renderLayer` method monkeypatched so that it uses canvas instead of SVG.
 *
 * It is not assumed to be feature equivalent with React-Vis, and functionality like animation or curves is not supported.
 *
 * https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1730487324099259
 */
export class AreaSeriesCanvas extends AreaSeries {
  static displayName = 'AreaSeriesCanvas';

  static get requiresSVG() {
    return false;
  }

  static get isCanvas() {
    return true;
  }

  static renderLayer(props: any, ctx: CanvasRenderingContext2D) {
    const {data, marginLeft, marginTop, strokeWidth} = props;

    if (!data || data.length === 0) {
      return;
    }

    const x = ScaleUtils.getAttributeFunctor(props, 'x');
    const y = ScaleUtils.getAttributeFunctor(props, 'y');
    const y0 = ScaleUtils.getAttr0Functor(props, 'y');

    const fill =
      ScaleUtils.getAttributeValue(props, 'fill') ||
      ScaleUtils.getAttributeValue(props, 'color');

    const fillColor = rgb(fill);

    const area = d3Shape
      .area()
      .x(d => x(d) + marginLeft)
      .y0(d => y(d) + marginTop)
      .y1(d => y0(d) + marginTop);

    ctx.beginPath();
    ctx.lineWidth = strokeWidth;
    area.context(ctx)(data);
    ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${fillColor.opacity})`;
    ctx.fill();
    ctx.closePath();
  }

  render() {
    return null;
  }
}
