import {BoundingBox2D, BoundingBox3D} from '@wandb/weave/common/types/media';
import {createAction} from 'typesafe-actions';

export const loadBoxData = createAction('@media/loadBoxData', action => {
  return (opts: {
    panelID: string;
    mediaKey: string;
    mediaID: string;
    boxData: BoundingBox2D[] | BoundingBox3D[];
  }) => action(opts);
});

export const clearBoxData = createAction('@media/clearBoxData', action => {
  return (opts: {panelID: string; mediaKey: string; mediaID: string}) =>
    action(opts);
});
