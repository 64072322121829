import _ from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';

import {FancyLegendProps} from '../../util/plotHelpers/legendTypes';

const FancyLegend: React.FC<FancyLegendProps> = props => {
  const grouping = props.groupKeys && props.groupKeys.length > 0;

  let legendElement: JSX.Element;

  if (props.legend instanceof Array) {
    const fancySpanChildrenWithSpaces = _.zip(
      props.legend,
      _.range(props.legend.length - 1).map(() => ' ')
    ).map(o => o.filter(oo => !!oo)) as Array<
      Array<React.ReactElement | string>
    >;
    legendElement = React.createElement(
      'span',
      {},
      fancySpanChildrenWithSpaces
    );
  } else {
    legendElement = <span>{props.legend}</span>;
  }

  let legendElementWithRunningIcon: JSX.Element;
  if (props.run && props.run.state === 'running') {
    legendElementWithRunningIcon = (
      <span>
        {legendElement}{' '}
        <div
          style={{
            display: 'inline-block',
            backgroundColor: 'green',
            width: 6,
            height: 6,
            borderRadius: 3,
          }}
        />
      </span>
    );
  } else {
    legendElementWithRunningIcon = legendElement;
  }

  if (grouping && props.rootUrl != null && props.run.name != null) {
    return <>{legendElementWithRunningIcon}</>;
  } else if (props.disableRunLink) {
    return (
      <span key={props.run.id + props.metricName?.toString()}>
        {legendElementWithRunningIcon}
      </span>
    );
  } else {
    return (
      <Link
        className="legend-link"
        to={props.rootUrl + '/' + props.run.name}
        key={props.run.id + props.metricName?.toString()}>
        {legendElementWithRunningIcon}
      </Link>
    );
  }
};

export default FancyLegend;
