import {makeTrackEvent} from '../makeTrackEvent';
import {
  ReportCloneLocation,
  ReportDownloadMode,
  ReportDownloadOption,
  ReportDraftModalAction,
  ReportEventNames,
  ReportHeartAction,
  ReportMode,
  ReportNotificationsAction,
  ReportType,
} from './types';

type ReportCloneClickedData = {
  reportType: ReportType;
  location: ReportCloneLocation;
  isPublicProject?: boolean;
};
export const reportCloneClicked = makeTrackEvent<
  ReportCloneClickedData,
  {
    reportType: {
      description: 'The state of the report that is being cloned.';
      exampleValues: ['published report', 'report draft'];
    };
    location: {
      description: 'The value indicates the page location of where the clone action was clicked.';
      exampleValues: ['report table', 'report page'];
    };
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project. At the moment, this value is only available when user is on the report page.';
      exampleValues: [true, false];
    };
  } & {
    _description: 'A user clicks on the button that will trigger a report to be cloned.';
    _location: 'There is an menu item in the draft report table, published report table, draft report page, and published report page if a user is logged in.';
    _motivation: 'To track how many people are cloning a report.';
  }
>(ReportEventNames.ReportCloneClicked);

type ReportDownloadClickedData = {
  reportMode: ReportMode;
  downloadOption: ReportDownloadOption;
  downloadMode: ReportDownloadMode;
  isPublicProject: boolean;
};
export const reportDownloadClicked = makeTrackEvent<
  ReportDownloadClickedData,
  {
    reportMode: {
      description: 'The value indicates if this action occurred while user was editing or viewing a report.';
      exampleValues: ['view', 'edit'];
    };
    downloadOption: {
      description: 'The value indicates the download option the user selected.';
      exampleValues: ['pdf', 'latex'];
    };
    downloadMode: {
      description: 'The value indicates the pdf orientation or the latex template the user selected.';
      exampleValues: [
        'portrait',
        'landscape',
        'default template',
        'reproducibility challenge template'
      ];
    };
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
  } & {
    _description: 'A user clicks on the action that will trigger a report to be downloaded.';
    _location: 'Download option action appears when viewing or editing a report page.';
    _motivation: 'To track how many people are downloading a report and what type they are downloading.';
  }
>(ReportEventNames.ReportDownloadClicked);

type ReportDiscardDraftClickedData = {
  reportMode: ReportMode;
  location: ReportCloneLocation;
};
export const reportDiscardDraftClicked = makeTrackEvent<
  ReportDiscardDraftClickedData,
  {
    reportMode: {
      description: 'The value indicates if this action occurred while user was editing or viewing a report.';
      exampleValues: ['view', 'edit'];
    };
    location: {
      description: 'The location in the app where the discard draft modal was rendered.';
      exampleValues: ['report table', 'report page'];
    };
  } & {
    _description: 'This event is fired when a user clicks on the button that starts the discard report draft process.';
    _location: 'This is in the discard report draft modal component.';
    _motivation: 'To track how often a user chooses to discard a report draft.';
  }
>(ReportEventNames.ReportDiscardDraftClicked);

type ReporDraftWarningActionData = {
  actionClicked: ReportDraftModalAction;
};
export const reportDraftWarningAction = makeTrackEvent<
  ReporDraftWarningActionData,
  {
    actionClicked: {
      description: 'The value indicates the action that the user clicked on in the report draft warning modal.';
      exampleValues: ['continue', 'discard'];
    };
  } & {
    _description: 'This event is fired when a user clicks on one of the buttons in the report draft warning modal.';
    _location: 'This is in the draft report warning modal component.';
    _motivation: 'To track which action users take when they see the report draft warning modal.';
  }
>(ReportEventNames.ReportDraftWarningModalAction);

type ReportSetPreviewImageSavedData = {
  isPublicProject: boolean;
};
export const reportSetPreviewImageSaved = makeTrackEvent<
  ReportSetPreviewImageSavedData,
  {
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
  } & {
    _description: 'This event is fired when a user changes the set preview image for a report in edit mode.';
    _location: 'This is an action that appears for some users on report edit mode.';
    _motivation: "To track how often users set a report's preview image.";
  }
>(ReportEventNames.ReportSetPreviewImageSaved);

type ReportViewLikesClickedData = {
  isPublicProject: boolean;
};
export const reportViewLikesClicked = makeTrackEvent<
  ReportViewLikesClickedData,
  {
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
  } & {
    _description: "This event is fired when a user clickes on the menu item to view the report's likes.";
    _location: 'This is an action that appears for some users on report view mode.';
    _motivation: 'To track how often users view who and how many people liked a report.';
  }
>(ReportEventNames.ReportViewLikesClicked);

type ReportHeartClickedData = {
  isPublicProject: boolean;
  reportId: string;
  actionTriggered: ReportHeartAction;
};
export const reportHeartClicked = makeTrackEvent<
  ReportHeartClickedData,
  {
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
    reportId: {
      description: 'The unique identifier for a published report.';
      exampleValues: ['some-test-report-id'];
    };
    actionTriggered: {
      description: 'The action that occurred when user clicked on the heart in the report.';
      exampleValues: ['heart', 'unheart'];
    };
  } & {
    _description: "This event is fired when a user clickes on the heart in the report's view header.";
    _location: 'This is an action is located in the upper right corner of the report view header.';
    _motivation: 'To track how often users click on the heart icon in a report.';
  }
>(ReportEventNames.ReportHeartClicked);

type ReportNotificationsToggledData = {
  isPublicProject: boolean;
  reportId: string;
  actionTriggered: ReportNotificationsAction;
};
export const reportNotificationsToggled = makeTrackEvent<
  ReportNotificationsToggledData,
  {
    isPublicProject: {
      description: 'The value indicates if this report was created in a public project or private project.';
      exampleValues: [true, false];
    };
    reportId: {
      description: 'The unique identifier for a published report.';
      exampleValues: ['some-test-report-id'];
    };
    actionTriggered: {
      description: 'The action that occurred when user clicked on the notifications toggle button.';
      exampleValues: ['unsubscribe', 'subscribe'];
    };
  } & {
    _description: 'This event is fired when a user clicks on the notification toggle button in the report overflow menu.';
    _location: 'This is an action that appears for some users in the report overflow menu.';
    _motivation: 'To track how often users toggle notifications for reports.';
  }
>(ReportEventNames.ReportNotificationsToggled);
