// Public actions, to be called by React components.
import {createAction} from 'typesafe-actions';

import * as Types from './types';

export const setCurrentViewer = createAction(
  '@viewer/set',
  action => (loading: boolean, viewer?: Types.Viewer) =>
    action({viewer, loading})
);

export const reloadCurrentViewer = createAction(
  '@viewer/reload',
  action => (claims?: Types.JWTClaims) => action({reload: true, claims})
);
