import * as _ from 'lodash';

import {RunsQueryContext} from '../../../state/runs/context';
import {RunHistoryKeyInfo, RunHistoryKeyType} from '../../../types/run';
import {XAxisValues} from '../../WorkspaceDrawer/Settings/types';
import {runsLinePlotConfigDefaults} from '../defaults';
import {getHasSystemMetrics} from './../common';
import {isHistogramPlot} from './checkTypes';

type XAxisChoicesArgs = {
  configMetrics: string[];
  configXAxis: XAxisValues | string;
  keyInfo: RunHistoryKeyInfo;
  keyTypes: {
    [key: string]: RunHistoryKeyType;
  };
};

export function getXAxisChoices({
  configMetrics,
  configXAxis,
  keyInfo,
  keyTypes,
}: XAxisChoicesArgs): string[] {
  // Limit xAxis choices to number types
  // Put the monotonic xAxis choices first
  // Don't show non-monotic choices if we're looking at a histogram
  const monotonicChoices: string[] = [];
  const nonMonotonicChoices: string[] = [];
  keyInfo.sortedKeys.forEach(k => {
    if (keyTypes[k] !== 'number') {
      return;
    }
    if (keyInfo.keys[k].monotonic) {
      monotonicChoices.push(k);
    } else {
      nonMonotonicChoices.push(k);
    }
  });

  /**
   * The ordering matters here, as we want the hardcoded options here first in the dropdown
   */
  const result = _.uniq([
    // _step is not available as an option for system metrics charts
    ...(getHasSystemMetrics({metrics: configMetrics}) ? [] : ['_step']),
    '_absolute_runtime',
    '_runtime',
    '_timestamp',
    ...monotonicChoices,
    ...(isHistogramPlot({metrics: configMetrics}, keyInfo)
      ? []
      : nonMonotonicChoices),
    configXAxis,
  ]);

  return result;
}

const yAxisNumberKeyBlacklist = new Set(['_absolute_runtime', '_timestamp']);

export function isBlacklistedYAxisNumberKey(k: string): boolean {
  return yAxisNumberKeyBlacklist.has(k);
}

export function getYAxisChoices({
  configMetrics,
  configXAxis,
  keyInfo,
  keyTypes,
  runsContext,
}: XAxisChoicesArgs & {
  runsContext: Partial<RunsQueryContext>;
}): string[] {
  const startingYAxisChoices = keyInfo.sortedKeys;

  const yAxisChoices = startingYAxisChoices.filter(k => {
    if (keyTypes[k] === 'number') {
      return !isBlacklistedYAxisNumberKey(k);
    }

    if (keyTypes[k] === 'histogram') {
      // We only allow selecting histograms on single-run pages and reports
      return runsContext.runId != null || runsContext.report != null;
    }

    return false;
  });
  // We may have values for the yAxis that are not in yAxisChoices
  // This happens if the user configures the plot, and then changes the query
  // For example on a runs page, visualizes a single run that has one set of metrics,
  // adds a plot, then switch the eyeball to a run with different metrics.
  yAxisChoices.push(...(configMetrics ?? runsLinePlotConfigDefaults.metrics));

  const uniq = _.uniq(yAxisChoices);

  return uniq;
}
