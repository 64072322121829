import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {ButtonProps} from 'semantic-ui-react';

import * as S from './WBModal.styles';

interface WBModalProps {
  className?: string;
  theme?: 'danger';
  trigger?: React.ReactNode;
  open?: boolean;
  header?: string;
  headerIcon?: string;
  primaryAction?: ButtonProps;
  // Presence of includeCancel prop indicates we want a visible cancel button.
  includeCancel?: boolean;
  cancelButtonText?: string;
  onClose?(): void;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

const WBModal: React.FC<WBModalProps> = React.memo(
  ({
    className,
    theme,
    trigger,
    open,
    header,
    headerIcon,
    primaryAction,
    includeCancel,
    cancelButtonText = 'Cancel',
    onClose,
    onClick,
    children,
  }) => {
    return (
      <S.WBModal
        className={className}
        theme={theme}
        trigger={trigger}
        open={open}
        onClose={onClose}
        onClick={onClick}>
        {header && (
          <S.WBModalHeader>
            {headerIcon && <S.WBModalIcon name={headerIcon} size="large" />}
            {header}
          </S.WBModalHeader>
        )}
        <S.WBModalContent>{children}</S.WBModalContent>
        <S.WBModalActions>
          {/* Cancel is usually the first item in a row of actions */}
          {includeCancel && (
            <S.WBModalButton content={cancelButtonText} onClick={onClose} />
          )}
          {primaryAction && (
            <S.WBModalButton autoFocus primary {...primaryAction} />
          )}
        </S.WBModalActions>
      </S.WBModal>
    );
  }
);

export default WBModal;
