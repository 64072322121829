import {Unpack} from '@wandb/weave/common/types/base';
import {Table} from '@wandb/weave/common/util/csv';
import React from 'react';

import type {PanelConfigSpec} from '../components/property-editors/property-editors';
import {Settings} from '../components/WorkspaceDrawer/Settings/types';
import {RunsDataQuery} from '../containers/RunsDataLoader';
import {Ref as PanelRef} from '../state/views/panel/types';
// eslint-disable-next-line import/no-cycle
import {getPanelSpecs} from './getPanelSpec';
import type {PanelProps} from './panelProps';
import * as QueryTypes from './queryTypes';

export type PanelExportRef = {
  onDownloadSVG: (name: string) => Promise<void>;
  onDownloadPNG: (name: string) => Promise<void>;
  onDownloadPDF: (name: string) => Promise<void>;
};

export type PanelComponentType<ConfigType> = React.ComponentType<
  PanelProps<ConfigType>
>;

// Helpers that extract the ConfigType used in a Spec
type GetSpecType<S, T> = S extends {type: T} ? S : never;
// TODO: combine?
type ConfigTypeFromPanelSpec<S> = S extends PanelSpec<any, infer C, any>
  ? C
  : never;

// This helper distributes the `PanelType` union type by using conditional types.
// Instead of resulting in PanelWithConfig<A | B | ...>,
// it results in PanelWithConfig<A> | PanelWithConfig<B> | ...
// The latter is preferable because we can further type narrow the result into a specific PanelWithConfig<T>.
// See https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
// and https://www.typescriptlang.org/docs/handbook/advanced-types.html#distributive-conditional-types
type Dist<T extends PanelType> = T extends {} ? PanelWithConfig<T> : never;

// Valid JSON representation for all available Panels
export type Panel = Dist<PanelType> & {
  key?: string;
};

export type PanelConfig = Panel['config'];

// Generic to derive JSON representation of a given panel from Spec
export interface PanelWithConfig<T extends PanelType> {
  __id__: string; // This is a permanent unique ID (used to associate panels with comments). Note: don't confuse this with the ref ID, which is ephemeral.
  viewType: T;
  config: ConfigTypeFromPanelSpec<GetSpecType<AllPanelSpec, T>>;
  query?: any; // legacy, used by OpenAI dashboard
  /**
   * Whether the panel was created during automatic panel generation.
   * If an auto-generate panel is edited or moved to another section,
   * it'll no longer be considered "auto".
   */
  isAuto?: boolean;
}

// Helper to create a typed panel with a given layout.
export function layedOutPanel<T extends PanelType>(
  p: PanelWithConfig<T> & PanelLayout
): PanelWithConfig<T> & PanelLayout {
  return p;
}

export type AllPanelSpec = Unpack<ReturnType<typeof getPanelSpecs>>;
// Allowed panel types
export type PanelType = AllPanelSpec['type'];

export type PanelExportType = 'image' | 'csv' | 'api';

export type TransformConfigOptions = {
  singleRun?: boolean;
};

export interface PanelSpec<
  PanelTypeT,
  ConfigType,
  QueryType = QueryTypes.Query
> {
  type: PanelTypeT;
  Component: PanelComponentType<ConfigType>;

  // If true this panel doesn't have an editor modal
  noEditMode?: boolean;

  // can be exported
  exportable?: {[t in PanelExportType]?: boolean};

  configSpec?: PanelConfigSpec;

  // use SingleChartInspectorContainer instead of normal config editor, for transitioning to Inspector everything
  useInspector?: boolean;

  // icon name for <WBIcon>
  icon?: string;

  transformConfig?: (
    config: ConfigType,
    opts?: TransformConfigOptions
  ) => ConfigType;
  transformConfigUpdate?: (
    config: ConfigType,
    opts?: TransformConfigOptions
  ) => ConfigType;

  getTitleFromConfig?: (config: ConfigType) => string;
  transformQuery: (query: QueryType, config: ConfigType) => RunsDataQuery;
  useTableData?: (
    query: QueryType,
    config: ConfigType
  ) => {table: Table; loading: boolean};
}

export interface LayoutCoords {
  x: number;
  y: number;
}

export interface LayoutDimensions {
  w: number;
  h: number;
}

export type LayoutParameters = LayoutCoords & LayoutDimensions;

export interface PanelLayout {
  layout: LayoutParameters;
}

export type LayedOutPanel = Panel & PanelLayout;

export type LayedOutPanelWithRef = Panel & PanelLayout & {ref: PanelRef};

export type PanelGroupConfig = LayedOutPanel[];

export type PanelGroupId = string;

export interface PanelGroup {
  name: string;
  defaults: any[];
  // TBoard stores this in PanelGroup (view), but Reports store them outside the view.
  globalConfig?: Settings;
  config: PanelGroupConfig;
}

export interface Config {
  views: {[id: string]: PanelGroup};
  tabs: PanelGroupId[];
}

type TransformQueryFn = (
  query: QueryTypes.Query,
  config: PanelConfig
) => RunsDataQuery;

type UseTableDataFn = (
  query: QueryTypes.Query,
  config: PanelConfig
) => {table: Table; loading: boolean};

export function transformQuery(
  query: QueryTypes.Query,
  panel: Panel
): RunsDataQuery {
  const s = getPanelSpec(panel.viewType);
  // Here we know that s.transformQuery can be called with panel.config, because
  // spec.type === panel.viewType. However, there's no way to get typescript
  // to narrow a union to an individual member, so we cast.
  const transformFn = s.transformQuery as TransformQueryFn;
  const result = transformFn(query, panel.config);

  return result;
}

export function useTableData(
  query: QueryTypes.Query,
  panel: Panel
): {table: Table; loading: boolean} {
  const s = getPanelSpec(panel.viewType);
  const useTableDataFn = s.useTableData as UseTableDataFn;
  return useTableDataFn(query, panel.config);
}

export function getPanelSpec(panelType: PanelType) {
  for (const s of getPanelSpecs()) {
    if (s.type === panelType) {
      return s;
    }
  }
  throw new Error('invalid panel type');
}
