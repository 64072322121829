import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const OptionalLabel = styled.label`
  color: ${globals.MOON_500};
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
`;

export const DrawerContents = styled.div<{scrolled?: boolean}>`
  padding: ${({scrolled}) =>
    scrolled ? '0px 20px 20px 20px' : '60px 20px 20px 20px'};
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
DrawerContents.displayName = 'S.DrawerContents';

export const Label = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 2px;
`;

export const UnderLine = styled.div`
  height: 1px;
  background: ${globals.MOON_250};
  padding: 0;
  margin-left: -20px;
  margin-right: -20px;
`;

export const TopPanel = styled.div`
  flex: 1 1 auto;
`;
TopPanel.displayName = 'S.TopPanel';

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`;
HeaderRow.displayName = 'S.HeaderRow';

export const Header = styled.div`
  font-family: Source Sans Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
`;
Header.displayName = 'S.Header';

export const Close = styled.div`
  text-align: right;
  flex: 1 1 auto;
`;
Close.displayName = 'S.Close';
