import {Button} from '@wandb/weave/components';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';

import {Organization} from '../../../generated/graphql';
import {isTrialEndPath} from '../../../routes';
import {navigateTo} from '../../../util/history';
import {trialEndPage} from '../../../util/urls';
import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
import {useCurrentBannerStates} from '..';

export type TrialSubscriptionExpiredBannerProps = {
  organizationId: Organization['id'];
};
export const TrialSubscriptionExpiredBannerGlobalList: FC = () => {
  const currentBannerStates = useCurrentBannerStates();
  return (
    <Tailwind>
      {currentBannerStates.map(state => {
        const trialSubscriptionExpiredBannerProps =
          state.freeAccountEnforcementBannerFlags
            .trialSubscriptionExpiredBannerProps;

        return (
          trialSubscriptionExpiredBannerProps && (
            <TrialSubscriptionExpiredBanner
              {...trialSubscriptionExpiredBannerProps}
            />
          )
        );
      })}
    </Tailwind>
  );
};

const TrialSubscriptionExpiredBanner: FC<
  TrialSubscriptionExpiredBannerProps
> = ({organizationId}) => {
  const location = useLocation();
  const isOnTrialEndPath = isTrialEndPath(location.pathname);

  return (
    <Tailwind>
      <AccountStatusBanner
        eventData={{
          location: `trial subscription expired banner`,
          organizationId,
        }}>
        <AccountStatusBannerFlexWrapper>
          <Icon name="warning" />
          <AccountStatusBannerTextWrapper>
            <AccountStatusBannerBoldText>
              Your free trial has expired.
            </AccountStatusBannerBoldText>
          </AccountStatusBannerTextWrapper>
        </AccountStatusBannerFlexWrapper>
        {!isOnTrialEndPath && (
          <Button
            variant="ghost"
            className="bg-transparent outline hover:bg-transparent hover:text-moon-600"
            onClick={() => {
              navigateTo({pathname: trialEndPage()});
            }}>
            Choose plan
          </Button>
        )}
      </AccountStatusBanner>
    </Tailwind>
  );
};
