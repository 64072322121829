import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import ImageIcon from '../../../../components/ImageIcon';

export const CardBrandInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
`;

export const CardBrandIcon = styled(ImageIcon)`
  width: 48px;
  margin-right: 16px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  align-items: center;
`;

export const CardBrand = styled.span`
  font-color: ${globals.MOON_800};
  font-size: 16px;
  font-weight: 600;
  margin-right: 4px;
`;

export const EndingIn = styled.span`
  font-color: ${globals.MOON_800};
  font-size: 16px;
  font-weight: 400;
`;
