import {InMemoryCache} from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {routerMiddleware} from 'connected-react-router';
import {produce} from 'immer';
import {applyMiddleware, compose, createStore} from 'redux';
import {enableBatching} from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import {apolloClient} from './apolloClient';
import {envIsDev, envIsIntegration} from './config';
import {captureError} from './integrations';
import createRootReducer from './reducers';
import allSagas from './state/sagas';
import {createCircularDependencies} from './util/apollo';
import {
  apolloLink as apolloLink2,
  createCircularDependencies as createCircularDependencies2,
} from './util/apollo2';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {Auth} from './util/auth';
import DebugCollector from './util/debug-collector';
import globalHistory from './util/history';

export const apolloClient2 = new ApolloClient({
  link: apolloLink2,
  connectToDevTools: false,
  cache: new InMemoryCache(),
  assumeImmutableResults: true,
});

export type ApolloClientType = typeof apolloClient2;

// Create auth client
export const auth = new Auth();

// Setup browser history

// Setup redux
export const history = globalHistory;

// TODO: Get this through redux
export const getHistory = () => history;

// Create initial state.
const initialState = {};

// This hides bits of state from redux devtools. Otherwise the devtools don't work
// because the objects are too large.
const actionSanitizer = (action: any) => {
  try {
    if (action.type === 'view/INIT_ALL') {
      return {
        ...action,
        payload: {...action.payload, viewApi: '<sanitized viewApi>'},
      };
    }
    if (action.type === '@view/loadFinished') {
      return produce(action, (draft: any) => {
        if (draft.payload?.result?.spec) {
          draft.payload.result.spec = `<sanitized spec>`;
        }
      });
    }
    if (action.type === '@expectedPanels/update') {
      return produce(action, (draft: any) => {
        const {keys, viz, wbConfig} = draft.payload;
        const numKeys = Object.keys(keys ?? {}).length;
        const numVizKeys = Object.keys(viz ?? {}).length;
        const numWbConfigs = wbConfig?.length;
        if (numKeys > 1000) {
          draft.payload.keys = `<sanitized (${numKeys} keys)>`;
        }
        if (numVizKeys > 1000) {
          draft.payload.viz = `<sanitized (${numVizKeys} viz keys)>`;
        }
        if (numWbConfigs > 1000) {
          draft.payload.viz = `<sanitized (${numWbConfigs} wbConfigs)>`;
        }
      });
    }
    if (
      action.type === '@view/panelBankConfig/diffAndInitPanels' ||
      action.type === '@view/workspaceSettings/disableAutoGeneratePanels' ||
      action.type === '@view/workspaceSettings/enableAutoGeneratePanels'
    ) {
      return {
        ...action,
        payload: {
          ...action.payload,
          expectedPanels: '<sanitized>',
        },
      };
    }
    if (action.type === '@runs/updateHistoryKeyInfo') {
      return produce(action, (draft: any) => {
        const info = draft.payload.historyKeyInfo;
        const numKeys = Object.keys(info?.keys ?? {}).length;
        const numSets = info?.sets?.length;
        if (numKeys > 1000) {
          draft.payload.historyKeyInfo.keys = `<sanitized (${numKeys} keys)>`;
        }
        if (numSets > 100) {
          // this threshold is low because each item is large
          draft.payload.historyKeyInfo.sets = `<sanitized (${numSets} sets)>`;
        }
      });
    }
    return action;
  } catch (err) {
    console.error('An error occurred while sanitizing redux action', err);
    return 'An error occurred while sanitizing redux action';
  }
};

// This hides bits of state from redux devtools. Otherwise the devtools don't work
// because the objects are too large. In severe cases, it could crash the app!
const stateSanitizer = (state: any) => {
  try {
    return produce(state, (draft: any) => {
      const numPanelBankSections = Object.keys(
        draft.views?.parts?.['panel-bank-section-config'] ?? {}
      ).length;
      const numPanels = Object.keys(draft.views?.parts?.panel ?? {}).length;
      const numPanelSettings = Object.keys(
        draft.views?.parts?.panelSettings ?? {}
      ).length;

      const shouldSanitizePanelBankSections = numPanelBankSections > 1000;
      const shouldSanitizePanels = numPanels > 1000;
      const shouldSanitizePanelSettings = numPanelSettings > 1000;

      if (shouldSanitizePanelBankSections) {
        draft.views.parts[
          'panel-bank-section-config'
        ] = `<sanitized (${numPanelBankSections} sections)>`;
        Object.keys(draft.views.parts['panel-bank-config']).forEach(key => {
          const panelBankConfig = draft.views.parts['panel-bank-config'][key];
          panelBankConfig.sectionRefs = `<sanitized (${numPanelBankSections} sections)>`;
        });
      }

      if (shouldSanitizePanels) {
        draft.views.parts.panel = `<sanitized (${numPanels} panels)>`;
        if (!shouldSanitizePanelBankSections) {
          // this means there are many panels but not many sections - we didn't need to fully sanitize
          // all section configs, but should make sure the panels attached to each section are sanitized
          const sectionConfigs = draft.views.parts['panel-bank-section-config'];
          Object.keys(sectionConfigs).forEach(key => {
            const sectionConfig = sectionConfigs[key];
            const numPanelsInSection = sectionConfig.panelRefs.length;
            sectionConfig.panelRefs = `<sanitized (${numPanelsInSection} panels)>`;
            sectionConfig.panels = `<sanitized (${numPanelsInSection} panels)>`;
          });
        }
      }

      if (shouldSanitizePanelSettings) {
        draft.views.parts.panelSettings = `<sanitized (${numPanelSettings} panelSettings)>`;
      }

      // sanitize expected panels state as necessary
      const numExpectedPanelsDefault = Object.keys(
        draft.expectedPanels?.defaultSpecs ?? {}
      ).length;
      const numExpectedPanelsApiAdded = Object.keys(
        draft.expectedPanels?.apiAddedSpecs ?? {}
      ).length;
      if (numExpectedPanelsDefault > 1000) {
        draft.expectedPanels.defaultSpecs = `<sanitized (${numExpectedPanelsDefault} panels)>`;
      }
      if (numExpectedPanelsApiAdded > 1000) {
        draft.expectedPanels.apiAddedSpecs = `<sanitized (${numExpectedPanelsApiAdded} panels)>`;
      }

      // runs history keys/sets are always sanitized because it's
      // tricky/potentially expensive to compute the size of the data
      Object.keys(draft.runs?.runs ?? {}).forEach(runName => {
        const run = draft.runs.runs[runName];
        const historyKeys = run.historyKeys?.data?.historyKeys;
        if (historyKeys) {
          historyKeys.keys = '<sanitized keys>';
          historyKeys.sets = '<sanitized sets>';
        }
      });
      Object.keys(draft.runs?.historyKeyInfos ?? {}).forEach(key => {
        const info = draft.runs.historyKeyInfos[key];
        info.historyKeySets = '<sanitized historyKeySets>';
      });

      if (draft.views2?.viewApi) {
        draft.views2.viewApi = '<sanitized viewApi>';
      }
    });
  } catch (err) {
    console.error('An error occurred while sanitizing redux state', err);
    return 'An error occurred while sanitizing redux state';
  }
};

// Create store middleware
const isReduxDevtoolsAllowed = envIsDev || envIsIntegration;
const devtoolsMiddleware =
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' &&
  isReduxDevtoolsAllowed
    ? window.__REDUX_DEVTOOLS_EXTENSION__({
        // these actions aren't logged in devtools, but they are still dispatched
        actionsDenylist: [
          '@view/interactionState',
          `@global/resetErrorPortal`,
          `@runs/registerQuery`,
          `@runs/queryResults`,
        ],
        actionSanitizer,
        stateSanitizer,
        // Uncomment this line to enable action tracing
        // trace: true,
      })
    : (f: any) => f;

const sagaMiddleware = createSagaMiddleware({
  onError: (error, {sagaStack}) => {
    // Note that we don't anticipate errors reaching this point since we catch all exceptions in rootSaga
    console.error('Saga middleware error:', error, sagaStack);
    captureError(error, 'saga_middleware', {
      extra: {
        sagaStack,
      },
    });
  },
});

const middleware = compose(
  applyMiddleware(
    routerMiddleware(history),
    thunk.withExtraArgument(apolloClient),
    sagaMiddleware
  ),
  devtoolsMiddleware
);

// Create the store
const reducer = enableBatching(createRootReducer(history));
export const store = createStore(reducer, initialState as any, middleware);

// Give the apollo client backend access to the store and auth (which it uses
// to get the current JWT or refresh it if needed).
createCircularDependencies(store, auth);
createCircularDependencies2(store, auth);

// Give our auth library access to the store
auth.store = store;

// Give Cypress access to store for integration tests
if (envIsIntegration) {
  window.store = store;
}

// Don't flag as IFrame if we're mounted in a service like Kubeflow.
// Do flag as IFrame if we're rendered in Kubeflow notebooks.
export function isInIframe() {
  try {
    return (
      window.self !== window.top &&
      (window.self.location.origin !== window.top?.location.origin ||
        window.top.location.pathname.startsWith('/notebook'))
    );
  } catch (e) {
    return true;
  }
}

// Test if jupyter=true is set on document load. If it's set, then you should
// continue to act as if we're in jupyter notebook mode even if the href
// changes later.
// TODO: revisit this, we may want to differentiate between jupyter and other sites embedding us
const JUPYTER_REGEXP = /jupyter=true/;
const isJupyter = isInIframe() || JUPYTER_REGEXP.test(document.location.href);

// FeaturePeek runs containers in an iframe, so we have to differentiate from
// the Jupyter Notebook iframe
const FEATUREPEEK_REGEXP = /\.featurepeek\.com/;
const isFeaturePeek = FEATUREPEEK_REGEXP.test(document.location.href);

const LOCAL_LOGIN_REGEXP = /local=true/;
const isLocalLogin = LOCAL_LOGIN_REGEXP.test(document.location.href);

export function isInJupyterNotebook() {
  return isJupyter && !isLocalLogin && !isFeaturePeek;
}

// Global error notification
window.addEventListener('unhandledrejection', event => {
  // NOTE there is no need for this error to be displayed in flash message
  // as it is caught by apollo's `errorLink`
  console.error('Unhandled rejection', event);
});

sagaMiddleware.run(allSagas, apolloClient);

export const debugCollector = new DebugCollector();
