import * as Update from '../update';
import {QueryResult, ServerResultDelta} from './types';

// Refetch objects for up to a minute after their latest update time
// This is to account for any delay in metrics getting written to bigtable which
// happens after the last write to metadata.
const UPDATED_WINDOW = 60 * 1000;

/**
 * Get a lastUpdated timestamp that accounts for potential delays in
 * metrics being written to bigtable.
 */
export const getLastUpdatedWithWindow = (lastUpdatedAt: string) => {
  const maxUpdatedAt = Math.min(
    new Date(lastUpdatedAt).getTime(),
    Date.now() - UPDATED_WINDOW
  );
  return new Date(maxUpdatedAt).toISOString();
};

export function fromServerResult(
  serverResult: ServerResultDelta,
  prevResult: QueryResult
): QueryResult {
  // Note: this was refactored to not use immer for performance reasons
  // immer calculations were taking 200ms for 100 runs with 1500 points.
  const result = {...prevResult, totalRuns: serverResult.totalRuns};
  for (const op of serverResult.delta) {
    switch (op.op) {
      case 'INSERT':
        result.runs = Update.insertArrayItem(result.runs, op.index, op.run);
        result.lastUpdatedAt =
          op.run.updatedAt > result.lastUpdatedAt
            ? op.run.updatedAt
            : result.lastUpdatedAt;
        break;
      case 'DELETE':
        result.runs = Update.deleteArrayIndex(result.runs, op.index);
        break;
      case 'UPDATE':
        result.runs = Update.updateArrayIndex(result.runs, op.index, op.run);
        result.lastUpdatedAt =
          op.run.updatedAt > result.lastUpdatedAt
            ? op.run.updatedAt
            : result.lastUpdatedAt;
        break;
    }
  }

  return result;
}
