import {Context} from '@datadog/browser-core';
import {datadogLogs} from '@datadog/browser-logs';
import _ from 'lodash';

import config, {envIsDev} from '../config';
const MAX_RENDERS_PER_COMP = 1000; // if a component renders more than this, it's probably a render loop

export const datadogEnabled = !config.ANALYTICS_DISABLED && envIsDev;

interface PerfMetrics {
  [compName: string]: RenderStats[];
}

type RenderStats = number;

export let perfMetrics: PerfMetrics = {};

const PERF_METRICS_MESSAGE = 'render time (MS) by component';
const PERF_METRICS_SEND_INTERVAL = 10000;

if (datadogEnabled) {
  setInterval(() => {
    if (_.isEmpty(perfMetrics)) {
      return;
    }
    try {
      datadogLogs.logger.info(PERF_METRICS_MESSAGE, {
        data: perfMetrics,
      } as unknown as Context);
    } catch (e) {
      console.warn(`error uploading perf metrics: ${e}`);
    }
    perfMetrics = {};
  }, PERF_METRICS_SEND_INTERVAL);
}

export function recordRender(compName: string, renderTime: number): void {
  if (!datadogEnabled) {
    return;
  }
  perfMetrics[compName] = perfMetrics[compName] ?? [];
  if (perfMetrics[compName].length < MAX_RENDERS_PER_COMP) {
    perfMetrics[compName].push(Math.round(renderTime));
  }
}
