import {useParams} from 'react-router';

import {decodeURIComponentSafe} from '../../util/urls';

type Params = {
  entityName: string;
  projectName: string;
  projectId: string;
  tab?: string;
  artifactTypeName?: string;
  artifactCollectionName?: string;
  artifactCollectionView?: string;
  artifactCommitHash?: string;
  artifactTab?: string;
  filePath?: string;
  launchTab?: string;
  weaveTab?: string;
};
/**
 * These values have been previously prop-drilled, so this lets us use the given hook from react-router but adds type safety and extends the URI decoding to any consuming component
 */
export const useProjectParams = () => {
  const {projectName, ...p} = useParams<Params>();
  /**
   * HAX: the project name URI we get from react-router might be encoded. Decode this URI component before using it. If this happens again on another URI component (e.g. entity name), we should do more general fix at the react-router layer.
   */
  return {
    ...p,
    projectName: decodeURIComponentSafe(projectName),
  };
};
