import React, {ReactNode, useEffect} from 'react';

import {Analytics} from '../../services/analytics';
import {EventData} from '../../services/analytics/types';
type Props = {
  button?: ReactNode;
  children?: ReactNode;
  className?: string;
  eventData?: EventData;
  style?: React.CSSProperties;
};

export const AccountStatusBanner = React.memo(
  ({eventData, ...props}: Props) => {
    useEffect(() => {
      if (eventData) {
        Analytics.track('Account Status Banner Viewed', eventData ?? {});
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div
        {...props}
        className="
          font-source-sans-pro text-primary-color min-h-60 flex items-center
          justify-between gap-8 bg-gold-400 p-16 text-left text-base
          font-normal tracking-normal"
      />
    );
  }
);
AccountStatusBanner.displayName = 'AccountStatusBanner';

export const AccountStatusBannerFlexWrapper = React.memo(({children}) => {
  return <div className="flex items-center gap-8">{children}</div>;
});
AccountStatusBannerFlexWrapper.displayName = 'AccountStatusBannerFlexWrapper';

export const AccountStatusBannerTextWrapper = React.memo(({children}) => {
  return <div className="flex gap-4">{children}</div>;
});
AccountStatusBannerTextWrapper.displayName = 'AccountStatusBannerTextWrapper';

export const AccountStatusBannerBoldText = React.memo(({children}) => {
  return <span className="font-semibold">{children}</span>;
});
AccountStatusBannerBoldText.displayName = 'AccountStatusBannerBoldText';
