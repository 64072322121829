import {WBIcon} from '@wandb/ui';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {Link} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Dropdown} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import {Skeleton} from '../../../components/Skeleton';

export const SectionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Description = styled.div`
  margin-bottom: 16px;
`;

export const ItemDivider = styled.div`
  border-top: 1px solid ${globals.gray300};
`;

export const DropdownItem = styled(Dropdown.Item)`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
  }
`;

export const AccountLink = styled(Link)`
  padding: 13px 6px 13px 18px;
  width: 100%;
`;

export const AccountDropdownMenu = styled(Dropdown.Menu)`
  &&& {
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const AccountDropdown = styled.div<{
  isDisplay?: boolean;
  isOpen?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;

  ${props =>
    props.isDisplay &&
    css`
      background-color: ${globals.white};
      padding: 13px 6px 13px 18px;
      border: 1px solid ${globals.MOON_200};
      &:hover {
        background-color: ${globals.MOON_100};
      }
    `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  width: 264px;
`;

export const AccountInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  width: 100%;
`;

export const AccountName = styled.div`
  font-weight: 600;
  color: ${globals.MOON_800};
  overflow-wrap: break-word;
  white-space: normal;
`;

export const AccountType = styled.div`
  color: ${globals.gray500};
`;

export const CheckIcon = styled(WBIcon).attrs({name: 'heavy-checkmark'})`
  color: ${globals.deepTeal};
  height: 20px;
  margin: 0 4px;
`;

export const ClosedCarrotIcon = styled(WBIcon).attrs({name: 'next'})`
  color: ${globals.gray500};
  font-size: 28px;
  transform: rotate(90deg);
`;

export const LoadingAccountPicker = styled(Skeleton)`
  max-width: 318px;
  height: 68px;
  border-radius: 4px;
  margin: 40px 0 24px 0;
`;
