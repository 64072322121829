// responsive utils for axis ticks
export function numTicksForHeight(h: number) {
  if (h <= 300) {
    return 3;
  }
  if (300 < h && h <= 600) {
    return 5;
  }
  return 10;
}

export function numTicksForWidth(w: number) {
  if (w <= 300) {
    return 3;
  }
  if (300 < w && w <= 400) {
    return 5;
  }
  return 10;
}
