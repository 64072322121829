import * as globals from '@wandb/weave/common/css/globals.styles';
import {hexToRGB} from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

export type BannerVariant = 'announcement' | 'error' | 'info' | 'warning';

export const BANNER_VARIANTS: {[key: string]: BannerVariant} = {
  ANNOUNCEMENT: 'announcement',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const BannerWrapper = styled.div<{variant: string; alignItems?: string}>`
  align-items: ${({alignItems}) => alignItems ?? 'center'};
  border-radius: 8px;
  display: flex;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
  letter-spacing: 0em;
  line-height: 20px;
  padding: 16px;
  text-align: left;

  ${({variant}) => {
    switch (variant) {
      case BANNER_VARIANTS.ANNOUNCEMENT:
        return css`
          background-color: ${hexToRGB(globals.GOLD_300, 0.24)};
          color: ${globals.GOLD_600};
        `;
      case BANNER_VARIANTS.ERROR:
        return css`
          background-color: ${hexToRGB(globals.RED_300, 0.24)};
          color: ${globals.RED_600};
        `;
      case BANNER_VARIANTS.WARNING:
        return css`
          background-color: ${hexToRGB(globals.SIENNA_300, 0.24)};
          color: ${globals.SIENNA_600};
        `;
      case BANNER_VARIANTS.INFO:
      default:
        return css`
          background-color: ${globals.MOON_150};
          color: ${globals.MOON_600};
        `;
    }
  }};
`;
BannerWrapper.displayName = 'S.BannerWrapper';

export const BannerFlexWrapper = styled.div<{alignItems?: string}>`
  display: flex;
  gap: 16px;
  align-items: ${({alignItems}) => alignItems ?? 'center'};
  svg {
    flex-shrink: 0;
  }
`;
BannerFlexWrapper.displayName = 'S.BannerFlexWrapper';

export const BannerTextWrapper = styled.div`
  display: flex;
  gap: 4px;
`;
BannerTextWrapper.displayName = 'S.BannerTextWrapper';

export const BannerBoldText = styled.span`
  font-weight: 600;
`;
BannerBoldText.displayName = 'S.BannerBoldText';

export const BannerTextWithLink = styled.div`
  display: inline;
  margin-left: 6px;
  align-items: center;
`;
BannerTextWithLink.displayName = 'BannerTextWithLink';

export const BannerButtonWrapper = styled.button<{variant: string}>`
  display: flex;
  align-items: center;
  ${({variant}) => {
    switch (variant) {
      case BANNER_VARIANTS.ANNOUNCEMENT:
        return css`
          background-color: ${hexToRGB(globals.GOLD_300, 0.48)};
          color: ${globals.GOLD_600};
        `;
      case BANNER_VARIANTS.ERROR:
        return css`
          background-color: ${hexToRGB(globals.RED_300, 0.48)};
          color: ${globals.RED_500};
        `;
      case BANNER_VARIANTS.WARNING:
        return css`
          background-color: ${hexToRGB(globals.SIENNA_300, 0.48)};
          color: ${globals.SIENNA_600};
        `;
      case BANNER_VARIANTS.INFO:
      default:
        return css`
          background-color: ${globals.GRAY_TRANSPARENT};
          color: ${globals.MOON_600};
        `;
    }
  }};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  border: none;
  background-color: transparent;
  text-wrap: nowrap;
  padding: 0;
  &:hover {
    cursor: pointer;
    filter: brightness(150%);
  }
`;
BannerButtonWrapper.displayName = 'S.BannerButtonWrapper';
