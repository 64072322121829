export type SmoothingOptionMeta = {
  displayName: string; // shows up in the UI
  iconName: string; // configures what Icon is displayed
  // defaults is what seeds the smoothing input controls
  sortOrder: number; // controls what order they display in the dropdown
  defaults: {
    default: number;
    min: number;
    max: number;
    step: number;
  };
};

export const smoothingTypes = {
  // speculative: when none is hit the smoothing weight is set to 0 which prevents the smooth() fn from running?
  none: 'none',
  average: 'average',
  gaussian: 'gaussian',
  exponential: 'exponential',
  exponentialTimeWeighted: 'exponentialTimeWeighted',
} as const;
export type SmoothingTypeKeys = keyof typeof smoothingTypes;
export type SmoothingTypeValues = (typeof smoothingTypes)[SmoothingTypeKeys];

export const smoothingOptionsMeta: Record<
  SmoothingTypeValues,
  SmoothingOptionMeta
> = {
  none: {
    displayName: 'No smoothing',
    iconName: 'no-smooth',
    sortOrder: 0, // TODO - remove this since it doesn't seem necessary
    // these won't be used, but the UI is visible & disabled
    defaults: {
      default: 0,
      min: 0,
      max: 0.999,
      step: 0.001,
    },
  },
  exponentialTimeWeighted: {
    displayName: 'Time weighted EMA',
    iconName: 'time-weighted-ema',
    sortOrder: 1,
    defaults: {
      default: 0,
      min: 0,
      max: 0.99,
      step: 0.01,
    },
  },
  average: {
    displayName: 'Running average',
    iconName: 'running-average',
    sortOrder: 2,
    defaults: {
      default: 10,
      min: 1,
      max: 100,
      step: 1,
    },
  },
  gaussian: {
    displayName: 'Gaussian',
    iconName: 'gaussian',
    sortOrder: 3,
    defaults: {
      default: 1,
      min: 0,
      max: 100,
      step: 1,
    },
  },
  exponential: {
    displayName: 'Exponential moving average (deprecated)',
    iconName: 'ema',
    sortOrder: 4,
    defaults: {
      default: 0,
      min: 0,
      max: 0.99,
      step: 0.01,
    },
  },
};
