import NumberInput from '@wandb/weave/common/components/elements/NumberInput';
import * as globals from '@wandb/weave/common/css/globals.styles';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {
  InspectorPropertyLabel,
  InspectorPropertyWrapper,
} from '../Inspector.styles';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {PropertyEditorComponent} from './property-editors';

const MinimalNumberInput = styled(NumberInput)`
  input {
    border: none !important;
    padding: 4px 8px !important;
    margin: -4px -8px !important;
    line-height: 24px !important;
    &:hover {
      background: ${globals.gray100};
    }
  }
`;

const NumberPropertyEditor: PropertyEditorComponent<'number'> = props => {
  const displayedVal = props.values.length === 1 ? props.values[0] : undefined;
  return (
    <InspectorPropertyWrapper>
      <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
      <MinimalNumberInput
        value={displayedVal}
        placeholder="Auto"
        onChange={val => {
          props.save(val);
        }}></MinimalNumberInput>
    </InspectorPropertyWrapper>
  );
};

export default NumberPropertyEditor;
