import React from 'react';

import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
// eslint-disable-next-line import/no-cycle
import {useSampleAndQueryToTable} from '../../util/panelHelpers';
import type {PanelSpec} from '../../util/panelTypes';
import {Query} from '../../util/queryTypes';
import {panelViews} from '../PanelBank/types';
import {parseExpressions} from '../PanelExpressionOptions';
import {barChartTransformQuery, getTitleFromConfig} from './common';
import {BarChartConfig} from './types';

// We don't want the panel infrastructure to do the query for us, since the query
// we make depends on internal state. So we disable the
// outer query, and do our own query inside the panel.
const transformQuerySkip = (
  query: Query,
  config: BarChartConfig
): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

const useTableData = (pageQuery: Query, config: BarChartConfig): any => {
  const query = barChartTransformQuery(
    pageQuery,
    config,
    parseExpressions(config.expressions, undefined)
  );

  return useSampleAndQueryToTable(query, pageQuery, config);
};

export const Spec: PanelSpec<typeof panelViews.barChart, BarChartConfig> = {
  type: panelViews.barChart,
  // eslint-disable-next-line import/no-cycle -- please fix if you can
  Component: React.lazy(() => import('./Component')),
  getTitleFromConfig,
  transformQuery: transformQuerySkip,
  useTableData,

  exportable: {
    image: true,
    csv: true,
    api: true,
  },

  configSpec: {
    chartTitle: {editor: 'string', displayName: 'Chart title'},
    xAxisMin: {editor: 'number', displayName: 'X min'},
    xAxisMax: {editor: 'number', displayName: 'X max'},
  },

  icon: 'panel-bar-chart',
};
