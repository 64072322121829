/**
 * A form multi-line text input.
 */

import {TailwindContents} from '@wandb/weave/components/Tailwind';
import classNames from 'classnames';
import React, {TextareaHTMLAttributes} from 'react';

import {TextArea} from './TextArea';

export const FixedSizeTextArea = ({
  className,
  ...props
}: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <TailwindContents>
      <TextArea
        style={{height: '80px'}}
        className={classNames(className, 'resize-none')}
        {...props}
      />
    </TailwindContents>
  );
};
