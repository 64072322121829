import {createAction} from 'typesafe-actions';

import {RunSelectorRunsQuery} from '../../generated/graphql';
export {deleteRun, updateRun, updateRunTags} from '../runs-low/actions';

type QueryResultsData = NonNullable<
  NonNullable<RunSelectorRunsQuery['project']>['runs']
>;

export const processQueryResults = createAction(
  '@runSelectorRuns/processQueryResults',
  action => (queryId: string, data: QueryResultsData) => action({queryId, data})
);

export const removeQuery = createAction(
  '@runSelectorRuns/removeQuery',
  action => (queryId: string) => action({queryId})
);
