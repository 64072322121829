// eslint-disable-next-line wandb/no-deprecated-imports
import {isBefore} from 'date-fns';

import {RootState} from '../../types/redux';
import {isAfter} from '../../util/date';
import {LoadableView} from '../views/types';
import {getNamedWorkspaceIdForView, viewIsDeletedNW} from './names';
import {
  getDataChecked,
  getStatus,
  makeWorkspaceReduxStateSelector,
  ReduxWorkspaceStateWithLoadedView,
  WorkspaceStatusOrData,
} from './selectors';
import {NamedWorkspaceId} from './types';

export type WorkspaceViews = Record<
  string, // NamedWorkspaceId.id or NamedWorkspaceId.username
  ViewWithNamedWorkspaceId<LoadableView>
>;

export type ViewWithNamedWorkspaceId<T> = T & {
  namedWorkspaceId: NamedWorkspaceId;
};

type WorkspaceViewList = {
  workspace: ReduxWorkspaceStateWithLoadedView;
  personalWorkspaces: WorkspaceViews;
  sharedViews: WorkspaceViews;
};

export const workspaceViewListSelector =
  (workspaceStateId: string) =>
  (state: RootState): WorkspaceStatusOrData<WorkspaceViewList> => {
    const workspaceSelector = makeWorkspaceReduxStateSelector(workspaceStateId);
    const workspaceState = workspaceSelector(state);

    if (state.views.loading || getStatus(workspaceState) === 'loading') {
      return {status: 'loading', error: undefined};
    }

    if (getStatus(workspaceState) === 'error') {
      return {status: 'error', error: workspaceState.error};
    }

    const workspace = getDataChecked(workspaceState);
    const views = (workspace?.namedWorkspaceViewCids ?? []).map(
      viewCid => state.views.views[viewCid]
    );

    const {personalWorkspaces, sharedViews} = groupedNwViews(views);
    return {
      workspace,
      personalWorkspaces,
      sharedViews,
    };
  };

// Separate personal workspaces from shared views so they can be filtered later on
export function groupedNwViews(views: LoadableView[]) {
  const personalWorkspaces: WorkspaceViews = {};
  const sharedViews: WorkspaceViews = {};

  for (const view of views) {
    if (viewIsDeletedNW(view.name)) {
      continue;
    }

    const username = view.user.username;
    const nwId = getNamedWorkspaceIdForView(view.name, view.user);
    const viewWithNwId = {
      ...view,
      namedWorkspaceId: nwId,
    };

    // Index by the workspace owner for personal workspaces so it's quicker to access
    // and go grab the most recently updated personal workspace from either legacyor  1.2
    if (nwId.nwType === 'personal workspace') {
      personalWorkspaces[username] = getMoreRecentlyUpdatedNw(
        personalWorkspaces[username],
        viewWithNwId
      );
    } else if (nwId.nwType === 'shared workspace') {
      sharedViews[nwId.id] = getMoreRecentlyUpdatedNw(
        sharedViews[nwId.id],
        viewWithNwId
      );
    }
  }

  return {
    personalWorkspaces,
    sharedViews,
  };
}
function getMoreRecentlyUpdatedNw(
  a: ViewWithNamedWorkspaceId<LoadableView>,
  b: ViewWithNamedWorkspaceId<LoadableView>
) {
  if (!a) {
    return b;
  }
  const aUpdatedAt = new Date(a.updatedAt ?? '');
  const bUpdatedAt = new Date(b.updatedAt ?? '');

  if (isBefore(aUpdatedAt, bUpdatedAt)) {
    return b;
  }
  if (isAfter(aUpdatedAt, bUpdatedAt)) {
    return a;
  }
  return a;
}
