import type {Table} from '@wandb/weave/common/util/csv';

import {
  captureUniqueError,
  queryToTable,
  sampleQueryHistorySpecs,
} from '../components/Export/utils';
import type {BarChartConfig} from '../components/PanelBarChart/types';
import type {RunsLinePlotConfig} from '../components/PanelRunsLinePlot/types';
import type {RunsDataQuery} from '../containers/RunsDataLoader';
import {useRunsData} from '../state/runs/hooks';
import type {Query} from '../util/queryTypes';
import {isGrouped as isGroupedRunHelper} from '../util/runhelpers';
import type {PanelConfig} from './panelTypes';

export function isGrouped(
  query: Query,
  config: RunsLinePlotConfig | BarChartConfig
) {
  // Runs can be grouped in two ways:
  // 1) If there is grouping in the data populating the plot, the plot
  // will have grouping.
  // 2) the user selected grouping in the panel (config.aggregate)
  if (config.useRunsTableGroupingInPanels ?? true) {
    return (
      isGroupedRunHelper(query) || config.aggregate || config.aggregateMetrics
    );
  }

  return config.aggregate || config.aggregateMetrics;
}

export function isSingleRun(props: {pageQuery: Query}) {
  // runName is set in the query only if we are looking at a singleRun page
  return props.pageQuery.runName != null;
}

export function useSampleAndQueryToTable(
  query: RunsDataQuery,
  pageQuery: Query,
  config: PanelConfig
) {
  query = sampleQueryHistorySpecs(query);
  const runsData = useRunsData(query);

  let table: Table = {cols: [], data: []};
  try {
    table = queryToTable(runsData.data, query, pageQuery, config);
  } catch (e) {
    captureUniqueError(e);
  }

  return {table, loading: runsData.loading};
}

export function getConfigWithDefaults(configSpec: any, config: any) {
  const spec = configSpec || {};
  const result = {} as any;
  for (const key of Object.keys(spec)) {
    result[key] = config[key] ?? spec[key].default;
  }
  return result;
}
