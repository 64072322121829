import * as React from 'react';

import DOC_URLS from '../../util/doc_urls';
import {LabeledOptionBlock as LabeledOption} from '../elements/LabeledOptionBlock';
import MultiButton from '../elements/MultiButton';
import {isPlotStyle} from '../PanelBarChart/common';
import {type BarChartConfig, type PanelConfig} from './types';

export const PlotType = ({
  config,
  updateConfig,
  usingExpressions,
}: {
  config: BarChartConfig;
  updateConfig: (newConfig: PanelConfig) => void;
  usingExpressions: boolean;
}) => {
  const plotStyleKeys = ['bar', 'boxplot', 'violin'];
  const plotStyleLabels = ['Bar', 'Box Plot', 'Violin'];
  return (
    <LabeledOption
      label="Style"
      helpText="Presentation mode for grouped data"
      docUrl={DOC_URLS.compareMetrics + '#grouping'}
      option={
        <MultiButton
          keys={plotStyleKeys}
          labels={plotStyleLabels}
          selected={(config as BarChartConfig).plotStyle ?? 'bar'}
          disabled={usingExpressions}
          onChange={key => {
            if (isPlotStyle(key)) {
              updateConfig({
                plotStyle: key,
              });
            }
          }}
        />
      }
    />
  );
};
