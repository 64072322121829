import React from 'react';

import {useBeamerNewPosts} from '../util/beamer';
import * as S from './BeamerIcon.styles';

interface BeamerIconProps {
  absolute?: true;
}

const BeamerIcon: React.FC<BeamerIconProps> = React.memo(({absolute}) => {
  const newPosts = useBeamerNewPosts();
  if (newPosts === 0) {
    return null;
  }
  return (
    <S.BeamerIconDiv absolute={absolute}>
      <S.BeamerIconBG>{newPosts}</S.BeamerIconBG>
    </S.BeamerIconDiv>
  );
});

export default BeamerIcon;
