/**
 * These exports used to be in markdown-blocks.ts, but they were imported
 * in util/report/index.ts, a util module used from other components,
 * ultimately causing an import cycle under certain import conditions in
 * tests.
 */
import GithubSlugger from 'github-slugger';
import {Editor, Element, Node} from 'slate';

export interface MarkdownBlock extends Element {
  type: 'markdown-block';
  content: string;
  autoFocus?: boolean;
  collapsedChildren?: Node[];
}

export function getCollapsibleMarkdownHeading(text: string): string | null {
  const firstLine = text.split('\n')[0];
  if (/^\s*# .+$/.test(firstLine)) {
    return firstLine;
  }
  return null;
}

export const isMarkdownBlock = (node: Node): node is MarkdownBlock =>
  node.type === 'markdown-block';

export const markdownToTableOfContentsData = (
  node: Node
): Array<{id: string; level: number; heading: string}> => {
  if (!isMarkdownBlock(node)) {
    return [];
  }

  // Used in weave under the hood to create the slug via rehype-slug plugin.
  const slugs = new GithubSlugger();

  return node.content
    .split('\n')
    .map(heading => {
      // Match and count the number of # to determine which level heading this is
      const headingTrimmed = heading.trim();
      const match = headingTrimmed.match(/^#+/);
      // Matches return as an array of the batch of found items so, [ "###" ] for example.
      const level = match?.length ? Array.from(match[0]).length : 0;

      return {
        // It seems that rehype-slug inserts `user-content` before the slug...
        // I checked the source code but didn't see where that happens, so I'm confused
        // but will add it here too so things work properly.
        id: 'user-content' + slugs.slug(heading),
        // The heading displayed shouldn't contain # or a leading space
        heading: headingTrimmed.slice(level).trimStart(),
        level,
      };
    })
    .filter(v => v.level > 0);
};

export const withMarkdownBlocks = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element =>
    isMarkdownBlock(element) ? true : isVoid(element);

  return editor;
};
