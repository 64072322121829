import {Settings} from '../../../components/WorkspaceDrawer/Settings/types';
import * as DiscussionThreadNormalize from '../discussionThread/normalize';
import {denormFn, normFn} from '../normalizerSupport';
import * as PanelSettingsNormalize from '../panelSettings/normalize';
import * as Types from './types';

export const normalize = normFn<Types.ReportObjSchema>('runs', (whole, ctx) => {
  return {
    version: whole.version,
    panelSettingsRef: PanelSettingsNormalize.normalize(
      // TODO(joyce) - remove this type assertion once settings are fully migrated
      whole.panelSettings as Settings,
      ctx
    ),
    blocks: whole.blocks,
    width: whole.width,
    authors: whole.authors,
    discussionThreadRefs: whole.discussionThreads.map(dt => {
      return DiscussionThreadNormalize.normalize(dt, ctx);
    }),
  };
});

export const denormalize = denormFn<Types.ReportObjSchema>((part, ctx) => ({
  version: part.version,
  panelSettings: PanelSettingsNormalize.denormalize(part.panelSettingsRef, ctx),
  blocks: part.blocks,
  width: part.width,
  authors: part.authors,
  discussionThreads: part.discussionThreadRefs.map((dt, i) => {
    return DiscussionThreadNormalize.denormalize(dt, ctx, i);
  }),
}));
