import React, {useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Modal} from 'semantic-ui-react';

import {useViewer} from '../state/viewer/hooks';
import {safeLocalStorage} from '../util/localStorage';
import * as S from './AddEmailAlert.styles';

const AddEmailAlert: React.FC = () => {
  const viewer = useViewer();

  let emailInUse;
  try {
    emailInUse = safeLocalStorage.getItem('emailInUse');
    // this will throw an exception when storage is blocked
  } catch (e) {
    emailInUse = null;
  }
  const [addEmailAlertModalOpen, setAddEmailAlertModalOpen] = useState(true);

  const onClose = () => {
    safeLocalStorage.removeItem('emailInUse');
    setAddEmailAlertModalOpen(false);
  };

  if (viewer?.username == null || emailInUse == null) {
    return null;
  }

  if (addEmailAlertModalOpen) {
    return <AddEmailAlertModal onClose={onClose} username={viewer.username} />;
  }
  return null;
};

interface AddEmailAlertModalProps {
  onClose: () => void;
  username: string;
}

const AddEmailAlertModal: React.FC<AddEmailAlertModalProps> = ({
  onClose,
  username,
}) => {
  return (
    <Modal open size="tiny">
      <Modal.Header>Attempted to add an email</Modal.Header>
      <Modal.Content>
        <S.AddEmailWarningContent>
          You tried to link an email to your account, but it's already attached
          to another W&B account. If you want to associate this email with this
          account, please log out, log into W&B using the email, delete the
          account or delete the email from the account and then try adding the
          email.
        </S.AddEmailWarningContent>
      </Modal.Content>
      <Modal.Actions>
        <S.ConfirmationButtons>
          <Button onClick={onClose}>Okay</Button>
        </S.ConfirmationButtons>
      </Modal.Actions>
    </Modal>
  );
};

export default AddEmailAlert;
