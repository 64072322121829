import * as _ from 'lodash';

interface Histogram {
  counts: number[];
  binEdges: number[];
}

export function linspace(a: number, b: number, n: number): number[] {
  // Like the python numpy linspace
  if (n < 2) {
    return n === 1 ? [a] : [];
  }
  const ret = Array(n);
  n--;
  for (let i = n; i >= 0; i--) {
    ret[i] = (i * b + (n - i) * a) / n;
  }
  return ret;
}

export function makeHistogram(
  valuesOrHistogram: any,
  numBuckets: number,
  min?: number,
  max?: number
): Histogram {
  if (Array.isArray(valuesOrHistogram)) {
    return makeHistogramFromValues(valuesOrHistogram, numBuckets, min, max);
  } else if (valuesOrHistogram._type === 'histogram') {
    const histogram = histogramFromRunHistoryHistogram(valuesOrHistogram);
    return makeHistogramFromHistogram(histogram, numBuckets, min, max);
  } else {
    console.warn(
      'makeHistogram passed something other than array of numbers or runHistory histogram'
    );
    return {counts: [], binEdges: []};
    // Error case
  }
}

export function histogramFromRunHistoryHistogram(runHistoryHistogram: {
  bins: number[];
  values: number[];
  _type: string;
}): Histogram {
  /**  This takes a histogram oberjct from RunHistory that should look like:
   * {
   *    bins: number[]
   *    values: number[]
   *    _type: "histogram"
   * }
   */

  return {
    counts: runHistoryHistogram.values as number[],
    binEdges: runHistoryHistogram.bins as number[],
  };
}

export function makeHistogramFromHistogram(
  histogram: Histogram,
  numBuckets: number = 10,
  min: number = _.first(histogram.binEdges) || 0,
  max: number = _.last(histogram.binEdges) || 0
): Histogram {
  if (min >= max) {
    // shouldn't happen
    console.warn('Histogram with max smaller than or equal to min', min, max);
    return {counts: [histogram.counts[0]], binEdges: [min, min + 1]};
  }

  const binEdges = [];
  const bucketWidth = (max - min) / numBuckets;

  const counts: number[] = Array(numBuckets).fill(0);

  for (let i = 0; i < numBuckets + 1; i++) {
    binEdges.push(min + i * bucketWidth);
  }

  for (let i = 1; i < histogram.binEdges.length; i++) {
    const originalHistogramLeft = histogram.binEdges[i - 1];
    const originalHistogramRight = histogram.binEdges[i];
    const originalHistogramWidth =
      originalHistogramRight - originalHistogramLeft;
    const originalHistogramValue = histogram.counts[i - 1];
    const minBucket = Math.floor((originalHistogramLeft - min) / bucketWidth);
    const maxBucket = Math.ceil((originalHistogramRight - min) / bucketWidth);
    for (let j = minBucket; j <= maxBucket; j++) {
      const newLeft = binEdges[j];
      const newRight = binEdges[j + 1];
      const overlapLeft = Math.max(originalHistogramLeft, newLeft);
      const overlapRight = Math.min(originalHistogramRight, newRight);
      if (overlapRight > overlapLeft) {
        const overlapWidth = overlapRight - overlapLeft;

        counts[j] +=
          originalHistogramValue * (overlapWidth / originalHistogramWidth);
      }
    }
  }
  return {counts, binEdges};
}

export function makeHistogramFromValues(
  values: number[],
  numBuckets = 10,
  min: number = _.min(values) || 0,
  max: number = _.max(values) || 0
) {
  /**
   * builds a histogram of values for evenly spaced buckets from max to min.
   * * If max and min unspecified, set to max and min of values.
   */

  if (min === max) {
    console.warn('Histogram with max smaller than or equal to min', min, max);
    return {counts: [values.length], binEdges: [min, min + 1]};
  }

  const binEdges = [];
  const bucketWidth = (max - min) / numBuckets;

  const counts = Array(numBuckets).fill(0);

  for (let i = 0; i < numBuckets + 1; i++) {
    binEdges.push(min + i * bucketWidth);
  }

  values.forEach(v => {
    let bucket = Math.floor((v - min) / bucketWidth);
    if (bucket >= numBuckets) {
      bucket = numBuckets - 1;
    } else if (bucket < 0) {
      bucket = 0;
    }
    counts[bucket]++;
  });
  return {counts, binEdges};
}
