import {original} from 'immer';
import _ from 'lodash';

import * as Panels from '../../../util/panels';
import * as Normalize from '../normalize';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {
  deleteParts,
  removeHistoryForObject,
  replacePart,
  ViewReducerState,
} from '../reducerSupport';
import * as WorkspaceSettingsTypes from '../workspaceSettings/types';
import * as Types from './types';

/**
 * Deletes a list of panels from views reducer state. If a target section is specified,
 * panels will only be deleted from that section. Otherwise, if only the panel bank ref
 * is provided, panels will be removed from all sections across the workspace.
 *
 * If panel auto-gen is enabled, panels may be moved into the "Hidden Panels" section
 * and not fully deleted from the view. This ensures deleted panels aren't added back
 * by future rounds of panel generation.
 *
 * @param draftState immer draft of the view reducer state
 * @param panelRefs refs of the panels to delete
 * @param targetSectionRef if specified, only delete panels from this section
 * @param panelBankRef if provided, may need to use "Hidden panels" section
 * @param workspaceSettingsRef for looking up panel auto-gen setting value
 * @returns list of sections that had panels removed from it and metadata for undoing this action
 */
export function deletePanels(
  draftState: ViewReducerState,
  panelRefs: Types.Ref[],
  targetSectionRef?: PanelBankSectionConfigTypes.Ref,
  panelBankRef?: PanelBankConfigTypes.Ref,
  workspaceSettingsRef?: WorkspaceSettingsTypes.Ref
): Types.DeletePanelsResult {
  // Early exit if no panels to delete
  if (!panelRefs.length) {
    return [];
  }

  const originalState = original(draftState) as Readonly<ViewReducerState>;

  // Determine which section(s) to remove panels from
  const panelBankConfig = panelBankRef
    ? originalState.parts[panelBankRef.type][panelBankRef.id]
    : undefined;
  const sectionsToCheck = targetSectionRef
    ? [targetSectionRef] // only remove from target section if one is specified
    : panelBankConfig?.sectionRefs.slice(0, -1); // must exclude last section (AKA hidden panels)

  // Remove panel refs from the appropriate section(s)
  const panelRefIds = new Set(panelRefs.map(ref => ref.id));
  const modifiedSections: Array<{
    sectionRef: PanelBankSectionConfigTypes.Ref;
    removedPanelRefs: Types.Ref[];
  }> = [];
  sectionsToCheck?.forEach(sectionRef => {
    const sectionDraft = draftState.parts[sectionRef.type][sectionRef.id];
    if (!sectionDraft) {
      return;
    }

    const [panelsToRemove, panelsToKeep] = _.partition(
      sectionDraft.panelRefs,
      panelRef => panelRefIds.has(panelRef.id)
    );
    if (panelsToRemove.length > 0) {
      sectionDraft.panelRefs = panelsToKeep;
      modifiedSections.push({
        sectionRef,
        // unfortunately because we cannot use original state here, these panel refs
        // must be cloned to prevent "cannot getPrototypeof on revoked proxy" errors
        removedPanelRefs: _.cloneDeep(panelsToRemove),
      });
    }
  });

  // Determine if Hidden Panels section should be used
  const workspaceSettings = workspaceSettingsRef
    ? originalState.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
    : undefined;
  const isPanelAutoGenEnabled = workspaceSettings
    ? // must be explicit `!== false` because `undefined` defaults to auto-gen ON
      workspaceSettings.shouldAutoGeneratePanels !== false
    : false;
  const shouldUseHiddenSection =
    panelBankConfig != null && isPanelAutoGenEnabled;

  // A panel should only move to the hidden section if it's a single-
  // key panel and no other panels in the workspace uses the same key.
  // Create a set of existing panel keys for quick lookup.
  const existingPanelKeys = new Set<string>();
  if (shouldUseHiddenSection) {
    panelBankConfig.sectionRefs.forEach(sectionRef => {
      // must use draft state to ensure removed panels are excluded
      const sectionDraft = draftState.parts[sectionRef.type][sectionRef.id];
      sectionDraft.panelRefs.forEach(panelRef => {
        const config = originalState.parts[panelRef.type][panelRef.id];
        const key = Panels.getKey(config);
        if (key) {
          existingPanelKeys.add(key);
        }
      });
    });
  }

  // Hide or delete panels as appropriate
  return modifiedSections.map(({sectionRef, removedPanelRefs}) => {
    const deletedPanels: Types.PanelRefAndConfig[] = [];
    const hiddenPanels: Types.PanelRefAndConfig[] = [];
    removedPanelRefs.forEach(ref => {
      const isPanelInDraft = draftState.parts[ref.type][ref.id] != null;
      if (!isPanelInDraft) {
        return;
      }

      const config = originalState.parts[ref.type][ref.id];
      const key = Panels.getKey(config);
      if (shouldUseHiddenSection && key && !existingPanelKeys.has(key)) {
        // move to hidden section
        const hiddenSectionRef = panelBankConfig.sectionRefs.slice(-1)[0];
        const hiddenSectionDraft =
          draftState.parts[hiddenSectionRef.type][hiddenSectionRef.id];
        hiddenSectionDraft.panelRefs.push(ref);
        hiddenPanels.push({ref, config});
      } else {
        // delete panel entirely
        removeHistoryForObject(draftState, ref);
        deleteParts(draftState, ref);
        deletedPanels.push({ref, config});
      }
    });

    // return necessary info for undo
    const section = originalState.parts[sectionRef.type][sectionRef.id];
    const prevPanelRefs = section.panelRefs;
    const prevIsPanelsAuto = section.isPanelsAuto;
    return {
      sectionRef,
      prevPanelRefs,
      prevIsPanelsAuto,
      deletedPanels,
      hiddenPanels,
    };
  });
}

/**
 * Inverse of `deletePanels`. Used to restore panels to their previous positions.
 */
export function undoDeletePanels(
  draftState: ViewReducerState,
  deletePanelsResult: Types.DeletePanelsResult,
  panelBankConfigRef?: PanelBankConfigTypes.Ref
) {
  const restoredPanelRefs: Types.Ref[] = []; // track this for undo

  deletePanelsResult.forEach(modifiedSection => {
    const {
      sectionRef,
      prevPanelRefs,
      prevIsPanelsAuto,
      deletedPanels,
      hiddenPanels,
    } = modifiedSection;

    // Restore panel refs
    const sectionDraft = draftState.parts[sectionRef.type][sectionRef.id];
    sectionDraft.panelRefs = prevPanelRefs;
    restoredPanelRefs.push(
      ...deletedPanels.map(({ref}) => ref),
      ...hiddenPanels.map(({ref}) => ref)
    );

    // Re-create deleted panels
    deletedPanels.map(({ref, config}) => {
      const newRef = Normalize.addObj(
        draftState.parts,
        'panel',
        sectionRef.viewID,
        config
      );
      replacePart(draftState, ref, newRef);
    });

    // Remove restored panels from hidden section
    const panelBankConfig = panelBankConfigRef
      ? draftState.parts[panelBankConfigRef.type][panelBankConfigRef.id]
      : undefined;
    if (panelBankConfig && hiddenPanels.length > 0) {
      const panelsToUnhide = new Set(hiddenPanels.map(({ref}) => ref.id));
      const hiddenSectionRef = panelBankConfig.sectionRefs.slice(-1)[0];
      const hiddenSectionDraft =
        draftState.parts[hiddenSectionRef.type][hiddenSectionRef.id];
      hiddenSectionDraft.panelRefs = hiddenSectionDraft.panelRefs.filter(
        hiddenPanelRef => !panelsToUnhide.has(hiddenPanelRef.id)
      );
    }

    // Revert isPanelsAuto flag
    sectionDraft.isPanelsAuto = prevIsPanelsAuto;
  });

  return restoredPanelRefs;
}
