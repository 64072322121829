import {createAction} from 'typesafe-actions';

import * as Types from './types';

// User Actions

export const set = createAction(
  '@view/panelSettings/set',
  action => (ref: Types.Ref, panelSettings: Types.PanelSettings) =>
    action({ref, panelSettings})
);

export const update = createAction(
  '@view/panelSettings/update',
  action =>
    (ref: Types.Ref, panelSettingsUpdate: Partial<Types.PanelSettings>) =>
      action({ref, panelSettingsUpdate})
);
