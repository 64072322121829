import * as _ from 'lodash';

import {DomainMaybe} from '../../vis/types';
import {HistoryPoint} from '../types';
import {envIsProd} from './../../../config';
import {logHandledError} from './../../../services/errors/errorReporting';
import {RunHistoryRow} from './../../../types/run';
import {Boundary, PartialMaybeDomain} from './types';
import {
  hasExternalBounds,
  hasInternalBounds,
  hasLogicalBoundaries,
  hasLogicalMaxBoundary,
  hasLogicalMinBoundary,
  hasMaxBoundary,
  hasMinBoundary,
  isInRange,
  isMissingExternalBounds,
} from './utils';

// leave this - helpful for debugging zooming bug and test cases
// can't fire in prod
function log(...m: any) {
  // eslint-disable-next-line no-constant-condition
  if (!envIsProd && false) {
    console.log(...m);
  }
}
/**
 * Given a list of points sorted by the x-axis key, find the _step range that encompasses all of the metric values included in the domain. This is tricky because the metric values are not guaranteed to be monotonically increaing along with _step
 *
 * Logic:
 * - do a pass over the points, logging the internal min/max for points in the domain
 * - do a second pass over the points outside the range, any _steps that are lower or higher than the internal min/max get set as external min/max
 *
 * Return an approximate domain range that encompasses as many of the points as we can given there is a known failure mode for resampling charts with custom x-values where the x-values aren't correlated to the underlying _step value: https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1710777391541279
 */
export function baseCalcDomainRange(
  domain: PartialMaybeDomain,
  historyData: HistoryPoint[],
  xAxisKey: string
): DomainMaybe {
  /**
   * We are going to aggressively widen the domain range on zooming based only on the external points. Any side that doesn't have a valid bounding external point will be widened to `null` in order to let the API get greedy about requesting values
   *
   * History data is a flattened array of all the points in the chart. We are going to loop through it once, keeping track of the _step values for the closest bounding points that aren't in the range and grabbing their step values.
   *
   * NOTE: This will not work well for charts with a non-monotonic custom x-axis. The looser the correlation between x-axis and _step, the less likely the boundaries determined from the history data will be correct and progressively zoom down to finer resolutions.
   *
   */

  // if there's no domain just return null, null
  if (!Number.isFinite(domain[0]) && !Number.isFinite(domain[1])) {
    log('no domain');
    return [null, null];
  }

  const b: Boundary = {
    internalMin: null,
    internalMax: null,
    externalMin: null,
    externalMax: null,
  };

  const internalVals: RunHistoryRow[] = [];
  const externalVals: RunHistoryRow[] = [];

  (historyData ?? []).forEach(point => {
    const xVal = point[`${xAxisKey}Avg`] ?? point[xAxisKey];
    const inRange = isInRange(xVal, domain);
    if (inRange) {
      internalVals.push(point);
    } else {
      externalVals.push(point);
    }
  });

  log('internalVals', internalVals);
  log('externalVals', externalVals);
  /**
   * First we have to look through the internal values and find the min/max of the values
   */
  internalVals.forEach(v => {
    const [min, max] = [v._stepMin ?? v._step, v._stepMax ?? v._step];

    if (_.isNull(b.internalMax) || max > b.internalMax) {
      b.internalMax = max;
    }
    if (_.isNull(b.internalMin) || min < b.internalMin) {
      b.internalMin = min;
    }
  });

  /**
   * If we're missing internal data we need to widen to find more points, so eject
   */
  if (!hasInternalBounds(b)) {
    log('missing internal bounds');
    return [null, null];
  }

  /**
   * Imagine the following points:
   * [
   *  {_stepAvg: 2, _stepMin: 1, _stepMax: 3},
   *   -- inside range --
   *  {_stepAvg: 5, _stepMin: 4, _stepMax: 6},
   *  {_stepAvg: 8, _stepMin: 7, _stepMax: 9},
   *   -- end inside range --
   *  {_stepAvg: 11, _stepMin: 10, _stepMax: 12},
   * ]
   *
   * The minimum external bounding value will be the closest max of the lowest outside bounding point
   * The minimum inside value will be the minimum of the internal points
   * The maximum inside value will be the maximum of the internal points
   * The maximum external bounding value will be the closest min of the highest outside bounding point
   */

  externalVals.forEach(v => {
    const [min, max] = [v._stepMin ?? v._step, v._stepMax ?? v._step];
    // what side of the domain is this on?
    const leftSide = max < b.internalMin;
    const rightSide = min > b.internalMax;
    if (leftSide === rightSide) {
      // this is a nonsense point since it can't be higher and lower simultaneously
      // than the internal min/max
      return;
    }
    if (leftSide) {
      if (_.isNull(b.externalMin) || max > b.externalMin) {
        b.externalMin = max;
      }
    } else if (rightSide) {
      if (_.isNull(b.externalMax) || min < b.externalMax) {
        b.externalMax = min;
      }
    }
  });

  log('bounds', b);

  /**
   * If we're missing external data we can't trust that we've got enough data in the range for custom x-axes, so eject
   */
  if (isMissingExternalBounds(b)) {
    log('missing external bounds');
    return [null, null];
  }

  /**
   * Cases remaining:
   * - has internal data and external min and external max
   * - has internal data annd external min
   * - has internal data and external max
   *
   * Note: just because boundaries exist doesn't mean they're logical (e.g. the external min might not be less than the internal min)
   */

  // cases where both sides of the external boundary are present
  if (hasExternalBounds(b)) {
    // both sides are logical
    if (hasLogicalBoundaries(b)) {
      log('both sides logical');
      return [b.externalMin, b.externalMax];
    }

    if (hasLogicalMaxBoundary(b)) {
      log('logical max');
      return [null, b.externalMax];
    }

    if (hasLogicalMinBoundary(b)) {
      log('logical min');
      return [b.externalMin, null];
    }

    // try to flip the boundaries as a last resort
    return [
      b.externalMin < b.internalMin ? b.externalMin : null,
      b.externalMax > b.internalMax ? b.externalMax : null,
    ];
  }
  /** If we're bounded only on one side we widen the other side to find more points */
  if (hasMinBoundary(b) && hasLogicalMinBoundary(b)) {
    log('min logical');
    return [b.externalMin, null];
  }
  if (hasMaxBoundary(b) && hasLogicalMaxBoundary(b)) {
    log('max logical');
    return [null, b.externalMax];
  }

  // to be safe, kick anything else back as widened
  return [null, null];
}

export function calcDomainRange(
  domain: PartialMaybeDomain,
  historyData: HistoryPoint[],
  xAxisKey: string
): DomainMaybe {
  const [min, max] = baseCalcDomainRange(domain, historyData, xAxisKey);

  /**
   * It should be impossible to return non-integer values from the calcDomain function since it should never use the _stepAvg value when setting a boundary. If this happens, let's log an error so we can go investigate why it's happening.
   *
   * The nulls are defaulted to `1` so we can do the check.
   */
  if ([(min ?? 1) % 1, (max ?? 1) % 1].some(v => v !== 0)) {
    logHandledError('calcDomainRange returned non-integer values');
  }

  // as a safeguard, make sure we don't accidentally pass these non-integer values back through to the API
  return [
    _.isNull(min) ? null : Math.floor(min),
    _.isNull(max) ? null : Math.ceil(max),
  ];
}
