import 'react-app-polyfill/ie11';
import 'es6-symbol/implement';
// Needed for ios12 mobile safari
import 'intersection-observer';
import 'globalthis/auto';

import {enableMapSet as immerEnableMapSet} from 'immer';
// @ts-ignore
import entries from 'object.entries';
// @ts-ignore
import values from 'object.values';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

immerEnableMapSet();

// Polyfills
if (!Object.values) {
  values.shim();
}
if (!Object.entries) {
  entries.shim();
}
if (!Element.prototype.matches) {
  Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
}

if (!(window as any).ResizeObserver) {
  (window as any).ResizeObserver = ResizeObserverPolyfill;
}

if (typeof (window as any).global === 'undefined') {
  (window as any).global = window;
}
