import React, {useContext} from 'react';

import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {Account} from '../../../components/Search/SearchNav/types';
import {envIsPublicCloud} from '../../../config';
import {Plan, usePredefinedRoleOrgInfoQuery} from '../../../generated/graphql';
import {
  getPrimarySub,
  isCommunityEditionPrimarySubscription,
  isTrialPlanPrimarySubscription,
  PremiumFeatureTooltip,
} from '../../../util/accounts/pricing';
import {SettingsCard} from '../../Billing/AccountSettings/SettingsTab/SettingsCard';
import {RoleDescription, RoleItem, RoleName} from './CommonRoles';

type Role =
  | {
      title: string;
      description: string;
      isPremiumOption: true;
      isPremiumFeatureDisabled: (
        subscription: {
          plan: Pick<Plan, 'name'>;
        },
        selectedAccount: Account
      ) => boolean;
    }
  | {
      title: string;
      description: string;
      isPremiumOption: false;
    };

const roles: Role[] = [
  {
    title: 'Viewer',
    description:
      'View-Only members can view assets within the team such as runs, reports, and workspaces. They can follow and comment on reports, but they can not create, edit, or delete project overview, reports, or runs.',
    isPremiumOption: true,
    isPremiumFeatureDisabled: (
      subscription: {
        plan: Pick<Plan, 'name'>;
      },
      selectedAccount: Account
    ) => !selectedAccount.isEnterprise,
  },
  {
    title: 'Member',
    description:
      'A regular member of the team. A team member is invited by email by the team admin. A team member cannot invite other members. Team members can only delete runs and sweep runs created by that member.',
    isPremiumOption: true,
    isPremiumFeatureDisabled: (
      subscription: {
        plan: Pick<Plan, 'name'>;
      },
      selectedAccount: Account
    ) =>
      isTrialPlanPrimarySubscription(subscription) ||
      isCommunityEditionPrimarySubscription(subscription),
  },
  {
    title: 'Admin',
    description:
      'Team admins can add and remove other admins or team members. They have permissions to modify all projects and full deletion permissions.',
    isPremiumOption: false,
  },
];

export const PredefinedRoles: React.FC = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const {data, loading} = usePredefinedRoleOrgInfoQuery({
    variables: {organizationID: selectedAccount?.id ?? ''},
    skip: !envIsPublicCloud || selectedAccount?.id == null,
  });
  const organization = data?.organization;
  const primarySub = organization == null ? null : getPrimarySub(organization);

  return (
    <SettingsCard>
      {roles.map(role => (
        <div
          className="border-b border-moon-250 py-12 first:pt-0 last:border-0 last:pb-0"
          key={role.title}>
          <RoleItem>
            <div className="flex-grow text-left">
              <div className="flex">
                <RoleName>{role.title}</RoleName>
                {role.isPremiumOption &&
                  !loading &&
                  primarySub != null &&
                  selectedAccount != null && (
                    <PremiumFeatureTooltip
                      isPremiumFeatureDisabled={role.isPremiumFeatureDisabled(
                        primarySub,
                        selectedAccount
                      )}
                    />
                  )}
              </div>
              <RoleDescription>{role.description}</RoleDescription>
            </div>
          </RoleItem>
        </div>
      ))}
    </SettingsCard>
  );
};
