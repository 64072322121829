import {getCascadingSetting} from '../../getCascadingSetting';
import {LinePlotSettings} from '../../types';

export const getCascadingShowLegend = ({
  suppressLegends,
  showLegend,
}: {
  suppressLegends: LinePlotSettings['suppressLegends'];
  showLegend?: LinePlotSettings['showLegend'];
}): boolean | undefined => {
  // supressLegends is a workspace level setting and showLegend is used in the panel config
  // so we need to flip the value. There's currently no section level setting
  return getCascadingSetting([
    suppressLegends == null ? undefined : !suppressLegends,
    showLegend,
  ]);
};
