import {getCookie, setCookie} from '@wandb/weave/common/util/cookie';
import React, {useEffect, useState} from 'react';

import {envIsLocal} from '../config';
import {useLatestLocalVersionInfoQuery} from '../generated/graphql';
import NudgeBar from './NudgeBar';

/**
 * Note on cookie names:
 * We used to have a cookie named "hide local nudge" (with a space in the
 * name). Since the cookie is only used in client-side browser code, and
 * browsers like Chromium don't complain about spaces in cookie names, we didn't
 * have a problem. However, spaces in cookie names are technically invalid
 * (1,2,3), yet loosely enforced, and can cause problems downstream. So we will
 * retain the legacy name such that users who already have this cookie set will
 * not see the banner again, but we will use the correct name for new cookies.
 *
 * References:
 * 1. https://tools.ietf.org/html/rfc6265#section-4.1.1
 * 2. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
 * 3. https://stackoverflow.com/questions/1969232/allowed-characters-in-cookies
 */
const LOCAL_NUDGE_COOKIE_NAME = 'hide_local_nudge';
const LEGACY_LOCAL_NUDGE_COOKIE_NAME = 'hide local nudge';

const LocalNudgeBar: React.FC = React.memo(() => {
  const [showNudge, setShowNudge] = useState<boolean>(false);
  const localOutOfDateQuery = useLatestLocalVersionInfoQuery();
  const localOutOfDate =
    localOutOfDateQuery.data?.serverInfo?.latestLocalVersionInfo?.outOfDate ??
    false;
  const latestVersion =
    localOutOfDateQuery?.data?.serverInfo?.latestLocalVersionInfo
      ?.latestVersionString;

  const hasDismissed =
    getCookie(LOCAL_NUDGE_COOKIE_NAME) ||
    getCookie(LEGACY_LOCAL_NUDGE_COOKIE_NAME);
  const contactString =
    'Contact your instance administrator to upgrade or find upgrade instructions here.';
  const mainText =
    latestVersion == null
      ? 'A new version of W&B Local is now available. ' + contactString
      : `Local version ${latestVersion} is now available. ` + contactString;
  const linkText = 'Upgrade local docs →';
  const link = 'https://docs.wandb.ai/guides/hosting/basic-setup#upgrades';
  const onNudgeDismiss = () => {
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const expiryDate = new Date(Date.now() + oneWeek);
    setCookie(LOCAL_NUDGE_COOKIE_NAME, 'true', expiryDate);
    setShowNudge(false);
  };

  useEffect(() => {
    setShowNudge(hasDismissed === '' && localOutOfDate);
  }, [localOutOfDate, hasDismissed]);
  return (
    <>
      {envIsLocal && showNudge && (
        <NudgeBar
          content={mainText}
          trackingLocation={'local version nudge bar'}
          linkText={linkText}
          link={link}
          showDismissOption={true}
          onClose={onNudgeDismiss}
        />
      )}
    </>
  );
});

export default LocalNudgeBar;
