import {
  emptyReportRunSetSelectAll,
  emptyReportRunSetSelectNone,
} from '../../../util/section';
import {updateIn} from '../../../util/utility';
import {addObjImmutable} from '../normalize';
import {lookupPart} from '../normalizerSupport';
import type {ActionType, ViewReducerState} from '../reducerSupport';
import * as Types from '../types';
import * as RunSetActions from './actions';
import {Ref} from './types';

function updatePart<RefType extends Types.AllPartRefs>(
  state: ViewReducerState,
  ref: RefType,
  updater: (
    v: Types.PartFromType<RefType['type']>
  ) => Types.PartFromType<RefType['type']>
): ViewReducerState {
  return updateIn<any>(state, ['parts', ref.type, ref.id], (v: any) =>
    updater(v)
  );
}

function updatePartsInViewstate(
  state: ViewReducerState,
  newParts: ViewReducerState['parts']
): ViewReducerState {
  return {
    ...state,
    parts: {
      ...state.parts,
      ...newParts,
    },
  };
}

export function addNewRunSet(
  state: ViewReducerState,
  ref: Ref
): [ViewReducerState, ActionType] {
  const section = lookupPart(state.parts, ref);
  const runSet =
    section.runSetRefs.length === 0
      ? emptyReportRunSetSelectAll()
      : emptyReportRunSetSelectNone();
  const count = section.runSetRefs.length;

  const {parts: newParts, ref: partRef} = addObjImmutable(
    state.parts,
    'runSet',
    ref.viewID,
    {
      ...runSet,
      name: `Run set ${count + 1}`,
    }
  );
  const newState = updatePart(
    updatePartsInViewstate(state, newParts),
    ref,
    sect => ({
      ...sect,
      openRunSet: sect.runSetRefs.length,
      runSetRefs: sect.runSetRefs.concat(partRef),
    })
  );

  return [newState, RunSetActions.removeRunSet(ref, partRef)];
}
