import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {InstrumentedFloatingWaveLoader} from './../components/utility/InstrumentedLoader';
import {envIsDev} from './../config';

type AppLoaderContextType = {
  addLoader: (name: string) => void;
  removeLoader: (name: string) => void;
};
const AppLoaderContext = React.createContext<AppLoaderContextType>({
  addLoader: () => {},
  removeLoader: () => {},
});

export const AppLoaderContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loaders, setLoaders] = useState<string[]>([]);

  const addLoader = useCallback((name: string) => {
    setLoaders(prev => [...prev, name]);
  }, []);

  const removeLoader = useCallback((name: string) => {
    setLoaders(prev => prev.filter(n => n !== name));
  }, []);

  const value = useMemo(
    () => ({
      addLoader,
      removeLoader,
    }),
    [addLoader, removeLoader]
  );

  return (
    <AppLoaderContext.Provider value={value}>
      {loaders.length > 0 ? (
        <InstrumentedFloatingWaveLoader
          name="floating-loader"
          samplingRate={0}
          size="huge"
        />
      ) : null}
      {children}
    </AppLoaderContext.Provider>
  );
};

const debugLoaderProxy = envIsDev && false;

export const LoaderProxy = ({name}: {name: string}) => {
  const {addLoader, removeLoader} = useContext(AppLoaderContext);
  useEffect(() => {
    const mount = performance.now();
    addLoader(name);
    return () => {
      const duration = performance.now() - mount;
      if (debugLoaderProxy) {
        console.log(`${name} unmounted in ${duration}ms`);
      }
      removeLoader(name);
    };
  }, [addLoader, removeLoader, name]);
  return null;
};
