import {ActionType as TSActionType, createAction} from 'typesafe-actions';

import {Ref} from './types';

export const setHighlight = createAction(
  '@interactionState/setHighlight',
  action => (axis: string, value: string | number | undefined) =>
    action({axis, value})
);

export const setHighlights = createAction(
  '@view/interactionState/setHighlights',
  action =>
    (
      highlights: Array<{
        axis: string;
        value: string | number | undefined;
      }>
    ) =>
      action({highlights})
);

export const setPanelSelection = createAction(
  '@interactionState/setPanelSelection',
  action => (value: Ref[]) => action({value})
);

export type ActionType = TSActionType<
  typeof setPanelSelection | typeof setHighlights | typeof setHighlight
>;
