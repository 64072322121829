import {envIsLocal, urlPrefixed} from './config';

export default async function install() {
  // going to test the SW in prod for a while before we put it in local
  // just to be extra cautious
  if ('serviceWorker' in navigator && !envIsLocal) {
    try {
      // NOTE: I had to figure this out by trial and error, so for posterity:
      //
      // * a service worker registered at the root of the page can control all
      //   requests -- even to other domains
      //
      // * a service worker registered anywhere else, or with any other scope,
      //   just doesn't work and doesn't intercept ANYTHING, as far as I can
      //   tell.
      //
      // The docs say you should be able to register a SW at a subpath and
      // control the subpath (`/foo/sw.js` to control `/foo/*`) or set a
      // narrower scope to limit control (`/sw.js` with scope `/foo` to
      // control `/foo/*`). I have never gotten either to work under any
      // conditions, in Chrome or Firefox.
      //
      // So that means you have to intercept *everything*, and then use
      // the `fetch` event in the worker to decide what to handle. If you
      // don't call event.respondWith(), it falls through and is handled
      // normally (though it's still shown as handled by the SW in dev tools).
      const registration = await navigator.serviceWorker.register(
        urlPrefixed('/sw.js')
      );
      if (registration.installing) {
        console.log('Service worker installing');
      } else if (registration.waiting) {
        console.log('Service worker installed');
      } else if (registration.active) {
        console.log('Service worker active');
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
}
