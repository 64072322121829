import produce from 'immer';
import React from 'react';

import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
// eslint-disable-next-line import/no-cycle
import {useSampleAndQueryToTable} from '../../util/panelHelpers';
import type {PanelSpec, TransformConfigOptions} from '../../util/panelTypes';
import {Query} from '../../util/queryTypes';
import {parseExpressions} from '../PanelExpressionOptions';
import {
  getTitleFromConfig,
  ScalarChartConfig,
  scalarChartTransformQuery,
} from './common';
export type {ScalarChartConfig};

const PANEL_TYPE = 'Scalar Chart';

// We don't want the panel infrastructure to do the query for us, since the query
// we make depends on internal state. So we disable the
// outer query, and do our own query inside the panel.
const transformQuerySkip = (
  query: Query,
  config: ScalarChartConfig
): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

const useTableData = (pageQuery: Query, config: ScalarChartConfig) => {
  const query = scalarChartTransformQuery(
    pageQuery,
    config,
    parseExpressions(config.expressions, undefined)
  );
  return useSampleAndQueryToTable(query, pageQuery, config);
};

function transformConfig(
  config: ScalarChartConfig,
  {singleRun}: TransformConfigOptions = {}
): ScalarChartConfig {
  return produce(config, draft => {
    if (singleRun && draft.groupArea !== 'none') {
      draft.groupArea = 'none';
    }
  });
}

export const Spec: PanelSpec<typeof PANEL_TYPE, ScalarChartConfig> = {
  type: PANEL_TYPE,
  // eslint-disable-next-line import/no-cycle -- please fix if you can
  Component: React.lazy(() => import('./Component')),
  getTitleFromConfig,
  transformConfig,
  transformQuery: transformQuerySkip,
  useTableData,

  exportable: {
    image: true,
    csv: true,
    api: true,
  },

  configSpec: {
    chartTitle: {editor: 'string', displayName: 'Chart title'},
    xAxisMin: {editor: 'number', displayName: 'X min'},
    xAxisMax: {editor: 'number', displayName: 'X max'},
  },

  icon: 'panel-number',
};
