import * as React from 'react';

import DOC_URLS from '../../util/doc_urls';
import {isChartAggOption} from '../../util/plotHelpers/chart';
import {ChartAggOption} from '../../util/plotHelpers/chartTypes';
import {LabeledOptionBlock as LabeledOption} from '../elements/LabeledOptionBlock';
import MultiButton from '../elements/MultiButton';
import {type PanelConfig} from './types';

export function AggType({
  config,
  updateConfig,
  options = {
    allowMedian: true,
    defaultGroupAgg: 'mean',
    includeSum: false,
    disabled: false,
  },
}: {
  config: PanelConfig;
  updateConfig: (newConfig: PanelConfig) => void;
  options: {
    allowMedian: boolean;
    defaultGroupAgg: ChartAggOption;
    disabled: boolean;
    includeSum: boolean;
  };
}) {
  const keysAndLabels: Array<[key: string, label: string, condition: boolean]> =
    [
      ['mean', 'Mean', true],
      ['median', 'Median', options.allowMedian],
      ['min', 'Min', true],
      ['max', 'Max', true],
      ['sum', 'Sum', options.includeSum],
    ];
  const chartAggKeys = keysAndLabels.filter(o => o[2]).map(o => o[0]);
  const chartAggLabels = keysAndLabels.filter(o => o[2]).map(o => o[1]);

  return (
    <LabeledOption
      label="Agg"
      helpText="The aggregation calculation for the line or value that displays for a group."
      docUrl={DOC_URLS.compareMetrics + '#grouping'}
      option={
        <MultiButton
          keys={chartAggKeys}
          labels={chartAggLabels}
          selected={config.groupAgg ?? options.defaultGroupAgg}
          disabled={options.disabled}
          onChange={key => {
            if (isChartAggOption(key)) {
              updateConfig({
                groupAgg: key,
              });
            }
          }}
        />
      }
    />
  );
}
