import React, {createContext, useContext} from 'react';

type PanelIDContextType = string;

const PanelIDContext = createContext<PanelIDContextType>('');

/**
 * Panel Refs offer unstable IDs, we need the stable ID to be able to fire events that are tied to the panel
 */
export const PanelIDContextProvider = ({
  children,
  stablePanelId,
}: {
  children: React.ReactNode;
  stablePanelId: string;
}) => {
  return (
    <PanelIDContext.Provider value={stablePanelId}>
      {children}
    </PanelIDContext.Provider>
  );
};

export const useStablePanelId = () => {
  const stablePanelId = useContext(PanelIDContext);
  if (!stablePanelId) {
    console.warn('No stable panel id provided to PanelIdContextProvider.');
  }

  return stablePanelId;
};
