import React, {FC, memo, useEffect} from 'react';

import {datadogRumSetGlobalContext} from '../../../integrations';
import {GALLERY_PATH_SEGMENT} from '../../../routes/paths';
import {isInIframe} from '../../../setup';
import {useViewer} from '../../../state/viewer/hooks';
import {
  useIsAdminModeActiveAndIsNotLocalEnv,
  viewingAs,
} from '../../../util/admin';
import {updateProfilerViewer} from '../../../util/profiler';
import {useBetaFeatureNightModeEnabled} from '../../../util/useBetaFeature';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useNavContext} from '../../NavContextProvider';
import ImpersonatingButton from './ImpersonatingButton';
import NavLeft from './NavLeft';
import NavRight from './NavRight';
import * as S from './SearchNav.styles';

const SearchNav: FC = memo(() => {
  const isNightMode = useBetaFeatureNightModeEnabled();
  const {hidden} = useNavContext();

  useUpdateProfilerViewer();

  // hide the search nav in gallery for mobile
  const currentPath: string = window.location.pathname.split('/')[1] ?? '';
  const isMobileGallery = currentPath === GALLERY_PATH_SEGMENT;
  const isSignup = currentPath === 'signup';
  const showAdminBanner = useIsAdminModeActiveAndIsNotLocalEnv();
  const showImpersonatingBanner = viewingAs() !== '';

  if (hidden) {
    return null;
  }
  // TODO: this component is getting re-rendered on each poll...
  return (
    <S.SearchNav
      $isNightMode={isNightMode}
      showAdminBanner={showAdminBanner}
      showImpersonatingBanner={showImpersonatingBanner}
      className={`search-nav ${
        isMobileGallery ? 'hide-in-mobile-gallery' : ''
      }`}
      id="searchNav">
      {!isInIframe() && (
        <S.ExperimentalTestBar
          id="search-nav-experimental-test-bar"
          showAdminBanner={showAdminBanner}
          showImpersonatingBanner={showImpersonatingBanner}
        />
      )}
      <NavLeft />
      {showImpersonatingBanner && <ImpersonatingButton />}
      <NavRight isSignup={isSignup} />
    </S.SearchNav>
  );
});
SearchNav.displayName = 'SearchNav';

export default SearchNav;

function useUpdateProfilerViewer(): void {
  const viewer = useViewer();
  const {
    params: {entityName, projectName},
  } = useNavContext();

  useEffect(() => {
    updateProfilerViewer(entityName, projectName, viewer?.admin);
    datadogRumSetGlobalContext('entityName', entityName);
    datadogRumSetGlobalContext('projectName', projectName);
  }, [entityName, projectName, viewer?.admin]);
}
