import gql from 'graphql-tag';

import {reportsTableFragment} from '../components/ReportsTable';

/**
 * See detail here: https://weightsandbiases.slack.com/archives/C02U6LLHP9S/p1693264384725819
 */
export const TEAM_PAGE_PROJECTS_QUERY_TWO = gql`
  query TeamPageProjectsTwo($entityName: String!, $first: Int = 10) {
    entity(name: $entityName) {
      id
      organizationId
      projects(order: "-created_at", first: $first) {
        edges {
          node {
            id
            name
            entityName
            description
            totalRuns
            lastActive
          }
        }
      }
    }
  }
`;

export const TEAM_PAGE_PROJECTS_QUERY = gql`
  query TeamPageProjects($entityName: String!) {
    entity(name: $entityName) {
      id
      organizationId
      projects(order: "-created_at", first: 100) {
        edges {
          node {
            id
            name
            entityName
            access
            allViews(viewType: "runs", first: 10000) {
              edges {
                node {
                  id
                  ...ReportsTableFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${reportsTableFragment}
`;

export const FETCH_TEAM_PROFILE_PAGE_PROJECTS_BY_IDS = gql`
  query FetchTeamProfilePageProjectsByIDs(
    $entityName: String!
    $projectIDs: [ID!]!
  ) {
    projects(entityName: $entityName, projectIDs: $projectIDs) {
      edges {
        node {
          id
          name
          entityName
          access
          lastActive
          runCount
          user {
            id
            username
          }
        }
      }
    }
  }
`;

export const TEAM_PAGE_QUERY = gql`
  query TeamPage(
    $entityName: String!
    $workflowsAdminType: WorkflowsAdminType!
  ) {
    entity(name: $entityName) {
      available
      claimingEntity {
        id
        name
      }
      id
      isTeam
      members {
        id
        admin
        pending
        email
        username
        name
        photoUrl
        accountType
        apiKey
        role
        memberRole {
          ID
          name
        }
      }
      memberCount
      name
      organizationId
      invites {
        edges {
          node {
            id
            email
            fromUser {
              id
              username
            }
          }
        }
      }
      photoUrl
      # TODO(elaina): fully remove this and rely on project count
      projects(first: 1) {
        edges {
          node {
            id
          }
        }
      }
      projectCount
      readOnly
      workflowsAdmins(adminType: $workflowsAdminType) {
        id
        username
        name
        email
      }
      protectedAliases(adminType: $workflowsAdminType)
    }
  }
`;
