import {WBIcon} from '@wandb/ui';
import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {WBModalButton} from './WBModal.styles';

export const SubscriptionTypeSection = styled.div`
  display: flex;
  direction: column;
  justify-content: space-between;
  align-items: baseline;
`;

export const LimitTypeSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LimitTypeRow = styled.div<{extraPadding?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${props =>
    props.extraPadding ? '6px 8px 6px 24px' : '2px 8px 2px 24px'};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const InfoIcon = styled(WBIcon)`
  padding-left: 4px;
`;

export const ResetButton = styled(WBModalButton)`
  background-color: ${globals.warning};
`;
