import * as _ from 'lodash';

// Compares two arrays for equality. All non-arrays are compared
// with reference-equality
export function deepArrayEqual(
  a1: any,
  a2: any,
  maxDepth: number = Infinity
): boolean {
  if (!_.isArray(a1) || !_.isArray(a2) || maxDepth === 0) {
    return a1 === a2;
  }
  if (a1.length !== a2.length) {
    return false;
  }
  for (let i = 0; i < a1.length; i++) {
    if (!deepArrayEqual(a1[i], a2[i], maxDepth - 1)) {
      return false;
    }
  }
  return true;
}

// this is for checking if the timestamp is in seconds or milliseconds
const TIMESTAMP_SECONDS_THRESHOLD = 99999999999;

export function timestampBefore(a: number, b: number): boolean {
  const ams = convertTimestampToMilliseconds(a);
  const bms = convertTimestampToMilliseconds(b);
  return ams < bms;
}

export function timestampAfter(a: number, b: number): boolean {
  const ams = convertTimestampToMilliseconds(a);
  const bms = convertTimestampToMilliseconds(b);
  return ams > bms;
}

// if the timestamp is in seconds, convert to milliseconds
export function convertTimestampToMilliseconds(timestamp: number) {
  return timestamp <= TIMESTAMP_SECONDS_THRESHOLD
    ? timestamp * 1000
    : timestamp;
}
