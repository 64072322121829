/**
 * Allow resizing a drawer.
 */
import {Colors, TEAL_500} from '@wandb/weave/common/css/globals.styles';
import React, {useEffect} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {useResizer} from '../../util/resize';

type ResizableDrawerProps = {
  startWidth: number;

  minWidth: number;

  // If defined, onSetWidth will be called when a new width of the drawer is set.
  // This allows you to determine whether you want the drawer to "remember" its size.
  onSetWidth?: (width: number) => void;

  children: React.ReactNode;
};

type DragWrapperProps = {
  width: number;
};

const DragWrapper = styled.div<DragWrapperProps>`
  height: 100%;
  width: ${props => `${props.width}px`};
  max-width: calc(100vw - 100px);
`;
DragWrapper.displayName = 'S.DragWrapper';

export const DragHandle = styled.div`
  cursor: ew-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background-color: ${Colors.TRANSPARENT};
  transition: background-color 0.1s linear;
  &:hover,
  &:active {
    background-color: ${TEAL_500};
  }
`;
DragHandle.displayName = 'S.DragHandle';

export const ResizableDrawer = ({
  startWidth,
  minWidth,
  onSetWidth,
  children,
}: ResizableDrawerProps) => {
  const {size, dropSize, cursor, resizing, onMouseDown} = useResizer(
    'left',
    startWidth,
    {
      min: minWidth,
    }
  );
  useEffect(() => {
    if (!resizing && onSetWidth) {
      onSetWidth(size);
    }
  }, [resizing, size, onSetWidth]);

  return (
    <DragWrapper width={dropSize}>
      <DragHandle onMouseDown={onMouseDown} style={{cursor}} />
      {children}
    </DragWrapper>
  );
};
