import {createAction} from 'typesafe-actions';

import * as Types from './types';

export const set = createAction(
  '@view/sort/set',
  action => (ref: Types.Ref, sort: Types.Sort) => action({ref, sort})
);

export type ActionType = ReturnType<typeof set>;
