import {ActionType as TSActionType, getType} from 'typesafe-actions';

import {immerReducer} from '../reducer';
import * as Actions from './actions';

export interface StateType {
  basePollInterval: number;

  serverPollingOK: boolean;
  userPollingOK: boolean;
  userInactive: boolean;
  tabBackgrounded: boolean;

  /**
   * Timestamp of when polling started (i.e. poll interval was first populated).
   * This allows us to compute elapsed time before the first tick is recorded.
   */
  pollingStartedAt: number | null;
  /**
   * Timestamp of the last synchronized tick.
   * If null, it means polling is not active
   * or the first tick hasn't happened yet.
   */
  lastTickedAt: number | null;
}

export type ActionType = TSActionType<typeof Actions>;

function globalReducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case getType(Actions.setServerPollingOK): {
      const {serverPollingOK} = action.payload;
      state.serverPollingOK = serverPollingOK;
      break;
    }
    case getType(Actions.setUserPollingOK): {
      const {userPollingOK} = action.payload;
      state.userPollingOK = userPollingOK;
      break;
    }
    case getType(Actions.setUserInactive): {
      const {userInactive} = action.payload;
      state.userInactive = userInactive;
      break;
    }
    case getType(Actions.setTabBackgrounded): {
      const {tabBackgrounded} = action.payload;
      state.tabBackgrounded = tabBackgrounded;
      break;
    }
    case getType(Actions.setBasePollInterval): {
      const {basePollInterval} = action.payload;
      state.basePollInterval = basePollInterval;
      if (basePollInterval === 0) {
        state.pollingStartedAt = null;
      } else if (!state.pollingStartedAt) {
        state.pollingStartedAt = Date.now();
      }
      break;
    }
    case getType(Actions.tick): {
      state.lastTickedAt = Date.now();
      break;
    }
    case getType(Actions.resetTicker): {
      state.lastTickedAt = null;
      break;
    }
  }
}

export default immerReducer<StateType, ActionType>(globalReducer, {
  serverPollingOK: true,
  userPollingOK: true,
  userInactive: false,
  tabBackgrounded: false,
  basePollInterval: 0,
  pollingStartedAt: null,
  lastTickedAt: null,
});
