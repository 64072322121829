import {MOON_200} from '@wandb/weave/common/css/globals.styles';
import {IconModelOnDark} from '@wandb/weave/components/Icon';
import {IconWeaveLogoGray} from '@wandb/weave/components/Panel2/Icons';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC, ReactNode} from 'react';

import longLine from '../assets/data-long-line.svg';
import trialEnd from '../assets/trial-end.svg';
import {urlPrefixed} from '../config';
import {OnboardingFlowNavDropdown} from './OnboardingFlowNavDropdown';

interface OnboardingFlowSkinningProps {
  dialogContent: ReactNode;
}

const OnboardingFlowSkinning: FC<OnboardingFlowSkinningProps> = ({
  dialogContent,
}) => {
  return (
    <Tailwind>
      <div className="relative flex h-screen w-screen flex-col justify-between overflow-auto bg-moon-900">
        <div className="hidden md:flex">
          <OnboardingFlowNavDropdown />
        </div>
        <div className="flex flex-grow flex-col justify-between md:flex-row">
          <div className="flex h-full flex-grow flex-col justify-between overflow-hidden lg:mb-10">
            <div className="flex h-full w-full flex-col justify-between px-16 py-16 sm:px-40 sm:pt-40 md:px-60 md:pt-60 lg:px-80 lg:pt-80">
              <div className="flex min-w-[250px] items-center gap-8">
                <img
                  src={urlPrefixed(`/logo-transparent.svg`)}
                  alt="Weights & Biases"
                  className="h-20 w-20 sm:h-30 sm:w-30"
                />
                <p className="font-serif text-xl text-white md:text-2xl">
                  Weights & Biases
                </p>
                <div className="flex md:hidden">
                  <OnboardingFlowNavDropdown />
                </div>
              </div>
              <div className="mt-16 flex items-center">
                <div className="flex items-center">
                  <IconModelOnDark color={MOON_200} className="h-20 w-20" />
                  <p className="ml-6 text-base font-bold text-moon-150 sm:text-xl">
                    Models
                  </p>
                </div>

                <div className="ml-32 flex items-center border-l border-moon-500 pl-32">
                  <IconWeaveLogoGray className="h-20 w-20" />
                  <p className="ml-6 text-base font-bold text-moon-150 sm:text-xl ">
                    Weave
                  </p>
                </div>
              </div>
              <div className="mt-16 flex flex-grow flex-col justify-center  md:justify-start">
                <h1 className="font-serif text-3xl leading-snug text-white sm:text-4xl md:mt-80 md:text-5xl">
                  The AI developer platform
                </h1>
                <p className="mt-16 max-w-[300px] font-sans text-sm text-moon-100 sm:mt-32 sm:max-w-full sm:text-2xl md:max-w-[500px] md:text-xl">
                  Build models faster, fine-tune LLMs, develop GenAI
                  applications with confidence, all in one system of record
                  developers are excited to use.
                </p>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <img
                src={longLine}
                alt="Long line"
                className="mb-96 hidden h-[262px] w-full md:flex"
              />
              <img
                src={trialEnd}
                alt="Background Curves"
                className="mb-96 hidden h-[262px] w-[1200px] max-w-none md:flex"
              />
            </div>
          </div>
          <div className="relative w-full min-w-[300px] bg-white px-40 pb-30 pt-30 sm:min-w-0 md:h-full md:w-[500px] md:w-[800px] md:px-60 md:py-[100px] lg:w-[1000px] lg:px-[90px]">
            {dialogContent}
          </div>
        </div>
      </div>
    </Tailwind>
  );
};

export default OnboardingFlowSkinning;
