// The zoom implementation has profilerated thorughout a bunch of components,
// hooks and contexts. It could be greatly simplified by merging all of that.

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {useSharedPanelState} from '../Panel/SharedPanelStateContext';
import {DomainMaybe} from '../vis/types';
import {Range} from './common';
import {usePanelData} from './PanelDataContext';
import {useRunsLinePlotContext} from './RunsLinePlotContext/RunsLinePlotContext';
import {Zoom} from './types';
import {getDomain} from './utils';

type PanelZoomContextType = {
  /**
   * A handler for updating the zoom based on changed panel settings
   */
  handleConfigZoomChange: (partialZoomConfig: Zoom) => void;
  /**
   * a handler for updating the zoom based on drag-to-zoom interaction
   */
  handleUserZoomChange: (partialZoomUser: Zoom) => void;
  /**
   * The x domain of the chart - this is the horizontal bounds of the chart
   */
  xDomainChart: DomainMaybe | undefined;
  /**
   * The x domain of the query - sometimes this is different from the chart display because we need to widen the query to fetch more data (See notes on custom x-axis)
   */
  xDomainQuery: Range;
  /**
   * The y domain of the chart, which is the result of combining the user zoom and the config zoom
   */
  yDomain: DomainMaybe | undefined;
  /**
   * The config zoom, which is the result of the user's panel settings
   */
  zoomConfig: Zoom;
};

const PanelZoomContext = createContext<PanelZoomContextType>({
  handleConfigZoomChange: (newConfig: Zoom) => {},
  handleUserZoomChange: (newConfig: Zoom) => {},
  xDomainChart: undefined,
  xDomainQuery: {
    min: null,
    max: null,
  },
  yDomain: undefined,
  zoomConfig: {},
});

function safeQueryZoom(
  zoomMin: number | null | undefined,
  zoomMax: number | null | undefined
): [number | null, number | null] {
  const min =
    zoomMin != null && Number.isFinite(zoomMin) ? Math.floor(zoomMin) : null;
  const max =
    zoomMax != null && Number.isFinite(zoomMax) ? Math.ceil(zoomMax) : null;

  return [min, max];
}

/**
 * This context splits out the "zoom" state for a chart. A "zoom" is made up of either:
 * a) explicit x/y domain settings set by a user in the panel settings
 * b) a drag-to-zoom interaction on the chart
 * These are important because zooming MUST retrigger data fetching on a chart since users know that below a minimum range (1500 points) their data should be exact. If a refetch of data is not triggered, users will see missing points in the chart.
 *
 * Also, it is assumed that a user setting explicit chart values intendeds to preserve those values across sessions (so they're written to the view spec). Drag-to-zoom interactions are assumed to be ephemeral and not written to the view spec.
 *
 * Note: when there is not an active zoom, the chart handles a "smart zoom" where it will automatically adjust its ranges to fit the data given. The PR that introduced this change assumes that charts should never automatically adjust themselves if a user has set explicit zoom values, so we don't worry about handling a user zooming into an empty chart area.
 *
 * This is split out from other interactions to avoid triggering a context update that thrashes the chart.
 * https://weightsandbiases.slack.com/archives/C050NM2AVMW/p1708731655893969
 */
export const PanelZoomContextProvider = React.memo(
  ({
    children,
    updateConfig,
    xAxisKey,
    zoomConfig,
  }: {
    children: React.ReactNode;
    updateConfig: (config: Zoom) => void;
    xAxisKey: string;
    zoomConfig: {
      xAxisMin?: number;
      xAxisMax?: number;
      yAxisMin?: number;
      yAxisMax?: number;
    };
  }) => {
    const {calculateZoomRange} = usePanelData();
    const {isMonotonic} = useRunsLinePlotContext();

    /**
     * Rules for zooming:
     * 1. A user changing any kind of zoom (user zoom or config zoom) always is the change that's appled to the active zoom calculation
     * 2. If a user clears a user zoom, the panel should revert back to the config zoom
     */
    const {userZoomState, setUserZoomState, setXDomainQuery} =
      useSharedPanelState();

    /**
     * The query zoom is the value that ultimately feeds into the API query. It's the result of taking the last changes to the zoom, and then feeding it through the calculateZoomRange fn that's tied to the history data (for non-_step x-axis metrics). It will default to the stored x-axis range stored in the config for the panel.
     *
     * The central idea is that we need to recalculate the query zoom on any of these two conditions:
     * 1. The user zoom changes (either from a zoom range or from clearing it back null,null)
     * 2. The zoom config changes (such as when a user enters a new xMin, xMax value in the panel config)
     *
     * When this happens we have to translate these new zoom boundaries (which are given in terms of the x-axis unit) back into _step units that we can query the API with. For way that we do that is inspecting the run history data to find the closest bounding values, and then using the _step values from those points to query the API. The trick is that we have to make sure we don't create a cycle between the history data and the zoom calculation.
     *
     * This is handled by tying to regeneration of the `calculateZoomRange` function to the history data, but then only calling this function when we detect changes to zoom config or user zoom. This lets the fn refresh in the background on new history data but won't regenerate the zoom range until another zoom event is triggered.
     */
    const [queryZoom, setQueryZoom] = React.useState<
      [null | number, null | number]
    >(
      safeQueryZoom(
        zoomConfig.xAxisMin ?? userZoomState.xAxisMin,
        zoomConfig.xAxisMax ?? userZoomState.xAxisMax
      )
    );

    /**
     * NOTE: updating the zoom config will write it to the view spec where it will then feed back down into the panel through props
     */
    const handleConfigZoomChange = useCallback(
      function updateZoomConfig(partialZoomConfig: Zoom) {
        updateConfig(partialZoomConfig);
      },
      [updateConfig]
    );

    const handleUserZoomChange = useCallback(
      function updateUserZoom(partialZoom: Zoom) {
        // I think it's technically impossible for a user zoom to not include xAxisMin and xAxisMax values [to be confirmed]
        setQueryZoom(safeQueryZoom(partialZoom.xAxisMin, partialZoom.xAxisMax));
        setUserZoomState(panelZoom => mergeZoom(panelZoom, partialZoom));
      },
      [setUserZoomState]
    );

    /// Keep track of the last zoom config and x axis key to trigger a reset
    // of the user zoom when these change.
    const [lastZoomConfig, setLastZoomConfig] = React.useState(zoomConfig);
    const [lastXAxisKey, setLastXAxisKey] = React.useState(xAxisKey);

    // If zoom config or key state are defined and not equal to the last
    // values, reset the user zoom and query zoom.
    if (lastZoomConfig !== zoomConfig || lastXAxisKey !== xAxisKey) {
      setUserZoomState({
        xAxisMax: undefined,
        xAxisMin: undefined,
        yAxisMax: undefined,
        yAxisMin: undefined,
      });
      setQueryZoom(safeQueryZoom(zoomConfig.xAxisMin, zoomConfig.xAxisMax));
      setLastZoomConfig(zoomConfig);
      setLastXAxisKey(xAxisKey);
    }

    const xDomainChart = useMemo(
      () =>
        getDomain(
          userZoomState.xAxisMin ?? zoomConfig.xAxisMin,
          userZoomState.xAxisMax ?? zoomConfig.xAxisMax
        ),
      [userZoomState, zoomConfig]
    );
    const xDomainQuery = useMemo(
      () => {
        if (
          xAxisKey === '_step' ||
          (queryZoom[0] === null && queryZoom[1] === null)
        ) {
          return {
            min: queryZoom[0] ?? null,
            max: queryZoom[1] ?? null,
          };
        }
        const [xMin, xMax] = calculateZoomRange(xAxisKey, queryZoom);
        return {
          min: xMin,
          max: xMax,
        };
      },
      /**
       * we need to disable exhaustive deps here because we explicitly do not want to rerun this calculation when the `calculateZoomRange` fn changes. That function will be updated when the history data changes, which is the result of the query that is executed on changes in the zoom range. If this calculation were to rerun on that change, it would trigger an infinite loop
       */
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [queryZoom, xAxisKey, isMonotonic]
    );

    useEffect(() => {
      setXDomainQuery(xDomainQuery);
    }, [xDomainQuery, setXDomainQuery]);

    const yDomain = useMemo(
      () =>
        getDomain(
          userZoomState.yAxisMin ?? zoomConfig.yAxisMin,
          userZoomState.yAxisMax ?? zoomConfig.yAxisMax
        ),
      [userZoomState, zoomConfig]
    );

    const value = useMemo(() => {
      return {
        handleConfigZoomChange,
        handleUserZoomChange,
        xDomainChart,
        xDomainQuery,
        yDomain,
        zoomConfig,
      };
    }, [
      handleConfigZoomChange,
      handleUserZoomChange,
      xDomainChart,
      xDomainQuery,
      yDomain,
      zoomConfig,
    ]);

    return (
      <PanelZoomContext.Provider value={value}>
        {children}
      </PanelZoomContext.Provider>
    );
  }
);
PanelZoomContextProvider.displayName = 'PanelZoomContextProvider';

/**
 * Merges two zooms together, giving precedence to the new zoom and allowing
 * `undefined` values to overwrite existing values (since a user can reset a
 * config zoom input to be empty)
 */
export function mergeZoom(prevZoom: Zoom = {}, newZoom: Zoom): Zoom {
  // Object.keys() makes sure we write undefined when it's given as a value
  return Object.keys(newZoom).reduce(
    (acc: Zoom, key) => {
      // TODO: figure out how to type this
      // @ts-ignore
      acc[key] = newZoom[key];
      return acc;
    },
    // NOTE: this must be a new object to trigger a context update
    {...prevZoom}
  );
}

export const usePanelZoom = () => {
  const context = useContext(PanelZoomContext);
  return context;
};
