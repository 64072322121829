import {FormControlLabel, Radio} from '@material-ui/core';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Message} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const InputLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${globals.MOON_800};
  margin-bottom: 4px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const StorageTypeControlLabel = styled(FormControlLabel)`
  & input:disabled {
    opacity: 0 !important;
  }
`;

export const StorageTypeLabel = styled.h4`
  margin: 17px 0 0 0 !important;
  font-weight: bold;
`;

export const StorageTypeRadio = styled(Radio)`
  padding-top: 0 !important;
  margin-right: 10px !important;
`;

export const StorageTypeDescription = styled.span`
  display: flex;
`;

export const BucketStorageSelectWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  gap: 3px;
`;

export const StorageBucketNewBucket = styled.div`
  display: flex;
  margin-top: 25px;
`;

export const StorageBucketInputs = styled.div`
  flex-grow: 1;
`;

export const BucketStorageHeader = styled.div`
  color: ${globals.MOON_800};
  width: 100%;
  font-weight: 600;
`;

export const StorageBucketInfoDivider = styled.div`
  border-left: 2px solid ${globals.MOON_250};
  display: flex;
  padding-left: 8px;
  gap: 16px;
`;

export const BucketStorageSelectProvider = styled.span`
  flex: 0 0 auto;
  color: ${globals.MOON_500};
  font-style: italic;
`;

export const BucketStorageMessage = styled(Message).attrs({
  visible: true,
  size: 'tiny',
})`
  padding-top: 14px !important;
  padding-bottom: 14px !important;
`;

export const SeeDocsLink = styled(TargetBlank)`
  display: flex;
  align-items: center;
`;
