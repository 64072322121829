import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Modal} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const AddPaymentModal = styled(Modal)`
  min-height: 100vh;
  padding: 24px;
  min-height: 0;
  &&& {
    width: auto;
  }
  padding: 0;
`;

export const AddPaymentFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 550px;
  width: 320px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
`;

export const Header = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${globals.black};
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
`;
