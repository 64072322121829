import * as VegaLib3 from '../../util/vega3';
import {derived} from '../property-editors/property-editors';
import * as BuiltinPanelDefs from '../Vega2/builtinPanelDefs';

export /* eslint-disable no-template-curly-in-string */
const configSpec = {
  query: {
    editor: 'query' as const,
    default: VegaLib3.defaultRunSetsQuery.queryFields,
    fixedFields: VegaLib3.fixedRunSetsQuery.queryFields,
  },
  vegaSpec: {
    editor: 'vega-spec' as const,
    default: {panelDefId: BuiltinPanelDefs.IDS[1]},
  },
  mapping: {
    editor: 'vega-mapping' as const,
    default: {name: 'runSets_name', value: 'runSets_summary_loss'},
    query: derived((config: any) => config.query),
    vegaSpec: derived((config: any) => config.vegaSpec),
  },
};
