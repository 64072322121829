import * as globals from '@wandb/weave/common/css/globals.styles';
import {hexToRGB} from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

const BEAMER_ICON_SIZE = 22;

export const BeamerIconDiv = styled.div<{absolute?: true}>`
  border-radius: 50%;
  width: ${BEAMER_ICON_SIZE}px;
  height: ${BEAMER_ICON_SIZE}px;
  background-color: ${globals.WHITE};
  color: ${globals.RED_550};
  font-size: 11px;
  font-family: arial;
  text-align: center;
  line-height: ${BEAMER_ICON_SIZE}px;
  font-weight: 700;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;

  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: -3px;
      right: -3px;
    `}
`;

export const BeamerIconBG = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: ${hexToRGB(globals.RED_300, 0.48)};
`;
