import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {makePropsAreEqual} from '@wandb/weave/common/util/shouldUpdate';
import React, {FC, memo, useCallback} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Popup} from 'semantic-ui-react';

import {trackSetting} from '../../services/analytics/workspaceSettingsEvents';
import {RunHistoryKeyInfo, RunHistoryKeyType} from '../../types/run';
import {PanelProps} from '../../util/panelProps';
import {RunsLinePlotConfig} from '../PanelRunsLinePlot/types';
import {useTrackSettingPage} from '../WorkspaceDrawer/Settings/hooks/useTrackSettingEvent';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {MetricsPickerDropdown} from './MetricsPickerDropdown';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {MetricsPickerRegexInput} from './MetricsPickerRegexInput';

export type MetricsPickerProps = {
  options: string[];
  keyTypes: {
    [key: string]: RunHistoryKeyType;
  };
  keyInfo: RunHistoryKeyInfo;
  config: PanelProps<RunsLinePlotConfig>['config'];
  updateConfig: PanelProps<RunsLinePlotConfig>['updateConfig'];
};

export const MetricsPicker: FC<MetricsPickerProps> = memo(
  (props: MetricsPickerProps) => {
    if (props.config.useMetricRegex) {
      return <MetricsPickerRegexInput {...props} />;
    }

    return <MetricsPickerDropdown {...props} />;
  },
  makePropsAreEqual({
    name: 'MetricsPicker',
    deep: ['options', 'keyTypes', 'config'],
  })
);

export const MetricsRegexToggle = ({
  updateConfig,
  config,
}: MetricsPickerProps) => {
  const trackSettingPage = useTrackSettingPage();

  const onButtonClick = useCallback(() => {
    const newVal = !config.useMetricRegex;
    updateConfig({
      useMetricRegex: newVal,
    });

    trackSetting({
      action: 'change',
      page: trackSettingPage,
      settingLevel: 'panel',
      setting: 'yAxis',
      settingValue: newVal.toString(),
      additionalProperties: {
        useMetricRegex: newVal,
      },
    });
  }, [config.useMetricRegex, trackSettingPage, updateConfig]);

  return (
    <Popup
      inverted
      size="mini"
      on="hover"
      position="top right"
      content="Regular Expression"
      trigger={
        <Button
          icon
          toggle
          className={config.useMetricRegex ? ' active' : ''}
          onClick={onButtonClick}>
          <LegacyWBIcon name="regex" />
        </Button>
      }
    />
  );
};
