import {regexMatchHighlight} from '@wandb/weave/common/util/fuzzyMatch';
import {isEmpty} from 'lodash';
import React, {
  memo,
  ReactElement,
  ReactFragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import type {BaseSearchHistoryItem} from '../../components/PanelBank/types';
import {isInJupyterNotebook} from '../../setup';
import {useDebounceState} from '../../util/hooks';
import {searchRegexFromQuery} from '../../util/panelbank';

const PanelSearchInputContext = React.createContext<{
  searchQuery: string; // original query typed by user (not debounced)
  searchHistory: BaseSearchHistoryItem[];
  searchInputCallbackRef(node: HTMLInputElement | null): void;
  onChangeSearchQuery(newSearchQuery: string): void;
  onAddToHistory?(query: string): void;
}>({
  searchQuery: '',
  searchInputCallbackRef: () => {},
  onChangeSearchQuery: () => {},
  onAddToHistory: (query: string) => {},
  searchHistory: [],
});
PanelSearchInputContext.displayName = 'PanelSearchInputContext';

const PanelSearchDebouncedContext = React.createContext<{
  isSearching: boolean;
  debouncedSearchQuery: string;
  debouncedSearchRegex: RegExp | null;
  getIsSearchMatch: (test: string) => boolean;
  makeHighlightSearchTitle?: (title: string) => ReactFragment;
}>({
  isSearching: false,
  debouncedSearchQuery: '',
  debouncedSearchRegex: null,
  getIsSearchMatch: () => false,
});
PanelSearchDebouncedContext.displayName = 'PanelSearchDebouncedContext';

type Props = {
  children: ReactElement;
  savedSearch?: string;
  searchHistory?: BaseSearchHistoryItem[];
  onDebouncedSearch?: (newSearch: string) => void;
  onAddToHistory?(query: string): void;
};

const PanelSearchContextProviderComp: React.FC<Props> = ({
  children,
  savedSearch = '',
  onDebouncedSearch,
  onAddToHistory,
  searchHistory = [],
}) => {
  // Focus the search input if we're not in a jupyter notebook
  const searchInputCallbackRef = useCallback(
    (node: HTMLInputElement | null) => {
      if (!isInJupyterNotebook() && node != null) {
        node.focus();
      }
    },
    []
  );

  const [searchQuery, debouncedSearchQuery, setSearchQuery] = useDebounceState(
    // restore the user's saved search
    savedSearch,
    100
  );

  // if saved search query (from the spec) is updated from outside of this
  // component (e.g. via undo/redo actions), update local state accordingly
  useEffect(() => {
    setSearchQuery(savedSearch);
  }, [savedSearch, setSearchQuery]);

  const onChangeSearchQuery = useCallback(
    (newSearchQuery: string) => {
      setSearchQuery(newSearchQuery);
      if (newSearchQuery !== savedSearch) {
        onDebouncedSearch?.(newSearchQuery);
      }
    },
    [onDebouncedSearch, savedSearch, setSearchQuery]
  );

  const inputState = useMemo(
    () => ({
      searchInputCallbackRef,
      searchQuery, // original query typed by user (not debounced). used for display.
      onChangeSearchQuery,
      onAddToHistory,
      searchHistory,
    }),
    [
      searchInputCallbackRef,
      searchQuery,
      onChangeSearchQuery,
      onAddToHistory,
      searchHistory,
    ]
  );
  const trimmedDebouncedSearchQuery = debouncedSearchQuery.trim();
  const isSearching = !isEmpty(trimmedDebouncedSearchQuery);
  const debouncedSearchRegex = useMemo(
    () => searchRegexFromQuery(trimmedDebouncedSearchQuery),
    [trimmedDebouncedSearchQuery]
  );
  const getIsSearchMatch = useCallback(
    (testString: string) => {
      if (debouncedSearchRegex == null) {
        // no search query, everything matches
        return true;
      }
      return debouncedSearchRegex.test(testString);
    },
    [debouncedSearchRegex]
  );

  // This takes in a string (e.g. a section title, or panel title)
  // And returns jsx to display the string with the search match letters highlighted
  const makeHighlightSearchTitle = useCallback(
    (title: string) =>
      isSearching ? regexMatchHighlight(title, debouncedSearchRegex) : title,
    [debouncedSearchRegex, isSearching]
  );

  const debouncedState = useMemo(
    () => ({
      debouncedSearchQuery: trimmedDebouncedSearchQuery, // changes a lot, maybe should have its own context
      debouncedSearchRegex,
      isSearching,
      makeHighlightSearchTitle,
      getIsSearchMatch,
    }),
    [
      debouncedSearchRegex,
      getIsSearchMatch,
      isSearching,
      makeHighlightSearchTitle,
      trimmedDebouncedSearchQuery,
    ]
  );

  return (
    <PanelSearchInputContext.Provider value={inputState}>
      <PanelSearchDebouncedContext.Provider value={debouncedState}>
        {children}
      </PanelSearchDebouncedContext.Provider>
    </PanelSearchInputContext.Provider>
  );
};

export const PanelSearchContextProvider = memo(PanelSearchContextProviderComp);

export function usePanelSearchDebouncedContext() {
  return useContext(PanelSearchDebouncedContext);
}

export function usePanelSearchInputContext() {
  return useContext(PanelSearchInputContext);
}
