import React, {FC, memo, useContext} from 'react';

import {envIsPublicCloud} from '../../../config';
import {DEFAULT_ONPREM_ORGANIZATION_NAME} from '../../../pages/OrgDashboard/OrgRoles/RolesDashboard';
import * as URL from '../../../util/urls';
import {useNavContext} from '../../NavContextProvider';
import {useIsGlobalRegistryEnabled} from '../../Registries/common/hooks';
import {AccountSelectorContext} from './AccountSelectorContextProvider';
import NavBreadcrumbs from './NavBreadcrumbs';
import {NavBreadcrumbsRegistries} from './NavBreadcrumbs/NavBreadcrumbsRegistries';

const NavLeftDefaultComp: FC = () => {
  const {params, shouldShowBreadcrumbs} = useNavContext();
  const {enableGlobalRegistry} = useIsGlobalRegistryEnabled();
  const {selectedAccount} = useContext(AccountSelectorContext);
  const accountOrgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;
  if (
    params.entityName == null &&
    URL.isOnRegistriesPage() &&
    enableGlobalRegistry
  ) {
    return (
      <NavBreadcrumbsRegistries
        orgName={accountOrgName}
        registryName={params.registryName}
      />
    );
  }
  if (params.entityName == null || !shouldShowBreadcrumbs) {
    return null;
  }

  return <NavBreadcrumbs />;
};

const NavLeftDefault = memo(NavLeftDefaultComp);

export default NavLeftDefault;
