import * as React from 'react';

export const useInfiniteItems = () => {
  const [listItems, setListItems] = React.useState<string[]>([]);

  const addListItems = React.useCallback((newListItems: string[]) => {
    setListItems(oldItems => [...oldItems, ...newListItems]);
  }, []);

  const clearListItems = React.useCallback(() => {
    setListItems([]);
  }, []);

  const isItemLoaded = (itemIndex: number) => {
    return !!listItems[itemIndex];
  };

  return {
    addListItems,
    clearListItems,
    isItemLoaded,
    listItems,
  };
};
export const useInfinitePageDetails = () => {
  const [pageDetails, setPageDetails] = React.useState<{
    hasNextPage: boolean;
    endCursor: string;
  }>({hasNextPage: false, endCursor: ''});

  const updatePageDetails = React.useCallback(
    (hasNextPage: boolean, endCursor: string) => {
      setPageDetails({
        hasNextPage,
        endCursor,
      });
    },
    []
  );

  return {
    pageDetails,
    updatePageDetails,
  };
};
