import {Select} from '@wandb/weave/components/Form/Select';
import {Icon, IconName} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC} from 'react';
import {components, OptionProps, SingleValueProps} from 'react-select';

import {PremiumFeatureTooltip} from '../../../util/accounts/pricing';
import {
  AccessOption,
  getDescriptionForProjectAccess,
  getDisplayNameForProjectAccess,
  getIconForProjectAccess,
} from '../../../util/permissions';

interface ProjectAccessOption {
  value: AccessOption;
  icon: IconName;
  description: string;
  label: string;
  showPremiumFeatureIcon: boolean;
  premiumFeatureEnabled: boolean;
  isDisabled: boolean;
}

interface ProjectAccessOptionsProps {
  selectedProjectAccess: AccessOption | undefined;
  setSelectedProjectAccess: (accessOption: AccessOption) => void;
  projectAccessOptions: AccessOption[];
  isTeamProject: boolean;
  isRestrictedProjectEnabled: boolean;
}

export const ProjectAccessOptions: FC<ProjectAccessOptionsProps> = ({
  selectedProjectAccess,
  setSelectedProjectAccess,
  projectAccessOptions,
  isTeamProject,
  isRestrictedProjectEnabled,
}) => {
  const options = projectAccessOptions.map(o => {
    const isPremiumFeatureEnabled = isRestrictedProjectEnabled && isTeamProject;
    return {
      value: o,
      icon: getIconForProjectAccess(o, isTeamProject),
      description: getDescriptionForProjectAccess(o, isTeamProject),
      label: getDisplayNameForProjectAccess(o, isTeamProject),
      showPremiumFeatureIcon: o === AccessOption.Restricted,
      premiumFeatureEnabled: isPremiumFeatureEnabled,
      isDisabled: o === AccessOption.Restricted && !isPremiumFeatureEnabled,
    };
  });

  const selectedOption = options.find(o => o.value === selectedProjectAccess);

  return (
    <Select<ProjectAccessOption>
      value={selectedOption}
      options={options}
      id="project-access-options"
      placeholder={'Select the visibility for this project'}
      components={{
        Option: ProjectAccessOptionComp,
        SingleValue: ProjectAccessValueComp,
      }}
      onChange={(o: ProjectAccessOption | null) =>
        setSelectedProjectAccess(o?.value ?? AccessOption.Private)
      }
    />
  );
};

const ProjectAccessOptionComp: React.FC<
  OptionProps<ProjectAccessOption, false>
> = props => {
  const {
    label,
    description,
    icon,
    showPremiumFeatureIcon,
    premiumFeatureEnabled,
  } = props.data;

  return (
    <components.Option {...props}>
      <Tailwind>
        <div className="flex items-center">
          <Icon name={icon} />
          <span
            data-test="project-access-option"
            className="ml-8 flex items-center font-medium">
            {label}
            {showPremiumFeatureIcon && (
              <PremiumFeatureTooltip
                isPremiumFeatureDisabled={!premiumFeatureEnabled}
              />
            )}
          </span>
        </div>
        <div className="ml-28">
          <span className="text-sm text-moon-500">{description}</span>
        </div>
      </Tailwind>
    </components.Option>
  );
};

const ProjectAccessValueComp: React.FC<
  SingleValueProps<ProjectAccessOption, false>
> = ({children, ...props}) => {
  const {icon} = props.data;
  return (
    <components.SingleValue {...props}>
      <Tailwind>
        <div className="flex items-center">
          <Icon name={icon} />
          <span data-test="project-access-value" className="ml-6">
            {children}
          </span>
        </div>
      </Tailwind>
    </components.SingleValue>
  );
};
