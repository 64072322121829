import {Select} from '@wandb/weave/components/Form/Select';
import React, {FC} from 'react';

import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import {Label} from './CommonDrawer.styles';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {TeamOption} from './CreateProjectDrawer';
import * as S from './TeamOptions.styles';

interface TeamOptionsProps {
  selectedTeam: TeamOption | null;
  setSelectedTeam: (teamOwner: string) => void;
  teamOptions?: TeamOption[];
  isTeamProject: boolean;
}

export const TeamOptions: FC<TeamOptionsProps> = React.memo(
  ({selectedTeam, setSelectedTeam, teamOptions, isTeamProject}) => {
    const enableAccountSelector = useRampFlagAccountSelector();
    return (
      <>
        <Label>
          {enableAccountSelector && isTeamProject ? 'Team' : 'Owner'}
        </Label>
        <Select<TeamOption>
          value={selectedTeam}
          options={teamOptions}
          placeholder={
            <S.Placeholder>
              Select the {enableAccountSelector ? 'team' : 'owner'} for this
              project
            </S.Placeholder>
          }
          onChange={(option: TeamOption | null) =>
            setSelectedTeam(option?.value ?? '')
          }
        />
      </>
    );
  }
);
