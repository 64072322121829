import {diffInDays} from '@wandb/weave/common/util/time';

import config from '../../config';
import {
  OrganizationSubscriptionStatus,
  OrganizationSubscriptionType,
} from '../../generated/graphql';
import {
  getPrimarySub,
  isDisabledSubscription,
  isExpiredSubscription,
  isExpiringSubscription,
  isNonAcademicOrg,
  isTrialPlanPrimarySubscription,
  shouldEnforceExpiration,
  shouldEnforceTrackingHour,
} from '../../util/accounts/pricing';
import {secondsToHours} from '../../util/date';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {getComputeHourSubscription, ViewerOrg} from '.';

export enum LegacyNudgeBarType {
  StandardComputeHours = 'standard-compute-hours',
  AdvancedComputeHours = 'advanced-compute-hours',
  EnterpriseToStandard = 'enterprise-to-standard',
  EnterpriseToAdvanced = 'enterprise-to-advanced',
  UpgradeNudgeBarForExpiringSubscription = 'upgrade-nudge-bar-for-expiring-subscription',
  ContactUsNudgeBarForExpiringSubscription = 'contact-us-nudge-bar-for-expiraing-subscription',
  ExpiredSubscriptionEnforcementWithUpgrade = 'expired-subscription-enforcement-with-upgrade',
  ExpiredSubscriptionEnforcementWithContactUs = 'expired-subscription-enforcement-with-contact-us',
  DisabledSubscriptionEnforcement = 'disabled-subscription-enforcement',
}

const EXPIRING_NUDGE_BAR_DISPLAY_THRESHOLD = 14;

// TODO: remove this once we finish releasing the enforcement
export const EXPIRED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE = new Date(
  '2022-09-27T17:00:00Z' // 10AM PT September 27th 2022
);
// TODO: remove this after the release of enforcement for disabled subscription
export const DISABLED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE = new Date(
  '2022-10-04T17:00:00Z' // 10AM PT October 4th 2022
);
// we start showing nudge bar when their expiration is within 14 days from today

export type LegacyNudgeBarInfo = {
  type: LegacyNudgeBarType;
  maxComputeHours?: number;
  aggressive?: boolean;
  daysUntilEnforcement?: number;
};

export function getLegacyNudgeBarTypeForViewerOrg(
  org: ViewerOrg
): LegacyNudgeBarInfo | null {
  if (!isNonAcademicOrg(org)) {
    return null;
  }
  const totalComputeHoursInSeconds = org.teams
    .map(t => t.computeHours)
    .reduce((a, b) => a + b, 0);
  const totalOldComputeSeconds = org.teams
    .map(t => t.oldComputeHours)
    .reduce((a, b) => a + b, 0);

  // TODO(haruka): Put getNudgeBarTypeForDisabledSubscription at the top after enforcement
  // Right now, tracked hour enforcement and expired subscription enforcement are more
  // powerful until the release of enforcement for disabled subscription.
  // But after the disabled enforcement, we want to prioritize enforcing with disabled status.
  for (const getLegacyNudgeBarType of [
    getLegacyNudgeBarTypeForComputeHourSub,
    getLegacyNudgeBarTypeForExpiringSubscription,
    getLegacyNudgeBarTypeForExpiredSubscription,
    getLegacyNudgeBarTypeForDisabledSubscription,
    getLegacyNudgeBarTypeForStandardTrialSub,
  ]) {
    const nb = getLegacyNudgeBarType(
      org,
      totalComputeHoursInSeconds,
      totalOldComputeSeconds
    );
    if (nb != null) {
      return nb;
    }
  }

  return null;
}

function getLegacyNudgeBarTypeForComputeHourSub(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number,
  totalOldComputeSeconds: number
): LegacyNudgeBarInfo | null {
  const computeHourSub = getComputeHourSubscription(org);
  if (computeHourSub == null) {
    return null;
  }

  if (!shouldEnforceTrackingHour(computeHourSub)) {
    return null;
  }

  const exemptFromEnforcement =
    computeHourSub.isAutomaticUpgrade &&
    computeHourSub.plan.name.includes('tier_3');

  const maxComputeHours = computeHourSub.privileges.compute_hours as number;
  if (secondsToHours(totalComputeHoursInSeconds) <= maxComputeHours) {
    return null;
  }
  const aggressive =
    secondsToHours(totalOldComputeSeconds) >= maxComputeHours &&
    !exemptFromEnforcement;

  if (computeHourSub.plan.name === 'basic') {
    return {
      type: LegacyNudgeBarType.StandardComputeHours,
      maxComputeHours,
      aggressive,
    };
  }

  return {
    type: LegacyNudgeBarType.AdvancedComputeHours,
    maxComputeHours,
    aggressive,
  };
}

function getLegacyNudgeBarTypeForExpiringSubscription(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number
): LegacyNudgeBarInfo | null {
  const primarySubscription = getPrimarySub(org);

  if (primarySubscription == null) {
    return null;
  }

  // don't need any legacy giant red banners for our new plans
  if (isTrialPlanPrimarySubscription(primarySubscription)) {
    return null;
  }

  if (!shouldEnforceExpiration(primarySubscription)) {
    return null;
  }

  const {isExpiring, daysUntilExpire} =
    isExpiringSubscription(primarySubscription);

  if (!isExpiring) {
    return null;
  }

  if (daysUntilExpire == null) {
    throw new Error(
      'daysUntilExpire should exist when the subscription isExpiring'
    );
  }

  // If they have more than 14 days until their expiration, we don't show the nudge bar
  if (EXPIRING_NUDGE_BAR_DISPLAY_THRESHOLD < daysUntilExpire) {
    return null;
  }

  // if their subscription expires before the release,
  // they actually have daysUntilRelease to get affected by enforcement
  // if their subscription expires after the release,
  // enforcement should happen on their expiration date
  const daysUntilEnforcement = daysUntilExpire;

  // Ideally, we want to use the compute_hours privilege from the subscription
  // but some users don't have this. To avoid more free rideres,
  // we use the hardcoded 5000 tracked hour limit to check if the organization
  // can upgrade their subscription on their own via UI
  const canUpgradeToStarterTier1 =
    totalComputeHoursInSeconds <= config.MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS;

  // if they are on free user led trial and under starter tier 1 tracked hour limit,
  // they can upgrade themselves through UI
  if (
    primarySubscription.subscriptionType ===
      OrganizationSubscriptionType.UserLedTrial &&
    primarySubscription.plan?.name === 'basic' &&
    canUpgradeToStarterTier1
  ) {
    return {
      type: LegacyNudgeBarType.UpgradeNudgeBarForExpiringSubscription,
      daysUntilEnforcement,
    };
  }

  // if they are not on free user led trial or they exceed starter tier 1 limit,
  // they need to contact us
  return {
    type: LegacyNudgeBarType.ContactUsNudgeBarForExpiringSubscription,
    daysUntilEnforcement,
  };
}

function getLegacyNudgeBarTypeForExpiredSubscription(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number
): LegacyNudgeBarInfo | null {
  const primarySubscription = getPrimarySub(org);

  if (primarySubscription == null) {
    return null;
  }

  if (!shouldEnforceExpiration(primarySubscription)) {
    return null;
  }

  const isExpired = isExpiredSubscription(primarySubscription);

  if (!isExpired) {
    return null;
  }

  // Ideally, we want to use the compute_hours privilege from the subscription
  // but some users don't have this. To avoid more free rideres,
  // we use the hardcoded 5000 tracked hour limit to check if the organization
  // can upgrade their subscription on their own via UI
  const canUpgradeToStarterTier1 =
    totalComputeHoursInSeconds <= config.MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS;

  /*
    TODO: Replace the rest of this function with the following return statement
    after the enforcement for expired subscription get released

    if (canUpgradeToStarterTier1) {
      return {type: NudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade}
    }

    return {type: NudgeBarType.ExpiredSUbscriptionEnforcementWithContactUs}
  */

  const daysUntilRelease = diffInDays(
    EXPIRED_SUBSCRIPTION_ENFORCEMENT_RELEASE_DATE,
    new Date()
  );
  const daysUntilEnforcement = daysUntilRelease;

  // if they are on free user led trial and under starter tier 1 tracked hour limit,
  // they can upgrade themselves through UI
  if (
    primarySubscription.subscriptionType ===
      OrganizationSubscriptionType.UserLedTrial &&
    primarySubscription.plan?.name === 'basic' &&
    canUpgradeToStarterTier1
  ) {
    return {
      type: LegacyNudgeBarType.ExpiredSubscriptionEnforcementWithUpgrade,
      daysUntilEnforcement,
    };
  }

  // if they are not on free user led trial or they exceed starter tier 1 limit,
  // they need to contact us
  return {
    type: LegacyNudgeBarType.ExpiredSubscriptionEnforcementWithContactUs,
    daysUntilEnforcement,
  };
}

function getLegacyNudgeBarTypeForDisabledSubscription(
  org: ViewerOrg
): LegacyNudgeBarInfo | null {
  const primarySubscription = getPrimarySub(org);

  if (primarySubscription == null) {
    return null;
  }

  const isDisabled = isDisabledSubscription(primarySubscription);

  if (!isDisabled) {
    return null;
  }

  return {
    type: LegacyNudgeBarType.DisabledSubscriptionEnforcement,
  };
}

function getLegacyNudgeBarTypeForStandardTrialSub(
  org: ViewerOrg,
  totalComputeHoursInSeconds: number
): LegacyNudgeBarInfo | null {
  const oldSub = org.subscriptions.some(sub => {
    return (
      sub.subscriptionType === OrganizationSubscriptionType.UserLedTrial &&
      sub.status === OrganizationSubscriptionStatus.Enabled &&
      sub.expiresAt != null &&
      new Date(sub.expiresAt) <= new Date()
    );
  });
  if (!oldSub) {
    return null;
  }

  if (
    totalComputeHoursInSeconds > config.MAX_STANDARD_COMPUTE_HOURS_IN_SECONDS
  ) {
    // TODO(haruka): rename the type to starterToEnterprise
    return {type: LegacyNudgeBarType.EnterpriseToAdvanced};
  }

  if (totalComputeHoursInSeconds > config.MAX_COMPUTE_HOURS_IN_SECONDS) {
    return {type: LegacyNudgeBarType.EnterpriseToStandard};
  }

  return null;
}
