import {captureError} from '../../../integrations';
import {Analytics} from '../../../services/analytics';
import {CheckoutPlan, Org} from '../../../util/accounts/pricing';

type ErrOpts = {
  err: unknown;
  op: string;
  userFacingErrorMsg: string;
  analyticsEvent?: string;
  extra?: {[key: string]: any};
};
export function handleUpgradeSubError(
  opts: ErrOpts,
  org: Org,
  plan: CheckoutPlan,
  seats: number
): string | null {
  const {err, op, userFacingErrorMsg, analyticsEvent} = opts;
  if (!(err instanceof Error) && typeof err !== 'string') {
    return null;
  }
  const extra = {
    org: org.id,
    plan: plan.id,
    units: seats,
    location: 'new upgrade subscription form',
    ...opts.extra,
  };
  captureError(err, `NewUpgradeSubscriptionForm-${op}`, {
    extra,
  });
  if (analyticsEvent != null) {
    Analytics.track(analyticsEvent, extra);
  }
  return userFacingErrorMsg;
}
