import gql from 'graphql-tag';

import * as Generated from '../../generated/graphql';
import {TagCount} from '../../types/graphql';
import {EMPTY_FILTERS, toMongo} from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as RunTypes from '../../util/runTypes';
import {useQuery} from './query';

export const PROJECT_TAGS_QUERY_2 = gql`
  query ProjectTags2(
    $projectName: String!
    $entityName: String!
    $pattern: String
    $before: String
    $after: String
    $first: Int = 100
    $last: Int
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      runTags(
        pattern: $pattern
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            name
            id
          }
        }
      }
    }
  }
`;
///// Query definition

export const PROJECT_TAGS_QUERY = gql`
  query ProjectTags(
    $projectName: String!
    $entityName: String
    $filters: JSONString
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      tagCounts(filters: $filters) {
        name
        count
      }
    }
  }
`;

// The query's output shape.
interface Data {
  project: {
    tagCounts: TagCount[];
  };
}

///// HOC definition

// These are the extra props required by the HOC. All are passed through to your
// wrapped component.
interface InputProps {
  entityName: string;
  projectName: string;

  filters?: FilterTypes.Filter<RunTypes.Key>;
}

export function useProjectTagsQuery(inputProps: InputProps) {
  const filters = inputProps.filters || EMPTY_FILTERS;
  const variables = {
    entityName: inputProps.entityName,
    projectName: inputProps.projectName,
    filters: JSON.stringify(toMongo(filters)),
  };
  return useQuery<Data, Generated.ProjectTagsQueryVariables>(
    Generated.ProjectTagsDocument,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    }
  );
}
