import * as React from 'react';

import DOC_URLS from '../../util/doc_urls';
import {isChartAreaOption} from '../../util/plotHelpers/chart';
import {ChartAreaOption} from '../../util/plotHelpers/chartTypes';
import {LabeledOptionBlock as LabeledOption} from '../elements/LabeledOptionBlock';
import MultiButton from '../elements/MultiButton';
import {type PanelConfig} from './types';

export const AggRange = ({
  config,
  updateConfig,
  includeSamples = false,
  disabled = false,
}: {
  config: PanelConfig;
  updateConfig: (newConfig: PanelConfig) => void;
  includeSamples: boolean;
  disabled: boolean;
}) => {
  const chartAreaKeys: ChartAreaOption[] = includeSamples
    ? ['minmax', 'stddev', 'stderr', 'samples', 'none']
    : ['minmax', 'stddev', 'stderr', 'none'];
  const chartAreaLabels = includeSamples
    ? ['Min/Max', 'Std Dev', 'Std Err', 'Samples', 'None']
    : ['Min/Max', 'Std Dev', 'Std Err', 'None'];

  return (
    <LabeledOption
      label="Range"
      helpText="The aggregation calculation for the range that displays for a group."
      docUrl={DOC_URLS.compareMetrics + '#grouping'}
      option={
        <MultiButton
          keys={chartAreaKeys}
          labels={chartAreaLabels}
          selected={config.groupArea ?? 'minmax'}
          disabled={disabled}
          onChange={key => {
            if (isChartAreaOption(key)) {
              updateConfig({
                groupArea: key,
              });
            }
          }}
        />
      }
    />
  );
};
