import {KeyboardEventHandler, useCallback, useEffect, useState} from 'react';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useNavContext, useNavUpdaterContext} from '../NavContextProvider';
import {useSearchNavAnalytics} from '../Search/useSearchNavAnalytics';
import {ActivationMethods} from './../../services/analytics/types';
import globalHistory from './../../util/history';
import {hotkeyPressed} from './../../util/hotkeys';

export function useAppSearchGlobalKeyboardControls(): void {
  const {searchActive} = useNavContext();
  const {toggleSearch, deactivateSearch} = useNavUpdaterContext();
  const {trackSearchNavOnScreen, trackAppLauncherOnScreen} =
    useSearchNavAnalytics();

  const handleGlobalKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!hotkeyPressed(e, 'appSearch')) {
        return;
      }
      if (!searchActive) {
        trackSearchNavOnScreen(ActivationMethods.KeyboardHotkey);
        trackAppLauncherOnScreen(ActivationMethods.KeyboardHotkey);
      }
      toggleSearch();
    },
    [
      searchActive,
      trackSearchNavOnScreen,
      trackAppLauncherOnScreen,
      toggleSearch,
    ]
  );

  const handleEscapePress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        deactivateSearch();
      }
    },
    [deactivateSearch]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyDown);
    document.addEventListener('keydown', handleEscapePress);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [handleGlobalKeyDown, handleEscapePress]);
}

type AppSearchInnerKeyboardControlsParams = {
  searchTerm: string;
  currentResultCount: number;
};

type AppSearchInnerKeyboardControls = {
  onKeyDown: KeyboardEventHandler;
};

export function useAppSearchInnerKeyboardControls({
  searchTerm,
  currentResultCount,
}: AppSearchInnerKeyboardControlsParams): AppSearchInnerKeyboardControls {
  const {trackAppLauncherUsed, trackAppLauncherFailure} =
    useSearchNavAnalytics();
  const [selection, setSelection] = useState(0);

  const onKeyDown: KeyboardEventHandler = useCallback(
    e => {
      if (e.key === 'Enter') {
        const links = document.querySelectorAll<HTMLAnchorElement>(
          '#launcher-option-trap > a'
        );
        if (links.length > 0) {
          const details = links[selection];
          // we don't need to track app launcher use here since the `onclick` handler on each
          // child link will track it
          details.click();
        } else if (searchTerm.startsWith('/')) {
          trackAppLauncherUsed(searchTerm, 'direct-navigation');

          globalHistory.push(encodeURI(searchTerm.trim()));
        } else {
          trackAppLauncherFailure(searchTerm);
        }
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (selection < currentResultCount - 1) {
          setSelection(selection + 1);
        }
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (selection > 0) {
          setSelection(selection - 1);
        }
      }
    },
    [
      selection,
      searchTerm,
      currentResultCount,
      trackAppLauncherUsed,
      trackAppLauncherFailure,
    ]
  );

  // update the selection value if the num of results changes outside the selection
  useEffect(() => {
    if (currentResultCount === 0) {
      setSelection(0);
    } else if (currentResultCount > 0 && selection > currentResultCount - 1) {
      setSelection(currentResultCount - 1);
    }
  }, [currentResultCount, selection]);

  return {onKeyDown};
}
