import {createContext, useContext} from 'react';

import {OrganizationRolesResult} from './useOrgRolesContextResult';

export const OrganizationRolesContext = createContext<
  OrganizationRolesResult | undefined
>(undefined);

export const useOrgRolesContext = (): OrganizationRolesResult => {
  const value = useContext(OrganizationRolesContext);

  if (value == null) {
    throw new Error(
      'useOrganizationRolesContext must be used within a OrganizationRolesContext.Provider'
    );
  }

  return value;
};
