import {Editor, Node} from 'slate';

import {PanelGrid} from './types';

export const isPanelGrid = (node: Node): node is PanelGrid =>
  node.type === 'panel-grid';

export const withPanelGrids = <T extends Editor>(editor: T) => {
  const {isVoid} = editor;

  editor.isVoid = element => (isPanelGrid(element) ? true : isVoid(element));

  return editor;
};
