import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import * as globals from '@wandb/weave/common/css/globals.styles';
import classNames from 'classnames';
import React from 'react';

import {useSetHighlight} from '../../state/interactState/hooks';
import {getLegendOverrideKey} from '../../util/plotHelpers/legend';
import {markToIconNameMap} from '../LineStylePicker';
import FancyLegend from '../vis/FancyLegend';
import {getTextColor} from './../../util/colors';
import {
  PlotFontSize,
  plotFontSizeToPx,
} from './../../util/plotHelpers/plotFontSize';
import {Line} from './../../util/plotHelpers/types';

type LinePlotLegendProps = {
  children?: React.ReactNode;
  disableRunLinks?: boolean;
  expandLegend?: boolean;
  fontSize: PlotFontSize;
  hiddenLines: Line[];
  isVertical: boolean;
  legendPrefix?: React.Component | JSX.Element;
  lines: Line[];
  singleRun: boolean;
  disallowSelect: boolean;
};
export const LinePlotLegend = React.memo(
  ({
    disableRunLinks,
    fontSize,
    hiddenLines,
    isVertical,
    lines,
    singleRun,
    disallowSelect,
  }: LinePlotLegendProps) => {
    const setHighlightRun = useSetHighlight();
    const fSize = plotFontSizeToPx.legend[fontSize];

    const wrapperClasses = classNames(
      `scrollbar-hide flex flex-shrink-0 flex-wrap gap-x-8 gap-y-2 leading-none`,
      {
        // tailwind requires the classes to be fully built for static analysis
        // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
        'text-[10px]': fSize === 10,
        'text-[12px]': fSize === 12,
        'text-[14px]': fSize === 14,
        'flex-column w-full items-center justify-center': isVertical,
        'flex-row justify-start': !isVertical,
        'select-none': disallowSelect,
      }
    );

    const hiddenLineIds = React.useMemo(
      () => new Set(hiddenLines.map(l => getLegendOverrideKey(l))),
      [hiddenLines]
    );

    return (
      <div className={wrapperClasses} data-test="line-plot-legend">
        {lines.map((line, i) => {
          const hidden = hiddenLineIds.has(getLegendOverrideKey(line));
          const color = !hidden ? line.color : globals.gray500;

          return (
            <div
              className={`flex items-center ${
                isVertical ? '' : 'flex-column basis-full'
              }`}
              key={`${line.uniqueId}-${i}`}>
              <span
                className="line-plot-color flex items-center"
                style={{color}}>
                <LegacyWBIcon name={markToIconNameMap[line.mark ?? 'solid']} />
              </span>
              <span
                className="whitespace-nowrap"
                onMouseOver={() => {
                  if (!singleRun) {
                    setHighlightRun('run:name', line.uniqueId);
                  }
                }}
                onMouseOut={() => {
                  if (!singleRun) {
                    setHighlightRun('run:name', undefined);
                  }
                }}
                style={{color: getTextColor(color)}}>
                {line.fancyTitle &&
                  FancyLegend({
                    ...line.fancyTitle,
                    disableRunLink: disableRunLinks,
                  })}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
);

LinePlotLegend.displayName = 'LinePlotLegend';
