import React, {FC, memo, useContext} from 'react';

import {COUNTRY_CODE_NAME_OPTIONS} from '../../../util/countryCode';
import {
  BillingAddressContext,
  BillingAddressContextUpdater,
} from './BillingAddressContext';
import * as S from './BillingAddressInput.styles';

const BillingAddressInputComp: FC = () => {
  const {line1, line2, country, city, state, postalCode} = useContext(
    BillingAddressContext
  );

  const {setLine1, setLine2, setCity, setCountry, setPostalCode, setState} =
    useContext(BillingAddressContextUpdater);

  const useUSAFormat = country === 'US';

  return (
    <S.BillingAddressWrapper>
      <S.AddressInput
        value={line1}
        placeholder="Street addresses"
        onChange={(e: any) => setLine1(e.target.value as string)}
      />
      <S.AddressInput
        value={line2}
        placeholder="Apt, unit, suite, etc. (optional)"
        onChange={(e: any) => setLine2(e.target.value as string)}
      />
      <S.CountryDropdown
        value={country}
        placeholder="Country"
        search
        options={COUNTRY_CODE_NAME_OPTIONS}
        onChange={(e: any, data: any) => {
          setCountry(data.value as string);
        }}
      />
      <S.AddressInput
        value={city}
        placeholder="City"
        onChange={(e: any) => setCity(e.target.value as string)}
      />
      <S.MultipleAddressInputWrapper>
        <S.AddressInput
          value={state}
          placeholder={useUSAFormat ? 'State' : 'Province / region'}
          onChange={(e: any) => setState(e.target.value as string)}
        />
        <S.AddressInput
          value={postalCode}
          placeholder={useUSAFormat ? 'Zip code' : 'Postal code'}
          onChange={(e: any) => setPostalCode(e.target.value as string)}
        />
      </S.MultipleAddressInputWrapper>
    </S.BillingAddressWrapper>
  );
};
export const BillingAddressInput = memo(BillingAddressInputComp);
