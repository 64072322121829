import {Alert} from '@wandb/weave/components/Alert';
import React from 'react';

import {Skeleton} from '../../../components/Skeleton';
import {SettingsHeader} from '../../Billing/AccountSettings/SettingsTab/SettingsHeader';
import {CustomRoles} from './CustomRoles';
import {useOrgRolesContext} from './OrgRolesContext';
import {PredefinedRoles} from './PredefinedRole';

export const OrgRolesDashboard: React.FC = () => {
  const {data, loading, error} = useOrgRolesContext();
  return (
    <div className="flex max-w-5xl flex-col">
      <SettingsHeader
        title="Roles"
        description="Add a new role to your organization with customized permissions."
      />
      <PredefinedRoles />
      {loading && <Skeleton className="h-96 max-w-5xl" />}
      {error && <Alert severity="error">Error loading custom roles</Alert>}
      {!error && !loading && (
        <CustomRoles roles={data.customRoles} orgName={data.orgName} />
      )}
    </div>
  );
};
