import React from 'react';

import ImageIcon, {ImageIconName} from '../components/ImageIcon';

interface ImageIconHeaderProps {
  icon: ImageIconName;
  text: string;
}

const ImageIconHeader: React.FC<ImageIconHeaderProps> = React.memo(
  ({icon, text}) => {
    return (
      <div className="image-icon-header">
        <ImageIcon name={icon} style={{}} />
        {text}
      </div>
    );
  }
);

export default ImageIconHeader;
