import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Checkbox, Message} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  color: ${globals.MOON_800};
`;

export const ErrorMessage = styled(Message)`
  width: 100%;
`;

export const CheckoutModalForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const MakePrimaryCheckbox = styled(Checkbox)`
  margin: 12px 0 20px 4px;
  color: ${globals.MOON_800};
`;

export const ActionButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

export const UpdateButton = styled(Button)`
  &&&&& {
    background: ${globals.TEAL_500};
    border: none;
    color: ${globals.WHITE};
    width: 100%;
    &:disabled {
      background: ${globals.MOON_200};
    }
  }
`;

export const CancelButton = styled.div`
  color: ${globals.MOON_800};
  font-weight: 600;
  margin-left: 24px;
  &:hover {
    cursor: pointer;
  }
`;

export const CardElementWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  border: 1px solid #dededf;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
`;
