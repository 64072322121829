import {createAction} from 'typesafe-actions';

import {StateType} from './stateType';

export const update = createAction(
  '@expectedPanels/update',
  action => (expectedPanels: StateType) => action({expectedPanels})
);

export const clear = createAction('@expectedPanels/clear');
