import {createAction} from 'typesafe-actions';

export const setCurrentIdToken = createAction(
  'SET_CURRENT_ID_TOKEN',
  resolve => (idToken: string, expiry: number) => resolve({idToken, expiry})
);

export const clearCurrentIdToken = createAction(
  'CLEAR_CURRENT_ID_TOKEN',
  resolve => () => resolve()
);
