import {RunHistoryKeyInfo} from '../../../types/run';

// maximum metrics to get when using regex to pick metrics
export const REGEX_METRIC_MAX_LEN = 100;

export function getTruncatedSelectedMetrics(args: {
  metricRegex: string | undefined;
  keyInfo?: RunHistoryKeyInfo;
  sortedKeys?: string[];
}) {
  return getSelectedMetrics({
    ...args,
    maxMatchedLimit: REGEX_METRIC_MAX_LEN,
  });
}

export function getSelectedMetrics({
  metricRegex,
  keyInfo,
  sortedKeys,
  maxMatchedLimit,
}: {
  metricRegex: string | undefined;
  maxMatchedLimit?: number;
  keyInfo?: RunHistoryKeyInfo;
  sortedKeys?: string[];
}): string[] {
  if (keyInfo == null || metricRegex == null || metricRegex === '') {
    return [];
  }

  let regex: RegExp;
  try {
    regex = new RegExp(metricRegex, 'g');
  } catch {
    return [];
  }

  /**
   * KeyInfo can get big, into the 10,000s of keys.
   * At 20k keys running this .keys().sort() takes 10ms+ each time through the fn so we'll use the previously sorted keys if they're available
   */
  const hasSortedKeys = !!sortedKeys && sortedKeys.length > 0;
  const keys = hasSortedKeys ? sortedKeys : Object.keys(keyInfo.keys).sort();

  const matchedKeys = [];
  /**
   * Because we throttle the results to 100, use a loop to avoid running the regex past the point of usable results
   */
  for (const key of keys) {
    // If we don't reset the regex with regex.lastIndex = 0, the results are wrong.
    // See https://stackoverflow.com/questions/1520800/why-does-a-regexp-with-global-flag-give-wrong-results
    regex.lastIndex = 0;
    if (regex.test(key)) {
      matchedKeys.push(key);
    }
    if (matchedKeys.length === maxMatchedLimit) {
      break;
    }
  }
  return matchedKeys;
}
