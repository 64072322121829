import React, {createContext, FC, useEffect, useMemo, useState} from 'react';

interface BillingAddressContextState {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
  filledAllRequired: boolean;
}

export const BillingAddressContext = createContext<BillingAddressContextState>({
  city: '',
  country: '',
  line1: '',
  line2: '',
  postalCode: '',
  state: '',
  filledAllRequired: false,
});

interface BillingAddressContextUpdaterState {
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setLine1: React.Dispatch<React.SetStateAction<string>>;
  setLine2: React.Dispatch<React.SetStateAction<string>>;
  setPostalCode: React.Dispatch<React.SetStateAction<string>>;
  setState: React.Dispatch<React.SetStateAction<string>>;
}

export const BillingAddressContextUpdater =
  createContext<BillingAddressContextUpdaterState>({
    setCity: () => {},
    setCountry: () => {},
    setLine1: () => {},
    setLine2: () => {},
    setPostalCode: () => {},
    setState: () => {},
  });

const BillingAddressContextProviderComp: FC<{children?: React.ReactNode}> = ({
  children,
}) => {
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [line1, setLine1] = useState<string>('');
  const [line2, setLine2] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [filledAllRequired, setFilledAllRequired] = useState<boolean>(false);

  useEffect(() => {
    const filledAll = [city, country, line1, postalCode, state].every(
      required => required !== ''
    );
    setFilledAllRequired(filledAll);
  }, [city, country, line1, postalCode, state]);

  const contextState = useMemo<BillingAddressContextState>(
    () => ({city, country, line1, line2, postalCode, state, filledAllRequired}),
    [city, country, line1, line2, postalCode, state, filledAllRequired]
  );

  const contextUpdaterState = useMemo<BillingAddressContextUpdaterState>(
    () => ({
      setCity,
      setCountry,
      setLine1,
      setLine2,
      setPostalCode,
      setState,
    }),
    [setCity, setCountry, setLine1, setLine2, setPostalCode, setState]
  );

  return (
    <BillingAddressContext.Provider value={contextState}>
      <BillingAddressContextUpdater.Provider value={contextUpdaterState}>
        {children}
      </BillingAddressContextUpdater.Provider>
    </BillingAddressContext.Provider>
  );
};

export const BillingAddressContextProvider = React.memo(
  BillingAddressContextProviderComp
);
