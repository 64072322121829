import {IconName} from '@wandb/weave/components/Icon';

export function userFlags(user: any) {
  if (user && user.flags) {
    return JSON.parse(user.flags);
  } else {
    return {};
  }
}

export enum AccessOption {
  Restricted = 'RESTRICTED',
  Private = 'PRIVATE',
  Public = 'USER_READ',
  Open = 'USER_WRITE',
}

export const accessOptionMapping = {
  USER_WRITE: AccessOption.Open,
  USER_READ: AccessOption.Public,
  ENTITY_WRITE: AccessOption.Private, // deprecated, same as private
  ENTITY_READ: AccessOption.Private, // deprecated, same as private
  PRIVATE: AccessOption.Private,
  RESTRICTED: AccessOption.Restricted,
} as const;

export type AccessOptions = keyof typeof accessOptionMapping;

export function getProjectAccess(accessOption: AccessOptions): AccessOption {
  return accessOptionMapping[accessOption];
}

export function getDisplayNameForProjectAccess(
  access: AccessOption,
  isTeamProject: boolean
): string {
  switch (access) {
    case AccessOption.Restricted:
      return 'Restricted';
    case AccessOption.Private:
      return isTeamProject ? 'Team' : 'Private';
    case AccessOption.Public:
      return 'Public';
    case AccessOption.Open:
      return 'Open';
  }
}

export function getDescriptionForProjectAccess(
  access: AccessOption,
  isTeamProject: boolean
): string {
  switch (access) {
    case AccessOption.Restricted:
      return 'Only invited members can access this project. Public sharing is disabled.';
    case AccessOption.Private:
      return `Only ${
        isTeamProject ? `your team` : `you`
      } can view and edit this project.`;
    case AccessOption.Public:
      return `Anyone can view this project. Only ${
        isTeamProject ? `your team` : `you`
      } can edit.`;
    case AccessOption.Open:
      return `Anyone can submit runs or reports (intended for classroom projects or benchmark competitions).`;
  }
}

export function getIconForProjectAccess(
  access: AccessOption,
  isTeamProject: boolean
): IconName {
  switch (access) {
    case AccessOption.Restricted:
      return 'lock-closed';
    case AccessOption.Private:
      return isTeamProject ? 'users-team' : 'lock-closed';
    case AccessOption.Public:
      return 'lock-open';
    case AccessOption.Open:
      return 'privacy-open';
  }
}

export function getTooltipTextForProjectAccess(
  access: AccessOption,
  isTeamProject: boolean
): string {
  const name = getDisplayNameForProjectAccess(access, isTeamProject);
  const description = getDescriptionForProjectAccess(access, isTeamProject);
  return `${name} Project - ${description}`;
}

export function accessIsPublicOrOpen(accessOption: AccessOptions): boolean {
  return (
    getProjectAccess(accessOption) === AccessOption.Public ||
    getProjectAccess(accessOption) === AccessOption.Open
  );
}

export function accessIsPrivate(accessOption: AccessOptions): boolean {
  return getProjectAccess(accessOption) === AccessOption.Private;
}
