import * as globals from '@wandb/weave/common/css/globals.styles';
import Color from 'color';
import * as d3 from 'd3';
import produce from 'immer';
import _ from 'lodash';
import React from 'react';
import {ColorResult, SketchPicker} from 'react-color';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Accordion, Icon} from 'semantic-ui-react';

import {COLORS16, RGBA} from '../util/colors';

export type D3Gradient =
  | 'inferno'
  | 'magma'
  | 'viridis'
  | 'plasma'
  | 'cool'
  | 'warm'
  | 'rainbow'
  | 'sinebow';

export const DEFAULT_GRADIENT = {
  type: 'd3gradient',
  gradient: 'plasma',
} as WBGradient;

export interface GradientStop {
  offset: number;
  color: string;
}

export type CustomGradient = GradientStop[];

export type WBGradient =
  | {type: 'd3gradient'; gradient: D3Gradient}
  | {type: 'customGradient'; gradient: CustomGradient};

const D3_GRADIENTS = [
  {name: 'inferno' as D3Gradient, scale: d3.interpolateInferno},
  {name: 'magma' as D3Gradient, scale: d3.interpolateMagma},
  {name: 'viridis' as D3Gradient, scale: d3.interpolateViridis},
  {name: 'plasma' as D3Gradient, scale: d3.interpolatePlasma},
  {name: 'cool' as D3Gradient, scale: d3.interpolateCool},
  {name: 'warm' as D3Gradient, scale: d3.interpolateWarm},
  {name: 'rainbow' as D3Gradient, scale: d3.interpolateRainbow},
  {name: 'sinebow' as D3Gradient, scale: d3.interpolateSinebow},
];

export const DEFAULT_GRADIENTS: WBGradient[] = [
  // D3 gradient presets
  {
    type: 'd3gradient',
    gradient: 'plasma' as D3Gradient,
  },
  {
    type: 'd3gradient',
    gradient: 'viridis' as D3Gradient,
  },
  // Custom gradient combinations for the presets
  // Blue teal yellow
  {
    type: 'customGradient',
    gradient: [
      {offset: 0, color: '#260090'},
      {offset: 50, color: '#04D6C9'},
      {offset: 100, color: '#FFE600'},
    ],
  },
  // Purple blue green
  {
    type: 'customGradient',
    gradient: [
      {offset: 0, color: '#390166'},
      {offset: 50, color: '#369FFF'},
      {offset: 100, color: '#6FEF7B'},
    ],
  },
  // Red orange yellow
  {
    type: 'customGradient',
    gradient: [
      {offset: 0, color: '#900000'},
      {offset: 50, color: '#D64F04'},
      {offset: 100, color: '#FFE600'},
    ],
  },
  // Blue purple pink
  {
    type: 'customGradient',
    gradient: [
      {offset: 0, color: '#030090'},
      {offset: 50, color: '#B336FF'},
      {offset: 100, color: '#FF5E84'},
    ],
  },
  // Dark purple light
  {
    type: 'customGradient',
    gradient: [
      {offset: 0, color: '#0E0018'},
      {offset: 50, color: '#A141EC'},
      {offset: 100, color: '#E6C6FF'},
    ],
  },
  // Purple blue seafoam
  {
    type: 'customGradient',
    gradient: [
      {offset: 0, color: '#3B0185'},
      {offset: 50, color: '#608BFF'},
      {offset: 100, color: '#A5FFFF'},
    ],
  },
];

export function d3GradientToGradient(d3gradient: D3Gradient): CustomGradient {
  const gradient = D3_GRADIENTS.find(g => g.name === d3gradient);
  if (gradient == null) {
    return [];
  }

  const scale = gradient.scale;

  const customGradient = [] as CustomGradient;
  for (let i = 0; i <= 20; i++) {
    customGradient.push({
      offset: Math.floor((100 * i) / 20),
      color: scale(i / 20),
    });
  }

  return customGradient;
}

export function gradientToRGBAValues(
  stops: GradientStop[],
  length: number
): RGBA[] {
  let leftStop = stops[0];
  let rightStop = stops[1];
  let rightStopIndex = 1;
  const rgbaValues: RGBA[] = [];
  for (let i = 0; i < length - 1; i++) {
    const curOffset = i / (length - 1);
    while (
      stops[rightStopIndex] != null &&
      stops[rightStopIndex].offset <= 100 * curOffset
    ) {
      rightStopIndex += 1;
    }
    if (stops[rightStopIndex] == null) {
      break;
    }
    leftStop = stops[rightStopIndex - 1];
    rightStop = stops[rightStopIndex];
    const dist =
      (100 * curOffset - leftStop.offset) /
      (rightStop.offset - leftStop.offset);

    const {r, g, b, opacity} = d3.rgb(
      d3.interpolateRgb(leftStop.color, rightStop.color)(dist)
    );

    rgbaValues.push([r, g, b, opacity]);
  }
  const lastColor = d3.rgb(stops[stops.length - 1].color);
  rgbaValues.push([lastColor.r, lastColor.g, lastColor.b, lastColor.opacity]);
  return rgbaValues;
}

export const minMaxToGradient = (minColor: string, maxColor: string) => {
  const customGradient = [] as CustomGradient;
  customGradient.push({offset: 0, color: minColor});
  customGradient.push({offset: 100, color: maxColor});
  return customGradient;
};

const limitPos = (offset: number, min: number, max: number) =>
  Math.max(Math.min(offset, max), min);

interface UseDraggingProps {
  onDragStart: (ev: MouseEvent) => void;
  onDrag: (ev: MouseEvent) => void;
  onDragEnd: (ev: MouseEvent) => void;
}

const useDragging = (props: UseDraggingProps) => {
  const {onDragStart, onDrag, onDragEnd} = props;

  const [context, setContext] = React.useState({} as any);
  const [dragging, setDragging] = React.useState(false);

  const handleMouseDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.button) {
      activate(e);
    }
  };

  const activate = (e: any) => {
    setDragging(true);

    onDragStart(e);
  };

  const deactivate = () => {
    setDragging(false);

    onDragEnd(context.change);
    setContext({});
  };

  const handleDrag = (e: MouseEvent) => {
    if (!dragging) {
      return;
    }

    context.change = onDrag(e);
  };

  React.useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', deactivate);
    }

    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', deactivate);
    };
  });

  return [handleMouseDown, activate, deactivate];
};

interface StopDraggingProps {
  limits: {min: number; max: number; drop: number};
  stop: GradientStop;
  id: number;
  isActive?: boolean;
  pointX: number;
  initialPos: number;
  onPosChange: (id: number, offset: number) => void;
  onDragEnd: (id: number) => void;
  onDragStart: (id: number) => void;
  onDeleteColor: (id: number) => void;
}

const useStopDragging = (props: StopDraggingProps) => {
  const {id, limits, pointX, initialPos, onPosChange, onDragEnd, onDragStart} =
    props;
  const [posStart, setPosStart] = React.useState(initialPos);

  const handleDrag = (e: MouseEvent) => {
    const {min, max} = limits;
    // Removing if out of drop limit on Y axis.
    // LB Bring back
    /*const top = getColorStopRefTop(colorStopRef);
		if (Math.abs(clientY - top) > limits.drop) {
			return onDeleteColor(id);
		}*/

    // Limit movements
    const limitedPos = limitPos(e.clientX - posStart, min, max);
    onPosChange(id, limitedPos);
  };

  const [handleMouseDown] = useDragging({
    onDragStart: (e: MouseEvent) => {
      setPosStart(e.clientX - pointX);
      onDragStart(id);
    },
    onDrag: handleDrag,
    onDragEnd: () => onDragEnd(id),
  });

  return [handleMouseDown];
};

interface ColorStopProps {
  stop: {
    offset: number;
    color: string;
  };
  id: number;
  isActive?: boolean;
  pointX: number;

  limits: {min: number; max: number; drop: number};
  onPosChange: (id: number, offset: number) => void;
  onDeleteColor: (id: number) => void;
  onDragStart?: (id: number) => void;
  onDragEnd?: (id: number) => void;
}

const ColorStop = (props: ColorStopProps) => {
  const {
    stop,
    id,
    isActive,
    pointX,
    limits,
    onPosChange,
    onDeleteColor,
    onDragStart,
    onDragEnd,
  } = props;
  const [drag] = useStopDragging({
    stop,
    id,
    limits,
    pointX,
    onPosChange,
    onDragStart: onDragStart || ((idx: number) => undefined),
    onDragEnd: onDragEnd || ((idx: number) => undefined),
    onDeleteColor,
    initialPos: pointX,
  });

  const {color} = stop;

  return (
    <div
      className={isActive ? 'color-stop active' : 'color-stop'}
      onMouseDown={drag}
      style={{left: pointX}}>
      <div className="color-block" style={{backgroundColor: color}}></div>
    </div>
  );
};

export interface StopPickerProps {
  width: number;
  height: number;
  colorStops: ColorStopProps[];
  addColorStop: (pointX: number) => void;
}

export const StopPicker = (props: StopPickerProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const handleColorAdd = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    if (ref.current != null) {
      const pointX = e.clientX - ref.current.getBoundingClientRect().left;
      props.addColorStop(pointX);
    }
  };

  return (
    <div
      style={{width: props.width, height: props.height}}
      ref={ref}
      className="stop-picker"
      onMouseDown={handleColorAdd}>
      {props.colorStops.map(stopProps => (
        // eslint-disable-next-line react/jsx-key
        <ColorStop {...stopProps} />
      ))}
    </div>
  );
};

export interface GradientProps {
  className?: string;
  gradient: WBGradient;
  index: number;
  width: number;
  height: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

export const Gradient = (props: GradientProps) => {
  const d3Container = React.useRef(null);

  React.useEffect(() => {
    if (d3Container.current) {
      const width = props.width;
      const height = props.height;
      const svg = d3.select(d3Container.current);
      svg.selectAll('*').remove();

      const defs = svg.append('defs');

      const linearGradient = defs
        .append('linearGradient')
        .attr('id', 'gradient' + props.index);
      const customGradient =
        props.gradient.type === 'd3gradient'
          ? d3GradientToGradient(props.gradient.gradient)
          : _.sortBy(props.gradient.gradient, g => g.offset);
      customGradient.forEach(s => {
        linearGradient
          .append('stop')
          .attr('offset', `${s.offset}%`)
          .attr('stop-color', s.color);
      });

      svg
        .append('rect')
        .classed('filled', true)
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', height)
        .style('fill', `url(#gradient${props.index})`);
    }
  }, [
    props.gradient.gradient,
    props.gradient.type,
    props.gradient,
    props.height,
    props.width,
    props.index,
  ]);
  return (
    <svg
      className={'d3-component ' + (props.className || '')}
      width={props.width}
      height={props.height}
      ref={d3Container}
      onClick={props.onClick}
    />
  );
};

export interface GradientOptionsProps {
  setGradient?: (gradient: WBGradient) => void;
  gradients: WBGradient[];
  width?: number;
  height?: number;
}

const GradientOptions = (props: GradientOptionsProps) => {
  const [selectedOption, setSelectedOption] = React.useState(-1);

  const gradientToPicker = (gradient: WBGradient, i: number) => {
    const name =
      gradient.type === 'd3gradient'
        ? gradient.gradient
        : gradient.gradient.map(s => d3.rgb(s.color).r).join('');
    return (
      <div
        key={name}
        onClick={
          props.setGradient != null
            ? () => {
                setSelectedOption(i);
                if (props.setGradient != null) {
                  props.setGradient(gradient);
                }
              }
            : undefined
        }>
        <Gradient
          className={selectedOption === i ? 'selected' : 'not-selected'}
          key={name}
          index={i}
          gradient={gradient}
          width={props.width || 200}
          height={(props.height || 200) / props.gradients.length}
        />
      </div>
    );
  };

  const leftGradients = props.gradients.slice(
    0,
    Math.floor(props.gradients.length / 2)
  );
  const rightGradients = props.gradients.slice(
    Math.floor(props.gradients.length / 2),
    props.gradients.length
  );

  return (
    <div className="columns">
      <div className="column">
        {' '}
        {leftGradients.map((gradient, i) => {
          return gradientToPicker(gradient, i);
        })}
      </div>
      <div className="column">
        {rightGradients.map((gradient, i) => {
          return gradientToPicker(gradient, i + leftGradients.length);
        })}
      </div>
    </div>
  );
};

export interface GradientPickerProps {
  defaultGradient: WBGradient;
  setGradient?: (gradient: GradientStop[]) => void;
}

export const GradientPicker = (props: GradientPickerProps) => {
  const stopPickerWidth = 400;

  const onPosChange = (id: number, offset: number) => {
    setColorStops(curColorStops => {
      const newColorStops = curColorStops.map(s => {
        if (s.id === id) {
          return {
            ...s,
            pointX: offset,
            stop: {...s.stop, offset: (s.pointX * 100) / stopPickerWidth},
          };
        } else {
          return s;
        }
      });
      return newColorStops;
    });
  };

  const onDragStart = (id: number) => {
    setColorStops(curColorStops => {
      const changedStop = curColorStops.find(s => s.id === id);
      curColorStops.forEach(s => (s.isActive = false));
      if (changedStop != null) {
        changedStop.isActive = true;
      }
      return [...curColorStops];
    });
  };

  const onDragEnd = (id: number) => {
    setColorStops(curColorStops => {
      const gradient = curColorStops
        .map(cs => cs.stop)
        .sort((a, b) => a.offset - b.offset);
      setActiveGradient({gradient, type: 'customGradient'});
      if (props.setGradient != null) {
        props.setGradient(gradient);
      }
      return curColorStops;
    });
  };

  const addColorStop = (pointX: number) => {
    setColorStops(curColorStops => {
      const stop = {
        offset: (100 * pointX) / stopPickerWidth,
        color: globals.white,
      };
      const maxIndex = Math.max(...curColorStops.map(s => s.id), 0);
      const newColorStop = {
        ...stopToColorStop(stop, maxIndex + 1),
        isActive: true,
      };

      const newColorStops = _.concat(
        curColorStops.map(s => ({
          ...s,
          isActive: false,
        })),
        [newColorStop]
      );
      return [...newColorStops];
    });
  };

  const stopToColorStop = (stop: GradientStop, i: number) => {
    return {
      stop: {
        offset: stop.offset,
        color: stop.color,
      },
      isActive: false,
      pointX: (stop.offset / 100) * stopPickerWidth,
      id: i,
      key: 'stop' + i,
      limits: {min: 0, max: stopPickerWidth, drop: 30},
      onDragStart,
      onDragEnd,
      onPosChange,

      onDeleteColor: (id: number) => undefined,
    } as ColorStopProps;
  };
  const [activeGradient, setActiveGradient] = React.useState(
    props.defaultGradient
  );

  let defaultStops = [
    {offset: 0, color: '#f00'},
    {offset: 100, color: '#00f'},
  ];
  if (
    props.defaultGradient.type === 'customGradient' &&
    props.defaultGradient.gradient.length < 8
  ) {
    defaultStops = props.defaultGradient.gradient;
  }
  const defaultColorStops = defaultStops.map((stop, i) =>
    stopToColorStop(stop, i)
  );
  const [colorStops, setColorStops] = React.useState(defaultColorStops);
  const activeColorStop = colorStops.find(colorStop => colorStop.isActive);

  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={() => {
          if (activeIndex === 0) {
            setActiveIndex(-1);
          } else {
            setActiveIndex(0);
          }
        }}>
        <Icon name="dropdown" />
        Presets
      </Accordion.Title>
      <Accordion.Content className="preset-picker" active={activeIndex === 0}>
        <div className="preset-gradients">
          <GradientOptions
            gradients={DEFAULT_GRADIENTS}
            height={150}
            width={150}
            setGradient={gradient => {
              if (gradient.type === 'd3gradient') {
                if (props.setGradient != null) {
                  const gradientStops = d3GradientToGradient(gradient.gradient);
                  props.setGradient(gradientStops);
                }
                setColorStops(defaultColorStops);
                setActiveGradient({
                  gradient: gradient.gradient,
                  type: 'd3gradient',
                });
              } else {
                if (props.setGradient != null) {
                  props.setGradient(gradient.gradient);
                }
                const newColorStops = gradient.gradient.map((stop, i) => {
                  return stopToColorStop(stop, i);
                });
                setColorStops(newColorStops);

                setActiveGradient(gradient);
              }
            }}
          />
        </div>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={() => {
          if (activeIndex === 1) {
            setActiveIndex(-1);
          } else {
            if (activeGradient.type === 'd3gradient') {
              setActiveGradient({
                gradient: defaultStops,
                type: 'customGradient',
              });
            }
            setActiveIndex(1);
          }
        }}>
        <Icon name="dropdown" />
        Custom
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <StopPicker
          width={400}
          height={20}
          colorStops={colorStops}
          addColorStop={addColorStop}></StopPicker>
        <div className={'active-gradient'}>
          <Gradient
            gradient={activeGradient}
            width={400}
            height={20}
            index={100}
          />
        </div>
        <div className={'color-picker'}>
          <SketchPicker
            color={
              activeColorStop != null ? activeColorStop.stop.color : undefined
            }
            presetColors={COLORS16}
            onChangeComplete={(color: ColorResult) => {
              const {a, ...colorMinusAlpha} = color.rgb;
              const alpha = a || 0.8;
              const updateColor = Color(colorMinusAlpha).alpha(alpha).string();
              if (activeColorStop) {
                const newColorStops = produce(colorStops, draft => {
                  const modifiedStop = draft.find(
                    s => s.stop.offset === activeColorStop.stop.offset
                  );
                  if (modifiedStop == null) {
                    return;
                  }
                  modifiedStop.stop.color = updateColor;
                });
                setColorStops(newColorStops);
                const gradient = newColorStops.map(colorStop => {
                  return colorStop.stop;
                });

                if (props.setGradient != null) {
                  props.setGradient(gradient);
                }
                setActiveGradient({
                  gradient,
                  type: 'customGradient',
                });
              }
            }}
          />
        </div>
      </Accordion.Content>
    </Accordion>
  );
};
