import {createAction} from 'typesafe-actions';

import {RootState, ThunkResult} from '../../types/redux';
import {Viewer} from '../viewer/types';
import * as ViewActions from '../views/actions';
import * as ViewActionsInternal from '../views/actionsInternal';
import {makeViewListSelector} from '../views/selectors';
import * as ViewThunks from '../views/thunks';
import * as ViewTypes from '../views/types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {loadNamedWorkspaceForUrl} from './namedWorkspacesActions';
import {
  getNamedWorkspaceIdForView,
  viewIsLegacyWorkspace,
  viewIsNamedWorkspace,
} from './names';
import * as Types from './types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as Url from './url';

/* This is called when the user first visits a workspace URL.
 * Responsible for doing initial set up of the redux state and should only
 * be called once.
 */
export function init(
  workspaceStateId: string,
  entityName: string,
  projectName: string,
  viewType: Types.ViewTypes,
  workspaceUrl: Url.WorkspaceUrl,
  defaultSpec: Types.WorkspaceMaterialized,
  relatedId: Types.WsRelatedId
): ThunkResult<Promise<string | void>> {
  return async (dispatch, getState, client) => {
    // Create the loadMetadatalist start action so we can get it's ID.
    const startAction = ViewActions.loadMetadataListStarted({
      entityName,
      projectName,
      viewType,
    });

    // Setup the view first
    dispatch(startAction);
    // Then the workspace redux state
    dispatch(
      initStart(
        workspaceStateId,
        viewType,
        startAction.payload.id,
        defaultSpec,
        relatedId,
        entityName,
        projectName
      )
    );

    const legacyAndNwViews = await dispatch(
      ViewThunks.loadMetadataList(startAction)
    );

    const legacyViews = legacyAndNwViews
      .filter(
        v =>
          viewIsLegacyWorkspace(v.name) &&
          getNamedWorkspaceIdForView(v.name, v.user).relatedId === relatedId
      )
      .reduce((acc: Record<string, ViewTypes.View>, curr) => {
        acc[curr.cid] = curr;
        return acc;
      }, {});

    // loadMetadataList makes network calls, which could hang, and during which we could have
    // already started unloading views. Let's detect and handle that here by making sure
    // the workspaceID we're interested in still exists in redux.
    const state = getState();
    const workspace = state.workspaces[workspaceStateId] as
      | (typeof state)['workspaces'][string]
      | undefined;
    if (workspace == null) {
      return Promise.resolve();
    }

    // We are using legacy views as the source of truth now, so all legacy views are valid cids
    const validViewCids = Object.keys(legacyViews);
    for (const view of legacyAndNwViews) {
      if (viewIsNamedWorkspace(view) && !(view.cid in validViewCids)) {
        // make sure the relatedId matches so we have only views that should be visible.
        // the view type does filtering for other types, but within the sweeps views, you need to
        // also check relatedId
        try {
          const nwId = getNamedWorkspaceIdForView(view.name, view.user);
          if (nwId.relatedId === relatedId) {
            validViewCids.push(view.cid);
          }
        } catch (e) {
          // If it's a malformed view.name, let's just ignore it since it most likely was caused
          // by previous iterations and bugs
          continue;
        }
      }
    }
    await dispatch(newWorkspaceViews(workspaceStateId, validViewCids));

    const viewListSelector = makeViewListSelector(
      entityName,
      projectName,
      viewType
    );
    const viewList = viewListSelector(state);
    return dispatch(
      loadNamedWorkspaceForUrl(
        workspaceStateId,
        workspaceUrl,
        viewList.views,
        viewType
      )
    );
  };
}

export function deinit(workspaceID: string): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState();
    const {viewListID: metadataListID, viewRef} = state.workspaces[workspaceID];
    dispatch(unloadWorkspace(workspaceID));
    if (viewRef != null) {
      dispatch(ViewThunks.unloadView(viewRef.id));
    }
    dispatch(ViewThunks.unloadMetadataList(metadataListID));
    dispatch(ViewActionsInternal.deleteUndoRedoHistory());
  };
}

export function load(
  workspaceID: string,
  cid: string,
  nwId: Types.NamedWorkspaceId | 'legacy'
): ThunkResult<Promise<void>> {
  return (dispatch, getState) => {
    const state = getState();
    const workspace = state.workspaces[workspaceID];
    dispatch(viewLoadStart(workspaceID));
    return dispatch(ViewThunks.load(cid)).then(view => {
      if (view.type !== workspace.viewType) {
        throw new Error(
          `Loaded view's type (${view.type}) didn't match workspace type (${workspace.viewType})`
        );
      }
      dispatch(
        showNewView(workspaceID, {type: workspace.viewType, id: cid}, nwId)
      );
      return Promise.resolve();
    });
  };
}

export function getProjectReadonlyState(
  viewer: Viewer | undefined,
  workspace: Types.ReduxWorkspaceState,
  state: RootState
) {
  const {viewListID, viewType} = workspace;
  if (viewListID == null || viewType == null) {
    throw new Error('Invalid state');
  }
  const viewList = state.views.lists[viewListID];
  let readOnly = false;
  if (viewer == null) {
    readOnly = true;
  } else if (viewList.viewIds.length > 0) {
    // This logic isn't quite right, we only know if the project
    // is readonly if there is at least one workspace saved.
    // TODO: Handle the zero workspace case
    const view0 = state.views.views[viewList.viewIds[0]];
    readOnly = view0.project == null || view0.project.readOnly;
  }
  return readOnly;
}

export function fixView(workspaceID: string): ThunkResult<Promise<void>> {
  return (dispatch, getState) => {
    const workspace = getState().workspaces[workspaceID];
    if (workspace.defaultSpec == null) {
      throw new Error('Invalid state');
    }
    dispatch(update(workspaceID, workspace.defaultSpec));
    return Promise.resolve();
  };
}

export function update(
  workspaceID: string,
  spec: Types.WorkspaceMaterialized
): ThunkResult<void> {
  return (dispatch, getState) => {
    const workspace = getState().workspaces[workspaceID];
    if (workspace.viewRef == null) {
      throw new Error('Unexpected state');
    }
    dispatch(ViewThunks.updateViewSpec(workspace.viewRef.id, spec));
  };
}

// Internal actions

export const initStart = createAction(
  '@workspace/initStart',
  action =>
    (
      id: string,
      viewType: Types.ViewTypes,
      viewListID: string,
      defaultSpec: Types.WorkspaceMaterialized,
      relatedId: Types.WsRelatedId,
      entityName: string,
      projectName: string
    ) =>
      action({
        id,
        viewListID,
        viewType,
        defaultSpec,
        relatedId,
        entityName,
        projectName,
      })
);

export const unloadWorkspace = createAction(
  '@workspace/deinitStart',
  action => (id: string) => action({id})
);

export const viewLoadStart = createAction(
  '@workspace/viewLoadStart',
  action => (id: string) => action({id})
);

export const showNewView = createAction(
  '@workspace/showNewView',
  action =>
    (
      workspaceId: string,
      viewRef: Types.ViewID,
      nwId: Types.NamedWorkspaceId | 'legacy'
    ) =>
      action({workspaceId, nwId, viewRef})
);

export const newWorkspaceViews = createAction(
  '@workspace/newWorkspaceViews',
  action => (workspaceId: string, viewCids: string[]) =>
    action({workspaceId, viewCids})
);

export const removeWorkspaceView = createAction(
  '@workspace/removeWorkspaceView',
  action => (workspaceId: string, cidToRemove: string) =>
    action({workspaceId, cidToRemove})
);

export const displayWorkspaceError = createAction(
  '@workspace/showError',
  action => (workspaceId: string, error: Types.WorkspaceError) =>
    action({workspaceId, error})
);

export type ActionType = ReturnType<
  | typeof initStart
  | typeof unloadWorkspace
  | typeof viewLoadStart
  | typeof showNewView
  | typeof newWorkspaceViews
  | typeof removeWorkspaceView
  | typeof displayWorkspaceError
>;
