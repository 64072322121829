import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {Skeleton} from '../../../components/Skeleton';

const ICON_WIDTH = '24px'; // 1.5rem;
const ICON_HEIGHT = ICON_WIDTH;

export const HomePageSidebarSkeleton = styled(Skeleton)`
  width: 300px;
  max-width: 300px;
  min-height: calc(
    100vh - ${globals.SEARCH_NAV_HEIGHT}
  ); // account for top nav bar

  padding: 16px 12px !important;
  flex-shrink: 0;

  ul {
    padding: 0;
  }

  @media only screen and (max-width: ${globals.TABLET_BREAKPOINT}) {
    min-width: 100vw;
  }
`;

export const SidebarList = styled(List)`
  margin-bottom: 16px !important;
`;

export const SidebarListSubHeader = styled(ListSubheader)`
  padding: 0 12px !important;

  // Override .MuiListSubheader-sticky
  position: unset !important;
`;

export const SidebarListSubHeaderText = styled(ListItemText)`
  padding: 0 !important;
  margin: 0 !important;
  font-weight: calc(${globals.FONT_WEIGHT_STANDARD} * 4); // 400
  font-size: 20px;
  line-height: 40px;
`;

export const AvatarImage = styled(Avatar)`
  width: ${ICON_WIDTH} !important;
  height: ${ICON_HEIGHT} !important;
  img {
    object-fit: contain;
  }
`;

// Lint will complain if we don't pass in `button` prop
export const ProjectSearchInput = styled(ListItem)<{button?: boolean}>`
  padding: 8px 12px !important;
  background-color: unset !important;
  > div {
    width: 100%;
  }

  input {
    width: 100%;
    padding: 0 !important;
    margin-left: 8px;
  }
`;

// Used in TeamsList.tsx
export const SidebarListItemText = styled(ListItemText)`
  margin-left: 8px !important;
  padding: 0 !important;

  overflow: hidden;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// Used in TeamsList.tsx
export const SidebarListItemFolderExpand = styled(ListItem)`
  // Override material UI to have consistent hovering style
  min-height: 36px;
  height: 36px;
  color: ${globals.TEAL_600} !important;
  padding-left: 36px !important;

  &:hover {
    color: ${globals.TEAL_500} !important;
    background-color: unset !important;
  }
`;
