// eslint-disable-next-line wandb/no-deprecated-imports
import {Loader} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const VariantLoader = styled(Loader)`
  && {
    position: relative;
  }
`;
