import {ApolloError} from 'apollo-client';

import {useOrganizationRolePermissionsQuery} from '../../../generated/graphql';

export enum DefaultRoleType {
  ADMIN = 'admin',
  MEMBER = 'member',
  VIEWER = 'viewer',
}

type Role = {
  id: string;
  permissions: Permission[];
};

export type Permission = {
  name: string;
  displayName: string;
  groupName: string;
};

export type DefaultRole = Role & {
  roleType: DefaultRoleType;
};

export type CustomRole = Role & {
  name: string;
  description: string | null;
  inheritedFrom: DefaultRoleType;
};

export type OrganizationRolesResult = {
  loading: boolean;
  error?: ApolloError;
  refetch: () => {};
  data: {
    defaultRoles: DefaultRole[];
    customRoles: CustomRole[];
    orgName: string;
    orgId?: string;
  };
};

export function useOrgRolesContextResult(orgName: string) {
  const {loading, refetch, data, error} = useOrganizationRolePermissionsQuery({
    variables: {
      orgName,
    },
    fetchPolicy: 'cache-and-network',
    skip: orgName === '',
  });

  const roles = data?.organization?.roles ?? [];

  const defaultRoles = roles
    .filter(role => !role.inheritedFrom)
    .map(role => ({
      id: role.ID,
      roleType: role.name as DefaultRoleType,
      permissions: role.permissions,
    }));

  const customRoles = roles
    .filter(role => role.inheritedFrom)
    .map(role => ({
      id: role.ID,
      name: role.name,
      inheritedFrom: role.inheritedFrom?.name as DefaultRoleType,
      description: role.description ?? null,
      permissions: role.permissions,
    }));

  return {
    loading,
    error,
    refetch,
    data: {
      defaultRoles,
      customRoles,
      orgName,
      orgId: data?.organization?.id,
    },
  };
}
