import {Button} from '@wandb/weave/components';
import * as Dialog from '@wandb/weave/components/Dialog';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {maybePluralize} from '@wandb/weave/core/util/string';
import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import {AccountStatusBanner} from '../../../../components/AccountStatusBanner';
import {
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../../../components/Banner';
import {GlobalBannerContext} from '../../../../components/GlobalBanners';
import {AccountSelectorContext} from '../../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {OrganizationDataForFailedPaymentBanner} from '../../../../generated/graphql';
import {isBillingPath, isUserSettingsPath} from '../../../../routes';
import {Analytics} from '../../../../services/analytics';
import {StripeElementsComp} from '../../../../util/accounts/pricing';
import history from '../../../../util/history';
import {createGraphqlOrgId} from '../../../../util/id';
import {useRampFlagAccountSelector} from '../../../../util/rampFeatureFlags';
import {accountSettings} from '../../../../util/urls';
import {AddPaymentMethodModal} from '../../Checkout/AddPaymentMethodModal';
import {InvoicesUnpaidTable} from '../BillingTab/InvoicesUnpaidTable';

// Redirect ach customers who have not paid their pending payment after 30 days
const ACHCustomerAfter30daysOfNoPaymentRedirect = ({
  orgName,
}: {
  orgName: string;
}) => {
  const location = useLocation();

  useEffect(() => {
    const shouldRedirect =
      !isBillingPath(location.pathname) &&
      !isUserSettingsPath(location.pathname);
    if (shouldRedirect) {
      history.push(accountSettings(orgName));
    }
  }, [location.pathname, orgName]);
  return null;
};

export const PaymentFailedBanner = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const {states} = useContext(GlobalBannerContext);

  if (states == null || !enableAccountSelector || selectedAccount == null) {
    return null;
  }

  const currentOrgName = selectedAccount.name;

  // Only show banner if data found for currently selected account
  const organizationStatesToShow = states
    .filter(s => s?.failedPaymentOrgData?.orgName === currentOrgName)
    .map(s => s.failedPaymentOrgData as OrganizationDataForFailedPaymentBanner);

  // If there are no orgs that have failed payments then don't display banners
  if (organizationStatesToShow.length === 0) {
    return null;
  }

  const org = organizationStatesToShow[0];

  let bannerText = ``;
  let buttonContent = null;
  let contactInfo;

  const isACHCustomer = org.isCollectionMethodACH;
  const daysUntilCancellation = org.daysUntilCancellation;
  const shouldRedirectToBillingPage =
    isACHCustomer && daysUntilCancellation < 30;

  if (isACHCustomer) {
    bannerText = `You have a pending payment due for ${org.orgName}. `;

    if (org?.isBillingUser) {
      buttonContent = (
        <Button
          variant="ghost"
          className="hover:text-moon-600"
          onClick={() => {
            setIsPaymentModalOpen(true);
            Analytics.track('See open invoices for ACH customer clicked', {
              location: 'payment failed warning banner',
              organizationName: org,
            });
          }}>
          Pay invoice
        </Button>
      );
      contactInfo = `Complete the payment to continue using Weights & Biases without disruptions.`;
    } else {
      contactInfo = `Contact your admin, ${org.billingEmail}, in order to pay the invoice.`;
    }
  } else {
    bannerText = `Your payment method failed for ${org.orgName}. `;

    if (org?.isBillingUser) {
      buttonContent = (
        <Button
          variant="ghost"
          className="hover:text-moon-600"
          onClick={() => {
            setIsPaymentModalOpen(true);
            Analytics.track('Add Payment Method Clicked', {
              location: 'payment failed warning banner',
              organizationName: org,
            });
          }}>
          Add Payment Method
        </Button>
      );
      contactInfo = `Update your payment method to continue using Weights & Biases without disruption.`;
    } else {
      contactInfo = `Contact your admin, ${org.billingEmail}, to update your payment method to continue using Weights & Biases without disruption.`;
    }
  }

  let daysUntilCancellationText = `Otherwise, your account will be suspended in ${maybePluralize(
    daysUntilCancellation,
    'day'
  )}.`;

  if (isACHCustomer && daysUntilCancellation > 30) {
    daysUntilCancellationText = `Otherwise, your account will be locked out in ${maybePluralize(
      daysUntilCancellation - 30,
      'day'
    )}.`;
  }

  return (
    <>
      {shouldRedirectToBillingPage && (
        <ACHCustomerAfter30daysOfNoPaymentRedirect orgName={currentOrgName} />
      )}
      <div>
        <Tailwind>
          <AccountStatusBanner
            data-test="failed-payment-warning-banner"
            eventData={{
              location: 'failed payment warning banner',
              organizationName: selectedAccount?.name,
            }}>
            <BannerFlexWrapper>
              <Icon name="warning" />
              <BannerTextWrapper>
                <span>
                  <strong>{bannerText}</strong>
                  {contactInfo}
                  {daysUntilCancellation > 0 && (
                    <> {daysUntilCancellationText}</>
                  )}
                </span>
              </BannerTextWrapper>
            </BannerFlexWrapper>
            {buttonContent}
          </AccountStatusBanner>
        </Tailwind>
        {isPaymentModalOpen &&
          (!isACHCustomer ? (
            <StripeElementsComp>
              <AddPaymentMethodModal
                billingUserEmail={org.billingEmail}
                organizationName={org.orgName}
                organizationID={createGraphqlOrgId(org.orgId)}
                shouldDefault={true}
                shouldRetryOpenInvoices={true}
                onTransactionCompleted={() => setIsPaymentModalOpen(false)}
                onClose={() => setIsPaymentModalOpen(false)}
              />
            </StripeElementsComp>
          ) : (
            <Tailwind>
              <Dialog.Root
                open={isPaymentModalOpen}
                onOpenChange={setIsPaymentModalOpen}>
                <Dialog.Portal>
                  <Dialog.Content
                    className="w-1/3 rounded-md bg-white shadow-lg"
                    onInteractOutside={event => {
                      event.preventDefault();
                      setIsPaymentModalOpen(false);
                    }}>
                    <Dialog.Title>
                      <div className="mb-10 flex items-center">
                        <Icon name="warning-alt" className="mr-4" />
                        Pending Payments
                      </div>
                    </Dialog.Title>
                    <Dialog.Description>
                      <InvoicesUnpaidTable
                        orgID={createGraphqlOrgId(org.orgId)}
                        isACHCustomer={isACHCustomer}
                      />
                    </Dialog.Description>
                    <div className="flex justify-end">
                      <Dialog.Close asChild>
                        <Button
                          variant="secondary"
                          onClick={() => setIsPaymentModalOpen(false)}
                          className="mr-2">
                          Close
                        </Button>
                      </Dialog.Close>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </Tailwind>
          ))}
      </div>
    </>
  );
};
