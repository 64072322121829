import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

const StyledChartModal = styled.div`
  @media only screen and (min-width: 1200px) {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 64fr 42fr;
    height: max(520px, 66vh);
    overflow: hidden;
  }
`;

type PanelChartModalProps = {children: React.ReactNode; id?: string};

export const PanelChartModal = ({children, id}: PanelChartModalProps) => {
  return (
    <StyledChartModal
      className="chart-modal-refactor"
      data-test="panel-chart-modal"
      id={id}>
      {children}
    </StyledChartModal>
  );
};
