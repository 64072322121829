import './ProfileMenu.css';

import {toast} from '@wandb/weave/common/components/elements/Toast';
import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';

import {envIsPublicCloud} from '../../../config';
import {useViewer} from '../../../state/viewer/hooks';
import {removeNullUndefinedOrFalse} from '../../../util/utility';
import {AccountSelectorContext} from './AccountSelectorContextProvider';
import {AccountSelectorMenuItem} from './AccountSelectorMenuItem';
import {NavMenuItem} from './NavMenu';
import {Account, AccountType} from './types';
import {useAccountList} from './useAccountList';

export function useAccountSelector(): NavMenuItem[] {
  const viewer = useViewer();
  const {accountList, isLoading: useAccountListLoading} = useAccountList();
  const history = useHistory();
  const {selectedAccount, setSelectedAccount} = useContext(
    AccountSelectorContext
  );

  const orgs: Account[] =
    accountList
      .filter(
        o =>
          o.name !== selectedAccount?.name ||
          o.accountType !== selectedAccount?.accountType // in case there are orgs and personal entities with the same name but different account types, we still want to show it
      )
      .map(o => ({
        name: o.name,
        id: o.id ?? '',
        accountType: o.accountType,
        memberCount: o.memberCount,
        isEnterprise: o.isEnterprise,
        username: o.username,
      })) ?? [];

  if (viewer == null || useAccountListLoading) {
    return [];
  }

  function listAccounts(otherAccountsList: Account[]): NavMenuItem[] {
    return removeNullUndefinedOrFalse(
      otherAccountsList.map(
        account =>
          envIsPublicCloud && {
            content: (
              <AccountSelectorMenuItem
                account={account}
                isSelectedAccount={false}
              />
            ),
            onClick: () => {
              const accountName =
                account?.accountType === AccountType.Personal
                  ? 'Personal'
                  : account?.name;
              setSelectedAccount(account);
              history.push('/home');
              toast('You are now viewing your ' + accountName + ' account.');
            },
          }
      )
    );
  }

  return listAccounts(orgs);
}
