export const getSupplementalMetrics = (
  metricKeys: string[],
  metrics: {
    configMetrics: Record<string, unknown>;
    summaryMetrics: Record<string, unknown>;
  }
) => {
  const supplementalMetrics = metricKeys.reduce((acc, metric) => {
    if (metric.startsWith('config:')) {
      const m = metric.split(':').slice(1).join(':');
      const metricAsConfigValue = `${m}.value`;
      if (metricAsConfigValue in metrics.configMetrics) {
        // note: I have no idea why the config values have this structure
        acc[metric] = metrics.configMetrics[
          metricAsConfigValue
        ] as unknown as number;
      }
    }

    if (metric.startsWith('summary:')) {
      const m = metric.split(':').slice(1).join(':');
      if (m in metrics.summaryMetrics) {
        acc[metric] = metrics.summaryMetrics[m] as unknown as number;
      }
    }

    return acc;
  }, {} as Record<string, number>);

  /**
   * This is a workaround as config metrics get stripped out. This needs a deeper exploration
   */
  Object.entries(metrics.configMetrics).forEach(([key, val]) => {
    if (key.endsWith('.value')) {
      const cleanKey = key.slice(0, -6);
      supplementalMetrics[`config:${cleanKey}`] = val as unknown as number;
    }
  });

  return supplementalMetrics;
};
