import {TailwindContents} from '@wandb/weave/components/Tailwind';
import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {LegendPosition} from '../../util/plotHelpers/legendTypes';
import {InstrumentedWaveLoader} from '../utility/InstrumentedLoader';

type PlotLayoutProps = {
  fontSize: number;
  Graph: React.ReactNode;
  handleHover: (isHovered: boolean) => void;
  Legend: React.ReactNode;
  legendPosition: LegendPosition;
  loading: boolean;
  numLines: number;
  showLegend: boolean;
  Title: React.ReactNode;
  Warning: React.ReactNode;
};

const Wrapper = styled.div<
  Pick<PlotLayoutProps, 'legendPosition' | 'showLegend' | 'fontSize'>
>`
  display: grid;
  height: 100%;
  column-gap: 12px;
  row-gap: 2px;
  position: relative;

  ${({fontSize, legendPosition, showLegend}) => {
    // we show four rows of legend items, plus some space for the row gaps
    const legendHeight = fontSize * 4 + 8;

    if (!showLegend) {
      switch (legendPosition) {
        case 'north':
          return `
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto fit-content(14px) 1fr;
          `;
        case 'south':
          return `
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto fit-content(14px) 1fr;
          `;
        case 'west':
          return `
            grid-template-columns: 1fr;
            grid-template-rows: auto fit-content(14px) 1fr;
          `;
        case 'east':
          return `
            grid-template-columns: 1fr;
            grid-template-rows: auto fit-content(14px) 1fr;
          `;
      }
    }
    switch (legendPosition) {
      case 'north':
        return `
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto fit-content(14px) fit-content(${legendHeight}px) 1fr;
        `;
      case 'south':
        return `
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto fit-content(14px) 1fr fit-content(${legendHeight}px);
        `;
      case 'west':
        return `
          grid-template-columns: minmax(auto, 3fr) 7fr;
          grid-template-rows: auto fit-content(14px) 1fr;
        `;
      case 'east':
        return `
          grid-template-columns: 7fr minmax(auto, 3fr);
          grid-template-rows: auto fit-content(14px) 1fr;
        `;
    }
  }}
`;

const GraphWrapper = styled.div<
  Pick<PlotLayoutProps, 'legendPosition' | 'showLegend'>
>`
  cursor: crosshair;
  height: 100%;
  position: relative;
  width: 100%;
  ${({legendPosition, showLegend}) => {
    if (!showLegend) {
      switch (legendPosition) {
        case 'north':
        case 'south':
          return `
            grid-column: 1 / span 2;
            grid-row: 3;
          `;
        case 'west':
          return `
            grid-column: 1;
            grid-row: 3;
          `;
        case 'east':
          return `
            grid-column: 1;
            grid-row: 3;
          `;
        default:
          return ``;
      }
    }
    switch (legendPosition) {
      case 'north':
        return `
          grid-column: 1 / span 2;
          grid-row: 4;
        `;

      case 'south':
        return `
          grid-column: 1 / span 2;
          grid-row: 3;
        `;
      case 'west':
        return `
          grid-column: 2;
          grid-row: 3;
        `;
      case 'east':
        return `
          grid-column: 1;
          grid-row: 3;
        `;
      default:
        return ``;
    }
  }}
`;
const LegendWrapper = styled.div<Pick<PlotLayoutProps, 'legendPosition'>>`
  overflow: auto;

  ${({legendPosition}) => {
    switch (legendPosition) {
      case 'north':
        return `
          display: flex;
          flex-wrap: wrap;
          grid-column: 1 / span 2;
          grid-row: 3;
        `;
      case 'south':
        return `
          display: flex;
          flex-wrap: wrap;
          grid-column: 1 / span 2;
          grid-row: 4;
        `;
      case 'west':
        return `
          grid-column: 1;
          grid-row: 3;
        `;
      case 'east':
        return `
          grid-column: 2;
          grid-row: 3;
        `;
    }
  }}
`;

export const PlotLayout = ({
  fontSize,
  Graph,
  handleHover,
  Legend,
  legendPosition,
  loading,
  numLines,
  showLegend,
  Title,
  Warning,
  ...props
}: PlotLayoutProps) => {
  // display: contents keeps tailwind from interfering with the CSS grid
  return (
    <TailwindContents>
      <Wrapper
        fontSize={fontSize}
        key="plot-layout"
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        legendPosition={legendPosition}
        showLegend={showLegend}
        {...props}>
        <div
          className="col-span-2 col-start-1 row-start-1 overflow-hidden"
          data-testid="title-wrapper">
          {Title}
        </div>
        <div
          className="col-span-2 col-start-1 row-start-2 flex justify-center overflow-hidden"
          data-testid="warning-wrapper">
          {Warning}
        </div>

        <GraphWrapper legendPosition={legendPosition} showLegend={showLegend}>
          {Graph}
        </GraphWrapper>
        {showLegend && (
          <LegendWrapper legendPosition={legendPosition}>
            {Legend}
          </LegendWrapper>
        )}

        {loading && (
          <>
            {numLines > 0 ? (
              <div className="absolute z-10">
                <InstrumentedWaveLoader
                  size="small"
                  name="panel-layout-loading-small"
                />
              </div>
            ) : (
              <div className="absolute flex h-full w-full items-center justify-center">
                <InstrumentedWaveLoader
                  name="panel-layout-loading-huge"
                  size="huge"
                />
              </div>
            )}
          </>
        )}
      </Wrapper>
    </TailwindContents>
  );
};
