import {rgb} from 'd3-color';
import {AbstractSeries, ScaleUtils} from 'react-vis';

import {inRange} from '../../util/math';

const getAttributeFunctor = ScaleUtils.getAttributeFunctor;
const getScaleObjectFromProps = ScaleUtils.getScaleObjectFromProps;

const DEFAULT_OPACITY = 1;

function getScaleDistance(props: any, attr: any) {
  const scaleObject = getScaleObjectFromProps(props, attr);
  return scaleObject ? scaleObject.distance : 0;
}

export class HeatmapSeriesCanvas extends AbstractSeries<any> {
  static get requiresSVG() {
    return false;
  }

  static get isCanvas() {
    return true;
  }

  static getParentConfig(attr: any) {
    const isDomainAdjustmentNeeded = attr === 'x' || attr === 'y';
    return {isDomainAdjustmentNeeded};
  }

  static renderLayer(props: any, ctx: any) {
    const {data, marginLeft, marginTop} = props;

    if (!data || data.length === 0) {
      return;
    }

    const xFunctor = getAttributeFunctor(props, 'x');
    const yFunctor = getAttributeFunctor(props, 'y');
    const opacityFunctor = getAttributeFunctor(props, 'opacity');
    const fillFunctor =
      getAttributeFunctor(props, 'fill') || getAttributeFunctor(props, 'color');
    const strokeFunctor =
      getAttributeFunctor(props, 'stroke') ||
      getAttributeFunctor(props, 'color');
    const xDistance = getScaleDistance(props, 'x');
    const yDistance = getScaleDistance(props, 'y');

    // don't render data points where the value is 0
    const filteredData = data.filter((d: any) => d.color && d.color > 0);

    filteredData.forEach((d: any) => {
      const fillColor = rgb(fillFunctor(d));
      const strokeColor = rgb(strokeFunctor(d));
      const opacity = opacityFunctor(d) || DEFAULT_OPACITY;

      let width = xDistance;
      let height = yDistance;
      let x = xFunctor(d) - width / 2;
      let y = yFunctor(d) - height / 2;

      const xEnd = x + width;
      const yEnd = y + height;

      // Zooming renders boxes outside of the visible Boundary. If the result is larger the
      // intended region for the graph it can paint outside its intended area.
      // We clip these elements to remove the extra
      if (
        (inRange(x, 0, props.innerWidth) ||
          inRange(xEnd, 0, props.innerWidth)) &&
        (inRange(y, 0, props.innerHeight) ||
          inRange(yEnd, 0, props.innerHeight))
      ) {
        /*
         * Start Clipping Code
         * The canv
         */
        if (x < 0) {
          const absX = Math.abs(x);
          x = 0;
          width = width - absX;
        }

        if (y < 0) {
          const absY = Math.abs(y);
          y = 0;
          height = height - absY;
        }

        if (xEnd > props.innerWidth) {
          const shift = xEnd - props.innerWidth;
          width += shift;
        }

        if (yEnd > props.innerHeight) {
          const shift = yEnd - props.innerHeight;
          height += shift;
        }
        /*
         * End Clipping Code
         */

        // Draw shapes
        ctx.beginPath();
        ctx.rect(x + marginLeft, y + marginTop, width, height);
        ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${opacity})`;
        ctx.fill();
        ctx.strokeStyle = `rgba(${strokeColor.r}, ${strokeColor.g}, ${strokeColor.b}, ${opacity})`;
        ctx.stroke();
      }
    });
  }

  render() {
    return null;
  }
}

// Monkey patch the types
(HeatmapSeriesCanvas as any).displayName = 'HeatmapSeriesCanvas';
(HeatmapSeriesCanvas as any).defaultProps = {
  ...(AbstractSeries as any).defaultProps,
  pixelRatio: (window && window.devicePixelRatio) || 1,
};

(HeatmapSeriesCanvas as any).propTypes = {
  ...(AbstractSeries as any).propTypes,
};
