import {omit} from 'lodash';

import {denormFn, normFn} from '../normalizerSupport';
import * as SectionNormalize from '../section/normalize';
import * as Types from './types';

export const normalize = normFn<Types.ProjectPageObjSchema>(
  'project-view',
  (whole, ctx) => {
    return {
      ...omit(whole, 'section'),
      sectionRef: SectionNormalize.normalize(whole.section, ctx),
    };
  }
);

export const denormalize = denormFn<Types.ProjectPageObjSchema>(
  (part, ctx) => ({
    ...omit(part, 'sectionRef'),
    section: SectionNormalize.denormalize(part.sectionRef, ctx),
  })
);
