import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Input} from 'semantic-ui-react';

import {
  useAttachCustomerPaymentMethodMutation,
  useRetryStripeOpenInvoicesByOrganizationMutation,
  useUpdateCustomerDefaultPaymentMethodMutation,
} from '../../generated/graphql';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../util/errors';
import {InvoicesUnpaidTable} from './AccountSettings/BillingTab/InvoicesUnpaidTable';
import {BillingAddressContext} from './Checkout/BillingAddressContext';
import {BillingAddressInput} from './Checkout/BillingAddressInput';
import * as S from './UpdatePaymentForm.styles';

export interface UpdatePaymentFormProps {
  customerEmail: string;
  organizationId: string;
  shouldDefault: boolean;
  shouldRetryOpenInvoices: boolean;
  onUpdateCompleted(): void;
  onClose(): void;
}

// When the user confirms adding / updating payment, 3 things happen:
// 1. Request to Stripe to create a payment method
// 2. Request to our backend to attach the payment method to the customer
// 3. Request to our backend to update the customer's default payment method
// Step 3 is optional and only happens if the user checks the "Make primary" checkbox
const UpdatePaymentFormComp = (props: UpdatePaymentFormProps) => {
  const [error, setError] = useState<string | null>(null);
  const [custoName, setCustoName] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const {city, country, line1, line2, postalCode, state, filledAllRequired} =
    useContext(BillingAddressContext);

  const updateButtonDisabled = formDisabled || !filledAllRequired;

  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    if (!stripe || !elements) {
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
  }, [elements, stripe, setFormDisabled]);

  const {
    customerEmail,
    organizationId,
    shouldDefault,
    shouldRetryOpenInvoices,
    onUpdateCompleted,
  } = props;
  const [isPrimary, setIsPrimary] = useState(shouldDefault);

  const [attachPaymentMethodMutation] = useAttachCustomerPaymentMethodMutation({
    context: {...propagateErrorsContext(), ...doNotRetryContext()},
  });
  const [updateDefaultPaymentMethodMutation] =
    useUpdateCustomerDefaultPaymentMethodMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  const [retryStripeOpenInvoicesByOrganizationMutation] =
    useRetryStripeOpenInvoicesByOrganizationMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  // 1. Request to Stripe to create a payment method
  const handleCreatePaymentMethod = useCallback(async () => {
    window.analytics?.track('Add Payment Method Started', {
      location: 'update payment form',
      organizationID: organizationId,
    });

    if (!stripe || !elements) {
      throw new Error('Stripe not loaded.');
    }

    if (custoName == null || custoName === '') {
      setError('Must provide cardholder name.');
      return null;
    }

    const cardElement = elements.getElement(CardElement);
    if (cardElement == null) {
      throw new Error('Stripe card element not loaded.');
    }

    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: custoName,
        email: customerEmail,
        address: {
          city,
          country,
          line1,
          line2,
          postal_code: postalCode,
          state,
        },
      },
    });

    if (paymentMethod.error != null || paymentMethod.paymentMethod == null) {
      const errMsg =
        paymentMethod.error?.message ?? 'Error validating payment method.';
      setError(errMsg);
      window.analytics?.track('Add Payment Method Error Viewed', {
        location: 'update payment form',
        organizationId,
        err: errMsg,
      });
      return null;
    }

    window.analytics?.track('Add Payment method Completed', {
      location: 'update payment form',
      organizationId,
      paymentMethodID: paymentMethod.paymentMethod.id,
    });
    return paymentMethod.paymentMethod.id;
  }, [
    organizationId,
    stripe,
    elements,
    custoName,
    customerEmail,
    city,
    country,
    line1,
    line2,
    postalCode,
    state,
  ]);

  // 2. Request to our backend to attach the payment method to the customer
  const handleAttachPaymentMethod = useCallback(
    async (paymentMethodID: string) => {
      window.analytics?.track('Attach Payment Method Started', {
        location: 'update payment form',
        organizationID: organizationId,
        paymentMethodID,
      });

      try {
        const attachPaymentMethodResult = await attachPaymentMethodMutation({
          variables: {
            organizationId,
            paymentMethod: paymentMethodID,
          },
        });

        // attaching payment method succeeded
        if (
          attachPaymentMethodResult.errors == null &&
          attachPaymentMethodResult.data?.attachCustomerPaymentMethod?.success
        ) {
          window.analytics?.track('Attach Payment Method Completed', {
            location: 'update payment form',
            organizationId,
          });
          return true;
        }

        // attaching payment method failed
        const errs = attachPaymentMethodResult.errors?.map(
          ({message}) => message as string
        );
        const errMsg = `Error attaching your payment method${
          errs != null ? `: ${errs.join(', ')}` : ''
        } Please try again.`;

        setError(errMsg);
        window.analytics?.track('Attach Payment Method Error Viewed', {
          location: 'update payment form',
          organizationID: organizationId,
          paymentMethodID,
          err: errMsg,
        });
        return false;
      } catch (err) {
        const msg = extractErrorMessageFromApolloError(err) ?? null;
        const errMsg = `Error attaching payment method${
          msg != null ? `: ${msg}` : ''
        } Please try again.`;
        setError(errMsg);
        window.analytics?.track('Attach Payment Method Error Viewed', {
          location: 'update payment form',
          organizationId,
          paymentMethodID,
          err: errMsg,
        });
        return false;
      }
    },
    [organizationId, attachPaymentMethodMutation]
  );

  // 3. Request to our backend to update the customer's default payment method
  const handleUpdateDefaultPaymentMethod = useCallback(
    async (paymentMethodID: string) => {
      // If the user wants to set the new payment method as primary,
      // we make graphql call to update the payment method
      try {
        window.analytics?.track('Update Default Payment Method Started', {
          location: 'update payment form',
          organizationId,
          paymentMethodID,
        });

        const updateDefaultPaymentMethodResult =
          await updateDefaultPaymentMethodMutation({
            variables: {
              organizationId,
              paymentMethod: paymentMethodID,
            },
          });

        // updating default payment method succeeded
        if (
          updateDefaultPaymentMethodResult.errors == null &&
          updateDefaultPaymentMethodResult.data
            ?.updateCustomerDefaultPaymentMethod?.success
        ) {
          window.analytics?.track('Update Default Payment Method Completed', {
            location: 'update payment form',
            organizationId,
          });
          return true;
        }

        // updating default payment method failed
        const errs = updateDefaultPaymentMethodResult.errors?.map(
          ({message}) => message as string
        );
        const errMsg = `Error updating payment method${
          errs != null ? `: ${errs.join(', ')}` : ''
        } Please try again.`;

        setError(errMsg);
        window.analytics?.track('Update Default Payment Method Error Viewed', {
          location: 'update payment form',
          organizationId,
          err: errMsg,
        });
        return false;
      } catch (err) {
        const msg = extractErrorMessageFromApolloError(err);
        const errMsg = `Error updating payment method${
          msg != null ? `: ${msg}` : ''
        } Please try again.`;

        setError(errMsg);
        window.analytics?.track('Update Default Payment Method Error Viewed', {
          location: 'update payment form',
          organizationId,
          err: errMsg,
        });
        return false;
      }
    },
    [organizationId, updateDefaultPaymentMethodMutation]
  );

  const handleRetryOpenInvoices = useCallback(async () => {
    try {
      const retryStripeOpenInvoicesByOrganizationResults =
        await retryStripeOpenInvoicesByOrganizationMutation({
          variables: {
            organizationId,
          },
        });

      if (
        retryStripeOpenInvoicesByOrganizationResults.errors == null &&
        retryStripeOpenInvoicesByOrganizationResults.data
          ?.retryStripeOpenInvoicesByOrganization?.success
      ) {
        return true;
      }

      const errs = retryStripeOpenInvoicesByOrganizationResults.errors?.map(
        ({message}) => message as string
      );
      const errMsg = `Error retrying open invoices. ${
        errs != null ? `: ${errs.join(', ')}` : ''
      } Check Billing page for latest invoice status.`;

      setError(errMsg);
      return false;
    } catch (err) {
      const msg = extractErrorMessageFromApolloError(err);
      const errMsg = `Error retrying open invoices. ${
        msg != null ? `: ${msg}` : ''
      } Check Billing page for latest invoice status.`;

      setError(errMsg);
      return false;
    }
  }, [organizationId, retryStripeOpenInvoicesByOrganizationMutation]);

  const onUpdatePaymentMethod = useCallback(async () => {
    setFormDisabled(true);
    setError(null);

    const paymentMethodID = await handleCreatePaymentMethod();
    if (paymentMethodID == null) {
      // something went wrong with creating the payment method
      // re-enable the form with error message displayed
      setFormDisabled(false);
      return;
    }
    const attachedSuccesfully = await handleAttachPaymentMethod(
      paymentMethodID
    );

    if (!attachedSuccesfully) {
      // something went wrong with updating the default payment method
      // re-enable the form with error message displayed
      setFormDisabled(false);
      return;
    }

    if (isPrimary) {
      // if the user wants to set the new payment method as primary,
      // we make graphql call to update the payment method
      const updatedSuccesfully = await handleUpdateDefaultPaymentMethod(
        paymentMethodID
      );

      if (!updatedSuccesfully) {
        // something went wrong with updating the default payment method
        // re-enable the form with error message displayed
        setFormDisabled(false);
        return;
      }

      // user chose to make the new payment method their primary payment method
      // and successfully added and updated their primary payment method
      toast(
        'Your payment method was successfully added and updated as your primary payment method.'
      );
    } else {
      // succesfully adding payment method. updating primary payment method was not requested
      toast('Your payment method was successfully added.');
    }

    if (shouldRetryOpenInvoices) {
      const retriedSuccessfully = await handleRetryOpenInvoices();

      if (retriedSuccessfully) {
        toast(
          'Your failed payments were retried, please check the billing page for latest invoice status.'
        );
      } else {
        toast(
          'Your failed payments were not retried. please check the billing page for latest invoice status.',
          {type: 'error'}
        );
      }
    }

    onUpdateCompleted();
  }, [
    handleCreatePaymentMethod,
    handleAttachPaymentMethod,
    handleUpdateDefaultPaymentMethod,
    isPrimary,
    onUpdateCompleted,
    shouldRetryOpenInvoices,
    handleRetryOpenInvoices,
  ]);

  return (
    <S.CheckoutModalForm>
      {error != null && <S.ErrorMessage negative content={error} />}
      <S.Label htmlFor="stripe-form-name">Cardholder Name</S.Label>
      <Input
        className="custo-input"
        placeholder="Name on card"
        onChange={(_0, data) => setCustoName(data.value)}
        disabled={formDisabled}
      />
      <S.Label htmlFor="stripe-form-name">Credit card</S.Label>
      <S.CardElementWrapper>
        <CardElement
          options={{
            disabled: formDisabled,
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '15px',
                color: '#424770',
                fontFamily: 'sans-serif',
                fontWeight: '300',
                letterSpacing: '0.025em',
                '::placeholder': {
                  color: 'rgba(55, 59, 62, 0.45)',
                },
              },
            },
          }}
        />
      </S.CardElementWrapper>
      <S.Label>Billing address</S.Label>
      <BillingAddressInput />
      <S.MakePrimaryCheckbox
        checked={isPrimary}
        label={`Set as primary payment method`}
        onClick={() => {
          if (!shouldDefault) {
            setIsPrimary(prev => !prev);
          }
        }}
        disabled={shouldDefault}
      />
      {shouldRetryOpenInvoices && (
        <InvoicesUnpaidTable orgID={organizationId} />
      )}
      <S.ActionButtonSection>
        <S.UpdateButton
          color="blue"
          onClick={onUpdatePaymentMethod}
          disabled={updateButtonDisabled}>
          {shouldRetryOpenInvoices
            ? 'Add card & retry payments'
            : 'Add payment method'}
        </S.UpdateButton>
        <S.CancelButton onClick={props.onClose}>Cancel</S.CancelButton>
      </S.ActionButtonSection>
    </S.CheckoutModalForm>
  );
};

export const UpdatePaymentForm = memo(UpdatePaymentFormComp);
