import {SmoothingTypeValues} from '../../../../elements/SmoothingConfig';
import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {getCascadingSetting} from '../../getCascadingSetting';
import {LinePlotSettings} from '../../types';
import {getLinePlotSettingWithDefault} from '../linePlotDefaults';

export const getCascadingSmoothingSettings = (
  parentSettings: LinePlotSettings | undefined,
  childSettings?: LinePlotSettings,
  panelSettings?: RunsLinePlotConfig
): {
  smoothingType?: SmoothingTypeValues;
  smoothingWeight?: number;
} => {
  // Order matters here - panel setting >> section setting >> workspace setting
  for (const settings of [panelSettings, childSettings, parentSettings]) {
    if (settings?.smoothingType != null && settings?.smoothingWeight != null) {
      return {
        smoothingType: settings.smoothingType,
        smoothingWeight: settings.smoothingWeight,
      };
    }

    if (settings?.smoothingType != null) {
      return {
        smoothingType: settings.smoothingType,
        smoothingWeight: findCascadingSmoothingWeight(
          settings.smoothingType,
          parentSettings,
          childSettings,
          panelSettings
        ),
      };
    }

    if (settings?.smoothingWeight != null) {
      return {
        smoothingType: getCascadingSetting([
          parentSettings?.smoothingType,
          childSettings?.smoothingType,
          panelSettings?.smoothingType,
        ]),
        smoothingWeight: settings?.smoothingWeight,
      };
    }
  }

  return {
    smoothingType: undefined,
    smoothingWeight: undefined,
  };
};

/**
 *
 * @returns smoothing weight that should be used with the defined smoothingType
 *
 * We need to walk back up the tree of specificity (panel -> section -> workspace)
 * to determine which weight value should be used. Not all smoothing types use the
 * same range and step, so we need to always check for the type.
 */
const findCascadingSmoothingWeight = (
  smoothingType: SmoothingTypeValues,
  parentSettings: LinePlotSettings | undefined,
  childSettings?: LinePlotSettings,
  panelSettings?: RunsLinePlotConfig
): number | undefined => {
  // Order matters here

  if (
    panelSettings?.smoothingType === smoothingType &&
    panelSettings?.smoothingWeight != null
  ) {
    return panelSettings?.smoothingWeight;
  }

  if (childSettings?.smoothingType === smoothingType) {
    return childSettings?.smoothingWeight;
  }

  // if section doesn't have smoothingType defined, look at parent to get
  // the inherited smoothingType. And if smoothingType is undefined, we take
  // the default value
  const parentSettingSmoothingType = getLinePlotSettingWithDefault(
    parentSettings?.smoothingType,
    'smoothingType'
  );
  if (
    childSettings?.smoothingWeight != null &&
    parentSettingSmoothingType === smoothingType
  ) {
    return childSettings?.smoothingWeight;
  }

  if (parentSettingSmoothingType === smoothingType) {
    return parentSettings?.smoothingWeight;
  }

  return undefined;
};
