import React, {FC, useEffect} from 'react';

import {useExperiment} from './components/ExperimentVariant/utils';
import {useViewer} from './state/viewer/hooks';
import {enableCacheLink} from './util/cacheLink';
import {getEntityNameFromPath} from './util/opfs';
import {
  useRampFlagGQLLongTermCacheByEntity,
  useRampFlagGQLLongTermCacheByUser,
} from './util/rampFeatureFlags';

const graphqlExperimentId =
  'RXhwZXJpbWVudDplYzM2ODg5ZC1kOTMxLTRkYzktYjk0My03OWQ0ODI2MDMyMWU=';

export const LongTermCacheExperimentContextProvider: FC = ({children}) => {
  const viewer = useViewer();

  const {isLoading, error, activeExperiment, experimentData} = useExperiment(
    {
      experimentGraphqlId: graphqlExperimentId,
      controlBucketValue: 0,
      bucketValues: new Set([0, 1]),
    },
    viewer?.id ?? ''
  );

  const gqlLongTermCacheByUser = useRampFlagGQLLongTermCacheByUser();
  const gqlLongTermCacheByEntity = useRampFlagGQLLongTermCacheByEntity(
    getEntityNameFromPath()
  );

  useEffect(() => {
    if (
      gqlLongTermCacheByUser ||
      gqlLongTermCacheByEntity ||
      (!isLoading &&
        !error &&
        activeExperiment &&
        experimentData?.treatment === 1)
    ) {
      enableCacheLink();
    }
  }, [
    isLoading,
    error,
    activeExperiment,
    experimentData,
    gqlLongTermCacheByUser,
    gqlLongTermCacheByEntity,
  ]);
  return isLoading ? null : <>{children}</>;
};
