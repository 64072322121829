import {useContext} from 'react';

import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {envIsPublicCloud} from '../../../config';
import {
  OrganizationSubscriptionType,
  useOrganizationSubscriptionInfoQuery,
  useUserLimitReachedQuery,
} from '../../../generated/graphql';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';

export function useIsRestrictedAccessOptionEnabled(organizationId?: string) {
  const enableAccountSelector = useRampFlagAccountSelector();
  const {selectedAccount} = useContext(AccountSelectorContext);

  const {data: licenseData, loading: licenseDataLoading} =
    useUserLimitReachedQuery({
      skip: envIsPublicCloud,
    });

  // TODO(elaina): this can be removed once we have fully transitioned to the account selector
  const {data: subData, loading: subLoading} =
    useOrganizationSubscriptionInfoQuery({
      variables: {organizationId: organizationId ?? ''},
      skip: !organizationId || enableAccountSelector || !envIsPublicCloud,
    });
  const hasEnterpriseSubscription =
    subData?.organization?.subscriptions.find(
      s => s.subscriptionType === OrganizationSubscriptionType.Enterprise
    ) != null;

  if (envIsPublicCloud) {
    const isRestrictedOptionEnabled = enableAccountSelector
      ? selectedAccount?.isEnterprise
      : hasEnterpriseSubscription;
    return {isRestrictedOptionEnabled, subLoading};
  }

  return {
    isRestrictedOptionEnabled:
      licenseData?.serverInfo?.licenseFlags.includes('rbac'),
    loading: licenseDataLoading,
  };
}
