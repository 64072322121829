import {createAction} from 'typesafe-actions';

export const setUserPollingOK = createAction(
  '@polling/setUserPollingOK',
  action => (userPollingOK: boolean) => action({userPollingOK})
);

export const setServerPollingOK = createAction(
  '@polling/setServerPollingOK',
  action => (serverPollingOK: boolean) => action({serverPollingOK})
);

export const setUserInactive = createAction(
  '@polling/setUserInactive',
  action => (userInactive: boolean) => action({userInactive})
);

export const setTabBackgrounded = createAction(
  '@polling/setTabBackgrounded',
  action => (tabBackgrounded: boolean) => action({tabBackgrounded})
);

export const setBasePollInterval = createAction(
  '@polling/setBasePollInterval',
  action => (basePollInterval: number) => action({basePollInterval})
);

export const tick = createAction('@polling/tick');

export const resetTicker = createAction('@polling/resetTicker');
