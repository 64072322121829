import {Maybe} from '../generated/graphql';
import {keyExistsInQueryString} from './query-string';

interface Project {
  access: Maybe<string>;
}

export function isPublicRead(p: Project): boolean {
  if (p == null) {
    return false;
  }

  if (p.access == null) {
    return false;
  }

  return ['USER_READ', 'USER_WRITE'].indexOf(p.access) !== -1;
}

export function isUsingAccessToken(): boolean {
  return keyExistsInQueryString(`accessToken`);
}
