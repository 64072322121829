import {MatchParams} from '@wandb/weave/common/types/base';
import {useBooleanState} from '@wandb/weave/common/util/hooks';
import {SetState} from '@wandb/weave/common/util/types';
import _ from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useLocation} from 'react-router-dom';

import {useSearchNavQueryQuery} from '../generated/graphql';
import {HomePageContextProvider} from '../pages/HomePage/HomePageContextProvider';
import {useCurrentRouteMatchParams} from '../routes/utils';
import {isInIframe} from '../setup';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {SearchNavQueryData} from './Search';

type GlobalNavState = {
  /**
   * The global nav sidebar is either "inline" or "floating",
   * depending on the page. An inline sidebar flows with the
   * rest of the page contents, while a floating sidebar sits
   * on top of the page contents and covers them.
   */
  isInline: boolean;
  /**
   * Controls whether the global nav sidebar is shown
   */
  isOpen: boolean;
  /**
   * If nav is inline, a shadow is applied to the sidebar
   * when the toggle button is hovered.
   */
  isTriggerHovered: boolean;
  /**
   * Controls whether animation will occur on open and close.
   * It's a bit hacky, but the reason for this is explained here:
   * https://github.com/wandb/core/pull/18117#discussion_r1395020152
   */
  shouldAnimate: boolean;
};

type NavContextState = {
  params: MatchParams;
  hidden: boolean;
  shouldShowBreadcrumbs: boolean;
  searchLoading: boolean;
  searchData: SearchNavQueryData | undefined;
  searchActive: boolean;
  globalNav: GlobalNavState;
  isCreatingTeam: boolean;
  isCreatingProject: boolean;
  isEditingProject: boolean;
};

type NavUpdaterContextState = {
  setHidden: SetState<boolean>;
  setShouldShowBreadcrumbs: SetState<boolean>;
  toggleSearch: () => void;
  activateSearch: () => void;
  deactivateSearch: () => void;
  updateGlobalNav: (changedValues: Partial<GlobalNavState>) => void;
  toggleTeamDrawer: () => void;
  toggleCreateProjectDrawer: () => void;
  toggleEditProjectDrawer: () => void;
};

const NavContext = createContext<NavContextState>({
  params: {},
  hidden: false,
  shouldShowBreadcrumbs: true,
  searchLoading: false,
  searchData: undefined,
  searchActive: false,
  globalNav: {
    isInline: false,
    isOpen: false,
    isTriggerHovered: false,
    shouldAnimate: true,
  },
  isCreatingTeam: false,
  isCreatingProject: false,
  isEditingProject: false,
});
NavContext.displayName = 'NavContext';
const NavUpdaterContext = createContext<NavUpdaterContextState>({
  setHidden: () => {},
  setShouldShowBreadcrumbs: () => {},
  toggleSearch: () => {},
  activateSearch: () => {},
  deactivateSearch: () => {},
  updateGlobalNav: () => {},
  toggleTeamDrawer: () => {},
  toggleCreateProjectDrawer: () => {},
  toggleEditProjectDrawer: () => {},
});
NavUpdaterContext.displayName = 'NavUpdaterContext';

export function useNavContext(): NavContextState {
  return useContext(NavContext);
}
export function useNavUpdaterContext(): NavUpdaterContextState {
  return useContext(NavUpdaterContext);
}

type NavContextProviderProps = {};

const NavContextProvider: React.FC<NavContextProviderProps> = React.memo(
  ({children}) => {
    const [hidden, setHidden] = useState(false);
    const [shouldShowBreadcrumbs, setShouldShowBreadcrumbs] = useState(true);
    const {
      state: searchActive,
      setTrue: activateSearch,
      setFalse: deactivateSearch,
      toggle: toggleSearch,
    } = useBooleanState(false);

    const [globalNav, setGlobalNav] = useState<GlobalNavState>({
      isInline: false,
      isOpen: false,
      isTriggerHovered: false,
      shouldAnimate: true,
    });
    const [isCreatingTeam, setIsCreatingTeam] = useState(false);
    const toggleTeamDrawer = useCallback(() => {
      setIsCreatingTeam(!isCreatingTeam);
    }, [isCreatingTeam]);
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const toggleCreateProjectDrawer = useCallback(() => {
      setIsCreatingProject(!isCreatingProject);
    }, [isCreatingProject]);
    const [isEditingProject, setIsEditingProject] = useState(false);
    const toggleEditProjectDrawer = useCallback(() => {
      setIsEditingProject(!isEditingProject);
    }, [isEditingProject]);
    const updateGlobalNav = useCallback(changedValues => {
      setGlobalNav(prevValues => {
        const mergedValues = {...prevValues, ...changedValues};
        // must check if values actually changed to prevent unwanted re-renders
        const isUnchanged = _.isEqual(prevValues, mergedValues);
        return isUnchanged ? prevValues : mergedValues;
      });
    }, []);

    // Reset global nav on location change. By default, inline nav
    // should be open and floating nav should be closed.
    const location = useLocation();
    useEffect(
      () =>
        updateGlobalNav({
          isOpen: globalNav.isInline,
          shouldAnimate: false,
        }),
      [location, globalNav.isInline, updateGlobalNav]
    );

    const params = useCurrentRouteMatchParams();
    const {loading: searchLoading, data: searchNavData} =
      useSearchNavQueryQuery({
        variables: {
          entityName: params.entityName ?? '',
          projectName: params.projectName ?? '',
          runName: params.runName ?? '',
          sweepName: params.sweepName ?? '',
        },
        skip: hidden || isInIframe(),
      });
    const searchData = searchNavData as SearchNavQueryData | undefined;

    const value = useMemo(
      () => ({
        params,
        hidden,
        shouldShowBreadcrumbs,
        searchLoading,
        searchData,
        searchActive,
        globalNav,
        isCreatingTeam,
        isCreatingProject,
        isEditingProject,
      }),
      [
        params,
        hidden,
        shouldShowBreadcrumbs,
        searchLoading,
        searchData,
        searchActive,
        globalNav,
        isCreatingTeam,
        isCreatingProject,
        isEditingProject,
      ]
    );

    const updaterValue = useMemo(
      () => ({
        setHidden,
        setShouldShowBreadcrumbs,
        toggleSearch,
        activateSearch,
        deactivateSearch,
        updateGlobalNav,
        toggleTeamDrawer,
        toggleCreateProjectDrawer,
        toggleEditProjectDrawer,
      }),
      [
        toggleSearch,
        activateSearch,
        deactivateSearch,
        updateGlobalNav,
        toggleTeamDrawer,
        toggleCreateProjectDrawer,
        toggleEditProjectDrawer,
      ]
    );

    return (
      <NavContext.Provider value={value}>
        <NavUpdaterContext.Provider value={updaterValue}>
          {/* HomePageContext is required for global nav sidebar */}
          <HomePageContextProvider>{children}</HomePageContextProvider>
        </NavUpdaterContext.Provider>
      </NavContext.Provider>
    );
  }
);
NavContextProvider.displayName = 'NavContextProvider';

export default NavContextProvider;

export function useHideNavOnPage(): void {
  const {setHidden} = useNavUpdaterContext();
  useEffect(() => {
    setHidden(true);
    return () => setHidden(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
