import {notEmpty} from '@wandb/weave/common/util/obj';
import _ from 'lodash';

import {captureError} from '../../integrations';
import {Line, Mark, Point} from '../../util/plotHelpers/types';

export type CrosshairPoints = {
  point: Point;
  minmaxPoint: Point | null;
  stddevPoint: Point | null;
};

export function getAllCrosshairPointsFromLine(line: Line) {
  const nonNanCrosshairPoints: CrosshairPoints[] = line.data.map((p, i) => ({
    point: p,
    minmaxPoint: line.minmaxLine?.data[i] ?? null,
    stddevPoint: line.stddevLine?.data[i] ?? null,
  }));
  const nanCrosshairPoints: CrosshairPoints[] =
    line.nanPoints?.map(p => ({
      point: p,
      minmaxPoint: null,
      stddevPoint: null,
    })) ?? [];
  const allCrosshairPoints = mergeCrosshairPoints(
    nonNanCrosshairPoints,
    nanCrosshairPoints
  );
  return allCrosshairPoints;
}

export function getCrosshairPoints(
  allCrosshairPoints: CrosshairPoints[],
  cursorX: number,
  xMin: number,
  xMax: number
): CrosshairPoints | null {
  const clampedHighlightX = _.clamp(cursorX, xMin, xMax);
  const highlightCrosshairPoint = {
    point: {x: clampedHighlightX, y: 0},
    minmaxPoint: null,
    stddevPoint: null,
  };

  // binary search
  const pointIndex = _.sortedIndexBy(
    allCrosshairPoints,
    highlightCrosshairPoint,
    ({point}) => point.x
  );
  if (pointIndex < 0 || pointIndex > allCrosshairPoints.length) {
    return null;
  }

  // when the user's cursor is out past the last point in the line,
  // only return a point from this line if the end of line is reasonably close
  const lastPointX =
    allCrosshairPoints[allCrosshairPoints.length - 1]?.point?.x;

  if (pointIndex === allCrosshairPoints.length) {
    if (cursorX > lastPointX) {
      return pointIsReasonablyClose(cursorX, lastPointX, xMin, xMax)
        ? allCrosshairPoints[allCrosshairPoints.length - 1]
        : null;
    } else {
      return allCrosshairPoints[allCrosshairPoints.length - 1];
    }
  }

  // ditto but for the beginning of the line
  const firstPointX = allCrosshairPoints[0]?.point?.x;
  if (pointIndex === 0) {
    if (cursorX < firstPointX) {
      return pointIsReasonablyClose(cursorX, firstPointX, xMin, xMax)
        ? allCrosshairPoints[0]
        : null;
    } else {
      return allCrosshairPoints[0];
    }
  }

  // Show the point we're closest to
  const nextPoint = allCrosshairPoints[pointIndex];
  const prevPointIndex = Math.max(pointIndex - 1, 0);
  const prevPoint = allCrosshairPoints[prevPointIndex];

  const prevPointOutOfBounds = prevPoint.point.x < xMin;
  const nextPointOutOfBounds = nextPoint.point.x > xMax;
  const nextPointCloserThanPrevPoint =
    nextPoint.point.x - cursorX < cursorX - prevPoint.point.x;

  const useNextPoint =
    prevPointOutOfBounds ||
    (nextPointCloserThanPrevPoint && !nextPointOutOfBounds);

  return useNextPoint ? nextPoint : prevPoint;
}

export function mergeCrosshairPoints(
  nonNanPoints: CrosshairPoints[],
  nanPoints: CrosshairPoints[]
): CrosshairPoints[] {
  if (nanPoints.length === 0) {
    return nonNanPoints;
  }

  return nonNanPoints.concat(nanPoints).sort((a, b) => a.point.x - b.point.x);
}

export const pointIsReasonablyClose = (
  cursorX: number,
  pointX: number,
  xMin: number,
  xMax: number
): boolean => {
  const graphWidth = xMax - xMin;
  if (graphWidth <= 0) {
    captureError(
      'graphWidth was not a valid number!',
      'crosshairValuesFromLines',
      {extra: {xMin, xMax}}
    );
    // Not sure how we would hit this case, so I'm just allowing all points
    return true;
  }
  const distanceFromHighlight = Math.abs(pointX - cursorX);
  const percentOfGraphFromCursor = distanceFromHighlight / graphWidth;

  // don't show points from lines that are far away from the cursor
  // Note that this value is basically just a guess, so it would not
  // surprise me if it makes sense to tweak it a bit
  return percentOfGraphFromCursor < 0.05;
};

export interface MarkSeriesPointForCrosshair {
  color?: string;
  fill?: string | number;
  mark?: Mark;
  minmax?: Array<number | undefined>;
  name?: string;
  opacity?: string | number;
  original?: number;
  percent?: number;
  runFriendlyName: string;
  size?: string | number;
  stddev?: number;
  stroke?: string | number;
  title: string;
  total?: number;
  uniqueId?: string;
  value: string | number | Date;
  values?: number[] /* I don't know where this get filled in, might be unused? */;
  x: number;
  y: number;
}

export function crosshairValuesFromLines(
  enabledLinesWithCrosshairPoints: Array<{
    line: Line;
    crosshairPoints: CrosshairPoints[];
  }>,
  cursorX: number,
  xMin: number,
  xMax: number
): MarkSeriesPointForCrosshair[] {
  const highlightPoints = enabledLinesWithCrosshairPoints
    .map(({line, crosshairPoints}) => {
      const crosshairPointsByCursor = getCrosshairPoints(
        crosshairPoints,
        cursorX,
        xMin,
        xMax
      );
      if (crosshairPointsByCursor == null) {
        return null;
      }
      const {point, minmaxPoint, stddevPoint} = crosshairPointsByCursor;
      return {
        color: line.color,
        mark: line.mark,
        minmax:
          minmaxPoint != null ? [minmaxPoint.y, minmaxPoint.y0] : undefined,
        name: line.run ? line.run.name : undefined,
        original: point.legendData?.original,
        percent: point.legendData?.percent,
        // line.displayName is populated when runs not grouped
        // line.name is populated when runs are grouped.
        runFriendlyName: line.displayName || line.name || '',
        stddev: stddevPoint != null ? stddevPoint.y - point.y : undefined,
        title: line.title ?? '',
        total: point.legendData?.total,
        uniqueId: line.uniqueId,
        value: point.legendData?.y ?? point.y,
        x: point.x,
        y: point.y,
      };
    })
    .filter(notEmpty);

  // At this point, we have the closest X values (relative to cursorX) for each of the lines.
  // We now filter them to show only the lines with points that are close (relative to the
  // overall x range of the graph).
  //
  // Note that we do not filter to exact x value matches because:
  // 1. There are cases where there are no exact x value matches (eg from wandb backend sampling
  //    of long lines)
  // 2. The x value is usually steps, and depending on how the user is logging, there's no
  //    guarantee that steps will actually line up between runs.
  // So matching close points is actually better than exact x value matches.
  const xVals = new Set<number>();
  const dist = (xVal: number) => Math.abs(xVal - cursorX);

  highlightPoints.forEach(p => xVals.add(p.x));
  if (xVals.size > 1) {
    const closestX = Array.from(xVals).reduce(
      (acc: undefined | number, pointX: number) => {
        const accIsCloser = acc && dist(pointX) >= dist(acc);
        return accIsCloser ? acc : pointX;
      },
      undefined
    );
    if (closestX == null) {
      // xVals size is guaranteed to be > 1, so the reduce statement
      // shouldn't be returning undefined since it'll always return a value
      // if there's at least one point.
      console.warn('encountered null closestX');
      // safe default to return all points
      return highlightPoints;
    }

    return highlightPoints.filter(pt =>
      pointIsReasonablyClose(closestX, pt.x, xMin, xMax)
    );
  }
  return highlightPoints;
}
