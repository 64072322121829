import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import {useViewerUserInfoQuery} from '../generated/graphql';
import {isCreateTeamPath} from '../routes';
import {useViewer} from '../state/viewer/hooks';
import history from '../util/history';
import {useRampFlagAccountSelector} from '../util/rampFeatureFlags';
import {createNewTeamPage} from '../util/urls';
import {AccountSelectorContext} from './Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from './Search/SearchNav/types';
import {useAccountList} from './Search/SearchNav/useAccountList';

// Redirect users who belong to no team to CreateNewTeam page
const UserWithNoOrgsRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const shouldRedirect = !isCreateTeamPath(location.pathname);
    if (shouldRedirect) {
      history.push(createNewTeamPage());
    }
  }, [location.pathname]);
  return null;
};

// Confirm Account Selector is on, Personal Entity is selected, and personal entity is hidden
export const UserWithNoOrgEnforcement = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const viewer = useViewer();
  const {data: viewerData, loading: userInfoLoading} = useViewerUserInfoQuery();
  const {accountList: accountListData, isLoading: accountListLoading} =
    useAccountList();
  const isSingleAccount =
    accountListData.length === 1 &&
    accountListData[0].name === viewer?.username;
  const userInfo = viewerData?.viewer?.userInfo;
  const shouldHidePersonalEntity = userInfo?.isPersonalEntityHidden ?? false;

  const [viewerState, setViewerState] = useState(viewer);

  useEffect(() => {
    setViewerState(viewer);
  }, [viewer]);

  const numberOfViewerOrgs = viewerState?.organizations.length;

  const skip =
    !enableAccountSelector ||
    selectedAccount == null ||
    selectedAccount.accountType !== AccountType.Personal ||
    userInfoLoading ||
    !shouldHidePersonalEntity ||
    accountListLoading ||
    numberOfViewerOrgs === undefined ||
    numberOfViewerOrgs > 0 ||
    !isSingleAccount;

  return skip ? null : <UserWithNoOrgsRedirect />;
};
