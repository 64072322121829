import {getSafeYKey} from './util';

export function filterRedundantHistories(
  histories: Array<Array<Record<string, number>>>,
  yMetrics: string[]
) {
  return (histories ?? []).filter(history => {
    if (!history[0]) {
      return false;
    }
    // only return histories that have at least one matching y metric
    // this gets rid of redundant histories when custom x expressions are used
    // see: https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1720029000176989?thread_ts=1720027827.954079&cid=C062UKHTAHG
    return yMetrics.some(metric => {
      const yKey = getSafeYKey(metric);
      return yKey in history[0];
    });
  });
}
