import {ID} from '@wandb/weave/common/util/id';
import {useCallback, useEffect, useRef} from 'react';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ThunkResult} from '../../types/redux';
import {useDispatch} from '../hooks';
// eslint-disable-next-line import/no-cycle
import * as Actions from './actions';
import * as Types from './types';
import * as Url from './url';

// Note: the Workspace being initialized here is *not* what is referred to as a workspace in the rest of code
// this workspace (and the WorkspaceID returned from it) refer to the redux workspace state
export function useWorkspaceInit(
  entityName: string,
  projectName: string,
  viewType: Types.ViewTypes,
  defaultSpec: Types.WorkspaceMaterialized,
  workspaceUrl: Url.WorkspaceUrl,
  // If specified, we'll create one workspace per combination of viewType
  // and workspaceObjectID per user. We use this for sweeps to get a new
  // workspace for every sweep. Groups and runs do not use this at this time.
  workspaceObjectID?: string
) {
  const workspaceIDRef = useRef('workspace-' + ID(9));
  const workspaceStateId = workspaceIDRef.current;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      Actions.init(
        workspaceStateId,
        entityName,
        projectName,
        viewType,
        workspaceUrl,
        defaultSpec,
        workspaceObjectID
      )
    );
    return () => dispatch(Actions.deinit(workspaceStateId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return workspaceStateId;
}

// Hook that simplifies dispatching redux workspace actions, by binding them
// to a specific view part reference.
export function useWorkspaceAction<ArgType extends any[], R>(
  workspaceID: string,
  fn: (ref: string, ...fnArgs: ArgType) => ThunkResult<R>
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(workspaceID, ...args)),
    [dispatch, workspaceID, fn]
  );
}
