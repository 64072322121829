import _ from 'lodash';

import {SectionPanelSorting} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {panelSortingKeyFromPanel} from '../../../util/panelbank';
import {getNewGridItemLayout} from '../../../util/panelbankGrid';
import {LayedOutPanel} from '../../../util/panelTypes';
import {setInShallowClone} from '../../../util/utility';
import * as Normalize from '../normalize';
import {StateType} from '../normalizerSupport';
import * as PanelTypes from '../panel/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as Actions from './actions';
import {PanelBankSectionConfigNormalized, Ref} from './types';

export const toggleIsOpen = (
  state: ViewReducerState,
  ref: Ref
): [ViewReducerState, ActionType] => {
  const curSectionConfig = state.parts[ref.type][ref.id];
  const newState = setInShallowClone(
    state,
    ['parts', ref.type, ref.id, 'isOpen'],
    !curSectionConfig.isOpen
  );
  const inverseAction = Actions.toggleIsOpen(ref);
  return [newState, inverseAction];
};

export function addPanels(
  prevState: ViewReducerState,
  sectionRef: Ref,
  panels: LayedOutPanel[],
  isFatPanel?: boolean,
  callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
) {
  // Add layout for grid sections
  const panelsWithLayout = panels.map(panel => {
    return {
      ...panel,
      layout: getNewGridItemLayout(
        Normalize.denormalize(prevState.parts, sectionRef)
          .panels.map(p => p.layout)
          .filter(l => l),
        isFatPanel
      ),
    };
  });

  const {parts: newParts, refs: newPanelRefs} = Normalize.addObjsImmutable(
    prevState.parts,
    'panel',
    sectionRef.viewID,
    panelsWithLayout
  );

  if (callbackFn) {
    newPanelRefs.forEach((panelRef, idx) => {
      const panel = panelsWithLayout[idx];
      callbackFn(panel, panelRef);
    });
  }

  const prevSectionConfig = prevState.parts[sectionRef.type][sectionRef.id];
  const prevIsPanelsAuto = prevSectionConfig.isPanelsAuto;
  let newSectionConfig;

  if (prevSectionConfig.sorted === SectionPanelSorting.Alphabetical) {
    newSectionConfig = immutableInsertPanelsAlphabetically(
      newParts,
      prevSectionConfig,
      newPanelRefs
    );
  } else {
    // Insert at the beginning of section.panels
    newSectionConfig = {
      ...prevSectionConfig,
      panelRefs: newPanelRefs.concat(prevSectionConfig.panelRefs),
    };
  }

  // If section is closed, open it
  if (!newSectionConfig.isOpen) {
    newSectionConfig.isOpen = true;
  }

  // Update section in state
  const newState = {
    ...prevState,
    parts: {
      ...newParts,
      [sectionRef.type]: {
        ...newParts[sectionRef.type],
        [sectionRef.id]: newSectionConfig,
      },
    },
  };

  return {newState, newPanelRefs, prevIsPanelsAuto};
}

export function addPanel(
  prevState: ViewReducerState,
  sectionRef: Ref,
  panel: LayedOutPanel,
  isFatPanel?: boolean,
  callbackFn?: (panel: LayedOutPanel, newPanelRef: PanelTypes.Ref) => void
): [ViewReducerState, ActionType] {
  const {newState, newPanelRefs, prevIsPanelsAuto} = addPanels(
    prevState,
    sectionRef,
    [panel],
    isFatPanel,
    callbackFn
  );
  return [
    newState,
    Actions.deletePanel(sectionRef, newPanelRefs[0], undefined, undefined, {
      value: prevIsPanelsAuto,
    }),
  ];
}

const movePanelAlphabeticallyKey = (key: string | undefined) => {
  if (key === undefined) {
    return '0';
  } else {
    return '1' + key;
  }
};

export function immutableInsertPanelsAlphabetically(
  parts: StateType,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  newPanelRefs: PanelTypes.Ref[]
): PanelBankSectionConfigNormalized {
  return {
    ...normalizedSectionConfig,
    panelRefs: normalizedSectionConfig.panelRefs
      .concat(newPanelRefs)
      .sort((refA, refB) => {
        const keyA = panelSortingKeyFromPanel(parts[refA.type][refA.id]);
        const keyB = panelSortingKeyFromPanel(parts[refB.type][refB.id]);
        return movePanelAlphabeticallyKey(keyA).localeCompare(
          movePanelAlphabeticallyKey(keyB)
        );
      }),
  };
}

export function insertPanelsAlphabetically(
  parts: StateType,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  newPanelRefs: PanelTypes.Ref[]
) {
  normalizedSectionConfig.panelRefs = normalizedSectionConfig.panelRefs
    .concat(newPanelRefs)
    .sort((refA, refB) => {
      const keyA = panelSortingKeyFromPanel(parts[refA.type][refA.id]);
      const keyB = panelSortingKeyFromPanel(parts[refB.type][refB.id]);
      return movePanelAlphabeticallyKey(keyA).localeCompare(
        movePanelAlphabeticallyKey(keyB)
      );
    });
}

export function movePanelAlphabeticallyInSection(
  state: ViewReducerState,
  normalizedSectionConfig: PanelBankSectionConfigNormalized,
  panelRef: PanelTypes.Ref,
  panel: LayedOutPanel
) {
  const panelKeys = normalizedSectionConfig.panelRefs
    .filter(oldPanelRef => oldPanelRef.id !== panelRef.id)
    .map(oldPanelRef => {
      const sectionPanel = Normalize.denormalize(state.parts, oldPanelRef);
      return panelSortingKeyFromPanel(sectionPanel);
    });
  const newPanelIndex = _.sortedIndexBy(
    panelKeys,
    panelSortingKeyFromPanel(panel),
    movePanelAlphabeticallyKey
  );
  const origPanelIndex = normalizedSectionConfig.panelRefs.findIndex(
    sectionPanelRef => sectionPanelRef.id === panelRef.id
  );
  normalizedSectionConfig.panelRefs.splice(origPanelIndex, 1);
  normalizedSectionConfig.panelRefs.splice(newPanelIndex, 0, panelRef);
}

export const updateLinePlotSectionSettings = (
  state: ViewReducerState,
  ref: Ref,
  settings: Partial<LinePlotSettings> | undefined
): [ViewReducerState, ActionType] => {
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
      },
    },
  };

  const prevSectionSettings =
    newState.parts['panel-bank-section-config'][ref.id].sectionSettings;

  newState.parts['panel-bank-section-config'][ref.id] = {
    ...newState.parts['panel-bank-section-config'][ref.id],
    sectionSettings: {
      ...prevSectionSettings,
      linePlot: {
        ...(prevSectionSettings?.linePlot ?? {}),
        ...settings,
      },
    },
  };

  const inverseAction = Actions.updateLinePlotSectionSettings(
    ref,
    prevSectionSettings?.linePlot
  );
  return [newState, inverseAction];
};

const setPanelAutoVals = (
  prevState: ViewReducerState,
  ref: Ref,
  newIsAutoVals: boolean[]
) => {
  const newState = {
    ...prevState,
    parts: {
      ...prevState.parts,
      panel: {
        ...prevState.parts.panel,
      },
    },
  };
  const {panelRefs} = prevState.parts[ref.type][ref.id];
  const prevPanelAutoVals: Array<boolean | undefined> = [];
  panelRefs.forEach((panelRef, idx) => {
    const prevPanel = prevState.parts[panelRef.type][panelRef.id];
    prevPanelAutoVals.push(prevPanel.isAuto);
    newState.parts.panel[panelRef.id] = {
      ...prevPanel,
      isAuto: newIsAutoVals[idx],
    };
  });
  return {newState, prevPanelAutoVals};
};

export const updateName = (
  prevState: ViewReducerState,
  ref: Ref,
  newName: string,
  panelAutoVals?: Array<boolean | undefined>
): [ViewReducerState, ActionType] => {
  const prevSection = prevState.parts[ref.type][ref.id];
  const prevName = prevSection.name;
  const prevIsPanelsAuto = prevSection.isPanelsAuto;

  // update section.panels -> each panel.isAuto
  const numPanelsInSection = prevSection.panelRefs.length;
  const {newState: stateAfterIsAutoUpdates, prevPanelAutoVals} =
    setPanelAutoVals(
      prevState,
      ref,
      panelAutoVals ?? Array(numPanelsInSection).fill(false)
    );

  // update section.name
  const newState = setInShallowClone(
    stateAfterIsAutoUpdates,
    ['parts', ref.type, ref.id, 'name'],
    newName
  );

  return [
    newState,
    Actions.updateName(
      ref,
      prevName,
      {value: prevIsPanelsAuto},
      prevPanelAutoVals
    ),
  ];
};
