import {useMemo} from 'react';

import * as FilterTypes from '../filter/types';
import * as GroupSelectionsTypes from '../groupSelections/types';
import {usePart, useParts} from '../hooks';
import * as SortTypes from '../sort/types';
import {WholeFromTypeWithRef} from '../types';
import * as Types from './types';

function assembleRunSet(
  part: Types.RunSetPart,
  groupSelections: GroupSelectionsTypes.GroupSelections,
  sort: SortTypes.Sort,
  filters: FilterTypes.Filter
): Types.RunSetConfig {
  return {
    ...part,
    grouping: groupSelections.grouping,
    selections: groupSelections.selections,
    expandedRowAddresses: groupSelections.expandedRowAddresses,
    sort,
    filters,
  };
}

export function useRunSet(ref: Types.Ref): WholeFromTypeWithRef<'runSet'> {
  const part = usePart(ref);
  const groupSelections = usePart(part.groupSelectionsRef);
  const sort = usePart(part.sortRef);
  const filters = usePart(part.filtersRef);

  return useMemo(
    () => ({...assembleRunSet(part, groupSelections, sort, filters), ref}),
    [ref, part, groupSelections, sort, filters]
  );
}

export function useRunSets(
  refs: Types.Ref[]
): Array<WholeFromTypeWithRef<'runSet'>> {
  const parts = useParts(refs);

  const groupSelectionsRefs = useMemo(
    () => parts.map(part => part.groupSelectionsRef),
    [parts]
  );
  const groupSelectionsList = useParts(groupSelectionsRefs);

  const sortRefs = useMemo(() => parts.map(part => part.sortRef), [parts]);
  const sorts = useParts(sortRefs);

  const filtersRefs = useMemo(
    () => parts.map(part => part.filtersRef),
    [parts]
  );
  const filtersList = useParts(filtersRefs);

  return useMemo(
    () =>
      parts.map((part, i) => ({
        ...assembleRunSet(
          part,
          groupSelectionsList[i],
          sorts[i],
          filtersList[i]
        ),
        ref: refs[i],
      })),
    [parts, groupSelectionsList, sorts, filtersList, refs]
  );
}
