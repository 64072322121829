import queryString from 'query-string';

import {useUserOrganizationsQuery} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {getAuthRedirect} from '../util/auth';
import {useUserEmails} from '../util/useUserEmails';

interface JoinTeamsResult {
  showTeamsContent: boolean;
  viewerEmail: string | undefined;
}

export function useShowJoinTeamsContent(location: string): JoinTeamsResult {
  const viewer = useViewer();
  const {verifiedCorporateEmails} = useUserEmails();

  const authRedirect = getAuthRedirect();
  const {query} = queryString.parseUrl(authRedirect);

  const viewerEmailDomain =
    verifiedCorporateEmails && verifiedCorporateEmails.length > 0
      ? verifiedCorporateEmails[0]?.emailAddress.split('@')[1]
      : viewer?.email?.split('@')[1];

  const userOrganizationsQueryResult = useUserOrganizationsQuery({
    variables: {
      emailDomain: viewerEmailDomain,
    },
  });

  const userTeamIDs = new Set(
    viewer?.teams?.edges?.map(({node}) => node.id) ?? []
  );

  const hasAvailableMatchingTeams =
    userOrganizationsQueryResult?.data?.viewer?.openMatchingOrganizations?.some(
      ({teams}) =>
        teams.filter(
          team =>
            !userTeamIDs.has(team.id) &&
            team.settings.openToMatchingOrgEmailDomainUsers
        ).length
    ) ?? false;

  const showJoinTeamsContent =
    (verifiedCorporateEmails || false) &&
    verifiedCorporateEmails.length > 0 &&
    hasAvailableMatchingTeams;

  const justAddedVerifiedCorporateEmail = query.addEmail === `true`;

  if (justAddedVerifiedCorporateEmail) {
    window.analytics?.track('Added work email', {
      entityName: viewer?.name,
      location,
    });
  }
  return {
    showTeamsContent: showJoinTeamsContent,
    viewerEmail:
      verifiedCorporateEmails && verifiedCorporateEmails[0]?.emailAddress,
  };
}
