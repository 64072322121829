import * as Tabs from '@wandb/weave/components/Tabs';
import React, {FC, memo} from 'react';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {LocalSearchResultType} from '.';

export type ResultFilterMode = `all` | LocalSearchResultType;

type TabOption = {
  label: string;
  value: ResultFilterMode;
};

const tabOptions: TabOption[] = [
  {label: `All results`, value: `all`},
  {label: `Projects`, value: `project`},
  {label: `Reports`, value: `report`},
  {label: `Teams`, value: `team`},
];

type FilterTabsProps = {
  filterMode: ResultFilterMode;
  disabledModes: Set<ResultFilterMode>;
  onSelect: (m: ResultFilterMode) => void;
};

const FilterTabsComp: FC<FilterTabsProps> = ({
  filterMode,
  disabledModes,
  onSelect,
}) => {
  return (
    <Tabs.Root
      value={filterMode}
      onValueChange={value => onSelect(value as ResultFilterMode)}
      className={`max-sm:hidden mt-20`}>
      <Tabs.List>
        {tabOptions.map(({label, value}, i) => {
          return (
            <Tabs.Trigger
              key={i}
              value={value}
              disabled={disabledModes.has(value)}>
              {label}
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
    </Tabs.Root>
  );
};

export const FilterTabs = memo(FilterTabsComp);
