import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC, Fragment} from 'react';

import {createDismissibleBanner} from '../../../pages/Billing/AccountSettings/WarningBanner/DismissibleBanner';
import {Analytics} from '../../../services/analytics';
import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
import {useCurrentBannerStates} from '..';
import {BlockedRegionBannerProps} from './utils';

// Source: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
const BELARUS_TIMEZONES = ['Europe/Moscow', 'Europe/Minsk'];
const NORTH_KOREA_TIMEZONES = ['Asia/Pyongyang'];
const RUSSIA_TIMEZONES = [
  'Asia/Anadyr',
  'Asia/Barnaul',
  'Asia/Chita',
  'Asia/Irkutsk',
  'Asia/Kamchatka',
  'Asia/Khandyga',
  'Asia/Krasnoyarsk',
  'Asia/Magadan',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Tomsk',
  'Asia/Ust-Nera',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Europe/Astrakhan',
  'Europe/Kaliningrad',
  'Europe/Kirov',
  'Europe/Samara',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Ulyanovsk',
  'Europe/Volgograd',
  'W-SU',
  'Europe/Moscow',
];
export const BLOCKED_REGION_TIMEZONES = BELARUS_TIMEZONES.concat(
  NORTH_KOREA_TIMEZONES,
  RUSSIA_TIMEZONES
);

export const BlockedRegionBannerGlobalList: FC = () => {
  const currentBannerStates = useCurrentBannerStates();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Tailwind>
      <div className="space-y-8">
        {currentBannerStates.map(state => {
          const blockedRegionBannerProps = state.blockedRegionBannerProps;

          return (
            <Fragment key={state.account.name + state.account.accountType}>
              {BLOCKED_REGION_TIMEZONES.includes(timezone) && (
                <BlockedRegionBanner {...blockedRegionBannerProps} />
              )}
            </Fragment>
          );
        })}
      </div>
    </Tailwind>
  );
};

export const BlockedRegionBanner: FC<BlockedRegionBannerProps> =
  createDismissibleBanner<BlockedRegionBannerProps>({
    id: ({entityId}) => entityId + ':blocked-region',
    Component: ({onDismiss}) => {
      return (
        <Tailwind>
          <AccountStatusBanner
            eventData={{
              location: `blocked region banner`,
            }}>
            <AccountStatusBannerFlexWrapper>
              <div>
                <Icon name="info" />
              </div>
              <AccountStatusBannerTextWrapper>
                <span>
                  <AccountStatusBannerBoldText>
                    In order to comply with US export control regulations and
                    sanctions, Weights & Biases will no longer be accessible
                    from certain regions, effective as of September 12. If you
                    are seeing this, your access may be impacted.
                  </AccountStatusBannerBoldText>{' '}
                </span>
              </AccountStatusBannerTextWrapper>
            </AccountStatusBannerFlexWrapper>
            <button
              className="flex items-center border-none bg-transparent font-semibold text-moon-600 hover:cursor-pointer hover:text-moon-900"
              onClick={() => {
                Analytics.track('Dismiss button clicked', {
                  location: `blocked region banner`,
                });
                onDismiss();
              }}>
              Dismiss
            </button>
          </AccountStatusBanner>
        </Tailwind>
      );
    },
  });
