import * as _ from 'lodash';
import {RunHistoryRow} from 'src/types/run';

// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as RunHelpers from '../../util/runhelpers';

type SimplePoint = {
  x: number;
  y: number;
};

export type PointsByRunAndMetric = {
  [runId: string]: {
    [metricName: string]: {
      nanPoints: SimplePoint[];
      points: SimplePoint[];
    };
  };
};

export function getStartTimeForAbsoluteRunTime(
  history: RunHistoryRow[]
): number {
  const firstRow = history[0];
  return (firstRow?._timestamp ?? 0) - (firstRow?._runtime ?? 0);
}

/**
 * Why this exists:
 * The previous way that we build lines from runsets could result in blocking the JS thread for a _long_ time (up to 20s measured) because of the iteration logic. The sampled history for a run is 1500 data points _per metric_. Then the sampled history is unpacked into a history list, so with three metrics
 * sampledHistory = [
 *   metricA: [1500 items],
 *   metricB: [1500 items],
 *   metricC: [1500 items]
 * ]
 * history = [...metricA, ...metricB, ...metricC]j = 4500 items
 *
 * So if you have 96 metrics (such as a 96 core machine in system metrics) hitting the sample limit on a 20 run history you would build up iteration logic like:
 * loop each metric name (96)
 * loop each run (20)
 * loop each point in the run history (144000)
 * 96 * 20 * 144000 = 276,000,000 iterations
 *
 * This escape hatch works by cutting out the top level metric name loop. Because each history list contains ALL THE DATA we don't need to loop per metricname and extract the relevant data, instead, we can do a top level loop through the history record of each run and build up a dictionary where we can easily retrieve the values
 *
 * Caveat: this is a classic "trade time complexity for space complexity problem". For the workspace: https://app.wandb.test/mattklug/Roberta?workspace=user-justintulk the size of the returned object here is 82mb.
 */

export function makePointsByRunAndMetric(
  runsetRuns: RunHelpers.RunWithRunsetInfoAndHistory[],
  xAxisName: string
): PointsByRunAndMetric {
  const pointsByRunAndMetric = {} as PointsByRunAndMetric;
  /**
   * Previously this conditional check ran inside each history point. Splitting the fn's apart and handling it with a higher level check saves time.
   */
  const isAbsoluteRuntime = xAxisName === '_absolute_runtime';
  const parseFn = isAbsoluteRuntime ? parseRunTimePoint : parsePoint;

  runsetRuns.forEach(run => {
    pointsByRunAndMetric[run.id] = {};
    // establish the baseline for calculating absolute_runtimes
    const startTime = getStartTimeForAbsoluteRunTime(run.history);

    const xVals = new Set<number>();

    for (const point of run.history) {
      const [metricNames, xAxisValue] = parseFn(xAxisName, point, {
        startTime,
        isAbsoluteRuntime,
      });

      /**
       * Quirk: this is silly, but in order to calculate x values when a user has entered an x-axis expression we have to construct a line where the x & y are both the xAxis value. The line gets filtered out later but without it the block that constructs x-axis values as a function of the x-axis expression won't ever be entered.
       *
       * So if you're creating lines for a run: { id: 'abc' } based on _step for metrics x & y, you need to come out of here with:
       * pointsByRunAndMetric = {
       *  abc: {
       *   _step: {
       *     nanPoints: [...],
       *     points: [...]
       *   },
       *   x: {
       *     nanPoints: [...],
       *     points: [...]
       *   },
       *   y: {
       *     nanPoints: [...],
       *     points: [...]
       *   }
       * }
       *
       * There are definitely more efficient, more direct ways to handle expressions, and this entire line construction module needs to be refactored towards that end.
       *
       */
      xVals.add(xAxisValue);

      // this will only iterate through the y-value metrics, because we destructure off the x-axis key above
      Object.keys(metricNames).forEach(metricName => {
        if (!pointsByRunAndMetric[run.id][metricName]) {
          pointsByRunAndMetric[run.id][metricName] = {
            nanPoints: [],
            points: [],
          };
        }

        const value = point[metricName];
        if (!_.isNil(value)) {
          if (_.isFinite(value)) {
            pointsByRunAndMetric[run.id][metricName].points.push({
              x: xAxisValue,
              y: value,
            });
          } else {
            pointsByRunAndMetric[run.id][metricName].nanPoints.push({
              x: xAxisValue,
              y: value,
            });
          }
        }
      });
    }
    /**
     * now we add the x-axis metric back in as a series of points where x & y are both the x-axis value. Without doing this legacy implementation of how expressions get evaluated won't work. The `for...of` here executes faster than a [...xVals].map(), and given how often this fn gets hit in panels we are optimizing for speed
     */
    const points = [];
    for (const x of xVals) {
      points.push({x, y: x});
    }
    pointsByRunAndMetric[run.id][xAxisName] = {
      nanPoints: [],
      points,
    };
  });

  return pointsByRunAndMetric;
}

function parsePoint(xAxisName: string, point: Record<string, any>) {
  const {[xAxisName]: xAxisValue, ...metricNames} = point;

  return [metricNames, xAxisValue] as const;
}

export function parseRunTimePoint(
  // @ts-ignore
  xAxisName: string,
  point: Record<string, any>,
  config: {
    startTime: number;
    isAbsoluteRuntime: boolean;
  }
) {
  const {_timestamp, _runtime, ...metricNames} = point;

  return [metricNames, _timestamp - config.startTime] as const;
}
