import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import classNames from 'classnames';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Popup} from 'semantic-ui-react';

import DOC_URLS from '../../../util/doc_urls';
import {PlotType} from '../../../util/plotHelpers/types';
import {RunsLinePlotConfig} from '../types';
import {LabeledOptionBlock as LabeledOption} from './../../elements/LabeledOptionBlock';

type PlotTypeOption = {
  type: PlotType;
  displayName: string;
  iconName: string;
};

const plotTypeOptions: PlotTypeOption[] = [
  {
    type: 'line',
    displayName: 'Line plot',
    iconName: 'standard-line-plot',
  },
  {
    type: 'stacked-area',
    displayName: 'Area plot',
    iconName: 'area-line-plot',
  },
  {
    type: 'pct-area',
    displayName: 'Percent area plot',
    iconName: 'percent-line-plot',
  },
];

type PlotTypeSelectionProps = {
  config: RunsLinePlotConfig;
  updateConfig: (newConfig: RunsLinePlotConfig) => void;
};
export const PlotTypeSelection = ({
  config,
  updateConfig,
}: PlotTypeSelectionProps) => {
  const plotType = config.plotType ?? 'line';
  return (
    <LabeledOption
      label="Chart type"
      helpText="Switch between line plot, area plot, and percentage area"
      docUrl={DOC_URLS.compareMetrics + '#plot-style'}
      option={
        <Button.Group>
          {plotTypeOptions.map(({type, displayName, iconName}) => (
            <Popup
              key={type}
              inverted
              size="mini"
              content={displayName}
              trigger={
                <Button
                  size="tiny"
                  className={classNames('wb-icon-button', 'only-icon', {
                    'action-button--active': plotType === type,
                  })}
                  onClick={() => updateConfig({plotType: type})}>
                  <LegacyWBIcon name={iconName} />
                </Button>
              }
            />
          ))}
        </Button.Group>
      }
    />
  );
};
