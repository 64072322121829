import React from 'react';
import {NavLink} from 'react-router-dom';

import {HOME_PATH} from '../../../routes/paths';
import {SidebarItem, SidebarItemProps} from './SidebarItem';

export const SidebarLink = (props: SidebarItemProps) => {
  return (
    <NavLink exact to={props.url ?? HOME_PATH}>
      <SidebarItem {...props} />
    </NavLink>
  );
};
