import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {Link} from '@wandb/weave/common/util/links';
import React, {useEffect} from 'react';

import * as S from './NudgeBar.styles';

type NudgeBarProps = {
  content: React.ReactNode;
  trackingLocation: string;
  linkText?: string | null;
  link?: string;
  showDismissOption?: boolean;
  inline?: true;
  bgcolor?: string;
  onClickLink?: () => void;
  buttonText?: string | null;
  onClickButton?: () => void;
  onClose?: () => void;
};

const NudgeBar: React.FC<NudgeBarProps> = React.memo((props: NudgeBarProps) => {
  useEffect(() => {
    window.analytics?.track('Nudge Bar Displayed', {
      location: props.trackingLocation,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Nudge inline={props.inline} bg={props.bgcolor}>
        <S.NudgeInner>
          <S.NudgeMessage>{props.content}</S.NudgeMessage>
          {props.linkText != null && props.link != null && (
            <S.NudgeLink>
              <Link
                className="nudge-info-link"
                to={props.link}
                onClick={props.onClickLink}>
                {props.linkText}
              </Link>
            </S.NudgeLink>
          )}
        </S.NudgeInner>

        <S.NudgeDismiss>
          {props.buttonText != null && (
            <S.NudgeBarActionLink onClick={props.onClickButton}>
              {props.buttonText}
            </S.NudgeBarActionLink>
          )}
          {props.showDismissOption && (
            <LegacyWBIcon
              name="close"
              className="close-button"
              onClick={props.onClose != null ? props.onClose : () => ({})}
              size="large"
            />
          )}
        </S.NudgeDismiss>
      </S.Nudge>
    </>
  );
});

export default NudgeBar;
