import {createAction} from 'typesafe-actions';

import * as RunTypes from '../../../util/runTypes';
import * as GroupSelectionsTypes from '../groupSelections/types';
import * as Types from './types';

export const selectAllVisible = createAction(
  '@view/tempSelections/selectAllVisible',
  action => (ref: Types.Ref, groupSelectionsRef: GroupSelectionsTypes.Ref) =>
    action({ref, groupSelectionsRef})
);

export const selectNone = createAction(
  '@view/tempSelections/selectNone',
  action => (ref: Types.Ref, groupSelectionsRef: GroupSelectionsTypes.Ref) =>
    action({ref, groupSelectionsRef})
);

export const selectToggle = createAction(
  '@view/tempSelections/selectToggle',
  action =>
    (
      ref: Types.Ref,
      groupSelectionsRef: GroupSelectionsTypes.Ref,
      run: RunTypes.Run,
      depth: number
    ) =>
      action({ref, groupSelectionsRef, run, depth})
);

export const selectSome = createAction(
  '@view/tempSelections/selectSome',
  action =>
    (
      ref: Types.Ref,
      groupSelectionsRef: GroupSelectionsTypes.Ref,
      runs: RunTypes.Run[]
    ) =>
      action({ref, groupSelectionsRef, runs})
);

export const selectAll = createAction(
  '@view/tempSelections/selectAll',
  action => (ref: Types.Ref, groupSelectionsRef: GroupSelectionsTypes.Ref) =>
    action({ref, groupSelectionsRef})
);
