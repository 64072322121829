import React, {FC, memo, useMemo} from 'react';

import {PUBLISHED_PROJECT_NAME} from '../../../../util/constants';
import {useFormatTag} from '../../../../util/gallery';
import {trackBreadcrumbClicked} from '../../../../util/navigation';
import * as links from '../../../../util/urls';
import Breadcrumbs, {BreadcrumbPart} from '../../../Breadcrumbs';

type NavBreadcrumbsFCReportProps = {
  entityName: string;
  projectName: string | undefined;
  reportID: string;
  reportName: string;
  galleryTag: string;
};

const NavBreadcrumbsFCReportComp: FC<NavBreadcrumbsFCReportProps> = ({
  entityName,
  projectName,
  reportID,
  reportName,
  galleryTag,
}) => {
  const formattedGalleryTag = useFormatTag(galleryTag);

  const parts: BreadcrumbPart[] = useMemo(
    () => [
      {label: `Fully Connected`, to: links.reportGallery()},
      {label: formattedGalleryTag, to: links.reportGallery({tag: galleryTag})},
      {
        label: reportName,
        to: `${links.reportView({
          entityName,
          projectName: projectName ?? PUBLISHED_PROJECT_NAME,
          reportID,
          reportName,
        })}${window.location.search}`,
        onClick: () => trackBreadcrumbClicked('Report'),
      },
    ],
    [
      entityName,
      projectName,
      reportID,
      reportName,
      galleryTag,
      formattedGalleryTag,
    ]
  );

  return <Breadcrumbs parts={parts} />;
};

const NavBreadcrumbsFCReport = memo(NavBreadcrumbsFCReportComp);

export default NavBreadcrumbsFCReport;
