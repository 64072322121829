import {createAction} from 'typesafe-actions';

import * as RunSetTypes from '../runSet/types';
import * as Types from './types';

export const setRunColor = createAction(
  '@view/section/setRunColor',
  action => (ref: Types.Ref, name: string, color: string) =>
    action({ref, name, color})
);

export const addNewRunSet = createAction(
  '@view/section/addNewRunSet',
  action => (ref: Types.Ref) => action({ref})
);

export const insertRunSet = createAction(
  '@view/section/addRunSet',
  action => (ref: Types.Ref, index: number, runSet: RunSetTypes.RunSetConfig) =>
    action({ref, index, runSet})
);

export const removeRunSet = createAction(
  '@view/section/removeRunSet',
  action => (ref: Types.Ref, runSetRef: RunSetTypes.Ref) =>
    action({ref, runSetRef})
);

export const duplicateRunSet = createAction(
  '@view/section/duplicateRunSet',
  action => (ref: Types.Ref, runSetRef: RunSetTypes.Ref) =>
    action({ref, runSetRef})
);

export const reorderRunSet = createAction(
  '@view/section/reorderRunSet',
  action => (ref: Types.Ref, indexFrom: number, indexTo: number) =>
    action({ref, indexFrom, indexTo})
);

export const setActiveIndex = createAction(
  '@view/section/setActiveIndex',
  action => (ref: Types.Ref, index: number | undefined) => action({ref, index})
);

export const setHideRunSets = createAction(
  '@view/section/setHideRunSets',
  action => (ref: Types.Ref, hide: boolean) => action({ref, hide})
);

export const setOpen = createAction(
  '@view/section/setOpen',
  action => (ref: Types.Ref, open: boolean) => action({ref, open})
);

export const setName = createAction(
  '@view/section/setName',
  action => (ref: Types.Ref, name: string) => action({ref, name})
);
