import * as globals from '@wandb/weave/common/css/globals.styles';
import {spu} from '@wandb/weave/common/css/globals.styles';
import {Link} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {Banner} from '../../../components/Banner';

export const FormRow = styled.div<{marginTopSpuMultiplier?: number}>`
  margin-top: calc(
    ${spu} *
      ${({marginTopSpuMultiplier}) =>
        marginTopSpuMultiplier ? marginTopSpuMultiplier : 3.5}
  );
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
`;

export const ProjectLink = styled(Link)`
  color: ${globals.RED_600};
  text-decoration: underline;
  &:hover {
    color: ${globals.RED_600};
    text-decoration: underline;
  }
`;

export const EditRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const BannerWrapper = styled(Banner)`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Description = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 20px;
`;
Description.displayName = 'S.Description';
