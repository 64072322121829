/* eslint-disable no-template-curly-in-string */

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'scatter-lite',
  displayName: 'Scatter plot (Vega lite)',
  description: 'A simple scatter plot implemented with Vega Lite',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega-lite/v3.json',
    data: {
      name: 'wandb',
    },
    title: 'Run data scatter plot',
    mark: 'point',
    encoding: {
      x: {
        field: '${field:x-axis}',
        type: 'quantitative',
      },
      y: {
        field: '${field:y-axis}',
        type: 'quantitative',
      },
      size: {
        field: '${field:size}',
        type: 'quantitative',
      },
      color: {
        field: '${field:color}',
        type: 'nominal',
      },
    },
  },
};

export default def;
