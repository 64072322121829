import {MemberData} from '../../../graphql/teamQuery';
import {checkIsServiceAccount} from '../../../util/serviceAccounts';

export interface InviteKey {
  id: string;
  name: string;
  createdAt: string;
  email: string;
  username: string;
  wasCreatedByViewer: boolean;
}

export enum TeamMemberRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  SERVICE = 'service',
  VIEW_ONLY = 'viewer',
}

export function getRoleLabel(member: MemberData) {
  if (member.memberRole == null) {
    return '';
  }
  if (checkIsServiceAccount(member.accountType)) {
    return 'service';
  }
  if (member.pending) {
    return 'invited';
  }
  return member.memberRole.name;
}
