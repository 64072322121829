// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const UnstyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;
