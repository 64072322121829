import type {ChartAggOption} from './../../../util/plotHelpers/chartTypes';
import {buildTimePoint, extendPoint, type PointWithMeta} from './buildPoints';
import {HistoryRecord} from './types';
import {
  getAreaSafeYKeys,
  getSafeXKey,
  getSafeYKey,
  sortPointsByX,
  takeLastDuplicate,
} from './util';

export function parseHistory(
  history: HistoryRecord[],
  metric: string,
  xMetric: string,
  otherMetrics: string[],
  config: {
    agg: 'Avg' | 'minMax';
    startTime: number;
    usingExpressions: boolean; // we don't need to extend the point values if we're not computing any expressions
  },
  // this value has to get mutated because we need to track it at the chart level
  groupAgg?: ChartAggOption
) {
  const finitePoints: PointWithMeta[] = [];
  const nanPoints: PointWithMeta[] = [];

  /**
   * We need to extend the point with all metrics so we can compute expressions later on. This extension won't include the y-axis metric because:
   * a) if we include it we might overwrite the yMin value the way the code is written on the area line
   * b) we can't compute y-expressions anyway because we can only do that from the output of built lines
   */
  const xMetrics = otherMetrics.concat(xMetric);

  const xKey = getSafeXKey(xMetric, metric, 'last');
  if (config.agg === 'Avg') {
    const yKey = getSafeYKey(metric, undefined, groupAgg);
    for (const historyP of history) {
      if (xMetric === '_absolute_runtime') {
        buildTimePoint(historyP, config.startTime);
      }
      const point = {x: Number(historyP[xKey]), y: Number(historyP[yKey])};
      if (!isFinite(point.x)) {
        continue;
      }
      if (config.usingExpressions) {
        extendPoint(point, historyP, xMetrics, groupAgg);
      }
      if (!isFinite(point.y)) {
        nanPoints.push(point);
      } else {
        finitePoints.push(point);
      }
      if (historyP[`${metric}HasNaN`]) {
        nanPoints.push({...point, y: NaN});
      }
    }
  } else {
    const {yKey, y0Key} = getAreaSafeYKeys(metric);
    for (const historyP of history) {
      if (xMetric === '_absolute_runtime') {
        buildTimePoint(historyP, config.startTime);
      }
      const v = {
        x: Number(historyP[xKey]),
        y: Number(historyP[yKey]),
        y0: Number(historyP[y0Key]),
      };
      if (config.usingExpressions) {
        extendPoint(v, historyP, xMetrics, groupAgg);
      }

      finitePoints.push(v);
    }
  }

  /**
   * If plotting by _step the API returns results in order of _step, custom x-axis values aren't guaranteed to be in order so we need to sort them. But because _step is incremented as a history is logged, we can always rely on the highest _step value being the most recent value
   */

  const isPlottedAgainstStep = xMetric === '_step';
  const sortedFinite = isPlottedAgainstStep
    ? finitePoints
    : finitePoints.sort(sortPointsByX).filter(takeLastDuplicate);
  const sortedNan = isPlottedAgainstStep
    ? nanPoints
    : nanPoints.sort(sortPointsByX).filter(takeLastDuplicate);

  /**
   * Special handling of first/last points for plots: When plotting binned points each point will render as xAvg, yAvg. This works fine except at the bounds of the chart. For those points we want to plot xMin and xMax (so that the first point shows at the xMin value for the first bin, and the final point in the line shows at the xMax value). This allows the line to span the full range of the chart and keeps users from thinking that their lines are missing data because they don't extend to the boundaries of the plotted range.
   *
   * We were previously doing this as part of the buildPoint logic, but there was a subtle bug where the first and last points in lines plotted by a custom x-axis value don't necessarily end up being the first and last points in the line. Extending each point to track the min/max x value as well so that at the end we can selectively grab the min/max x-value for just two of the points adds more overhead than required. Once we have the sorted output we can use the _step value in the first and last point to go grab that value from the original history record and update it.
   */

  const safeXKey = getSafeXKey(xMetric, metric, 'last');
  const safeXMaxKey = getSafeXKey(xMetric, metric, 'last');
  const safeMinKey = getSafeXKey(xMetric, metric, 'first');
  const safeStepKey = getSafeXKey('_step', metric, 'last');
  [sortedFinite, sortedNan].forEach(points => {
    if (points.length === 0) {
      return;
    }
    /**
     * Go through the history, find the places where the x-value matches the first or last points in the line. Because there can be multiple points in the history record (pre-filtered list) we need to find the one with the maximum _step value
     */
    const [firstPoint, lastPoint] = history.reduce(
      (acc, p) => {
        if (p[safeXKey] === points[0].x) {
          if (acc[0] === null) {
            acc[0] = p;
          } else if (p[safeStepKey] > acc[0][safeStepKey]) {
            acc[0] = p;
          }
        }
        if (p[safeXKey] === points[points.length - 1].x) {
          if (acc[1] === null) {
            acc[1] = p;
          } else if (p[safeStepKey] < acc[1][safeStepKey]) {
            acc[1] = p;
          }
        }
        return acc;
      },
      [null, null] as [HistoryRecord | null, HistoryRecord | null]
    );

    if (firstPoint) {
      points[0].x = firstPoint[safeMinKey] as number;
    }
    if (lastPoint) {
      points[points.length - 1].x = lastPoint[safeXMaxKey] as number;
    }
  });

  return {
    finitePoints: sortedFinite,
    nanPoints: sortedNan,
  };
}
