import {Tailwind} from '@wandb/weave/components/Tailwind';
import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox} from 'semantic-ui-react';

import {LabeledOptionBlock as LabeledOption} from '../elements/LabeledOptionBlock';
import {type PanelConfig} from './types';

type AggregationToggleProps = {
  checked: boolean;
  disabled: boolean;
  updateConfig: (newConfig: PanelConfig) => void;
};
export const AggregationToggle = ({
  disabled,
  checked,
  updateConfig,
}: AggregationToggleProps) => {
  return (
    <Tailwind>
      <LabeledOption
        label="Windowing"
        helpText="Windowing aggregates data into sections along the x-axis, averaging points within each section. Without windowing, only points with the same x-axis value are averaged together."
        option={
          <Checkbox
            toggle
            disabled={disabled}
            checked={checked}
            name="Windowing switch"
            onChange={(e, value) => {
              updateConfig({
                windowing: value.checked,
              });
            }}
          />
        }
      />
    </Tailwind>
  );
};
