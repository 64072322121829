import Markdown from '@wandb/weave/common/components/Markdown';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {GenericObject, MatchParams} from '@wandb/weave/common/types/base';
import {isMarkdown} from '@wandb/weave/common/util/runhelpers';
import _ from 'lodash';
import numeral from 'numeral';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import {TruncationType} from '../components/common/TruncateText/TruncateTextTypes';
// eslint-disable-next-line import/no-cycle
import BarChart from '../components/vis/BarChart/BarChart';
import {
  WBTableColumn,
  WBTableRowFields,
  WBTableRowInterface,
} from '../components/WBTable/types';
import {DEFAULT_X_AXIS_OPTION_VALUES} from '../components/WorkspaceDrawer/Settings/runLinePlots/linePlotDefaults';
import {RunsData, RunWithRunsetInfo} from '../containers/RunsDataLoader';
import * as GqlTypes from '../types/graphql';
import {
  RunHistoryKeyInfo,
  RunHistoryKeyInfoKeys,
  RunHistoryKeyType,
  RunHistoryRow,
  RunKeyInfo,
} from '../types/run';
import {Query} from '../util/queryTypes';
import {Bar} from './plotHelpers/types';
import * as Run from './runs';
import * as RunTypes from './runTypes';
import * as urls from './urls';
export * from '@wandb/weave/common/util/runhelpers';

export function keySuggestions(fields: string[]): string[] {
  return ['displayName', 'createdAt']
    .concat(
      fields
        .map(Run.serverPathToKeyString)
        .filter(
          k =>
            k != null &&
            !k.startsWith('config:_wandb') &&
            k !== 'config:wandb_version'
        ) as string[]
    )
    .sort();
}

export function mergeKeyVals(keyVals: RunTypes.KeyVal[]): RunTypes.KeyVal {
  const keys = _.uniq(_.flatMap(keyVals, (kv: RunTypes.KeyVal) => _.keys(kv)));
  const result: {[key: string]: RunTypes.Value | undefined} = {};
  for (const key of keys) {
    const values = keyVals.map(kv => kv[key]);
    if (typeof values[0] === 'number') {
      result[key] = _.sum(values) / values.length;
    } else {
      result[key] = values[0];
    }
  }
  return result;
}

// Only used by unit tests (please change this comment if no longer true)
export function mergeRunsBase(runs: RunTypes.Run[]) {
  return {
    state: runs[0].state,
    user: runs[0].user,
    host: runs[0].host,
    createdAt: _.min(runs.map(r => r.createdAt)) || new Date(0).toISOString(),
    updatedAt: _.max(runs.map(r => r.updatedAt)) || new Date(0).toISOString(),
    heartbeatAt:
      _.max(runs.map(r => r.heartbeatAt)) || new Date(0).toISOString(),
    tags: runs[0].tags,
    group: runs[0].group,
    jobType: runs[0].jobType,
    displayName: '',
    notes: '',
    stopped: runs[0].stopped,
  };
}

// Only used by unit tests (please change this comment if no longer true)
export function mergeRuns(
  runs: RunTypes.Run[],
  name: string,
  subgroupKey?: string | null
): RunTypes.Run {
  const groupCounts: number[] = [];
  if (subgroupKey != null) {
    groupCounts.push(_.uniq(runs.map(run => run.config[subgroupKey])).length);
  }
  groupCounts.push(runs.length);
  const aggregationMin = mergeKeyVals(runs.map(r => r.aggregations.min));
  const aggregationMax = mergeKeyVals(runs.map(r => r.aggregations.max));
  return {
    ...mergeRunsBase(runs),
    id: name,
    name,
    config: mergeKeyVals(runs.map(r => r.config)),
    _wandb: mergeKeyVals(runs.map(r => r._wandb)),
    summary: mergeKeyVals(runs.map(r => r.summary)),
    aggregations: {min: aggregationMin, max: aggregationMax},
    groupCounts,
  };
}

// wraps displayValue in a <span>
export function displayValueSpan(value: any): string | React.ReactElement<any> {
  return <span title={JSON.stringify(value)}>{displayValue(value)}</span>;
}

function determinePrecision(value: number) {
  // returning 5 for now
  // https://weightsandbiases.slack.com/archives/C01KQ5KTDC3/p1723477711255159
  return 5;
}

// http://numeraljs.com/#format
// the format template will drop trailing zeroes when the wrapping `[]` syntax is used:
// "0.1200" ->"0.[0000]" -> "0.12"
export function formatTemplateBySigDigits(sigDigits: number) {
  return `0.[${'0'.repeat(Math.max(1, sigDigits))}]`;
}

export function removeTrailingZeros(stringNum: string) {
  // don't trim anything from numbers in scientific notation
  if (stringNum.includes('e')) {
    return stringNum;
  }
  while (stringNum.endsWith('0')) {
    stringNum = stringNum.slice(0, -1);
  }

  if (stringNum.endsWith('.')) {
    return stringNum.slice(0, -1);
  }

  return stringNum;
}

export function displayValue(value: any): string | React.ReactElement<any> {
  if (
    _.isString(value) &&
    value.length &&
    value[0] === '{' &&
    value[value.length - 1] === '}'
  ) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      // parse error; leave as a string.
    }
  }
  if (_.isNull(value) || _.isUndefined(value)) {
    return '-';
  } else if (typeof value === 'number') {
    if (_.isFinite(value)) {
      if (_.isInteger(value)) {
        return value.toString();
      } else {
        const sigDigits = determinePrecision(value);

        if (value < 1 && value > -1) {
          return removeTrailingZeros(value.toPrecision(sigDigits));
        } else {
          return numeral(value).format(formatTemplateBySigDigits(sigDigits));
        }
      }
    } else {
      return value.toString();
    }
  } else if (_.isString(value)) {
    // This could really bite you - with love from CVP (made more specific by Shawn)
    // TODO: This function should only return strings. Many callsites probably depend on
    // on that. We should have another displayValueHTML function that can return either a
    // string or a JSX element.
    if (value.match(/^(http|https|s3|gs|ftp):/)) {
      return React.createElement(
        'a',
        {href: value},
        value.substr(0, 25) + '...'
      );
    } else if (isMarkdown(value)) {
      return (
        <Markdown
          condensed={false}
          content={value.substring(3, value.length - 3)}
        />
      );
    } else {
      return value;
    }
  } else if (value._type) {
    if (value._type === 'histogram') {
      const dataPoints: Bar[] = value.values.map(
        (elem: number, index: number) => {
          const key =
            value.bins && value.bins.length > index
              ? value.bins[index].toPrecision(4)
              : '-';
          return {
            key,
            value: elem,
            color: globals.darkBlue,
            title: 'x= ' + key + ', y=',
          };
        }
      );
      return (
        <BarChart bars={dataPoints} vertical={true} height={150}></BarChart>
      );
    } else if (value._type === 'images') {
      return 'Image';
    } else {
      return value._type;
    }
  } else {
    return JSON.stringify(value);
  }
}

export function sortableValue(value: any): string | number | boolean {
  /**
   * Takes a value and returns something that we can sort on
   * and compare. Used by run filters.
   */
  if (
    typeof value === 'number' ||
    typeof value === 'string' ||
    typeof value === 'boolean'
  ) {
    return value;
  } else {
    return JSON.stringify(value);
  }
}

// returns a link to a run. linkText defaults to run name
export function runLink(
  matchParams: MatchParams,
  linkText?: string,
  extraProps?: GenericObject
) {
  extraProps = extraProps || {};
  const {runName} = matchParams;
  return (
    <Link {...extraProps} to={runPath(matchParams)} title={linkText || runName}>
      {linkText || runName}
    </Link>
  );
}

// returns a path to a run
export function runPath(matchParams: MatchParams) {
  const {entityName, projectName, runName} = matchParams;
  return `/${entityName}/${projectName}/runs/${runName}`;
}

export function truncateString(
  s: string,
  maxLength: number = 30,
  runNameTruncationType: TruncationType = TruncationType.Middle
): string {
  /**
   * Truncate string. Doesn't seem like there's an easy way to do this
   * by pixel length. Splits the string in the middle.
   */
  const sString = s as string;
  if (sString.length <= maxLength) {
    return sString;
  }

  if (runNameTruncationType === TruncationType.Beginning) {
    return '…' + sString.substr(-maxLength);
  }

  if (runNameTruncationType === TruncationType.End) {
    return sString.substr(0, maxLength) + '…';
  }

  // default to middle truncation
  if (maxLength < 4) {
    return sString.substr(0, Math.max(1, maxLength)) + '…';
  }
  const rightLength = Math.floor(maxLength / 2) - 1;
  const leftLength = maxLength - rightLength - 1;
  const leftSide = sString.substr(0, leftLength);
  const rightSide = sString.substr(-rightLength);
  const truncString = leftSide + '…' + rightSide;
  return truncString;
}

export const truncateDisplayName = (displayName: string) => {
  if (displayName.length > 20) {
    return displayName.substr(0, 19) + '...';
  } else {
    return displayName;
  }
};

interface IPusherProjectSlug {
  entityName: string;
  projectName: string;
}

export function pusherProjectSlug<T extends IPusherProjectSlug>(
  params: T
): string {
  return `${params.entityName}@${params.projectName}`;
}

interface IPusherRunSlug extends IPusherProjectSlug {
  runName: string;
}
// Generates pusher identifier for logs
export function pusherRunSlug<T extends IPusherRunSlug>(params: T): string {
  return `${pusherProjectSlug(params)}.${params.runName}`;
}

// forceColumn is a single RunTypes.Key that will always be displayed (we use it
// to ensure that the sort column is displayed)
export function autoCols<T extends RunTypes.Run>(
  section: 'config' | 'summary',
  runs: T[],
  minUniq: number,
  forceColumn?: any // {[id: string]: any}
): string[] {
  runs = runs.filter((run: any) => run[section]);
  if (runs.length === 0) {
    return [];
  }
  const allKeys = _.uniq(_.flatMap(runs, run => _.keys(run[section])));
  const result: {[id: string]: boolean} = {};
  for (const key of allKeys) {
    if (runs.length === 1) {
      result[key] = true;
      continue;
    }
    const vals = runs.map((run: any) => run[section][key]);
    // const types = _.uniq(vals.map((val: any) => typeof val));
    const typeCounts = _.countBy(
      vals.map(val =>
        val == null ? 'null' : val._type != null ? 'wandb_type' : typeof val
      )
    );
    // Get the most common
    let type = 'wandb_type';
    let count = 0;
    _.each(typeCounts, (v, k) => {
      if (v > count) {
        count = v;
        type = k;
      }
    });
    if (
      forceColumn &&
      section === forceColumn.section &&
      key === forceColumn.name
    ) {
      result[key] = true;
    } else if (vals.length === 0) {
      result[key] = false;
    } else if (
      key.includes('biases/summaries') ||
      key.includes('weights/summaries')
    ) {
      result[key] = false;
    } else if (type === 'wandb_type') {
      result[key] = false;
    } else {
      const uniqVals = _.uniq(vals.map((v: any) => JSON.stringify(v)));
      if (type === 'string') {
        // Show columns that have differing values, unless there are more than 10 runs and all values differ
        if (
          uniqVals.length > minUniq &&
          (vals.length < 10 || uniqVals.length < vals.length)
        ) {
          result[key] = true;
        } else {
          result[key] = false;
        }
      } else {
        if (vals.every((val: any) => _.isArray(val) && val.length === 0)) {
          // Special case for empty arrays, we don't get non-empty arrays
          // as config values because of the flattening that happens at a higher
          // layer.
          result[key] = false;
        } else if (
          vals.every((val: any) => _.isObject(val) && _.keys(val).length === 0)
        ) {
          // Special case for empty objects.
          result[key] = false;
        } else {
          // Show columns that have differing values even if all values differ
          if (uniqVals.length > minUniq) {
            result[key] = true;
          } else {
            result[key] = false;
          }
        }
      }
    }
  }
  return _.map(result, (enable, key) => enable && key)
    .filter(o => o && !_.startsWith(o, '_'))
    .sort()
    .map(key => section + ':' + key);
}

export const RUN_DISPLAY_NAME_COL = 'run:displayName';
export const RUN_NOTES_COL = 'run:notes';
export const RUN_TAGS_COL = 'tags:__ALL__';

const AUTO_COLS_ALWAYS_INCLUDE = [
  RUN_DISPLAY_NAME_COL,
  RUN_NOTES_COL,
  RUN_TAGS_COL,
];

export function autoColsNew<T extends RunTypes.Run>(
  allColumns: WBTableColumn[],
  runs: T[],
  minUniq: number
): string[] {
  if (runs.length === 0) {
    return [];
  }

  const includedAccessors = [];

  for (const col of allColumns) {
    const {key, accessor} = col;

    if (AUTO_COLS_ALWAYS_INCLUDE.indexOf(accessor) !== -1) {
      includedAccessors.push(accessor);
      continue;
    }

    if (shouldExcludeKeyNameFromAutoCols(key.name)) {
      continue;
    }

    if (runs.length === 1) {
      includedAccessors.push(accessor);
      continue;
    }

    const vals = runs.map(r => Run.getValue(r, key));
    const mostCommonType = getMostCommonType(vals);

    if (mostCommonType === 'wandb_type') {
      continue;
    }

    const uniqVals = _.uniq(vals.map(v => JSON.stringify(v)));

    if (mostCommonType === 'string') {
      // Show columns that have differing values, unless there are more than 10 runs and all values differ
      if (
        uniqVals.length > minUniq &&
        (vals.length < 10 || uniqVals.length < vals.length)
      ) {
        includedAccessors.push(accessor);
      }
      continue;
    }

    // Special case for empty arrays, we don't get non-empty arrays
    // as config values because of the flattening that happens at a higher
    // layer.
    if (vals.every(val => _.isArray(val) && val.length === 0)) {
      continue;
    }

    // Special case for empty objects.
    if (vals.every(val => _.isObject(val) && _.keys(val).length === 0)) {
      continue;
    }

    // Show columns that have differing values even if all values differ
    if (uniqVals.length > minUniq) {
      includedAccessors.push(accessor);
    }
  }

  return _.uniq(includedAccessors).sort();
}

function getMostCommonType(vals: RunTypes.Value[]): string {
  const typeCounts = _.countBy(
    vals.map(val =>
      val == null
        ? 'null'
        : (val as any)._type != null
        ? 'wandb_type'
        : typeof val
    )
  );

  let type = 'wandb_type';
  let count = 0;
  _.each(typeCounts, (v, k) => {
    if (v > count) {
      count = v;
      type = k;
    }
  });

  return type;
}

function shouldExcludeKeyNameFromAutoCols(keyName: string): boolean {
  return (
    _.startsWith(keyName, '_wandb') ||
    keyName.includes('biases/summaries') ||
    keyName.includes('weights/summaries')
  );
}

export function getColumns<T extends RunTypes.Run>(runs: T[]): string[] {
  return autoCols('config', runs, 1, undefined).concat(
    autoCols('summary', runs, 0, undefined)
  );
}

export function groupByCandidates(configs: RunTypes.KeyVal[]): string[] {
  /* We want to pull out the configurations that a user might want to groupBy
   * this would be any config that has more than one value that's different
   * and more than one values that is the same
   */
  const configKeys: Set<string> = new Set();
  // get all the keys from all the configs

  configs.forEach((c, i) => {
    _.keys(c).forEach((key, j) => {
      configKeys.add(key);
    });
  });
  const k = Array.from(configKeys.keys()).slice();
  const interesting = k.filter((key, i) => {
    const uniq = Array.from(new Set(configs.map((c, ii) => c[key]))).slice();
    return uniq.length > 1 && uniq.length < configs.length;
  });
  return interesting;
}

// These variables facilitate memoization of the previous result of the keyTypes function
let memoizedKeyTypes: {[key: string]: RunHistoryKeyType} | null = null;
let memoizedKeys: RunHistoryKeyInfoKeys | null = null;

/**
 * This function returns the types of keys present in the given RunHistoryKeyInfoKeys object.
 * It memoizes the result to avoid redundant calculations for the same input.
 *
 * @param {RunHistoryKeyInfoKeys} keys - The keys object containing type counts for each key.
 * @returns {Object} An object mapping each key to its type.
 */
export function keyTypes(keys: RunHistoryKeyInfoKeys): {
  [key: string]: RunHistoryKeyType;
} {
  // Read memoized result if appropriate
  if (keys === memoizedKeys && memoizedKeyTypes) {
    return memoizedKeyTypes;
  }

  const result: {[key: string]: RunHistoryKeyType} = {};
  for (const key of Object.keys(keys)) {
    const {typeCounts = []} = keys[key];
    let maxSeenCount = -1;
    for (const typeCount of typeCounts) {
      if (typeCount.type !== 'unknown' && typeCount.count > maxSeenCount) {
        result[key] = typeCount.type;
        maxSeenCount = typeCount.count;
      }
    }
  }

  memoizedKeys = keys;
  memoizedKeyTypes = result;
  return result;
}

export const useKeyTypes: typeof keyTypes = keys =>
  useMemo(() => keyTypes(keys), [keys]);

/**
 * Return the key names for an object where the value matches a list of types
 */
export function keysOfType(
  kts: {[key: string]: RunHistoryKeyType},
  ofType: string | string[]
): string[] {
  if (!_.isArray(ofType)) {
    ofType = [ofType];
  }
  return _.keys(_.pickBy(kts, type => _.indexOf(ofType, type) !== -1));
}

// Computes a weighted value for how "heavy" queries to fetch history data are, given this run.
// Query performance is affected by the total size of each row, and the total number of rows in
// the dataset, currently.
// We use a 'divide by 10' in the caller to compute pollTime.
// 10 scalars, 10 rows = 100
// 100 scalars, 1000 rows = 100,000
// 100 histograms, 500 rows = 2,000,000
// 100 scalars, 10000 rows = 1,000,000
// 100 histograms, 10000 rows = 400,000,000
export function queryWeight(keyInfo: RunHistoryKeyInfo): number {
  return (
    _.sum(
      _.map(keyInfo.keys, (ki, key) =>
        _.sum(
          (ki.typeCounts ?? []).map(
            tc => tc.count * (tc.type === 'histogram' ? 30 : 1)
          )
        )
      )
    ) || 0
  );
}

export function firstHistory(data: RunsData): RunHistoryRow[] {
  if (data.histories.data.length > 0) {
    return data.histories.data[0].history;
  }
  return [];
}

// Returns keys that are numbers
export function globalXAxisOptions(keyInfo: RunHistoryKeyInfo): string[] {
  const types = keyTypes(keyInfo.keys);
  const allKeys = _.keys(keyInfo.keys)
    .filter(k => !DEFAULT_X_AXIS_OPTION_VALUES.includes(k))
    .filter(k => types[k] === 'number');

  const opts = allKeys;

  // Filter the keys that are always present with other keys
  // LB: Removing this logic for now since customers seem to want
  //     all kinds of wild x-axis

  /*const opts = allKeys
    .reduce((accum, key) => {
      const containingSets = keyInfo.sets
        .filter(set => _.includes(set.keys, key))
        .map(set => set.keys);
      return _.intersection(accum, _.union(...containingSets));
    }, allKeys)
    .sort();*/

  const score = (opt: string) => {
    if (opt.startsWith('system')) {
      // put the system metrics last
      return -2;
    } else if (opt === 'global_step') {
      // put global_step first
      return 2;
    } else if (opt === 'epoch') {
      // move epoch to the top since many people use this
      return 1;
    } else if (keyInfo.keys[opt] && keyInfo.keys[opt].monotonic) {
      // put monotonic x axis options higher
      return 0;
    } else {
      return -1;
    }
  };

  opts.sort((a, b) => {
    return score(b) - score(a);
  });

  return opts;
}

export function runsToKeyInfo(runs: RunTypes.Run[]): RunKeyInfo {
  const rki: RunKeyInfo = {};
  const parseSection = (sectionKey: RunTypes.RunKeySection) =>
    runs.forEach(r => {
      let section: RunTypes.KeyVal = {};
      if (sectionKey === 'config') {
        section = r.config;
      } else if (sectionKey === 'summary') {
        section = r.summary;
      } else if (sectionKey === 'aggregations_min') {
        section = r.aggregations.min;
      } else if (sectionKey === 'aggregations_max') {
        section = r.aggregations.max;
      }
      _.forEach(section, (v, subKey) => {
        const k = Run.keyToString(Run.key(sectionKey, subKey));
        if (rki[k] == null) {
          rki[k] = {
            valueCount: {},
            distinctCount: 0,
            majorType: 'unknown',
          };
        }
        const ki = rki[k];
        if (
          typeof v === 'number' ||
          typeof v === 'boolean' ||
          (typeof v === 'string' && _.keys(ki.valueCount).length < 100)
        ) {
          const sVal = JSON.stringify(v);
          if (ki.valueCount[sVal] == null) {
            ki.valueCount[sVal] = 0;
          }
          ki.valueCount[sVal] += 1;
        }
        if (Run.isWBValue(v) && v._type != null) {
          ki.majorType = v._type;
        } else if (typeof v === 'number') {
          ki.majorType = 'number';
        } else if (typeof v === 'string') {
          ki.majorType = 'string';
        } else if (typeof v === 'boolean') {
          ki.majorType = 'boolean';
        }
      });
    });
  parseSection('config');
  parseSection('summary');
  parseSection('aggregations_min');
  parseSection('aggregations_max');

  _.forEach(rki, (ki, k) => {
    ki.distinctCount = _.keys(ki.valueCount).length;
  });

  return rki;
}

export function hasDiffPatchFile(r: GqlTypes.RunPageRunQuery) {
  return !!(
    r.diffPatchFile &&
    r.diffPatchFile.edges.length > 0 &&
    r.diffPatchFile.edges[0].node.sizeBytes &&
    r.diffPatchFile.edges[0].node.sizeBytes !== 0
  );
}

export function metadataFile(r: GqlTypes.RunPageRunQuery) {
  return r.metadataFile &&
    r.metadataFile.edges.length > 0 &&
    r.metadataFile.edges[0].node.sizeBytes &&
    r.metadataFile.edges[0].node.sizeBytes !== 0
    ? r.metadataFile.edges[0].node.directUrl
    : null;
}

export function outputLogFile(r: GqlTypes.RunPageRunQuery) {
  return r.outputLogFile &&
    r.outputLogFile.edges.length > 0 &&
    r.outputLogFile.edges[0].node.sizeBytes &&
    r.outputLogFile.edges[0].node.sizeBytes !== 0
    ? r.outputLogFile.edges[0].node.url
    : null;
}

export interface RunWithRunsetInfoAndHistory extends RunWithRunsetInfo {
  history: RunHistoryRow[];
}

export function runsetData(
  filtered: RunsData['filtered'],
  histories: RunsData['histories'],
  runSetID?: string
): RunWithRunsetInfoAndHistory[] {
  // Output (optionally) filtered RunsData with histories broken out adjacent to each run.
  const runs =
    runSetID != null
      ? filtered.filter(r => r.runsetInfo.id === runSetID)
      : filtered;
  return runs.map(r => {
    const history = histories.data.find(h => h.name === r.name);
    if (history == null) {
      throw new Error('Programming error: invalid RunsDataLoader result');
    }
    return {...r, history: history.history};
  });
}

/**
 * A potentially fragile hack to check if a run is a group.
 * Beware.
 */
export function runIsActuallyGroup(run: RunWithRunsetInfo) {
  return run.group === run.name;
}

export function conditionalRunOrGroupLink(
  run: RunWithRunsetInfo,
  entityName: string,
  projectName: string,
  color?: string
) {
  return run.groupCounts ? (
    runIsActuallyGroup(run) ? (
      <Link
        style={{color}}
        to={urls.runGroup({
          entityName,
          projectName,
          name: run.name,
        })}>
        {run.displayName}
      </Link>
    ) : (
      <span>{run.displayName}</span>
    )
  ) : (
    <Link
      style={{color}}
      to={urls.run({
        entityName,
        projectName,
        name: run.name,
      })}>
      {run.displayName}
    </Link>
  );
}

export const isGrouped = (query: {runSets?: Query['runSets']}) => {
  // Runs can be grouped in two ways:
  // 1) If there is grouping in the data populating the plot, the plot
  // will have grouping.
  // 2) the user selected grouping in the panel (config.aggregate)
  return (
    query.runSets != null &&
    query.runSets.filter((rs: any) => rs.enabled && rs.grouping.length > 0)
      .length > 0
  );
};

export type WBTableRowWithRunSetInfo = WBTableRowFields<RunWithRunsetInfo>;

// Type guard to test if a row in WBTable is a run
export function rowIsRun(
  row: WBTableRowInterface | WBTableRowWithRunSetInfo
): row is WBTableRowWithRunSetInfo {
  return (row as WBTableRowWithRunSetInfo)._wandb !== undefined;
}
