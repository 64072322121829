/* This is our csv export modal.

This component will generate a csv for any RunsDataQuery. We allow passing
a panel config in because so we can do special post-processing for specific
panel types. This should be refactored so that it doesn't need to know anything
about panels themselves.
*/
import {saveTableAsCSV} from '@wandb/weave/common/util/csv';
import * as React from 'react';
import {useCallback, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {StrictButtonProps} from 'semantic-ui-react';

// eslint-disable-next-line import/no-cycle
import {LayedOutPanel, useTableData} from '../../util/panelTypes';
import {Query} from '../../util/queryTypes';
import {InstrumentedLoader as Loader} from '../utility/InstrumentedLoader';
import * as S from '../WBModal.styles';

type ExportProps = {
  pageQuery: Query;
  panel: LayedOutPanel;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
};

const Export = (props: ExportProps) => {
  const {trigger, open, onClose} = props;

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => {
    setModalOpen(false);
    onClose?.();
  }, [onClose]);

  return (
    <S.WBModal
      width="1000px"
      height="785px"
      trigger={trigger}
      open={open}
      onOpen={openModal}
      onClose={closeModal}>
      <S.WBModalHeader>Export Preview</S.WBModalHeader>
      <S.WBModalContent>
        {/* we only render the content if the modal is actually open, since
          the ExportModalContent component actually does the query*/}
        {modalOpen || open ? (
          <ExportModalContent {...props} onClose={closeModal} />
        ) : (
          <div />
        )}
      </S.WBModalContent>
    </S.WBModal>
  );
};

type ExportContentProps = ExportProps & {
  onClose(): void;
};

const ExportModalContent = (props: ExportContentProps) => {
  const {pageQuery, panel, onClose} = props;
  const result = useTableData(pageQuery, panel);

  return result.loading ? (
    <Loader name="export-modal-loader" />
  ) : (
    <>
      <S.WBModalTable
        noSearch
        columns={result.table.cols.map(c => ({
          id: c,
          Header: c,
          accessor: r => r[c],
        }))}
        data={result.table.data.map(r => ({searchString: '', row: r}))}
        pageSize={20}
      />
      <S.WBModalButton
        primary
        floated="right"
        content="Save as CSV"
        onClick={
          (e => {
            window.analytics?.track('Export panel', {
              viewType: props.panel.viewType,
              downloadType: 'csv',
            });
            e.stopPropagation();
            saveTableAsCSV(result.table);
            onClose();
          }) as StrictButtonProps['onClick']
        }
      />
    </>
  );
};

export default Export;
