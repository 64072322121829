import {useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {
  SettingType,
  trackSetting,
} from '../../../../services/analytics/workspaceSettingsEvents';
import {useStablePanelId} from '../../../Panel/PanelIDContext';

export const useTrackSettingPage = () => {
  const params = useParams<{
    entityName: string;
    projectName: string;
    reportNameAndID?: string;
  }>();
  return params?.reportNameAndID == null ? 'workspace' : 'report';
};

export const useTrackSettingsChange = () => {
  const stablePanelId = useStablePanelId();
  const trackSettingPage = useTrackSettingPage();

  return useCallback(
    (args: {
      setting: SettingType;
      settingValue: string | undefined;
      additionalProperties?: Record<string, any>;
    }) => {
      trackSetting({
        ...args,
        action: 'change',
        page: trackSettingPage,
        settingLevel: 'panel',
        additionalProperties: {
          panelId: stablePanelId,
          ...args.additionalProperties,
        },
      });
    },
    [stablePanelId, trackSettingPage]
  );
};
