import {
  constFunction,
  constString,
  escapeDots,
  list,
  opConcat,
  opMap,
  opPick,
  opRunHistory,
  opRunSummary,
  typedDict,
  varNode,
} from '@wandb/weave/core';

export interface WeaveConfig {
  panel2Config?: any;
  defaultWorkspaceState?: {
    panel2Config?: any;
    key: string;
    keyType: string;
  };
}

export function weavePanelForWeaveKey(
  key: string,
  keyType: string
): WeaveConfig {
  // var name does not matter, PanelExpression will auto-replace.
  // Using something unique & ugly to force out errors.
  const panel2Config: any = {};
  const vNode = varNode(list('run'), 'runs');
  if (keyType === 'wb_trace_tree') {
    panel2Config.exprAndPanelLocked = true;
    panel2Config.exp = opConcat({
      arr: opMap({
        arr: vNode,
        mapFn: constFunction({row: typedDict({})}, ({row}) => {
          return opRunHistory({
            run: row,
          });
        }),
      }),
    });
    panel2Config.panelId = 'wb_trace_tree-traceDebuggerFromRunHistory';
    panel2Config.panelConfig = {
      traceKey: key,
    };
  } else {
    const tableNode = opPick({
      obj: opRunSummary({run: vNode}),
      key: constString(escapeDots(key)),
    });
    panel2Config.exp = tableNode;
  }

  return {
    panel2Config,
    defaultWorkspaceState: {
      panel2Config,
      key,
      keyType,
    },
  };
  // Uncomment the below to auto-inject the table merge. For now, I
  // prefer not auto-injecting, but this may change as we get feedback.
  // const newExp = callOp(Panel2.panelIdToPanelOpName('merge'), {
  //   input: runTableNode,
  //   config: Op.constNodeUnsafe('any', {}),
  // });

  // return {
  //   panel2Config: {exp: newExp},
  // };
}
