import {isDraft} from 'immer';

import * as CustomRunColorsNormalize from './customRunColors/normalize';
import * as DiscussionCommentNormalize from './discussionComment/normalize';
import * as DiscussionThreadNormalize from './discussionThread/normalize';
import * as FilterNormalize from './filter/normalize';
import * as GroupPageNormalize from './groupPage/normalize';
import * as GroupSelectionsNormalize from './groupSelections/normalize';
import * as MarkdownBlockNormalize from './markdownBlock/normalize';
import {
  DenormalizationOptions,
  DenormFunctionMap,
  FullDenormFn,
  PartWithRef,
  StateType,
} from './normalizerSupport';
import * as PanelNormalize from './panel/normalize';
import * as PanelBankConfigNormalize from './panelBankConfig/normalize';
import * as PanelBankSectionConfigNormalize from './panelBankSectionConfig/normalize';
import * as PanelsNormalize from './panels/normalize';
import * as PanelSettingsNormalize from './panelSettings/normalize';
import * as ProjectPageNormalize from './projectPage/normalize';
import * as ReportNormalize from './report/normalize';
import * as ReportDraftNormalize from './reportDraft/normalize';
import * as RunPageNormalize from './runPage/normalize';
import * as RunSetNormalize from './runSet/normalize';
import * as SectionNormalize from './section/normalize';
import * as SortNormalize from './sort/normalize';
import * as SweepPageNormalize from './sweepPage/normalize';
import * as TempSelectionsNormalize from './tempSelections/normalize';
import * as Types from './types';
import * as WorkspaceSettingsNormalize from './workspaceSettings/normalize';

const denormFunctions: DenormFunctionMap = {
  'project-view': ProjectPageNormalize.denormalize,
  'group-view': GroupPageNormalize.denormalize,
  'sweep-view': SweepPageNormalize.denormalize,
  'run-view': RunPageNormalize.denormalize,
  runs: ReportNormalize.denormalize,
  'runs/draft': ReportDraftNormalize.denormalize,
  'markdown-block': MarkdownBlockNormalize.denormalize,
  runSet: RunSetNormalize.denormalize,
  section: SectionNormalize.denormalize,
  panels: PanelsNormalize.denormalize,
  panel: PanelNormalize.denormalize,
  panelSettings: PanelSettingsNormalize.denormalize,
  sort: SortNormalize.denormalize,
  filters: FilterNormalize.denormalize,
  'group-selections': GroupSelectionsNormalize.denormalize,
  'run-colors': CustomRunColorsNormalize.denormalize,
  'temp-selections': TempSelectionsNormalize.denormalize,
  'panel-bank-config': PanelBankConfigNormalize.denormalize,
  'panel-bank-section-config': PanelBankSectionConfigNormalize.denormalize,
  'discussion-thread': DiscussionThreadNormalize.denormalize,
  'discussion-comment': DiscussionCommentNormalize.denormalize,
  'workspace-settings': WorkspaceSettingsNormalize.denormalize,
};

type RefType = Types.PartRefFromType<Types.ObjType>;

interface CacheValue {
  whole: WeakRef<Types.WholeFromTypeWithRef<any>>;
  partsWithRef: WeakRef<Array<PartWithRef<any>>>;
  state: WeakRef<StateType>;
}

interface DenormalizedResult {
  whole: Types.WholeFromTypeWithRef<any>;
  partsWithRef: Array<PartWithRef<any>>;
}

const hasWeakMap = 'WeakMap' in globalThis;
const hasWeakRef = 'WeakRef' in globalThis;

class Denormalize {
  cache: Map<string, CacheValue>;
  refCache?: WeakMap<RefType, string>;

  constructor() {
    this.cache = new Map();
    this.refCache = hasWeakMap ? new WeakMap<RefType, string>() : undefined;
  }

  getFromRefCache(ref: RefType) {
    if (!this.refCache) {
      return null;
    }
    return this.refCache.get(ref) ?? null;
  }

  setToRefCache(ref: RefType, refString: string) {
    if (!this.refCache) {
      return;
    }
    this.refCache.set(ref, refString);
  }

  refToCacheKey(ref: RefType) {
    const cached = this.getFromRefCache(ref);
    if (cached) {
      return cached;
    }
    // Make the ref's key order canonical for extra paranoia:
    const refString = JSON.stringify({
      id: ref.id,
      type: ref.type,
      viewID: ref.viewID,
    });
    this.setToRefCache(ref, refString);
    return refString;
  }

  getFromCache(ref: RefType) {
    if (hasWeakRef) {
      return this.cache.get(this.refToCacheKey(ref));
    }
    return null;
  }

  addToCache(
    ref: RefType,
    state: StateType,
    denormalizedResult: DenormalizedResult
  ) {
    if (hasWeakRef) {
      this.cache.set(this.refToCacheKey(ref), {
        whole: new WeakRef(denormalizedResult.whole),
        partsWithRef: new WeakRef(denormalizedResult.partsWithRef),
        state: new WeakRef(state),
      });
    }
  }

  getValueFromCache(state: StateType, ref: RefType) {
    const cached = this.getFromCache(ref);
    if (!cached) {
      return null;
    }

    const whole = cached.whole.deref();
    const partsWithRef = cached.partsWithRef.deref();
    const cachedState = cached.state.deref();

    // The state values are identical between this and the cached value, which
    // means that we don't need to do any more work. We have the correct, cached
    // normalized value.
    if (state === cachedState) {
      return {whole, partsWithRef};
    }

    return null;
  }

  getWholeValueFromCache(state: StateType, ref: RefType) {
    const cached = this.getValueFromCache(state, ref);
    if (!cached || !cached.whole) {
      return null;
    }
    return cached.whole;
  }

  getWholeWithPartsFromCache(state: StateType, ref: RefType) {
    const cached = this.getValueFromCache(state, ref);
    if (!cached || !cached.whole || !cached.partsWithRef) {
      return null;
    }
    return {
      whole: cached.whole,
      partsWithRef: cached.partsWithRef,
    };
  }

  performDenormalization(
    state: StateType,
    ref: RefType,
    options?: DenormalizationOptions
  ): DenormalizedResult {
    const partsWithRef: Array<PartWithRef<Types.ObjType>> = [];
    const wholeDenormFn = denormFunctions[ref.type] as FullDenormFn<any>;
    const whole = wholeDenormFn(ref as any, {
      state,
      partsWithRef,
      options: options ?? {includeRefs: true},
      stack: [],
    });
    return {
      whole,
      partsWithRef,
    };
  }

  shouldSkipCache(state: StateType, opts?: DenormalizationOptions) {
    const stateIsDraft = isDraft(state);

    // If the state we get in is an immer draft, _never_ use the cache.
    //
    // This is because there are reducers that will denormalize a value out of
    // the immer draft state, make some mutations to that draft state, then
    // denormalize again. The reference identity of the draft hasn't changed,
    // so the denormalizer erroneously returns the cached value. So if the input
    // is an immer draft, never use the cache because the state value should be
    // treated as mutable.
    //
    // This is also a good encouragement to move stuff out of immer, where
    // possible
    if (stateIsDraft) {
      return true;
    }

    // Pragmatic choice: the only option right now is to exclude refs when
    // denormalizing. The only time this happens is when we send data to the
    // server for saving. This happens infrequently enough that hitting the
    // cache is unnecessary.
    return Boolean(opts);
  }

  denormalizeWholeWithParts(
    state: StateType,
    ref: RefType,
    opts?: DenormalizationOptions
  ): DenormalizedResult {
    if (this.shouldSkipCache(state, opts)) {
      return this.performDenormalization(state, ref, opts);
    }

    const fromCache = this.getWholeWithPartsFromCache(state, ref);
    if (fromCache) {
      return fromCache;
    }

    // The cache does not contain this ref, either because it has not been
    // added yet, or because it was evicted by the garbage collector. In
    // either case, we need to generate the cache value and add it before
    // returning.
    const denormalizedResult = this.performDenormalization(state, ref, opts);
    this.addToCache(ref, state, denormalizedResult);
    return denormalizedResult;
  }

  denormalizeWhole(
    state: StateType,
    ref: RefType,
    opts?: DenormalizationOptions
  ): Types.WholeFromTypeWithRef<any> {
    if (this.shouldSkipCache(state, opts)) {
      return this.performDenormalization(state, ref, opts).whole;
    }

    const fromCache = this.getWholeValueFromCache(state, ref);
    if (fromCache) {
      return fromCache;
    }

    // The cache does not contain this ref, either because it has not been
    // added yet, or because it was evicted by the garbage collector. In
    // either case, we need to generate the cache value and add it before
    // returning.
    const denormalizedResult = this.performDenormalization(state, ref, opts);
    this.addToCache(ref, state, denormalizedResult);
    return denormalizedResult.whole;
  }
}

let cachingDenormalizer: Denormalize;

export function getOrCreateDenormalizer(): Denormalize {
  if (!cachingDenormalizer) {
    cachingDenormalizer = new Denormalize();
  }
  return cachingDenormalizer;
}
