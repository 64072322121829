import * as _ from 'lodash';

import {
  QueryToRunsDataQueryParams,
  RunsDataQuery,
  toRunsDataQuery,
} from '../../containers/RunsDataLoader';
import {Expression} from '../../util/expr';
import * as Filters from '../../util/filters';
import {Filter} from '../../util/filterTypes';
import {legendTemplateFieldNames} from '../../util/legend';
import {
  ChartAggOption,
  ChartAreaOption,
} from '../../util/plotHelpers/chartTypes';
import {PlotFontSizeOrAuto} from '../../util/plotHelpers/plotFontSize';
import {Query, RunSetQuery} from '../../util/queryTypes';
import * as Run from '../../util/runs';
import * as RunTypes from '../../util/runTypes';
import {getExpressionFields, parseExpressions} from '../PanelExpressionOptions';
export const DEFAULT_SCALAR_CHART_MAX_RUNS = 10000;
export const DEFAULT_SCALAR_CHART_MAX_GROUP_RUNS = 10000;

export interface ScalarChartConfig {
  metrics?: string[];
  chartTitle?: string;
  aggregate?: boolean; // panel level grouping
  aggregateMetrics?: boolean; // currently not used
  groupBy?: string; // panel level groupBy
  groupRunsLimit?: number;
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  legendFields?: string[];
  legendTemplate?: string; // used to generate the default legend
  expressions?: string[];
  showLegend?: boolean;
  fontSize?: PlotFontSizeOrAuto;
}

export function isGrouped(query: Query, config: ScalarChartConfig): boolean {
  if (config.aggregate) {
    return true;
  }

  // Check if there is grouping in the data populating the plot
  return query.runSets?.some(isGroupingInEnabledRunSet) ?? false;
}

export function isGroupingInEnabledRunSet(rs: RunSetQuery): boolean {
  return (rs.enabled && !_.isEmpty(rs.grouping)) ?? false;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function defaultTitle(
  {metrics, expressions, groupAgg}: ScalarChartConfig,
  singleRun = false
): string {
  const metricsInUse = areValidExpressions(expressions) ? expressions : metrics;
  if (metricsInUse == null) {
    return '';
  }

  const groupKeyword = capitalizeFirstLetter(groupAgg ?? 'max');
  const metricsStr = metricsInUse.map(getDisplayNameFromKeyStr).join(', ');
  if (singleRun) {
    return metricsStr;
  }
  return `${groupKeyword}\u00A0of\u00A0${metricsStr}`;
}

export function areValidExpressions(
  expressions: string[] | undefined
): boolean {
  const parsedExpressions = parseExpressions(expressions);
  return parsedExpressions.expressions?.[0] != null;
}

export function getDisplayNameFromKeyStr(keyStr: string): string {
  const key = Run.keyFromString(keyStr);
  return key != null ? Run.keyDisplayName(key, true) : keyStr;
}

export function getKeysFromMetricStrings(
  metrics: string[] | undefined
): RunTypes.Key[] {
  return metrics?.map(getKeyFromMetricStr) ?? [];
}

export function getKeyFromMetricStr(metricStr: string): RunTypes.Key {
  // metrics should be in the form config:metricName, if not we assume they
  // are summary metrics.
  const summaryKeyFallback = Run.key('summary', metricStr);

  if (!metricStr.includes(':')) {
    return summaryKeyFallback;
  }

  return Run.keyFromString(metricStr) ?? summaryKeyFallback;
}

export function scalarChartTransformQuery(
  query: Query,
  config: ScalarChartConfig,
  parsedExpressions: {
    expressions?: Expression[];
    xExpressions?: Expression;
  }
): RunsDataQuery {
  const queryToDataQueryParams: QueryToRunsDataQueryParams = {
    selectionsAsFilters: true,
  };

  const expressionFields = getExpressionFields(parsedExpressions);

  const metricKeys = getKeysFromMetricStrings(config.metrics);

  if (config.metrics && config.metrics.length > 0) {
    const filters: Array<Filter<RunTypes.Key>> = metricKeys.map(metricKey => ({
      key: metricKey,
      op: '!=',
      value: null,
    }));
    const mergeFilters: Filter<RunTypes.Key> = Filters.Or(filters);
    queryToDataQueryParams.mergeFilters = mergeFilters;
  }

  const transformed = toRunsDataQuery(query, queryToDataQueryParams);

  let legendFields = config.legendFields || [];
  if (config.legendTemplate != null) {
    const templateFields = legendTemplateFieldNames(config.legendTemplate);
    const extraLegendFields = _.difference(
      templateFields,
      config.legendFields || []
    );
    legendFields = legendFields.concat(extraLegendFields);
  }

  let displayFields = [
    ...legendFields.map(Run.keyFromString),
    ...(query.grouping || []),
    config.aggregate && config.groupBy
      ? Run.key('config', config.groupBy)
      : null,
  ];

  if (query.runSets != null) {
    query.runSets.forEach(rs => {
      if (rs.grouping) {
        displayFields = displayFields.concat(rs.grouping);
      }
    });
  }

  const extraFields = _.uniq(_.concat(displayFields, expressionFields));

  // And then add them into the correct query fields.
  transformed.configKeys = extraFields
    .concat(metricKeys || [])
    .filter(key => key != null && key.section === 'config')
    .map(key => key!.name);
  // Have to concat here to preserve the config key we added above.
  transformed.summaryKeys = extraFields
    .concat(metricKeys || [])
    .filter(key => key != null && key.section === 'summary')
    .map(key => key!.name);

  transformed.page = {
    size: DEFAULT_SCALAR_CHART_MAX_RUNS,
  };

  if (isGrouped(query, config)) {
    // We need the metadata for grouping because we do it locally
    // TODO: move grouping to server
    // result.disableMeta = false;

    // optionally compute group statistics over all runs instead of sub-sampling
    transformed.page.size = DEFAULT_SCALAR_CHART_MAX_GROUP_RUNS;
    // Disable grouping for this query, we'll do it ourselves.
    transformed.queries = transformed.queries.map(q => ({...q, grouping: []}));
  }

  return transformed;
}

export function getTitleFromConfig(config: ScalarChartConfig): string {
  return config.chartTitle || defaultTitle(config);
}
