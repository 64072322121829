import React from 'react';

interface ErrorBoundaryProps {
  renderError(e: Error): JSX.Element;
  onError?(e: Error, info: React.ErrorInfo): void;
}

interface ErrorBoundaryState {
  error: Error | null;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {error};
  }

  state: ErrorBoundaryState = {error: null};

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('Encountered ErrorBoundary:', error);
    this.props.onError?.(error, info);
  }

  render() {
    if (this.state.error != null) {
      return this.props.renderError(this.state.error);
    }

    return this.props.children;
  }
}
