import React, {FC, memo, useCallback, useMemo} from 'react';

import {useUpsertModelMutation} from '../../../../generated/graphql';
import {trackBreadcrumbClicked} from '../../../../util/navigation';
import {AccessOption} from '../../../../util/permissions';
import {capitalizeFirstLetter} from '../../../../util/string';
import * as links from '../../../../util/urls';
import AccessSettingOptions from '../../../AccessSettingOptions';
import Breadcrumbs, {BreadcrumbPart} from '../../../Breadcrumbs';
import {useNavContext} from '../../../NavContextProvider';

export type NavBreadcrumbsWeaveProps = {
  entityName: string;
  projectName: string;
  weaveTab?: string;
  itemName?: string;
  version?: string;
  refExtra?: string;
};

const NavBreadcrumbsWeaveComp: FC<NavBreadcrumbsWeaveProps> = params => {
  const {entityName, projectName, weaveTab, itemName, version, refExtra} =
    params;
  const {searchData} = useNavContext();
  const project = searchData?.project;

  const [upsertModelMutation] = useUpsertModelMutation();
  const updateProjectAccess = useCallback(
    async (access: AccessOption) => {
      if (projectName != null && project != null) {
        await upsertModelMutation({
          variables: {
            entityName,
            name: projectName,
            access,
          },
        });
      }
    },
    [upsertModelMutation, entityName, projectName, project]
  );

  const parts: BreadcrumbPart[] = useMemo(
    () => [
      {
        label: entityName,
        to: `/${entityName}`,
        onClick: () => trackBreadcrumbClicked('Entity'),
      },
      {
        label: 'Projects',
        to: `/${entityName}/projects`,
        onClick: () => trackBreadcrumbClicked('Projects'),
      },
      {
        beforeLabel: project != null && updateProjectAccess != null && (
          <AccessSettingOptions
            accessSettings={{
              project: {
                id: project.id,
                name: project.name,
                access: project.access,
                user: project.user,
              },
              entity: project.entity,
            }}
            compact
            updateProjectAccess={updateProjectAccess}
          />
        ),
        label: projectName,
        to: links.project({entityName, name: projectName}),
        onClick: () => trackBreadcrumbClicked('Project'),
      },
      ...(weaveTab
        ? [
            {
              label: capitalizeFirstLetter(weaveTab),
              to: links.weaveView({
                entityName,
                projectName,
                weaveTab,
              }),
            },
            ...(itemName
              ? [
                  {
                    label: itemName,
                    to: links.weaveView({
                      entityName,
                      projectName,
                      weaveTab,
                      itemName,
                    }),
                  },
                  ...(version
                    ? [
                        {
                          label: version,
                          to: links.weaveView({
                            entityName,
                            projectName,
                            weaveTab,
                            itemName,
                            version,
                          }),
                        },
                        ...(refExtra
                          ? [
                              {
                                label: refExtra,
                                to: links.weaveView({
                                  entityName,
                                  projectName,
                                  weaveTab,
                                  itemName,
                                  version,
                                  refExtra,
                                }),
                              },
                            ]
                          : []),
                      ]
                    : []),
                ]
              : []),
          ]
        : []),
    ],
    [
      entityName,
      weaveTab,
      itemName,
      projectName,
      refExtra,
      version,
      project,
      updateProjectAccess,
    ]
  );

  return <Breadcrumbs parts={parts} />;
};

const NavBreadcrumbsWeave = memo(NavBreadcrumbsWeaveComp);

export default NavBreadcrumbsWeave;
