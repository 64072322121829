import {evaluateExpression, Expression} from './../../../util/expr';
import {Line} from './../../../util/plotHelpers';
import {SupplementalMetricsByRun} from './types';

export function convertLineDataToTimestamp(lines: Line[]) {
  lines.forEach(line => {
    line.data.forEach(p => {
      p.x = p.x * 1000;
    });
  });
}

/**
 * Evaluate the x expression for each point in the line
 */
export function evaluateXExpressions(
  expression: Expression,
  lines: Line[],
  supplementalMetricsByRun: SupplementalMetricsByRun
) {
  try {
    lines.forEach(line => {
      line.data.forEach(p => {
        p.x = evaluateExpression(expression, {
          ...supplementalMetricsByRun[line.name!],
          ...(p as Record<string, any>),
        });
      });
    });
  } catch (e) {
    console.error('Error evaluating x expression', e);
  }
}

/**
 * Any line with a single point where y !== y0 has a valid area to be displayed
 */
export function removeUselessAreaLines(lines: Line[]) {
  return lines.filter(l => {
    if (l.meta.type !== 'area') {
      return true;
    }

    return l.data.some(p => p.y !== p.y0);
  });
}
