import {useCallback, useEffect, useMemo, useRef} from 'react';

import {useServerPollingOkLazyQuery} from '../../generated/graphql';
import {useDispatch, useSelector} from '../hooks';
import * as PollingActions from './actions';
import * as PollingReducer from './reducer';
import {
  addSlowEvent,
  clearSlowEvents,
  getSlowEvents,
  SlowEvent,
} from './slowEvents';

const LOOP_INTERVAL = 2000;
const SLOW_EVENT_INTERVAL_MULTIPLIER = 5;
export const SLOW_EVENT_THRESHOLD = 1000;

const STALL_HISTOGRAM_BUCKET0_SIZE = 5;
const STALL_HISTOGRAM_N_BUCKETS = 13;
const STALL_HISTOGRAM_BUCKET_N_SIZE =
  STALL_HISTOGRAM_BUCKET0_SIZE * 2 ** (STALL_HISTOGRAM_N_BUCKETS - 1);
// Exponentially growing reporting interval
const STALL_HISTOGRAM_FIRST_REPORT_INTERVAL = 10000;
const STALL_HISTOGRAM_BLANK: {[key: string]: number} = {
  0: 0,
};
let bucketTop = STALL_HISTOGRAM_BUCKET0_SIZE;
for (let i = 0; i < STALL_HISTOGRAM_N_BUCKETS; i++) {
  STALL_HISTOGRAM_BLANK[bucketTop] = 0;
  bucketTop *= 2;
}

// Stores tab backgrounded and user activity states in redux, and reports
// a histogram of "stall times" to analytics.
export function useActivityDetectionLoop() {
  const lastUserActivityTime = useRef(Date.now());

  const dispatch = useDispatch();

  const setActivityTime = useCallback(() => {
    lastUserActivityTime.current = Date.now();
  }, [lastUserActivityTime]);

  const tabBackgrounded = useRef(false);
  const userInactive = useRef(false);

  // We need to put the state from redux into refs so we can access in the
  // setInterval callback (if we just use local variables the initial state
  // will get captured in the closure created for the setInterval callback)
  tabBackgrounded.current = useSelector(state => state.polling.tabBackgrounded);
  userInactive.current = useSelector(state => state.polling.userInactive);

  const stallHistogram = useRef<{[key: string]: number}>(STALL_HISTOGRAM_BLANK);
  const lastIntervalTime = useRef<number>(Date.now());
  const lastReportTime = useRef<number>(Date.now());
  const curReportInterval = useRef<number>(
    STALL_HISTOGRAM_FIRST_REPORT_INTERVAL
  );

  useEffect(() => {
    const events = ['mousemove', 'keypress'];
    events.forEach(name => {
      document.addEventListener(name, setActivityTime, true);
    });

    const interval = setInterval(() => {
      const curTabBackgrounded = document.hidden;
      const curUserInactive =
        Date.now() - lastUserActivityTime.current > 30 * 60000;

      // We keep a histogram of "stall times". A stall time is: how far off
      // did this timer fire from the time we expected it too?
      // We only track stall time when the user is active and the tab is
      // in the foreground. We report it to the analytics after 10s, and then
      // expontentionally back off from there.
      if (
        (curTabBackgrounded === true && tabBackgrounded.current === false) ||
        (curUserInactive === true && userInactive.current === false)
      ) {
        // tab became backgrounded or user became inactive
        if (Object.values(stallHistogram.current).some(count => count > 0)) {
          // window.analytics?.track('js_perf', {
          //   stallHistogram: stallHistogram.current,
          // });
        }
        // Reset histogram
        for (const bucket of Object.keys(stallHistogram.current)) {
          stallHistogram.current[bucket] = 0;
        }
        lastReportTime.current = Date.now();
        curReportInterval.current = STALL_HISTOGRAM_FIRST_REPORT_INTERVAL;
      } else if (curTabBackgrounded === false && curUserInactive === false) {
        // tab is foregrounded and user is active, track this stall
        const now = Date.now();
        const intervalLength = now - lastIntervalTime.current;
        const stall = Math.abs(intervalLength - LOOP_INTERVAL);
        let bucketTopInner = STALL_HISTOGRAM_BUCKET0_SIZE;
        for (let i = 0; i < STALL_HISTOGRAM_N_BUCKETS - 1; i++) {
          if (stall < bucketTopInner) {
            let bucketBottom = bucketTopInner / 2;
            if (bucketTopInner === STALL_HISTOGRAM_BUCKET0_SIZE) {
              bucketBottom = 0;
            }
            stallHistogram.current[bucketBottom] += 1;
            break;
          }
          bucketTopInner *= 2;
        }
        if (bucketTopInner === STALL_HISTOGRAM_BUCKET_N_SIZE) {
          stallHistogram.current[STALL_HISTOGRAM_BUCKET_N_SIZE] += 1;
        }

        if (now - lastReportTime.current > curReportInterval.current) {
          // window.analytics?.track('js_perf', {
          //   stallHistogram: stallHistogram.current,
          // });
          // Reset histogram
          for (const bucket of Object.keys(stallHistogram.current)) {
            stallHistogram.current[bucket] = 0;
          }
          curReportInterval.current *= 2;
          lastReportTime.current = now;
        }
      }
      lastIntervalTime.current = Date.now();

      if (curTabBackgrounded !== tabBackgrounded.current) {
        dispatch(PollingActions.setTabBackgrounded(curTabBackgrounded));
      }
      if (curUserInactive !== userInactive.current) {
        dispatch(PollingActions.setUserInactive(curUserInactive));
      }
    }, LOOP_INTERVAL);

    return () => {
      events.forEach(name => {
        document.removeEventListener(name, setActivityTime);
      });
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
}

/**
 * Manages polling ticks that can be used by multiple subscribers
 * to perform polled tasks in sync. An example use case is workspaces,
 * where RunSelector and Charts must poll for runs data in sync.
 *
 * Any page with components that need to poll in sync simply has to
 * `setBasePollInterval` on mount and clear it back to 0 on unmount.
 * The polling publisher then handles:
 *   - Computing smart/dynamic interval duration based on user activity
 *     and general application state (e.g. use larger interval to poll
 *     less aggressively if user is inactive or app is responding slowly)
 *   - Setting or clearing `lastTickedAt` time, which is the state that
 *     subscribers use to determine when to execute their polled tasks
 *   - Polling the server for `pollingOK` signal
 */
export function useSyncedPollingPublisher() {
  // This starts the loop to detect whether tab is backgrounded or user is inactive
  useActivityDetectionLoop();

  const dispatch = useDispatch();
  const pollInterval = usePollInterval();
  const {lastTickedAt, pollingStartedAt} = useSelector(state => state.polling);

  const [fetchPollingOk, pollingOkQuery] = useServerPollingOkLazyQuery();
  const serverPollingOK = pollingOkQuery.data?.serverInfo?.pollingOK ?? true;

  const tick = useCallback(() => {
    dispatch(PollingActions.tick());
    fetchPollingOk();
    clearSlowEvents();
  }, [dispatch, fetchPollingOk]);

  const resetTicker = useCallback(() => {
    dispatch(PollingActions.resetTicker());
    clearSlowEvents();
  }, [dispatch]);

  useEffect(() => {
    // reset ticker if polling is stopped
    if (pollInterval === 0) {
      if (lastTickedAt) {
        resetTicker();
      }
      return;
    }

    const now = Date.now();
    let start = lastTickedAt ?? pollingStartedAt;
    if (!start) {
      // something is wrong if no start time is available even though polling is active.
      // this would be considered a bug. log a warning and try to handle gracefully.
      console.warn('No start time for active poll ticker loop');
      start = now;
    }
    const elapsedTime = now - start;

    if (elapsedTime >= pollInterval) {
      // this means pollInterval was decreased while waiting for
      // the next tick, and the new value is actually smaller than
      // the time that has already passed - should tick immediately
      tick();
      return;
    }

    // set a timer to the next tick. we factor in the time that's
    // already elapsed in case the pollInterval was updated while
    // waiting for the next tick.
    const timeoutId = setTimeout(tick, pollInterval - elapsedTime);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [lastTickedAt, pollInterval, pollingStartedAt, resetTicker, tick]);

  // keep serverPollingOK state up to date
  useEffect(() => {
    dispatch(PollingActions.setServerPollingOK(serverPollingOK));
  }, [dispatch, serverPollingOK]);
}

/**
 * Use this hook to subscribe to global synchronized polling ticks.
 * When `lastTickedAt` time is updated, the subscriber should execute
 * its polled task.
 *
 * This hook measures the duration of each task, which requires the
 * subscriber to call `stopTaskTimer` when its task is complete.
 * Slow events will be flagged to the global store and taken into
 * account when computing the next interval duration.
 *
 * Subscribers may also trigger ticks manually to signal an update
 * to all other subscribers.
 */
export function useSyncedPollingSubscriber(subscriberId: string) {
  const dispatch = useDispatch();
  const {lastTickedAt, pollingStartedAt} = useSelector(state => state.polling);

  const tick = useCallback(() => {
    dispatch(PollingActions.tick());
    clearSlowEvents();
  }, [dispatch]);

  // compute task duraton and flag slow event if necessary
  const stopTaskTimer = useCallback(() => {
    const taskStartedAt = lastTickedAt ?? pollingStartedAt;
    if (!taskStartedAt) {
      return;
    }
    const duration = Date.now() - taskStartedAt;
    if (duration > SLOW_EVENT_THRESHOLD) {
      addSlowEvent({duration, subscriberId});
      // dispatch(PollingActions.flagSlowEvent(subscriberId, duration));
    }
  }, [lastTickedAt, pollingStartedAt, subscriberId]);

  return useMemo(
    () => ({
      lastTickedAt,
      stopTaskTimer,
      tick,
    }),
    [lastTickedAt, stopTaskTimer, tick]
  );
}

export function usePollInterval() {
  // It's ok to use getPollInterval in the selector, since it returns a constant
  // which will be ref equal until it changes.
  const slowEvents = getSlowEvents();
  const pollingState = useSelector(state => state.polling);
  return useMemo(
    () => getPollInterval(pollingState, slowEvents),
    [pollingState, slowEvents]
  );
}

export function getPollInterval(
  pollingState: PollingReducer.StateType,
  slowEvents: SlowEvent[]
) {
  const {
    tabBackgrounded,
    userPollingOK,
    serverPollingOK,
    userInactive,
    basePollInterval,
  } = pollingState;
  if (!userPollingOK || !serverPollingOK) {
    return 0;
  }
  // When polling changes to 0 to some number, the polling timer restarts,
  // so we won't poll again until the new timer expires. We use a large interval
  // instead. So if the user foregrounds the tab,
  // the amount of time it was backgrounded counts toward the timer, which
  // usually means we'll poll immediately, which is what we want.
  if (tabBackgrounded) {
    return 365 * 24 * 60 * 60000;
  }
  if (userInactive) {
    return 15 * 60000;
  }
  // If some subscribers have flagged themselves as slow,
  // we should back off and use a longer interval
  if (slowEvents.length) {
    const slowest = Math.max(...slowEvents.map(({duration}) => duration));
    return Math.max(slowest * SLOW_EVENT_INTERVAL_MULTIPLIER, basePollInterval);
  }
  return basePollInterval;
}
