import {LoadableView, ViewType} from '../views/types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {getWorkspaceDisplayName} from './namedWorkspaceActionsInternal';
import {
  createViewNameForLegacyWorkspace,
  getNamedWorkspaceIdForLegacyView,
  getNamedWorkspaceIdForNWView,
  getNamedWorkspaceIdForView,
  getSafeNwPart,
  LegacyDbNameValue,
  NwType,
  viewIsLegacyWorkspace,
  viewIsNamedWorkspace,
} from './names';
import {compareDates} from './sort';
import {NamedWorkspaceId, WsRelatedId} from './types';
import {groupedNwViews} from './workspaceViewListSelector';

export const findLegacyOrNwView = (
  username: string | undefined, // if we don't know the username, skip looking in legacy views
  views: LoadableView[],
  relatedId: WsRelatedId,
  viewType: ViewType,
  nwId: NamedWorkspaceId
) => {
  if (username) {
    const legacyView = findLegacyView(
      username,
      views,
      relatedId,
      viewType,
      nwId.nwType
    );
    if (legacyView) {
      return {
        foundView: legacyView,
        nwId: legacyView
          ? getNamedWorkspaceIdForLegacyView(
              legacyView.name,
              legacyView.user.username
            )
          : undefined,
      };
    }
  }

  const nwView = findNwView(username, views, nwId, viewType);
  return {foundView: nwView, nwId: nwView ? nwId : undefined};
};
export const findLegacyView = (
  username: string | undefined,
  legacyAndNwViews: LoadableView[],
  relatedId: WsRelatedId,
  viewType: ViewType,
  nwType: NwType
) => {
  if (username) {
    return legacyAndNwViews.find(
      v =>
        isSameNwTypeforLegacyWorkspace(
          v,
          relatedId,
          viewType,
          nwType,
          'user'
        ) && getSafeNwPart(v.user.username) === getSafeNwPart(username)
    );
  }

  // if no username is specified, then we are trying to look for the default view
  return legacyAndNwViews.find(v =>
    isSameNwTypeforLegacyWorkspace(v, relatedId, viewType, nwType, 'default')
  );
};
const findNwView = (
  username: string | undefined,
  legacyAndNwViews: LoadableView[],
  nwId: NamedWorkspaceId,
  viewType: ViewType
) => {
  return legacyAndNwViews.find(v => {
    return (
      isSameNwTypeforNwWorkspace(v, nwId.relatedId, viewType, nwId.nwType) &&
      (username == null ||
        getSafeNwPart(username) === getSafeNwPart(v.user.username)) &&
      nwId.id === getNamedWorkspaceIdForNWView(v.name, v.user).id
    );
  });
};

// Get the first alphabetically sorted personal workspace
// or the most recently updated shared view
export const getDefaultLandingView = ({
  legacyAndNwViews,
  relatedId,
  viewType,
}: {
  legacyAndNwViews: LoadableView[];
  relatedId: WsRelatedId;
  viewType: ViewType;
}) => {
  const groupedViews = groupedNwViews(legacyAndNwViews);
  const personalWorkspace = getFirstSortedView({
    views: Object.values(groupedViews.personalWorkspaces),
    relatedId,
    viewType,
    nwType: 'personal workspace',
    sortFn: (a: LoadableView, b: LoadableView) =>
      getWorkspaceDisplayName(a)
        .toLocaleUpperCase()
        .localeCompare(getWorkspaceDisplayName(b).toLocaleUpperCase()),
  });
  if (personalWorkspace) {
    return personalWorkspace;
  }

  const sharedViews = getFirstSortedView({
    views: Object.values(groupedViews.sharedViews),
    relatedId,
    viewType,
    nwType: 'shared workspace',
    sortFn: (a: LoadableView, b: LoadableView) =>
      compareDates(a.createdAt, b.createdAt),
  });
  return sharedViews;
};

const getFirstSortedView = ({
  views,
  relatedId,
  viewType,
  nwType,
  sortFn,
}: {
  views: LoadableView[];
  relatedId: WsRelatedId;
  viewType: ViewType;
  nwType: NwType;
  sortFn: (a: LoadableView, b: LoadableView) => number;
}) => {
  const sortedViews = views
    .filter(
      v =>
        isSameNwTypeforLegacyWorkspace(
          v,
          relatedId,
          viewType,
          nwType,
          v.name === 'workspace' ? 'user' : 'default'
        ) || isSameNwTypeforNwWorkspace(v, relatedId, viewType, nwType)
    )
    .sort(sortFn);
  return sortedViews.length > 0 ? sortedViews[0] : undefined;
};

const isSameNwTypeforLegacyWorkspace = (
  view: LoadableView,
  relatedId: WsRelatedId,
  viewType: ViewType,
  nwType: NwType,
  legacyWorkspaceType: keyof typeof LegacyDbNameValue
) => {
  return (
    view.name != null &&
    viewIsLegacyWorkspace(view.name) &&
    view.name ===
      createViewNameForLegacyWorkspace(legacyWorkspaceType, relatedId) &&
    view.type === viewType &&
    getNamedWorkspaceIdForView(view.name, view.user).nwType === nwType
  );
};
const isSameNwTypeforNwWorkspace = (
  view: LoadableView,
  relatedId: WsRelatedId,
  viewType: ViewType,
  nwType: NwType
) => {
  if (!viewIsNamedWorkspace(view) || view.type !== viewType) {
    return false;
  }

  try {
    const currNwId = getNamedWorkspaceIdForNWView(view.name, view.user);
    return nwType === currNwId.nwType && relatedId === currNwId.relatedId;
  } catch (e) {
    // If it's a malformed view.name, let's just ignore it since it most likely was caused
    // by previous iterations and bugs
    return false;
  }
};
