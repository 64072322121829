import type {ChartAggOption} from './../../../util/plotHelpers/chartTypes';
import {
  HistoryAggKey,
  HistoryFallThroughKey,
  Point,
  ValidHistoryKey,
} from './types';

function isUnsafeMetric(metricName: string) {
  return [
    // system metric charts currently don't come back with min/max/avg suffixes in full fidelity
    (m: string) => m.startsWith('system/'),
  ].some(cond => cond(metricName));
}

export type XKeyPositions = 'first' | 'midpoint' | 'last';

const XKeymap: Record<XKeyPositions, string> = {
  first: 'Min',
  midpoint: 'Avg',
  last: 'Max',
};

export function getSafeXKey(
  xMetricKey: string,
  yMetricKey: string,
  position: XKeyPositions = 'last'
): ValidHistoryKey {
  const useDefault = isUnsafeMetric(yMetricKey);
  if (useDefault) {
    return xMetricKey as ValidHistoryKey;
  }

  return `${xMetricKey}${XKeymap[position]}` as ValidHistoryKey;
}
/**
 * This map exists to translate groupAgg options into suffixes that can be used to read data out of the api returned bins. A bin returns `-Min, -Max, and -Avg suffixed values for each metric. This translates the groupAgg option into the correct suffix so that the line construction logic automatically pulls the correct option.
 *
 * e.g. if you're constructing a group and you want to show all the max values you'd choose the max groupAgg option, and then when the buckets are reduced into the line during the grouping function the max value would be returned instead of the average or min value.
 */
export const mapGroupAggToSuffix: Record<
  ChartAggOption,
  'Avg' | 'Min' | 'Max'
> = {
  mean: 'Avg',
  min: 'Min',
  max: 'Max',
  // these options aren't supported yet and won't be called
  // they're just here to satisfy the types for now
  median: 'Avg',
  sum: 'Avg',
  samples: 'Avg',
};

/**
 * If we're plotting a system metric plot (only usage currently known) on the y-axis we need to avoid adding the `Avg` suffix to the key because the API doesn't return these values in binned mode. This function takes in the yMetric (and an optional xMetric if needing to safely access that as well and returns prefixed or unprefixed keys as needed.)
 */
export function getSafeYKey(
  yMetricKey: string,
  overrides?: {
    yAxisSuffix?: string;
  },
  aggType: ChartAggOption = 'mean'
): ValidHistoryKey {
  // if we're doing a system metric plot we get results back that aren't binned
  // we can handle that by avoid transforming logic to use the `-Avg` suffix
  const suffix = mapGroupAggToSuffix[aggType] ?? 'Avg';
  const ySuffix = overrides?.yAxisSuffix ?? suffix;

  return isUnsafeMetric(yMetricKey)
    ? (yMetricKey as ValidHistoryKey)
    : (`${yMetricKey}${ySuffix}` as ValidHistoryKey);
}

export function sortPointsByX(a: Point, b: Point) {
  return a.x - b.x;
}
export function takeLastDuplicate(point: Point, index: number, array: Point[]) {
  return index === array.length - 1 || array[index + 1].x !== point.x;
}

export function getAreaSafeYKeys(yMetricKey: string): {
  yKey: HistoryAggKey | HistoryFallThroughKey;
  y0Key: HistoryAggKey | HistoryFallThroughKey;
} {
  const useDefault = isUnsafeMetric(yMetricKey);
  const yMinSuffix = useDefault ? '' : 'Min';
  const yMaxSuffix = useDefault ? '' : 'Max';
  return {
    yKey: `${yMetricKey}${yMinSuffix}` as ValidHistoryKey,
    y0Key: `${yMetricKey}${yMaxSuffix}` as ValidHistoryKey,
  };
}

export function sliceObjectKeys<T extends Record<string, any>>(
  obj: T,
  numKeys: number
) {
  return Object.entries(obj).reduce((acc, [key, val], index) => {
    if (index < numKeys) {
      acc[key as keyof T] = val;
    }
    return acc;
  }, {} as Partial<T>);
}
