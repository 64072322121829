import {Avatar} from '@material-ui/core';
import {MOON_500} from '@wandb/weave/common/css/globals.styles';
import {SelectSize} from '@wandb/weave/components/Form/Select';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

const VALUE_OPTION_HEIGHTS: Partial<Record<SelectSize, number>> = {
  small: 14,
  medium: 18,
  large: 26,
};

const VALUE_ICON_HEIGHTS: Partial<Record<SelectSize, number>> = {
  small: 14,
  medium: 18,
  large: 26,
};

type UserOptionProps = {
  size: SelectSize;
  isValue: boolean;
};

export const UserOption = styled.div<UserOptionProps>`
  display: flex;
  align-items: center;
  height: ${props => (props.isValue ? VALUE_OPTION_HEIGHTS[props.size] : 40)}px;
`;
UserOption.displayName = 'S.UserOption';

type AvatarImageProps = {
  size: SelectSize;
  $isValue: boolean;
};

export const AvatarImage = styled(Avatar)<AvatarImageProps>`
  && {
    width: ${props => (props.$isValue ? VALUE_ICON_HEIGHTS[props.size] : 30)}px;
    height: ${props =>
      props.$isValue ? VALUE_ICON_HEIGHTS[props.size] : 30}px;
  }
  margin-right: 8px;
  img {
    object-fit: contain;
  }
`;
AvatarImage.displayName = 'S.AvatarImage';

export const UserName = styled.span`
  margin-right: 8px;
`;
UserName.displayName = 'S.UserName';

type EnabledProps = {
  isDisabled: boolean;
};

export const UserUsername = styled.span<EnabledProps>`
  color: ${MOON_500};
  opacity: ${props => (props.isDisabled ? 0.7 : 'inherit')};
`;
UserUsername.displayName = 'S.UserUsername';

export const UserEmail = styled.div<EnabledProps>`
  font-size: 14px;
  color: ${MOON_500};
  opacity: ${props => (props.isDisabled ? 0.7 : 'inherit')};
`;
UserEmail.displayName = 'S.UserEmail';
