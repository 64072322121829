import {datadogRum} from '@datadog/browser-rum';

import {datadogDebugOverride, frontendPerfLoggingEnabled} from '../../config';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {getDatadog} from '../../integrations';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  MIN_NETWORK_ELAPSED_TIME_MILLIS,
  THROTTLED_MIN_NETWORK_ELAPSED_TIME_MILLIS,
} from './networkReport';
import {
  beginTtiSession,
  EndReason,
  EndReasons,
  endTtiSession,
  TtiRecord,
} from './timeToInteractive';

export const PERF_LOG_PREFIX = 'WB_P'; // W&B Perf

// What performance events should we print to the console?
export const CONSOLE_REPORT_SLOW_EVENT_MILLIS = datadogDebugOverride()
  ? 1
  : 500;

// We don't report all events to server since we only want to capture data about
// particularly bad loading times
export const MIN_REPORT_TO_SERVER_TIME_MILLIS = datadogDebugOverride()
  ? 1
  : 500;

// This is data sent to DataDog as part of network performance events. We add this so it's
// easier to find network perf events, regardless of how they're reported to DD (graphql vs resource)
export const NETWORK_EVENT_CONTEXT = {isNetwork: true};

let userIsAdmin = false;

// Internal only - this is the One True Place to emit log lines related to perf events.
const perfConsoleLog = (...args: any[]) => {
  if (!frontendPerfLoggingEnabled() || args.length < 1) {
    return;
  }
  // to preserve any colors set in the text, we need the first arg to be
  // kept in the first position
  console.log(`${PERF_LOG_PREFIX} ${args[0]}`, ...args.slice(1));
};

export const perfStat = ({
  name,
  value,
  interestingThreshold,
  context,
}: {
  name: string;
  value: number;
  interestingThreshold: number;
  context?: Record<string, any>;
}) => {
  if (value > interestingThreshold) {
    const logLine = `PerfStat ${name}:${value}`;
    perfConsoleLog(logLine, context ?? '');

    const ddContext = {...context};
    ddContext[name] = value;
    logToDataDog(logLine, ddContext);
  }
};

const timedPageLoads = new Map<string, number>();

export const perfTimerFromPageLoad = ({
  name,
  interestingThreshold,
  context,
}: {
  name: string;
  interestingThreshold: number;
  context?: Record<string, any>;
}) => {
  if (timedPageLoads.has(name)) {
    // already recorded this page load time from start, don't record again
    return;
  }

  // Note that we do this inside of the perf timer code b/c we are using
  // pefTimer's concept of a particular page view. DD_RUM will happily
  // override an earlier timing if you addTiming() twice. `timedPageLoads`
  // ensures that addTiming is not called twice. This is very helpful for the
  // workspace metrics, where it's hard to keep track of what's been sent.
  window.DD_RUM?.addTiming(name);

  const value = Date.now() - pageStats.startTimeMs;
  timedPageLoads.set(name, value);
  perfStat({name, value, interestingThreshold, context});
};

// You should generally prefer a method that filters what is sent,
// but this exists if you really just want to send something directly to DD
export const logToDataDog = (
  message: string,
  context?: Record<string, any>
) => {
  const ddLogger = getDatadog(userIsAdmin);
  if (ddLogger) {
    ddLogger.logger.info(message, context);
  }
};

export const getDefaultPageStats = () => ({
  // Note on time keeping: We don't use performance.mark()/.measure() because
  // (per https://3perf.com/blog/react-monitoring/) it is memory intensive
  startTimeMs: Date.now(),
  pageMaxLongTask: 0,
});

type AnalyticsCallWithDuration = (val: {duration: number}) => void;
let pageStats = getDefaultPageStats();

const endPageView = (pageEndReason: EndReason) => {
  endTtiSession(pageEndReason);
  perfStat({
    name: 'PageMaxLongTask',
    value: pageStats.pageMaxLongTask,
    interestingThreshold: 0 /* always report, so that we have a semi-accurate %ile curve */,
    context: {pageEndReason},
  });
};

// we pass these analytics callbacks in since we are in @wandb/weave/common, and the analytics
// service is tied to the app, so it shouldn't go in common.
export const startProfilerPageView = (
  trackPageTti?: AnalyticsCallWithDuration
) => {
  endPageView(EndReasons.SPA_NAV);
  beginTtiSession((record: TtiRecord) => {
    trackPageTti?.({duration: record.tti});
    perfStat({
      name: 'PageTTI',
      value: record.tti,
      interestingThreshold: 0 /* we always want to send TTI data*/,
      context: {
        pageEndReason: record.endReason,
      },
    });
  });
  pageStats = getDefaultPageStats();

  // Reset any tracked page timings when the page is reloaded
  timedPageLoads.clear();

  perfConsoleLog('Profiler page view start');
};

if (typeof window !== 'undefined') {
  // We need to detect when the user navigates away from the current page. Unfortunately,
  // the window 'unload' event is no longer trustworthy on mobile.
  // source: https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event
  // The new best choices for what event to handle as "page is unloaded" appear to be pagehide
  // and visibilitychange - I chose visibilitychange since it's fired also fired before
  // transitioning to freezing and "Transitioning to hidden is the last event that's
  // reliably observable by the page, so developers should treat it as the likely end of
  //  the user's session". However, it'll also fire before tab switches/etc so it's
  // going to fire earlier than wanted some times.
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      // note that we ignore event.persisted since we always want to
      // end the TTI session - it relies on timing data that
      // is not reliable when page is frozen.
      endPageView(EndReasons.LEAVE_PAGE);
    }
  });
}

const updateProfilerContextInner = (name: string, value?: string) => {
  const dd = getDatadog(userIsAdmin);
  // when value is undefined, it won't include that in context
  dd?.addLoggerGlobalContext(name, value);
};

export const updateProfilerViewer = (
  entityName: string | undefined,
  projectName: string | undefined,
  isAdmin: boolean | undefined
) => {
  userIsAdmin = isAdmin ?? false;
  updateProfilerContextInner('entityName', entityName);
  updateProfilerContextInner('projectName', projectName);
};

export const updatePerformanceMonitoringRoute = (routeName?: string) => {
  updateProfilerContextInner('routeName', routeName);
  datadogRum.startView({name: routeName});
};

export const updatePageLongTask = (elapsedMs: number) => {
  if (elapsedMs > pageStats.pageMaxLongTask) {
    pageStats.pageMaxLongTask = elapsedMs;
  }
};

type ReportableEvent = {
  elapsedMs: number;
  absoluteStartTimeMs: number; // note that this is based on DateTime.now(), not performance.now()
  name: string;
  context?: Record<string, any>;
  variables?: Record<string, unknown>;
};
export const reportTimedEvent = (
  {elapsedMs, absoluteStartTimeMs, name, context, variables}: ReportableEvent,
  ddLogger: any = getDatadog(userIsAdmin)
) => {
  const relativeStartTimeMs = absoluteStartTimeMs - pageStats.startTimeMs;
  if (elapsedMs <= 1) {
    // short circuit for this common case
    return;
  }

  let severityColor = 'black';
  if (elapsedMs > 500) {
    severityColor = 'blue';
  }
  if (elapsedMs > 1000) {
    severityColor = 'orange';
  }
  if (elapsedMs > 5000) {
    severityColor = 'red';
  }
  if (elapsedMs > CONSOLE_REPORT_SLOW_EVENT_MILLIS) {
    perfConsoleLog(
      `%cSlow event, started @${relativeStartTimeMs}ms, elapsed: ${elapsedMs}ms: ${name} `,
      // it's hard to style console log lines so they work well normally & in dark mode,
      // so just set a background color
      `color: ${severityColor}; background: #f0f0f0;`,
      context ?? ''
    );
  }

  /**
   * ###############################################################
   * ##################### Ignorable conditions ####################
   * ###############################################################
   */
  // we don't want to log anything if we don't have a logging service
  const isMissingDDLogger = !ddLogger;

  /**
   * we don't want to log anything where the elapsed time is too short to be interesting
   * Network conditions get handled different based on kind:
   * 1. GraphQL events we don't throttle much (anything over 50ms qualifies) so we can do meaningful perf analysis
   * 2. `PerfObserver:` network events we only want to log when they're slow, otherwise they overwhelm DD logs
   */
  const isNetworkContext = context?.isNetwork ?? false;
  const elapsedTimeDoesNotMeetThreshold = isNetworkContext
    ? elapsedMs < MIN_NETWORK_ELAPSED_TIME_MILLIS // network events have a different time threshold
    : elapsedMs < MIN_REPORT_TO_SERVER_TIME_MILLIS; // elapsed time is too short to be interesting

  const isShortRunningPerfObserver =
    name.includes('PerfObserver: Network') &&
    elapsedMs < THROTTLED_MIN_NETWORK_ELAPSED_TIME_MILLIS;

  const ignoreConditions = [
    isMissingDDLogger,
    elapsedTimeDoesNotMeetThreshold,
    isShortRunningPerfObserver,
  ];
  /**
   * ###############################################################
   * ################### End Ignorable conditions ##################
   * ###############################################################
   */

  if (!ignoreConditions.includes(true)) {
    // if any of the ignore conditions are true, don't log the event
    const leftAlignedNum = elapsedMs.toString().padStart(6, ' ');
    // 6 because that's enough to show 99s, which is more than we care about.
    ddLogger.logger.warn(
      // wording is slightly awkward, but it's easier to compare times like this
      `${PERF_LOG_PREFIX} Perf event: ${leftAlignedNum}ms for ${name}`,
      {
        perfEvent: name,
        elapsedMs,
        variables,
        ...context,
      }
    );
  }
};
