import React, {createContext, useContext} from 'react';

type PanelInteractionContextType = {
  setZooming: (isZooming: boolean) => void;
  zooming: boolean;
};

export const PanelInteractionContext =
  createContext<PanelInteractionContextType | null>(null);

export const PanelInteractionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [zooming, setZooming] = React.useState(false);

  const value = React.useMemo(
    () => ({
      setZooming: (isZooming: boolean) => {
        setZooming(isZooming);
      },
      zooming,
    }),
    [setZooming, zooming]
  );
  return (
    <PanelInteractionContext.Provider value={value}>
      {children}
    </PanelInteractionContext.Provider>
  );
};

export function usePanelInteractionContext() {
  const context = useContext(PanelInteractionContext);

  if (!context) {
    console.error(
      'usePanelInteractionContext must be used within a PanelInteractionProvider'
    );

    return {
      setZooming: () => {},
      zooming: false,
    };
  }

  return context;
}
