import {ID} from '@wandb/weave/core';

import {Viewer} from '../viewer/types';
import {ViewObjSchema, ViewRef} from '../views/types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {getSafeNwPart, throwIfUnsafeNwId} from './names';

/** The set of values for the type field of the view table  */
export type ViewTypes = ViewObjSchema['type'];
export type WorkspaceMaterialized = ViewObjSchema['whole'];
export type ViewID = ViewRef;

/** Defines the behavior of the workspace (eg saving behavior, readonly behavior) */
export const WorkspaceTypes = {
  // added this as a way to future proof workspaces - if
  // a user loads a project where someone has workspaces that their
  // client doesn't understand, this gives code a chance
  // to recover gracefully
  unknown: 'unknown',
  user: 'user',
  default: 'default',
  named: 'named',
  namedDeleted: 'x-named',
} as const;

export const NW_DEFAULT_ID = 'default';
export const DEFAULT_WORKSPACE_DISPLAY_NAME = 'Default view';

/**
 * This are the values needed to uniquely identify a particular named workspace instance.
 * The id field holds either the legacy view.name or the nw param from url
 */
export type NamedWorkspaceId =
  | {
      id: string;
      nwType: 'shared workspace';
      relatedId: WsRelatedId;
    }
  | {
      id: string;
      nwType: 'personal workspace';
      username: string;
      relatedId: WsRelatedId;
    };

/**
 * The related id is used to store the Id about the specifc sweep that's
 * being referenced. It can theoretically be extended to support groups/runs/etc
 * */
export type WsRelatedId = string | undefined;

export const getNamedWorkspaceId = (unknownNwId: NamedWorkspaceId) => {
  throwIfUnsafeNwId(unknownNwId);
  return unknownNwId;
};

export const createSharedNamedWorkspaceId = (
  relatedId: WsRelatedId
): NamedWorkspaceId => {
  return getNamedWorkspaceId({
    id: ID(12),
    nwType: 'shared workspace',
    relatedId,
  });
};

export const namedWorkspaceBelongsToUser = (
  nwId: NamedWorkspaceId,
  viewer: Viewer | undefined
) => {
  if (nwId.nwType === 'shared workspace') {
    return false;
  }

  return (
    viewer?.username != null &&
    nwId.username != null &&
    nwId.username === viewer.username
  );
};

export const getSharedNwId = (
  id: string,
  relatedId: WsRelatedId
): NamedWorkspaceId =>
  getNamedWorkspaceId({
    id,
    nwType: 'shared workspace',
    relatedId,
  });

export const getPersonalNwId = (
  username: string,
  relatedId: WsRelatedId
): NamedWorkspaceId =>
  // getSafeNwPart is necessary because ids must not contain hyphens (which is the current NAME_SEPARATOR),
  // but valid usernames can include a hyphen
  getNamedWorkspaceId({
    id: getSafeNwPart(`nwuser${username}`),
    nwType: 'personal workspace',
    username,
    relatedId,
  });

export const getIsPersonalWorkspace = (id: string) => {
  return id.includes('nwuser');
};

export const getUsernameFromId = (id?: string) => {
  if (!id || id.length < 7 || !id.includes('nwuser')) {
    return undefined;
  }
  return id.split('nwuser')[1];
};

export type WorkspaceError = {
  errorCode: string;
  userTitle?: string;
  userMessage?: string;
  debugContext?: any;
};

export type ReduxWorkspaceState = {
  error: WorkspaceError | undefined;
  ///// These attributes will all be populated after init

  /**
   * The viewList represents the current set of workspaces visible to the user in the
   * legacy workspace menu. It is pointer to a view list metadata query result in the views
  // reducer's lists attribute.
   * @deprecated for named workspaces
   */
  viewListID: string;
  /**
   * The type of the view represented in the current workspace
   */
  viewType: ViewTypes;
  /**
   * Each sweep has its own set of workspaces. This id is added to the view name column to uniquely identifies them.
   * Will only be populated for viewType 'sweep-view'
   */
  relatedId: WsRelatedId;
  // The spec to revert to if we need to clear
  defaultSpec: WorkspaceMaterialized;
  // TODO: test that when switching between projects, we properly change these
  // values so you're not writing to the old project/entity
  entityName: string;
  projectName: string;

  // I'm keeping these deprecated fields around in case there is a migration
  // case. We should be be safe to remove these fields after dec 2023
  /** @deprecated The view name field for user workspaces */
  userWorkspaceName?: string;
  /** @deprecated The view name field for the default workspace */
  defaultWorkspaceName?: string;

  ///// These attributes change as the user switches between views being displayed
  /**
   * This attribute is changed when a new view is loaded, and is undefined during loading
   * Note that the id in this is the cid for the view.
   *  */
  viewRef: ViewID | undefined;

  /**
   * In named workspaces this holds the fnwid for the view being displayed
   */
  nwId: NamedWorkspaceId | undefined;

  /**
   * In named workspaces, this tracks the list of views that we are showing user
   * We no longer use the ViewListID since that adds extra complexity and
   * it's much easier to add new views this way.
   */
  namedWorkspaceViewCids?: string[];
};

// key is WorkspaceID
export type StateType = Record<string, ReduxWorkspaceState>;
