import {omit as _omit} from 'lodash';

import {denormFn, normFn} from '../normalizerSupport';
import * as PanelNormalize from '../panel/normalize';
import * as Types from './types';

const omit = _omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize = normFn<Types.PanelBankSectionConfigObjSchema>(
  'panel-bank-section-config',
  (whole, ctx) => {
    return {
      ...omit(whole, 'panels'),
      panelRefs:
        whole.panels != null
          ? whole.panels.map(panel => PanelNormalize.normalize(panel, ctx))
          : [],
    };
  }
);

export const denormalize = denormFn<Types.PanelBankSectionConfigObjSchema>(
  (part, ctx) => {
    return {
      ...omit(part, 'panelRefs'),
      panels:
        part.panelRefs != null
          ? part.panelRefs.map((ref, i) =>
              PanelNormalize.denormalize(ref, ctx, i)
            )
          : [],
    };
  }
);
