import {Button} from '@wandb/weave/components/Button';
import React, {FC, memo, useCallback, useState} from 'react';

import {CreateTeamDrawer} from '../../../pages/HomePage/HomePageSidebar/CreateTeamDrawer';
import {useNavContext} from '../../NavContextProvider';

const InviteTeammatesButtonComp: FC = () => {
  const {isCreatingTeam} = useNavContext();
  const [isCreatingTeamInvite, setIsCreatingTeamInvite] = useState(false);

  const toggleTeamInviteDrawer = useCallback(() => {
    setIsCreatingTeamInvite(!isCreatingTeamInvite);
  }, [isCreatingTeamInvite]);

  return (
    <>
      <Button
        icon="users-team"
        className="hidden sm:flex"
        twWrapperStyles={{
          marginRight: '7px',
          marginLeft: '7px',
        }}
        size="large"
        disabled={isCreatingTeam}
        onClick={toggleTeamInviteDrawer}>
        Invite teammates
      </Button>
      <CreateTeamDrawer
        open={isCreatingTeamInvite}
        toggleTeamDrawer={toggleTeamInviteDrawer}
        analyticsLocation="top nav bar"
      />
    </>
  );
};

const InviteTeammatesButton = memo(InviteTeammatesButtonComp);
InviteTeammatesButton.displayName = 'InviteTeammatesButton';

export default InviteTeammatesButton;
