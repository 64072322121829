import {useDeepMemo} from '@wandb/weave/hookUtils';
import {useEffect, useMemo, useState} from 'react';

import {Query} from '../../../util/queryTypes';
import {useSharedPanelState} from '../../Panel/SharedPanelStateContext';
import {POINT_VISUALIZATION_OPTIONS} from '../../WorkspaceDrawer/Settings/types';
import {Range, runsLinePlotTransformQuery} from '../common';
import {usePanelConfigContext} from '../PanelConfigContext';
import {RunsLinePlotConfig} from '../types';
import {BucketedQueryState} from './bucketedQueryManager';

const usePanelQuery = (
  pageQuery: Query,
  config: RunsLinePlotConfig,
  queryStepRange: Range
) => {
  const {limit: numRuns, parsedExpressions} = usePanelConfigContext();
  const transformed = runsLinePlotTransformQuery({
    query: pageQuery,
    runsLinePlotConfig: config,
    xStepRange: queryStepRange,
    parsedExpressions,
    defaultMaxRuns: numRuns,
    isFullFidelity: true,
  });

  return useDeepMemo(transformed);
};

export function useBucketedData(
  config: RunsLinePlotConfig,
  pageQuery: Query,
  queryStepRange: Range,
  nBuckets: number
) {
  const panelQuery = usePanelQuery(pageQuery, config, queryStepRange);
  const {bucketedQueryManager} = useSharedPanelState();

  const [bucketedData, setBucketedData] = useState<BucketedQueryState>({
    loading: true,
    data: null,
    lastUpdated: 0,
    error: null,
    totalRuns: 0,
  });

  useEffect(() => {
    const handlerDataChange = (state: BucketedQueryState) => {
      setBucketedData(state);
    };
    bucketedQueryManager.registerRequest(
      nBuckets,
      panelQuery,
      handlerDataChange
    );
    return () => {
      bucketedQueryManager.unregisterRequest(handlerDataChange);
    };
  }, [bucketedQueryManager, nBuckets, panelQuery]);

  const dataMemo = useMemo(() => {
    return {
      _dataType: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      entityName: pageQuery.entityName,
      histories: {
        data: Object.values(bucketedData.data?.runsById ?? {}).filter(r => !!r),
      },
      initialLoading: false,
      loadMore: () => {},
      projectName: pageQuery.projectName,
      totalRuns: bucketedData.totalRuns,
    };
  }, [
    bucketedData.data?.runsById,
    pageQuery.entityName,
    pageQuery.projectName,
    bucketedData.totalRuns,
  ]);

  return {
    data: dataMemo,
    loading: bucketedData.loading,
  };
}
