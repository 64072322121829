import gql from 'graphql-tag';
import {useMemo} from 'react';

import {
  NwEntityPermissionQueryResult,
  NwProjectPermissionQueryResult,
  useNwEntityPermissionQuery,
  useNwProjectPermissionQuery,
} from '../../generated/graphql';
import {AccessOption, accessOptionMapping} from '../../util/permissions';

export const NAMED_WORKSPACES_ENTITY_PERMISSION = gql`
  query NwEntityPermission($entityName: String) {
    entity(name: $entityName) {
      id
      isTeam
      settings {
        hidden
      }
    }
  }
`;

export type NwEntityPermissionData = {
  isTeam: boolean;
  isTeamHidden: boolean;
};

export function useNwEntityPermissionResult({
  entityName,
  skip,
}: {
  entityName: string;
  skip?: boolean;
}): {
  data: NwEntityPermissionData | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const entityResult = useNwEntityPermissionQuery({
    variables: {
      entityName,
    },
    skip: entityName == null || skip,
  });

  const result = useMemo(
    () =>
      getEntityPermissionResult(
        entityResult.data,
        entityResult.loading,
        entityResult.error
      ),
    [entityResult]
  );

  return result;
}

export const getEntityPermissionResult = (
  data: NwEntityPermissionQueryResult['data'],
  loading: NwEntityPermissionQueryResult['loading'],
  error: Error | undefined
) => {
  if (loading || error) {
    return {
      data: undefined,
      loading,
      error,
    };
  }

  const entity = data?.entity;
  if (entity == null) {
    return {
      data: undefined,
      loading: false,
      error: {
        name: 'Unable to find entity permissions.',
        message: 'Error getting entity information.',
      },
    };
  }
  return {
    data: {
      isTeam: entity?.isTeam ?? false,
      isTeamHidden: entity?.settings.hidden ?? false,
    },
    loading: false,
    error: undefined,
  };
};

export const NAMED_WORKSPACES_PROJECT_PERMISSION = gql`
  query NwProjectPermission($projectName: String!, $entityName: String) {
    project(name: $projectName, entityName: $entityName) {
      id
      access
      user {
        id
        username
      }
      members {
        id
        username
      }
    }
  }
`;

export type RestrictedProjectMember = {
  id: string;
  username: string;
};

export type NwProjectPermissionData = {
  projectAccess: AccessOption;
  projectUsername: string | undefined;
  restrictedProjectMembers: RestrictedProjectMember[];
};

export function useNwProjectPermissionResult({
  projectName,
  entityName,
  skip,
}: {
  projectName: string;
  entityName?: string;
  skip?: boolean;
}): {
  data: NwProjectPermissionData | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const projectResult = useNwProjectPermissionQuery({
    variables: {
      entityName,
      projectName,
    },
    skip: entityName == null || projectName == null || skip,
  });

  const result = useMemo(
    () =>
      getProjectPermissionResult(
        projectResult.data,
        projectResult.loading,
        projectResult.error
      ),
    [projectResult]
  );
  return result;
}

export const getProjectPermissionResult = (
  data: NwProjectPermissionQueryResult['data'],
  loading: NwProjectPermissionQueryResult['loading'],
  error: Error | undefined
) => {
  if (loading || error) {
    return {
      data: undefined,
      loading,
      error,
    };
  }

  const project = data?.project;
  const projectAccess = project?.access;
  if (
    projectAccess == null ||
    Object.keys(accessOptionMapping).indexOf(projectAccess) < 0
  ) {
    return {
      data: undefined,
      loading: false,
      error: {
        name: 'Unable to find project permissions.',
        message: 'Error getting project and entity information.',
      },
    };
  }

  const restrictedProjectMembers: RestrictedProjectMember[] = (
    project?.members ?? []
  ).map(m => ({
    id: m.id,
    username: m.username ?? '',
  }));

  return {
    data: {
      projectAccess: projectAccess as AccessOption, // can safely type assert after checking if value is in accessOptionMapping
      // If the user is undefined, that means a service account created the project.
      // example: wandb-sdk team has projects like this
      projectUsername: project?.user?.username ?? undefined,
      restrictedProjectMembers,
    },
    loading: false,
    error: undefined,
  };
};
