import {DocumentNode} from 'graphql';
import {useLazyQuery} from 'react-apollo';

import {ExtractPageInfo} from './types';

export type InfiniteResultsResult = {
  fetchMore: (options: {variables: any}) => void;
  isLoading: boolean;
};

export function useInfiniteResults(
  {
    entityName,
    projectName,
    addListItems,
    extractItems,
    extractPageInfo,
    query,
    extraQueryVariables = {},
    updatePageDetails,
  }: {
    entityName: string;
    projectName: string;
    addListItems: (newItems: string[]) => void;
    extractItems: (data: any) => string[];
    extractPageInfo: ExtractPageInfo;
    query: DocumentNode;
    extraQueryVariables?: any;
    updatePageDetails: (hasNextPage: boolean, endCursor: string) => void;
  },
  config: {
    useQueryHook: typeof useLazyQuery;
  } = {
    useQueryHook: useLazyQuery,
  }
): InfiniteResultsResult {
  const [fetchMore, {loading}] = config.useQueryHook(query, {
    onCompleted: data => {
      const newItems = extractItems(data);
      addListItems(newItems);
      const pageData = extractPageInfo(data);
      updatePageDetails(pageData.hasNextPage, pageData.endCursor);
    },
    context: {
      // Prevents the app from hard crashing when receiving 500s
      // See @onError in frontends/app/src/util/apollo.ts
      propagateErrors: true,
    },
    variables: {
      entityName,
      projectName,
      pattern: '',
      after: '',
      first: 20,
      ...extraQueryVariables,
    },
    fetchPolicy: `cache-and-network`,
  });

  return {
    fetchMore,
    isLoading: loading,
  };
}
