import {isNotNullUndefinedOrFalse} from '@wandb/weave/common/util/types';
import {clone, setWith, updateWith} from 'lodash';

export function isOneOf(v: any, values: any[]): boolean {
  return values.indexOf(v) > -1;
}

export function getDomainFromEmail(email: string): string {
  return email.substring(email.lastIndexOf('@') + 1);
}

export function removeNullUndefinedOrFalse<T>(
  arr: Array<T | null | undefined | false>
): T[] {
  return arr.filter(isNotNullUndefinedOrFalse);
}

export function cyrb53a(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 0x85ebca77);
    h2 = Math.imul(h2 ^ ch, 0xc2b2ae3d);
  }
  h1 ^= Math.imul(h1 ^ (h2 >>> 15), 0x735a2d97);
  h2 ^= Math.imul(h2 ^ (h1 >>> 15), 0xcaf649a9);
  h1 ^= h2 >>> 16;
  h2 ^= h1 >>> 16;
  return 2097152 * (h2 >>> 0) + (h1 >>> 11);
}

/**
 * Given a dot-deliniated path to a property within Value, return the type of
 * Value or undefined. So, for example:
 *
 * @example
 * const s = {
 *   users: {
 *     '123': {
 *       addresses: [{
 *         house_number_and_street: '500 Pennsylvania Avenue'
 *       }]
 *     }
 *   }
 * } as const
 * type Address = GetType<typeof s, 'users.123.addresses.0'>
 * // Address expands to '{house_number_and_street: "500 Pennsylvania Avenue"}'
 *
 * type NonUser = GetType<typeof s, 'users.456'> // undefined
 */
type GetFieldTypeFromString<Value, Path> =
  Path extends `${infer Left}.${infer Right}`
    ? Left extends keyof Value
      ? GetFieldTypeFromString<Value[Left], Right>
      : undefined
    : Path extends keyof Value
    ? Value[Path]
    : undefined;

type GetFieldTypeFromAry<Value, Path> = Path extends []
  ? Value
  : Path extends readonly [infer First, ...infer Rest]
  ? First extends keyof Value
    ? GetFieldTypeFromAry<Value[First], Rest>
    : undefined
  : unknown;

/**
 * Creates a shallow clone of an `obj` through the
 * specified `path` and sets the value at the path to
 * the provided `value`. Useful in reducers where we
 * need to update deeply nested state immutably.
 */
export function setInShallowClone<Obj extends object | [], Path extends string>(
  obj: Obj,
  path: Path,
  value: GetFieldTypeFromString<Obj, Path>
): Obj;
export function setInShallowClone<
  Obj extends object | [],
  Path extends readonly unknown[] | []
>(obj: Obj, path: Path, value: GetFieldTypeFromAry<Obj, Path>): Obj;
export function setInShallowClone<Obj extends object | []>(
  obj: Obj,
  path: any,
  value: any
): Obj;
export function setInShallowClone<Obj extends object | []>(
  obj: any,
  path: any,
  value: any
): Obj {
  return setWith(clone(obj), path, value, clone);
}

type UpdaterFn<Arg> = (arg: Arg) => Arg;

/**
 * Given a value, a dot-delinated path or array path, and an update function,
 * return a new value that has the updater change applied to the value at the
 * path. Attempts to smartly structurally share data along the way. For arrays,
 * continue to use dot syntax (e.g. `someList.3.property`) rather than subscript
 * notation.
 *
 * @param value
 * @param path
 * @param updater
 */
export function updateIn<Value extends object | [], Path extends string>(
  value: Value,
  path: Path,
  updater: UpdaterFn<GetFieldTypeFromString<Value, Path>>
): Value;
export function updateIn<
  Value extends object | [],
  Path extends readonly unknown[] | []
>(
  value: Value,
  path: Path,
  updater: UpdaterFn<GetFieldTypeFromAry<Value, Path>>
): Value;
export function updateIn<Value extends object | []>(
  value: Value,
  path: any,
  updater: UpdaterFn<any>
): Value;
export function updateIn<Value extends object | []>(
  value: any,
  path: any,
  updater: UpdaterFn<any>
): Value {
  return updateWith<Value>(clone(value), path as any, updater, clone);
}
