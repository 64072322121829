import {Line} from './../types';

/**
 * When all of the lines' x values line up perfectly, we can skip the whole bucketing process and simply treat each x value as a bucket with 1 point from each line.This also prevents jankery -- users don't expect grouping to change the x values if all the lines in the group share the same set of x values. This almost always returns false when sampling is in effect, as there is no way to guarantee sampling the same x values for every line.
 */
export function linesShareXInterval(lines: Line[]): boolean {
  if (lines.length === 0) {
    return false;
  }
  if (lines.length === 1) {
    return true;
  }

  const [firstLine, ...restOfLines] = lines;

  // fastest way is to compare the length of the x-values - if they differ we can eject and know we need to bucket
  const linesAreNotEqualLength = restOfLines.some(
    l => l.data.length !== firstLine.data.length
  );
  if (linesAreNotEqualLength) {
    return false;
  }

  // if all the lines are equal length we check to make sure all the x-axis values line up
  // the first one non-matched means we can eject and proceed with bucketing
  const hasUnmatchedX = firstLine.data.some((point, i) => {
    return restOfLines.some(l => {
      return l.data[i].x !== point.x;
    });
  });

  if (hasUnmatchedX) {
    return false;
  }

  return true;
}
