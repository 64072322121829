import produce from 'immer';

import {legendTemplateRemoveCrosshairValues} from '../../util/legend';
import {ChartAggOption, ChartAreaOption} from './chartTypes';
import {getLegendOverrideKeyBackwardsCompatible} from './legend';
import {Bar, Line, Mark} from './types';

export function isChartAreaOption(s: string): s is ChartAreaOption {
  return (
    s === 'minmax' ||
    s === 'stddev' ||
    s === 'stderr' ||
    s === 'none' ||
    s === 'samples'
  );
}

export function isChartAggOption(s: string): s is ChartAggOption {
  return (
    s === 'mean' ||
    s === 'min' ||
    s === 'max' ||
    s === 'median' ||
    s === 'sum' ||
    s === 'samples'
  );
}

export function overrideLineTitles(
  lines: Line[],
  overrides: {[key: string]: string},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalTitle = l.title;
      if (lineKey in overrides) {
        l.title = overrides[lineKey];
        if (l.fancyTitle) {
          l.fancyTitle.legend = legendTemplateRemoveCrosshairValues(l.title);
        }
      }
    });
  });
}

export function overrideBarTitles(
  bars: Bar[],
  overrides: {[key: string]: string},
  useRunOrGroupId: boolean
): Bar[] {
  return produce(bars, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalTitle = l.title;
      if (lineKey in overrides) {
        l.title = overrides[lineKey];
        l.key = legendTemplateRemoveCrosshairValues(l.title);
      }
    });
  });
}

export function overrideBarColors(
  lines: Bar[],
  overrides: {[key: string]: {color: string; transparentColor: string}},
  useRunOrGroupId: boolean
): Bar[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );

      if (lineKey in overrides) {
        l.originalColor = l.color;
        l.color = overrides[lineKey].color;
      }
    });
  });
}

export function overrideLineColors(
  lines: Line[],
  overrides: {[key: string]: {color: string; transparentColor: string}},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );

      if (lineKey in overrides) {
        l.originalColor = l.color;
        if (l.aux) {
          l.color = overrides[lineKey].transparentColor;
        } else {
          l.color = overrides[lineKey].color;
        }
      }
    });
  });
}

export function overrideMarks(
  lines: Line[],
  overrides: {[key: string]: Mark},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalMark = l.mark;
      if (lineKey in overrides) {
        l.mark = overrides[lineKey];
      }
    });
  });
}

export function overrideLineWidths(
  lines: Line[],
  overrides: {[key: string]: number},
  useRunOrGroupId: boolean
): Line[] {
  return produce(lines, draft => {
    draft.forEach(l => {
      const lineKey = getLegendOverrideKeyBackwardsCompatible(
        l,
        useRunOrGroupId,
        overrides
      );
      l.originalLineWidth = l.lineWidth;
      if (lineKey in overrides) {
        l.lineWidth = overrides[lineKey];
      }
    });
  });
}
