import {fuzzyMatchWithMapping} from '@wandb/weave/common/util/fuzzyMatch';
import {useMemo} from 'react';

import {
  useSearchEntityProjectsQuery,
  useSearchViewerQuery,
} from '../../generated/graphql';
import {useCurrentRouteMatchParams} from '../../routes/utils';
import * as Urls from '../../util/urls';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  LOCAL_SEARCH_RESULT_TYPES,
  LocalSearchResult,
  SearchNavProject,
  SearchNavQueryData,
  SearchNavReport,
  SearchNavTeam,
} from '.';
import {ResultFilterMode} from './FilterTabs';

type LocalSearchDataParams = {
  searchTerm: string;
  filterMode: ResultFilterMode;
};

type LocalSearchData = {
  loading: boolean;
  filteredResults: LocalSearchResult[];
  disabledFilterModes: Set<ResultFilterMode>;
};

export function useLocalSearchData({
  searchTerm,
  filterMode,
}: LocalSearchDataParams): LocalSearchData {
  const params = useCurrentRouteMatchParams();

  const {loading: searchViewerLoading, data: searchViewer} =
    useSearchViewerQuery();

  // Only fetch entity projects if entityName changes
  const {loading: searchProjectsLoading, data: searchProjects} =
    useSearchEntityProjectsQuery({
      variables: {entityName: params?.entityName},
      skip: !params?.entityName,
    });

  // Only show "loading" when we have NO data, or both queries are loading
  const searchLoading = searchViewerLoading && searchProjectsLoading;

  return useMemo(() => {
    const allResults = getLocalSearchResults({
      data: {
        ...searchViewer,
        ...searchProjects,
      } as SearchNavQueryData,
      currentQuery: searchTerm,
    });

    const filteredResults =
      filterMode === `all`
        ? allResults
        : allResults.filter(r => r.type === filterMode);

    const disabledSet = new Set<ResultFilterMode>(LOCAL_SEARCH_RESULT_TYPES);
    for (const r of allResults) {
      disabledSet.delete(r.type);
    }

    return {
      loading: searchLoading,
      filteredResults,
      disabledFilterModes: disabledSet,
    };
  }, [searchTerm, filterMode, searchLoading, searchViewer, searchProjects]);
}

type GetLocalSearchResultsParams = {
  data: SearchNavQueryData | undefined;
  currentQuery: string;
};

function getLocalSearchResults({
  data,
  currentQuery,
}: GetLocalSearchResultsParams): LocalSearchResult[] {
  if (data?.viewer == null) {
    return [];
  }

  const alreadyFoundId: {
    [key: string]: boolean;
  } = {};

  const entityOptions: LocalSearchResult[] = [];
  data.projects?.edges.forEach(project => {
    alreadyFoundId[project.node.id] = true;
    entityOptions.push(projectToLocalSearchResult(project.node));
    project.node.allViews?.edges.forEach(view => {
      alreadyFoundId[view.node.id] = true;
      const reportResult = reportToLocalSearchResult(view.node);
      if (reportResult != null) {
        entityOptions.push(reportResult);
      }
    });
  });

  const contributedOptions: LocalSearchResult[] = [];
  if (currentQuery) {
    data.viewer.projects.edges.forEach(project => {
      if (!alreadyFoundId[project.node.id]) {
        alreadyFoundId[project.node.id] = true;
        contributedOptions.push(projectToLocalSearchResult(project.node));
      }
    });
    data.viewer.views?.edges.forEach(view => {
      if (!alreadyFoundId[view.node.id]) {
        alreadyFoundId[view.node.id] = true;
        const reportResult = reportToLocalSearchResult(view.node);
        if (reportResult != null) {
          entityOptions.push(reportResult);
        }
      }
    });
  }

  const remainingOptions: LocalSearchResult[] = [];
  if (currentQuery) {
    data.viewer.teams.edges.forEach(team => {
      remainingOptions.push(teamToLocalSearchResult(team.node));
      team.node.projects.edges.forEach(project => {
        if (!alreadyFoundId[project.node.id]) {
          remainingOptions.push(projectToLocalSearchResult(project.node));
        }
      });
    });
  }

  return fuzzyMatchWithMapping<LocalSearchResult>(
    [...contributedOptions, ...entityOptions, ...remainingOptions],
    currentQuery,
    result => result.name
  );
}

function projectToLocalSearchResult(
  project: SearchNavProject
): LocalSearchResult {
  return {
    type: 'project',
    name: project.name,
    entityName: project.entityName,
    url: Urls.project({
      name: project.name,
      entityName: project.entityName,
    }),
  };
}

function reportToLocalSearchResult(
  report: SearchNavReport
): LocalSearchResult | null {
  if (!report.displayName || report.project == null) {
    return null;
  }
  return {
    type: 'report',
    name: report.displayName,
    entityName: report.project.entityName,
    url: Urls.reportView({
      entityName: report.project.entityName,
      projectName: report.project.name,
      reportID: report.id,
      reportName: report.displayName,
    }),
  };
}

function teamToLocalSearchResult(team: SearchNavTeam): LocalSearchResult {
  return {
    type: 'team',
    name: team.name,
    url: Urls.teamPage(team.name),
    imageURL: team.photoUrl,
  };
}
