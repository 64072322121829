import {assertUnreachable} from '@wandb/weave/common/util/types';

import {Viewer} from '../viewer/types';
import {LoadableView, ViewType} from '../views/types';
import {
  findLegacyOrNwView,
  findLegacyView,
  getDefaultLandingView,
} from './find';
import {getUserWorkspaceDisplayName} from './namedWorkspaceActionsInternal';
import {
  getNamedWorkspaceIdForView,
  viewIsDefault,
  viewNameColumnForNamedWorkspace,
} from './names';
import {
  LoadPermsResult,
  viewerCanOnlyReadNw,
  viewerCanOnlyWriteToOwnPersonal,
  viewerCanWriteToNw,
  viewerHasNoNwPerms,
} from './nwPermissions';
import {
  getNamedWorkspaceId,
  getPersonalNwId,
  getSharedNwId,
  NamedWorkspaceId,
  NW_DEFAULT_ID,
  WsRelatedId,
} from './types';
import {WorkspaceUrl} from './url';

const NO_PERMISSIONS_MESSAGE =
  'You do not have permissions to view this workspace.';
const NOT_FOUND_ERROR_MESSAGE = 'That workspace could not be found.';
export const UNAVAILBALE_WORKSPACES_ERROR: WorkspaceLoadActionError = {
  type: 'error',
  errorCode: 'notFound',
  userTitle: 'Workspace unavailable',
  userMessage: 'No workspaces are available for public viewing.',
};

/**
 * The set of actions we can take when visiting a url.
 *
 * We don't support creating workspaces via visiting a URL, so 'load'
 * is the normal use case, but the primary workspace is special, so we allow creating it.
 *
 * We differentiate between the create scenarios since we already
 * have the list of views, so we can avoid loading the metadatalist for views
 * later by figuring out what needs to be created here.
 */
type WorkspaceLoadActionError = {
  type: 'error';
  errorCode: 'notFound' | 'noEmpty' | 'noPerms';
  userTitle?: string;
  userMessage?: string;
  debugContext?: any;
};
export type WorkspaceLoadAction =
  | {type: 'load'; viewCid: string; nwId: NamedWorkspaceId}
  | {
      type: 'migrateLegacy';
      legacyViewServerId: string;
      newNwId: NamedWorkspaceId;
      newDisplayName: string;
    }
  | WorkspaceLoadActionError
  | {
      type: 'createAndLoadNewView';
      nwId: NamedWorkspaceId;
      displayName: string;
    };

/**
 * Figures out what we should do when the workspace loads from a URL.
 * It notably uses the list of views for the entity/project/run/group/sweep/etc,
 * which means that we know if we need to create things.
 */
export const determineLoadActionForUrl = (
  workspaceUrl: WorkspaceUrl,
  viewer: Viewer | undefined,
  legacyAndNwViews: LoadableView[],
  relatedId: WsRelatedId,
  viewType: ViewType,
  nwPermInfo: LoadPermsResult
): WorkspaceLoadAction => {
  if (viewerHasNoNwPerms(nwPermInfo.nwPermissions.data)) {
    return {
      type: 'error',
      errorCode: 'noPerms',
      userMessage: NO_PERMISSIONS_MESSAGE,
    };
  }

  const viewerUsername = viewer?.username;

  switch (workspaceUrl.type) {
    case 'empty':
      return {
        type: 'error',
        errorCode: 'noEmpty',
        userMessage: NOT_FOUND_ERROR_MESSAGE,
      };
    case 'default':
      return determineLoadActionForLegacyDefault(
        legacyAndNwViews,
        relatedId,
        viewType,
        viewerUsername,
        nwPermInfo
      );

    case 'user':
      return determineLoadActionForLegacyUser(
        {type: 'user', username: workspaceUrl.user},
        legacyAndNwViews,
        relatedId,
        viewType,
        viewerUsername,
        nwPermInfo
      );

    case 'none':
      // user did not specify a workspace id when visiting the project
      return determineLoadActionForLegacyUser(
        {type: 'none', username: viewerUsername},
        legacyAndNwViews,
        relatedId,
        viewType,
        viewerUsername,
        nwPermInfo
      );

    case 'named':
      return determineLoadActionForNamedWorkspace(
        workspaceUrl,
        legacyAndNwViews,
        viewerUsername,
        relatedId,
        viewType,
        nwPermInfo
      );

    default:
      assertUnreachable(workspaceUrl);
  }
};

const determineLoadActionForLegacyDefault = (
  legacyAndNwViews: LoadableView[],
  relatedId: WsRelatedId,
  viewType: ViewType,
  viewerUsername: string | undefined,
  nwPermInfo: LoadPermsResult
): WorkspaceLoadAction => {
  // go find a workspace to land on if viewer has read perms or viewer is logged out
  if (
    viewerUsername == null ||
    viewerCanOnlyReadNw(nwPermInfo.nwPermissions.data)
  ) {
    const defaultLandingView = getDefaultLandingView({
      legacyAndNwViews,
      relatedId,
      viewType,
    });
    if (defaultLandingView) {
      return {
        type: 'load',
        nwId: getNamedWorkspaceIdForView(
          defaultLandingView.name,
          defaultLandingView.user
        ),
        viewCid: defaultLandingView.cid,
      };
    }
    return {
      ...UNAVAILBALE_WORKSPACES_ERROR,
      debugContext: {
        legacyAndNwViews,
        nwPermInfo,
        workspaceType: 'default',
      },
    };
  }

  // We only care about legacy views now to keep things consistent across all of workspace versions
  // We ignore the migrated default view at this point.
  const legacyDefaultView = findLegacyView(
    undefined,
    legacyAndNwViews,
    relatedId,
    viewType,
    'shared workspace'
  );
  if (legacyDefaultView) {
    return {
      type: 'load',
      nwId: getNamedWorkspaceIdForView(
        legacyDefaultView.name,
        legacyDefaultView.user
      ),
      viewCid: legacyDefaultView.cid,
    };
  }

  // Try to find the current viewer's personal workspace and land them there
  return determineLoadActionForCurrentViewer(
    legacyAndNwViews,
    relatedId,
    viewType,
    viewerUsername
  );
};

const determineLoadActionForCurrentViewer = (
  legacyAndNwViews: LoadableView[],
  relatedId: WsRelatedId,
  viewType: ViewType,
  viewerUsername: string | undefined
): WorkspaceLoadAction => {
  // Fall back to load user view if one exists
  // go load the legacy view or the migrated one if legacy doesn't exist
  const personalNwId = getPersonalNwId(viewerUsername ?? '', relatedId);
  const {foundView, nwId} = findLegacyOrNwView(
    viewerUsername,
    legacyAndNwViews,
    relatedId,
    viewType,
    personalNwId
  );
  if (foundView && foundView.id && nwId) {
    return {
      type: 'load',
      nwId,
      viewCid: foundView.cid,
    };
  }

  // Go create the user view if neither a legacy default or user view exist
  return {
    type: 'createAndLoadNewView',
    nwId: personalNwId,
    displayName: getUserWorkspaceDisplayName(viewerUsername, viewType),
  };
};

const determineLoadActionForLegacyUser = (
  typeSpecificData:
    | {type: 'none'; username?: string}
    | {
        type: 'user';
        username: string;
      },
  legacyAndNwViews: LoadableView[],
  relatedId: WsRelatedId,
  viewType: ViewType,
  viewerUsername: string | undefined,
  nwPermInfo: LoadPermsResult
): WorkspaceLoadAction => {
  if (
    typeSpecificData.username == null ||
    viewerUsername == null ||
    viewerCanOnlyReadNw(nwPermInfo.nwPermissions.data)
  ) {
    const defaultLandingView = getDefaultLandingView({
      legacyAndNwViews,
      relatedId,
      viewType,
    });
    if (defaultLandingView) {
      return {
        type: 'load',
        nwId: getNamedWorkspaceIdForView(
          defaultLandingView.name,
          defaultLandingView.user
        ),
        viewCid: defaultLandingView.cid,
      };
    }
    return {
      ...UNAVAILBALE_WORKSPACES_ERROR,
      debugContext: {
        legacyAndNwViews,
        typeSpecificData,
        nwPermInfo,
      },
    };
  }

  // go load the legacy view or the migrated one if legacy doesn't exist
  const {foundView, nwId} = findLegacyOrNwView(
    // This is specified in the username and may not belong to viewer.
    // example: team members can view another teammate's personal workspace
    typeSpecificData.username,
    legacyAndNwViews,
    relatedId,
    viewType,
    getPersonalNwId(typeSpecificData.username, relatedId)
  );
  if (foundView && foundView.id && nwId) {
    return {
      type: 'load',
      nwId,
      viewCid: foundView.cid,
    };
  }

  // Go create the user view if neither a legacy default or user view exist
  if (viewerUsername === typeSpecificData.username) {
    return {
      type: 'createAndLoadNewView',
      nwId: getPersonalNwId(typeSpecificData.username, relatedId),
      displayName: getUserWorkspaceDisplayName(
        typeSpecificData.username,
        viewType
      ),
    };
  }

  // Try to find the current viewer's personal workspace and land them there
  return determineLoadActionForCurrentViewer(
    legacyAndNwViews,
    relatedId,
    viewType,
    viewerUsername
  );
};

const determineLoadActionForNamedWorkspace = (
  workspaceUrl:
    | {
        type: 'named';
        id: string;
        nwType: 'shared workspace';
      }
    | {
        type: 'named';
        id: string;
        nwType: 'personal workspace';
        username: string;
      },
  legacyAndNwViews: LoadableView[],
  viewerUsername: string | undefined,
  relatedId: WsRelatedId,
  viewType: ViewType,
  nwPermInfo: LoadPermsResult
): WorkspaceLoadAction => {
  let nwId;
  switch (workspaceUrl.nwType) {
    case 'shared workspace':
      /**
       * nw=default
       * In the NW 1.2, we want to use the legacy default view and ignore
       * any the previous migrated NW default views.
       * If a legacy default view can't be found, we should go and load/create
       * the viewer's personal workspace instead.
       */
      if (viewIsDefault(workspaceUrl.id)) {
        nwId = getSharedNwId(NW_DEFAULT_ID, relatedId);
        return determineLoadActionForLegacyDefault(
          legacyAndNwViews,
          relatedId,
          viewType,
          viewerUsername,
          nwPermInfo
        );
      }

      nwId = getNamedWorkspaceId({
        id: workspaceUrl.id,
        nwType: workspaceUrl.nwType,
        relatedId,
      });
      return getLoadActionFromLegacyAndNWViews(
        nwId,
        legacyAndNwViews,
        undefined,
        relatedId,
        viewType,
        viewerUsername,
        nwPermInfo
      );
    case 'personal workspace':
      nwId = getPersonalNwId(workspaceUrl.username, relatedId);
      return getLoadActionFromLegacyAndNWViews(
        nwId,
        legacyAndNwViews,
        workspaceUrl.username,
        relatedId,
        viewType,
        viewerUsername,
        nwPermInfo
      );
    default:
      assertUnreachable(workspaceUrl);
  }
};

const getLoadActionFromLegacyAndNWViews = (
  nwId: NamedWorkspaceId,
  legacyAndNwViews: LoadableView[],
  username: string | undefined, // if we don't know the username, skip looking in legacy views
  relatedId: WsRelatedId,
  viewType: ViewType,
  viewerUsername: string | undefined,
  nwPermInfo: LoadPermsResult
): WorkspaceLoadAction => {
  const {foundView, nwId: foundNwId} = findLegacyOrNwView(
    username,
    legacyAndNwViews,
    relatedId,
    viewType,
    nwId
  );
  if (foundView && foundView.cid && foundNwId) {
    return {type: 'load', viewCid: foundView.cid, nwId: foundNwId};
  }

  // Only viewers with write perms can have personal workspace
  // we want to allow w&b admins with powers to land on their own workspace too
  const permData = nwPermInfo.nwPermissions.data;
  if (
    viewerCanWriteToNw(permData) ||
    viewerCanOnlyWriteToOwnPersonal(permData)
  ) {
    return determineLoadActionForCurrentViewer(
      legacyAndNwViews,
      relatedId,
      viewType,
      viewerUsername
    );
  }

  // go find a workspace to land on if
  // (1) viewer has read perms or
  // (2) viewer is logged out or
  // (3) couldn't find specific workspace specified in url
  const defaultLandingView = getDefaultLandingView({
    legacyAndNwViews,
    relatedId,
    viewType,
  });
  if (defaultLandingView) {
    return {
      type: 'load',
      nwId: getNamedWorkspaceIdForView(
        defaultLandingView.name,
        defaultLandingView.user
      ),
      viewCid: defaultLandingView.cid,
    };
  }

  return {
    ...UNAVAILBALE_WORKSPACES_ERROR,
    debugContext: {
      legacyAndNwViews,
      nwId,
      name: viewNameColumnForNamedWorkspace(nwId),
    },
  };
};
