import {avg} from './../../../util/plotHelpers';
import {SafePoint} from './types';

export type PointReduceFn = (points: {x: number; y: number[]}) => SafePoint;

export const averagePoint = ({x, y}: {x: number; y: number[]}) => {
  return {
    x,
    y: avg(y),
  };
};

export const minPoint = ({x, y}: {x: number; y: number[]}) => {
  return {
    x,
    y: Math.min(...y),
  };
};

export const maxPoint = ({x, y}: {x: number; y: number[]}) => {
  return {
    x,
    y: Math.max(...y),
  };
};
