import gql from 'graphql-tag';
import {useMemo} from 'react';

import {TeamMemberRole} from '../../components/MembersTable/TeamMembersTable/utils';
import {Member, useTeamMemberQuery} from '../../generated/graphql';
import {Viewer} from '../viewer/types';

const TEAM_MEMBER_FIELDS = gql`
  fragment TeamMemberFields on Member {
    id
    username
    name
    photoUrl
    role
  }
`;

// Used for fetching potential report authors, checking team membership, and admin status
export const TEAM_MEMBERS_QUERY = gql`
  query TeamMembers($entityName: String!) {
    entity(name: $entityName) {
      id
      members {
        ...TeamMemberFields
      }
    }
  }
  ${TEAM_MEMBER_FIELDS}
`;

export const TEAM_MEMBER_QUERY = gql`
  query TeamMember($entityName: String!, $memberId: ID!) {
    entity(name: $entityName) {
      id
      member(id: $memberId) {
        ...TeamMemberFields
      }
    }
  }
  ${TEAM_MEMBER_FIELDS}
`;

type TeamMemberArgs = {
  viewer?: Viewer;
  entityName?: string;
  skip?: boolean;
};
export type TeamMemberInfo = {
  viewerIsTeamAdmin: boolean;
  viewerIsTeamMember: boolean;
  foundTeamMember?: Member;
};
// Finds a team member -- hook should probably be renamed for clarity
export function useTeamMember({
  viewer,
  entityName,
  skip,
}: TeamMemberArgs): TeamMemberInfo {
  const {data} = useTeamMemberQuery({
    variables: {
      entityName: entityName ?? '',
      memberId: viewer?.id ?? '',
    },
    skip: entityName == null || skip,
  });

  const member = data?.entity?.member;
  return useMemo(
    () => getTeamMemberInfo(viewer, [member] as Partial<Member[]>),
    [viewer, member]
  );
}

export const findTeamMember = (
  viewer: Viewer | undefined,
  members: Partial<Member[]>
) => {
  return members.find(member => member?.id && member.id === viewer?.id);
};

export const getTeamMemberInfo = (
  viewer: Viewer | undefined,
  members: Partial<Member[]>
) => {
  const foundTeamMember = findTeamMember(viewer, members);

  if (foundTeamMember == null) {
    return {
      viewerIsTeamAdmin: false,
      viewerIsTeamMember: false,
    };
  }

  return {
    viewerIsTeamAdmin: foundTeamMember.role === TeamMemberRole.ADMIN,
    viewerIsTeamMember: true,
    foundTeamMember,
  };
};
