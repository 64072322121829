import React from 'react';

const DEFAULT_SNAP_FUNC = (f: number) => f;

type ResizerDirection = 'up' | 'right' | 'down' | 'left';

function createShield() {
  const shield = document.createElement('div');
  shield.className = 'resizer-shield';
  shield.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    z-index: ${Number.MAX_SAFE_INTEGER};
  `;
  return shield;
}

function getCursorSet(direction: ResizerDirection) {
  switch (direction) {
    case 'up':
      return ['n-resize', 'ns-resize', 's-resize'];
    case 'right':
      return ['e-resize', 'ew-resize', 'w-resize'];
    case 'down':
      return ['s-resize', 'ns-resize', 'n-resize'];
    case 'left':
      return ['w-resize', 'ew-resize', 'e-resize'];
  }
}

export function useResizer(
  direction: ResizerDirection,
  initialSize: number,
  opts: {
    min?: number;
    max?: number;
    deltaMultiplier?: number;
    snapFunc?(from: number): number;
  } = {}
) {
  const {
    min = 0,
    max = Infinity,
    deltaMultiplier = 1,
    snapFunc = DEFAULT_SNAP_FUNC,
  } = opts;
  const getCursor = React.useCallback(
    (rawSize: number) => {
      const cursorSet = getCursorSet(direction);
      const snappedSize = snapFunc(rawSize);
      if (snappedSize <= snapFunc(min)) {
        return cursorSet[0];
      } else if (snappedSize >= snapFunc(max)) {
        return cursorSet[2];
      } else {
        return cursorSet[1];
      }
    },
    [min, max, snapFunc, direction]
  );
  const getDropSizeFromDragSize = React.useCallback(
    (dragSize: number) => {
      const clampedSize = Math.min(Math.max(dragSize, min), max);
      return snapFunc(clampedSize);
    },
    [min, max, snapFunc]
  );
  const [size, setSize] = React.useState(initialSize);
  const [sizeDelta, setSizeDelta] = React.useState(0);
  const [resizing, setResizing] = React.useState(false);
  const [cursor, setCursor] = React.useState(getCursor(initialSize));

  let positionKey: 'pageY' | 'pageX';
  if (direction === 'up' || direction === 'down') {
    positionKey = 'pageY';
  } else {
    positionKey = 'pageX';
  }

  let directionMultiplier: 1 | -1;
  if (direction === 'down' || direction === 'right') {
    directionMultiplier = 1;
  } else {
    directionMultiplier = -1;
  }

  function onMouseDown(mouseDownEvent: React.MouseEvent) {
    const mouseDownPosition = mouseDownEvent[positionKey];
    setResizing(true);
    const shield = createShield();
    document.body.appendChild(shield);
    const target = mouseDownEvent.currentTarget;
    shield.style.cursor = window.getComputedStyle(target).cursor;

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      const rawDelta = mouseMoveEvent[positionKey] - mouseDownPosition;
      const realDelta = rawDelta * directionMultiplier * deltaMultiplier;
      setSizeDelta(realDelta);
      setCursor(getCursor(size + realDelta));
      shield.style.cursor = window.getComputedStyle(target).cursor;
    }
    window.addEventListener('mousemove', onMouseMove);

    function onMouseUp(mouseUpEvent: MouseEvent) {
      const rawDelta = mouseUpEvent[positionKey] - mouseDownPosition;
      const realDelta = rawDelta * directionMultiplier * deltaMultiplier;
      setSize(prevSize => getDropSizeFromDragSize(prevSize + realDelta));
      setSizeDelta(0);
      setResizing(false);
      document.body.removeChild(shield);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }
    window.addEventListener('mouseup', onMouseUp);
  }

  return {
    onMouseDown,
    size,
    setSize,
    dragSize: size + sizeDelta,
    dropSize: getDropSizeFromDragSize(size + sizeDelta),
    resizing,
    cursor,
  };
}
