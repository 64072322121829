import {useCallback} from 'react';
import {useLocation} from 'react-router';

import {
  appLauncherFailure,
  appLauncherOnScreen,
  appLauncherUsed,
  QuickNavData,
  searchNavOnScreen,
  searchNavUsed,
} from './../../services/analytics';
import {ActivationMethods} from './../../services/analytics/types';

type SearchNavAnalytics = {
  trackSearchNavOnScreen: (activationMethod: ActivationMethods) => void;
  trackSearchNavUsed: (destination: string) => void;
  trackAppLauncherOnScreen: (activationMethod: ActivationMethods) => void;
  trackAppLauncherUsed: (
    destination: string,
    type: QuickNavData['type']
  ) => void;
  trackAppLauncherFailure: (destination: string) => void;
};

/**
 * Centralization of analytics events for the search nav. The aim here is to minimize how many dependencies are imported to each component that are only used for analytics as well reduce extra noise in the component files for adding analytics metadata.
 */
export function useSearchNavAnalytics(): SearchNavAnalytics {
  const location = useLocation();

  const trackSearchNavOnScreen = useCallback(
    (activationMethod: ActivationMethods) => {
      searchNavOnScreen({
        activationMethod,
        location: location.pathname,
      });
    },
    [location.pathname]
  );

  const trackSearchNavUsed = useCallback(
    (destination: string) => {
      searchNavUsed({
        destination,
        location: location.pathname,
      });
    },
    [location.pathname]
  );

  const trackAppLauncherOnScreen = useCallback(
    (activationMethod: ActivationMethods) => {
      appLauncherOnScreen({
        activationMethod,
        location: location.pathname,
      });
    },
    [location.pathname]
  );

  const trackAppLauncherUsed = useCallback(
    (destination: string, type: QuickNavData['type']) => {
      appLauncherUsed({
        destination,
        location: location.pathname,
        type,
      });
    },
    [location.pathname]
  );

  const trackAppLauncherFailure = useCallback(
    (destination: string) => {
      appLauncherFailure({
        destination,
        location: location.pathname,
      });
    },
    [location.pathname]
  );

  return {
    trackSearchNavOnScreen,
    trackSearchNavUsed,
    trackAppLauncherOnScreen,
    trackAppLauncherUsed,
    trackAppLauncherFailure,
  };
}
