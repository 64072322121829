import {decodeURIComponentSafe} from '@wandb/weave/common/util/url';
import {Buffer} from 'buffer';

import {encodeURIComponentSafe} from '../urls/utils';

export type ReportNameAndID = {
  reportId?: string;
  reportName?: string;
  error?: string;
};

export const INVALID_REPORT_URL = 'Invalid report url';
export const INVALID_REPORT_ID = 'Invalid report id';

/**
 * Assumes id is a base64 encoded string
 * Example: `View:13` (base64 decoded), `VmlldzoxMw` (base64 encoded), VmlldzoxMw== (encoded with padding)
 */
export function makeNameAndID(id: string, name?: string): string {
  // Note we strip base64 = padding to make this pretty.
  // It's added back in parseNameAndID.
  id = id.replace(/=/g, '');
  if (name != null) {
    // Replace all non word characters with dashes, eliminate repeating dashes
    name = name.replace(/\W/g, '-').replace(/-+/g, '-');
  }
  return name != null ? `${encodeURIComponentSafe(name)}--${id}` : id;
}

export function parseReportURL(str: string): ReportNameAndID {
  try {
    //  Check if str is a url or not
    new URL(str);

    const nameAndID = str
      .split('?')[0] // remove querystring
      .replace(/\/$/, '') // remove trailing slash
      .split('/') // get last portion of URL
      .slice(-1)[0];
    return parseReportParams(nameAndID);
  } catch (_) {
    return {
      reportId: undefined,
      reportName: undefined,
      error: INVALID_REPORT_URL,
    };
  }
}

/**
 * SECURITY CHECK: validate that this URL is actually a link to a webpage - if not, omit it.
 * Rendering non- http/https links is a risk for XSS, even when they use target="_blank"
 * because users can still choose to right click and open in the same tab */
export const getSafeUrlWithoutXss = (url: string): string => {
  try {
    const urlForChecking = new URL(url);
    if (
      urlForChecking.protocol !== 'http:' &&
      urlForChecking.protocol !== 'https:'
    ) {
      console.error('Unsafe URL was attempted to be rendered: ' + url);
      // for now, just blanking out the URL to prevent the unsafe link from getting rendered,
      // not trying to make this a good experience, since users are very unlikely to see this
      return '';
    }
    return url;
  } catch (e) {
    // URL constructor throws on invalid URLs - in that case, we don't want to allow that as a link
    return '';
  }
};

/**
 * Assumes path has `<name>--<id>` where <id> is a base64 encoded string of `View:<some #>`
 * Assumes the id has padding stripped off
 */
export function parseReportParams(path: string): ReportNameAndID {
  let reportId = '';
  let reportName = '';

  if (!path || !path.includes('--')) {
    return {
      reportId: undefined,
      reportName: undefined,
      error: INVALID_REPORT_URL,
    };
  }

  const separatorPosition = path.lastIndexOf('--');
  if (separatorPosition !== -1) {
    reportName = decodeURIComponentSafe(path.slice(0, separatorPosition))
      .replace(/-/g, ' ')
      .trim();
    reportId = path.slice(separatorPosition + 2, path.length).trim();
  }

  reportId = addBase64Padding(reportId);

  if (base64IDToViewID(reportId) == null) {
    return {
      reportId: undefined,
      reportName: undefined,
      error: INVALID_REPORT_ID,
    };
  }

  return {reportId, reportName};
}

/**
 * We strip base64 `=` padding to make report IDs pretty.
 * We do this because a report's ID is included in the URL.
 * We have to add it back before using it for other purposes.
 * Otherwise, we'll run into issues because we're using invalid IDs.
 *
 * Examples:
 * 1. No padding needed for `View:1290312` (base64 decoded), `VmlldzoxMjkwMzEy` (base64 encoded)
 * 2. Add "==" padding to `View:13` (base64 decoded), `VmlldzoxMw` (base64 encoded)
 * 3. Add "=" padding to " `View:144` (base64 decoded), `VmlldzoxNDQ` (base64 encoded)
 */

export function addBase64Padding(s: string): string {
  const pad = 4 - (s.length % 4);
  if (pad !== 4) {
    for (let i = 0; i < pad; i++) {
      s += '=';
    }
  }
  return s;
}

export function base64IDToViewID(base64: string): number | null {
  const decoded = Buffer.from(base64, 'base64').toString();
  if (decoded.indexOf(':') === -1) {
    return null;
  }
  const [viewString, numericID] = decoded.split(':', 2);
  if (viewString !== 'View' || !numericID.match(/^-{0,1}\d+$/)) {
    return null;
  }
  return Number(numericID);
}

// this helper function assumes that url is /:entityName/:projectName/reports/:reportNameAndID format
export function replaceViewIDInURL(url: string, newViewId: string): string {
  const indexBeforeID = url.lastIndexOf('--');
  const urlName = url.slice(0, indexBeforeID);
  return urlName + '--' + newViewId;
}

export function getParentReportURL(
  url: string,
  parentViewId: string | undefined
): string {
  const childUrl = url.split('/edit')[0].split('#')[0];
  const {reportId} = parseReportURL(childUrl);

  if (!parentViewId || parentViewId.length === 0) {
    return childUrl;
  }

  // remove any padding from the view id
  const parentViewIdWithoutPad = parentViewId.replace(/=/g, '');
  if (reportId === parentViewIdWithoutPad) {
    return childUrl;
  }

  return replaceViewIDInURL(childUrl, parentViewIdWithoutPad);
}
