import React, {FC, memo, useEffect} from 'react';
import {Redirect} from 'react-router';

import * as urls from '../util/urls';

type AggressiveRedirectEnforcementProps = {
  analyticsLocation: string;
  entityName?: string;
  organizationName?: string;
};

const AggressiveRedirectEnforcementComp: FC<
  AggressiveRedirectEnforcementProps
> = ({entityName, organizationName, analyticsLocation}) => {
  useEffect(() => {
    window.analytics?.track('Aggressively Redirected', {
      location: analyticsLocation,
      entityName,
      organizationName,
    });
    // disabling the lint check since we only want to
    // send analytics once when this components first mounts
    // eslint-disable-next-line
  }, []);

  return (
    <Redirect to={urls.accountSettingsBillingTab(organizationName ?? '')} />
  );
};

export const AggressiveRedirectEnforcement = memo(
  AggressiveRedirectEnforcementComp
);
