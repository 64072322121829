import {createAction} from 'typesafe-actions';

import {PanelSelections} from '../../../util/selectionmanager';
import * as Types from './types';

export const set = createAction(
  '@view/filter/set',
  action => (ref: Types.Ref, filters: Types.Filter) => action({ref, filters})
);

export const selectionsToFilters = createAction(
  '@view/filter/selectionsToFilters',
  action => (ref: Types.Ref, selections: PanelSelections, axes?: string[]) =>
    action({ref, selections, axes})
);

export type ActionType = ReturnType<typeof set | typeof selectionsToFilters>;
