export const PANEL_BANK_CHARTS_NAME = 'Charts';
export const PANEL_BANK_TABLES_NAME = 'Tables';
export const PANEL_BANK_MEDIA_NAME = 'Media';
export const PANEL_BANK_SYSTEM_NAME = 'System';
export const PANEL_BANK_HIDDEN_SECTION_NAME = 'Hidden Panels';
export const PANEL_BANK_CUSTOM_VISUALIZATIONS_NAME = 'Custom Visualizations';
export const PANEL_BANK_CUSTOM_CHARTS_NAME = 'Custom Charts';

export const DEFAULT_X_AXIS = '_step';
export const DEFAULT_PANEL_SIZE = 250;
export const PANEL_BANK_PADDING = 16;
export const TABLE_SECTION_HEIGHT = 600;
