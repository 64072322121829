import {RunHistoryKeyInfo} from '../../../types/run';
import {Line} from '../../../util/plotHelpers/types';
import * as RunHelpers from '../../../util/runhelpers';
import {RunsLinePlotConfig, RunsLinePlotPanelInnerProps} from '../types';

export function isHistogramPlot(
  config: Pick<RunsLinePlotConfig, 'metrics'>,
  keyInfo?: RunHistoryKeyInfo
): boolean {
  // Return true if currently plotting a histogram

  if (
    keyInfo == null ||
    config.metrics == null ||
    config.metrics.length === 0
  ) {
    return false;
  }
  const keyTypes = RunHelpers.keyTypes(keyInfo.keys);
  return keyTypes[config.metrics[0]] === 'histogram';
}

export function isEmptyChart(
  config: Pick<RunsLinePlotConfig, 'metrics' | 'expressions'>
): boolean {
  const emptyMetrics = config.metrics == null || config.metrics.length === 0;
  const emptyExpressions =
    config.expressions == null || config.expressions.length === 0;
  return emptyMetrics && emptyExpressions;
}

export function isBarPlot(
  props: RunsLinePlotPanelInnerProps,
  zooming: boolean = false
): boolean {
  // When theres more than one line and all the visible lines are a single point
  // we show a bar plot.
  const {lines} = props;

  // this keeps a runs line plot from being converted to a bar plot when zooming
  // there is no way to zoom back out from a bar plot
  // TODO: change this behavior: https://weightsandbiases.slack.com/archives/CA4PBB2TF/p1708971196432519
  if (zooming) {
    return false;
  }

  const filteredLines = lines.filter(isValidLine);
  return (
    filteredLines.length > 1 && filteredLines.every(l => l.data.length === 1)
  );
}

export function isValidLine(l: Line): boolean {
  return !l.aux && l.data.length > 0;
}
