import {WBIcon} from '@wandb/ui';
import {
  errorDark,
  gray700,
  white,
} from '@wandb/weave/common/css/globals.styles';
import {Button as NewButton} from '@wandb/weave/components/Button';
import Color from 'color';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Modal} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import WBReactTable from './WBReactTable';

export const WBModalHeader = styled(Modal.Header)`
  &&& {
    border: none !important;
    padding: 0 !important;
    margin: 0 auto 24px;
    font-size: 20px !important;
    font-weight: 600 !important;
    vertical-align: center;
    display: flex;
    align-items: center;

    div {
      font-size: 16px;
      font-weight: 400;
      color: ${gray700};
      margin-top: 8px;
    }
  }
`;

export const WBModalContent = styled(Modal.Content)`
  &&& {
    border: none !important;
    padding: 0 !important;
    margin: 0 auto;
    font-size: 16px;
    p {
      margin-bottom: 24px;
    }
  }
`;

export const WBModalContentHeader = styled.p`
  &&&&& {
    margin-bottom: 0px;
    font-weight: 600;
  }
`;

export const WBModalContentSection = styled.div`
  &&& {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 8px;
    }
  }
`;

export const WBModalContentNoActions = styled(Modal.Content)`
  &&& {
    border: none !important;
    padding: 0 !important;
    margin: 24px auto 0px auto;
    font-size: 16px;
    p {
      margin-bottom: 24px;
    }
  }
`;

export const WBModalActions = styled(Modal.Actions)`
  &&& {
    border: none !important;
    padding: 0 !important;
    margin: 24px auto 0;
    background: ${white};
  }
`;

export const WBModalButton = styled(Button)`
  &&& {
    height: 40px;
    margin: 0 0 0 16px !important;
  }
`;

export const WBModalImageGallery = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 190px;

    img {
      width: 144px;
      height: 168px;
      margin-bottom: 16px;
    }

    div {
      font-size: 16px;
      font-weight: 400;
      color: ${gray700};
    }
  }
`;

export const WBModalInfoIcon = styled(WBIcon)`
  margin-left: 4px;
`;

export const WBModalTable = styled(WBReactTable)`
  height: 600px;
  overflow: scroll;
  margin-bottom: 32px;
`;

export const WBModal = styled(Modal)<{
  height?: string;
  maxWidth?: string;
  theme?: string;
  width?: string;
}>`
  &&& {
    padding: 32px;
    ${props => css`
      ${props.height && `height: ${props.height};`}
      ${props.width ? `width: ${props.width}` : 'max-width: 450px'};
      ${props.maxWidth ? `max-width: ${props.maxWidth}` : ''};
    `}

    ${props =>
      props.theme === 'danger' &&
      `
    ${WBModalHeader} {
      color: ${errorDark};
    }

    .ui.primary.button:not(.disabled) {
      background: ${errorDark};
      border: 1px solid ${errorDark};
      &:hover {
        background: ${Color(errorDark).darken(0.1).toString()};
      }
    }
    `}
  }
`;

export const WBModalIcon = styled(WBIcon)`
  font-size: 28px;
  margin-right: 8px;
`;

export const ModalButton = styled(NewButton)``;

export const ImportModalSection = styled.div`
  margin-bottom: 6px;
  display: flex;
  align-items: center;

  font-size: 16px;
  gap: 8px;

  > div {
    word-break: break-all;
  }
`;

export const ImportModalSectionOption = styled(ImportModalSection)`
  margin-left: 28px;
`;

export const CheckboxModalContentHeader = styled(ImportModalSection)`
  &&&&& {
    font-weight: 600;
  }
`;

export const ImportModalActions = styled(WBModalActions)`
  &&& {
    text-align: left;
    display: flex;
    gap: 8px;
  }
`;
