import {isMac} from '@wandb/weave/common/util/browser';

export const HOTKEYS = {
  appSearch: '⌘+K', // stealing the quick nav pattern from the Slack App
  toggleRunsSidebar: '⌘+J',
  undoWorkspaceAction: '⌘+Z',
  redoWorkspaceAction: '⌘+⇧+Z',
};

// for non-mac OS
export const HOTKEYS_FALLBACK = {
  appSearch: 'ctrl+K',
  toggleRunsSidebar: 'ctrl+J',
  undoWorkspaceAction: 'ctrl+z',
  redoWorkspaceAction: 'ctrl+y',
};

export const hotkeyString = (hotkey: keyof typeof HOTKEYS) => {
  if (!isMac && hotkey in HOTKEYS_FALLBACK) {
    return HOTKEYS_FALLBACK[hotkey];
  }
  return HOTKEYS[hotkey];
};

export const hotkeyPressed = (
  e: KeyboardEvent,
  hotkey: keyof typeof HOTKEYS
) => {
  const combo = hotkeyString(hotkey);
  const parts = combo.split('+');
  for (const part of parts) {
    switch (part) {
      case 'command':
      case '⌘':
        if (!e.metaKey) {
          return false;
        }
        break;
      case 'ctrl':
        if (!e.ctrlKey) {
          return false;
        }
        break;
      case 'alt':
      case 'option':
      case '⌥':
        if (!e.altKey) {
          return false;
        }
        break;
      case '⇧':
      case 'shift':
        if (!e.shiftKey) {
          return false;
        }
        break;
      default:
        if (e.key !== part.toLowerCase()) {
          return false;
        }
        break;
    }
  }
  return true;
};
