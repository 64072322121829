import {createAction} from 'typesafe-actions';

import * as Sweeps from '../../util/sweeps';

export const clearCreateSweepArgs = createAction(
  '@sweeps/clearCreateSweepArgs',
  action => {
    return () => action();
  }
);

export const setCreateSweepArgs = createAction(
  '@sweeps/setCreateSweepArgs',
  action => {
    return (args: Sweeps.CreateSweepArgs) => action({createSweepArgs: args});
  }
);
