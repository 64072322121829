import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC, Fragment} from 'react';

import {createDismissibleBanner} from '../../../pages/Billing/AccountSettings/WarningBanner/DismissibleBanner';
import {Analytics} from '../../../services/analytics';
import {bytesInGB} from '../../../util/storage';
import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  HasStorageSubscriptionSection,
  StorageUsageBannerContent,
  useCurrentBannerStates,
} from '..';
export type ReachingResourceLimitWarningBannerProps = {
  organizationId: string;
  organizationName: string;
  isOrgAdmin: boolean;
  isBillingAdmin: boolean;
  billingAdminEmail?: string;
  hasStorageSubscription: boolean;
  orgStorageBytes: number;
  orgStorageBytesLimitGB: number;
};

export const ReachingResourceLimitWarningBannerGlobalList: FC = () => {
  const currentBannerStates = useCurrentBannerStates();

  return (
    <Tailwind>
      <div className="space-y-8">
        {currentBannerStates.map(state => {
          const reachingStorageLimitBannerProps =
            state.storageAndEnforcementBannerFlags
              .reachingStorageLimitWarningBannerProps;

          return (
            <Fragment key={state.account.name + state.account.accountType}>
              {reachingStorageLimitBannerProps && (
                <ReachingResourceLimitWarningBanner
                  {...reachingStorageLimitBannerProps}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </Tailwind>
  );
};

export const ReachingResourceLimitWarningBanner: FC<ReachingResourceLimitWarningBannerProps> =
  createDismissibleBanner<ReachingResourceLimitWarningBannerProps>({
    id: ({organizationId}) => organizationId + ':storage-90',
    Component: ({
      onDismiss,
      organizationName,
      organizationId,
      isBillingAdmin,
      isOrgAdmin,
      billingAdminEmail,
      hasStorageSubscription,
      orgStorageBytes,
      orgStorageBytesLimitGB,
    }) => {
      const storageFractionLeft =
        1 - orgStorageBytes / (orgStorageBytesLimitGB * bytesInGB);
      const storageFractionLeftText = storageFractionLeft.toLocaleString(
        'us-en',
        {
          style: 'percent',
          maximumFractionDigits: 0,
        }
      );
      const statusMsg =
        'Once you exceed your storage limit, access to your data will be restricted';
      return (
        <Tailwind>
          <AccountStatusBanner
            eventData={{
              location: `exceeded 90% for storage alert banner`,
              organizationId,
              resource: 'storage',
            }}>
            <AccountStatusBannerFlexWrapper>
              <div>
                <Icon name="info" />
              </div>
              <AccountStatusBannerTextWrapper>
                <span>
                  <AccountStatusBannerBoldText>
                    You have {storageFractionLeftText} of your{' '}
                    {orgStorageBytesLimitGB} GB free storage remaining.
                  </AccountStatusBannerBoldText>{' '}
                  <span>
                    {hasStorageSubscription ? (
                      <HasStorageSubscriptionSection
                        organizationName={organizationName}
                      />
                    ) : (
                      <StorageUsageBannerContent
                        organizationName={organizationName}
                        billingAdminEmail={billingAdminEmail}
                        isBillingAdmin={isBillingAdmin}
                        isOrgAdmin={isOrgAdmin}
                        statusMsg={statusMsg}
                      />
                    )}
                  </span>
                </span>
              </AccountStatusBannerTextWrapper>
            </AccountStatusBannerFlexWrapper>
            {hasStorageSubscription && (
              <button
                className="flex items-center border-none bg-transparent font-semibold text-moon-600 hover:cursor-pointer hover:text-moon-900"
                onClick={() => {
                  Analytics.track('Dismiss button clicked', {
                    location: `exceeded 90% for artifact alert banner`,
                    organizationId,
                  });
                  onDismiss();
                }}>
                Dismiss
              </button>
            )}
          </AccountStatusBanner>
        </Tailwind>
      );
    },
  });
