import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {WBSelect} from '@wandb/weave/common/components/WBSelect';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import * as QueryEditorDeleteButtonStyles from './QueryEditorDeleteButton.styles';

export const SelectWrapper = styled.span`
  display: inline;
  margin-left: -24px;
  padding-left: 24px;
  & > ${QueryEditorDeleteButtonStyles.Wrapper} {
    position: absolute;
    left: -26px;
    top: 1px;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }
  &:hover {
    & > ${QueryEditorDeleteButtonStyles.Wrapper} {
      /* transform: translateY(16px); */
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const Select = styled(WBSelect)<{disabled?: boolean}>`
  display: inline-flex;
  width: auto;
  max-width: none;
  border: none !important;
  background: ${props => (props.disabled ? 'none' : props.theme.clickable)};
  padding: 0 0 0 4px;
  margin-left: 0;
  margin-right: 0;
  line-height: 20px;
  &:hover {
    background: ${props => (props.disabled ? 'none' : props.theme.focused)};
  }
  .wbic-ic-next {
    color: ${props => props.theme.text} !important;
  }
  ${props =>
    props.disabled &&
    css`
      padding: 0;
      cursor: text;
      .wbic-ic-next {
        display: none;
      }
    `}
`;

export const FieldOption = styled.div<{hovered?: boolean}>`
  display: flex;
  align-items: center;
  background: ${props =>
    props.hovered ? props.theme.popupHover : props.theme.clickable};
  color: ${props => props.theme.text};
  padding: 2px 24px 2px 12px;
  font-family: Inconsolata;
  cursor: pointer;
`;

export const FieldOptionIcon = styled(LegacyWBIcon)`
  margin-right: 8px !important;
  font-size: 16px;
  flex-shrink: 0;
`;
