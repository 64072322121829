import {Icon} from '@wandb/weave/components/Icon';
import React from 'react';
import {useLocation} from 'react-router';

import {
  Banner,
  BannerBoldText,
  BannerFlexWrapper,
  BannerTextWrapper,
} from '../../../../components/Banner';
import {useViewer} from '../../../../state/viewer/hooks';
import * as Urls from '../../../../util/urls';
import {useIsLockablePersonalEntityLocation} from '../../../../util/userLockdown';
import * as S from './PersonalEntityDisabledBanner.styles';

export const PersonalEntityDisabledBanner = () => {
  const location = useLocation();
  const viewer = useViewer();
  const isLockableLocation = useIsLockablePersonalEntityLocation(
    location.pathname
  );

  if (!isLockableLocation || !viewer || !viewer.personalEntityDisabled) {
    return null;
  }

  const hasTeams = viewer.teams?.edges?.some(edge => edge.node?.isTeam);

  return (
    <Banner
      variant={'warning'}
      eventData={{
        location: 'personal entity disabled banner',
      }}>
      <BannerFlexWrapper>
        <S.IconWrapper>
          <Icon name="info" />
        </S.IconWrapper>
        <BannerTextWrapper>
          <span>
            <BannerBoldText>
              Your admin has disabled personal entities.
            </BannerBoldText>{' '}
            You will no longer be able to log new runs to your personal entity.
          </span>
        </BannerTextWrapper>
      </BannerFlexWrapper>
      {hasTeams ? (
        <S.CallToActionButton
          href={Urls.settings()}
          onClick={() => {
            // Insert analytics here when agreed on
          }}>
          Change default team
        </S.CallToActionButton>
      ) : (
        <S.CallToActionButton
          href={Urls.createTeamPage()}
          onClick={() => {
            // Insert analytics here when agreed on
          }}>
          Create a team
        </S.CallToActionButton>
      )}
    </Banner>
  );
};
