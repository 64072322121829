import React, {useCallback} from 'react';
import {useHistory} from 'react-router';
import {toast} from 'react-toastify';

import {useCreateRoleMutation} from '../../../generated/graphql';
import {extractErrorMessageFromApolloError} from '../../../util/errors';
import {orgRoles} from '../../../util/urls';
import {CustomRoleForm} from './CustomRoleForm';
import {useOrgRolesContext} from './OrgRolesContext';
import {CustomRole} from './useOrgRolesContextResult';

export const OrgRolesNew: React.FC = () => {
  const history = useHistory();
  const {refetch, data} = useOrgRolesContext();

  const [createRoleMutation] = useCreateRoleMutation();
  const createRole = useCallback(
    async (role: Omit<CustomRole, 'id'>) => {
      if (!data.orgId) {
        return;
      }
      try {
        await createRoleMutation({
          variables: {
            name: role.name,
            description: role.description,
            permissions: role.permissions?.map(p => p.name),
            inheritedFrom: role.inheritedFrom,
            organizationID: data.orgId,
          },
        });
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err);
        toast(`Error creating role: ${errMsg}`);
      }
      refetch();
      history.push(orgRoles(data.orgName));
      toast('Successfully created role');
    },
    [createRoleMutation, data.orgId, history, refetch, data.orgName]
  );

  return <CustomRoleForm handleSubmit={createRole} />;
};
