import {ID} from '@wandb/weave/common/util/id';
import {ValueOf} from '@wandb/weave/common/util/types';

import {View, ViewUser} from '../views/types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  getIsPersonalWorkspace,
  getNamedWorkspaceId,
  getPersonalNwId,
  getSharedNwId,
  NamedWorkspaceId,
  NW_DEFAULT_ID,
  WorkspaceTypes,
  WsRelatedId,
} from './types';

export const LegacyDbNameValue: Record<string, string> = {
  // note that values here are used as the value in the views table named field,
  // so you should not change existing ones.
  user: 'workspace',
  default: 'default',
} as const;

/**
 * Default workspaces will have the name field populated with 'default' or 'default-<sweep-related-id>'.
 * Otherwise, it's known to be a user workspace and view.user should give us
 * information on who's user workspace it belongs to.
 */
const LEGACY_WORKSPACE_NAME = 'workspace';
const LEGACY_DEFAULT_NAME = 'default';

/**
 * This is used to determine what type of workspace it is
 * based on the view table's name field
 */
export function getWorkspaceType(
  viewName: string
): ValueOf<typeof WorkspaceTypes> {
  if (viewName.startsWith(LegacyDbNameValue[WorkspaceTypes.default])) {
    return WorkspaceTypes.default;
  }
  if (viewName.startsWith(LegacyDbNameValue[WorkspaceTypes.user])) {
    return WorkspaceTypes.user;
  }
  if (viewName.startsWith(NAME_PREFIX)) {
    return WorkspaceTypes.named;
  }
  if (viewName.startsWith(NAME_PREFIX_DELETED)) {
    return WorkspaceTypes.namedDeleted;
  }
  console.error('unknown workspace name', {viewName});
  return WorkspaceTypes.unknown;
}

export const createViewNameForLegacyWorkspace = (
  workspaceType: keyof typeof LegacyDbNameValue,
  relatedId: WsRelatedId
) => {
  const workspaceNameSuffix = relatedId != null ? `-${relatedId}` : '';
  return LegacyDbNameValue[workspaceType] + workspaceNameSuffix;
};

const NAME_PREFIX = 'nw';
const NAME_PREFIX_DELETED = 'xnw'; // x in front so it won't prefix match with NAME_PREFIX
const NAME_SEPARATOR = '-';

const SHARED_WORKSPACE_INDICATOR = 'v'; // Display name is "Shared view", so we use "v" as indicator
const PERSONAL_WORKSPACE_INDICATOR = 'w'; // Display name is "Personal workspace", so we use "w" as indicator

// nwType and nwIndicator use "as const" because we need to use the array values
// to verify a view.name and nwId are valid
const NW_TYPE = ['shared workspace', 'personal workspace'] as const;
export type NwType = (typeof NW_TYPE)[number];

const NW_INDICATOR = [
  SHARED_WORKSPACE_INDICATOR,
  PERSONAL_WORKSPACE_INDICATOR,
] as const;
type NwIndicator = (typeof NW_INDICATOR)[number];

const NW_TYPE_FROM_VIEW_NAME_INDICATOR: Record<NwIndicator, NwType> = {
  v: 'shared workspace',
  w: 'personal workspace',
};

const VIEW_NAME_INDICATOR_FROM_NW_TYPE: Record<NwType, NwIndicator> = {
  'shared workspace': 'v',
  'personal workspace': 'w',
};

// In the views table, we don't have relatedId stored anywhere, so need to include that value
// We also use the 'p'/'d' indicators to delineate published version since there's not
// a column to track that.
// We could potentially consider adding an explicit column for that if we see a need.
export const viewNameColumnForNamedWorkspace = (
  nwId: NamedWorkspaceId,
  isDeleted?: 'deleted'
): string => {
  throwIfUnsafeNwId(nwId);
  // Views table has a unique key based on project_id, user_id, name, and type. (search for uniq_view__project_user_name)
  // Users can discard multiple drafts for a published workspace, so we need to gurantee the soft deleted view name is unique.
  const prefix =
    isDeleted === 'deleted' ? NAME_PREFIX_DELETED + '-' + ID(12) : NAME_PREFIX;

  const relatedIdSuffix =
    nwId.relatedId != null ? `${nwId.relatedId}` : undefined;

  return [
    prefix,
    nwId.id,
    VIEW_NAME_INDICATOR_FROM_NW_TYPE[nwId.nwType],
    relatedIdSuffix,
  ]
    .filter(x => x != null)
    .join(NAME_SEPARATOR);
};

export const getNamedWorkspaceIdForView = (
  viewRowName: string,
  viewRowUser: ViewUser
): NamedWorkspaceId => {
  return viewIsLegacyWorkspace(viewRowName)
    ? getNamedWorkspaceIdForLegacyView(viewRowName, viewRowUser.username)
    : getNamedWorkspaceIdForNWView(viewRowName, viewRowUser);
};

export const getNamedWorkspaceIdForNWView = (
  viewRowName: string,
  viewRowUser: ViewUser
): NamedWorkspaceId => {
  // an example viewName here will look like:
  // nw-{id}-{nwType}
  // OR
  // nw-{id}-{nwType}-{relId}
  // OR
  // xnw-{random id}-{id}-{relId}
  const s = viewRowName.split(NAME_SEPARATOR);

  const prefix = s[0]; // nw or xnw
  if (prefix === NAME_PREFIX_DELETED) {
    return getNwIdForDeletedView(viewRowName, viewRowUser);
  }

  const id = s[1]; // this is the id used in the url which we generate with ID() or viewer.username

  const indicator = s[2];
  const nwType: NwType =
    NW_TYPE_FROM_VIEW_NAME_INDICATOR[indicator as NwIndicator];
  if (indicator && !nwType) {
    throw new Error(`invalid view.name for named workspace: ${viewRowName}`);
  }

  const relatedId = s[3]; /* relies on out of bound index returning undefined */

  if (getIsPersonalWorkspace(id)) {
    return getPersonalNwId(viewRowUser.username, relatedId); // get the safe username
  }

  return getSharedNwId(id, relatedId);
};

// Convert legacy information to a nice NwId to work with
// because we use the nwId.id value to update the url
export const getNamedWorkspaceIdForLegacyView = (
  viewRowName: string,
  viewRowUsername: string
): NamedWorkspaceId => {
  // an example viewName here will look like:
  // workspace
  // OR
  // workspace-{id} (for sweeps)
  const s = viewRowName.split(NAME_SEPARATOR);
  if (s.length > 2) {
    throw new Error(`invalid view.name for named workspace: ${viewRowName}`);
  }
  // s[0] = workspace or default
  const relatedId = s[1]; /* relies on out of bound index returning undefined */

  const isLegacyDefault = viewIsDefault(viewRowName);
  if (isLegacyDefault) {
    return getSharedNwId(NW_DEFAULT_ID, relatedId);
  }
  return getPersonalNwId(viewRowUsername, relatedId);
};

export const getNwIdForDeletedView = (
  viewRowName: string,
  viewRowUser: ViewUser
) => {
  const s = viewRowName.split(NAME_SEPARATOR);

  // s[0] = xnw
  // s[1] = random Id() we add to view name to make delete views names unique
  const id = s[2]; // this is the id used in the url which we generate with ID() or viewer.username

  const indicator = s[3];
  const nwType: NwType =
    NW_TYPE_FROM_VIEW_NAME_INDICATOR[indicator as NwIndicator];

  const relatedId = s[4]; /* relies on out of bound index returning undefined */

  return getNamedWorkspaceId({
    id,
    nwType,
    username: viewRowUser.username,
    relatedId,
  });
};

export const viewIsNamedWorkspace = (view: View) =>
  view.name.startsWith(NAME_PREFIX);

// Regular default workspace: name = default
// Sweeps default workspace: name = default-<id>
export const viewIsDefault = (viewName: string) =>
  viewName.startsWith(LEGACY_DEFAULT_NAME);

export const viewIsLegacyWorkspace = (viewName: string) =>
  !viewName.startsWith(NAME_PREFIX) &&
  !viewName.startsWith(NAME_PREFIX_DELETED) &&
  (viewName.startsWith(LEGACY_WORKSPACE_NAME) ||
    viewName.startsWith(LEGACY_DEFAULT_NAME));

export const viewIsDeletedNW = (viewName: string) =>
  viewName.startsWith(NAME_PREFIX_DELETED);

// this is necessary because we use '-' as the delimiter between
// parts of the view.name column, and we also allow '-' in the user's usernames
export const getSafeNwPart = (str: string) =>
  str.replaceAll(NAME_SEPARATOR, '');

export const throwIfUnsafeNwId = (nwId: NamedWorkspaceId) => {
  if (nwId.relatedId && nwId.relatedId.includes(NAME_SEPARATOR)) {
    throw new Error(`invalid relatedId with '-': was '${nwId.relatedId}'`);
  }

  if (viewIsLegacyWorkspace(nwId.id)) {
    return;
  }

  if (nwId.id.includes(NAME_SEPARATOR)) {
    throw new Error(`throwIfUnsafeNwId - id with '-': '${nwId.id}'`);
  }
};
