import {notEmpty} from '@wandb/weave/common/util/obj';
import {useMemo} from 'react';

import {
  RunSelectorCsvExportRunsQueryVariables,
  useRunSelectorCsvExportRunsQuery,
} from '../../../generated/graphql';
import {propagateErrorsContext} from '../../../util/errors';
import * as Filters from '../../../util/filters';
import * as Run from '../../../util/runs';
import {RunSelectorRunsPartialVars} from '../types';

const EXPORTED_RUNS_LIMIT = 10000;

export const useCsvExportRuns = (
  topLevelQueryVariables: RunSelectorRunsPartialVars
) => {
  const queryVariables = useMemo(() => {
    const {filters, grouping, selections, ...passThroughVars} =
      topLevelQueryVariables;
    const filtersWithSelections =
      selections != null ? Filters.And([filters, selections]) : filters;
    const groupKeys = grouping?.map(Run.keyToServerPath) ?? [];

    // these the variables that are actually used
    const variables: RunSelectorCsvExportRunsQueryVariables = {
      ...passThroughVars,
      filters: JSON.stringify(Filters.toMongo(filtersWithSelections)),
      groupKeys,
      limit: EXPORTED_RUNS_LIMIT,
    };
    return variables;
  }, [topLevelQueryVariables]);

  const {loading, data} = useRunSelectorCsvExportRunsQuery({
    context: propagateErrorsContext(),
    variables: queryVariables,
    fetchPolicy: 'no-cache',
  });

  const runs = useMemo(
    () =>
      data?.project?.runs?.edges
        ?.map(({node}) => Run.fromJson(node))
        .filter(notEmpty) ?? [],
    [data?.project?.runs?.edges]
  );

  return {
    isLoading: loading,
    runs,
  };
};
