import {Maybe, UserAccountType} from '../generated/graphql';

const serviceAccountTypes: string[] = [
  UserAccountType.Service,
  UserAccountType.OrgService,
];

export const checkIsServiceAccount = (
  accountType?: Maybe<UserAccountType> | string
): boolean => !!accountType && serviceAccountTypes.includes(accountType);

export const checkIsOrgServiceAccount = (
  accountType?: Maybe<UserAccountType> | string
): boolean => !!accountType && accountType === UserAccountType.OrgService;

export const checkIsTeamServiceAccount = (
  accountType?: Maybe<UserAccountType> | string
): boolean => !!accountType && accountType === UserAccountType.Service;
