import {useOnMouseDownOutside} from '@wandb/weave/common/util/dom';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {useRef} from 'react';
import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';

import * as S from './PageWithSidebar.styles';

type FloatingSidebarProps = {
  children: React.ReactNode;
  isOpen: boolean;
  triggerRef: React.RefObject<HTMLButtonElement>;
  close: () => void;
  shouldAnimate: boolean;
  isCreatingTeamOrProject: boolean;
};

export const FloatingSidebar = ({
  children,
  isOpen,
  triggerRef,
  close,
  isCreatingTeamOrProject,
  shouldAnimate = true,
}: FloatingSidebarProps) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  useOnMouseDownOutside([triggerRef.current, sidebarRef.current], () => {
    if (isOpen && !isCreatingTeamOrProject) {
      close();
    }
  });
  return createPortal(
    <Tailwind>
      <CSSTransition
        nodeRef={sidebarRef}
        in={isOpen}
        timeout={shouldAnimate ? 200 : 0}
        classNames={{
          enter: '-translate-x-30 opacity-0',
          enterActive:
            '!translate-x-0 !opacity-100 transition-all duration-200',
          exit: 'translate-x-0 opacity-100',
          exitActive: '!-translate-x-30 !opacity-0 transition-all duration-200',
        }}
        unmountOnExit>
        <S.FloatingSidebar
          ref={sidebarRef}
          bodyScrollHeight={document.body.scrollHeight}>
          {children}
        </S.FloatingSidebar>
      </CSSTransition>
    </Tailwind>,
    document.body
  );
};
