import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Popup} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import * as S from './LabeledOption.styles';

interface LabeledOptionProps {
  label: string;
  option: React.ReactNode;
  helpText?: string;
  docUrl?: string;
}

const LABEL_WIDTH = '100px';

const StyledLabelOption = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 4px 0;

  & + .accordion.ui.advanced-options {
    padding-left: 100px;
  }
`;
const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 600;
  width: ${LABEL_WIDTH};
  padding-right: 12px;
`;
const StyledOption = styled.div`
  flex-grow: 1;
  max-width: calc(100% - ${LABEL_WIDTH});

  .number-input__container {
    margin-right: 0px;
  }

  .number-input__input {
    margin-right: 0px;
  }

  .smoothing-type-trigger {
    margin-left: 10px;
  }
`;

export const LabeledOptionBlock: React.FC<LabeledOptionProps> = props => {
  /*
Doesn't really work to have link in a popup, but would be nice to give users a way to 
quickly get to the docs.

  const helpWithLink =
  props.docUrl != null ? (
    <span>
      {props.helpText}
      <TargetBlank href={props.docUrl}>
        Learn More
      </TargetBlank>
    </span>
  ) : (
    <span>{props.helpText}</span>
  );
  */
  const optionLabel = (
    <S.OptionLabel className="option-label">{props.label}</S.OptionLabel>
  );

  const popupLabel =
    props.helpText != null ? (
      <Popup
        inverted
        size="mini"
        trigger={optionLabel}
        content={props.helpText}
      />
    ) : (
      optionLabel
    );

  return (
    <StyledLabelOption data-test="styled-label-option">
      <StyledLabel data-test="styled-label">{popupLabel}</StyledLabel>
      <StyledOption data-test="styled-option">
        <S.Option>{props.option}</S.Option>
      </StyledOption>
    </StyledLabelOption>
  );
};

export default LabeledOptionBlock;
