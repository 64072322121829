import * as Track from '../../services/analytics';
import {
  assertIsDateOp,
  assertIsValueFilter,
  getDefaultAbsoluteDateValue,
  getDefaultRelativeDateValue,
  getFilterValueType,
  isMultiOp,
  isMultiValue,
  ValueType,
} from '../../util/filters';
import {WITHINSECONDS_OP_NAME} from '../../util/filtersDefaults';
import {
  FilterKey,
  IndividualFilter,
  IndividualOp,
  ValueFilter,
} from '../../util/filterTypes';

const handleDateFilterValueTypeChange = <K extends FilterKey>(
  filter: IndividualFilter<K>,
  newFilterOp: IndividualOp,
  now?: Date
): ValueFilter<K> => {
  filter = assertIsValueFilter(filter);
  newFilterOp = assertIsDateOp(newFilterOp);
  const oldOpIsWithinSeconds = filter.op === WITHINSECONDS_OP_NAME;
  const newOpIsWithinSeconds = newFilterOp === WITHINSECONDS_OP_NAME;

  if (oldOpIsWithinSeconds !== newOpIsWithinSeconds) {
    const newValue = newOpIsWithinSeconds
      ? getDefaultRelativeDateValue()
      : getDefaultAbsoluteDateValue(now);
    if (newOpIsWithinSeconds) {
      Track.withinLastFilterAdded();
    } else {
      Track.withinLastFilterRemoved();
    }
    return {...filter, op: newFilterOp, value: newValue};
  } else {
    return {...filter, op: newFilterOp};
  }
};

export const handleFilterOpChange = (
  filter: IndividualFilter,
  newFilterOp: IndividualOp,
  now?: Date
): IndividualFilter => {
  if (
    getFilterValueType(filter) === ValueType.Date ||
    getFilterValueType(filter) === ValueType.Duration
  ) {
    return handleDateFilterValueTypeChange(filter, newFilterOp, now);
  } else if (isMultiValue(filter)) {
    if (!isMultiOp(newFilterOp)) {
      let val = null;
      if (filter.value.length > 0) {
        val = filter.value[0];
      }
      return {
        key: filter.key,
        op: newFilterOp,
        value: val,
        disabled: filter.disabled,
      };
    } else {
      return {...filter, op: newFilterOp};
    }
  } else {
    if (isMultiOp(newFilterOp)) {
      return {
        key: filter.key,
        op: newFilterOp,
        disabled: filter.disabled,
        value:
          filter.value != null && filter.value !== '*' ? [filter.value] : [],
      };
    } else {
      return {...filter, op: newFilterOp};
    }
  }
};
