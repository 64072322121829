import {makeSafeURIFunction} from '@wandb/weave/common/util/url';
import _ from 'lodash';
import * as QueryString from 'query-string';

export const encodeURISafe = makeSafeURIFunction(encodeURI);
export const decodeURISafe = makeSafeURIFunction(decodeURI);
export const encodeURIComponentSafe = makeSafeURIFunction(encodeURIComponent);

export function encodeURIIfNotYetEncoded(uri: string): string {
  const decoded = decodeURISafe(uri);

  const isAlreadyEncoded = uri !== decoded;
  if (isAlreadyEncoded) {
    return uri;
  }

  return encodeURISafe(uri);
}

export function modifyQueryString(
  // typescript does not get along with the ParsedQuery type,
  // so we use Record<string, any> as a substitute for that type. It
  // should be correct in all situations that we are currently using.
  modFn: (orig: Record<string, any>) => Record<string, any>
) {
  const parsed = QueryString.parse(window.location.search);
  const modded = modFn(parsed);
  // Safari throws errors if you update this too frequently. We *shouldn't* be doing that,
  // but this prevents the hard exception being thrown and crashing the app.
  if (_.isEqual(parsed, modded)) {
    return;
  }
  window.history.replaceState(
    null,
    document.title,
    window.location.pathname + '?' + QueryString.stringify(modded)
  );
}

export function removeHashFromURL(url: string): string {
  return url.split('#')[0];
}

export function urlSafeSlug(slug: string) {
  // Legacy urls have encoded components that google doesn't like, this makes
  // old urls use the same logic as makeNameAndID
  const parts = slug.split('--');
  return (
    parts[0].replace(/%[A-Z0-9]{2}/g, '-').replace(/-+/g, '-') + '--' + parts[1]
  );
}

export function queryStringParamExists(param: string): boolean {
  const qs = QueryString.parse(window.location.search);
  return param in qs;
}

export function getQueryStringParamValue(
  param: string
): string | null | undefined {
  const qs = QueryString.parse(window.location.search);
  const res = qs[param];
  return Array.isArray(res) ? res[0] : res;
}

export function stripOriginFromURL(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return url;
  }
  const {pathname, search, hash} = new URL(url);
  return pathname + search + hash;
}

export function canonicalize(url: string): string {
  let canonical = url.split('?')[0];
  if (canonical.endsWith('/')) {
    canonical = canonical.substring(0, canonical.length - 1);
  }
  return canonical;
}
