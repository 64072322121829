import EditableField from '@wandb/weave/common/components/EditableField';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {
  InspectorPropertyLabel,
  InspectorPropertyWrapper,
} from '../Inspector.styles';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {PropertyEditorComponent} from './property-editors';

const MinimalEditableField = styled(EditableField)`
  margin: 0;
`;

const StringPropertyEditor: PropertyEditorComponent<'string'> = props => {
  const displayedVal =
    props.values.length === 1 && props.values[0] != null ? props.values[0] : '';
  return (
    <InspectorPropertyWrapper>
      <InspectorPropertyLabel>{props.displayName}</InspectorPropertyLabel>
      <MinimalEditableField
        placeholder={props.values.length > 1 ? 'Mixed' : 'Auto'}
        value={displayedVal}
        save={value => {
          props.save(value);
        }}></MinimalEditableField>
      {/* <Input value={props.value} onChange={(e, {value}) => {}}></Input> */}
    </InspectorPropertyWrapper>
  );
};

export default StringPropertyEditor;
