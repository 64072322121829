import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Dropdown, Input, Popup, Table} from 'semantic-ui-react';

import {
  Alert,
  AlertSubscription,
  EntityQuery,
  SlackIntegration as Slack,
  StoppedRunCondition,
  useCreateEmailSubscriptionMutation,
  useCreateFinishedRunAlertMutation,
  useCreateScriptableRunAlertMutation,
  useCreateSlackChannelSubscriptionMutation,
  useCreateStoppedRunAlertMutation,
  useDeleteAlertSubscriptionMutation,
  useUpdateStoppedRunAlertMutation,
} from '../generated/graphql';
import {TeamSettingsPageEntity} from '../graphql/teamQuery';
import {Entity} from '../types/graphql';
import * as S from './Alerts.styles';
import {isSlackIntegration, toSlackIntegration} from './SlackIntegration';

const alertConditionTypeToAnalyticsName = {
  FinishedRunCondition: 'finished',
  StoppedRunCondition: 'crashed',
  ScriptableRunCondition: 'custom',
};

export type AlertSubscriptionType =
  | 'EmailSubscription'
  | 'SlackChannelSubscription';

export interface AlertsProps {
  entity: Entity | TeamSettingsPageEntity | EntityQuery['entity'];
  entityRefetch: any;
  subscriptionTypes: AlertSubscriptionType[];
}

export type Unit = 'minutes' | 'hours' | 'days';

export const timeUnits = [
  {key: 'minutes', text: 'minutes', value: 'minutes'},
  {key: 'hours', text: 'hours', value: 'hours'},
  {key: 'days', text: 'days', value: 'days'},
];

export function toMilliseconds(duration: number, unit: Unit): number {
  if (unit === 'minutes') {
    return duration * (1000 * 60);
  } else if (unit === 'hours') {
    return duration * (1000 * 60 * 60);
  } else if (unit === 'days') {
    return duration * (1000 * 60 * 60 * 24);
  }
  throw new Error(`invalid unit ${unit}`);
}

export function fromMilliseconds(
  milliseconds: number | undefined,
  unit: Unit
): number | undefined {
  if (milliseconds === undefined) {
    return undefined;
  } else if (unit === 'minutes') {
    return milliseconds / (1000 * 60);
  } else if (unit === 'hours') {
    return milliseconds / (1000 * 60 * 60);
  } else if (unit === 'days') {
    return milliseconds / (1000 * 60 * 60 * 24);
  }
  throw new Error(`invalid unit ${unit}`);
}

export function defaultUnit(milliseconds: number): Unit {
  if (milliseconds >= 1000 * 60 * 60 * 24) {
    return 'days';
  } else if (milliseconds >= 1000 * 60 * 60) {
    return 'hours';
  } else {
    return 'minutes';
  }
}

export function defaultScriptDurationMilliseconds(
  entity: AlertsProps['entity']
): number | undefined {
  // https://github.com/microsoft/TypeScript/issues/44373
  const stoppedRunAlert = ((entity?.defaultAlerts ?? []) as any[]).find(
    alert => (alert.condition as any).__typename === 'StoppedRunCondition'
  );

  if (stoppedRunAlert !== undefined) {
    return (stoppedRunAlert.condition as StoppedRunCondition)
      .minimumRunDuration;
  }

  return undefined;
}

const Alerts = (props: AlertsProps) => {
  const {entity, entityRefetch, subscriptionTypes} = props;
  const defaultDurationMillis = defaultScriptDurationMilliseconds(entity);
  const [durationUnit, setDurationUnit] = useState<
    'minutes' | 'hours' | 'days'
  >(
    defaultDurationMillis !== undefined
      ? defaultUnit(defaultDurationMillis)
      : 'minutes'
  );
  const [scriptDurationMilliseconds, setScriptDurationMilliseconds] = useState<
    number | undefined
  >(defaultDurationMillis);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const [createFinishedRunAlert] = useCreateFinishedRunAlertMutation();
  const [createStoppedRunAlert] = useCreateStoppedRunAlertMutation();
  const [updateStoppedRunAlert] = useUpdateStoppedRunAlertMutation();
  const [createScriptableRunAlert] = useCreateScriptableRunAlertMutation();

  const defaultAlerts = (entity?.defaultAlerts ?? []) as any[];
  const finishedRunAlert = defaultAlerts.find(
    alert => (alert.condition as any).__typename === 'FinishedRunCondition'
  );
  const stoppedRunAlert = defaultAlerts.find(
    alert => (alert.condition as any).__typename === 'StoppedRunCondition'
  );
  const scriptableRunAlert = defaultAlerts.find(
    alert => (alert.condition as any).__typename === 'ScriptableRunCondition'
  );

  let slackIntegration: Slack | undefined;
  for (const integrationEdge of entity?.integrations?.edges ?? []) {
    if (integrationEdge?.node && isSlackIntegration(integrationEdge.node)) {
      slackIntegration = toSlackIntegration(integrationEdge.node);
    }
  }

  const finishedRunAlertCreator = () => {
    return createFinishedRunAlert({
      variables: {
        entityName: entity?.name ?? '',
      },
    }).then(alert => {
      return alert.data!.createFinishedRunAlert!.alert.id;
    });
  };

  const stoppedRunAlertCreator = () => {
    return createStoppedRunAlert({
      variables: {
        entityName: entity?.name ?? '',
        minimumRunDuration:
          scriptDurationMilliseconds !== undefined
            ? scriptDurationMilliseconds
            : toMilliseconds(10, 'minutes'),
      },
    }).then(alert => {
      return alert.data!.createStoppedRunAlert!.alert.id;
    });
  };

  const scriptableRunAlertCreator = () => {
    return createScriptableRunAlert({
      variables: {
        entityName: entity?.name ?? '',
      },
    }).then(alert => {
      return alert.data!.createScriptableRunAlert!.alert.id;
    });
  };

  return (
    <div className="alerts">
      <Table className="alerts--table" basic="very">
        <Table.Header>
          <Table.Row>
            <S.HeaderCellBold>Events</S.HeaderCellBold>
            {!isMobile &&
              subscriptionTypes.map(type => {
                if (type === 'EmailSubscription') {
                  return <S.HeaderCellBold key={type}>Email</S.HeaderCellBold>;
                } else {
                  return <S.HeaderCellBold key={type}>Slack</S.HeaderCellBold>;
                }
              })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              Run finished&nbsp;
              <Tooltip
                placement="top"
                title="Alerts for finished runs are disabled in Jupyter Notebook environments to prevent alert notifications on every cell execution. Use wandb.alert() in Jupyter Notebook environments instead.">
                <span>
                  <S.GrayIcon name="question circle outline" />
                </span>
              </Tooltip>
            </Table.Cell>
            {subscriptionTypes.map(type => {
              if (type === 'EmailSubscription') {
                return (
                  <Table.Cell key={type}>
                    <S.TableCellInner isMobile={isMobile}>
                      {isMobile && (
                        <S.MobileAlertTypeLabel>Email</S.MobileAlertTypeLabel>
                      )}
                      <EmailSubscription
                        key={`${type}|${finishedRunAlert?.id}`}
                        alert={finishedRunAlert}
                        alertCreator={finishedRunAlertCreator}
                        refetch={entityRefetch}
                        location="settings"
                      />
                    </S.TableCellInner>
                  </Table.Cell>
                );
              } else {
                return (
                  <Table.Cell key={type}>
                    <S.TableCellInner isMobile={isMobile}>
                      {isMobile && (
                        <S.MobileAlertTypeLabel>Slack</S.MobileAlertTypeLabel>
                      )}
                      <SlackChannelSubscription
                        key={`${type}|${finishedRunAlert?.id}`}
                        alert={finishedRunAlert}
                        alertCreator={finishedRunAlertCreator}
                        integration={slackIntegration}
                        refetch={entityRefetch}
                        location="settings"
                      />
                    </S.TableCellInner>
                  </Table.Cell>
                );
              }
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Run crashed after
              <Input
                className="alerts--crashed-duration"
                size="mini"
                type="number"
                value={fromMilliseconds(
                  scriptDurationMilliseconds,
                  durationUnit
                )}
                onChange={_.debounce((e, {value}) => {
                  const durationInMilliseconds = toMilliseconds(
                    parseFloat(value),
                    durationUnit
                  );
                  setScriptDurationMilliseconds(durationInMilliseconds);

                  const alertUpdater = (id: string) => {
                    return updateStoppedRunAlert({
                      variables: {
                        id,
                        minimumRunDuration: durationInMilliseconds,
                      },
                    }).then();
                  };

                  if (stoppedRunAlert !== undefined) {
                    alertUpdater(stoppedRunAlert.id).then();
                  } else {
                    stoppedRunAlertCreator()
                      .then(alertID => {
                        return alertUpdater(alertID);
                      })
                      .then(entityRefetch);
                  }
                }, 1)}
                placeholder="10"
                label={
                  <Dropdown
                    defaultValue={durationUnit}
                    options={timeUnits}
                    onChange={(event, data) => {
                      setDurationUnit(data.value as Unit);
                    }}
                  />
                }
                labelPosition="right"
              />
            </Table.Cell>
            {subscriptionTypes.map(type => {
              if (type === 'EmailSubscription') {
                return (
                  <Table.Cell key={type}>
                    <S.TableCellInner isMobile={isMobile}>
                      {isMobile && (
                        <S.MobileAlertTypeLabel>Email</S.MobileAlertTypeLabel>
                      )}
                      <EmailSubscription
                        key={`${type}|${stoppedRunAlert?.id}`}
                        alert={stoppedRunAlert}
                        alertCreator={stoppedRunAlertCreator}
                        refetch={entityRefetch}
                        location="settings"
                      />
                    </S.TableCellInner>
                  </Table.Cell>
                );
              } else {
                return (
                  <Table.Cell key={type}>
                    <S.TableCellInner isMobile={isMobile}>
                      {isMobile && (
                        <S.MobileAlertTypeLabel>Slack</S.MobileAlertTypeLabel>
                      )}
                      <SlackChannelSubscription
                        key={`${type}|${stoppedRunAlert?.id}`}
                        alert={stoppedRunAlert}
                        alertCreator={stoppedRunAlertCreator}
                        integration={slackIntegration}
                        refetch={entityRefetch}
                        location="settings"
                      />
                    </S.TableCellInner>
                  </Table.Cell>
                );
              }
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <S.TargetBlankLink href="https://docs.wandb.ai/guides/runs/alert">
                wandb.alert()
              </S.TargetBlankLink>
            </Table.Cell>
            {subscriptionTypes.map(type => {
              if (type === 'EmailSubscription') {
                return (
                  <Table.Cell key={type}>
                    <S.TableCellInner isMobile={isMobile}>
                      {isMobile && (
                        <S.MobileAlertTypeLabel>Email</S.MobileAlertTypeLabel>
                      )}
                      <EmailSubscription
                        key={`${type}|${scriptableRunAlert?.id}`}
                        alert={scriptableRunAlert}
                        alertCreator={scriptableRunAlertCreator}
                        refetch={entityRefetch}
                        location="settings"
                      />
                    </S.TableCellInner>
                  </Table.Cell>
                );
              } else {
                return (
                  <Table.Cell key={type}>
                    <S.TableCellInner isMobile={isMobile}>
                      {isMobile && (
                        <S.MobileAlertTypeLabel>Slack</S.MobileAlertTypeLabel>
                      )}
                      <SlackChannelSubscription
                        key={`${type}|${scriptableRunAlert?.id}`}
                        alert={scriptableRunAlert}
                        alertCreator={scriptableRunAlertCreator}
                        integration={slackIntegration}
                        refetch={entityRefetch}
                        location="settings"
                      />
                    </S.TableCellInner>
                  </Table.Cell>
                );
              }
            })}
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default Alerts;

interface SubscriptionProps {
  alert: Alert | undefined;
  alertCreator: () => Promise<string>;
  subscription: AlertSubscription | undefined;
  subscriptionCreator: (id: string) => Promise<any>;
  disabled: boolean;
  disabledReason: string;
  label: string;
  refetch: any;
  colorTheme?: 'light' | 'dark';
}

export const Subscription = (props: SubscriptionProps) => {
  const [subscriptionRemover] = useDeleteAlertSubscriptionMutation();
  const {
    alert,
    alertCreator,
    subscription,
    subscriptionCreator,
    disabled,
    disabledReason,
    label,
    refetch,
    colorTheme = 'light',
  } = props;

  return (
    <Popup
      className="alerts--config-popup"
      disabled={!disabled}
      on="hover"
      content={disabledReason}
      trigger={
        <S.ToggleWrapper>
          <S.Toggle
            $colorTheme={colorTheme}
            className="alerts--config-slider"
            toggle
            label={label}
            disabled={disabled}
            checked={subscription != null}
            onChange={(e: any, data: any) => {
              const updateSub = (id: string) => {
                if (data.checked === true && subscription === undefined) {
                  return subscriptionCreator(id).then(refetch);
                } else if (
                  data.checked === false &&
                  subscription !== undefined
                ) {
                  return subscriptionRemover({
                    variables: {
                      id: subscription.id,
                    },
                  }).then(refetch);
                } else {
                  return Promise.resolve();
                }
              };

              // Create the alert if it doesn't yet exist
              if (alert === undefined) {
                alertCreator().then(newAlertID => {
                  return updateSub(newAlertID);
                });
              } else {
                updateSub(alert.id);
              }
            }}
          />
        </S.ToggleWrapper>
      }
    />
  );
};

interface EmailSubscriptionProps {
  alert: Alert | undefined;
  alertCreator: () => Promise<string>;
  refetch: any;
  location: 'settings' | 'alert popover';
  colorTheme?: 'light' | 'dark';
}

export const EmailSubscription = (props: EmailSubscriptionProps) => {
  const {refetch, alert, alertCreator, location, colorTheme = 'light'} = props;
  const [createEmailSubscription] = useCreateEmailSubscriptionMutation();

  let emailSubscription: AlertSubscription | undefined;
  if (alert !== undefined) {
    emailSubscription = alert.subscriptions.find(
      sub => (sub as any).__typename === 'EmailSubscription'
    );
  }

  return (
    <Subscription
      colorTheme={colorTheme}
      alert={alert}
      alertCreator={alertCreator}
      subscription={emailSubscription}
      disabled={false}
      disabledReason={''}
      label={''}
      refetch={refetch}
      subscriptionCreator={(id: string) => {
        window.analytics?.track('Email Alert Activated', {
          location,
          alert: alert?.condition?.__typename
            ? alertConditionTypeToAnalyticsName[alert.condition.__typename]
            : 'undefined',
        });
        return createEmailSubscription({
          variables: {
            alertID: id,
          },
        });
      }}
    />
  );
};

interface SlackChannelSubscriptionProps {
  alert: Alert | undefined;
  alertCreator: () => Promise<string>;
  integration: Slack | undefined;
  refetch: any;
  location: 'settings' | 'alert popover';
  colorTheme?: 'dark' | 'light';
}

export const SlackChannelSubscription = (
  props: SlackChannelSubscriptionProps
) => {
  const {
    refetch,
    alert,
    alertCreator,
    integration,
    location,
    colorTheme = 'light',
  } = props;
  const [createSlackChannelSubscription] =
    useCreateSlackChannelSubscriptionMutation();

  let slackChannelSubscription: AlertSubscription | undefined;
  if (alert !== undefined) {
    slackChannelSubscription = alert.subscriptions.find(
      sub => (sub as any).__typename === 'SlackChannelSubscription'
    );
  }

  return (
    <Subscription
      colorTheme={colorTheme}
      alert={alert}
      alertCreator={alertCreator}
      subscription={slackChannelSubscription}
      disabled={integration === undefined}
      disabledReason={'Connect Slack to enable this feature'}
      label={''}
      refetch={refetch}
      subscriptionCreator={(id: string) => {
        window.analytics?.track('Slack Alert Activated', {
          location,
          alert: alert?.condition?.__typename
            ? alertConditionTypeToAnalyticsName[alert.condition.__typename]
            : 'undefined',
        });
        return createSlackChannelSubscription({
          variables: {
            integrationID: integration!.id,
            alertID: id,
          },
        });
      }}
    />
  );
};
