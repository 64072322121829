// eslint-disable-next-line import/no-cycle -- please fix if you can
import {RootState} from '../../types/redux';
import {GroupPageNormalized} from '../views/groupPage/types';
import * as ViewsNormalize from '../views/normalize';
import {lookupPart} from '../views/normalizerSupport';
import * as PanelBankConfigTypes from '../views/panelBankConfig/types';
import * as PanelsTypes from '../views/panels/types';
import {ProjectPageNormalized} from '../views/projectPage/types';
import {ReportPageNormalized} from '../views/report/types';
import {RunPageNormalized} from '../views/runPage/types';
import * as RunSetTypes from '../views/runSet/types';
import {SectionNormalized, SectionObjSchema} from '../views/section/types';
import {SweepPageNormalized} from '../views/sweepPage/types';
import * as ViewTypes from '../views/types';
import * as WorkspaceSettingTypes from '../views/workspaceSettings/types';
import * as Types from './types';

const INTERNAL_SERVER_ERROR = {
  errorCode: '500',
  userTitle: 'Error loading workspace',
  userMessage: 'This workspace could not be loaded.',
};

type BasicStatusStates = 'loading' | 'error';
type AllStatusStates = BasicStatusStates | 'ready';

export type WorkspaceStatus = {
  // the lack of a status means that the status is 'ready'
  // we aren't including it here so that we can directly spread/use the
  // return value of the selector
  status: BasicStatusStates | undefined;
  // having this be undefined seems unnecssary, but for useEffect
  // dependency lint checks, the prop needs to be present even if undefined
  error: Types.WorkspaceError | undefined;
};

/**
 * Gives you a consistent way to use data that may be loading or errored, eg anything loaded off the network
 * The helper functions getDataChecked, getError, getStatus make it much easier to work with this.
 */
export type WorkspaceStatusOrData<T> = WorkspaceStatus | T;

export type ReduxWorkspaceStateWithLoadedView = Types.ReduxWorkspaceState & {
  viewRef: ViewTypes.ViewRef;
};
export function makeWorkspaceReduxStateSelector(workspaceID: string) {
  return (
    state: RootState
  ): WorkspaceStatusOrData<ReduxWorkspaceStateWithLoadedView> => {
    const workspace = state.workspaces[workspaceID];
    // Order matters here - we don't have a loading field, so we are relying
    // on the error field to toggle off the "loading" status
    if (workspace?.error) {
      return {status: 'error', error: workspace.error};
    }
    if (workspace == null || workspace.viewRef == null) {
      return {status: 'loading', error: undefined};
    }
    return {
      ...workspace,
      viewRef: workspace.viewRef,
    };
  };
}

export function makeWorkspaceViewSelector(workspaceID: string) {
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStatusOrData<{view: ViewTypes.LoadableView}> => {
    const workspaceState = workspaceReduxStateSel(state);
    if (getStatus(workspaceState) !== 'ready') {
      return workspaceState as WorkspaceStatus;
    }
    const workspace = getDataChecked(workspaceState);

    const view = state.views.views[workspace.viewRef.id];
    if (view.loading) {
      return {status: 'loading', error: undefined};
    }
    if (!view) {
      console.error('invalid state - workspace view not found');
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
      };
    }
    return {view};
  };
}

export function makeWorkspaceReduxStateWithViewSelector(workspaceID: string) {
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStatusOrData<
    {view: ViewTypes.LoadableView} & ReduxWorkspaceStateWithLoadedView
  > => {
    const workspaceState = workspaceReduxStateSel(state);
    if (getStatus(workspaceState) !== 'ready') {
      return workspaceState as WorkspaceStatus;
    }
    const workspace = getDataChecked(workspaceState);

    const view = state.views.views[workspace.viewRef.id];
    if (view.loading) {
      return {status: 'loading', error: undefined};
    }
    if (!view) {
      console.error('invalid state - workspace view not found');
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
      };
    }
    return {...workspace, view};
  };
}

export function makeWorkspaceSpecSelector<T extends Types.ViewTypes>(
  workspaceID: string,
  partType: T
) {
  const workspaceViewSel = makeWorkspaceViewSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStatusOrData<{
    view: ViewTypes.LoadableView;
    fullSpec: ViewTypes.WholeFromTypeWithRef<
      'project-view' | 'group-view' | 'sweep-view' | 'run-view'
    >;
  }> => {
    const workspaceViewResult = workspaceViewSel(state);
    if (getStatus(workspaceViewResult) !== 'ready') {
      return workspaceViewResult as WorkspaceStatus;
    }

    const {view} = getDataChecked(workspaceViewResult);
    const partRef = view.partRef;
    if (partRef == null || partRef.type !== partType) {
      console.error(
        'invalid state - unable to find normalized workspace data in redux'
      );
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
      };
    }

    // coercing type since this is a workspace spec
    const fullSpec = ViewsNormalize.denormalize(
      state.views.parts,
      partRef
    ) as ViewTypes.WholeFromTypeWithRef<
      'project-view' | 'group-view' | 'sweep-view' | 'run-view'
    >;

    return {
      view,
      fullSpec,
    };
  };
}

export function makeWorkspaceViewPartSelector<T extends Types.ViewTypes>(
  workspaceID: string,
  partType: T
) {
  const workspaceViewSel = makeWorkspaceViewSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStatusOrData<{
    part:
      | ProjectPageNormalized
      | GroupPageNormalized
      | SweepPageNormalized
      | RunPageNormalized
      | ReportPageNormalized;
    partRef: ViewTypes.ViewPartRefs;
  }> => {
    const workspaceViewResult = workspaceViewSel(state);
    if (getStatus(workspaceViewResult) !== 'ready') {
      return workspaceViewResult as WorkspaceStatus;
    }

    const {view} = getDataChecked(workspaceViewResult);
    const partRef = view.partRef;
    if (partRef == null || partType !== partRef.type) {
      console.error(
        'invalid state - unable to find normalized workspace data in redux'
      );
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
      };
    }

    // asserting type here since we know what page types are allowed
    const part = lookupPart(state.views.parts, partRef);

    return {part, partRef};
  };
}

export const getStatus = <T>(
  state: WorkspaceStatusOrData<T>
): AllStatusStates => {
  const status = (state as WorkspaceStatus).status;
  if (status === 'loading') {
    return status;
  }
  if (status === 'error') {
    return status;
  }
  if (status != null) {
    throw new Error('invalid state - unknown status: ' + status);
  }
  return 'ready';
};

export const getError = <T>(
  state: WorkspaceStatusOrData<T>
): Types.WorkspaceError | undefined => {
  if (getStatus(state) !== 'error') {
    throw new Error('tried to get error on non-error status');
  }
  return (state as WorkspaceStatus).error;
};

export const getDataChecked = <T>(state: WorkspaceStatusOrData<T>): T => {
  if (getStatus(state) !== 'ready') {
    throw new Error('tried to get data on non-ready status');
  }
  return state as T;
};

export const getDataMaybe = <T>(
  state: WorkspaceStatusOrData<T>
): T | undefined => {
  if (getStatus(state) !== 'ready') {
    return undefined;
  }
  return state as T;
};

const combineStatusOrData = <A, B>(
  stateOne: WorkspaceStatusOrData<A>,
  stateTwo: WorkspaceStatusOrData<B>
): WorkspaceStatus | WorkspaceStatusOrData<A & B> => {
  // note that if they both have error set, we are dropping the second one and not trying to combine them
  if (getStatus(stateOne) !== 'ready') {
    return stateOne as WorkspaceStatus;
  }
  if (getStatus(stateTwo) !== 'ready') {
    return stateTwo as WorkspaceStatus;
  }
  return {...stateOne, ...stateTwo};
};

export function makeMultiRunWorkspacePageSelector(
  workspaceID: string,
  type: 'project-view' | 'sweep-view' | 'group-view'
) {
  const workspaceViewPartSel = makeWorkspaceViewPartSelector(workspaceID, type);
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStatusOrData<
    MultiRunWorkspacePageData & GenericWorkspacePageData
  > => {
    const workspaceReduxState = workspaceReduxStateSel(state);
    const workspaceViewPart = workspaceViewPartSel(state);

    const combinedResult = combineStatusOrData(
      workspaceReduxState,
      workspaceViewPart
    );
    if (getStatus(combinedResult) !== 'ready') {
      return combinedResult as WorkspaceStatus;
    }
    const data = getDataChecked(combinedResult);
    const partRef = data.partRef as WorkspacePartRefTypes;
    const part = data.part as
      | ProjectPageNormalized
      | GroupPageNormalized
      | SweepPageNormalized;
    const sectionPart = lookupPart(state.views.parts, part.sectionRef);
    if (sectionPart.runSetRefs.length !== 1) {
      console.error('invalid state - no runset found for workspace');
      return {
        status: 'error',
        error: INTERNAL_SERVER_ERROR,
      };
    }

    const runSetPart = lookupPart(state.views.parts, sectionPart.runSetRefs[0]);

    const panelsPart =
      sectionPart.panelsRef != null
        ? lookupPart(state.views.parts, sectionPart.panelsRef)
        : undefined;

    const panelBankConfigPart = lookupPart(
      state.views.parts,
      sectionPart.panelBankConfigRef
    );

    return {
      workspaceSettingsRef: sectionPart.workspaceSettingsRef,
      sectionRef: part.sectionRef,
      sectionPart,
      runSetRef: sectionPart.runSetRefs[0],
      runSetPart,
      panelsRef: sectionPart.panelsRef,
      panelsPart,
      panelBankConfigRef: sectionPart.panelBankConfigRef,
      panelBankConfigPart,
      part,
      partRef,
      ...calculateGenericWorkspacePageData(data),
    };
  };
}

type WorkspacePartRefTypes =
  | ({
      type: 'project-view';
    } & ViewTypes.PartRefFields)
  | ({
      type: 'group-view';
    } & ViewTypes.PartRefFields)
  | ({
      type: 'sweep-view';
    } & ViewTypes.PartRefFields);

export type MultiRunWorkspacePageData = {
  sectionRef: ViewTypes.PartRefFromObjSchema<SectionObjSchema>;
  sectionPart: SectionNormalized;
  runSetRef: RunSetTypes.Ref;
  runSetPart: RunSetTypes.RunSetPart;
  panelsRef: PanelsTypes.Ref | undefined;
  panelsPart: PanelsTypes.PanelsNormalized | undefined;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelBankConfigPart: PanelBankConfigTypes.PanelBankConfigNormalized;
  part: ProjectPageNormalized | SweepPageNormalized | GroupPageNormalized;
  partRef: WorkspacePartRefTypes;
  workspaceSettingsRef: WorkspaceSettingTypes.Ref;
};

export type GenericWorkspacePageData = {
  nwId?: Types.NamedWorkspaceId;
};

const calculateGenericWorkspacePageData = (
  workspaceReduxState: Types.ReduxWorkspaceState
) => {
  const nwId = workspaceReduxState.nwId;
  return {
    nwId,
  };
};

type RunPagePartRef = {
  type: 'run-view';
} & ViewTypes.PartRefFields;

export type SingleRunWorkspacePageData = {
  workspaceSettingsRef: WorkspaceSettingTypes.Ref;
  panelBankConfigRef: PanelBankConfigTypes.Ref;
  panelsRef: PanelsTypes.Ref | undefined;
  partRef: RunPagePartRef;
};
export function makeRunWorkspaceSelector(workspaceID: string) {
  const workspaceViewPartSel = makeWorkspaceViewPartSelector(
    workspaceID,
    'run-view'
  );
  const workspaceReduxStateSel = makeWorkspaceReduxStateSelector(workspaceID);
  return (
    state: RootState
  ): WorkspaceStatusOrData<
    SingleRunWorkspacePageData & GenericWorkspacePageData
  > => {
    const workspaceViewPart = workspaceViewPartSel(state);
    const workspaceReduxState = workspaceReduxStateSel(state);

    const combinedResult = combineStatusOrData(
      workspaceReduxState,
      workspaceViewPart
    );
    if (getStatus(combinedResult) !== 'ready') {
      return combinedResult as WorkspaceStatus;
    }

    const combinedData = getDataChecked(combinedResult);
    // coerce type since we know what data we're querying
    const part = combinedData.part as RunPageNormalized;

    return {
      workspaceSettingsRef: part.workspaceSettingsRef,
      panelBankConfigRef: part.panelBankConfigRef,
      panelsRef: part.panelsRef,
      partRef: combinedData.partRef as RunPagePartRef,
      ...calculateGenericWorkspacePageData(combinedData),
    };
  };
}

export function makeWorkspaceFnwIdSelector(workspaceID: string) {
  return (state: RootState) => {
    const workspace = state.workspaces[workspaceID];

    return workspace?.nwId;
  };
}

export const makeWorkspaceErrorSelector = (workspaceId: string) => {
  return (state: RootState) => {
    const workspace = state.workspaces[workspaceId];

    return workspace?.error;
  };
};
