import * as _ from 'lodash';

import {PERF_LOG_PREFIX, reportTimedEvent} from './report';

/*
This file exposes a simple timer you can use 

We use this instead of performance.mark/measure since we want to do things
like track context data that is updatable. We may in the future also want to
go back and look at unfinished events/etc to see what's in flight
*/

interface TimedEvent {
  name: string;
  context?: Record<string, any>;
  startTimeMs: number;
}

const timedEvents = new Map<string, TimedEvent>();

const getTimedEventKey = (name: string) => _.uniqueId(name);

const startTimedEventInner = (eventKey: string, event: TimedEvent) => {
  if (timedEvents.has(eventKey)) {
    console.error(PERF_LOG_PREFIX, 'non-unique key:', eventKey);
  }
  timedEvents.set(eventKey, event);
  return eventKey;
};

const endTimedEventInner = (key: string) => {
  const nowMs = Date.now();
  const event = timedEvents.get(key);
  if (!event) {
    console.error(PERF_LOG_PREFIX, 'endTimedEvent key not found!', key);
    return;
  }
  const elapsedMs = nowMs - event.startTimeMs;
  reportTimedEvent({
    elapsedMs,
    absoluteStartTimeMs: event.startTimeMs,
    name: event.name,
    context: event.context,
  });
  timedEvents.delete(key);
};

export const startPerfTimer = (name: string, context?: Record<string, any>) => {
  const key = getTimedEventKey(name);
  const event = {name, context, startTimeMs: Date.now()};
  startTimedEventInner(key, event);

  return {
    endPerfTimer: () => {
      endTimedEventInner(key);
    },
    updatePerfContext: (newContext: Record<string, any>) => {
      event.context = event.context ?? {};
      Object.assign(event.context, newContext);
    },
  };
};
