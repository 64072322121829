import {FormControlLabel, Radio} from '@material-ui/core';
import {WBIcon} from '@wandb/ui';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
// eslint-disable-next-line wandb/no-deprecated-imports
import {
  Button as SemanticButton,
  Checkbox,
  Dropdown,
  Message,
  Popup,
  StrictDropdownProps,
} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import CopyableText from '../CopyableText';

export const ErrorMessage = styled.div`
  font-size: 14px;
  margin-top: 4px;
  line-height: 140%;
  color: ${globals.RED_550};
`;

export const IndividualEmailErrorMessage = styled.div`
  margin-top: -8px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${globals.errorText};
`;

export const IndividualEmailSuccessMessage = styled.div`
  margin-top: -8px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${globals.successText};
`;

export const ExtraInfoMessage = styled.div`
  margin-top: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  a {
    color: ${globals.gray600};

    &:hover {
      color: ${globals.linkHoverBlue};
    }
  }
`;

export const ContactSalesLink = styled(TargetBlank)`
  display: inline-flex;
  align-items: center;
`;

export const ContactSalesIcon = styled(WBIcon)`
  margin-left: 2px;
  margin-top: 3px;
`;

export const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const InputLabel = styled.div`
  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${globals.gray800};

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export const InputRadio = styled(InputLabel)`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const InputRadioOption = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: 3px;
  background-color: ${globals.gray300};
  margin-left: 8px;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  height: 22px;
  font-size: 13px;
  font-weight: 400;

  padding: ${p => (p.active ? `0 8px 0 4px` : `0 6px`)};

  ${p =>
    p.active &&
    css`
      background-color: ${globals.primary};
      color: ${globals.white};
    `}

  ${p =>
    p.disabled &&
    css`
      background-color: ${globals.gray200};
      color: ${globals.gray600};
    `}
`;

export const RadioIcon = styled(WBIcon)`
  font-size: 20px;
  margin-right: 4px;
`;

export const InputText = styled.input<{
  error?: boolean;
  extraMarginTop?: boolean;
  marginBottom?: boolean;
  isInvalid?: boolean;
}>`
  border: 1px solid ${globals.gray400};
  box-sizing: border-box;
  border-radius: 4px;
  border-color: ${props =>
    props.isInvalid ? globals.error : globals.gray400} !important;

  height: 40px;
  width: 100%;
  margin-top: ${props => (props.extraMarginTop ? '16px' : '8px')};
  ${props => (props.marginBottom ? 'margin-bottom: 8px;' : '')}
  padding: 8px 32px 8px 12px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  ${props =>
    props.error &&
    css`
      &:focus,
      & {
        outline-color: ${globals.errorBorder};
        border-color: ${globals.errorBorder};
      }
    `}
`;

export const MultilineInputText = styled.textarea`
  border: 1px solid #afb1b6;
  box-sizing: border-box;
  border-radius: 8px;

  width: 100%;
  height: 120px;
  margin-top: 16px;
  padding: 8px 32px 8px 12px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  resize: none;
`;

export const EntityInputText = styled(InputText)`
  padding: 8px 32px 10px 82px;
`;

export const EntityInputIcon = styled(WBIcon)`
  color: ${globals.error};
  position: absolute;
  top: 20px;
  right: 10px;
`;

export const InputDropdown = styled<React.ComponentClass<StrictDropdownProps>>(
  Dropdown
)`
  &.ui.selection.dropdown {
    background: ${globals.white};
    border: 1px solid ${globals.gray400};
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: unset;
  }

  margin-top: 8px;
  width: 100%;
`;

export const EntityPrefix = styled.div`
  position: relative;

  ::after {
    position: absolute;
    left: 14px;
    top: 16px;
    content: 'wandb.ai/';
    pointer-events: none;

    line-height: 22px;
  }
`;

export const Button = styled(SemanticButton)`
  &&&&& {
    width: 100%;
    margin: 32px 0 0 0;
  }
`;

export const CopyableTextInput = styled(CopyableText)`
  height: 40px;
  width: 100%;
  margin: 8px 0px 0px 0px;
  white-space: nowrap;

  border: 1px solid ${globals.gray400};
  box-sizing: border-box;
  border-radius: 4px;

  i.icon.copy-icon {
    opacity: 1;
  }
`;

export const WarningMessage = styled.div`
  color: ${globals.gray600};
  font-size: 12px;
  line-height: 18px;
  padding: 3px 0;
  display: flex;

  a {
    color: ${globals.linkHoverBlue};
  }
`;

export const AgreeCheckbox = styled(Checkbox)`
  &&&& {
    margin-top: 24px;

    label {
      padding-left: 24px;
    }
  }
`;

export const Tooltip = styled(Popup)`
  &&&&& {
    color: white;
    background: ${globals.gray700};
    border-color: ${globals.gray700};
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.125rem;
    padding: 0.45rem 0.4rem;
    max-width: 260px;

    a {
      color: white;
      text-decoration: underline;
    }

    &::before {
      background: ${globals.gray700};
      box-shadow: none;
    }
  }
`;

export const TooltipIcon = styled(WBIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  font-size: 18px;
`;

export const ToggleLinkTextWrapper = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${globals.gray600};
  margin-top: 8px;
  :hover {
    cursor: pointer;
  }
`;

export const InputWrapper = styled.div<{visible?: boolean}>`
  display: ${props => (props.visible ? 'block' : 'none')};
`;

export const ValidationBlock = styled.div`
  min-height: 75px;
  display: flex;
`;

export const ValidationLoadingContainer = styled.div`
  flex: 1 0 auto;
  text-align: right;
`;

export const StorageTypeControlLabel = styled(FormControlLabel)`
  & input:disabled {
    opacity: 0 !important;
  }
`;

export const StorageTypeLabel = styled.h4`
  margin: 17px 0 0 0 !important;
  font-weight: bold;
`;

export const StorageTypeRadio = styled(Radio)`
  /* align-items: start !important; */
  padding-top: 0 !important;
  margin-right: 10px !important;
`;

export const StorageTypeDescription = styled.span`
  display: flex;
`;

export const BucketStorageForm = styled.div`
  margin: 15px 0 0 40px;
`;

export const BucketStorageSelectDropdown = styled(Dropdown)`
  & > .text {
    width: 100%;
  }
`;

export const BucketStorageSelectWrapper = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const BucketStorageSelectName = styled.span`
  flex: 1 0 auto;
`;

export const BucketStorageSelectProvider = styled.span`
  flex: 0 0 auto;
  color: #757575;
`;

export const BucketStorageMessage = styled(Message).attrs({
  visible: true,
  size: 'tiny',
})`
  /* display: block !important; */
  padding-top: 14px !important;
  padding-bottom: 14px !important;
`;

export const DisplayStorageBucketInfoWrapper = styled.div`
  margin: 18px 0;
`;
export const EmailsInputLabelAndToggleWrapper = styled.div`
  margin-top: ${globals.spu};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmailsInputLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${globals.gray700};
`;

export const SeeDocsLink = styled(TargetBlank)`
  display: flex;
  align-items: center;
`;
