import * as _ from 'lodash';

import {CHART_MAX_X_BUCKETS} from '../../constants';
import * as PlotMath from './../math';
import {Line} from './../types';
import {linesShareXInterval} from './linesShareXInterval';
import {BucketSpec} from './types';

export function getBucketSpec(
  lines: Line[],
  config: {
    maxBuckets: number;
  } = {
    maxBuckets: CHART_MAX_X_BUCKETS,
  }
): BucketSpec | null {
  try {
    const maxLengthRun = PlotMath.arrMax(
      lines.map(line => line.data.length),
      0
    );

    if (
      lines.every(line => {
        return line.data.length === 0;
      })
    ) {
      return null;
    }

    let minX = Infinity;
    let maxX = -Infinity;

    lines.forEach(line =>
      line.data.forEach(point => {
        if (_.isFinite(point.x)) {
          if (point.x < minX) {
            minX = point.x;
          }
          if (point.x > maxX) {
            maxX = point.x;
          }
        }
      })
    );

    if (linesShareXInterval(lines)) {
      return {minX, maxX, bucketCount: maxLengthRun, alignedWithPoints: true};
    }

    /**
     * optimize bucketing strategy for the use case of epochs on the x-axis (one bucket per epoch):
     *   - data points are plotted at each bucket's center, so we increase the range by 0.5 on each end - this means we'll see data points precisely at epoch 0 and epoch maxEpoch
     *  - set bucketCount to the total number of epochs
     */
    const bucketCount =
      maxLengthRun > config.maxBuckets ? config.maxBuckets : maxLengthRun;
    /**
     * We have a hard-limit on how many buckets we'll have. As the number of points exceeds this number the bucket center points will no longer be exactly aligned with the data. But we MUST ensure that by the time we start sampling (at CHART_SAMPLES points) we get at least 6 points per bucket.
     */

    return {
      minX: minX - 0.5,
      maxX: maxX + 0.5,
      bucketCount,
      alignedWithPoints: false,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}
