import React from 'react';

type NewCheckoutModalContextState = {
  submitting: boolean;
};
export const NewCheckoutModalContext =
  React.createContext<NewCheckoutModalContextState | null>(null);

type NewCheckoutModalUpdaterContextState = {
  setSubmitting: (s: boolean) => void;
};
export const NewCheckoutModalUpdaterContext =
  React.createContext<NewCheckoutModalUpdaterContextState | null>(null);
