import config from '../config';
import {Email, EmailType, useUserEmailsQuery} from '../generated/graphql';

type userEmailData = {
  emails: Email[] | undefined;
  verifiedCorporateEmails: Email[] | undefined;
  verifiedViewerEmailTypes: EmailType[] | undefined;
};

export function useUserEmails(): userEmailData {
  const {data: userEmailsData} = useUserEmailsQuery();

  const userEmails = userEmailsData?.viewer?.emails;
  const verifiedCorporateEmails = userEmails?.filter(
    email =>
      email.type === 'CORPORATE' &&
      (email.verified || // Do not require emails to be verified in dev/CI envs
        ['development', 'integration', 'devprod'].includes(
          config.ENVIRONMENT_NAME
        ))
  );
  const verifiedViewerEmailTypes = userEmails
    ?.filter(e => e.verified)
    .map(e => e.type);
  return {
    emails: userEmails,
    verifiedCorporateEmails,
    verifiedViewerEmailTypes,
  };
}
