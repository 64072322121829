/*

Time To Interactive (TTI) Implementation

Algorithm description: 
The algorithm implemented in this file attempts to known when a page appears loaded from the 
user's perspective and is not doing any background processing related to page startup.

TTI captures how long it takes for the web page to load data from the server and process it. TTI is measured 
from the start of the page load until both the network and javascript thread become idle. TTI occurs 
at the end of the first activity that is followed by 5 seconds of idleness.

It is based on the algorithm described at:
* https://developer.mozilla.org/en-US/docs/Glossary/Time_to_interactive
* https://web.dev/tti/
There are a couple useful graphics showing how TTI is calculated there.

The general outline of the algorithm is:
* When a measured event occured (network activity or longtask thread activity):
  * See if the start is within IDLE_GAP_MILLIS of the existing TTI
  * If it is, and TTI+IDLE_GAP_MILLIS is less than the end of the measured event:
    * Set TTI to the end of the measured event
    * Check to see if the new TTI is within IDLE_GAP_MILLIS of the start of otherChunks
      -> otherChunks stores all events seen that were > TTI, stored in sorted 
        order by start time.
  * If it is not:
    * add the event to otherChunks, maintaining the sorted order of otherChunks


The goal of TTI is not to perfectly capture a single page's loading time, but to create 
a _relative_ value and one that signals what users/pages need attention. 

Scenario considerations if you're considering adjusting the algorithm:
* User clicking around in the UI - if a user does this while a page is loading, or 
  immediately after a page loads, it will generate new network traffic/JS logic.
  - users scrolling around in the UI is a special case of this since it causes 
    additional loading to occur (eg in workspaces: new panels load data only when in view).
* Some pages (eg workspaces) poll for data - right now this is set to happen between 10 - 60s,
  but a more smaller value closer approaching IDLE_GAP_MILLIS would be problematic.
* There's no guaranteed "The user left this page" event fired by browsers (only pagehide and visibilitychange)
* There's only a single javascript thread running at a time, so we can't guarantee setTimeout callbacks
  will fire exactly when we want
*/

import produce from 'immer';

// when (startOfNextTimeChunk - EndOfPrevTimeChunk) < IDLE_GAP_MILLIS, we combine those
// into one block of busy-ness for purposes of TTI calculation
// Previously, this value was higher (4s), but we found that users were frequently
// interacting with the UI during this time, which caused network requests to occur
// before that time was passed. This caused TTI to be inaccurate.
// This value may be too low and was not arrived at in a scientific way, so
// don't be surprised if we need to adjust it in the future.
export const IDLE_GAP_MILLIS = 1_000;

// When TTI exceeds this value, we consider this bad enough to immediately report
// the TTI. Note that this effectively caps the TTI
// at this number, so if it's too low, we won't have visibility into truly
// bad workspaces
export const MAX_TTI_MILLIS = 30_000;

// When a TTI session starts, we start this timer. If the callback fires
// and we TTI hasn't hit MAX_TTI_MILLIS, it means the page was able to reach
// an idle, responsive state and we can report the TTI.
//
// Note that an extremely long request could cause this to
// report early, but that long request would itself generate a report
// to be investigated as a longtask. We will operate under the assumption that
// normal pages won't be seeing 45s request times.
export const SUCCESSFUL_IDLE_MILLIS = 45_000;

const PRINT_TTI_TEST_LOGS = false; // DO NOT SUBMIT with value true.

export interface TimeChunk {
  start: number;
  end: number;
}

export const EndReasons = {
  // The browser signaled that the user is no longer on the page
  LEAVE_PAGE: 'leavePage',
  // The app navigated using normal SPA conventions (ie startProfilerPageView was called for the next page
  SPA_NAV: 'spaNav',
  // This is a "success" - the page was active for at least TTI_TIMEOUT_MILLIS and didn't keep increasing TTI - it's *possible*
  // there are outstanding requests in flights, but most likely the page loaded and had a chance to idle successfully
  REACHED_IDLE: 'reachedIdle',
  // If TTI gets too large, we go ahead and report it so that the data doesn't get lost
  TTI_EXCEEDED_MAX: 'ttiExceededMax',
} as const;

export type EndReason = (typeof EndReasons)[keyof typeof EndReasons];

export interface TtiRecord {
  tti: number;
  endReason: EndReason;
}

export type TtiReportCallback = (record: TtiRecord) => void;

interface TrackingState {
  tti: number;
  otherChunks: TimeChunk[];
  reportCb: TtiReportCallback;
  baseTime: number;
}

const getDefaultState = (): TrackingState => ({
  tti: 0,
  otherChunks: [],
  reportCb: () => null,
  baseTime: 0,
});

// Holds the state of the current session - will be reset when
// the session ends
let sessionState: TrackingState | undefined;

// Handles the timeout/side effects part of beginning session
// so they don't interfere with tests.
export const beginTtiSession = (cb: TtiReportCallback) => {
  if (PRINT_TTI_TEST_LOGS) {
    console.log('TTI Begin Session');
  }
  beginTtiSessionInner(cb);

  setTimeout(() => {
    // We use this timer to detect if the page idled and we
    // didn't trigger TTI reporting before this point.
    // This is "success" in that we hope that TTI won't reach TTI_EXCEEDED_MAX :)

    // Note that this callback won't get invoked if the browser JS thread is busy,
    // so this callback is not guaranteed to happen right at TTI_TIMEOUT_MILLIS
    endTtiSession(EndReasons.REACHED_IDLE, sessionState);
  }, SUCCESSFUL_IDLE_MILLIS);
};

// This export is only intended for tests.
export const beginTtiSessionInner = (cb: TtiReportCallback) => {
  sessionState = getDefaultState();
  sessionState.reportCb = cb;
};

export const endTtiSession = (cause: EndReason, stateToEnd?: TrackingState) => {
  if (PRINT_TTI_TEST_LOGS) {
    console.log('TTI End Session');
  }
  if (stateToEnd && stateToEnd !== sessionState) {
    // This occurs when the timeout in beginTtiSession fires, but a new session has
    // already been started. We can safely ignore it since beginSession closes out the older session
    return;
  }
  reportPageTtiAndEndSession(cause);
};

// Called when we have decided the TTI for the page
// and are ready to invoke the report callback.
const reportPageTtiAndEndSession = (endReason: EndReason) => {
  if (!sessionState) {
    return;
  }

  sessionState.reportCb({
    tti: sessionState.tti,
    endReason,
  });
  sessionState = undefined;
};

const baseTimeAdjustTimeChunk = (tc: TimeChunk, baseTime: number) => ({
  start: tc.start - baseTime,
  end: tc.end - baseTime,
});

// This is a stateful wrapper of updateTimeToInteractiveInner,
// it handles everything that isn't purely the tti algorithm
// eg: interactions with state storage (currentState)
export const updateTimeToInteractive = (tc: TimeChunk) => {
  if (!sessionState) {
    // this will get called frequently after TTI is reported,
    // so don't throw errors/etc here
    return;
  }
  if (sessionState.tti === 0) {
    // PeformanceObserver times for a given pageview don't always start at zero
    // eg when the user navigates between pages in the app, PerformanceObserver
    // times don't get reset.
    sessionState.baseTime = tc.start;
    sessionState.tti = tc.end - sessionState.baseTime;
  }
  sessionState = updateTimeToInteractiveInner(
    sessionState,
    baseTimeAdjustTimeChunk(tc, sessionState.baseTime),
    IDLE_GAP_MILLIS
  );
  if (sessionState.tti >= MAX_TTI_MILLIS) {
    reportPageTtiAndEndSession(EndReasons.TTI_EXCEEDED_MAX);
  }
};

// actual implementation of the TTI algorithm - see description at the beginning of the file
// for discussion of algorithm
export const updateTimeToInteractiveInner = (
  state: TrackingState,
  latest: TimeChunk,
  // I pass gapMillis in for testing purposes: we may change this value
  // but don't want to have to rewrite the tests
  gapMillis: number
): TrackingState =>
  produce(state, draft => {
    if (PRINT_TTI_TEST_LOGS) {
      console.log('update TTI:', latest, state, gapMillis);
    }

    const ttiWithGap = draft.tti + gapMillis;
    if (latest.start > ttiWithGap) {
      // we didn't change TTI, so just put latest into otherChunks
      draft.otherChunks = addToOtherChunks(latest, state.otherChunks);
      return;
    }
    draft.tti = Math.max(draft.tti, latest.end);
    if (draft.otherChunks.length > 0) {
      // The newer TTI means TTI might reach the chunks in otherChunks,
      // so check the otherChunks list.
      const {newTti, newOtherChunks} = gobbleUpOtherChunks(
        draft.tti,
        state.otherChunks,
        gapMillis
      );
      draft.tti = newTti;
      draft.otherChunks = newOtherChunks;
    }
  });

// This method is called when the TTI time has increased. It checks
// to see if the new TTI overlaps with timechunks in otherChunks.
//
// otherChunks is sorted by start time, so it's able to
// only check the start of the list, increasing TTI as it goes.
const gobbleUpOtherChunks = (
  tti: number,
  otherChunks: TimeChunk[],
  gap: number
): {newTti: number; newOtherChunks: TimeChunk[]} => {
  let idx = 0;
  while (idx < otherChunks.length && tti + gap >= otherChunks[idx].start) {
    tti = Math.max(tti, otherChunks[idx].end);
    idx++;
  }
  return {newTti: tti, newOtherChunks: otherChunks.slice(idx)};
};

// This adds a new chunk to the list, ensuring that the list
// stays sorted in order of the start field.
const addToOtherChunks = (
  latest: TimeChunk,
  otherChunks: TimeChunk[]
): TimeChunk[] => {
  // Idea for future optimization: considering merging chunks inside of otherChunks. It's a bit complex, but
  //   would help if we end up with too many items in otherChunks.
  // The assumption for current code is that otherChunks will generally be small since most events should occur roughly in order
  if (otherChunks.length === 0) {
    return [latest];
  }

  // Future potential optimizations:
  // 1. wW could use a linked list or heap to make adding to the list less expensive if needed
  // 2. Start search for insertion location at the end of the list
  let idx = otherChunks.findIndex(tc => latest.start <= tc.start);
  if (idx === -1) {
    idx = otherChunks.length;
  }
  return [...otherChunks.slice(0, idx), latest, ...otherChunks.slice(idx)];
};
