import {envIsPublicCloud} from '../config';

/**
 * These published-work paths should be kept for backwards compatibility. This was a quick hack job
 * (before gallery) to satisfy a few customers who wanted to publicize reports while using data from
 * a private project. The way to create such a report is via a very old script that (hopefully) isn't
 * being used anymore. This should be phased out in the future, but there are a few reports that still
 * utilize this path (eg. openai).
 */
export const PUBLISHED_WORK_PATH_SEGMENT = 'published-work';
export const REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH = `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}/:reportNameAndID`;

/**
 * These gallery paths are "old fully connected" paths that should be kept for backwards compatibility.
 * Ideally, this should be phased out. Gallery and FC are terms that are used all over the codebase,
 * so the terminology also needs to be updated.
 */

// This variable name is quite confusing. We should rename it to be FULLY_CONNECTED_SEGMENT
// so it matches the redirecting and file naming logic.
export const GALLERY_PATH_SEGMENT = 'fully-connected';

export const GALLERY_PATH = [
  `/${GALLERY_PATH_SEGMENT}/projects/:repoName`,
  `/${GALLERY_PATH_SEGMENT}/topics/:topic`,
  `/${GALLERY_PATH_SEGMENT}/:page?/:tag?/:reportID?`,
];
export const GALLERY_CREATE_POST = `/${GALLERY_PATH_SEGMENT}/create-post`;

// Only GALLERY_ADMINS have access to this internal wandb admins and manage fully connected content
// including pending posts, discussions, content ordering, etc.
export const GALLERY_INTERNAL_ADMIN_PATH = `/admin/${GALLERY_PATH_SEGMENT}`;

// Paths used when clicking on "Write post" in fully connected
export const GALLERY_POST_VIEW_PATH = '/:entityName/posts/:reportNameAndID';
export const GALLERY_POST_EDIT_PATH = `${GALLERY_POST_VIEW_PATH}/edit`; // redirects to view mode

export const REPORT_PAGE_VIEW_PATH =
  '/:entityName/:projectName/reports/:reportNameAndID';
export const REPORT_PAGE_EDIT_PATH = `${REPORT_PAGE_VIEW_PATH}/edit`;

// Deprecated report paths that redirect to /reportlist now
export const OLD_REPORT_PAGE_VIEW_PATH = '/:entityName/:projectName/published';
export const OLD_REPORT_PAGE_EDIT_PATH = '/:entityName/:projectName/reports';

// Old featured report paths that go through the fully connected paths now because
// there is no way to trigger create-discussion anymore
export const OLD_GALLERY_DISCUSSION_VIEW_PATH =
  '/:entityName/discussions/:reportNameAndID';
export const OLD_GALLERY_DISCUSSION_EDIT_PATH = `${OLD_GALLERY_DISCUSSION_VIEW_PATH}/edit`; // redirects to view mode

export const STORAGE_EXPLORER_TRACKED_PATH =
  '/usage/:entityName/tracked/:filePath*';
export const STORAGE_EXPLORER_COMPUTE_HOURS_PATH =
  '/usage/:entityName/computehour';
export const USAGE_PATH = '/usage/:entityName';

export const ORG_DASHBOARD = '/org/dashboard';
export const ORG_ROLES_NEW = envIsPublicCloud
  ? '/account-settings/:orgName/:tab/roles/new'
  : '/org/settings/roles/new';
export const ORG_ROLE_EDIT = envIsPublicCloud
  ? '/account-settings/:orgName/:tab/roles/:roleId/edit'
  : '/org/settings/roles/:roleId/edit';
export const ORG_PROXY = '/org/proxy';
export const ORG_TEAMS = '/org/teams';
export const ORG_USER_ACTIVITY = '/org/activity';
export const ORG_BILLING = '/org/billing';
export const ORG_SETTINGS = envIsPublicCloud
  ? '/account-settings/:orgName/settings'
  : '/org/settings';

export const ADMIN_GLUE = '/admin/glue';

export const ORG_SETTINGS_PATHS = [ORG_SETTINGS, ORG_ROLES_NEW, ORG_ROLE_EDIT];

export const ACCOUNT_SETTINGS_USAGE_PAGE_PATHS = [
  '/account-settings/:orgName/usage/manage',
  '/account-settings/:orgName/usage/manage/:entityName/:filePath*',
];

export const ACCOUNT_SETTINGS_PATHS = [
  '/billing',
  '/subscriptions',
  '/account-settings',
  '/account-setting',
  '/account-settings/:orgName',
  '/account-setting/:orgName',
  '/account-settings/:orgName/:tab',
  '/account-setting/:orgName/:tab',
  '/account-settings/:orgName/:tab/roles/new',
  '/account-settings/:orgName/:tab/roles/:roleId/edit',
];

export const HOME_PATH = '/home';
export const USER_SETTINGS_PATH = '/settings';
export const PROFILE_PATH = '/:entityName';
export const PROFILE_REPORTS_PATH = `${PROFILE_PATH}/reports`;
export const PROFILE_PROJECTS_PATH = `${PROFILE_PATH}/projects`;
export const PROFILE_STARS_PATH = `${PROFILE_PATH}/stars`;
export const TEAM_MEMBERS_PATH = `${PROFILE_PATH}/members`;
export const TEAM_SERVICE_ACCOUNTS_PATH = `${PROFILE_PATH}/service-accounts`;
export const TEAM_SETTINGS_PATH = `${PROFILE_PATH}/settings`;
// NOTE: These paths are risky since they overlap with the
// schema of PROJECT_BASE_PATH. It would be nice to always
// mediate variable route components with a static path. For
// example: `/:entityName/project/:projectName` instead of
// `/:entityName/:projectName`.

// Team-level registry paths
export const ENTITY_REGISTRY_ALL_TEAMS_PATHS = [
  '/teams/registry/',
  '/teams/registry/:registryName?',
];
export const ENTITY_REGISTRY_ACCESS_CONTROL_PATH =
  '/:entityName/registry/:registryName/access-control';
export const ENTITY_REGISTRY_PATHS = [
  ...ENTITY_REGISTRY_ALL_TEAMS_PATHS,
  '/:entityName/registry/',
  '/:entityName/registry/:registryName?',
  ENTITY_REGISTRY_ACCESS_CONTROL_PATH,
];

// Organization registry paths
// Deprecated registry paths that redirect to /orgs/:orgName/registry/:registryName now
export const OLD_ORG_REGISTRY_SETTINGS_PATH =
  '/registry/:registryName/settings';
export const OLD_ENTITY_REGISTRY_ALL_TEAMS_PATHS = [
  '/registry/',
  '/registry/:registryName?',
  OLD_ORG_REGISTRY_SETTINGS_PATH,
];
export const ORG_SCOPED_REGISTRY_SETTINGS_PATH = envIsPublicCloud
  ? '/orgs/:orgName/registry/:registryName/settings'
  : '/orgs/server/registry/:registryName/settings';
export const ORG_SCOPED_REGISTRY_PATHS = envIsPublicCloud
  ? ['/orgs/:orgName/registry/', '/orgs/:orgName/registry/:registryName?']
  : ['/orgs/server/registry/', '/orgs/server/registry/:registryName?'];
export const ORG_REGISTRY_PATHS = [
  ...OLD_ENTITY_REGISTRY_ALL_TEAMS_PATHS,
  ...ORG_SCOPED_REGISTRY_PATHS,
  ORG_SCOPED_REGISTRY_SETTINGS_PATH,
];
export const REGISTRY_PATHS = [...ENTITY_REGISTRY_PATHS, ...ORG_REGISTRY_PATHS];

export const ENTITY_LAUNCH_PATHS = [
  '/launch/',
  '/:entityName/launch',
  '/launch/agents',
  '/:entityName/launch/agents',
  '/launch/resources',
  '/:entityName/launch/resources',
];
export const AGENT_PATHS = [
  '/:entityName/launch/agents/:agentId',
  '/:entityName/launch/agents/:agentId/:tab',
];
export const QUEUE_PATHS = [
  '/:entityName/launch/:queueId',
  '/:entityName/launch/:queueId/:tab',
];

export const PROJECT_BASE_PATH = '/:entityName/:projectName';
export const QUICKSTART_PATH = '/quickstart';
export const ONBOARDING_FLOW_PATH = `/signup`;

export const WEAVE_TITLE = 'Weave';
export const WEAVE_SLUG = 'weave';
export const PROJECT_WEAVE_PATHS = [
  `/:entityName/:projectName/:tab(${WEAVE_SLUG})/:weaveTab/:itemName/versions/:version/:refExtra*`,
  `/:entityName/:projectName/:tab(${WEAVE_SLUG})/:weaveTab/:itemName`,
  `/:entityName/:projectName/:tab(${WEAVE_SLUG})/:weaveTab`,
  `/:entityName/:projectName/:tab(${WEAVE_SLUG})`,
];
export const PROJECT_PATHS = [
  PROJECT_BASE_PATH,
  '/:entityName/:projectName/:tab(overview|workspace|table|reportlist|sweeps|launch|jobs|automations|artifacts|charts)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName(new)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCommitHash(new)',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/_view/:artifactCollectionView',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/:artifactCommitHash/:artifactTab(files)/:filePath*',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName/:artifactCommitHash/:artifactTab?',
  '/:entityName/:projectName/:tab(artifacts)/:artifactTypeName/:artifactCollectionName',
  '/:entityName/:projectName/:tab(launch)/:launchTab?',
  '/:entityName/:projectName/:tab(jobs)/:jobId',
  '/:entityName/:projectName/:tab(jobs)/:jobId/:jobTab(runs|job_details|version_details)/:jobVersion?',
  '/:entityName/:projectName/panel/:panelId',
  ...PROJECT_WEAVE_PATHS,
];
export const PROJECT_OVERVIEW_PATH = '/:entityName/:projectName/overview';

export const REDIRECT_PATH = '/:entityName/:projectName/r/:redirectPath*';

export const ARTIFACT_VERSION_PATH =
  '/:entityName/:projectName/artifacts/:artifactTypeName/:artifactCollectionName/:artifactCommitHash';
export const RUN_PATHS = [
  '/:entityName/:projectName/runs/:runName',
  '/:entityName/:projectName/runs/:runName/:tab(files|code)/:filePath*',
  '/:entityName/:projectName/runs/:runName/:tab',
  '/:entityName/:projectName/runs/:runName/panel/:panelId',
];
export const GROUP_PATHS = [
  '/:entityName/:projectName/groups/:groupName',
  '/:entityName/:projectName/groups/:groupName/:tab(files)/:filePath*',
  '/:entityName/:projectName/groups/:groupName/:tab',
];
export const SWEEP_PATHS = [
  '/:entityName/:projectName/sweeps/:sweepName',
  '/:entityName/:projectName/sweeps/:sweepName/:tab',
];
export const BENCHMARK_PATHS = [
  '/:entityName/:projectName/benchmark',
  '/:entityName/:projectName/benchmark/:tab/:threadID?',
];

export const NO_ACCESS_PATH = '/no-access';

export const ANALYZE_MY_EXPERIMENTS_PATH = '/analyze_my_experiments';

export const TRIAL_END_PATH = '/trial_end';

export const CREATE_TEAM_PATH = '/create-team';

export const ACADEMIC_APPLICATION_PATH = '/academic_application';

export enum UsageTabURLPart {
  TRACKED_HOURS = 'tracked-hours',
  STORAGE = 'storage',
  WEAVE = 'weave',
}

export const GLOBAL_HISTORIC_USAGE_REDIRECT_PATH = '/orgs/server/usage';
export const GLOBAL_HISTORIC_USAGE_REDIRECT_TO_PATH = `/orgs/server/usage/${UsageTabURLPart.STORAGE}`;
export const GLOBAL_HISTORIC_USAGE_PATH = '/orgs/server/usage/:tab';

export const HISTORIC_USAGE_REDIRECT_PATH = '/:orgName/usage';
export const HISTORIC_USAGE_REDIRECT_TO_PATH = `/:orgName/usage/${UsageTabURLPart.STORAGE}`;
export const HISTORIC_USAGE_PATH = '/:orgName/usage/:tab';

export enum UTM_Source {
  FREE_PLAN = 'free-plan',
  APP_RESOURCE_CENTER = 'app-resource-center',
}

export enum UTM_Content {
  LEFT_NAV = 'left_nav',
}

export enum UTM_Medium {
  APP = 'app',
}

export enum UTM_Term {
  DOCS = 'docs',
  QUICKSTART = 'quickstart',
  FULLY_CONNECTED = 'fully-connected',
}
