import {GraphQLError} from 'graphql';

import {captureError} from '../../../integrations';
import {getErrMsgFromError} from '../../../util/errors';

type HandleMutationErrorProps = {
  analyticsLocation: string;
  err: any;
  errLabel: string;
  errExtra?: {[key: string]: any};
  trackingEventName: string;
  trackingOrgName: string | null;
  setErrorMsg: (m: string | null) => void;
};

export function handleMutationError({
  analyticsLocation,
  err,
  errLabel,
  errExtra,
  trackingEventName,
  trackingOrgName,
  setErrorMsg,
}: HandleMutationErrorProps) {
  captureError(err, errLabel, {extra: errExtra});
  const errMsg = getErrMsgFromError(err);
  setErrorMsg(
    `Error creating team${
      errMsg != null ? ` (${errMsg})` : ''
    }. Please try again.`
  );
  window.analytics?.track(trackingEventName, {
    organizationName: trackingOrgName,
    location: analyticsLocation,
    error: errMsg,
  });
}

type HandleGQLErrorsProps = {
  analyticsLocation: string;
  errors: GraphQLError[];
  errLabel: string;
  errExtra?: {[key: string]: any};
  trackingEventName: string;
  trackingOrgName: string | null;
  setErrorMsg: (m: string | null) => void;
};

export function handleGQLErrors({
  analyticsLocation,
  errors,
  errLabel,
  errExtra,
  trackingEventName,
  trackingOrgName,
  setErrorMsg,
}: HandleGQLErrorsProps) {
  const collatedErrMsg = errors.map(e => e.message).join(', ');
  captureError(collatedErrMsg, errLabel, {
    extra: errExtra,
  });
  setErrorMsg('Error creating team');
  window.analytics?.track(trackingEventName, {
    organizationName: trackingOrgName,
    location: analyticsLocation,
    error: collatedErrMsg,
  });
}
