import React, {useState} from 'react';

import {useMessageOfTheDayQuery} from '../generated/graphql';
import {safeLocalStorage} from '../util/localStorage';
import NudgeBar from './NudgeBar';

const MessageOfTheDay: React.FC = () => {
  const {data} = useMessageOfTheDayQuery();

  const lastMessageDismissed = safeLocalStorage.getItem(
    'lastMessageDismissedText'
  );

  const message = data?.serverInfo?.messageOfTheDay;

  if (!message || lastMessageDismissed === message) {
    return null;
  }
  return <MessageOfTheDayLoaded message={message} />;
};

export default MessageOfTheDay;

type MessageOfTheDayLoadedProps = {
  message: string;
};

const MessageOfTheDayLoaded: React.FC<MessageOfTheDayLoadedProps> = ({
  message,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    safeLocalStorage.setItem('lastMessageDismissedText', message);
    setOpen(false);
  };
  if (!open) {
    return null;
  }

  return (
    <NudgeBar
      content={message}
      trackingLocation={'message of the day'}
      showDismissOption={true}
      onClose={handleClose}
    />
  );
};
