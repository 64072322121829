import React, {useCallback, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';

import {SettingType} from '../../../../services/analytics/workspaceSettingsEvents';
import {
  smoothingOptionsMeta,
  SmoothingTypeValues,
} from '../../../elements/SmoothingConfig';
import {ClearSettingsRow} from '../components/ClearSettingsRow';
import {ResetSettingButton} from '../components/ResetSettingButton';
import {SettingLabel} from '../components/SettingLabel';
import {SettingSelect} from '../components/SettingSelect';
import {SettingSlider} from '../components/SettingSlider';
import {ClearSettingFnType} from '../hooks/useSettingOverrides';
import {LinePlotSettings} from '../types';
import {DEFAULT_SMOOTHING_SETTINGS} from './linePlotDefaults';

const EMPTY_SMOOTHING_SETTINGS = {
  smoothingType: undefined,
  smoothingWeight: undefined,
};

type SmoothingSelectOption = {
  value: SmoothingTypeValues;
  label: string;
};

type SmoothingMetadataType = {
  defaultValue: number;
  min: number;
  max: number;
  step: number;
};

export type LinePlotSmoothingProps = {
  className?: string;
  /**
   * Whether the smoothing setting is inherited from workspace or defined at the section level.
   * If value is inherited, we want to disable the reset button.
   */
  isInheritedValue?: boolean;
  smoothingWeight?: number;
  smoothingType?: SmoothingTypeValues;
  numOverrides?: {
    sections?: number;
    panels?: number;
  };
  type: 'workspace' | 'section' | 'report' | 'panel';
  /**
   * We don't support clearing value at panel level yet, so it's an optional prop
   */
  clearSetting?: ClearSettingFnType;
  trackSetting: (setting: SettingType, value: string | undefined) => void;
  updateSetting: (
    settings: Pick<LinePlotSettings, 'smoothingType' | 'smoothingWeight'>
  ) => void;
};

export const LinePlotSmoothing = ({
  className,
  isInheritedValue,
  smoothingWeight,
  smoothingType,
  numOverrides,
  type,
  clearSetting,
  updateSetting,
  trackSetting,
}: LinePlotSmoothingProps) => {
  const smoothingOptions = useMemo(() => getSmoothingOptions(), []);

  const smoothingTypeOption = useMemo(() => {
    if (smoothingType == null) {
      return {
        value: DEFAULT_SMOOTHING_SETTINGS.smoothingType,
        label: getSmoothingTypeDisplayName(
          DEFAULT_SMOOTHING_SETTINGS.smoothingType
        ),
      };
    }
    return {
      value: smoothingType,
      label: getSmoothingTypeDisplayName(smoothingType),
    };
  }, [smoothingType]);

  const onSmoothingTypeChange = useCallback(
    (selected: SmoothingSelectOption | null) => {
      if (selected != null) {
        updateSetting({
          smoothingType: selected.value,
          smoothingWeight: getSmoothingMetadata(selected).defaultValue, // reset
        });
        trackSetting('smoothingType', selected.value.toString());
      }
    },
    [trackSetting, updateSetting]
  );

  const clearSettingRows = useMemo(() => {
    if (clearSetting == null) {
      return [];
    }

    const rows = [];
    if (numOverrides?.sections != null && numOverrides.sections > 0) {
      rows.push({
        type: 'section',
        numOverrides: numOverrides.sections,
        clearSetting: () =>
          clearSetting('section', 'smoothing', EMPTY_SMOOTHING_SETTINGS),
      });
    }
    if (numOverrides?.panels != null && numOverrides.panels > 0) {
      rows.push({
        type: 'panel',
        numOverrides: numOverrides.panels,
        clearSetting: () =>
          // Reset to all undefined so cascading settings is propoagated properly
          clearSetting('panel', 'smoothing', EMPTY_SMOOTHING_SETTINGS),
      });
    }
    return rows;
  }, [clearSetting, numOverrides]);

  const isSmoothingTypeNone = smoothingTypeOption.value === 'none';

  return (
    <div className={twMerge('flex flex-col', className)}>
      <div className="flex w-full">
        <SettingLabel text="Smoothing" className="shrink-0" />
        <div className="item-center flex w-full flex-row gap-8">
          <SettingSelect
            className="grow"
            options={smoothingOptions}
            value={smoothingTypeOption}
            onChange={onSmoothingTypeChange}
          />
          {clearSetting != null && isInheritedValue != null && (
            <ResetSettingButton
              isActive={isInheritedValue}
              onButtonClick={() =>
                clearSetting(type, 'smoothing', EMPTY_SMOOTHING_SETTINGS)
              }
            />
          )}
        </div>
      </div>
      <div
        className={twMerge(
          'flex flex-col',
          'group-[.is-panel-config]:text-sm', // account for panel config - font size is smaller in there than slider
          isSmoothingTypeNone ? '' : 'ml-[7rem] mt-12'
        )}>
        {!isSmoothingTypeNone && (
          <SmoothingSettingSlider
            smoothingWeight={smoothingWeight}
            smoothingTypeOption={smoothingTypeOption}
            updateSetting={updateSetting}
            trackSetting={trackSetting}
          />
        )}
        <div
          className={twMerge(
            isSmoothingTypeNone && clearSettingRows.length > 0 ? 'mt-12' : ''
          )}>
          {clearSettingRows.map(row => {
            return (
              <ClearSettingsRow
                setting="smoothing"
                key={`clear-${row.type}-row`}
                count={row.numOverrides}
                type={row.type}
                clearSetting={row.clearSetting}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SmoothingSettingSlider = ({
  smoothingWeight,
  smoothingTypeOption,
  updateSetting,
  trackSetting,
}: {
  smoothingWeight: number | undefined;
  smoothingTypeOption: SmoothingSelectOption;
  updateSetting: (settings: Partial<LinePlotSettings>) => void;
  trackSetting: (setting: SettingType, value: string | undefined) => void;
}) => {
  const {min, max, step, defaultValue} = useMemo(
    () => getSmoothingMetadata(smoothingTypeOption),
    [smoothingTypeOption]
  );

  const updateSettingValue = useCallback(
    (newVal: number) => {
      updateSetting({
        smoothingWeight: newVal,
      });
      trackSetting('smoothingWeight', newVal.toString());
    },
    [trackSetting, updateSetting]
  );

  return (
    <SettingSlider
      name="smoothing"
      min={min}
      max={max}
      step={step}
      sliderValue={smoothingWeight == null ? defaultValue : smoothingWeight}
      updateSetting={updateSettingValue}
    />
  );
};

const getSmoothingTypeDisplayName = (smoothingType: SmoothingTypeValues) => {
  return smoothingOptionsMeta[smoothingType].displayName;
};

const getSmoothingOptions = (): SmoothingSelectOption[] => {
  return Object.keys(smoothingOptionsMeta).map(val => ({
    label: getSmoothingTypeDisplayName(val as SmoothingTypeValues),
    value: val as SmoothingTypeValues,
  }));
};

const getSmoothingMetadata = (
  smoothingOption: SmoothingSelectOption
): SmoothingMetadataType => {
  const data = smoothingOptionsMeta[smoothingOption.value].defaults;
  return {
    defaultValue: data.default,
    min: data.min,
    max: data.max,
    step: data.step,
  };
};
