import React, {forwardRef, memo} from 'react';

import * as S from './ActiveRunIndicator.styles';

const ActiveRunIndicatorComp = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <S.Wrapper ref={ref} {...props}>
      <S.Dot />
    </S.Wrapper>
  );
});

const ActiveRunIndicator = memo(ActiveRunIndicatorComp);

export default ActiveRunIndicator;
