import React, {FC, memo} from 'react';

import {
  tagFromQS,
  useDiscussionTag,
  usePostTag,
} from '../../../../util/gallery';
import {isUsingAccessToken} from '../../../../util/projectAccess';
import * as URL from '../../../../util/urls';
import {useNavContext} from '../../../NavContextProvider';
import NavBreadcrumbsDefault from './NavBreadcrumbsDefault';
import NavBreadcrumbsFCPost from './NavBreadcrumbsFCPost';
import NavBreadcrumbsFCReport from './NavBreadcrumbsFCReport';
import NavBreadcrumbsPublishedReport from './NavBreadcrumbsPublishedReport';
import NavBreadcrumbsSEO from './NavBreadcrumbsSEO';
import NavBreadcrumbsWeave, {
  NavBreadcrumbsWeaveProps,
} from './NavBreadcrumbsWeave';
import {getSEOBreadcrumbs, useProjObjType, useReportNameAndID} from './utils';

const NavBreadcrumbsComp: FC = () => {
  const {params} = useNavContext();
  const {entityName, projectName, reportNameAndID} = params;
  const postTag = usePostTag();
  const discussionTag = useDiscussionTag();
  const galleryTag = tagFromQS();
  const projObjType = useProjObjType();
  const {reportName, reportID} = useReportNameAndID({
    reportNameAndID,
    projObjType,
  });

  if (entityName == null) {
    return null;
  }

  // HAX: this works with host/src/report.ts to ensure the app does not override custom SEO breadcrumbs
  const seoBreadcrumbs = getSEOBreadcrumbs();
  if (seoBreadcrumbs != null) {
    return <NavBreadcrumbsSEO breadcrumbs={seoBreadcrumbs} />;
  }

  if (URL.isOnReportView() && isUsingAccessToken()) {
    return null;
  }

  if (
    projectName == null &&
    reportNameAndID != null &&
    !URL.isOnPublishedReportView() &&
    postTag != null &&
    discussionTag != null
  ) {
    const isDiscussion = URL.isOnGalleryDiscussionPage();
    return (
      <NavBreadcrumbsFCPost
        entityName={entityName}
        reportID={reportID}
        reportName={reportName}
        tag={isDiscussion ? discussionTag : postTag}
        isDiscussion={isDiscussion}
      />
    );
  }

  if (reportNameAndID != null && galleryTag != null) {
    return (
      <NavBreadcrumbsFCReport
        entityName={entityName}
        projectName={projectName}
        reportID={reportID}
        reportName={reportName}
        galleryTag={galleryTag}
      />
    );
  }

  if (URL.isOnPublishedReportView() && entityName != null) {
    return (
      <NavBreadcrumbsPublishedReport
        entityName={entityName}
        reportID={reportID}
        reportName={reportName}
      />
    );
  }

  if (URL.isOnWeavePage() && entityName != null && projectName != null) {
    const weaveParams: NavBreadcrumbsWeaveProps = params as any;
    return (
      <NavBreadcrumbsWeave
        entityName={entityName}
        projectName={projectName}
        weaveTab={weaveParams.weaveTab}
        itemName={weaveParams.itemName}
        version={weaveParams.version}
        refExtra={weaveParams.refExtra}
      />
    );
  }

  return (
    <NavBreadcrumbsDefault
      entityName={entityName}
      reportName={reportName}
      reportID={reportID}
      projObjType={projObjType}
    />
  );
};

const NavBreadcrumbs = memo(NavBreadcrumbsComp);

export default NavBreadcrumbs;
