import React, {useContext} from 'react';

import {AccountSelectorContext} from '../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../components/Search/SearchNav/types';
import {
  useStarProjectMutation,
  useStarredProjectsQuery,
  useUnstarProjectMutation,
} from '../generated/graphql';
import {AccountProjectData} from '../pages/HomePage/HomePageContent/util';
import {Analytics} from '../services/analytics';
import {doNotRetryContext, propagateErrorsContext} from './errors';
import {useRampFlagAccountSelector} from './rampFeatureFlags';

export const StarredProjectsContext = React.createContext({
  starredProjects: {} as Record<string, AccountProjectData>,
  onStarClick: (
    project: AccountProjectData,
    starred: boolean,
    analyticsLocation: string
  ) => {},
  isLoading: true,
  refetch: () => {},
});

export const StarredProjectsContextProvider: React.FC = ({children}) => {
  const enableAccountSelector = useRampFlagAccountSelector();
  const {selectedAccount} = useContext(AccountSelectorContext);
  const orgID =
    selectedAccount?.accountType !== AccountType.Personal
      ? selectedAccount?.id
      : undefined;
  const starredProjectsQuery = useStarredProjectsQuery({
    variables: {
      organizationId: enableAccountSelector ? orgID : undefined,
    },
  });
  const [starredProjects, setStarredProjects] = React.useState(
    {} as Record<string, AccountProjectData>
  );
  const isLoading = starredProjectsQuery.loading;
  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    const projects =
      starredProjectsQuery.data?.viewer?.starredProjects?.edges ?? [];
    setStarredProjects(
      Object.fromEntries(
        projects.filter(p => p.node).map(p => [p.node!.id, p.node!])
      )
    );
  }, [starredProjectsQuery, isLoading]);

  const [starProjectMutation] = useStarProjectMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
  });
  const [unstarProjectMutation] = useUnstarProjectMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
  });
  const onStarClick = async (
    project: AccountProjectData,
    starred: boolean,
    analyticsLocation: string
  ) => {
    if (starred) {
      const newStarredProjects = {...starredProjects};
      // This will get filled out with the full data after refetch
      newStarredProjects[project.id] = project;
      setStarredProjects(newStarredProjects);
      await starProjectMutation({
        variables: {
          entityName: project.entityName,
          projectName: project.name,
          organizationId: enableAccountSelector ? orgID : undefined,
        },
      });
      Analytics.track('Project star added', {
        projectId: project.id,
        location: analyticsLocation,
      });
    } else {
      const newStarredProjects = {...starredProjects};
      delete newStarredProjects[project.id];
      setStarredProjects(newStarredProjects);
      await unstarProjectMutation({
        variables: {
          entityName: project.entityName,
          projectName: project.name,
          organizationId: enableAccountSelector ? orgID : undefined,
        },
      });
      Analytics.track('Project star removed', {
        projectId: project.id,
        location: analyticsLocation,
      });
    }
  };

  return (
    <StarredProjectsContext.Provider
      value={{
        starredProjects,
        onStarClick,
        isLoading,
        refetch: starredProjectsQuery.refetch,
      }}>
      {children}
    </StarredProjectsContext.Provider>
  );
};
