import {ID} from '@wandb/weave/common/util/id';
import {Buffer} from 'buffer';
import fnv from 'fnv-plus';
import {sortBy} from 'lodash';
import {useContext, useMemo} from 'react';

import {ActiveExperiment, ActiveVariant} from '../../generated/graphql';
import {safeLocalStorage} from '../../util/localStorage';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ExperimentContext} from './ExperimentContextProvider';
import {
  DEFAULT_EXPERIMENT_DATA,
  ExperimentConfig,
  ExperimentData,
  ExperimentQueryParams,
} from './types';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {validateExperimentVariants} from './validation';

export const getExperimentData = (
  activeExperiment: ActiveExperiment,
  observationalId: string,
  controlBucketValue: number
): ExperimentData => {
  const {
    id: graphqlExperimentId,
    name: experimentName,
    startAt,
    endAt,
    activeVariants,
  } = activeExperiment;
  const experimentQueryParams: ExperimentQueryParams =
    getExperimentQueryParams();

  // Default to control if observationalUnitId is not populated
  const observationalUnitId = observationalId ?? '';

  const defaultExperimentData: ExperimentData = {
    ...DEFAULT_EXPERIMENT_DATA,
    observationalUnitId,
    graphqlExperimentId,
    experimentName,
  };

  // Force-bucketing an experiment by using query params
  if (hasValidVariantQueryParams(graphqlExperimentId, experimentQueryParams)) {
    return {
      ...defaultExperimentData,
      treatment: parseInt(experimentQueryParams.experimentBucketParam, 10),
    };
  }

  const isExperimentRunning = startAt != null && endAt == null;
  if (!isExperimentRunning || observationalUnitId === '') {
    // Default to control when observationalUnitId is invalid
    return {
      ...defaultExperimentData,
      treatment: controlBucketValue,
    };
  }

  // hash the graphqlExperimentId and observationalUnitId to assign a treatment. The experimentId is the "salt"
  // that prevents users from consistently being bucketed into the same variants across different experiments
  const hash = Number(
    fnv.hash(graphqlExperimentId + observationalUnitId).dec()
  );
  const assignedBucket = getAssignedBucket(activeVariants, hash);
  if (assignedBucket == null) {
    // Default to control
    return {
      ...defaultExperimentData,
      hash,
      treatment: controlBucketValue,
    };
  }

  return {
    ...defaultExperimentData,
    hash,
    treatment: assignedBucket,
    log: true,
  };
};

const getExperimentQueryParams = (): ExperimentQueryParams => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    experimentIdParam: queryParams.get('experimentId') ?? '',
    experimentBucketParam: queryParams.get('experimentBucket') ?? '',
  };
};

const hasValidVariantQueryParams = (
  experimentId: string,
  experimentQueryParams: ExperimentQueryParams
): boolean => {
  const {experimentIdParam, experimentBucketParam} = experimentQueryParams;

  if (
    experimentIdParam !== experimentId ||
    experimentBucketParam == null ||
    isNaN(parseInt(experimentBucketParam, 10))
  ) {
    return false;
  }

  return true;
};

const getAssignedBucket = (
  experimentVariants: ActiveVariant[],
  hash: number
): number | null => {
  const assignment = hash % 100;

  // determine which variant to use based on the assignment and variant allocation
  const sortedVariants = sortBy(experimentVariants, ['bucket']);
  let allocation = 0;
  for (const v of sortedVariants) {
    allocation += v.allocation;
    if (assignment < allocation) {
      return v.bucket;
    }
  }

  return null;
};

/**
 * Decode graphql experiment id to database id in experiments table.
 * Note: it will be prefixed with "Experiment:"
 */
export function decodedExperimentId(graphqlExperimentId: string) {
  return Buffer.from(graphqlExperimentId, 'base64')
    .toString()
    .replace('Experiment:', '');
}

export const useExperiment = (
  config: ExperimentConfig,
  observationalUnitId: string
): {
  isLoading: boolean;
  error: boolean;
  activeExperiment?: ActiveExperiment;
  experimentData?: ExperimentData;
} => {
  const {activeExperiments, isExperimentsQueryLoading} =
    useContext(ExperimentContext);

  return useMemo(() => {
    if (isExperimentsQueryLoading) {
      return {
        isLoading: true,
        error: false,
        activeExperiment: undefined,
        experimentData: undefined,
      };
    }
    return getExperimentStateInternal(
      config,
      activeExperiments,
      observationalUnitId
    );
  }, [
    config,
    isExperimentsQueryLoading,
    activeExperiments,
    observationalUnitId,
  ]);
};

export const getExperimentStateInternal = (
  config: ExperimentConfig,
  activeExperiments: ActiveExperiment[],
  observationalUnitId: string
):
  | {isLoading: false; error: true}
  | {isLoading: true; error: false}
  | {
      isLoading: false;
      error: false;
      activeExperiment: ActiveExperiment;
      experimentData: ExperimentData;
    } => {
  const activeExperiment = activeExperiments.find(
    e => e.id === config.experimentGraphqlId
  );

  if (!activeExperiment) {
    return {
      isLoading: false,
      error: true,
    };
  }

  validateExperimentVariants(activeExperiment, config.bucketValues);

  const experimentData = getExperimentData(
    activeExperiment,
    observationalUnitId,
    config.controlBucketValue
  );
  return {isLoading: false, error: false, activeExperiment, experimentData};
};

/**
 * For an experiment, retrieve the current treatment.
 * Note that this does not try to handle loading or error states,
 * it just returns control bucket in that case.
 *
 * It also relies on the ExperimentContextProvider having been
 * used, but this should always be true unless you are really
 * early in web app initialization.
 */

export const getExperimentTreatment = (
  config: ExperimentConfig,
  observationalUnitId: string
): number => {
  const ret = getExperimentStateInternal(
    config,
    ActiveExperimentsGlobal.activeExperiments,
    observationalUnitId
  );

  return ret.error || ret.isLoading
    ? config.controlBucketValue
    : ret.experimentData.treatment;
};

const ActiveExperimentsGlobal: {activeExperiments: ActiveExperiment[]} = {
  activeExperiments: [],
};

export const updateActiveExperimentsGlobal = (
  activeExperiments: ActiveExperiment[]
) => {
  ActiveExperimentsGlobal.activeExperiments = activeExperiments;
};

const ANONYMOUS_OBSERVATIONAL_UNIT_ID_KEY = 'anonymousObservationalUnitID';
export function getAnonymousObservationalUnitID(): string {
  const key = ANONYMOUS_OBSERVATIONAL_UNIT_ID_KEY;

  const stored = safeLocalStorage.getItem(key);
  if (stored) {
    return stored;
  }

  const id = ID();
  safeLocalStorage.setItem(key, id);
  return id;
}
