import * as ReportViewTypes from '../views/report/types';
import * as ReportDraftViewTypes from '../views/reportDraft/types';

export const REPORT_VIEW_TYPE = 'runs';
export const REPORT_DRAFT_VIEW_TYPE = 'runs/draft';

export type ReportViewRef =
  | ReportViewTypes.ViewRef
  | ReportDraftViewTypes.ViewRef;

export type ReportRef = ReportViewTypes.Ref | ReportDraftViewTypes.Ref;

export interface StateType {
  viewRefs: {[id: string]: ReportViewRef};
  pushedRef: string | null;
  loading: boolean;
  noAccess: boolean;
}

export interface PushReportOpts {
  redirectQS?: {[key: string]: string | null};
  newTab?: true;
}
