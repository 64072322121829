import {notEmpty} from '@wandb/weave/common/util/obj';
import gql from 'graphql-tag';
import _ from 'lodash';
import {useMemo, useRef} from 'react';

import * as Generated from '../../generated/graphql';
import {EMPTY_ARRAY} from '../../util/constants';
import {propagateErrorsContext} from '../../util/errors';
import * as Run from '../../util/runs';

export const PROJECT_FIELDS_QUERY = gql`
  query ProjectFields(
    $projectName: String!
    $entityName: String!
    $types: [String!]!
    $columns: [String!]!
    $pattern: String
    $count: Int!
    $cursor: String
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      fields(
        types: $types
        columns: $columns
        pattern: $pattern
        first: $count
        after: $cursor
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            path
            type
          }
        }
      }
    }
  }
`;

export interface ProjectData {
  id: string;
  fields: {
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        path: string;
        type: string;
      };
    }>;
  };
}

export interface ProjectFieldsQueryData {
  project: ProjectData;
}

export function useProjectFieldsQuery(
  variables: Generated.ProjectFieldsQueryVariables & {skip?: boolean}
): {loading: boolean; keys: string[]} {
  const {skip, ...gqlVars} = variables;
  const {loading, data} = Generated.useProjectFieldsQuery({
    fetchPolicy: 'network-only',
    skip,
    variables: gqlVars,
    context: propagateErrorsContext(),
  });
  const fieldsRef = useRef<readonly string[]>(EMPTY_ARRAY);

  const dataFields = useMemo(() => {
    const project = data?.project;
    if (skip || loading || project == null) {
      return EMPTY_ARRAY;
    }
    return _.uniq(project.fields.edges.map(e => e.node.path));
  }, [skip, loading, data]);

  if (!loading) {
    fieldsRef.current = dataFields;
  }

  const fields = fieldsRef.current;

  const keys = useMemo(() => {
    const filtered = fields
      .map(f => Run.serverPathToKeyString(f))
      .filter(notEmpty)
      .filter(k => !k.startsWith('config:_wandb'))
      .sort(Run.compareKeyStrings);
    return filtered;
  }, [fields]);

  return {loading, keys};
}
