import {getCookie} from '@wandb/weave/common/util/cookie';
import {useLoadWeaveObjects} from '@wandb/weave/components/Panel2/weaveBackend';
import {ComputeGraphContextProviderFromClient} from '@wandb/weave/contextProviders';
import {
  BasicClient,
  bindClientToGlobalThis,
  CachedClient,
  Client,
  createLocalServer,
  createRemoteServer,
  createServerWithShadow,
  LocalServer,
  LOG_DEBUG_MESSAGES,
  OpStore,
  Server,
} from '@wandb/weave/core';
import {GlobalCGReactTracker} from '@wandb/weave/react';
import {mapKeys, omit} from 'lodash';
import React, {useMemo} from 'react';

import * as ServerApiProd from './backendProviders/serverApiProd';
import config, {
  backendWeaveExecutionUrl,
  backendWeaveHost,
  serverWeave1Percentage,
  serverWeaveShadowPercentage,
} from './config';
import {auth} from './setup';
import {useApolloClient} from './state/hooks';
import {useViewer} from './state/viewer/hooks';
import {viewerUsingAdminPrivileges} from './util/admin';
import {useBetaFeatureWeave1} from './util/useBetaFeature';

const useClient = (server: Server): Client => {
  return useMemo(() => {
    let client: Client = new BasicClient(server);
    if (!(server instanceof LocalServer)) {
      client = new CachedClient(client);
    }
    bindClientToGlobalThis(client);
    return client;
  }, [server]);
};

const useLocalServer = () => {
  const apolloClient = useApolloClient();
  return useMemo(() => {
    const serverApi = new ServerApiProd.Client(apolloClient);
    return createLocalServer(serverApi);
  }, [apolloClient]);
};

const useRemoteServer = (skip: boolean, opStore?: OpStore) => {
  const {loading, remoteOpStore} = useLoadWeaveObjects(skip || !!opStore);
  return useMemo(() => {
    const finalStore: OpStore | null = opStore || remoteOpStore;

    if (!finalStore || (!opStore && loading) || skip) {
      return null;
    }

    return createRemoteServer(
      backendWeaveExecutionUrl(),
      auth.ensureCurrentIdToken.bind(auth),
      finalStore,
      viewerUsingAdminPrivileges(),
      false,
      getCookie('anon_api_key'),
      // we force the use of window.fetch here because we
      // need to use the version of fetch that datadog and
      // other tracing tools have instrumented
      (input, init) => window.fetch(input, init)
    );
  }, [loading, remoteOpStore, opStore, skip]);
};

const useRemoteShadowServer = (skip: boolean, opStore: OpStore) => {
  const remoteServer = useMemo(() => {
    return createRemoteServer(
      backendWeaveExecutionUrl(true),
      auth.ensureCurrentIdToken.bind(auth),
      opStore,
      viewerUsingAdminPrivileges(),
      true,
      getCookie('anon_api_key'),
      // we force the use of window.fetch here because we
      // need to use the version of fetch that datadog and
      // other tracing tools have instrumented
      (input, init) => window.fetch(input, init)
    );
  }, [opStore]);
  return skip ? null : remoteServer;
};

const useServerWithShadow = (
  mainServer: Server,
  shadowServer: Server | null
) => {
  return useMemo(() => {
    if (!shadowServer) {
      return null;
    }
    return createServerWithShadow(mainServer, shadowServer);
  }, [mainServer, shadowServer]);
};

export enum WeaveVersion {
  V0 = 0,
  V1 = 1,
}

type WeaveServer = [Server, WeaveVersion];
/**
 * Note: this is the best way to determine if you're in a Weave 1 environment.
 * Use the WeaveVersion enum coming back as the second element of the tuple.
 */
export const useWeaveServer = (): WeaveServer => {
  const viewer = useViewer();
  const hasRemote = !!backendWeaveHost();
  const featureFlagSetting = useBetaFeatureWeave1();

  // shadow is always enabled if not onprem
  // or if onprem and within the shadow percentage(this still relies on the shadow backend to be setup)
  // we require there to be a viewer to enable shadow (this also fixes an error where anonymous users would 403 in shadow)
  const shadowEnabled =
    (!config.ENVIRONMENT_IS_PRIVATE ||
      Math.random() * 100 < serverWeaveShadowPercentage()) &&
    viewer;

  // weave1 is enabled onprem if onprem and the chance is within the weave1 percentage enabled
  const isWeave1EnabledOnPrem =
    config.ENVIRONMENT_IS_PRIVATE &&
    Math.random() * 100 < serverWeave1Percentage();

  // weave1 is enabled if:
  const weave1Enabled =
    // the user is NOT an employee who has manually enabled weave0
    featureFlagSetting &&
    // the env has a backend server setup -> hasRemote/!!backendWeaveHost()
    hasRemote &&
    // and not onprem or the onprem weave1 flag is true -> isWeave1EnabledOnPrem
    (!config.ENVIRONMENT_IS_PRIVATE || isWeave1EnabledOnPrem);

  const localServer = useLocalServer();

  // This will be null if shadow is disabled or no remote server is available
  const remoteShadowServer = useRemoteShadowServer(
    !(hasRemote && shadowEnabled),
    localServer.opStore
  );

  // This will be null if weave1 is disabled or no remote server is available
  const remoteExecutionServer = useRemoteServer(
    !(hasRemote && weave1Enabled),
    localServer.opStore
  );

  // This will be null if no shadow server is available
  const localServerWithShadow = useServerWithShadow(
    localServer,
    remoteShadowServer
  );

  // Only return if weave1 is enabled and there is a remote server
  if (weave1Enabled && remoteExecutionServer) {
    return [remoteExecutionServer, WeaveVersion.V1];
  }

  // Only return if shadow is enabled and there is a remote shadow server
  // to the client the weave server is still v0
  if (shadowEnabled && localServerWithShadow) {
    return [localServerWithShadow, WeaveVersion.V0];
  }

  return [localServer, WeaveVersion.V0];
};

export const ComputeGraphContextProvider: React.FC = React.memo(
  ({children}) => {
    const [server] = useWeaveServer();
    const client = useClient(server);
    return (
      <ComputeGraphContextProviderFromClient
        client={client}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />
    );
  }
);

//// Logging Execution Metrics ////

const globalCGReactTrackerInterval = 30000;
let hasStarted = false;

const sumDict = (dict: {[key: string]: any}): number => {
  return Object.values(dict).reduce(
    (acc, val) => acc + (typeof val === 'number' ? val : sumDict(val)),
    0
  );
};

const flattenDict = (dict: {[key: string]: any}): {[key: string]: number} => {
  let flatDict: {[key: string]: number} = {};
  for (const key in dict) {
    if (typeof dict[key] === 'number') {
      flatDict[key] = dict[key];
    } else {
      flatDict = {
        ...flatDict,
        ...mapKeys(flattenDict(dict[key]), (v, k) => `${key}.${k}`),
      };
    }
  }
  return flatDict;
};

const logGlobalCGReactTracker = () => {
  hasStarted = true;
  const summary = GlobalCGReactTracker.summary();
  // Ignore duration (since it is always non-negative) and _0_useNodeValue since
  // this has super high volume, but high cache rates. This will reduce the number
  // of log events produced, but not the accuracy of the metrics.
  const sumOfValues = sumDict(omit(summary, ['__duration', '_0_useNodeValue']));
  if (sumOfValues > 0) {
    if (LOG_DEBUG_MESSAGES) {
      console.log('cg-react-tracker', flattenDict(summary));
    }
    window.analytics?.track('cg-react-tracker', summary);
    GlobalCGReactTracker.reset();
  }
  setTimeout(logGlobalCGReactTracker, globalCGReactTrackerInterval);
};

if (!hasStarted) {
  setTimeout(logGlobalCGReactTracker, globalCGReactTrackerInterval);
}
