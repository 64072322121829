import {Select} from '@wandb/weave/components/Form/Select';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {Maybe} from 'graphql/jsutils/Maybe';
import React, {FC} from 'react';
import {components, MultiValue, OptionProps} from 'react-select';
import {FilterOptionOption} from 'react-select/dist/declarations/src/filters'; // eslint-disable-line import/no-unresolved
// eslint-disable-next-line wandb/no-deprecated-imports
import {Image} from 'semantic-ui-react';

import {useGetRoleOptions} from '../../../components/MembersTable/TeamMembersTable/useGetRoleOptions';
import {UserAccountType} from '../../../generated/graphql';
import {useViewer} from '../../../state/viewer/hooks';
import {TeamMember} from '../../../types/graphql';
import {checkIsServiceAccount} from '../../../util/serviceAccounts';

export interface UserInviteOption {
  value: string;
  label: string;
  username: Maybe<string>;
  photoUrl: Maybe<string>;
  name: Maybe<string>;
  email: Maybe<string>;
  accountType: Maybe<UserAccountType>;
}

interface UserInviteOptionsProps {
  users: TeamMember[];
  selectedUsers: string[];
  setSelectedUsers: (users: string[]) => void;
}

export const UserInviteOptions: FC<UserInviteOptionsProps> = ({
  users,
  selectedUsers,
  setSelectedUsers,
}) => {
  const viewer = useViewer();
  const options: UserInviteOption[] = users
    .filter(u => u.id && u.id !== viewer?.id)
    .map(u => {
      return {
        value: u.id as string,
        label: u.name,
        username: u.username,
        photoUrl: u.photoUrl,
        name: u.name,
        email: u.email,
        accountType: u.accountType as UserAccountType,
      };
    });

  const currentValue = options.filter(u => selectedUsers.includes(u.value));

  const customFilter = (
    option: FilterOptionOption<UserInviteOption>,
    inputValue: string
  ): boolean => {
    const input = inputValue.toLowerCase();
    const matchesName = option.data.name?.toLowerCase().includes(input);
    if (matchesName) {
      return true;
    }
    const matchesUsername = option.data.username?.toLowerCase().includes(input);
    if (matchesUsername) {
      return true;
    }
    const matchesEmail = option.data.email?.toLowerCase().includes(input);
    if (matchesEmail) {
      return true;
    }
    return false;
  };

  return (
    <Select<UserInviteOption, true>
      className={'w-full'}
      size={'variable'}
      value={currentValue}
      options={options}
      placeholder={'Email or username'}
      isMulti
      isSearchable
      filterOption={customFilter}
      closeMenuOnSelect={false}
      onChange={(opt: MultiValue<UserInviteOption>) =>
        setSelectedUsers(opt.map(o => o.value) ?? [])
      }
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: MultiUserInviteOptionComp,
      }}
    />
  );
};

const MultiUserInviteOptionComp: React.FC<
  OptionProps<UserInviteOption, true>
> = props => {
  const {data} = props;
  const {photoUrl, username, email, name} = data;

  return (
    <components.Option {...props}>
      <UserInviteOptionComp
        photoUrl={photoUrl}
        name={name}
        username={username}
        email={email}
      />
    </components.Option>
  );
};

export const SingleUserInviteOptionComp: React.FC<
  OptionProps<UserInviteOption, false>
> = props => {
  const {data} = props;
  const {photoUrl, username, email, name} = data;

  return (
    <components.Option {...props}>
      <UserInviteOptionComp
        photoUrl={photoUrl}
        name={name}
        username={username}
        email={email}
      />
    </components.Option>
  );
};

export const UserInviteOptionComp: React.FC<
  Pick<UserInviteOption, 'photoUrl' | 'username' | 'email' | 'name'>
> = props => {
  const {photoUrl, username, email, name} = props;

  return (
    <Tailwind>
      <div className={'flex items-center'}>
        <Image avatar src={photoUrl} />
        <div className={'flex flex-col items-center'}>
          <div className={'self-start'}>
            <span>{name}</span>
            {username && (
              <span className={'ml-6 text-sm text-moon-500'}>@{username}</span>
            )}
          </div>
          <span className={'-mt-2 self-start text-sm text-moon-500'}>
            {email}
          </span>
        </div>
      </div>
    </Tailwind>
  );
};

interface UserRoleItemProps {
  user: TeamMember;
  isOwner?: boolean;
  entityName: string;
}

export const UserRoleItem: FC<UserRoleItemProps> = ({
  user,
  isOwner,
  entityName,
}) => {
  let userRole: string = user.memberRole?.name ?? '';
  const {roleOptions} = useGetRoleOptions(entityName);

  const viewer = useViewer();
  const userIsViewer = user.id === viewer?.id;

  const defaultRoleOption = roleOptions.find(
    roleOption => roleOption.value === userRole
  );
  const defaultRoleName = defaultRoleOption?.text;

  if (checkIsServiceAccount(user.accountType)) {
    userRole = 'Service Account';
  } else if (isOwner) {
    userRole = 'Owner';
  } else if (defaultRoleName) {
    userRole = defaultRoleName;
  }

  return (
    <Tailwind>
      <div className={'mt-12 flex items-center'}>
        <Image avatar src={user.photoUrl} className={'mr-12'} />
        <span>
          {user.name}
          {userIsViewer && ' (you)'}
        </span>
        <span className={'ml-auto'}>{userRole}</span>
      </div>
    </Tailwind>
  );
};
