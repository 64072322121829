import * as globals from '@wandb/weave/common/css/globals.styles';
import {Icon, IconName} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React from 'react';

import {AccountType} from '../../../components/Search/SearchNav/types';

export enum AccountIconLocation {
  ProfileMenu = 'PROFILE_MENU',
  AccountSettingsHeader = 'ACCOUNT_SETTINGS_HEADER',
  AccountSettingsHeaderLegacy = 'ACCOUNT_SETTINGS_HEADER_LEGACY',
  Onboarding = 'ONBOARDING',
}

type AccountIconProps = {
  accountType: AccountType;
  location: AccountIconLocation;
  isSelected?: boolean;
};

function getCustomIconBackgroundColor(
  isSelected: boolean,
  location: AccountIconLocation,
  defaultColor: string
) {
  switch (location) {
    case AccountIconLocation.ProfileMenu:
      if (isSelected) {
        return globals.TEAL_550;
      }
      return globals.hexToRGB(globals.MOON_300, 0.48);
    case AccountIconLocation.AccountSettingsHeader:
      return globals.hexToRGB(globals.TEAL_300, 0.48);
    case AccountIconLocation.AccountSettingsHeaderLegacy:
      return defaultColor;
    case AccountIconLocation.Onboarding:
      if (isSelected) {
        return '#E1F7FA';
      }
      return globals.MOON_100;
    default:
      return undefined;
  }
}

function getCustomIconFillColor(
  isSelected: boolean,
  location: AccountIconLocation
) {
  switch (location) {
    case AccountIconLocation.ProfileMenu:
      if (isSelected) {
        return globals.WHITE;
      }
      return globals.MOON_600;
    case AccountIconLocation.Onboarding:
      if (isSelected) {
        return globals.TEAL_600;
      }
      return globals.MOON_450;
    case AccountIconLocation.AccountSettingsHeader:
      return globals.TEAL_600;
    default:
      return globals.WHITE;
  }
}

const AccountIconComp = ({
  accountType,
  location,
  isSelected = false,
}: AccountIconProps) => {
  const {iconName, iconColor} = AccountTypeToIconNameMap[accountType];
  const iconFill = getCustomIconFillColor(isSelected, location);

  const iconBackgroundCustom = getCustomIconBackgroundColor(
    isSelected,
    location,
    iconColor
  );

  const sizeClass =
    location === AccountIconLocation.ProfileMenu ? 'w-32 h-32' : 'w-40 h-40';
  const marginRightClass = 'mr-12';
  const colorClass =
    location === AccountIconLocation.ProfileMenu && !isSelected
      ? 'text-moon-600'
      : location === AccountIconLocation.AccountSettingsHeader
      ? 'text-teal-600'
      : 'text-white';
  const classes = `rounded-full ${sizeClass} ${marginRightClass} ${colorClass} flex flex-shrink-0 items-center justify-center`;
  const iconSize =
    location === AccountIconLocation.AccountSettingsHeader ? 24 : 20;
  return (
    <Tailwind>
      <div className={classes} style={{backgroundColor: iconBackgroundCustom}}>
        <Icon
          name={iconName}
          color={iconFill}
          width={iconSize}
          height={iconSize}
        />
      </div>
    </Tailwind>
  );
};

type IconData = {
  iconName: IconName;
  iconColor: string;
};

const AccountTypeToIconNameMap: Record<AccountType, IconData> = {
  [AccountType.Personal]: {
    iconName: 'user-profile-personal',
    iconColor: globals.GREEN_550,
  },
  [AccountType.Corporate]: {
    iconName: 'organization-corporate',
    iconColor: globals.TEAL_550,
  },
  [AccountType.Academic]: {
    iconName: 'education-academic',
    iconColor: globals.warningLight,
  },
};

export const AccountIcon = React.memo(AccountIconComp);
