import {Button} from '@wandb/weave/components';
import {Pill} from '@wandb/weave/components/Tag';
import React from 'react';

type Props = {
  title: string;
  daysUntilExpired: number | null;
  description: string;
  onClick: () => void;
  buttonLabel: string;
};

export const SidebarPlanCard = ({
  title,
  daysUntilExpired,
  description,
  onClick,
  buttonLabel,
}: Props) => {
  return (
    <div className="m-8 rounded-lg border border-moon-300 p-12">
      <div className="flex items-center justify-between ">
        <div className="text-base">{title}</div>
        {daysUntilExpired != null &&
          (daysUntilExpired > 0 ? (
            <Pill
              label={`${daysUntilExpired} days left`}
              className="p-12"
              color="teal"
            />
          ) : (
            <Pill label="Expired" className="p-12" color="red" />
          ))}
      </div>
      <div className="mb-8 mt-4 text-xs text-moon-600">{description}</div>
      <Button size="small" onClick={onClick}>
        {buttonLabel}
      </Button>
    </div>
  );
};
