import Color from 'color';
import * as _ from 'lodash';
import React, {useRef} from 'react';

import * as ColorUtil from '../../../util/colors';
import {smoothLines} from '../../../util/plotHelpers/lines';
import {getMetricIdentifiersFromExpressions} from '../../../util/plotHelpers/plotHelpers';
import {prettifyMetricName} from '../../../util/plotHelpers/prettifyMetricName';
import {Line} from '../../../util/plotHelpers/types';
import * as Run from '../../../util/runs';
import * as RunTypes from '../../../util/runTypes';
import {LineConfig} from '../config/types';
import {usePanelConfigContext} from '../PanelConfigContext';
import {usePanelGroupingSettings} from '../RunsLinePlotContext/usePanelGroupingSettings';
import {calculateStartTime} from '../timePlots/calcRunStart';
import {BucketedData} from '../types';
import {metricsInExpression} from './../../../util/expr';
import {
  legendTemplateToFancyLegendProps,
  parseLegendTemplate,
} from './../../../util/legend';
import {
  convertAreaToPercentArea,
  convertLinesToArea,
} from './../../../util/plotHelpers/areaCharts';
import {getBucketSpec} from './../../../util/plotHelpers/buckets/getBucketSpec';
import {markLinesByColor} from './../../../util/plotHelpers/colors';
import {filterRedundantHistories} from './../componentOutliers/filterRedundantHistories';
import {getSupplementalMetrics} from './../componentOutliers/getSupplementalMetrics';
import {parseHistory} from './../componentOutliers/parseHistory';
import {aggregateLines} from './aggregateLines';
import {PointWithMeta} from './buildPoints';
import {evaluateYExpressions} from './evaluateYExpressions';
import {HistoryRecord, SupplementalMetricsByRun} from './types';
import {
  convertLineDataToTimestamp,
  evaluateXExpressions,
  removeUselessAreaLines,
} from './useLinesUtils';
import {getSafeYKey, sliceObjectKeys} from './util';

/**
 * This is a hack because somehow when you mouse over lines in the chart the line config value is getting thrashed. I've not figured out why this is yet. This is a temporary fix to make sure the line config doesn't change unless it actually changes.
 */
function useDeepCompare(obj: any) {
  const old = useRef(obj);
  const refId = useRef(1);

  if (!_.isEqual(old.current, obj)) {
    // for (const k in obj) {
    //   if (!_.isEqual(obj[k], old.current[k])) {
    //     console.log('changed: ', k, obj[k]);
    //   }
    // }
    old.current = obj;
    refId.current += 1;
    return refId.current;
  }

  return refId.current;
}

export const useLines = (
  lineConfig: LineConfig,
  data: BucketedData['data']['histories']['data'],
  debugMode = false // this is a temporary flag to make debugging performance easier
) => {
  const shouldLineConfigUpdate = useDeepCompare(lineConfig);
  const {isGrouped, groupAgg, groupKeys} = usePanelGroupingSettings();
  const {expressionKeys, showMinMaxOnHover} = usePanelConfigContext();

  return React.useMemo(
    () => {
      const t0 = performance.now();

      const xExpressionMetricIdentifiers = lineConfig.xExpression
        ? metricsInExpression(lineConfig.xExpression, false)
        : [];
      const {expressionMetricIdentifiers} = getMetricIdentifiersFromExpressions(
        lineConfig.expressions,
        lineConfig.xExpression
      );

      const xMetrics = [
        ...new Set([
          ...xExpressionMetricIdentifiers.concat(lineConfig.xAxis, '_step'),
        ]),
      ];
      const yMetrics = [
        ...new Set([...lineConfig.yAxis.concat(expressionMetricIdentifiers)]),
      ];

      /**
       * Note: the `minMax` agg value isn't really used, all we really do is check if it's the Avg or not, otherwise we return aux lines
       */
      const aggregations = ['Avg', 'minMax'];

      /**
       * When plotting metrics by custom x-axis we get a bunch of redundant data results. Since the x-axis values come in on the history with each metric we can just strip off the redundant returns and save some processing time.
       *
       * TODO: eliminate these redundant requests in the sampledHistorySpecs
       */
      data.forEach(run => {
        const filteredHistories = filterRedundantHistories(
          run.history,
          yMetrics
        );
        run.history = filteredHistories;
      });

      /**
       * Determine if we're need to calculate any expressions - if not we can save some point computation for faster processing times
       */
      const usingExpressions =
        !!lineConfig.xExpression ||
        (lineConfig.expressions && lineConfig.expressions.length > 0);

      const supplementalMetricsByRun: SupplementalMetricsByRun = {};
      /**
       * first thing is to build all the lines w/out computing any of the expressions
       * the lines are a function of run count * aggregation count * yMetrics count
       */

      const t1 = performance.now();
      if (debugMode) {
        console.log('Preliminary line work: ', t1 - t0, 'ms');
      }

      /**
       * Hack to store non-finites (NF) points for each run and metric.
       *
       * The problem is that once you get into Graph/LinePlot/LinePlotPlot there's a lot of manipulation of the line data for [reasons]. The effect of this is that NanPoints that come in on aux lines (minMax areas) get handled differently and normalizing all the paths is tough. This solution is just to let both types of agg lines write to the same NF list, which is then only attached to the primary line. This way we can just check the primary line for NFs and handle them accordingly, and since the aux lines kick out NFs as x/y pairs (a NF point with x:5, y:-Infinity, y0: Infinity will spit out two NF points at 5/-Inf and 5/Inf) there's no type discrepancy with primary lines (which are just x/y points)
       */
      const nfPointsByRunAggMetric: {
        [name: string]: {
          [metric: string]: PointWithMeta[];
        };
      } = {};
      let lines = data.flatMap(run => {
        nfPointsByRunAggMetric[run.name] = {};
        // @ts-ignore hack because we need it for grouping
        run.runsetInfo =
          lineConfig.runSets.length > 0 ? lineConfig.runSets[0] : null;

        // see notes on handling time plots
        // Readme: frontends/app/src/components/PanelRunsLinePlot/timePlots/readme.md
        // Demo: https://wandb.ai/public-team-private-projects/Time%20plots?nw=36sx43brn2r
        const startTime = calculateStartTime(lineConfig.xAxis, run.history);

        const supplementalMetrics = getSupplementalMetrics(xMetrics, {
          configMetrics: run.config ?? {},
          summaryMetrics: run.summary ?? {},
        });
        supplementalMetricsByRun[run.name] = supplementalMetrics;

        /**
         * There are some perf gains here if we were to compute both agg lines (minMax and avg) within the same loop. This would save us from having to iterate over the history twice.
         *
         * Note: currently NaN and Infinite points aren't handled correctly so we're ejecting inside parseHistory by throwing an error.
         *
         * TODO: implement this to save processing time
         */
        return (aggregations as Array<'Avg' | 'minMax'>).flatMap(agg => {
          return yMetrics.flatMap(metric => {
            if (!nfPointsByRunAggMetric[run.name][metric]) {
              nfPointsByRunAggMetric[run.name][metric] = [];
            }
            const nfList = nfPointsByRunAggMetric[run.name][metric];

            /**
             * Only one history list in the run will have the metric we're looking for since each metric gets broken out individually
             */
            const yKey = getSafeYKey(metric);

            /**
             * There exists a case where we're running system metrics through the line plot and it's trying to build area points on a min/max aggregation on data which is coming back without being binned and having -Min, -Avg, -Max values. We eject in that scenario for now.
             */

            if (agg !== 'Avg' && yKey.startsWith('system/')) {
              return [];
            }
            const matchedHistory =
              run.history.find(history => {
                return yKey in history[0];
              }) ?? [];

            const {finitePoints, nanPoints} = parseHistory(
              matchedHistory as HistoryRecord[],
              metric,
              lineConfig.xAxis,
              xMetrics.filter(x => x !== lineConfig.xAxis),
              {
                agg,
                startTime,
                usingExpressions,
              },
              // we don't allow targeting min/max outside of groups currently
              isGrouped ? groupAgg : 'mean'
            );

            // Push all the NFs into the nanList: because this list reference is created outside the loop here we can mutate it from inside both agg line loops. I don't love this either.
            nfList.push(...nanPoints);

            const isAux = agg !== 'Avg';
            const prettyMetric = prettifyMetricName(metric);
            const line: Line = Object.assign({}, run, {
              aux: isAux,
              color: ColorUtil.runColor(
                // @ts-ignore
                run,
                groupKeys,
                lineConfig.customRunColors,
                0.2
              ),
              data: finitePoints,
              fancyTitle: legendTemplateToFancyLegendProps(
                lineConfig.legendTemplate,
                // @ts-ignore
                run,
                groupKeys,
                prettyMetric,
                lineConfig.rootUrl
              ),
              history: null,
              mark: isAux ? null : 'solid',
              meta: {
                aggregation: agg.toLowerCase(),
                category: 'default',
                minMaxOnHover: showMinMaxOnHover,
                mode: 'full-fidelity',
                type: isAux ? 'area' : 'line',
              },
              metricName: metric,
              name: run.name,
              nanPoints: isAux ? [] : nfList, // aux lines won't get NFs
              run: run as unknown as RunTypes.Run,
              title: parseLegendTemplate(
                lineConfig.legendTemplate,
                true,
                // @ts-ignore
                run,
                groupKeys,
                prettyMetric
              ),
              type: isAux ? 'area' : 'line',
              uniqueId: run.name,
            });

            return line;
          });
        });
      });

      const t2 = performance.now();
      if (debugMode) {
        console.log('Line creation: ', t2 - t1, 'ms');
      }

      // see notes on handling time plots
      // Readme: frontends/app/src/components/PanelRunsLinePlot/timePlots/readme.md
      // Demo: https://wandb.ai/public-team-private-projects/Time%20plots?nw=36sx43brn2r
      if (lineConfig.xAxis === '_timestamp') {
        convertLineDataToTimestamp(lines);
      }

      const t3 = performance.now();
      if (debugMode) {
        console.log('Timestamp conversion: ', t3 - t2, 'ms');
      }

      /**
       * Grouping on the client is taking all the lines and condensing them down into aggregate lines. This can happen based on:
       * a) group keys from the run selector (values read in from run selector filters)
       * b) group keys from the panel config (values set in the grouping tab)
       * c) aggregating metrics in the panel config
       *
       * Assume we're always going to bucket lines in grouping because if the number of lines gets large the checks to see if they require bucketing get expensive as we have to see if all x-values are in common. Odds are they won't be because of the indeterminancy of bucketing
       */
      // We can't plot any lines that don't have data, nor can we determine the
      // metadata about them (such as min/max/numBuckets/etc)
      const activeLines = lines.filter(l => l.data && l.data.length > 0);
      if (isGrouped && activeLines.length > 0) {
        const tA = performance.now();

        if (debugMode) {
          const tB = performance.now();
          console.log('meta info for grouping', tB - tA, 'ms');
        }

        /**
         * If we're grouping then group the lines by run keys
         */
        const groupedLines = sliceObjectKeys<Record<string, Line[]>>(
          lineConfig.aggregateMetrics && groupKeys.length > 0
            ? _.groupBy(activeLines, line => {
                return groupKeys.map(gKey => Run.getValue(line.run!, gKey));
              })
            : lineConfig.aggregateMetrics
            ? _.groupBy(activeLines, line => {
                return `${line.name}`;
              })
            : groupKeys.length > 0
            ? _.groupBy(activeLines, line => {
                return groupKeys.map(
                  gKey => line.metricName + '-' + Run.getValue(line.run!, gKey)
                );
              })
            : {default: activeLines},
          lineConfig.limit
        );

        const tC = performance.now();
        const aggLines = Object.values(groupedLines)
          .map(gLines => (gLines ? aggregateLines(gLines, {groupAgg}) : []))
          .flat();
        if (debugMode) {
          const tD = performance.now();
          console.log('running grouping: ', tD - tC, 'ms');
        }
        lines = aggLines;
      }

      /**
       *  We want to do this sooner rather than later so we're not computing expressions or smoothing on lines which ultimately have no display value
       */
      lines = removeUselessAreaLines(lines);

      const t4 = performance.now();
      if (lineConfig.xExpression) {
        evaluateXExpressions(
          lineConfig.xExpression,
          lines,
          supplementalMetricsByRun
        );
      }

      const t5 = performance.now();
      if (debugMode) {
        console.log('X-Expression computation: ', t5 - t4, 'ms');
      }

      const hasYExpression =
        lineConfig.expressions && lineConfig.expressions.length > 0;

      const expressionLines = hasYExpression
        ? evaluateYExpressions(
            lines,
            lineConfig.expressions,
            supplementalMetricsByRun
          )
        : [];

      let linesToUse = hasYExpression ? (expressionLines as Line[]) : lines;

      const t6 = performance.now();
      if (debugMode) {
        console.log('Expression computation: ', t6 - t5, 'ms');
      }
      markLinesByColor(linesToUse as Array<{color: string; mark: string}>);

      const isSmoothingActive = lineConfig.smoothingParam > 0;
      if (isSmoothingActive) {
        const [originalLines, smoothedLines] = smoothLines(
          // @ts-ignore
          linesToUse,
          lineConfig.smoothingParam,
          lineConfig.smoothingType
        );

        linesToUse = [...originalLines, ...smoothedLines] as Line[];
      }
      const t7 = performance.now();
      if (debugMode) {
        console.log('Smoothing computation: ', t7 - t6, 'ms');
      }
      let finalLines = makeAllowedLines(linesToUse, {
        isGrouped: isGrouped,
        isSmoothingActive: isSmoothingActive,
        showOriginalAfterSmoothing: lineConfig.showOriginalAfterSmoothing,
      });

      if (
        lines.length > 0 &&
        (lineConfig.plotType === 'stacked-area' ||
          lineConfig.plotType === 'pct-area')
      ) {
        // Crazy way of dealing with the fact that the samples don't line up
        // we make some buckets and compute an average value across the buckets
        //
        // We calculate these buckets for all lines together since there are more
        // interactions between the lines in a stacked/area charts than a regular line plot.
        const bucketSpec = getBucketSpec(lines);
        const xToSum: {[key: number]: number} = {};
        // Make it a stacked area chart
        finalLines = convertLinesToArea(finalLines, {
          buckets: bucketSpec,
          expressionKeys: expressionKeys,
          useMedian: false,
          xToSum,
        });
        const t8 = performance.now();
        if (debugMode) {
          console.log('Chart convertLinesToArea: ', t8 - t7, 'ms');
        }

        if (lineConfig.plotType === 'pct-area') {
          convertAreaToPercentArea(finalLines, xToSum);
          const t9 = performance.now();
          if (debugMode) {
            console.log('Chart convertAreaToPercentArea: ', t9 - t8, 'ms');
          }
        }
      }

      return finalLines;
    },
    // diffing lineConfig is handled by a deep comparison above since it's thrashing currently
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, shouldLineConfigUpdate, showMinMaxOnHover]
  );
};

export type MakeAllowedLinesConfig = {
  isGrouped: boolean;
  isSmoothingActive: boolean;
  showOriginalAfterSmoothing: boolean;
};
export const makeAllowedLines = (
  linesToUse: Line[],
  config: MakeAllowedLinesConfig
) =>
  linesToUse
    .filter(
      l =>
        !hasIneligibleDisplayCondition(l, {
          isGrouped: config.isGrouped,
          isSmoothingActive: config.isSmoothingActive,
          showOriginalAfterSmoothing: config.showOriginalAfterSmoothing,
        })
    )
    .map(l =>
      mutateColorOnPrimaryLine(l, {
        isGrouped: config.isGrouped,
        isSmoothingActive: config.isSmoothingActive,
      })
    );

type LinePrimaryFn = (
  line: Line,
  config: {
    isGrouped: boolean;
    isSmoothingActive: boolean;
  }
) => string | boolean;

const isDefaultPrimaryLine: LinePrimaryFn = (line, config) => {
  if (
    !config.isSmoothingActive &&
    !config.isGrouped &&
    line.meta.category === 'default' &&
    line.meta.type === 'line'
  ) {
    return 'Default primary line';
  }

  return false;
};

const isSmoothedPrimaryLine: LinePrimaryFn = (line, config) => {
  if (
    config.isSmoothingActive &&
    line.meta.category === 'smoothed' &&
    line.meta.type === 'line'
  ) {
    return 'Smoothed primary line';
  }

  return false;
};

const isGroupedPrimaryLine: LinePrimaryFn = (line, config) => {
  if (
    config.isGrouped &&
    !config.isSmoothingActive &&
    line.meta.category === 'grouped' &&
    line.meta.type === 'line'
  ) {
    return 'Grouped primary line';
  }
  return false;
};

export function mutateColorOnPrimaryLine(
  line: Line,
  config: {
    isGrouped: boolean;
    isSmoothingActive: boolean;
  }
) {
  const primaryConditions = [
    isDefaultPrimaryLine,
    isSmoothedPrimaryLine,
    isGroupedPrimaryLine,
  ]
    .map(fn => fn(line, config))
    .filter(c => c);

  if (primaryConditions.length > 0) {
    const primaryColor = Color(line.color).alpha(1).string();
    line.color = primaryColor;
  }
  return line;
}

type LineFilterFn = (
  line: {meta: Line['meta']},
  config: MakeAllowedLinesConfig
) => string | false;

const isLineMissingMeta: LineFilterFn = line => {
  if (line.meta == null) {
    return 'Line missing meta';
  }
  return false;
};

const isSampledLine: LineFilterFn = line => {
  if (line.meta.mode === 'sampled') {
    return 'Sampled line';
  }
  return false;
};

const isSmoothedAreaLine: LineFilterFn = line => {
  if (line.meta.category === 'smoothed' && line.meta.type === 'area') {
    return 'Smoothed area line';
  }
  return false;
};

const isSmoothedLineWithNoActiveSmoothing: LineFilterFn = (line, config) => {
  if (!config.isSmoothingActive && line.meta.category === 'smoothed') {
    return 'Smoothed without smoothing';
  }
  return false;
};

const isMinMaxNever: LineFilterFn = line => {
  if (line.meta.minMaxOnHover === 'never' && line.meta.type === 'area') {
    return 'MinMax never';
  }
  return false;
};

const isUngroupedLine: LineFilterFn = (line, config) => {
  if (config.isGrouped && line.meta.category === 'default') {
    return 'No default lines display while grouped';
  }
  return false;
};

const isHiddenOriginalLine: LineFilterFn = (line, config) => {
  if (
    config.isSmoothingActive &&
    !config.showOriginalAfterSmoothing &&
    line.meta.category !== 'smoothed' &&
    line.meta.type === 'line'
  ) {
    return 'No original default/grouped lines without `showOriginal`';
  }
  return false;
};

const isGroupedLineWithNoActiveGrouping: LineFilterFn = (line, config) => {
  if (!config.isGrouped && line.meta.category === 'grouped') {
    return 'Grouped without grouping';
  }
  return false;
};

export const hasIneligibleDisplayCondition = (
  line: {meta: Line['meta']},
  config: MakeAllowedLinesConfig
) => {
  const failedConditions = [
    isSampledLine,
    isLineMissingMeta,
    isSmoothedAreaLine,
    isSmoothedLineWithNoActiveSmoothing,
    isGroupedLineWithNoActiveGrouping,
    isMinMaxNever,
    isUngroupedLine,
    isHiddenOriginalLine,
  ].some(filterFn => {
    try {
      const r = filterFn(line, config);
      if (r) {
        return true;
      }
    } catch (e) {
      // eject on lines with errors, but make them visible to sentry
      console.error(e);
      return true;
    }

    return false;
  });

  return failedConditions;
};
