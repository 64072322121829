/**
 * This is a temporary fix. With a component system we'll get skeleton components for free,
 * but for the moment I'm trying to avoid making opiniated decisions about library imports
 * and implementations. The animation here is stolen from the skeleton component in
 * Material UI, so it should be visually similar to the eventual implmentation.
 */
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {memo} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {keyframes} from 'styled-components';

const wiooy9 = keyframes`
   0%{
     transform:translateX(-100%);
   }

   50%{
     transform:translateX(100%);
   }

   100%{
     transform:translateX(100%);
   }
 `;

export const Skeleton = styled.div<{
  height?: number | string;
  width?: number | string;
}>`
  background-color: rgba(0, 0, 0, 0.11);

  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05)
  );
  height: ${({height}) =>
    typeof height === 'number' ? `${height}px` : height ?? `${20}px`};
  width: ${({width}) =>
    typeof width === 'number' ? `${width}px` : width ?? `${20}px`};
  overflow: hidden;
  position: relative;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  :after {
    animation: ${wiooy9} 1.6s linear 0.5s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.04),
      transparent
    );
    content: '';
    position: absolute;
    transform: translateX(-100%);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
`;

const SkeletonWrapperComp: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  return (
    <Tailwind>
      <div className="animate-pulse-color">{children}</div>
    </Tailwind>
  );
};

/**
 * `SkeletonWrapper`a pulsating skeleton animation to its children
 * to indicate that the content is loading. It is a Tailwind component.
 *
 * Wrap any element(s) with `SkeletonWrapper` to apply the loading state animation.
 *
 * @component
 * @example
 *
 * <SkeletonWrapper>
 *   <div className="h-[10rem] w-[16rem] rounded-lg bg-moon-250" />
 * </SkeletonWrapper>
 *
 */
export const SkeletonWrapper = memo(SkeletonWrapperComp);
