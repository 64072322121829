import {SectionPanelSorting} from '../../../components/PanelBank/types';
import {
  panelSortingKeyFromPanel,
  sortPanelCheck,
} from '../../../util/panelbank';
import * as Normalize from '../normalize';
import * as PanelTypes from '../panel/types';
import {ViewReducerState} from '../reducerSupport';
import * as Types from './types';

export const sortPanelsInternal = (
  state: ViewReducerState,
  ref: Types.Ref,
  panelRefs: PanelTypes.Ref[]
) => {
  const prevPanels = Normalize.denormalize(state.parts, ref).panels;

  const refKeyPairs = prevPanels.map((panel, index) => {
    return {ref: panelRefs[index], key: panelSortingKeyFromPanel(panel)};
  });
  refKeyPairs.sort((a, b) => sortPanelCheck(a.key, b.key));

  state.parts[ref.type][ref.id].panelRefs = refKeyPairs.map(pair => pair.ref);
  state.parts[ref.type][ref.id].sorted = SectionPanelSorting.Alphabetical;
};
