import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface CompositionKeyboardContextValue {
  isDoingComposition: boolean;
  startComposition(): void;
  endComposition(): void;
}

// Context for handling composition events (onCompositionStart, onCompositionEnd)
// These are triggered when using an alternate keyboard, e.g. Japanese or Korean
// see https://stackoverflow.com/questions/51226598/what-is-javascripts-compositionevent-please-give-examples

export const CompositionKeyboardContext =
  createContext<CompositionKeyboardContextValue>({} as any);
CompositionKeyboardContext.displayName = 'CompositionKeyboardContext';

export const useCompositionKeyboard = () => {
  return useContext(CompositionKeyboardContext);
};

export const CompositionKeyboardContextProvider: React.FC = React.memo(
  ({children}) => {
    const [isDoingComposition, setIsDoingComposition] = useState(false);

    const startComposition = useCallback(() => {
      setIsDoingComposition(true);
    }, []);

    const endComposition = useCallback(() => {
      setIsDoingComposition(false);
    }, []);

    const compositionKeyboardContextValue = useMemo(
      () => ({isDoingComposition, startComposition, endComposition}),
      [endComposition, isDoingComposition, startComposition]
    );

    return (
      <CompositionKeyboardContext.Provider
        value={compositionKeyboardContextValue}>
        {children}
      </CompositionKeyboardContext.Provider>
    );
  }
);
