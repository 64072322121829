import {
  REPORT_DRAFT_VIEW_TYPE,
  REPORT_VIEW_TYPE,
  ReportViewRef,
} from '../reports/types';
import {Viewer} from '../viewer/types';
import * as Normalize from './normalize';
import {replacePartImmutable, ViewReducerState} from './reducerSupport';
import * as Types from './types';
import {View, ViewRef} from './types';

export function isOwnView(viewer: Viewer | undefined, view: View): boolean {
  return viewer != null && viewer.id === view.user.id;
}

export function isReportView(viewRef: ViewRef): viewRef is ReportViewRef {
  return (
    viewRef.type === REPORT_VIEW_TYPE || viewRef.type === REPORT_DRAFT_VIEW_TYPE
  );
}

export function copyObjectImmutable(
  state: ViewReducerState,
  fromRef: Types.AllPartRefs,
  ref: Types.AllPartRefs
) {
  const whole = Normalize.denormalize(state.parts, fromRef);
  const {ref: addedRef, parts: newParts} = Normalize.addObjImmutable(
    state.parts,
    fromRef.type,
    ref.viewID,
    whole
  );
  if (ref.type !== addedRef.type) {
    throw new Error('invalid action');
  }
  return replacePartImmutable({...state, parts: newParts}, ref, addedRef);
}
