import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from '@wandb/weave/components/Button';
import React from 'react';

export const ResetSettingButton = ({
  isActive,
  onButtonClick,
}: {
  isActive: boolean;
  onButtonClick: () => void;
}) => {
  return (
    <Button
      aria-label="Reset to parent"
      disabled={isActive}
      icon="randomize-reset-reload"
      onClick={onButtonClick}
      size={ButtonSizes.Medium}
      tooltip="Reset to parent"
      variant={ButtonVariants.Ghost}
    />
  );
};
