import {useContext, useMemo} from 'react';

import {envIsLocal, envIsPublicCloud} from '../../../config';
import {
  useOrganizationReadOnlyQuery,
  useProjectReadOnlyQuery,
} from '../../../generated/graphql';
import {DEFAULT_ONPREM_ORGANIZATION_NAME} from '../../../pages/OrgDashboard/OrgRoles/RolesDashboard';
import {propagateErrorsContext} from '../../../util/errors';
import {useRampFlagGlobalRegistry} from '../../../util/rampFeatureFlags';
import {AccountSelectorContext} from '../../Search/SearchNav/AccountSelectorContextProvider';

export const useIsGlobalRegistryEnabled = (): {
  enableGlobalRegistry: boolean;
  enableGlobalRegistryLoading: boolean;
} => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const orgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;
  const {data: enableGlobalRegistry, loading: enableGlobalRegistryLoading} =
    useRampFlagGlobalRegistry(orgName ?? '');

  const state = useMemo(() => {
    if (envIsLocal) {
      return {
        enableGlobalRegistry:
          (window.CONFIG as any)?.ENABLE_REGISTRY_UI ?? false,
        enableGlobalRegistryLoading: false,
      };
    }
    return {
      enableGlobalRegistry,
      enableGlobalRegistryLoading,
    };
  }, [enableGlobalRegistry, enableGlobalRegistryLoading]);
  return state;
};

export const useIsOrgReadOnly = (): {
  isOrganizationReadOnly: boolean;
  loading: boolean;
} => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const orgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;
  const {data: organizationData, loading: organizationLoading} =
    useOrganizationReadOnlyQuery({
      variables: {orgName},
      context: propagateErrorsContext(),
    });

  const state = useMemo(
    () => ({
      isOrganizationReadOnly:
        organizationData?.organization?.orgEntity?.readOnly ?? true,
      loading: organizationLoading,
    }),
    [organizationData, organizationLoading]
  );
  return state;
};

export const useIsProjectReadOnly = (
  entityName: string,
  projectName: string
): {isProjectReadOnly: boolean; loading: boolean} => {
  const {data, loading} = useProjectReadOnlyQuery({
    variables: {entityName, projectName},
    context: propagateErrorsContext(),
    skip: entityName === '' || projectName === '',
  });

  const state = useMemo(
    () => ({
      isProjectReadOnly: data?.entity?.project?.readOnly ?? true,
      loading,
    }),
    [data, loading]
  );
  return state;
};
