import * as React from 'react';

import cecileGrayAgentIcon from '../assets/cecile/ic-agent-gray.png';
import cecileGrayAgentIcon2x from '../assets/cecile/ic-agent-gray@2x.png';
import cecileAgentIcon from '../assets/cecile/ic-agent-white.png';
import cecileAgentIcon2x from '../assets/cecile/ic-agent-white@2x.png';
import cecileGrayCodeIcon from '../assets/cecile/ic-code-gray.png';
import cecileGrayCodeIcon2x from '../assets/cecile/ic-code-gray@2x.png';
import cecileCodeIcon from '../assets/cecile/ic-code-white.png';
import cecileCodeIcon2x from '../assets/cecile/ic-code-white@2x.png';
import cecileGrayFilesIcon from '../assets/cecile/ic-files-gray.png';
import cecileGrayFilesIcon2x from '../assets/cecile/ic-files-gray@2x.png';
import cecileFilesIcon from '../assets/cecile/ic-files-white.png';
import cecileFilesIcon2x from '../assets/cecile/ic-files-white@2x.png';
import cecileGrayJobIcon from '../assets/cecile/ic-job-gray.png';
import cecileGrayJobIcon2x from '../assets/cecile/ic-job-gray@2x.png';
import cecileJobIcon from '../assets/cecile/ic-job-white.png';
import cecileJobIcon2x from '../assets/cecile/ic-job-white@2x.png';
import cecileGrayLogsIcon from '../assets/cecile/ic-logs-gray.png';
import cecileGrayLogsIcon2x from '../assets/cecile/ic-logs-gray@2x.png';
import cecileLogsIcon from '../assets/cecile/ic-logs-white.png';
import cecileLogsIcon2x from '../assets/cecile/ic-logs-white@2x.png';
import cecileGrayModelIcon from '../assets/cecile/ic-model-gray.png';
import cecileGrayModelIcon2x from '../assets/cecile/ic-model-gray@2x.png';
import cecileModelIcon from '../assets/cecile/ic-model-white.png';
import cecileModelIcon2x from '../assets/cecile/ic-model-white@2x.png';
import cecileGrayOverviewIcon from '../assets/cecile/ic-overview-gray.png';
import cecileGrayOverviewIcon2x from '../assets/cecile/ic-overview-gray@2x.png';
import cecileOverviewIcon from '../assets/cecile/ic-overview-white.png';
import cecileOverviewIcon2x from '../assets/cecile/ic-overview-white@2x.png';
import cecileGrayReportsIcon from '../assets/cecile/ic-reports-gray.png';
import cecileGrayReportsIcon2x from '../assets/cecile/ic-reports-gray@2x.png';
import cecileReportsIcon from '../assets/cecile/ic-reports-white.png';
import cecileReportsIcon2x from '../assets/cecile/ic-reports-white@2x.png';
import cecileGrayRocketIcon from '../assets/cecile/ic-rocket-gray.png';
import cecileGrayRocketIcon2x from '../assets/cecile/ic-rocket-gray@2x.png';
import cecileRocketIcon from '../assets/cecile/ic-rocket-white.png';
import cecileRocketIcon2x from '../assets/cecile/ic-rocket-white@2x.png';
import cecileGrayBenchmarkIcon from '../assets/cecile/ic-scales-gray.png';
import cecileGrayBenchmarkIcon2x from '../assets/cecile/ic-scales-gray@2x.png';
import cecileBenchmarkIcon from '../assets/cecile/ic-scales-white.png';
import cecileBenchmarkIcon2x from '../assets/cecile/ic-scales-white@2x.png';
import cecileGrayControlsIcon from '../assets/cecile/ic-sweep-controls-gray.png';
import cecileGrayControlsIcon2x from '../assets/cecile/ic-sweep-controls-gray@2x.png';
import cecileControlsIcon from '../assets/cecile/ic-sweep-controls-white.png';
import cecileControlsIcon2x from '../assets/cecile/ic-sweep-controls-white@2x.png';
import cecileGraySweepsIcon from '../assets/cecile/ic-sweeps-gray.png';
import cecileGraySweepsIcon2x from '../assets/cecile/ic-sweeps-gray@2x.png';
import cecileClearGraySweepsIcon from '../assets/cecile/ic-sweeps-gray-clear.png';
import cecileClearGraySweepsIcon2x from '../assets/cecile/ic-sweeps-gray-clear@2x.png';
import cecileSweepsIcon from '../assets/cecile/ic-sweeps-white.png';
import cecileSweepsIcon2x from '../assets/cecile/ic-sweeps-white@2x.png';
import cecileGraySystemIcon from '../assets/cecile/ic-system-gray.png';
import cecileGraySystemIcon2x from '../assets/cecile/ic-system-gray@2x.png';
import cecileSystemIcon from '../assets/cecile/ic-system-white.png';
import cecileSystemIcon2x from '../assets/cecile/ic-system-white@2x.png';
import cecileGrayTableIcon from '../assets/cecile/ic-table-gray.png';
import cecileGrayTableIcon2x from '../assets/cecile/ic-table-gray@2x.png';
import cecileTableIcon from '../assets/cecile/ic-table-white.png';
import cecileTableIcon2x from '../assets/cecile/ic-table-white@2x.png';
import cecileGrayWorkspaceIcon from '../assets/cecile/ic-trend-gray.png';
import cecileGrayWorkspaceIcon2x from '../assets/cecile/ic-trend-gray@2x.png';
import cecileWorkspaceIcon from '../assets/cecile/ic-trend-white.png';
import cecileWorkspaceIcon2x from '../assets/cecile/ic-trend-white@2x.png';
import cecileGrayTriggerIcon from '../assets/cecile/ic-trigger-gray.png';
import cecileGrayTriggerIcon2x from '../assets/cecile/ic-trigger-gray@2x.png';
import cecileTriggerIcon from '../assets/cecile/ic-trigger-white.png';
import cecileTriggerIcon2x from '../assets/cecile/ic-trigger-white@2x.png';
import activityIcon from '../assets/ic-activity.png';
import activityIcon2x from '../assets/ic-activity@2x.png';
import artifactIconBlue from '../assets/ic-artifact-blue.png';
import artifactIconBlue2x from '../assets/ic-artifact-blue@2x.png';
import artifactIconGray from '../assets/ic-artifact-gray.png';
import artifactIconGray2x from '../assets/ic-artifact-gray@2x.png';
import artifactIconWhite from '../assets/ic-artifact-white.png';
import artifactIconWhite2x from '../assets/ic-artifact-white@2x.png';
import benchmarkIcon from '../assets/ic-benchmark.png';
import benchmarkIcon2x from '../assets/ic-benchmark@2x.png';
import projectBenchmarkIcon from '../assets/ic-benchmark-project.png';
import projectBenchmarkIcon2x from '../assets/ic-benchmark-project@2x.png';
import galleryIcon from '../assets/ic-gallery.png';
import galleryIcon2x from '../assets/ic-gallery@2x.png';
import projectIcon from '../assets/ic-project-blue.png';
import projectIcon2x from '../assets/ic-project-blue@2x.png';
import reportIcon from '../assets/ic-report-blue.png';
import reportIcon2x from '../assets/ic-report-blue@2x.png';
import runIcon from '../assets/ic-run-blue.png';
import runIcon2x from '../assets/ic-run-blue@2x.png';
import runGroupIcon from '../assets/ic-rungroup-blue.png';
import runGroupIcon2x from '../assets/ic-rungroup-blue@2x.png';
import smoothingIcon from '../assets/ic-smooth-gray.png';
import smoothingIcon2x from '../assets/ic-smooth-gray@2x.png';
import sweepIcon from '../assets/ic-sweep-blue.png';
import sweepIcon2x from '../assets/ic-sweep-blue@2x.png';
import tensorflowIconGray from '../assets/ic-tensorflow-gray.png';
import tensorflowIconGray2x from '../assets/ic-tensorflow-gray@2x.png';
import tensorflowIcon from '../assets/ic-tensorflow-white.png';
import tensorflowIcon2x from '../assets/ic-tensorflow-white@2x.png';
import xAxisIcon from '../assets/ic-x-gray.png';
import xAxisIcon2x from '../assets/ic-x-gray@2x.png';
import AMEXLogo from '../assets/logos/logo-AMEX.png';
import DinersLogo from '../assets/logos/logo-Diners-Club.png';
import DiscoverLogo from '../assets/logos/logo-Discover.png';
import InteracLogo from '../assets/logos/logo-Interac.png';
import JCBLogo from '../assets/logos/logo-JCB.png';
import MasterCardLogo from '../assets/logos/logo-MasterCard.png';
import UnionPayLogo from '../assets/logos/logo-UnionPay.png';
import VisaLogo from '../assets/logos/logo-Visa.png';
import runningAnimation from '../assets/running-animation.gif';
import creditCardIcon from '../assets/SVG/ic-credit-card.svg';

export type ImageIconName =
  | 'x-axis'
  | 'smoothing'
  | 'project'
  | 'run'
  | 'artifact'
  | 'artifact-gray'
  | 'artifact-white'
  | 'report'
  | 'runGroup'
  | 'sweep'
  | 'benchmark'
  | 'project-benchmark'
  | 'activity'
  | 'gallery'
  | 'cecile-agent'
  | 'cecile-files'
  | 'cecile-logs'
  | 'cecile-model'
  | 'cecile-overview'
  | 'cecile-reports'
  | 'cecile-controls'
  | 'cecile-sweeps'
  | 'cecile-system'
  | 'cecile-workspace'
  | 'running-animation'
  | 'logo-amex'
  | 'logo-mastercard'
  | 'logo-diners'
  | 'logo-discover'
  | 'logo-interac'
  | 'logo-jcb'
  | 'logo-unionpay'
  | 'logo-visa'
  | 'logo-unknown'
  | string;

interface ImageIconProps {
  className?: string;
  style?: any;
  name: ImageIconName;
  onClick?(): void;
}

function nameToIcon(name: ImageIconName): [string, string] {
  switch (name) {
    case 'x-axis':
      return [xAxisIcon, xAxisIcon2x];
    case 'smoothing':
      return [smoothingIcon, smoothingIcon2x];
    case 'project':
      return [projectIcon, projectIcon2x];
    case 'run':
      return [runIcon, runIcon2x];
    case 'artifact':
      return [artifactIconBlue, artifactIconBlue2x];
    case 'artifact-gray':
      return [artifactIconGray, artifactIconGray2x];
    case 'artifact-white':
      return [artifactIconWhite, artifactIconWhite2x];
    case 'report':
      return [reportIcon, reportIcon2x];
    case 'runGroup':
      return [runGroupIcon, runGroupIcon2x];
    case 'sweep':
      return [sweepIcon, sweepIcon2x];
    case 'benchmark':
      return [benchmarkIcon, benchmarkIcon2x];
    case 'project-benchmark':
      return [projectBenchmarkIcon, projectBenchmarkIcon2x];
    case 'activity':
      return [activityIcon, activityIcon2x];
    case 'gallery':
      return [galleryIcon, galleryIcon2x];
    case 'tensorflow':
      return [tensorflowIcon, tensorflowIcon2x];
    case 'tensorflow-gray':
      return [tensorflowIconGray, tensorflowIconGray2x];
    case 'cecile-agent':
      return [cecileAgentIcon, cecileAgentIcon2x];
    case 'cecile-files':
      return [cecileFilesIcon, cecileFilesIcon2x];
    case 'cecile-code':
      return [cecileCodeIcon, cecileCodeIcon2x];
    case 'cecile-logs':
      return [cecileLogsIcon, cecileLogsIcon2x];
    case 'cecile-model':
      return [cecileModelIcon, cecileModelIcon2x];
    case 'cecile-overview':
      return [cecileOverviewIcon, cecileOverviewIcon2x];
    case 'cecile-reports':
      return [cecileReportsIcon, cecileReportsIcon2x];
    case 'cecile-controls':
      return [cecileControlsIcon, cecileControlsIcon2x];
    case 'cecile-sweeps':
      return [cecileSweepsIcon, cecileSweepsIcon2x];
    case 'cecile-system':
      return [cecileSystemIcon, cecileSystemIcon2x];
    case 'cecile-workspace':
      return [cecileWorkspaceIcon, cecileWorkspaceIcon2x];
    case 'cecile-benchmark':
      return [cecileBenchmarkIcon, cecileBenchmarkIcon2x];
    case 'cecile-table':
      return [cecileTableIcon, cecileTableIcon2x];
    case 'cecile-rocket':
      return [cecileRocketIcon, cecileRocketIcon2x];
    case 'cecile-job':
      return [cecileJobIcon, cecileJobIcon2x];
    case 'cecile-trigger':
      return [cecileTriggerIcon, cecileTriggerIcon2x];
    case 'cecile-agent-gray':
      return [cecileGrayAgentIcon, cecileGrayAgentIcon2x];
    case 'cecile-files-gray':
      return [cecileGrayFilesIcon, cecileGrayFilesIcon2x];
    case 'cecile-code-gray':
      return [cecileGrayCodeIcon, cecileGrayCodeIcon2x];
    case 'cecile-logs-gray':
      return [cecileGrayLogsIcon, cecileGrayLogsIcon2x];
    case 'cecile-model-gray':
      return [cecileGrayModelIcon, cecileGrayModelIcon2x];
    case 'cecile-overview-gray':
      return [cecileGrayOverviewIcon, cecileGrayOverviewIcon2x];
    case 'cecile-reports-gray':
      return [cecileGrayReportsIcon, cecileGrayReportsIcon2x];
    case 'cecile-controls-gray':
      return [cecileGrayControlsIcon, cecileGrayControlsIcon2x];
    case 'cecile-sweeps-gray':
      return [cecileGraySweepsIcon, cecileGraySweepsIcon2x];
    case 'cecile-sweeps-gray-clear':
      return [cecileClearGraySweepsIcon, cecileClearGraySweepsIcon2x];
    case 'cecile-system-gray':
      return [cecileGraySystemIcon, cecileGraySystemIcon2x];
    case 'cecile-workspace-gray':
      return [cecileGrayWorkspaceIcon, cecileGrayWorkspaceIcon2x];
    case 'cecile-benchmark-gray':
      return [cecileGrayBenchmarkIcon, cecileGrayBenchmarkIcon2x];
    case 'cecile-table-gray':
      return [cecileGrayTableIcon, cecileGrayTableIcon2x];
    case 'cecile-rocket-gray':
      return [cecileGrayRocketIcon, cecileGrayRocketIcon2x];
    case 'cecile-job-gray':
      return [cecileGrayJobIcon, cecileGrayJobIcon2x];
    case 'cecile-trigger-gray':
      return [cecileGrayTriggerIcon, cecileGrayTriggerIcon2x];
    case 'running-animation':
      return [runningAnimation, runningAnimation];
    case 'logo-amex':
      return [AMEXLogo, AMEXLogo];
    case 'logo-diners':
      return [DinersLogo, DinersLogo];
    case 'logo-discover':
      return [DiscoverLogo, DiscoverLogo];
    case 'logo-interac':
      return [InteracLogo, InteracLogo];
    case 'logo-jcb':
      return [JCBLogo, JCBLogo];
    case 'logo-mastercard':
      return [MasterCardLogo, MasterCardLogo];
    case 'logo-unionpay':
      return [UnionPayLogo, UnionPayLogo];
    case 'logo-visa':
      return [VisaLogo, VisaLogo];
    case 'logo-unknown':
      return [creditCardIcon, creditCardIcon];
    default:
      throw new Error('Invalid icon name ' + name);
  }
}

const ImageIcon: React.StatelessComponent<ImageIconProps> = props => {
  const [imgSrc, imgSrc2x] = nameToIcon(props.name);
  return (
    <img
      alt={props.className}
      style={props.style}
      className={'image-icon ' + props.className}
      src={imgSrc}
      srcSet={imgSrc + ' 1x,' + imgSrc2x + ' 2x'}
      onClick={props.onClick ?? (() => {})}
    />
  );
};

export default ImageIcon;
