import {ActionType as TSActionType, getType} from 'typesafe-actions';

import * as Actions from './actions';
import {StateType} from './stateType';

export type {StateType} from './stateType';
export type ActionType = TSActionType<typeof Actions>;

export function expectedPanels(state: StateType = null, action: ActionType) {
  switch (action.type) {
    case getType(Actions.update): {
      return action.payload.expectedPanels;
    }
    case getType(Actions.clear): {
      return null;
    }
    default: {
      return state;
    }
  }
}
