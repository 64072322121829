import {getCookieBool, setCookie} from '@wandb/weave/common/util/cookie';
import React, {useContext} from 'react';

import {useContactUsForUserLedTrialMutation} from '../generated/graphql';
import * as urls from '../util/urls';
import {GlobalBannerContext, LegacyNudgeBarType} from './GlobalBanners';
import {
  NudgeBarLarge,
  NudgeBarLargeAction,
  NudgeBarLargeActionLink,
  NudgeBarLargeTitle,
} from './NudgeBarLarge';

/**
 * Note on cookie names:
 * We used to have a cookie named "hide trial nudge" (with a space in the
 * name). Since the cookie is only used in client-side browser code, and
 * browsers like Chromium don't complain about spaces in cookie names, we didn't
 * have a problem. However, spaces in cookie names are technically invalid
 * (1,2,3), yet loosely enforced, and can cause problems downstream. So we will
 * retain the legacy name such that users who already have this cookie set will
 * not see the banner again, but we will use the correct name for new cookies.
 *
 * References:
 * 1. https://tools.ietf.org/html/rfc6265#section-4.1.1
 * 2. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
 * 3. https://stackoverflow.com/questions/1969232/allowed-characters-in-cookies
 */
const TRIAL_NUDGE_COOKIE_NAME = 'hide_trial_nudge';
const LEGACY_TRIAL_NUDGE_COOKIE_NAME = 'hide trial nudge';

const TrialNudgeBar: React.FC = React.memo(() => {
  const {states} = useContext(GlobalBannerContext);

  const [createContactUsForUserLedTrial] =
    useContactUsForUserLedTrialMutation();
  const onContactUsClicked = async () => {
    setCookie(TRIAL_NUDGE_COOKIE_NAME, 'true');

    try {
      await createContactUsForUserLedTrial();
    } catch (e) {
      console.error('ERROR SENDING INFO TO CONTACT US', e);
    }
  };

  if (states == null || states.length === 0) {
    return null;
  }

  const alreadyContacted =
    getCookieBool(TRIAL_NUDGE_COOKIE_NAME) ||
    getCookieBool(LEGACY_TRIAL_NUDGE_COOKIE_NAME);
  const contactSalesContent = !alreadyContacted ? (
    <NudgeBarLargeAction>
      <NudgeBarLargeActionLink onClick={onContactUsClicked}>
        Contact sales
      </NudgeBarLargeActionLink>
    </NudgeBarLargeAction>
  ) : (
    <NudgeBarLargeTitle>
      Our sales team will reach out over email to help you upgrade.
    </NudgeBarLargeTitle>
  );

  return (
    <>
      {states.map((state, i) => {
        const showUpgradeButton =
          state.renderedNudgeBar !== LegacyNudgeBarType.EnterpriseToAdvanced &&
          !state.organization?.flags?.noContact;
        const shouldShowNudgeBar =
          (state.renderedNudgeBar === LegacyNudgeBarType.EnterpriseToAdvanced ||
            state.renderedNudgeBar ===
              LegacyNudgeBarType.EnterpriseToStandard) &&
          state.organization;

        if (!shouldShowNudgeBar) {
          return null;
        }

        window.analytics?.track('Upgrade Subscription Banner Displayed', {
          context: state.renderedNudgeBar,
          location: 'trial nudge bar',
          org: state.account.id,
          orgName: state.account.name,
        });

        return (
          <NudgeBarLarge key={i}>
            <NudgeBarLargeTitle>
              Your organization {state.account.name}'s 30 day free trial is
              over.{' '}
            </NudgeBarLargeTitle>
            {showUpgradeButton ? (
              <NudgeBarLargeAction>
                <NudgeBarLargeActionLink
                  href={urls.accountSettingsBillingTab(state.account.name)}
                  onClick={() => {
                    window.analytics?.track(
                      'banner upgrade plan button clicked',
                      {
                        org: state.account.id,
                        orgName: state.account.name,
                        location: 'trial nudge bar',
                      }
                    );
                  }}>
                  Upgrade
                </NudgeBarLargeActionLink>
              </NudgeBarLargeAction>
            ) : (
              contactSalesContent
            )}
          </NudgeBarLarge>
        );
      })}
    </>
  );
});

export default TrialNudgeBar;
