import {WBIcon} from '@wandb/ui';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
import {Icon as IconComp, IconLockClosed} from '@wandb/weave/components/Icon';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox, Popup} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {Banner} from '../../../components/Banner';

export const AccountTypeInnerContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ContactSalesLink = styled(TargetBlank)`
  display: inline-flex;
  align-items: center;
`;

export const ContactSalesIcon = styled(IconComp)`
  margin-top: 3px;
  height: 16px;
`;

export const Icon = styled(IconComp)`
  display: flex;
  align-items: center;
`;

export const ExternalStorageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AccountTypeSubtitle = styled.span<{isDisabled?: boolean}>`
  color: ${({isDisabled}) =>
    isDisabled ? globals.MOON_350 : globals.MOON_600};
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const AccountTypeTitle = styled.span<{isDisabled?: boolean}>`
  color: ${({isDisabled}) =>
    isDisabled ? globals.MOON_350 : globals.MOON_800};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline;
`;

export const AccountTypeItalic = styled.span<{isDisabled?: boolean}>`
  color: ${({isDisabled}) =>
    isDisabled ? globals.MOON_350 : globals.MOON_500};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  display: inline;
`;

export const SeeDocsLink = styled(TargetBlank)`
  display: flex;
  align-items: center;
`;

export const AccountTypeCard = styled.div<{
  isSelected: boolean;
  isActive?: boolean;
}>`
  display: flex;
  background: ${globals.WHITE};
  color: ${({isActive}) =>
    isActive ? globals.TEXT_PRIMARY_COLOR : globals.MOON_350} !important;
  outline: ${({isSelected}) =>
    isSelected
      ? `2px solid ${globals.hexToRGB(globals.TEAL_500, 0.4)}`
      : `1px solid ${globals.MOON_200}`};
  &:hover {
    outline: ${({isActive}) =>
      isActive ? `2px solid ${globals.hexToRGB(globals.TEAL_500, 0.4)}` : null};
  }
  border-radius: 4px;
  padding: 10px 12px;
  align-items: center;
  cursor: ${({isActive}) => (isActive ? 'pointer' : null)};
  justify-content: space-between;
`;

export const AccountTypeCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StorageTypeCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  > div {
    flex: 1;
  }
`;

export const FormRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const BannerWrapper = styled(Banner)`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const BannerTextWrapper = styled.div`
  display: inline;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
`;

export const Tooltip = styled(Popup)`
  && {
    font-size: 14px;
    color: ${globals.MOON_200};
  }
`;

export const IconLock = styled(IconLockClosed)`
  color: ${globals.MOON_450};
`;

export const AccountTypeIcon = styled(WBIcon)`
  font-size: 20px;
  margin-right: 4px;
  color: ${globals.TEAL_600};
`;

export const MultilineInputText = styled.textarea`
  border: 1px solid ${globals.MOON_200};
  &:hover {
    border: 1px solid transparent;
    outline: 2px solid ${globals.hexToRGB(globals.TEAL_500, 0.4)};
  }
  box-sizing: border-box;
  border-radius: 8px;

  width: 100%;
  height: 120px;
  padding: 8px 32px 8px 12px;

  font-family: ${globals.fontName};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  resize: none;
  &:focus-visible {
    border: 1px solid transparent;
    outline: 2px solid ${globals.MOON_250};
  }
`;

export const StorageWarningMessage = styled.span`
  color: ${globals.MOON_500};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 3px;
`;

export const WarningMessage = styled.span`
  color: ${globals.MOON_600};
  font-size: 12px;
  line-height: 18px;
  padding: 3px 0;
  display: flex;
`;

export const AgreeCheckbox = styled(Checkbox)`
  &&&& {
    margin-top: 24px;

    label {
      padding-left: 24px;
    }
  }
`;

export const InputWrapper = styled.div<{visible?: boolean}>`
  display: ${props => (props.visible ? 'block' : 'none')};
`;
