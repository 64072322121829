import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {NavLink} from '@wandb/weave/common/util/links';
import React, {FC, Fragment, memo, MouseEvent, ReactNode} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Breadcrumb} from 'semantic-ui-react';

import {useIsAdminModeActiveAndIsNotLocalEnv} from '../util/admin';
import {decodeURIComponentSafe} from '../util/urls';
import * as S from './Breadcrumbs.styles';
import {TruncateTextTooltip} from './common/TruncateText/TruncateTextTooltip';
import {TruncationType} from './common/TruncateText/TruncateTextTypes';
export type BreadcrumbPart = {
  label: string;
  beforeLabel?: ReactNode;
  afterLabel?: ReactNode;

  to?: string; // Uses our `NavLink` component which avoids a full page reload
  href?: string; // Uses an `<a>` tag which causes a full page reload

  onClick?: (e: MouseEvent) => void;
};

type BreadcrumbsProps = {
  parts: BreadcrumbPart[];
};

const BreadcrumbsComp: FC<BreadcrumbsProps> = ({parts}) => {
  const showAdminBanner = useIsAdminModeActiveAndIsNotLocalEnv();
  return (
    <S.BreadcrumbsWrapper
      className="nav-breadcrumbs-wrapper"
      showAdminBanner={showAdminBanner}>
      <Breadcrumb className="nav-breadcrumbs" size="small">
        {parts.map((part, i) => (
          <Fragment key={i}>
            {i > 0 && (
              <Breadcrumb.Divider icon={<LegacyWBIcon name="next" />} />
            )}
            <Breadcrumb.Section
              style={{display: 'inline-flex', alignItems: 'center'}}>
              {part.beforeLabel}
              <BreadcrumbSegment part={part} />
              {part.afterLabel}
            </Breadcrumb.Section>
          </Fragment>
        ))}
      </Breadcrumb>
    </S.BreadcrumbsWrapper>
  );
};

const Breadcrumbs = memo(BreadcrumbsComp);

export default Breadcrumbs;

type BreadcrumbSegmentProps = {part: BreadcrumbPart};

const BreadcrumbSegmentComp: FC<BreadcrumbSegmentProps> = ({
  part: {label, to, href, onClick},
}) => {
  const decodedLabel = decodeURIComponentSafe(label);
  const truncatedEl = (
    <div style={{maxWidth: '200px'}}>
      <TruncateTextTooltip
        truncationType={TruncationType.End}
        noLink
        fullText={decodedLabel}
      />
    </div>
  );

  if (to) {
    return (
      <NavLink to={to} onClick={onClick}>
        {truncatedEl}
      </NavLink>
    );
  }

  if (href) {
    return (
      // eslint-disable-next-line wandb/no-a-tags
      <a href={href} onClick={onClick}>
        {truncatedEl}
      </a>
    );
  }

  return <span onClick={onClick}>{truncatedEl}</span>;
};

const BreadcrumbSegment = memo(BreadcrumbSegmentComp);
