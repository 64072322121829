import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import {toast} from '@wandb/weave/common/components/elements/Toast';
import classNames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import React, {useCallback} from 'react';

interface CopyableTextProps {
  className?: string;
  dataTest?: string;
  text: string;
  copyText?: string;
  toastText: string;
  icon?: string;
  code?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

const CopyableText: React.FC<CopyableTextProps> = React.memo(
  ({
    className,
    dataTest,
    text,
    copyText,
    toastText,
    icon,
    code,
    disabled,
    onClick,
  }) => {
    const copy = useCallback(() => {
      copyToClipboard(copyText ?? text);
      toast(toastText);
    }, [text, copyText, toastText]);

    return (
      <span
        className={classNames('copyable-text', className, {code, disabled})}
        data-test={dataTest}
        onClick={e => {
          e.stopPropagation();
          if (disabled) {
            return;
          }
          copy();
          onClick?.();
        }}>
        <span className="copyable-text-content">{text}</span>
        <LegacyWBIcon className="copy-icon" name={icon ?? 'copy'} />
      </span>
    );
  }
);

export default CopyableText;
