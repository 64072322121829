import * as ColorUtil from '../../util/colors';
import {RunColorConfig} from '../../util/section';
import {LineResult} from './lineResultsForRunset';
import {Line, Mark} from './types';

export function getLinesColoredByMetric(lineResults: LineResult[]): Line[][][] {
  let lineColorIdx = 0;
  return lineResults.flatMap(lr => {
    return lr.linesByKeyAndMetricName.map(linesGroupByMetric => {
      return linesGroupByMetric.map(lineGroup => {
        const newLineGroup = lineGroup.map(l => {
          const newLine = {
            ...l,
            color: ColorUtil.color(lineColorIdx, l.aux ? 0.1 : undefined),
          } as Line;

          return newLine;
        });
        lineColorIdx += 1;
        return newLineGroup;
      });
    });
  });
}

export function getLinesColoredByRun(
  lineResults: LineResult[],
  colorEachMetricDifferently: boolean,
  customRunColors?: RunColorConfig
): Line[][][] {
  let lineColorIdx = 0;
  return lineResults.flatMap(lr => {
    if (
      lr.groupingType === 'Panel' ||
      customRunColors == null ||
      colorEachMetricDifferently
    ) {
      // If we're doing panel grouping OR old-stype coloring, apply round-robin colors.
      // There's extra nesting here so that we can capture mean & area lines together.
      const coloredLinesByKeyAndMetricName = lr.linesByKeyAndMetricName.map(
        linesGroupByMetric => {
          const coloredLineGroupByMetric: Line[][] = linesGroupByMetric.map(
            lineGroup => {
              const coloredLineGroup = lineGroup.map(l => {
                const newLine = {
                  ...l,
                  color: ColorUtil.color(lineColorIdx, l.aux ? 0.1 : undefined),
                } as Line;
                return newLine;
              });
              if (colorEachMetricDifferently) {
                lineColorIdx++;
              }
              return coloredLineGroup;
            }
          );
          if (!colorEachMetricDifferently) {
            lineColorIdx++;
          }
          return coloredLineGroupByMetric;
        }
      );
      return coloredLinesByKeyAndMetricName;
    } else {
      // Otherwise, we're doing new coloring either grouped or ungrouped. In either case,
      // it's the same codepath.
      const colLines = lr.linesByKeyAndMetricName.map(linesGroupByMetric => {
        const cl = linesGroupByMetric.map(lineGroup => {
          const newLineGroup = lineGroup.map(l => {
            const newLine = {
              ...l,
              color: ColorUtil.runColor(
                l.run!,
                lr.groupKeys,
                customRunColors,
                l.aux ? 0.1 : undefined
              ),
            } as Line;
            return newLine;
          });

          return newLineGroup;
        });
        lineColorIdx++;
        return cl;
      });
      return colLines;
    }
  });
}

// warning: this mutates the lines
export function markLinesByColor(
  lines: Array<{color?: string; mark?: string}>
) {
  const lineCountByColor = new Map<string, number>();

  const lineMarkOptions: Mark[] = [
    'solid',
    'dashed',
    'dotted',
    'dotdash',
    'dotdotdash',
  ];

  lines.forEach(l => {
    if (!l || !l.color) {
      return;
    } // skip if no color

    // Find the number of previous lines that are the same color as this line
    const sameColorCount = lineCountByColor.get(l.color) || 0;

    // Round-robin assignment of mark options
    l.mark = lineMarkOptions[sameColorCount % lineMarkOptions.length];

    lineCountByColor.set(l.color, sameColorCount + 1);
  });
}
