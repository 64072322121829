import * as globals from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  &&&& {
    border-radius: 12px;
  }

  background-color: ${globals.WHITE};
  color: ${globals.TEXT_PRIMARY_COLOR};
  @media only screen and (max-width: ${globals.TABLET_BREAKPOINT}) {
    flex-flow: row wrap;
  }
`;

const containerStyle = `
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 32px;
  flex-shrink: 0;
  @media only screen and (max-width: ${globals.TABLET_BREAKPOINT}) {
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  ${containerStyle};
  width: 408px;
  background-color: ${globals.MOON_100};
  overflow-y: auto;
  flex-shrink: 0;
  border-right: 1px solid ${globals.MOON_200};

  & > div {
    width: 100%;
  }
`;

export const SideContainer = styled.div`
  ${containerStyle};

  background-color: ${globals.WHITE};
  color: ${globals.TEXT_PRIMARY_COLOR};
  flex: 1;
`;

export const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-width: 600px;

  &&&& {
    border-radius: 12px;
  }

  background-color: ${globals.WHITE};
  color: ${globals.TEXT_PRIMARY_COLOR};
  @media only screen and (max-width: ${globals.TABLET_BREAKPOINT}) {
    flex-flow: row wrap;
  }
`;

export const SecondaryFormMainContainer = styled.div`
  ${containerStyle};
  overflow-y: auto;
  flex-shrink: 0;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Subtitle = styled.div`
  line-height: 20px;
  font-size: 16px;
  align-self: start;
  margin-bottom: 8px;
  color: ${globals.MOON_600};
`;

export const WhatsIncluded = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const WhatsIncludedTextContainer = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
`;

export const ViewPricingLink = styled(TargetBlank)`
  color: ${globals.TEAL_600};

  &:hover {
    color: ${globals.TEAL_500};
  }
`;

export const DescriptionList = styled.div`
  margin-left: 4px;
  margin-bottom: 16px;
  color: ${globals.MOON_600};
  padding: 0;
  font-size: 16px;
  font-weight: 400;
`;

export const DescriptionListItem = styled.li`
  line-height: 20px;
  &&&& {
    padding: 0;
    align-items: baseline;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const TermsAndPrivacyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UpgradeOrCancelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  gap: 16px;
  width: 100%;

  & > span {
    flex: 1 0 auto;
  }
`;

export const CloseModalButton = styled.div`
  color: ${globals.MOON_800};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export const ProcessingNotice = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
`;
