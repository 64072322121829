import _ from 'lodash';

import {apolloClientNoCache} from '../../../apolloClient';
import {RunsDataQuery} from '../../../containers/RunsDataLoader';
import {
  BucketedRunsDeltaQueryDocument,
  BucketedRunsDeltaQueryQuery,
  BucketedRunsDeltaQueryQueryVariables,
} from '../../../generated/graphql';
import {propagateErrorsContext} from '../../../util/errors';
import * as Filter from '../../../util/filters';
import {sortToOrderString} from '../../../util/queryts';
import * as Run from '../../../util/runs';
import {BucketedLine} from '../types';

function queryVars(runsDataQuery: RunsDataQuery, nBuckets: number) {
  const q = runsDataQuery.queries[0];
  const filters = JSON.stringify(Filter.toMongo(q.filters));
  const order = sortToOrderString(q.sort);
  return {
    configKeys: runsDataQuery.configKeys,
    enableBasic: true,
    enableConfig: runsDataQuery.configKeys != null,
    enableSummary: runsDataQuery.summaryKeys != null,
    entityName: runsDataQuery.entityName,
    filters,
    groupKeys: [],
    groupLevel: 0,
    limit: runsDataQuery.page?.size || 10,
    order,
    projectName: runsDataQuery.projectName,
    bucketedHistorySpecs:
      runsDataQuery.bucketedHistorySpecs?.map(bhs =>
        JSON.stringify({...bhs, bins: nBuckets})
      ) ?? [],
    summaryKeys: runsDataQuery.summaryKeys,
  };
}

function doBucketedApiQuery(
  vars: ReturnType<typeof queryVars>,
  currentRuns: string[],
  lastUpdated: string
) {
  return apolloClientNoCache.query<
    BucketedRunsDeltaQueryQuery,
    BucketedRunsDeltaQueryQueryVariables
  >({
    context: propagateErrorsContext(),
    query: BucketedRunsDeltaQueryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      currentRuns,
      lastUpdated,
    },
  });
}

function applyDelta(
  currentRunsById: Record<string, BucketedLine>,
  order: string[],
  delta: NonNullable<
    NonNullable<
      NonNullable<BucketedRunsDeltaQueryQuery['project']>['runs']
    >['deltas']
  >['delta']
) {
  const deleteOps = Object.keys(currentRunsById).filter(
    rId => !order.includes(rId)
  );
  if (delta.length === 0 && deleteOps.length === 0) {
    return currentRunsById;
  } else {
    // new diffOps or deleteOps means we need to refresh the reference for `runsById`
    currentRunsById = Object.assign({}, currentRunsById);
    deleteOps.forEach(rId => {
      delete currentRunsById[rId];
    });
  }

  delta.forEach(runDelta => {
    if (!runDelta.run) {
      console.error('Bucketed Delta Op returned without valid run');
      return;
    }

    try {
      const parsedRun = Run.fromJson(runDelta.run);
      if (parsedRun == null) {
        console.error('Unable to parse run from server in outliers reducer');
        return;
      }

      // Update the entire record for any diffOp
      currentRunsById[runDelta.run.id] = {
        ...(parsedRun as unknown as BucketedLine),
        history: runDelta.run.bucketedHistory,
      };
    } catch (e) {
      console.error('Error parsing run data in outliers reducer', e);
    }
  });
  return currentRunsById;
}

export async function bucketedQuery(
  runsDataQuery: RunsDataQuery,
  nBuckets: number,
  currentRunsById: Record<string, BucketedLine>
) {
  const vars = queryVars(runsDataQuery, nBuckets);
  const runIds = Object.keys(currentRunsById);
  const lastUpdated =
    _.max(Object.values(currentRunsById).map(l => l.updatedAt)) ??
    new Date(0).toISOString();

  const result = await doBucketedApiQuery(vars, runIds, lastUpdated);
  const {runs, totalRuns} = result?.data?.project ?? {};
  const {delta, order} = runs?.deltas ?? {};
  return {
    totalRuns: totalRuns ?? 0,
    runsById: applyDelta(currentRunsById, order ?? [], delta ?? []),
  };
}
