import {ID} from '@wandb/weave/common/util/id';
import {createAction} from 'typesafe-actions';

import {RunTag} from '../../generated/graphql';
import {ThunkResult} from '../../types/redux';
// eslint-disable-next-line import/no-cycle
import * as Lib from './lib';
import * as Types from './types';

// User Actions

export function registerQuery(query: Types.Query) {
  return registerQueryWithID({
    id: ID(),
    query,
  });
}

export const updateQuery = createAction(
  '@runs/updateQuery',
  action => (id: string, query: Types.Query) => action({id, query})
);

// This is a thunk that only calls updateQuery if the query has changed. It's better
// to call this instead of updateQuery, to avoid polluting the redux devtools action
// list.
export function updateQueryIfChanged(
  id: string,
  query: Types.Query
): ThunkResult<void> {
  return (dispatch, getState) => {
    const state = getState().runs;
    const stateQuery = state.queries[id];
    if (Lib.queryNeedsUpdate(query, stateQuery)) {
      dispatch(updateQuery(id, query));
    }
  };
}

export const unregisterQuery = createAction(
  '@runs/unregisterQuery',
  action => (id: string) => action({id})
);

export const deleteRun = createAction(
  '@runs/deleteRun',
  action => (runName: string) => action({runName})
);

export const updateRun = createAction(
  '@runs/updateRun',
  action => (id: string, vars: Types.UpdateRunVars) => action({id, vars})
);

export const updateRunTags = createAction(
  '@runs/updateRunTags',
  action => (tagMap: {[runName: string]: RunTag[]}) => action({tagMap})
);

// Internal actions

export const registerQueryWithID = createAction(
  '@runs/registerQuery',
  action => (query: Types.IDQuery) => action(query)
);

export const queryErrors = createAction(
  '@runs/queryErrors',
  action => (ids: string[][], errors: any[]) => action({ids, errors})
);

// We can't use createAction because queryResults is generic,
// so we make our own types.
export const QUERY_RESULTS_TYPE = '@runs/queryResults';

export const queryResults = <SR>(
  mergedQueries: Types.MergedQuery[],
  results: SR[]
): QueryResultsAction<SR> => ({
  type: QUERY_RESULTS_TYPE,
  payload: {
    mergedQueries,
    results,
  },
});

interface QueryResultsAction<SR> {
  type: typeof QUERY_RESULTS_TYPE;
  payload: {
    mergedQueries: Types.MergedQuery[];
    results: SR[];
  };
}

// Users use this
export type ActionType =
  | ReturnType<typeof registerQuery>
  | ReturnType<typeof updateQuery>
  | ReturnType<typeof unregisterQuery>
  | ReturnType<typeof queryErrors>
  | ReturnType<typeof deleteRun>
  | ReturnType<typeof updateRun>
  | ReturnType<typeof updateRunTags>;

export type FullActionType<SR> = ActionType | QueryResultsAction<SR>;
