import gql from 'graphql-tag';

export const ENTITY_STORAGE_QUERY = gql`
  query EntityStorage($entityName: String, $enableReferenceTracking: Boolean) {
    entity(name: $entityName) {
      id
      storageBytes
      referenceBytes
      computeHours
      storage: storageTree(enableReferenceTracking: $enableReferenceTracking) {
        id
        size
      }
    }
  }
`;

export const CACHED_ENTITY_STORAGE_INFO_QUERY = gql`
  query CachedEntityStorageInfo($entityName: String) {
    entity(name: $entityName) {
      id
      storageBytes(cached: true)
    }
  }
`;
