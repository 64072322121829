import {WBIcon} from '@wandb/ui';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {hexToRGB} from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import {Skeleton} from '../../../../components/Skeleton';
import WBReactTable from '../../../../components/WBReactTable';
import {StripeInvoiceStatus} from '../../../../generated/graphql';

export const LoadingInvoicesTable = styled(Skeleton)`
  height: 400px;
  max-width: none;
  border-radius: 4px;
`;

export const ActionIcon = styled(WBIcon)`
  color: ${globals.MOON_950};
  margin-right: 6px;
`;

export const ActionTargetBlank = styled(TargetBlank)`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${globals.black};
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
`;

export const InvoiceStatusTag = styled.div<{status: StripeInvoiceStatus}>`
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 3px 12px;
  width: fit-content;
  height: 24px;

  ${props =>
    props.status === StripeInvoiceStatus.Paid &&
    css`
      color: ${globals.GREEN_550};
      background-color: ${hexToRGB(globals.GREEN_300, 0.48)};
    `}
  ${props =>
    props.status === StripeInvoiceStatus.Open &&
    css`
      color: ${globals.RED_500};
      background-color: ${hexToRGB(globals.RED_300, 0.48)};
    `}
`;

export const InvoicesTable = styled(WBReactTable)``;
