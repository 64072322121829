import {WBSelect} from '@wandb/weave/common/components/WBSelect';
import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Menu} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const RowCountSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  padding-right: 24px;
  white-space: nowrap;
`;

export const RowCountSelect = styled(WBSelect)`
  padding: 0;
  margin-right: 5px;
`;

export const PageNav = styled.div`
  .ui.buttons .button:first-child {
    border-left: none;
  }
  .wb-icon-button.only-icon {
    border: none;
    border-radius: 50%;
  }
  .ui.buttons .button:last-child,
  .ui.buttons .button:first-child {
    border-radius: 50%;
  }
  .ui.button {
    background: none;
    &:focus,
    &:hover {
      background: ${globals.gray100};
    }
  }
  .ui.button:disabled,
  .ui.buttons .disabled.button,
  .ui.disabled.active.button,
  .ui.disabled.button,
  .ui.disabled.button:hover {
    background: none;
  }
`;

export const MenuItem = styled(Menu.Item)`
  &&&&& {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
