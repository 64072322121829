import {useApolloClient as useApolloApolloClient} from '@apollo/react-hooks';
import {usePrevious} from '@wandb/weave/common/state/hooks';
import {difference} from '@wandb/weave/common/util/data';
import _ from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useInView} from 'react-intersection-observer';
import {
  shallowEqual,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import {Selector} from 'reselect';

import {envIsDev} from '../config';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {Dispatch, RootAction, RootState} from '../types/redux';
import * as Types from './types';

export * from '@wandb/weave/common/state/hooks';

// This is a nicer way to make reselect selectors that depend on props
// work with hooks. We recreate the hook whenever the passed in args
// change. You'd typically unpack the props you want and pass them
// in as args.
export const usePropsSelector = <Args extends any[], R>(
  selectorFactory: (...args: Args) => Selector<RootState, R>,
  ...args: Args
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectorFactory(...args), args);
  return useReduxSelector(selector, shallowEqual);
};

export const useDispatch = () => {
  return useReduxDispatch<Dispatch>();
};

export function useReduxAction<ArgType extends any[]>(
  fn: (...fnArgs: ArgType) => RootAction
) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: ArgType) => dispatch(fn(...args)),
    [dispatch, fn]
  );
}

export function useChangedFromPrevious<T>(value: T): boolean {
  const prevValue = usePrevious(value);
  return value !== prevValue;
}

export function useChangedFromPreviousDeep<T>(value: T): boolean {
  const prevValue = usePrevious(value);
  return _.isEqual(value, prevValue);
}

type UseSelector = <TSelected>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
  debugStr?: string
) => TSelected;

const useSelectorInner: UseSelector = (selector, equalityFn, debugStr) =>
  useReduxSelector(selector, equalityFn);

const useSelectorInnerWithDebug: UseSelector = (
  selector,
  equalityFn = (a, b) => a === b,
  debugStr
) => {
  const result = useSelectorInner(selector, equalityFn);

  const prevResult = usePrevious(result);
  if (debugStr && !equalityFn(prevResult!, result)) {
    console.group(`[useSelector]: ${debugStr} returned different result`);
    console.log('prev', prevResult);
    console.log('next', result);
    console.log('diff', difference(prevResult, result));
    console.groupEnd();
  }

  return result;
};

export const useSelector = envIsDev
  ? useSelectorInnerWithDebug
  : useSelectorInner;

export const useMemoizedSelector: UseSelector = <T>(
  selector: (state: RootState) => T,
  equalityFn: (left: T, right: T) => boolean = (a, b) => a === b,
  debugStr?: string
) => {
  const resultRef = useRef<T>();
  const memoizedSelector = useMemo(
    () => (state: RootState) => {
      const result = selector(state);
      if (resultRef.current == null || !equalityFn(resultRef.current, result)) {
        resultRef.current = result;
      }
      return resultRef.current;
    },
    [selector, equalityFn]
  );
  return useSelector(memoizedSelector, undefined, debugStr);
};

// Returns true when domRef becomes onScreen for the first time, and stays
// true until value changes.
export function useWhenOnScreenAfterNewValue(value: any) {
  const {ref, inView} = useInView();
  const prevValue = usePrevious(value);
  // TODO: it would be nice to be able to init ready = true
  // but onScreen always starts false currently
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (inView) {
      if (!ready) {
        setReady(true);
      }
      return;
    }
    if (value !== prevValue && !inView) {
      setReady(false);
    }
  }, [inView, ready, prevValue, value]);
  return [ref, ready] as const;
}

export function useHasBeenOnScreen() {
  const [hasBeenOnScreen, setHasBeenOnScreen] = useState(false);
  const {ref, inView} = useInView();
  useEffect(() => {
    if (inView) {
      setHasBeenOnScreen(true);
    }
  }, [inView]);
  return {ref, hasBeenOnScreen} as const;
}

export const useApolloClient: () => Types.ApolloClient = () => {
  return useApolloApolloClient() as Types.ApolloClient;
};
