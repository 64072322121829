// Report section managment.
// All methods should do immutable updates

import {ID} from '@wandb/weave/common/util/id';
import {RunColorConfig} from '@wandb/weave/common/util/section';

import {TruncationType} from '../components/common/TruncateText/TruncateTextTypes';
import {
  OrganizedSettings,
  PanelBankConfig,
  PanelBankSectionConfig,
  RunSearchHistoryItem,
} from '../components/PanelBank/types';
import {Settings} from '../components/WorkspaceDrawer/Settings/types';
import * as Filter from './filters';
import * as FilterTypes from './filterTypes';
import type {Config as PanelTypesConfig} from './panelTypes';
import {addSearchFilter, CREATED_AT_DESC} from './queryts';
import * as QueryTypes from './queryTypes';
import * as RunFeed from './runfeed';
import * as RunTypes from './runTypes';
import * as SelectionManager from './selectionmanager';
export * from '@wandb/weave/common/util/section';

const EMPTY_RUN_SET_BASE = {
  filters: Filter.EMPTY_FILTERS,
  runFeed: RunFeed.EMPTY_CONFIG,
  sort: CREATED_AT_DESC,
  enabled: true,
  name: 'Run set',
  search: {
    query: '',
  },
  searchHistory: [],
  runNameTruncationType: TruncationType.Middle,
};

function emptyRunSetWithID() {
  return {...EMPTY_RUN_SET_BASE, id: ID(9)};
}

export function emptyRunSetSelectAll(): RunSetConfig {
  return {
    ...emptyRunSetWithID(),
    ...SelectionManager.EMPTY_ALL,
  };
}

export function emptyRunSetSelectNone(): RunSetConfig {
  return {
    ...emptyRunSetWithID(),
    ...SelectionManager.EMPTY_NONE,
  };
}

export function emptyReportRunSetSelectAll(): RunSetConfig {
  return prepRunSetForReport(emptyRunSetSelectAll());
}

export function emptyReportRunSetSelectNone(): RunSetConfig {
  return prepRunSetForReport(emptyRunSetSelectNone());
}

function prepRunSetForReport(rs: RunSetConfig): RunSetConfig {
  return {
    ...rs,
    runFeed: {
      ...rs.runFeed,
      pageSize: 10,
    },
  };
}

export function generateRunsetID(): string {
  return 'rs/' + ID(9);
}

export function runsetIdFromNameParts(...parts: string[]) {
  return ['rs', ...parts].join('/');
}

export type RunSetConfig = SelectionManager.GroupSelectionState & {
  id: string;
  project?: {
    entityName: string;
    name: string;
    id?: string;
  };
  filters: FilterTypes.RootFilter<RunTypes.Key>;
  runFeed: RunFeed.Config;
  // search is an object to make it easier to extend in the future
  search: {
    query: string;
    isRegex?: boolean;
  };
  searchHistory?: RunSearchHistoryItem[]; // list of past queries in this workspace
  runNameTruncationType: TruncationType;
  enabled?: boolean;
  sort: QueryTypes.Sort;
  name: string;
};

export interface Config {
  // to avoid migration, we're giving original sections the type null
  type?: null;
  name?: string;
  version?: number;
  openViz?: boolean;
  openRunSet?: number;
  hideRunSets?: boolean;
  runSets?: RunSetConfig[];
  settings?: Settings; // we're undergoing a migration and slowly moving towards `InheritedSettings` type, but cannot replace it here yet because it's still ongoing
  customRunColors?: RunColorConfig;
  panels?: PanelTypesConfig; // old spec ('Custom Visualizations' + pinnable panels)
  panelBankConfig: PanelBankConfig; // current spec for single- and multi-run workspaces (whole PanelBank)
  panelBankSectionConfig: PanelBankSectionConfig; // current spec for reports (a single PanelBank section)
  workspaceSettings?: OrganizedSettings; // contains workspace level settings
}

export interface ReportAuthor {
  username: string;
  name?: string;
  id?: string;
  photoUrl?: string;
}

export function runSetsToRunSetQuery(
  runSets: RunSetConfig[]
): QueryTypes.RunSetQuery[] {
  return runSets.map(rs => ({
    ...rs,
    filters: addSearchFilter(rs.filters, rs.search, rs.grouping),
    projectName: rs.project != null ? rs.project.name : undefined,
    entityName: rs.project != null ? rs.project.entityName : undefined,
    selections: SelectionManager.toRunFilter(rs),
  }));
}
