import {debounce} from 'lodash';
import React, {useMemo, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox, Input} from 'semantic-ui-react';

import {isLegendPosition} from '../util/plotHelpers/legend';
import {isFontSizeOrAuto} from '../util/plotHelpers/plotFontSize';
import {Query} from '../util/queryTypes';
import {LabeledOptionBlock as LabeledOption} from './elements/LabeledOptionBlock';
import MultiButton from './elements/MultiButton';
import {BarChartConfig} from './PanelBarChart/types';
import {RunsLinePlotConfig} from './PanelRunsLinePlot/types';

type PanelConfig = RunsLinePlotConfig | BarChartConfig;

interface PanelChartOptionsProps {
  config: PanelConfig;
  defaultTitle?: string;
  defaultXAxisTitle?: string;
  defaultYAxisTitle?: string;
  pageQuery: Query; // for the field selector
  showLegend?: boolean;
  type: 'lines' | 'bars' | 'scalar';
  updateConfig: (newConfig: PanelConfig) => void;
}

type DebouncedInputOptionProps = {
  label: string;
  value?: string;
  update: (newValue: string) => void;
  placeholder?: string;
};

export function DebouncedInputOption({
  label,
  value = '',
  update,
  placeholder = '',
}: DebouncedInputOptionProps) {
  const [displayedValue, setDisplayedValue] = useState(value);
  const debouncedUpdate = useMemo(() => debounce(update, 200), [update]);
  return (
    <LabeledOption
      label={label}
      option={
        <Input
          placeholder={placeholder}
          value={displayedValue}
          onChange={(e, {value: newValue}) => {
            setDisplayedValue(newValue);
            debouncedUpdate(newValue);
          }}
        />
      }
    />
  );
}

const fontSizeOptions = ['small', 'medium', 'large', 'auto'];
const fontSizeLabels = ['Small', 'Medium', 'Large', 'Auto'];

const legendPositionOptions = ['north', 'south', 'east', 'west'];
const legendPositionLabels = ['North', 'South', 'East', 'West'];

const PanelChartOptions = (props: PanelChartOptionsProps) => {
  const {config, showLegend, updateConfig} = props;

  return (
    <div data-test="panel-chart-options">
      <DebouncedInputOption
        label="Title"
        value={config.chartTitle}
        update={chartTitle => updateConfig({chartTitle})}
        placeholder={props.defaultTitle}
      />
      {props.type === 'lines' && (
        <>
          <DebouncedInputOption
            label="X-Axis Title"
            value={config.xAxisTitle}
            update={xAxisTitle => updateConfig({xAxisTitle})}
            placeholder={props.defaultXAxisTitle}
          />
          <DebouncedInputOption
            label="Y-Axis Title"
            value={config.yAxisTitle}
            update={yAxisTitle => updateConfig({yAxisTitle})}
            placeholder={props.defaultYAxisTitle}
          />
        </>
      )}
      {(props.type === 'lines' || props.type === 'scalar') && (
        <LabeledOption
          label="Show Legend"
          option={
            <Checkbox
              toggle
              checked={showLegend}
              onClick={(e, value) =>
                updateConfig({
                  showLegend: value.checked,
                })
              }
            />
          }
        />
      )}
      {props.type === 'lines' && (
        <>
          <LabeledOption
            label="Font Size"
            option={
              <MultiButton
                keys={fontSizeOptions}
                labels={fontSizeLabels}
                selected={config.fontSize ?? 'auto'}
                onChange={key => {
                  if (isFontSizeOrAuto(key)) {
                    updateConfig({
                      fontSize: key,
                    });
                  }
                }}
              />
            }
          />
        </>
      )}
      {props.type === 'lines' && (
        <>
          <LabeledOption
            label="Legend Position"
            helpText="Placement of the chart legend."
            option={
              <MultiButton
                keys={legendPositionOptions}
                labels={legendPositionLabels}
                selected={
                  (config as RunsLinePlotConfig).legendPosition ?? 'north'
                }
                onChange={key => {
                  if (isLegendPosition(key)) {
                    updateConfig({legendPosition: key});
                  }
                }}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default PanelChartOptions;
