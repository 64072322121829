import _ from 'lodash';
import React from 'react';

import {Line} from '../../util/plotHelpers/types';
import {RunsLinePlotConfig} from './types';
import {isEmptyChart} from './utils/checkTypes';

export const errorMessageDetails = {
  empty_chart: 'Select a metric to visualize in this line chart.',
  no_lines: 'No lines to visualize',
  no_lines_for_metric: `Select runs that logged this metric to visualize data in this line chart.`,
  no_data: `There's no data for the selected runs. Try a different X axis setting.`,
};

export const getErrorMessage = (config: RunsLinePlotConfig, lines: Line[]) => {
  if (isEmptyChart(config)) {
    return [
      errorMessageDetails.empty_chart,
      'Select a metric to visualize in this line chart.',
    ];
  } else if (_.isEmpty(lines)) {
    if (config.metrics != null && config.metrics.length > 0) {
      return [
        errorMessageDetails.no_lines_for_metric,
        <>
          Select runs that logged {config.metrics[0]} <br />
          to visualize data in this line chart.
        </>,
      ];
    } else {
      return [errorMessageDetails.no_lines, <>No lines to visualize</>];
    }
  } else if (lines.every(l => _.isEmpty(l.data) && _.isEmpty(l.nanPoints))) {
    return [
      errorMessageDetails.no_data,
      <>
        <div>There's no data for the selected runs.</div>
        <div>Try a different X axis setting.</div>
        <div className="mb-4">Current X axis: {config.xAxis} </div>
      </>,
    ];
  }

  return ['', null];
};
