import {Viewer} from 'src/state/viewer/types';

import {apolloClient as client} from '../../../apolloClient';
import {envIsPublicCloud} from '../../../config';
import {ORGANIZATION_COMPUTE_SECOND_USAGE_INFO} from '../../../graphql/organizations';
import {getPrimarySub, isNonAcademicOrg} from '../../../util/accounts/pricing';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {ViewerOrg} from '../GlobalBannerContextProvider';
import {
  ExceededTeamsPlanLimitBannerProps,
  ReachingTeamsPlanLimitBannerProps,
} from '.';

const TEAMS_PLAN_MONTHLY_NAME = 'monthly_teams_2023';
const TEAMS_PLAN_YEARLY_NAME = 'yearly_teams_2023';

const TEAMS_PLAN_MONTHLY_COST = 60;
const TEAMS_PLAN_ANNUAL_COST = 600;

export type TeamsPlanBannerFlags = {
  reachingTeamsPlanLimitBannerProps?: ReachingTeamsPlanLimitBannerProps;
  exceededTeamsPlanLimitBannerProps?: ExceededTeamsPlanLimitBannerProps;
};

export const fetchOrgComputeHourUsageQuery = async (
  org: ViewerOrg
): Promise<number> => {
  const primarySub = getPrimarySub(org);

  const billingPeriodStart = primarySub?.billingPeriodStart;

  return organizationComputeSecondUsageQuery(
    org.id,
    org.name,
    billingPeriodStart != null ? new Date(billingPeriodStart) : new Date()
  );
};

export const organizationComputeSecondUsageQuery = async (
  orgId: string,
  orgName: string,
  startTimeWindow: Date
): Promise<number> => {
  const startTimeWindowValue = startTimeWindow.valueOf();
  if (startTimeWindowValue === 0 || isNaN(startTimeWindowValue)) {
    console.error(
      `Invalid time window with value ${startTimeWindowValue} for org ${orgName}`
    );
    return 0;
  }
  try {
    const {data} = await client.query({
      query: ORGANIZATION_COMPUTE_SECOND_USAGE_INFO,
      variables: {
        organizationId: orgId,
        timeWindow: {
          start: startTimeWindow,
          end: new Date(),
        },
      },
    });

    return (
      data.organization?.teams.reduce(
        (acc: number, team: {computeHours: number}) =>
          acc + (team?.computeHours ?? 0),
        0
      ) ?? 0
    );
  } catch (error) {
    console.error(
      `Error fetching compute hour usage for org ${orgName}`,
      error
    );
    return 0;
  }
};

export async function getTeamsPlanBannerFlags(
  viewer?: Viewer,
  org?: ViewerOrg
): Promise<TeamsPlanBannerFlags> {
  const flags: TeamsPlanBannerFlags = {};

  if (!envIsPublicCloud) {
    return flags;
  }

  if (org == null) {
    return flags;
  }

  const isBillingAdmin = org.billingUser?.username === viewer?.username;
  if (!isNonAcademicOrg(org)) {
    return flags;
  }

  const primarySub = getPrimarySub(org);

  if (primarySub?.plan.name.toLowerCase() === 'enterprise') {
    return flags;
  }

  if (primarySub?.billingPeriodStart == null) {
    return flags;
  }

  const isMonthlyBillingInterval =
    primarySub?.plan?.billingInterval === 'month';
  const billingInterval = isMonthlyBillingInterval ? 'monthly' : 'annual';

  if (primarySub == null) {
    return flags;
  }

  if (
    primarySub?.plan?.name !== TEAMS_PLAN_MONTHLY_NAME &&
    primarySub?.plan?.name !== TEAMS_PLAN_YEARLY_NAME
  ) {
    return flags;
  }

  const expansionPacks = primarySub?.expansionPacks.edges
    .map(edge => edge?.node)
    .filter(
      node =>
        node != null &&
        (node.expiresAt == null || new Date(node.expiresAt) > new Date())
    );

  const computeSecondsLimit = expansionPacks?.reduce(
    (acc, pack) => acc + (pack?.secondsLimit ?? 0),
    0
  );

  const computeSecondsUsed = await fetchOrgComputeHourUsageQuery(org);

  // if for some reason the billing interval is null, default to annual because it just has the generic "contact sales for unlimited" message
  if (computeSecondsUsed >= computeSecondsLimit) {
    flags.exceededTeamsPlanLimitBannerProps = {
      organizationId: org?.id ?? '',
      organizationName: org?.name ?? '',
      billingInterval,
      computeSecondsUsed,
      computeSecondsLimit,
      isBillingAdmin,
      billingAdminEmail: org.billingUser?.email,
    };
    if (isMonthlyBillingInterval) {
      flags.exceededTeamsPlanLimitBannerProps.savingsIfSwitchToAnnual =
        (TEAMS_PLAN_MONTHLY_COST * 12 - TEAMS_PLAN_ANNUAL_COST) *
        primarySub.seats;
    }

    // early return, if they have exceeded the 100% limit we don't need to check if they exceeded the 85% limit
    return flags;
  }

  if (computeSecondsUsed >= computeSecondsLimit * 0.85) {
    flags.reachingTeamsPlanLimitBannerProps = {
      organizationId: org?.id ?? '',
      organizationName: org?.name ?? '',
      billingInterval,
      computeSecondsUsed,
      computeSecondsLimit,
      isBillingAdmin,
      billingAdminEmail: org.billingUser?.email,
    };
  }

  return flags;
}
