import {useCallback, useContext, useMemo, useState} from 'react';
import {toast} from 'react-toastify';

import {envIsLocal, envIsPublicCloud} from '../../config';
import {
  useCreateProjectMembersMutation,
  useDeleteProjectMembersMutation,
  useUpdateUserProjectRoleMutation,
} from '../../generated/graphql';
import {PROJECT_MEMBERS_QUERY} from '../../graphql/projects';
import {DEFAULT_ONPREM_ORGANIZATION_NAME} from '../../pages/OrgDashboard/OrgRoles/RolesDashboard';
import {PROJECT_PAGE_QUERY} from '../../state/graphql/projectPageQuery';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../../util/errors';
import {useRampProjectSpecificRoles} from '../../util/rampFeatureFlags';
import {AccountSelectorContext} from '../Search/SearchNav/AccountSelectorContextProvider';

// copied from app/src/components/Registries/common/hooks.ts
export const useIsProjectSpecificRolesEnabled = (): {
  enableProjectSpecificRoles: boolean;
  enableProjectSpecificRolesLoading: boolean;
} => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const orgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;
  const {
    data: enableProjectSpecificRoles,
    loading: enableProjectSpecificRolesLoading,
  } = useRampProjectSpecificRoles(orgName ?? '');

  const state = useMemo(() => {
    if (envIsLocal) {
      // always enabled for server
      return {
        enableProjectSpecificRoles: true,
        enableProjectSpecificRolesLoading: false,
      };
    }
    return {
      enableProjectSpecificRoles,
      enableProjectSpecificRolesLoading,
    };
  }, [enableProjectSpecificRoles, enableProjectSpecificRolesLoading]);
  return state;
};

export function useAddProjectMembers(entityName: string, projectName: string) {
  const [createProjectMembers] = useCreateProjectMembersMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PROJECT_MEMBERS_QUERY,
        variables: {projectName, entityName},
      },
      {
        query: PROJECT_PAGE_QUERY,
        variables: {entityName, projectName: projectName},
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(false);
  async function addProjectMembers(projectId: string, usersToAdd: string[]) {
    setIsLoading(true);
    try {
      if (usersToAdd.length > 0 && projectId !== '') {
        await createProjectMembers({
          variables: {
            projectId: projectId,
            userIds: usersToAdd,
          },
        });
      }

      toast('Updated users!');
      setIsLoading(false);
      return;
    } catch (error) {
      console.error(
        'Error trying to add project users: ',
        extractErrorMessageFromApolloError(error)
      );
      toast('Something went wrong while trying to add users in the project');
      setIsLoading(false);
      return;
    }
  }
  return {addProjectMembers, isLoading};
}

export function useDeleteProjectMembers(
  entityName: string,
  projectName: string
) {
  const [deleteProjectMembers] = useDeleteProjectMembersMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PROJECT_MEMBERS_QUERY,
        variables: {projectName, entityName},
      },
      {
        query: PROJECT_PAGE_QUERY,
        variables: {entityName, projectName},
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(false);
  async function deleteTeamProjectMembers(
    projectId: string,
    usersToRemove: string[]
  ) {
    setIsLoading(true);
    try {
      const usersLen = usersToRemove.length;
      if (usersLen > 0 && projectId !== '') {
        await deleteProjectMembers({
          variables: {
            projectId: projectId,
            userIds: usersToRemove,
          },
        });
      }
      if (usersLen !== 1) {
        toast(`${usersLen} users removed`);
      } else {
        toast(`1 user removed`);
      }

      setIsLoading(false);
      return;
    } catch (error) {
      const msg = extractErrorMessageFromApolloError(error);
      console.error('Error trying to delete project users: ', msg);
      if (msg !== undefined) {
        toast.error(`Error trying to delete users in the project: ${msg}`);
      } else {
        toast.error(
          'Something went wrong while trying to delete users in the project'
        );
      }

      setIsLoading(false);
      return;
    }
  }
  return {deleteTeamProjectMembers, isLoading};
}

export const useOnUserProjectRoleChange = (
  entityName: string,
  projectName: string
) => {
  const [updateUserProjectRoleMutation] = useUpdateUserProjectRoleMutation({
    refetchQueries: [
      {
        query: PROJECT_MEMBERS_QUERY,
        variables: {projectName, entityName},
      },
    ],
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const onUserProjectRoleChange = useCallback(
    async (userId: string, projectId: string, role: string) => {
      try {
        setIsUpdating(true);
        if (userId !== '' && projectId !== '' && role !== '') {
          await updateUserProjectRoleMutation({
            variables: {
              userId,
              projectId: projectId,
              userProjectRole: role,
            },
            context: propagateErrorsContext(),
          });
          toast.success('User role updated');
        }
      } catch (err) {
        const msg = extractErrorMessageFromApolloError(err);
        if (msg !== undefined) {
          toast.error(`Failed to update user role: ${msg}`);
        } else {
          toast.error('Failed to update user role');
        }
      } finally {
        setIsUpdating(false);
      }
    },
    [updateUserProjectRoleMutation]
  );

  return {
    isUpdating,
    onUserProjectRoleChange,
  };
};
