import * as globals from '@wandb/weave/common/css/globals.styles';
import {hexToRGB} from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
import {Button} from '@wandb/weave/components/Button';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Input} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const SavingMessageContainer = styled.div`
  background-color: ${hexToRGB(globals.GREEN_300, 0.48)};
  color: ${globals.GREEN_550};
  font-size: 12px;
  min-height: 16px;
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 10px;
  width: 100%;
`;

export const SavingMessage = styled.span`
  margin-left: 6px;
  color: ${globals.GREEN_550};
  font-size: 13px;
  min-height: 16px;
`;

export const SavingWarningMessage = styled.span`
  margin-left: 6px;
  color: ${globals.GOLD_600};
  font-size: 13px;
  min-height: 16px;
`;

export const SavingWarningMessageContainer = styled.div`
  background-color: ${hexToRGB(globals.GOLD_300, 0.48)};
  color: ${globals.GOLD_600};
  font-size: 12px;
  min-height: 16px;
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 10px;
  width: 100%;
`;

export const AddSeatTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const AddSeatBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const AddSeatMaxWarning = styled.div`
  font-size: 15px;
  line-height: 18px;
`;

export const AddSeatMaxWarningWrapper = styled.div`
  min-height: 40px;
`;

export const NumberInputSection = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StruckPlanAnnotation = styled.div`
  font-size: 16px;
  text-decoration: line-through;
`;

export const AmountSection = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StruckAmountSection = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: baseline;
  text-decoration: line-through;
  color: ${globals.MOON_600};
`;

export const StruckAmount = styled.div`
  font-size: 20px;
`;

export const TotalAmountSection = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: baseline;
`;

export const TotalAmount = styled.div`
  color: ${globals.MOON_800};
  font-weight: 600;
  font-size: 34px;
  line-height: 43px;
`;

export const BillingCycle = styled.div`
  font-size: 20px;
  color: ${globals.MOON_600};
  margin-right: 8px;
`;

export const ContactSalesLink = styled(TargetBlank)`
  color: ${globals.TEAL_600};

  &:hover {
    color: ${globals.TEAL_500};
  }
  font-weight: 600;
`;

export const NumberInput = styled(Input)`
  &&& {
    flex: 0 0 auto;

    input {
      text-align: center;
      height: 30px;
      width: 36px;
      padding: 0px;
      border-color: ${globals.MOON_150};
      border-radius: 0px;
      font-size: 18px;
    }
  }
`;

export const NumberButton = styled(Button)`
  &&&& {
    padding: 5px;
    font-size: 8px;
    width: 30px;
    height: 30px;
    background-color: ${globals.MOON_150};
    box-shadow: none !important;
    border-width: 0px;
    font-size: 18px;
  }
  &&&&.disabled {
    border-left: 1px solid #f4f4f4;
  }
`;

export const DescriptionList = styled.div`
  margin-left: 4px;
  margin-bottom: 16px;
  color: ${globals.MOON_600};
  padding: 0;
  font-size: 16px;
  font-weight: 400;
`;

export const DescriptionListItem = styled.li`
  line-height: 20px;
  &&&& {
    padding: 0;
    align-items: baseline;
  }
`;

export const ViewPricingLink = styled(TargetBlank)`
  color: ${globals.TEAL_600};

  &:hover {
    color: ${globals.TEAL_500};
  }
`;

export const WhatsIncluded = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const WhatsIncludedTextContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
