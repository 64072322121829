import {TargetBlank} from '@wandb/weave/common/util/links';
import {Button} from '@wandb/weave/components/Button';
import React, {useContext, useState} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Header, Modal} from 'semantic-ui-react';

import {useViewer} from '../state/viewer/hooks';
import {setViewingAs, unsetViewingAs, viewingAs} from '../util/admin';
import * as Urls from '../util/urls';
import {AccountSelectorContext} from './Search/SearchNav/AccountSelectorContextProvider';
import {SelectUser, UserOption} from './SelectUser';

type ImpersonateUserModalContentProps = {
  onImpersonate: () => void;
  onClose: () => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ImpersonateUserModal = (
  props: ImpersonateUserModalContentProps
) => {
  const viewer = useViewer();
  const [username, setUsername] = useState('');
  const {setLastNonImpersonatedAccount, selectedAccount} = useContext(
    AccountSelectorContext
  );
  const isSubmitDisabled = username === '';
  const loading = viewer == null;

  let profileButton = (
    <Button
      icon="user-profile-personal"
      variant="ghost"
      size="large"
      disabled={isSubmitDisabled}
      tooltip="View user's profile page"
    />
  );
  if (!isSubmitDisabled) {
    profileButton = (
      <TargetBlank href={Urls.profilePage(username)}>
        {profileButton}
      </TargetBlank>
    );
  }

  const onImpersonateClick = () => {
    setViewingAs(username);
    setLastNonImpersonatedAccount(selectedAccount);
    props.onImpersonate();
    window.location.reload();
  };

  return (
    <Modal open onClose={props.onClose} onClick={props.onClick} size="tiny">
      <Header>Select user to impersonate</Header>
      <Modal.Content>
        <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
          <div style={{flex: '1 1 auto'}}>
            <SelectUser
              size="large"
              onSelectUser={(user: UserOption | null) =>
                setUsername(user && user.username ? user.username : '')
              }
            />
          </div>
          {profileButton}
        </div>
      </Modal.Content>
      <Modal.Actions>
        {props.onClose && (
          <Button onClick={props.onClose} variant="secondary">
            Cancel
          </Button>
        )}
        <Button
          variant="destructive"
          data-test="stop-impersonate-button"
          disabled={viewingAs() === ''}
          onClick={() => {
            unsetViewingAs();
            window.location.reload();
          }}
          className="ml-12">
          Stop Impersonating
        </Button>
        <Button
          data-test="impersonate-button"
          variant="primary"
          disabled={isSubmitDisabled || loading}
          onClick={onImpersonateClick}
          className="ml-12">
          Impersonate ➞
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
