import * as PlotMath from './../math';
import {Point} from './../types';

/**
 * Calculates the average y-values of points grouped into specified number of buckets based on their x-values.
 *
 * This function divides the range between a minimum and maximum x-value into equal-sized intervals (buckets),
 * and then groups each point into a bucket based on its x-value. It calculates the average y-value for the points
 * in each bucket. If a bucket has no points, its average is represented as NaN.
 *
 * Parameters:
 * - points: An array of Point objects, where each Point is an object with x and y numeric properties.
 * - bucketCount: The number of buckets to divide the points into based on their x-values.
 * - min: The minimum x-value to consider for bucketing. Points with x-values less than this are ignored.
 * - max: The maximum x-value to consider for bucketing. Points with this x-value are placed in the last bucket.
 *
 * Returns:
 * An array of numbers, where each number is the average y-value of the points in a bucket. If a bucket has no points,
 * its average is NaN.
 *
 * Note:
 * - Points with an x-value exactly equal to the max parameter are always placed in the last bucket.
 * - The function logs a warning to the console if it attempts to place a point in a non-existent bucket, which could
 *   indicate an issue with the calculation of the bucket index.
 */
export function avgPointsByBucket(
  points: Point[],
  bucketCount: number,
  min: number,
  max: number,
  propertyToAverage: 'y' | 'y0' = 'y'
): number[] {
  const rangeOfBucket = (max - min) / bucketCount;

  // Note: you cannot fill an array with literals, it will set each value
  // to the same object: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/fill
  const buckets: Point[][] = new Array(bucketCount)
    .fill(undefined)
    .map(() => []);

  points.forEach(point => {
    const {x: xValue} = point;
    if (xValue === max) {
      buckets[bucketCount - 1].push(point);
    } else {
      /**
       * e.g. 100 points over 1000 units of range
       * each bucket is 10 units wide [0-9], [10-19], [20-29], ... [990-999]
       * point at 552
       * 552 - 0 / 10 = 55.2 => bucket 55
       * 0 - 0 / 10 =  0 => bucket 0
       * 999 - 0 / 10 = 99.9 => bucket 99
       */
      const floorVal = Math.floor((xValue - min) / rangeOfBucket);
      if (buckets[floorVal] != null) {
        buckets[floorVal].push(point);
      } else {
        console.warn("Can't find ", floorVal);
      }
    }
  });

  const avgBuckets = buckets.map((bucket, i) =>
    bucket.length > 0
      ? PlotMath.avg(buckets[i].map(b => b[propertyToAverage]!))
      : NaN
  );
  return avgBuckets;
}
