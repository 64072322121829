import {IconLightbulbInfo} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React from 'react';

import {useInteractStateWhenOnScreen} from '../../state/interactState/hooks';
import {Line} from '../../util/plotHelpers/types';
import {Banner} from '../Banner';

const bannerStyles: React.CSSProperties = {
  alignItems: 'center',
  background: 'rgba(255,255,255,.8)',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 44,
  zIndex: 3,
};

export const MissingRunBanner = React.memo(({lines}: {lines: Line[]}) => {
  const [domRef, activeRun] = useInteractStateWhenOnScreen(
    i => i.highlight?.['run:name'] ?? ''
  );
  const showMissingRunBanner =
    !!activeRun &&
    !lines.find(line => line.uniqueId === activeRun || line.name === activeRun);
  return showMissingRunBanner ? (
    <Tailwind style={bannerStyles}>
      <Banner
        className=" ml-30 mr-30 inline-flex self-center text-sm font-semibold text-moon-600"
        variant="info">
        <IconLightbulbInfo className="mr-12 shrink-0" />
        <span ref={domRef}>
          The highlighted run is not included in this panel's data
        </span>
      </Banner>
    </Tailwind>
  ) : null;
});
MissingRunBanner.displayName = 'MissingRunBanner';
