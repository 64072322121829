import * as _ from 'lodash';
import React from 'react';

import {useVegaPanelQuery} from '../../state/graphql/vega2PanelQuery';
import {useRunSetsQuery, useRunsQueryContext} from '../../state/runs/hooks';
import {GLOBAL_COLORS} from '../../util/colors';
import * as VegaLib2 from '../../util/vega2';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import * as VegaLib3 from '../../util/vega3';
import {InspectorPropertyWrapper} from '../Inspector.styles';
import {PropertyEditorComponent} from './property-editors';
import * as S from './VegaMappingPropertyEditor.styles';

/**
 * For vega3.
 * @param props
 */
const VegaMappingPropertyEditor: PropertyEditorComponent<
  'vega-mapping'
> = props => {
  const displayedVal = props.values.length === 1 ? props.values[0] : undefined;

  const [wrapperNode, setWrapperNode] = React.useState<HTMLElement | null>(
    null
  );
  const [sourceNodes, setSourceNodes] = React.useState<{
    [key: string]: HTMLElement | null;
  }>({});
  const [targetNodes, setTargetNodes] = React.useState<{
    [key: string]: HTMLElement | null;
  }>({});
  const [activeSource, setActiveSource] = React.useState<string | null>(null);
  const [activeTarget, setActiveTarget] = React.useState<string | null>(null);
  const [hoveredSource, setHoveredSource] = React.useState<string | null>(null);
  const [hoveredTarget, setHoveredTarget] = React.useState<string | null>(null);
  const [mousePosition, setMousePosition] = React.useState<{
    x: number;
    y: number;
  } | null>(null);

  React.useEffect(() => {
    function onMouseMove(e: MouseEvent) {
      setMousePosition({x: e.x, y: e.y});
    }
    if (activeSource || activeTarget) {
      document.addEventListener('mousemove', onMouseMove);
      return () => {
        document.removeEventListener('mousemove', onMouseMove);
      };
    }
    return () => {};
  }, [activeSource, activeTarget]);

  React.useEffect(() => {
    function onMouseDown(e: MouseEvent) {
      if (
        e.target instanceof Element &&
        e.target.closest('.mapping-item') == null
      ) {
        setActiveSource(null);
        setActiveTarget(null);
      }
    }
    if (activeSource || activeTarget) {
      document.addEventListener('mousedown', onMouseDown);
      return () => {
        document.addEventListener('mousedown', onMouseDown);
      };
    }
    return () => {};
  }, [activeSource, activeTarget]);

  const context = useRunsQueryContext();
  const vegaPanelQuery = useVegaPanelQuery({
    entityName: context.entityName,
    projectName: context.projectName,
    viewId: props.vegaSpec.panelDefId,
  });

  const pageQuery = useRunSetsQuery(props.runSetRefs);

  const {templateVals} = VegaLib3.queryTemplates(pageQuery, context);

  const {
    cols: [, ...sources],
  } = VegaLib3.useVegaQuery(
    {queryFields: props.query},
    VegaLib3.DEFAULT_TRANSFORM,
    templateVals
  );

  if (vegaPanelQuery.loading) {
    return <></>;
  }
  const spec = VegaLib3.getSpec(props.vegaSpec, vegaPanelQuery.views);
  if (spec == null) {
    return (
      <InspectorPropertyWrapper>Invalid vega spec</InspectorPropertyWrapper>
    );
  }
  if (displayedVal == null) {
    return <InspectorPropertyWrapper>Mixed</InspectorPropertyWrapper>;
  }
  const refs = VegaLib2.parseSpec(spec);
  const inputs = VegaLib2.refsToInputs(refs || []);

  const wrapperRect = wrapperNode?.getBoundingClientRect();

  return (
    <InspectorPropertyWrapper>
      <S.Wrapper ref={node => setWrapperNode(node)}>
        <S.Header>Mapping</S.Header>
        <svg style={{position: 'absolute', width: '100%', height: '100%'}}>
          {wrapperRect &&
            Object.keys(displayedVal).map(key => {
              const targetNode = targetNodes[key];
              if (targetNode) {
                const sourceNode = sourceNodes[displayedVal[key]];
                if (sourceNode) {
                  const targetRect = targetNode.getBoundingClientRect();
                  const sourceRect = sourceNode.getBoundingClientRect();
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <S.MappingLine
                      x1={
                        sourceRect.left +
                        sourceRect.width -
                        4 -
                        wrapperRect.left
                      }
                      y1={
                        sourceRect.top + sourceRect.height / 2 - wrapperRect.top
                      }
                      x2={targetRect.left + 4 - wrapperRect.left}
                      y2={
                        targetRect.top + targetRect.height / 2 - wrapperRect.top
                      }
                      stroke={GLOBAL_COLORS.primary
                        .alpha(
                          activeTarget === key ||
                            (hoveredTarget === key && activeSource != null)
                            ? 0.25
                            : 1
                        )
                        .toString()}></S.MappingLine>
                  );
                }
              }
              return <></>;
            })}
          {activeTarget &&
            wrapperRect &&
            mousePosition &&
            (() => {
              const targetRect =
                targetNodes[activeTarget]?.getBoundingClientRect();
              if (targetRect) {
                return (
                  <line
                    x1={mousePosition.x - wrapperRect?.left}
                    y1={mousePosition.y - wrapperRect?.top}
                    x2={targetRect.left + 4 - wrapperRect.left}
                    y2={
                      targetRect.top + targetRect.height / 2 - wrapperRect.top
                    }
                    stroke={GLOBAL_COLORS.primary.toString()}
                    strokeDasharray={'2 2'}></line>
                );
              }
              return <></>;
            })()}
          {activeSource &&
            wrapperRect &&
            mousePosition &&
            (() => {
              const sourceRect =
                sourceNodes[activeSource]?.getBoundingClientRect();
              if (sourceRect) {
                return (
                  <line
                    x1={
                      sourceRect.left + sourceRect.width - 4 - wrapperRect.left
                    }
                    y1={
                      sourceRect.top + sourceRect.height / 2 - wrapperRect.top
                    }
                    x2={mousePosition.x - wrapperRect?.left}
                    y2={mousePosition.y - wrapperRect?.top}
                    stroke={GLOBAL_COLORS.primary.toString()}
                    strokeDasharray={'2 2'}></line>
                );
              }
              return <></>;
            })()}
        </svg>

        <S.ColumnsWrapper>
          <S.MappingColumn align="left">
            {sources.map(item => {
              return (
                // eslint-disable-next-line react/jsx-key
                <S.MappingItemWrapper
                  className="mapping-item"
                  pairing={hoveredSource === item}
                  active={activeSource === item}
                  onMouseEnter={() => {
                    if (activeTarget) {
                      setHoveredSource(item);
                    }
                  }}
                  onMouseLeave={() => {
                    setHoveredSource(null);
                  }}
                  onMouseDown={e => {
                    setMousePosition({x: e.clientX, y: e.clientY});
                    setHoveredSource(null);
                    if (activeSource === item) {
                      setActiveSource(null);
                    } else if (activeTarget) {
                      props.save({...displayedVal, [activeTarget]: item});
                      setActiveTarget(null);
                    } else {
                      setActiveSource(item);
                    }
                  }}
                  ref={node => {
                    setSourceNodes(prev => {
                      prev[item] = node;
                      return prev;
                    });
                  }}>
                  <S.MappingItemWrapperInner>
                    <S.MappingItem>{item}</S.MappingItem>
                  </S.MappingItemWrapperInner>
                </S.MappingItemWrapper>
              );
            })}
          </S.MappingColumn>
          <S.MappingColumn align="right">
            {inputs.fieldInputs.map(key => {
              return (
                // eslint-disable-next-line react/jsx-key
                <S.MappingItemWrapper
                  className="mapping-item"
                  error={
                    displayedVal[key] == null &&
                    activeTarget !== key &&
                    hoveredTarget !== key
                  }
                  pairing={hoveredTarget === key}
                  active={activeTarget === key}
                  onMouseEnter={() => {
                    if (activeSource && activeSource !== displayedVal[key]) {
                      setHoveredTarget(key);
                    }
                  }}
                  onMouseLeave={() => {
                    setHoveredTarget(null);
                  }}
                  onMouseDown={e => {
                    setMousePosition({x: e.clientX, y: e.clientY});
                    setHoveredTarget(null);
                    if (activeTarget === key) {
                      setActiveTarget(null);
                    } else if (activeSource) {
                      if (displayedVal[key] === activeSource) {
                        props.save(_.omit(displayedVal, [key]));
                      } else {
                        props.save({...displayedVal, [key]: activeSource});
                        setActiveSource(null);
                      }
                    } else {
                      setActiveTarget(key);
                    }
                  }}
                  ref={node => {
                    setTargetNodes(prev => {
                      prev[key] = node;
                      return prev;
                    });
                  }}>
                  <S.MappingItemWrapperInner>
                    <S.MappingItem>{key}</S.MappingItem>
                  </S.MappingItemWrapperInner>
                </S.MappingItemWrapper>
              );
            })}
          </S.MappingColumn>
        </S.ColumnsWrapper>
      </S.Wrapper>
    </InspectorPropertyWrapper>
  );
};

export default VegaMappingPropertyEditor;
