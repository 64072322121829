import queryString from 'query-string';

export function keyExistsInQueryString(
  key: string,
  qs = window.location.search
): boolean {
  const params = Object.keys(queryString.parse(qs)).map(k => k.toLowerCase());
  return params.includes(key.toLowerCase());
}

export function getValueFromQueryString(
  key: string,
  qs = window.location.search
): string | string[] | null | undefined {
  return queryString.parse(qs)[key];
}

export function getStringValueFromQueryString(
  key: string,
  qs = window.location.search
): string | null {
  const val = getValueFromQueryString(key, qs);
  if (Array.isArray(val)) {
    return val[0];
  }
  return val ?? null;
}
