import * as globals from '@wandb/weave/common/css/globals.styles';
import {TargetBlank} from '@wandb/weave/common/util/links';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const SearchNav = styled.div<{
  showAdminBanner?: boolean;
  showImpersonatingBanner?: boolean;
  $isNightMode: boolean;
}>`
  background: ${props => {
    if (props.showImpersonatingBanner) {
      return globals.PURPLE_500;
    } else if (props.showAdminBanner) {
      return globals.GOLD_550;
    } else {
      return globals.gray900;
    }
  }};

  color: ${props => {
    if (props.showImpersonatingBanner || props.showAdminBanner) {
      return globals.WHITE;
    } else {
      return globals.gray500;
    }
  }};

  border-bottom: ${props => {
    if (props.$isNightMode) {
      return `1px solid ${globals.MOON_750}`;
    } else if (props.showImpersonatingBanner) {
      return `1px solid ${globals.PURPLE_500}`;
    } else if (props.showAdminBanner) {
      return `1px solid ${globals.GOLD_550}`;
    } else {
      return `1px solid ${globals.MOON_500}`;
    }
  }};

  position: relative;
  overflow: hidden;
  align-items: center;
`;

SearchNav.displayName = 'S.SearchNavLeft';

export const SearchNavLeft = styled.div<{showAdminBanner?: boolean}>`
  opacity: ${props => (props.showAdminBanner ? 0.5 : 1)};
  &:hover {
    opacity: ${props => (props.showAdminBanner ? 0.8 : 1)};
    color: white;
  }
`;
SearchNavLeft.displayName = 'S.SearchNavLeft';

export const ExperimentalTestBar = styled.div<{
  showAdminBanner?: boolean;
  showImpersonatingBanner?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 86px;
  right: 146px;
  z-index: 1;
  min-height: 1px;
  background: ${props => {
    if (props.showImpersonatingBanner) {
      return globals.PURPLE_500;
    } else if (props.showAdminBanner) {
      return globals.GOLD_550;
    } else {
      return globals.gray900;
    }
  }};
`;
ExperimentalTestBar.displayName = 'S.ExperimentalTestBar';

export const OpenPageLink = styled(TargetBlank)`
  align-items: center;
  display: flex;
  cursor: pointer;
  color: ${globals.gray800} !important;
  height: 100%;

  &&&:hover {
    color: ${globals.primaryText} !important;
  }
`;
OpenPageLink.displayName = 'S.OpenPageLink';
