/* eslint-disable no-template-curly-in-string */

// eslint-disable-next-line import/no-cycle -- please fix if you can
import {BuiltinPanelDef} from '../builtinPanelDefs';

const def: BuiltinPanelDef = {
  id: 'bar-lite',
  displayName: 'Bar chart (Vega lite)',
  description: 'A simple bar plot implemented with Vega Lite',
  specObj: {
    $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
    description: 'A simple bar chart',
    data: {
      name: 'wandb',
    },
    mark: 'bar',
    encoding: {
      color: {field: '${field:name}', type: 'nominal'},
      y: {field: '${field:name}', type: 'nominal', sort: '-x'},
      x: {field: '${field:value}', type: 'quantitative'},
    },
  },
};

export default def;
