import {MaskControl} from '@wandb/weave/common/components/MediaCard';

export const ZOOM_MIGRATED_TOKEN = 'ZOOM_MIGRATED';
export const WANDB_MASK_CLASS_LABEL_KEY = 'mask/class_labels';
export const WANDB_BBOX_CLASS_LABEL_KEY = 'bounding_box/class_labels';
export const WANDB_3DBBOX_CLASS_LABEL_KEY = '3d_bounding_box/class_labels';
export const WANDB_DELIMETER = '_wandb_delimeter_';
export const DEFAULT_ALL_MASK_CONTROL = {
  disabled: false,
  opacity: 0.6,
} as MaskControl;
export const DEFAULT_CLASS_MASK_CONTROL = {
  disabled: false,
  opacity: 1,
} as MaskControl;
