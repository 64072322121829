/**
 * Why not combine this with utils/url(s)?
 *
 * I'm starting to think that interacting with URLs in the frontend should be isolated as a browser URL is a weird bit of mutable state that
 * should be pushed to the boundaries of the application. It's very rare that you want a URL directly inside app code, rather, what you're after
 * is the state that is encoded in the URL
 *
 * It's true that the browser APIs (URL, URLSearchParams) do expose a lot of the functionality we need, but using them often involves a bunch
 * of companion code (reading the URL, parsing the search string, etc...) that could be encapsulated up into the service.
 *
 * The long-term goal here would be to have a service that sits in between the URL and the app which represents a bit of deserialized `state` that's encoded in the URL string, e.g.
 * URL = `https://wandb.ai/view?entity=foo&workspace=me&readonly&count=10`
 * syndicates to:
 * URLState = `{entity: 'foo', workspace: 'me', readonly: true, count: 10}`
 * which is read directly by the app without needing to worry about decoding
 *
 *
 * For the moment I'm avoiding the hard work of building this service and binding it to URL changes, as that leads to a lot of complexity.
 * I think we might be able skip implementing this in the near-term by using the hooks exposed by React Router.
 * ```
 * const location = useLocation();
 * const stateINeed = getUrlStateByKey('myParam', location.href)
 * ```
 * This keeps the service pure for the moment, while still allowing us to hook into lifecycle stuff as needed
 */

export const URL_STATE_KEYS = {
  TEMPORARY_VIEW: 'temporaryView',
} as const;

/**
 * @param param - a string key of the param in question
 * @param url - an optional URL to inject, defaults to `window.location.href`
 * @returns - the JSON parsed values of the param for ease of use
 */
export function getUrlStateByKey(key: string, url = window.location.href) {
  const urlState = new URL(url);

  const values = urlState.searchParams.getAll(key);

  if (values.length === 0) {
    return null;
  }

  // valueless query params are treated as truthy
  if (values.length === 1 && values[0] === '') {
    return true;
  }

  if (values.length === 1) {
    try {
      return JSON.parse(values[0]);
    } catch (e) {
      // JSON can't parse strings, so return those
      return values[0];
    }
  }

  return values.map(v => {
    try {
      return JSON.parse(v);
    } catch (e) {
      // JSON can't parse strings, so return those
      return v;
    }
  });
}

/**
 * @param key - a string key of the param in question
 * @param url - an optional URL to inject, defaults to `window.location.href`
 * @returns boolean
 */
export function urlStateHasKey(
  key: string,
  url = window.location.href
): boolean {
  const urlState = new URL(url);

  return urlState.searchParams.has(key);
}
