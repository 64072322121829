import * as _ from 'lodash';

import {SmoothingTypeValues} from './../components/elements/SmoothingConfig';
import {CHART_SAMPLES} from './constants';
import {AxisInfoType} from './plotHelpers/types';

export function smooth(
  yValues: number[],
  xAxis: {
    xValues: number[];
  },
  smoothingParameter: number,
  smoothingType: SmoothingTypeValues
) {
  switch (smoothingType) {
    case 'average': {
      return smoothMovingAverage(yValues, smoothingParameter);
    }
    case 'exponential': {
      return smoothExponentialMovingAverage(yValues, smoothingParameter);
    }
    case 'exponentialTimeWeighted': {
      return smoothExponentialMovingAverageAdjusted(
        yValues,
        xAxis,
        smoothingParameter
      );
    }
    case 'gaussian': {
      return smoothGaussian(yValues, xAxis, smoothingParameter);
    }
    default: {
      /**
       * exponential smoothing is default, leaving for historical consistency, i would expect to have a `none` case where we return the y-values, will investigate later
       */
      console.warn(
        'Smoothing type not found, returning exponential moving average'
      );
      return smoothExponentialMovingAverage(yValues, smoothingParameter);
    }
  }
}

export function smoothExponentialMovingAverageAdjusted(
  yValues: number[],
  xAxis: {
    xValues: number[];
  } & Partial<AxisInfoType>,
  smoothingParam: number
) {
  const xValues = xAxis.xValues;

  // avoid smoothing flat lines
  const isConstant = yValues.every(y => y === yValues[0]);
  if (isConstant) {
    return yValues;
  }

  const smoothingWeight = minMaxNormalize(
    Math.sqrt(smoothingParam),
    {min: 0, max: Math.sqrt(0.999)},
    {min: 0, max: 0.999}
  );
  let lastY = yValues.length > 0 ? 0 : NaN;
  let debiasWeight = 0;
  return yValues.map((yPoint, index) => {
    // cannot smoothing non-finite points
    if (!Number.isFinite(yPoint)) {
      return yPoint;
    }

    const prevX = index > 0 ? index - 1 : 0;
    const normalizedDeltaX = minMaxNormalize(
      xValues[index] - xValues[prevX],
      {min: 0, max: xAxis.max ?? 100},
      {min: 0, max: CHART_SAMPLES}
    );
    const smoothingWeightAdj = Math.pow(smoothingWeight, normalizedDeltaX);

    lastY = lastY * smoothingWeightAdj + yPoint;
    debiasWeight = debiasWeight * smoothingWeightAdj + 1;
    return lastY / debiasWeight;
  });
}

/** @deprecated */
export function smoothExponentialMovingAverage(
  data: number[],
  smoothingParam: number
) {
  /**
   * Exponential moving average with zero-debias. Same algothim as tensorboard.
   */

  // for legacy compatibility we pass in the square of the smoothing value as the smoothing
  // parameter for exponential moving average.

  const smoothingWeight = Math.min(Math.sqrt(smoothingParam || 0), 0.999);
  const smoothedData: number[] = [];
  let last = data.length > 0 ? 0 : NaN;
  let numAccum = 0;
  const isConstant = data.every(v => v === data[0]);

  data.forEach(d => {
    const nextVal = d;
    if (isConstant || !Number.isFinite(nextVal)) {
      smoothedData.push(nextVal);
      return;
    } else {
      last = last * smoothingWeight + (1 - smoothingWeight) * nextVal;
      numAccum++;

      let debiasWeight = 1;
      if (smoothingWeight !== 1.0) {
        debiasWeight = 1.0 - Math.pow(smoothingWeight, numAccum);
      }
      smoothedData.push(last / debiasWeight);
    }
  });
  return smoothedData;
}

export function smoothMovingAverage(data: number[], windowLength: number) {
  const smoothedData: number[] = [];
  for (let i = 0; i < data.length; i++) {
    let sum = 0;
    let count = 0;
    for (
      let j = Math.ceil(i - windowLength / 2);
      j < Math.ceil(i + windowLength / 2);
      j++
    ) {
      const clampedJ = clamp(j, {min: 0, max: data.length - 1});
      if (Number.isFinite(data[clampedJ])) {
        sum += data[clampedJ];
        count += 1;
      }
    }
    if (count > 0) {
      smoothedData.push(sum / count);
    } else {
      smoothedData.push(NaN);
    }
  }
  return smoothedData;
}

/**
 * Performs gaussian smoothing and returns an array of same length as data.
 */
export function smoothGaussian(
  yValues: number[],
  xAxis: {
    xValues: number[];
  },
  sigma: number
) {
  const xValues = xAxis.xValues;

  if (yValues.length !== xValues.length) {
    console.warn(
      'Cannot apply gaussian smoothing because x and y axis have different lengths'
    );
    return yValues;
  }
  if (sigma <= 0) {
    console.warn('Sigma must be greater than 0');
    return yValues;
  }

  const sigma2 = sigma * sigma;

  // Following scipy's default here where truncate = 4.0 and radius = round(truncate * sigma)
  // https://github.com/scipy/scipy/blob/87c46641a8b3b5b47b81de44c07b840468f7ebe7/scipy/ndimage/_filters.py#L274-L276
  const radius = Math.ceil(4.0 * sigma + 0.5);

  // https://github.com/scipy/scipy/blob/87c46641a8b3b5b47b81de44c07b840468f7ebe7/scipy/ndimage/_filters.py#L192-L196
  // Example: radius = 5, output should be [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]
  const x = Array.from({length: 2 * radius + 1}, (val, i) => i - radius);
  let weightSum = 0;
  const weights: number[] = [];
  x.forEach(val => {
    const x2 = val * val;
    const w = Math.exp(-x2 / (2 * sigma2));
    weightSum += w;
    weights.push(w);
  });
  const normalizedWeights = weights.map(val => val / weightSum);

  const smoothedData: number[] = [];
  const maxLen = yValues.length;
  for (let i = 0; i < maxLen; i++) {
    let sum = 0.0;
    for (let j = 0; j < normalizedWeights.length; j++) {
      // Change mode if algorithm is over or under smoothing
      // https://docs.scipy.org/doc/scipy/tutorial/ndimage.html
      // https://github.com/scipy/scipy/blob/635d3716b14e000af94b6bf89ba6d53df8ef825e/doc/source/tutorial/ndimage.rst#L142
      let idx = i + j - radius;
      idx = idx < 0 ? -idx - 1 : idx;

      let value = yValues[idx];
      if (idx < 0) {
        value = yValues[-idx - 1];
      } else if (idx >= maxLen) {
        value = yValues[2 * maxLen - idx - 1];
      }
      if (value == null) {
        continue;
      }

      sum += value * normalizedWeights[j];
    }
    smoothedData.push(sum);
  }

  return smoothedData;
}

function minMaxNormalize(
  value: number,
  oldRange: {min: number; max: number},
  newRange: {min: number; max: number}
) {
  return (
    ((value - oldRange.min) / (oldRange.max - oldRange.min)) *
      (newRange.max - newRange.min) +
    newRange.min
  );
}

export function findLineByLeastSquares(valuesX: number[], valuesY: number[]) {
  const n = valuesX.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;

  if (n !== valuesY.length) {
    console.warn('The parameters valuesX and valuesY need to have same size!');
    return {m: NaN, b: NaN, r2: NaN};
  }

  if (n === 0) {
    return {m: NaN, b: NaN, r2: NaN};
  }

  for (let v = 0; v < n; v++) {
    const x = valuesX[v];
    const y = valuesY[v];
    sumX += x;
    sumY += y;
    sumXX += x * x;
    sumXY += x * y;
  }

  // Calculate m and b for the formula:

  const m = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const b = sumY / n - (m * sumX) / n;

  // Calculate r^2

  const mean = _.mean(valuesY);
  let ssr = 0;
  let sst = 0;
  for (let v = 0; v < n; v++) {
    const x = valuesX[v];
    const y = valuesY[v];
    const pred = x * m + b;
    ssr += (y - pred) ** 2;
    sst += (y - mean) ** 2;
  }

  const r2 = 1 - ssr / sst;

  return {m, b, r2};
}

type ClampParams = {
  min?: number;
  max?: number;
};

export function clamp(value: number, {min, max}: ClampParams) {
  let clampedValue = value;
  if (min != null) {
    clampedValue = Math.max(clampedValue, min);
  }
  if (max != null) {
    clampedValue = Math.min(clampedValue, max);
  }
  return clampedValue;
}

// .e.g.
// Rounds the first number to the nearest multiple of the second
//
// 11 , 3  => 9
// 22 , 13 => 13
// 52 , 8 => 5
export function roundToNearestMultiple(value: number, multiple: number) {
  const half = multiple / 2;
  return value + half - ((value + half) % multiple);
}

// Check if v is in the range of x1, x2
export function inRange(v: number, x1: number, x2: number) {
  return v > x1 && v < x2;
}

export function linearToLog(x: number, min: number, max: number): number {
  const b = Math.log(max / min) / (max - min);
  const a = max / Math.exp(b * max);
  return a * Math.exp(b * x);
}
