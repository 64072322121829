import React, {memo, useEffect} from 'react';

import {UpdatePaymentForm} from '../UpdatePaymentForm';
import * as S from './AddPaymentMethodModal.styles';
import {BillingAddressContextProvider} from './BillingAddressContext';

type AddPaymentMethodModalProps = {
  billingUserEmail: string;
  organizationID: string;
  organizationName: string;
  shouldDefault: boolean;
  shouldRetryOpenInvoices: boolean;
  onTransactionCompleted: () => void;
  onClose: () => void;
};

const AddPaymentMethodModalComp = ({
  billingUserEmail,
  organizationID,
  organizationName,
  onTransactionCompleted,
  shouldDefault,
  shouldRetryOpenInvoices,
  onClose,
}: AddPaymentMethodModalProps) => {
  useEffect(() => {
    window.analytics?.track('Add Payment Method Modal Opened', {
      location: 'add payment method modal',
      organizationID,
      organizationName,
      billingUserEmail,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.AddPaymentModal open basic onClose={onClose}>
      <S.AddPaymentFormWrapper>
        <S.Header>
          <S.Title>Add Payment Method</S.Title>
        </S.Header>
        <BillingAddressContextProvider>
          <UpdatePaymentForm
            customerEmail={billingUserEmail}
            organizationId={organizationID}
            onUpdateCompleted={onTransactionCompleted}
            shouldDefault={shouldDefault}
            shouldRetryOpenInvoices={shouldRetryOpenInvoices}
            onClose={onClose}
          />
        </BillingAddressContextProvider>
      </S.AddPaymentFormWrapper>
    </S.AddPaymentModal>
  );
};

export const AddPaymentMethodModal = memo(AddPaymentMethodModalComp);
