import classNames from 'classnames';
import React, {ReactNode, useLayoutEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router';

import {PremiumFeatureTooltip} from '../../../util/accounts/pricing';

const MAX_SIDEBAR_LINK_PIXEL_LEN = 220;
const MAX_PREFIX_TEXT_LEN = 88;

export type SidebarItemProps = {
  dataTest?: string;
  icon: ReactNode;
  prefixText?: string;
  labelText: string;
  url?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isPremiumFeatureDisabled?: boolean;
  newFeatureIndicator?: ReactNode;
};

export const SidebarItem = ({
  dataTest,
  icon,
  prefixText,
  labelText,
  url,
  onClick,
  isDisabled,
  isPremiumFeatureDisabled,
  newFeatureIndicator,
}: SidebarItemProps) => {
  const prefixRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  const [modifiedPrefix, setModifiedPrefix] = useState(prefixText);

  useLayoutEffect(() => {
    const prefixLen = prefixRef.current?.clientWidth ?? 0;
    const labelLen = labelRef?.current?.clientWidth ?? 0;
    const totalLen = prefixLen + labelLen;
    if (
      totalLen >= MAX_SIDEBAR_LINK_PIXEL_LEN ||
      prefixLen > MAX_PREFIX_TEXT_LEN
    ) {
      setModifiedPrefix(prefixText?.substring(0, 8) + '...');
    }
  }, [prefixRef, labelRef, prefixText]);

  const params = useLocation();
  const isSelected = params.pathname === url;

  return (
    <div
      className={classNames(
        'h-36 w-full cursor-pointer rounded',
        isSelected
          ? 'bg-teal-450/10 font-semibold text-teal-550'
          : isDisabled
          ? 'text-moon-500'
          : 'text-moon-800 hover:bg-moon-100 group-hover:bg-moon-100'
      )}
      data-test={dataTest}
      onClick={isDisabled ? () => {} : onClick}>
      <div className={classNames('flex h-full items-center px-12')}>
        {icon}
        <div className="ml-8 flex max-w-[192px] p-0">
          {prefixText && (
            <div ref={prefixRef} className="truncate">
              {modifiedPrefix}
            </div>
          )}
          <div
            ref={labelRef}
            className="truncate"
            data-test={`${dataTest}-label-${labelText}`}>
            {prefixText && '/'}
            {labelText}
          </div>
        </div>
        {isPremiumFeatureDisabled != null && (
          <PremiumFeatureTooltip
            isPremiumFeatureDisabled={isPremiumFeatureDisabled}
          />
        )}
        {newFeatureIndicator}
      </div>
    </div>
  );
};
