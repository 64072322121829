import {randID} from '@wandb/weave/common/util/data';
import {Button} from '@wandb/weave/components';
import {flowRight as compose} from 'lodash';
import * as querystring from 'querystring';
import React, {useCallback, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Header, Modal, Table} from 'semantic-ui-react';

import {
  DeleteIntegrationComponent,
  Integration,
  SlackIntegration as Slack,
  useServerInfoQuery,
} from '../generated/graphql';
import {
  createSlackChannelSubscriptionMutation,
  CreateSlackChannelSubscriptionMutationFn,
} from '../graphql/alerts';
import {
  createSlackIntegrationMutation,
  CreateSlackIntegrationMutationFn,
} from '../graphql/integrations';
import {captureError} from '../integrations';
import {Entity} from '../types/graphql';
import {safeLocalStorage} from '../util/localStorage';
import {InstrumentedLoader as Loader} from './utility/InstrumentedLoader';

export const SLACK_CODE = 'slack';
export const STATE_STORE_KEY = '__slack_oauth_state';

// We store the request type in the oauth state variable
// This way, when the page is loaded with the query params we
// can make the correct integration consume the tokens
const isSlackOAuth = (state: string | string[] | undefined) => {
  if (typeof state === 'string') {
    const codes = state.split(':');
    return (codes && codes[0]) === SLACK_CODE;
  } else {
    return false;
  }
};

export interface SlackIntegrationProps {
  entity: Entity;
  entityRefetch: any;
  activeIntegration: string;
  integrationReason?: string;
  createSlackChannelSubscription: CreateSlackChannelSubscriptionMutationFn; // from withMutations()
  createSlackIntegration: CreateSlackIntegrationMutationFn; // from withMutations()
  hideDisconnect?: boolean;
  disabled?: boolean;
}

export function isSlackIntegration(
  integration: Integration
): integration is Slack {
  return (integration as any).__typename === 'SlackIntegration';
}

export function toSlackIntegration(
  integration: Integration
): Slack | undefined {
  if (isSlackIntegration(integration)) {
    return integration;
  }

  return undefined;
}

const SlackIntegration: React.FC<SlackIntegrationProps> = React.memo(
  ({
    entity,
    entityRefetch,
    integrationReason,
    createSlackIntegration: createSlackIntegrationMtn,
    disabled,
    hideDisconnect,
  }) => {
    const [disconnectSlackModalOpen, setDisconnectSlackModalOpen] =
      useState(false);
    const [slackIntegrationInProgress, setSlackIntegrationInProgress] =
      useState(false);

    const history = useHistory();
    // Grab the current URL -- we'll set this as the redirect URL.
    const redirectUriRef = useRef(window.location.href);

    const serverInfo = useServerInfoQuery();

    const getSlackIntegration = useCallback((): Slack | undefined => {
      for (const integrationEdge of entity.integrations.edges) {
        if (isSlackIntegration(integrationEdge.node)) {
          return toSlackIntegration(integrationEdge.node);
        }
      }

      return undefined;
    }, [entity.integrations.edges]);

    const startSlackOAuth = useCallback(() => {
      window.analytics?.track('Connect Slack Clicked', {location: 'settings'});
      const state = `${SLACK_CODE}:wandb:` + randID(20);
      safeLocalStorage.setItem(STATE_STORE_KEY, state);
      if (!serverInfo.data) {
        alert('unable to access slack configuration');
        return;
      }

      // eslint-disable-next-line wandb/no-unprefixed-urls
      window.open(
        `https://slack.com/oauth/authorize?${querystring.stringify({
          scope: 'incoming-webhook',
          client_id: serverInfo.data?.serverInfo?.slackClientID,
          state,
          redirect_uri: redirectUriRef.current,
        })}`,
        '_self'
      );
    }, [serverInfo.data]);

    const openDisconnectSlackModal = useCallback(
      () => setDisconnectSlackModalOpen(true),
      []
    );

    const closeDisconnectSlackModal = useCallback(
      () => setDisconnectSlackModalOpen(false),
      []
    );

    // Is called on page load when the oauth values are present in the url
    const createSlackIntegration = useCallback((): void => {
      const location = history.location;
      let params = history.location.search;

      // Extract query params
      if (location.search.startsWith('?')) {
        params = location.search.substring(1);
      }

      const queryParams = querystring.parse(params);
      const code = queryParams.code;
      const serverOAuthState = queryParams.state;

      // Only act for relevant responses, determined by a code we put in the state.
      if (!isSlackOAuth(serverOAuthState)) {
        return;
      }

      // The "code" and "state" query params have been injected by Slack OAuth.
      // Remove just these parameters from the URL.
      const searchParams = new URLSearchParams(params);
      searchParams.delete('code');
      searchParams.delete('state');
      history.replace({
        search: searchParams.toString(),
      });

      // Missing code means the user is not going through the integration flow.
      if (code === undefined || typeof code !== 'string') {
        return;
      }

      // Quit if in progress
      if (slackIntegrationInProgress) {
        return;
      }

      // Check that state matches across request to prevent xsrf
      const storedOAuthState = safeLocalStorage.getItem(STATE_STORE_KEY);
      // Reset local state
      safeLocalStorage.removeItem(STATE_STORE_KEY);

      if (storedOAuthState === undefined) {
        captureError('No stored state ', 'slackintegration1', {
          extra: {oAuthState: serverOAuthState, storedOAuthState},
        });
        return;
      }

      // Check auth state to prevent csrf
      if (serverOAuthState !== storedOAuthState) {
        // Scope the response to the specific integration so we don't hoist responses
        captureError(
          "Oauth state doesn't match local value",
          'slackintegratin2',
          {
            extra: {oAuthState: serverOAuthState, storedOAuthState},
          }
        );
      }

      setSlackIntegrationInProgress(true);

      // Strip away the 'code' and 'state' from redirectURI: these are injected from Slack OAuth.
      // If these are included in the redirectURI in the mutation below, we'll get a code_already_used Slack Oauth error
      let redirectUri = redirectUriRef.current;
      const fullRedirectURL = new URL(redirectUri);
      const redirectSearchParams = new URLSearchParams(fullRedirectURL.search);
      redirectSearchParams.delete('code');
      redirectSearchParams.delete('state');
      redirectUri = fullRedirectURL.origin + fullRedirectURL.pathname;
      if (Array.from(redirectSearchParams.keys()).length > 0) {
        redirectUri += `?${redirectSearchParams.toString()}`;
      }

      createSlackIntegrationMtn({
        code,
        redirectURI: redirectUri,
        entityName: entity.name,
      })
        .then(entityRefetch)
        .then(() => {
          setSlackIntegrationInProgress(false);
        });
    }, [
      createSlackIntegrationMtn,
      entity.name,
      entityRefetch,
      history,
      slackIntegrationInProgress,
    ]);

    const slackIntegration = getSlackIntegration();
    if (slackIntegration === undefined) {
      createSlackIntegration();
    }

    const header =
      slackIntegration !== undefined ? (
        <Table className="slack--table" basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{fontWeight: 600}} width={10}>
                Slack integration
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Workspace</Table.Cell>
              <Table.Cell>{slackIntegration.teamName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Channel</Table.Cell>
              <Table.Cell>{slackIntegration.channelName}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        integrationReason && (
          <div className="slack--header-text">{integrationReason}</div>
        )
      );

    if (serverInfo.loading) {
      return <Loader name="server-info-loader" />;
    }

    return (
      <div className="slack">
        <div className="slack--header">{header}</div>
        <DeleteIntegrationComponent onCompleted={entityRefetch}>
          {(deleteIntegrationMutation: any) => {
            if (slackIntegration !== undefined) {
              return (
                <Modal
                  className="slack--disconnect-modal"
                  open={disconnectSlackModalOpen}
                  onClose={closeDisconnectSlackModal}
                  trigger={
                    // make the global settings page the only place allowed to disconnect the Slack integration
                    // in order to prevent people from accidentally clicking it in other instances of this component
                    // such as automated notifications on artifacts
                    hideDisconnect ? (
                      <></>
                    ) : (
                      <Button
                        variant="destructive"
                        size="small"
                        className="p-8"
                        disabled={disabled}
                        onClick={openDisconnectSlackModal}>
                        Disconnect Slack
                      </Button>
                    )
                  }>
                  <Header content="Are you sure you want to disconnect Slack?" />
                  <Modal.Content>
                    <p>We will no longer be able to send you alerts!</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      variant="secondary"
                      size="small"
                      className="p-8"
                      onClick={closeDisconnectSlackModal}>
                      Nevermind
                    </Button>
                    <Button
                      variant="destructive"
                      size="small"
                      className="p-8"
                      disabled={disabled}
                      onClick={() => {
                        deleteIntegrationMutation({
                          variables: {
                            id: getSlackIntegration()!.id,
                          },
                        });

                        closeDisconnectSlackModal();
                      }}>
                      Disconnect
                    </Button>
                  </Modal.Actions>
                </Modal>
              );
            } else {
              return disabled ? null : (
                <Button
                  variant="primary"
                  size="small"
                  className="p-8"
                  disabled={disabled}
                  onClick={() => startSlackOAuth()}>
                  Connect Slack
                </Button>
              );
            }
          }}
        </DeleteIntegrationComponent>
      </div>
    );
  }
);

const withMutations = compose(
  createSlackChannelSubscriptionMutation,
  createSlackIntegrationMutation
) as any;

export default withMutations(SlackIntegration);
