import * as globals from '@wandb/weave/common/css/globals.styles';
import _ from 'lodash';
import React, {
  FC,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

type SearchResultsContainerProps = {};

export const SearchResultsContainer: FC<SearchResultsContainerProps> = ({
  children,
}) => {
  const [resultsScrollbarVisible, setResultsScrollbarVisible] = useState(false);
  const resultsScrollTimeoutIDRef = useRef<number | null>(null);

  const makeScrollbarVisible = useMemo(
    () =>
      _.throttle(() => {
        setResultsScrollbarVisible(true);
        const timeoutID = (resultsScrollTimeoutIDRef.current =
          window.setTimeout(() => {
            if (resultsScrollTimeoutIDRef.current === timeoutID) {
              setResultsScrollbarVisible(false);
            }
          }, 2000));
      }, 300),
    []
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const mouseX = e.nativeEvent.offsetX;
      const containerWidth = e.currentTarget.clientWidth;
      if (mouseX > containerWidth) {
        makeScrollbarVisible();
      }
    },
    [makeScrollbarVisible]
  );

  return (
    <OptionTrap
      id="launcher-option-trap"
      scrollbarVisible={resultsScrollbarVisible}
      onScroll={makeScrollbarVisible}
      onMouseMove={handleMouseMove}>
      {children}
    </OptionTrap>
  );
};

const OptionTrap = styled.ul<{scrollbarVisible: boolean}>`
  ${globals.SCROLLBAR_STYLES}
  margin: 16px 0 0 0;
  padding: 0;
  overflow: auto;
`;
