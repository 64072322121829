import {Line} from '../../../util/plotHelpers/types';
import {distributeAreaPoints, distributePoints} from './distributePoints';
import {
  averagePoint,
  maxPoint,
  minPoint,
  type PointReduceFn,
} from './reduceBucket';
import {AreaPoint, Point} from './types';

/**
 * What are we doing when we aggregate lines?
 * Each line is collection of points, and each point represents an underlying bin.
 * Lines with aux = true have y & y0 points
 * Lines with aux = false are primary lines and just have y points
 *
 * Aggregation is when we condense N lines of type T into a single line of type T. Because lines come in here with aux and !aux we need to condense them into two new lines: the primary line and its associated min/max range.
 *
 * https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1726084638869859
 */

function pointsFromLines(lines: Line[]) {
  return lines.filter(l => !l.aux).flatMap(line => line.data as Point[]);
}

function areaPointsFromLines(lines: Line[]) {
  return lines.filter(l => l.aux).flatMap(line => line.data as AreaPoint[]);
}

const aggOptionToReduceFn: Record<string, PointReduceFn> = {
  mean: averagePoint,
  min: minPoint,
  max: maxPoint,
};

export function aggregateLines(
  lines: Line[],
  meta: {
    groupAgg: string | undefined;
  }
) {
  if (lines.length === 0) {
    return [];
  }

  const baseAvgData = distributePoints(pointsFromLines(lines));
  const minMaxData = distributeAreaPoints(areaPointsFromLines(lines));

  const reduceFn = aggOptionToReduceFn[meta.groupAgg ?? ''] ?? averagePoint;
  const avgData = baseAvgData.map(b => reduceFn(b)).filter(b => !!b);

  const avgLineMeta = lines.find(l => !l.aux);
  const minMaxLineMeta = lines.find(l => l.aux);

  const toReturn = [
    {
      ...avgLineMeta,
      aux: false,
      data: avgData,
      meta: {
        ...avgLineMeta?.meta,
        category: 'grouped',
        mode: 'full-fidelity',
        type: 'line',
      },
      /**
       * Note: this is _ugly_. We shouldn't need to copy this data into the primary line in order to populate the overlay legend. But fixing this is a larger refactor with more blast radius than just copying some extra data, so I'm respecting the conventions of the previous implementation for now. This should be refactored in the future when we revisit how we construct the legend and the overlay which has some serious perf issues.
       */
      minmaxLine: {
        data: minMaxData,
      },
    },
    {
      ...minMaxLineMeta,
      data: minMaxData,
      meta: {
        ...minMaxLineMeta?.meta,
        aggregation: 'minmax',
        category: 'grouped',
        mode: 'full-fidelity',
        type: 'area',
      },
    },
  ] as Line[];

  return toReturn;
}
