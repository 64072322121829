import * as globals from '@wandb/weave/common/css/globals.styles';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Dropdown, Input} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

export const BillingAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  gap: 8px;
`;

export const BillingAddressHeader = styled.label`
  font-size: 16px;
  color: ${globals.gray600};
  margin-bottom: 6px;
  align-self: start;
`;

export const AddressInput = styled(Input)`
  width: 100%;
`;

export const CountryDropdown = styled(Dropdown)`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25rem;
  height: 40px;
  padding: 11px 12px;
  color: ${globals.gray800};

  .text.filtered {
    min-width: 100px;
  }
`;

export const MultipleAddressInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  @media only screen and (max-width: ${globals.tabletBreakpoint}) {
    flex-direction: column;
  }
`;
