import {produce} from 'immer';
import {uniqBy} from 'lodash';
import {ActionType as TSActionType, getType} from 'typesafe-actions';

import {RunDiff} from '../../generated/graphql';
import {fromServerResult, getLastUpdatedWithWindow} from '../../util/runDeltas';
import * as RunTypes from '../../util/runTypes';
import {parseGqlDeltaOp} from '../runs-low/api';
import * as Actions from './actions';

type RunSelectorRunsData = {
  lastUpdatedAt: string;
  runs: RunTypes.Run[];
  totalRuns: number;
};

export const EMPTY_DATA: RunSelectorRunsData = {
  lastUpdatedAt: new Date(0).toISOString(),
  runs: [],
  totalRuns: 0,
};

export type StateType = {
  /**
   * There may be multiple active RunSelectorRun queries on a single page.
   * For example, a report may contain multiple panel grids, which each
   * have a dedicated run selector with its own RSR results. Another example
   * is if grouping is applied, each expanded group is a sub-query.
   * Each key in this state represents the results of a distinct RSR query.
   */
  [queryId: string]: RunSelectorRunsData;
};

export type ActionType = TSActionType<typeof Actions>;

export function runSelectorRuns(state: StateType = {}, action: ActionType) {
  switch (action.type) {
    case getType(Actions.processQueryResults): {
      const {queryId, data} = action.payload;
      const existing = state[queryId] ?? EMPTY_DATA;
      const incoming = {
        delta: data.delta.map(d => parseGqlDeltaOp(d as RunDiff)),
        totalRuns: data.totalCount,
      };
      const combined = fromServerResult(incoming, existing);
      combined.runs = uniqBy(combined.runs, 'id');
      combined.lastUpdatedAt = getLastUpdatedWithWindow(combined.lastUpdatedAt);
      return {
        ...state,
        [queryId]: combined,
      };
    }
    case getType(Actions.removeQuery): {
      const {queryId} = action.payload;
      if (!state[queryId]) {
        return state;
      }
      const nextState = Object.assign({}, state);
      delete nextState[queryId];
      return nextState;
    }
    case getType(Actions.deleteRun): {
      const {runName} = action.payload;
      let newState: StateType | undefined;
      // search each query for the deleted run
      for (const queryId of Object.keys(state)) {
        const data = state[queryId];
        const runIdx = data.runs.findIndex(run => run.name === runName);
        if (runIdx > -1) {
          const runsBefore = data.runs.slice(0, runIdx);
          const runsAfter = data.runs.slice(runIdx + 1);
          const runsWithoutTarget = runsBefore.concat(runsAfter);
          if (!newState) {
            newState = {...state};
          }
          newState[queryId] = {
            ...data,
            runs: runsWithoutTarget,
          };
        }
      }
      return newState ?? state;
    }
    case getType(Actions.updateRun): {
      const {id: runName, vars} = action.payload;
      let newState: StateType | undefined;
      // search each query for the updated run
      for (const queryId of Object.keys(state)) {
        const data = state[queryId];
        const runIdx = data.runs.findIndex(run => run.name === runName);
        if (runIdx > -1) {
          const run = data.runs[runIdx];
          const updatedRun = {...run, ...vars};
          const runsBefore = data.runs.slice(0, runIdx);
          const runsAfter = data.runs.slice(runIdx + 1);
          if (!newState) {
            newState = {...state};
          }
          newState[queryId] = {
            ...data,
            runs: runsBefore.concat(updatedRun).concat(runsAfter),
          };
        }
      }
      return newState ?? state;
    }
    case getType(Actions.updateRunTags): {
      const {tagMap} = action.payload;
      return produce(state, draft => {
        Object.keys(tagMap).forEach(runName => {
          const tags = tagMap[runName];
          Object.values(draft).forEach(data => {
            const draftRun = data.runs.find(run => run.name === runName);
            if (draftRun) {
              draftRun.tags = tags;
            }
          });
        });
      });
    }
    default: {
      return state;
    }
  }
}
