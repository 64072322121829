/*
  This file is being used in the 'frontends/app/host' where it is still having issues resolving components from src/app.
  Do not add code that imports components into this file until that is resolved.
*/

import {
  ACADEMIC_APPLICATION_PATH,
  ACCOUNT_SETTINGS_PATHS,
  ACCOUNT_SETTINGS_USAGE_PAGE_PATHS,
  AGENT_PATHS,
  ARTIFACT_VERSION_PATH,
  BENCHMARK_PATHS,
  ENTITY_LAUNCH_PATHS,
  GALLERY_CREATE_POST,
  GALLERY_PATH,
  GALLERY_PATH_SEGMENT,
  GALLERY_POST_EDIT_PATH,
  GALLERY_POST_VIEW_PATH,
  GLOBAL_HISTORIC_USAGE_PATH,
  GLOBAL_HISTORIC_USAGE_REDIRECT_PATH,
  GLOBAL_HISTORIC_USAGE_REDIRECT_TO_PATH,
  GROUP_PATHS,
  HISTORIC_USAGE_PATH,
  HISTORIC_USAGE_REDIRECT_PATH,
  HISTORIC_USAGE_REDIRECT_TO_PATH,
  HOME_PATH,
  NO_ACCESS_PATH,
  OLD_GALLERY_DISCUSSION_EDIT_PATH,
  OLD_GALLERY_DISCUSSION_VIEW_PATH,
  OLD_REPORT_PAGE_EDIT_PATH,
  OLD_REPORT_PAGE_VIEW_PATH,
  ONBOARDING_FLOW_PATH,
  ORG_BILLING,
  ORG_DASHBOARD,
  ORG_SETTINGS,
  ORG_SETTINGS_PATHS,
  ORG_TEAMS,
  ORG_USER_ACTIVITY,
  PROFILE_PATH,
  PROFILE_PROJECTS_PATH,
  PROFILE_REPORTS_PATH,
  PROFILE_STARS_PATH,
  PROJECT_BASE_PATH,
  PROJECT_PATHS,
  QUEUE_PATHS,
  QUICKSTART_PATH,
  REDIRECT_PATH,
  REGISTRY_PATHS,
  REPORT_PAGE_EDIT_PATH,
  REPORT_PAGE_VIEW_PATH,
  REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
  RUN_PATHS,
  STORAGE_EXPLORER_TRACKED_PATH,
  SWEEP_PATHS,
  TEAM_MEMBERS_PATH,
  TEAM_SERVICE_ACCOUNTS_PATH,
  TEAM_SETTINGS_PATH,
  TRIAL_END_PATH,
  USAGE_PATH,
  USER_SETTINGS_PATH,
} from './paths';

export type SimpleRoute = {
  path: string | string[];
  exact?: true;
  redirect?: string;
};

export const ROUTES: SimpleRoute[] = [
  {
    path: '/__test_page',
    exact: true,
  },
  {
    path: ['/__registry', '/__registry/:tab'],
    exact: true,
  },
  {
    path: ['/__wbench', '/__wbench/:tab'],
    exact: true,
  },
  {
    path: '/',
    exact: true,
  },
  {
    path: '/login',
  },
  {
    path: '/login-redirect',
  },
  {
    path: '/logout',
  },
  {
    path: '/trending',
  },
  {
    path: HOME_PATH,
    exact: true,
  },
  {
    path: QUICKSTART_PATH,
    exact: true,
  },
  {
    path: GALLERY_CREATE_POST,
  },
  {
    path: GALLERY_PATH,
  },
  {
    path: '/fullyconnected',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/fc',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/gallery',
    redirect: `/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: '/__test/simple_error',
    exact: true,
  },
  {
    path: ONBOARDING_FLOW_PATH,
  },
  {
    path: '/change-password',
  },
  {
    path: '/authorize',
  },
  {
    path: USER_SETTINGS_PATH,
    exact: true,
  },
  {
    path: ACCOUNT_SETTINGS_PATHS,
    exact: true,
  },
  {
    path: ACCOUNT_SETTINGS_USAGE_PAGE_PATHS,
    exact: true,
  },
  {
    path: '/admin/audit-logs',
    exact: true,
  },
  {
    path: '/create-team',
    exact: true,
  },
  {
    path: '/admin/organization',
  },
  {
    path: '/admin/users',
  },
  {
    path: ORG_DASHBOARD,
    exact: true,
  },
  {
    path: ORG_TEAMS,
    exact: true,
  },
  {
    path: ORG_USER_ACTIVITY,
    exact: true,
  },
  {
    path: ORG_BILLING,
    exact: true,
  },
  {
    path: '/org/roles',
    redirect: ORG_SETTINGS,
  },
  {
    path: ORG_SETTINGS_PATHS,
    exact: true,
  },
  {
    path: `/admin/${GALLERY_PATH_SEGMENT}`,
  },
  {
    path: `/admin/ramp`,
  },
  {
    path: `/admin/glue`,
  },
  {
    path: '/admin/experiments',
  },
  {
    path: '/admin/home',
  },
  {
    path: [
      USAGE_PATH,
      STORAGE_EXPLORER_TRACKED_PATH,
      '/usage/:entityName/:filePath*',
    ],
    exact: true,
  },
  {
    path: '/storage/:entityName/tracked/:filePath*',
    redirect: STORAGE_EXPLORER_TRACKED_PATH,
  },
  {
    path: '/storage/:entityName/:filePath*',
    redirect: '/usage/:entityName/:filePath*',
  },
  {
    path: '/storage/:entityName',
    redirect: '/usage/:entityName',
  },
  {
    path: '/new-benchmark',
  },
  {
    path: '/:entityname/benchmark/new',
  },
  {
    path: PROFILE_REPORTS_PATH,
    exact: true,
  },
  {
    path: PROFILE_PROJECTS_PATH,
    exact: true,
  },
  {
    path: PROFILE_STARS_PATH,
    exact: true,
  },
  {
    path: TEAM_MEMBERS_PATH,
    exact: true,
  },
  {
    path: TEAM_SERVICE_ACCOUNTS_PATH,
    exact: true,
  },
  {
    path: TEAM_SETTINGS_PATH,
    exact: true,
  },
  // TODO: Remove! Keeping this solely for backwards compatibility
  {
    path: '/teams/:entityName',
    exact: true,
  },
  {
    path: REGISTRY_PATHS,
    exact: true,
  },
  {
    path: `${ARTIFACT_VERSION_PATH}/graph`,
    redirect: `${ARTIFACT_VERSION_PATH}/lineage`,
  },
  {
    path: '/models',
    redirect: '/registry/model',
  },
  {
    path: ENTITY_LAUNCH_PATHS,
    exact: true,
  },
  {
    path: RUN_PATHS,
    exact: true,
  },
  {
    path: GROUP_PATHS,
    exact: true,
  },
  {
    path: '/:entityName/:projectName/create-sweep',
    exact: true,
  },
  {
    path: SWEEP_PATHS,
    exact: true,
  },
  {
    path: AGENT_PATHS,
    exact: true,
  },
  {
    path: QUEUE_PATHS,
    exact: true,
  },
  {
    path: BENCHMARK_PATHS,
    exact: true,
  },
  // {
  //   path: `/:entityName/${PUBLISHED_WORK_PATH_SEGMENT}`,
  //   exact: true,
  // },
  {
    // This old edit report page route will be redirected to /reportlist
    path: OLD_REPORT_PAGE_EDIT_PATH,
    exact: true,
  },
  {
    // This old view report page route will be redirected to /reportlist
    path: OLD_REPORT_PAGE_VIEW_PATH,
    exact: true,
  },
  {
    path: REPORT_PAGE_EDIT_PATH,
    exact: true,
  },
  {
    path: REPORT_PAGE_VIEW_PATH,
    exact: true,
  },
  {
    path: REPORT_PAGE_VIEW_PUBLISHED_WORK_PATH,
    exact: true,
  },
  {
    path: GALLERY_POST_EDIT_PATH,
    exact: true,
  },
  {
    path: GALLERY_POST_VIEW_PATH,
    exact: true,
  },
  {
    path: OLD_GALLERY_DISCUSSION_EDIT_PATH,
    exact: true,
  },
  {
    path: OLD_GALLERY_DISCUSSION_VIEW_PATH,
    exact: true,
  },
  {
    path: '/:entityName/:projectName/querydemo',
    exact: true,
  },
  {
    path: '/:entityName/:projectName/weave-sandbox',
    exact: true,
  },
  {
    path: '/org/:orgName',
    exact: true,
  },
  {
    path: '/repo/:repoName',
    exact: true,
  },
  {
    path: NO_ACCESS_PATH,
    exact: true,
  },
  {
    path: GLOBAL_HISTORIC_USAGE_PATH,
    exact: true,
  },
  {
    path: GLOBAL_HISTORIC_USAGE_REDIRECT_PATH,
    redirect: GLOBAL_HISTORIC_USAGE_REDIRECT_TO_PATH,
  },
  {
    path: HISTORIC_USAGE_PATH,
    exact: true,
  },
  {
    path: HISTORIC_USAGE_REDIRECT_PATH,
    redirect: HISTORIC_USAGE_REDIRECT_TO_PATH,
  },
  {
    path: PROJECT_PATHS,
    exact: true,
  },
  {
    path: REDIRECT_PATH,
    exact: true,
  },
  {
    // this is here to truncate extra path segments after the actual path
    path: PROJECT_BASE_PATH,
    redirect: PROJECT_BASE_PATH,
  },
  {
    path: TRIAL_END_PATH,
    exact: true,
  },
  {
    path: ACADEMIC_APPLICATION_PATH,
    exact: true,
  },
  {
    path: PROFILE_PATH,
    exact: true,
  },
];
