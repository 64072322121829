import {TargetBlank} from '@wandb/weave/common/util/links';
import Color from 'color';
import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {
  Accordion,
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  TextArea,
} from 'semantic-ui-react';

import ColorPicker from '../components/ColorPicker';
import LineStylePicker from '../components/LineStylePicker';
import {getTextColor} from '../util/colors';
import DOC_URLS from '../util/doc_urls';
import {getLegendOverrideKey} from '../util/plotHelpers/legend';
import {Mark, RunDataSeries} from '../util/plotHelpers/types';
import {Query} from '../util/queryTypes';
import {LabeledOptionBlock as LabeledOption} from './elements/LabeledOptionBlock';
import {RunsLinePlotConfig} from './PanelRunsLinePlot/types';
import ProjectFieldSelector from './ProjectFieldSelector';

type PanelConfig = {
  metrics?: string[];
  legendFields?: string[];
  legendTemplate?: string;
  colorEachMetricDifferently?: boolean;
  overrideSeriesTitles?: {[key: string]: string};
  overrideColors?: {[key: string]: {color: string; transparentColor: string}};
  overrideMarks?: {[key: string]: Mark};
};

type PanelLegendProps = {
  type: 'lines' | 'bars';
  config: PanelConfig;
  updateConfig: (newConfig: PanelConfig) => void;
  defaultTitle?: string;
  defaultXAxisTitle?: string;
  defaultYAxisTitle?: string;
  pageQuery: Query; // for the field selector
  defaultLegendTemplate?: string;
  editableLegendSeries: RunDataSeries[];
  singleRun: boolean;
};

const PanelLegend: React.FC<PanelLegendProps> = React.memo(
  ({
    pageQuery,
    singleRun,
    defaultLegendTemplate,
    type,
    editableLegendSeries,
    config,
    updateConfig,
  }) => {
    const [advancedLegendVisible, setAdvancedLegendVisible] =
      React.useState(false);

    return (
      <>
        <LabeledOption
          label="Legend"
          helpText="Set variables in the chart legend"
          docUrl={DOC_URLS.compareMetrics + '#legend'}
          option={
            <ProjectFieldSelector
              className="legend"
              disabled={!config.metrics || config.metrics.length === 0}
              query={pageQuery}
              types={['string', 'number', 'boolean']}
              defaultKeys={[
                'run:displayName',
                'run:name',
                'run:createdAt',
                'run:userName',
              ]}
              selection
              fluid
              multi
              value={config.legendFields || ['run:displayName']}
              searchByKeyAndText
              setValue={value =>
                updateConfig({legendFields: value, legendTemplate: undefined})
              }
            />
          }
        />
        {config.metrics != null && config.metrics.length >= 2 && (
          <LabeledOption
            label="Color Metrics"
            helpText="Use different colors for each metric"
            option={
              <Checkbox
                toggle
                checked={config.colorEachMetricDifferently ?? singleRun}
                onClick={(e, value) =>
                  updateConfig({
                    colorEachMetricDifferently: value.checked,
                  })
                }
              />
            }
          />
        )}
        <Accordion className="advanced-options">
          <Accordion.Title
            active={advancedLegendVisible}
            onClick={() => {
              setAdvancedLegendVisible(cur => !cur);
            }}>
            <Icon name="dropdown" />
            Advanced Legend
          </Accordion.Title>
          <Accordion.Content active={advancedLegendVisible}>
            <p className="advanced-legend-info chart-label">
              {`Edit the legend template directly. For example:`}
              <br />
              {`\${config:epochs} displays the config value epochs for each line.`}
              <br />
              {`\${summary:loss} displays the summary value loss for each line.`}
              <br />
              {`[[ $x: $y ]] to displays history values in the tooltip hovering over a line.`}
              <br />

              <TargetBlank href={DOC_URLS.compareMetrics + '#legend'}>
                See the docs →
              </TargetBlank>
            </p>
            <TextArea
              value={config.legendTemplate ?? defaultLegendTemplate}
              className="chart-legend-template"
              onChange={(e, {value}) => {
                updateConfig({legendTemplate: value?.toString()});
              }}
            />
          </Accordion.Content>
        </Accordion>

        <OverrideDataSeriesConfig
          editColor
          editMark={type === 'lines'}
          editLineWidths={type === 'lines'}
          config={config}
          updateConfig={updateConfig}
          singleRun={singleRun}
          editableLegendSeries={editableLegendSeries}
        />
      </>
    );
  }
);

export default PanelLegend;

function getLegendDisplayName(line: RunDataSeries): string {
  if (!line.displayName) {
    return '';
  }
  return `${line.displayName} ${line.metricName ?? ''}`;
}

type OverrideDataSeriesConfigProps = {
  config: RunsLinePlotConfig;
  updateConfig: (newConfig: RunsLinePlotConfig) => void;
  editableLegendSeries: RunDataSeries[];
  editColor: boolean;
  editMark: boolean;
  editLineWidths: boolean;
  singleRun: boolean;
};
const OverrideDataSeriesConfig: React.FC<OverrideDataSeriesConfigProps> =
  React.memo(
    ({
      config,
      updateConfig,
      editColor,
      editMark,
      editLineWidths,
      editableLegendSeries,
      singleRun,
    }) => {
      if (editableLegendSeries.length === 0) {
        return <p>No editable lines on this chart.</p>;
      }
      const thicknessOptions = [0.5, 1, 1.5, 2, 3].map(val => ({
        key: val,
        text: `${val}px`,
        value: val,
      }));

      return (
        <div className="legend-options">
          {editableLegendSeries.map((l, i) => {
            const lineKey = getLegendOverrideKey(l, !singleRun);
            const lineKeyDisplayName = getLegendDisplayName(l);
            const usingOverride =
              config.overrideSeriesTitles?.[lineKey] != null ||
              config.overrideColors?.[lineKey] != null ||
              config.overrideMarks?.[lineKey] != null;

            const runColor = config.overrideColors?.[lineKey]?.color ?? l.color;

            return (
              <div key={i} className="legend-option">
                <div className="series-name">
                  <p
                    className="series-name-value"
                    style={{
                      color: getTextColor(runColor),
                    }}>
                    {lineKeyDisplayName}
                  </p>
                </div>
                <div className="override-name">
                  {editColor && (
                    <ColorPicker
                      activeColor={runColor || '#999999'}
                      // With current optimizations, some components do not update on function prop changes.
                      // To avoid a stale callback, we include an empty style prop to force a re-render.
                      // (This is a workaround, not the ideal solution.)
                      // Refer to shouldUpdate.ts : L16
                      style={{}}
                      setRunColor={value => {
                        const newOverrideColors = {...config.overrideColors};
                        const colors = {
                          color: value,
                          transparentColor: Color(value).alpha(0.1).toString(),
                        };

                        newOverrideColors[lineKey] = colors;
                        updateConfig({
                          overrideColors: newOverrideColors,
                        });
                      }}
                    />
                  )}
                  {editMark && (
                    <LineStylePicker
                      activeLineStyle={
                        config.overrideMarks?.[lineKey] ||
                        editableLegendSeries[i].mark ||
                        'solid'
                      }
                      setLineStyle={(mark: Mark) => {
                        const newOverrideMarks = {...config.overrideMarks};
                        newOverrideMarks[lineKey] = mark;
                        updateConfig({
                          overrideMarks: newOverrideMarks,
                        });
                      }}
                    />
                  )}
                  {editLineWidths && (
                    <Dropdown
                      className="line-width-picker"
                      defaultValue={config.overrideLineWidths?.[lineKey] || 1}
                      selection
                      options={thicknessOptions}
                      onChange={(e, {value}) => {
                        const newOverrideLineWidths = {
                          ...config.overrideLineWidths,
                        };

                        newOverrideLineWidths[lineKey] = Number.parseFloat(
                          (value ?? 1).toString()
                        );
                        updateConfig({
                          overrideLineWidths: newOverrideLineWidths,
                        });
                      }}
                    />
                  )}
                  <Input
                    className="override-value"
                    defaultValue={l.title?.toString()}
                    onChange={(e, {value}) => {
                      const newOverrideLineTitles = {
                        ...config.overrideSeriesTitles,
                      };

                      newOverrideLineTitles[lineKey] = value;
                      updateConfig({
                        overrideSeriesTitles: newOverrideLineTitles,
                      });
                    }}
                  />
                  <Button
                    icon
                    disabled={!usingOverride}
                    onClick={(e, {value}) => {
                      const newOverrideSeriesTitles = {
                        ...config.overrideSeriesTitles,
                      };
                      delete newOverrideSeriesTitles[lineKey];

                      const newOverrideColors = {...config.overrideColors};
                      delete newOverrideColors[lineKey];

                      const newOverrideMarks = {...config.overrideMarks};
                      delete newOverrideMarks[lineKey];

                      updateConfig({
                        overrideSeriesTitles: newOverrideSeriesTitles,
                        overrideColors: newOverrideColors,
                        overrideMarks: newOverrideMarks,
                      });
                    }}>
                    <Icon name="sync" />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  );
