// eslint-disable-next-line wandb/no-deprecated-imports
import moment from 'moment';
import {useCallback} from 'react';

import {
  EntityIsTeamQueryVariables,
  useEntityIsTeamQuery,
  useUpdateUserInfoMutation,
} from '../generated/graphql';
import {getRouteMatch} from '../routes/utils';
import {User, UserInfo} from '../types/graphql';

export enum UserLockdownState {
  Warned = 'WARNED',
  Locked = 'LOCKED',
  Cleared = 'CLEARED',
}

const lockablePathPrefixes: string[] = [
  '/:entityName/:projectName',
  '/:entityName/launch',
];

export function useIsLockablePersonalEntityLocation(pathname: string): boolean {
  const isLockable = isLockablePathname(pathname);

  const routeMatch = getRouteMatch(pathname);

  const params = routeMatch?.params as EntityIsTeamQueryVariables;
  const entityName = params?.entityName;

  const isEntityNameQueryIrrelevant = !isLockable || !entityName;

  const {data, loading, error} = useEntityIsTeamQuery({
    skip: isEntityNameQueryIrrelevant,
    variables: {
      entityName,
    },
  });

  if (isEntityNameQueryIrrelevant || loading || error) {
    return false;
  }

  const isPersonalEntityLocation = data?.entity?.isTeam === false;

  return isPersonalEntityLocation;
}

export function isLockablePathname(pathname: string): boolean {
  const routeMatch = getRouteMatch(pathname);

  const path = routeMatch?.path;

  if (!path) {
    return false;
  }

  const isLockablePath = lockablePathPrefixes.some(prefix =>
    path.startsWith(prefix)
  );

  return isLockablePath;
}

export function getUserLockdownDate(user: Pick<User, 'userInfo'>): Date | null {
  const lockdownDateString = user.userInfo?.userLockdownDate ?? null;
  if (lockdownDateString == null) {
    return null;
  }

  return new Date(lockdownDateString);
}

export function getUserLockdownStateFromDate(
  lockdownDate: Date | null
): UserLockdownState {
  if (lockdownDate == null) {
    return UserLockdownState.Cleared;
  }
  const now = moment.now();

  if (moment(lockdownDate).isAfter(now)) {
    return UserLockdownState.Warned;
  } else {
    return UserLockdownState.Locked;
  }
}

export function useUpdateUserLockdownDate() {
  const [updateUserInfo] = useUpdateUserInfoMutation();
  const updateUserLockdownDate = useCallback(
    (user: Pick<User, 'id' | 'userInfo'>, userLockdownDateObj: Date | null) => {
      const newUserLockdownDateString: string | undefined =
        userLockdownDateObj?.toISOString();
      const newUserInfo: UserInfo = {
        ...user.userInfo,
        userLockdownDate: newUserLockdownDateString,
      };
      return updateUserInfo({
        variables: {
          id: user.id,
          userInfo: JSON.stringify(newUserInfo),
        },
      });
    },
    [updateUserInfo]
  );
  return updateUserLockdownDate;
}
