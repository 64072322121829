// import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import React from 'react';

// import ReactDOM from 'react-dom';
import * as CustomRunColorsViewTypes from '../../state/views/customRunColors/types';
import * as PanelViewTypes from '../../state/views/panel/types';
import * as RunSetViewTypes from '../../state/views/runSet/types';
// import {PanelCompRedux} from '../../util/panels';
// import {Inspector} from '../Inspector';
// import * as S from './SinglePanelInspectorContainer.styles';

interface SinglePanelInspectorContainerProps {
  panelRef: PanelViewTypes.Ref;

  runSetRefs: RunSetViewTypes.Ref[];
  customRunColorsRef: CustomRunColorsViewTypes.Ref;

  onClose(): void;
}

export const SinglePanelInspectorContext = React.createContext(false);

export const SinglePanelInspectorContextProvider: React.FC<{
  children: React.ReactNode;
}> = props => {
  return (
    <SinglePanelInspectorContext.Provider value={true}>
      {props.children}
    </SinglePanelInspectorContext.Provider>
  );
};

export const useIsRenderingInPopup = () => {
  return React.useContext(SinglePanelInspectorContext);
};

// Inspector is an old prototype of panel multi-edit in a sidebar. Its not
// used and was causing circular imports.
// export const SinglePanelInspectorContainer: React.FC<
//   SinglePanelInspectorContainerProps
// > = props => {
//   const wrapperRef = React.useRef<HTMLDivElement>(null);
//   // const [previewPos, setPreviewPos] = React.useState<{
//   //   x: number;
//   //   y: number;
//   // } | null>(null);

//   const refs = React.useMemo(() => [props.panelRef], [props.panelRef]);
//   React.useEffect(() => {
//     const wrapperNode = wrapperRef.current;
//     if (!wrapperNode) {
//       return;
//     }
//     disableBodyScroll(wrapperNode);
//     return () => {
//       enableBodyScroll(wrapperNode);
//     };
//   }, []);
//   return ReactDOM.createPortal(
//     <SinglePanelInspectorContextProvider>
//       <S.Wrapper ref={wrapperRef}>
//         <S.VisPreviewerWrapper
//           onMouseDown={() => {
//             props.onClose();
//           }}>
//           <S.VisPreviewer
//             onMouseDown={e => e.stopPropagation()}
//             // pos={previewPos}
//           >
//             <PanelCompRedux
//               panelRef={props.panelRef}
//               // cruft
//               configMode={false}
//               currentHeight={320}
//               dimensions={undefined as any}
//             />
//           </S.VisPreviewer>
//         </S.VisPreviewerWrapper>
//         <Inspector
//           panelRefs={refs}
//           runSetRefs={props.runSetRefs}
//           //
//           // This would make the preview make way for the popout. But the container no longer knows
//           // about the popout node. May use or delete later.
//           //
//           // onPopoutChange={node => {
//           //   console.log('rect', node?.getBoundingClientRect());
//           //   if (node) {
//           //     const rect = node.getBoundingClientRect();
//           //     const centerX = (window.innerWidth - 280) / 2;
//           //     const centerY = window.innerHeight / 2;
//           //     const [x, xDist] = makeRoomFor(
//           //       rect.left,
//           //       rect.width,
//           //       600,
//           //       centerX
//           //     );
//           //     const [y, yDist] = makeRoomFor(
//           //       rect.top,
//           //       rect.height,
//           //       400,
//           //       centerY
//           //     );
//           //     if (xDist < yDist) {
//           //       setPreviewPos({
//           //         x,
//           //         y: centerY - 200,
//           //       });
//           //     } else {
//           //       setPreviewPos({x: centerX - 300, y});
//           //     }
//           //   } else {
//           //     setPreviewPos(null);
//           //   }
//           // }}
//         ></Inspector>
//       </S.Wrapper>
//     </SinglePanelInspectorContextProvider>,
//     document.body
//   );
// };

// // function makeRoomFor(l: number, w: number, pw: number, c: number) {
// //   let r = c - pw / 2;
// //   if (l < c + pw / 2 && l + w > c - pw / 2) {
// //     if (c - l < l + w - c) {
// //       r = l - pw;
// //     } else {
// //       r = l + w;
// //     }
// //   }
// //   return [r, Math.abs(r - (c - pw / 2))];
// // }
