import {denormFn, normFn} from '../normalizerSupport';
import * as Types from './types';

export const normalize = normFn<Types.DiscussionCommentObjSchema>(
  'discussion-comment',
  whole => whole
);

export const denormalize = denormFn<Types.DiscussionCommentObjSchema>(
  part => part
);
