import {ApolloLink, FetchResult, Observable} from 'apollo-link';

import {logError} from '../services/errors/errorReporting';
import {getCacheFileName, logPerfAndErrors} from './opfs';
import {startPerfTimer} from './profiler';

const cacheName = 'wandb-graphql-cache';
const cacheVersion = 'v1';

const queryAllowList = ['Views2RawView', 'Views2View', 'HistoryKeys'];

function logPerfAndErrorsSync<T>(operationName: string, fn: () => T): T {
  const {endPerfTimer} = startPerfTimer(operationName);
  try {
    return fn();
  } finally {
    endPerfTimer();
  }
}

let useCacheLink = false;

export const enableCacheLink = () => {
  useCacheLink = true;
};

let openedCache: Cache | null = null;
let browserHasCache = 'caches' in self;

async function getCache(): Promise<Cache | null> {
  if (openedCache == null && browserHasCache) {
    try {
      openedCache = await caches.open(`${cacheName}-${cacheVersion}`);
    } catch (error) {
      logError(`Error opening cache: ${error}, caching disabled`);
      browserHasCache = false;
    }
  }
  return openedCache;
}

/**
 * A custom Apollo Link that provides caching capabilities for specific GraphQL queries using the browser's Cache API.
 *
 * This link intercepts GraphQL operations and checks if they are in a predefined list of queries allowed for caching (`queryAllowList`).
 * For these queries, it attempts to retrieve a cached response from the browser's cache storage and emits it immediately if available.
 * It then forwards the operation to the next link to fetch fresh data from the network.
 * Once the network response is received, it updates the cache with the new data and emits the result to the observer.
 * For operations not in the allow list, it simply forwards them without any caching.
 *
 * The caching logic involves generating a unique cache key based on the operation name and variables using `getCacheFileName`,
 * and asynchronously accessing the cache using the Cache API.
 *
 * The link also handles performance logging and error handling using `logPerfAndErrors` and `logPerfAndErrorsSync` utility functions.
 *
 * @constant
 * @type {ApolloLink}
 * @param {Operation} operation - The GraphQL operation object containing details like operation name and variables.
 * @param {function} forward - A function to forward the operation to the next link in the chain.
 * @returns {Observable<FetchResult>} An Observable that emits cached data if available, then network data once retrieved.
 */
export const cacheLink = new ApolloLink((operation, forward) => {
  if (!useCacheLink) {
    return forward(operation);
  }

  return new Observable<FetchResult>(observer => {
    let subscription: ZenObservable.Subscription | null = null;
    let isComplete = false;

    async function processCacheAndNetwork() {
      try {
        const {operationName, variables} = operation;
        const cache = await getCache();
        if (queryAllowList.includes(operationName) && cache) {
          const cacheKey = getCacheFileName(operationName, variables);
          if (cacheKey == null) {
            throw new Error('Cache key is null');
          }
          const request = new Request(cacheKey);

          const cachedResponse = await logPerfAndErrors(
            'cache.match',
            () => cache.match(request),
            'CacheLink'
          );

          if (cachedResponse) {
            const cachedData = await logPerfAndErrors(
              'cachedResponse.json',
              () => cachedResponse.json(),
              'CacheLink'
            );
            observer.next(cachedData); // Emit cached data immediately
          }

          // Fetch data from the network
          const promises: Promise<void>[] = [];

          subscription = forward(operation).subscribe({
            next: result => {
              const promise = (async () => {
                const responseToCache = logPerfAndErrorsSync(
                  'CacheLink: serialize JS object to string',
                  () => new Response(JSON.stringify(result))
                );
                await logPerfAndErrors(
                  'cache.put',
                  () => cache.put(request, responseToCache),
                  'CacheLink'
                );

                observer.next(result); // Emit network data
              })();
              promises.push(promise);
            },
            error: networkError => {
              if (!isComplete) {
                observer.error(networkError);
              }
            },

            complete: () => {
              Promise.all(promises).then(() => {
                if (!isComplete) {
                  observer.complete();
                }
              });
            },
          });
        } else {
          // For everything else, just forward the operation
          subscription = forward(operation).subscribe(observer);
        }
      } catch (error) {
        if (!isComplete) {
          observer.error(error);
        }
      }
    }

    processCacheAndNetwork();

    return () => {
      isComplete = true;
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  });
});
