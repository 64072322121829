import {Button} from '@wandb/weave/components/Button';
import {TextField} from '@wandb/weave/components/Form/TextField';
import React from 'react';

import {backendHost} from '../config';
import * as S from '../pages/OnboardingFlow.styles';
import OnboardingFlowSkinning from '../pages/OnboardingFlowSkinning';
import {auth} from '../setup';
import {useHideNavOnPage} from './NavContextProvider';

const VerifyEmail: React.FC<{email?: string}> = ({email}) => {
  useHideNavOnPage();

  const [verifyEmailClicked, setVerifyEmailClicked] = React.useState(false);
  const verifyEmail = React.useCallback(async () => {
    try {
      const resp = await fetch(backendHost('/email'), {
        method: 'POST',
        credentials: 'include',
      });

      if (!resp.ok) {
        const msg = await resp.text();
        console.log('Error sending verification email: ', msg);
      }
    } catch (err) {
      console.log('Failed to request verification email.');
    }
  }, []);

  const dialogContent = (
    <>
      <h2 className="mb-5 font-['Source_Serif_Pro'] text-xl font-semibold text-moon-800 sm:text-3xl">
        Verify your email
      </h2>
      <div className="mb-20">
        Check your inbox to get your email address verified.{' '}
      </div>
      <S.FormRow>
        <S.Header>Email</S.Header>
        <TextField size="large" value={email} disabled={true} />
      </S.FormRow>
      <div className="flex w-full justify-between">
        <Button
          className="w-full flex-grow"
          size="large"
          onClick={() => auth.login()}>
          I already verified
        </Button>
        <Button
          size="large"
          className="ml-12 w-full flex-grow"
          disabled={verifyEmailClicked}
          variant="secondary"
          onClick={async () => {
            verifyEmail();
            setVerifyEmailClicked(true);
          }}>
          {verifyEmailClicked ? 'Sent!' : 'Re-send email'}
        </Button>
      </div>
      <div className="mt-16">
        Not finding the email? Please check your spam folder. Or if you'd
        prefer,{' '}
        <button
          className="font-semibold text-teal-600"
          onClick={() => auth.login()}>
          login with a different email
        </button>
        .
      </div>
    </>
  );

  return <OnboardingFlowSkinning dialogContent={dialogContent} />;
};

export default VerifyEmail;
