import {Icon, IconAddNew} from '@wandb/weave/components/Icon';
import {Tooltip} from '@wandb/weave/components/Tooltip';
import _ from 'lodash';
import React, {memo, useContext, useMemo, useState} from 'react';

import defaultTeamAvatarPng from '../../../assets/team-placeholder-small.png';
import {BannerFlexWrapper} from '../../../components/Banner';
import {CreateTeamButton} from '../../../components/CreateTeamModal';
import {
  useNavContext,
  useNavUpdaterContext,
} from '../../../components/NavContextProvider';
import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../../../components/Search/SearchNav/types';
import {envIsLocal} from '../../../config';
import {PlanType, useUserOrganizationsQuery} from '../../../generated/graphql';
import {useViewer} from '../../../state/viewer/hooks';
import {Graph} from '../../../types/graphql';
import {isTrialOrg} from '../../../util/accounts/pricing';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import * as urls from '../../../util/urls';
import {CreateTeamDrawer} from './CreateTeamDrawer';
import * as S from './HomePageSidebar.styles';
import {SidebarItem} from './SidebarItem';
import {SidebarLink} from './SidebarLink';
import {SidebarSectionHeader} from './SidebarSectionHeader';
import {useAccountTeamsList} from './useAccountTeamsList';

interface TeamsListProps {
  data: Graph<any>;
  name: string;
  userQueryRefetch: () => Promise<any>;
}

const CREATE_TEAM_BUTTON_LABEL = 'Create a team to collaborate';
const CREATE_TEAM_BUTTON_DATA_TEST = 'create-team-link';

const TeamsListComp = React.memo(({data, userQueryRefetch}: TeamsListProps) => {
  const viewer = useViewer() ?? null;
  const {isCreatingTeam} = useNavContext();
  const {toggleTeamDrawer} = useNavUpdaterContext();
  const allTeams = _.reject(data.edges, n => {
    return n.node.name === viewer?.username;
  });
  const enableAccountSelector = useRampFlagAccountSelector();
  const {selectedAccount} = useContext(AccountSelectorContext);
  const {accountTeams, loading} = useAccountTeamsList();
  const teams = enableAccountSelector
    ? accountTeams?.edges?.map(team => team?.node) ?? []
    : allTeams?.map(team => team?.node) ?? [];
  const [isFolderExpanded, setIsFolderExpanded] = useState(false);

  const userOrganizationsQueryResult = useUserOrganizationsQuery();
  const viewerNotAdminOfSelectedOrg = useMemo(() => {
    const userOrganizations =
      userOrganizationsQueryResult?.data?.viewer?.organizations ?? [];
    const selectedUserOrg =
      userOrganizations.find(o => o.id === selectedAccount?.id) ?? null;
    return (
      selectedUserOrg != null &&
      viewer != null &&
      !selectedUserOrg.members.some(m => m.id === viewer.id && m.admin)
    );
  }, [selectedAccount?.id, userOrganizationsQueryResult?.data, viewer]);

  const selectedOrgHasNoRemainingTeamSlots = useMemo(() => {
    const userOrganizations =
      userOrganizationsQueryResult?.data?.viewer?.organizations ?? [];
    const selectedUserOrg =
      userOrganizations.find(o => o.id === selectedAccount?.id) ?? null;
    const primarySub = selectedUserOrg?.subscriptions?.find(
      s => s.plan.planType === PlanType.Primary
    );
    const numTeams = selectedUserOrg?.teams?.length ?? 0;
    const maxTeams: number = primarySub?.privileges?.num_private_teams ?? 1;
    return numTeams >= maxTeams;
  }, [selectedAccount?.id, userOrganizationsQueryResult?.data]);

  const otherTrialOrg = useMemo(() => {
    return userOrganizationsQueryResult?.data?.viewer?.organizations?.find(
      org => isTrialOrg(org) && org.id !== selectedAccount?.id
    );
  }, [
    userOrganizationsQueryResult?.data?.viewer?.organizations,
    selectedAccount?.id,
  ]);

  let createTeamDrawer = (
    <>
      <SidebarItem
        dataTest={CREATE_TEAM_BUTTON_DATA_TEST}
        icon={<IconAddNew />}
        labelText={CREATE_TEAM_BUTTON_LABEL}
        onClick={() => toggleTeamDrawer()}
        isDisabled={selectedOrgHasNoRemainingTeamSlots}
        isPremiumFeatureDisabled={selectedOrgHasNoRemainingTeamSlots}
      />
      <CreateTeamDrawer
        open={isCreatingTeam}
        toggleTeamDrawer={toggleTeamDrawer}
        analyticsLocation="homepage sidebar"
      />
    </>
  );
  if (viewerNotAdminOfSelectedOrg && !selectedOrgHasNoRemainingTeamSlots) {
    createTeamDrawer = (
      <Tooltip
        position="top center"
        trigger={
          <div>
            <SidebarItem
              dataTest={CREATE_TEAM_BUTTON_DATA_TEST}
              icon={<IconAddNew />}
              labelText={CREATE_TEAM_BUTTON_LABEL}
              isDisabled
              isPremiumFeatureDisabled={false} // has remaining team slots, so premium feature is enabled
            />
          </div>
        }
        content={
          <BannerFlexWrapper>
            <Icon name="warning" width={24} height={24} />
            Please ask your organization administrator to create a new team
          </BannerFlexWrapper>
        }
      />
    );
  }
  if (
    selectedAccount?.accountType === AccountType.Personal &&
    otherTrialOrg != null
  ) {
    createTeamDrawer = (
      <Tooltip
        position="top center"
        trigger={
          <div>
            <SidebarItem
              dataTest={CREATE_TEAM_BUTTON_DATA_TEST}
              icon={<IconAddNew />}
              labelText={CREATE_TEAM_BUTTON_LABEL}
              isDisabled
              isPremiumFeatureDisabled={true} // premium feature is disabled because they already have a trial org that needs to be upgraded
            />
          </div>
        }
        content={
          <BannerFlexWrapper>
            <Icon name="warning" width={24} height={24} />
            To create a new team, upgrade {otherTrialOrg.name}
          </BannerFlexWrapper>
        }
      />
    );
  }

  if (loading) {
    return <></>;
  }
  let addTeamsComp = null;
  if (envIsLocal || viewer == null) {
    // This is a bit janky, but we should only show the "create team" modal if
    // we're in any kind of onprem env, or if we don't have the viewer info yet.
    // Otherwise we should direct the user to '/create-team'.
    addTeamsComp = (
      <CreateTeamButton
        data-test="create-team-button"
        onCreate={() => userQueryRefetch()}
        renderButton={onClick => (
          <SidebarLink
            dataTest="create-team-link"
            icon={<IconAddNew />}
            labelText="Create new team"
            onClick={() => {
              onClick();
              window.analytics?.track('Create Team Started', {
                location: 'sidebar',
              });
            }}
          />
        )}
      />
    );
  } else if (enableAccountSelector && selectedAccount) {
    addTeamsComp = <div>{createTeamDrawer}</div>;
  } else {
    addTeamsComp = (
      <SidebarLink
        dataTest="create-team-link"
        icon={<IconAddNew />}
        labelText="Create new team"
        url={urls.createTeam()}
      />
    );
  }

  return (
    <S.SidebarList>
      <SidebarSectionHeader headerText="Teams" />
      {teams.slice(0, isFolderExpanded ? teams.length : 5).map(team => {
        return (
          <SidebarLink
            dataTest="team-link"
            key={team.name}
            icon={
              <S.AvatarImage
                alt="Home nav team avatar"
                src={team.photoUrl ?? defaultTeamAvatarPng}
              />
            }
            labelText={team.name}
            url={`/${team.name}`}
          />
        );
      })}
      {teams.length > 5 && (
        <S.SidebarListItemFolderExpand
          button
          onClick={() => setIsFolderExpanded(!isFolderExpanded)}>
          <S.SidebarListItemText>
            {isFolderExpanded ? 'View less' : 'View more'}
          </S.SidebarListItemText>
        </S.SidebarListItemFolderExpand>
      )}
      {addTeamsComp}
    </S.SidebarList>
  );
});

export const TeamsList = memo(TeamsListComp);
