import {isAfter, isBefore} from '../../util/date';

export const compareDates = (aDate: Date | string, bDate: Date | string) => {
  const a = new Date(aDate);
  const b = new Date(bDate);
  if (isBefore(a, b)) {
    return 1;
  }
  if (isAfter(a, b)) {
    return -1;
  }
  return 0;
};
