import * as React from 'react';

import {convertLineToBar} from '../../util/plotHelpers/lines';
import {BarChart as VisBarChart} from '../vis/BarChart/BarChart';
import {RunsLinePlotPanelInnerProps} from './types';
import {isValidLine} from './utils/checkTypes';

export const BarChart = React.memo((props: RunsLinePlotPanelInnerProps) => {
  const {lines, config} = props;
  const filteredLines = lines.filter(isValidLine);
  const bars = filteredLines.map(convertLineToBar);

  return (
    <VisBarChart
      bars={bars}
      min={config.yAxisMin}
      max={config.yAxisMax}
      showAllLabels
    />
  );
});

BarChart.displayName = 'BarChart';
