import {WeaveFeatures} from '@wandb/weave/context';
import {useMemo} from 'react';

import {useBetaFeature} from './useBetaFeature';

export const useWeaveFeatures = (
  overrides?: Partial<WeaveFeatures>
): WeaveFeatures => {
  const weaveBetaFeatureEcosystem = useBetaFeature(
    'weave-python-ecosystem'
  ).isEnabled;
  const weaveBetaFeatureDevPopup = useBetaFeature('weave-devpopup').isEnabled;
  return useMemo(() => {
    return Object.assign(
      {
        actions: false,
        fullscreenMode: false,
        betaFeatures: {
          'weave-python-ecosystem': weaveBetaFeatureEcosystem,
          'weave-devpopup': weaveBetaFeatureDevPopup,
        },
      },
      overrides
    );
  }, [overrides, weaveBetaFeatureDevPopup, weaveBetaFeatureEcosystem]);
};
