import _ from 'lodash';
import {useMemo} from 'react';

import {
  OrganizationSubscriptionType,
  useOrganizationSubscriptionsBasicInfoQuery,
  useViewerUserInfoQuery,
} from '../../../generated/graphql';
import {getAccountType} from '../../../pages/Billing/AccountSettings/util';
import {PlanName} from '../../../pages/Billing/util';
import {useViewer} from '../../../state/viewer/hooks';
import {getPrimarySub} from '../../../util/accounts/pricing';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import {Account, AccountType} from './types';

type AccountList = {
  accountList: Account[];
  isLoading: boolean;
};
// returned value accountList should always have the personal account type
export function useAccountList(): AccountList {
  const viewer = useViewer();
  const {
    data: viewerOrgsResult,
    loading: organizationSubscriptionQueryLoading,
  } = useOrganizationSubscriptionsBasicInfoQuery({
    fetchPolicy: 'cache-and-network',
  });
  const enableAccountSelector = useRampFlagAccountSelector();
  const orgAccounts = useMemo(() => {
    return viewerOrgsResult?.viewer?.organizations.map(org => {
      const accountType = getAccountType(org);
      const subscription = getPrimarySub(org);
      return {
        name:
          accountType === AccountType.Personal
            ? viewer?.username ?? ''
            : org.name,
        orgType: org.orgType,
        accountType,
        id: accountType === AccountType.Personal ? viewer?.id : org.id,
        personalOrgId:
          accountType === AccountType.Personal ? org.id : undefined,
        memberCount: org.memberCount,
        isEnterprise:
          subscription?.plan?.name === PlanName.Enterprise &&
          subscription?.subscriptionType !==
            OrganizationSubscriptionType.AcademicTrial &&
          subscription?.subscriptionType !==
            OrganizationSubscriptionType.Academic,
        username: viewer?.username ?? '',
      };
    });
  }, [viewerOrgsResult?.viewer?.organizations, viewer?.id, viewer?.username]);

  const viewerUserInfo = useViewerUserInfoQuery();
  const userInfo = viewerUserInfo?.data?.viewer?.userInfo;

  const accountList = useMemo(() => {
    const sortedAccounts = _.sortBy(orgAccounts, 'name');
    const shouldHidePersonalEntity = userInfo?.isPersonalEntityHidden ?? false;

    if (shouldHidePersonalEntity) {
      const orgsWithOutPersonalAccount = sortedAccounts.filter(
        a => a.accountType !== AccountType.Personal
      );
      if (orgsWithOutPersonalAccount.length > 0) {
        // Only return account list if user has Accounts other than the personal entity.
        // If user only has personal entity, and shouldHidePersonalEntity is true, then this user is orphaned (does not belong to any org).
        // UserWithNoOrgEnforcement.tsx will redirect these users to create new team page
        return orgsWithOutPersonalAccount;
      }
    }

    const dummyPersonalAccountData = {
      name: viewer?.username ?? '',
      accountType: AccountType.Personal,
      id: enableAccountSelector ? viewer?.id : undefined,
      username: viewer?.username ?? '',
    };

    // users get personal type organization once they pay for storage plan
    const personalAccount = sortedAccounts.find(
      account => account?.accountType === AccountType.Personal
    );

    if (personalAccount == null) {
      // if users haven't paid for storage yet, then we need to
      // add a dummy personal account dropdown option to the top of options
      return [dummyPersonalAccountData, ...sortedAccounts];
    }

    // if user paid for personal account, move it to the front of the list
    const orgAccountList = sortedAccounts.filter(
      account => account?.accountType !== AccountType.Personal
    );
    return [personalAccount, ...orgAccountList];
  }, [
    orgAccounts,
    viewer?.username,
    viewer?.id,
    enableAccountSelector,
    userInfo,
  ]);

  return useMemo(
    () => ({
      accountList,
      isLoading:
        viewer?.username == null ||
        viewer?.id == null ||
        organizationSubscriptionQueryLoading,
    }),
    [
      accountList,
      viewer?.username,
      viewer?.id,
      organizationSubscriptionQueryLoading,
    ]
  );
}
