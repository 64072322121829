import React, {useMemo} from 'react';

import {getDefaultLegendTemplate} from '../../util/legend';
import {ErrorBoundary} from '../ErrorBoundary';
import {getLinePlotSettingsWithDefaults} from '../WorkspaceDrawer/Settings/runLinePlots/utils/getLinePlotSettings';
import {logError} from './../../services/errors/errorReporting';
import {isSingleRun as checkIfSingleRun} from './../../util/panelHelpers';
import {PanelRunsLinePlot} from './Component';
import {PanelOutliersRunsLinePlot} from './componentOutliers/ComponentOutliers';
import {runsLinePlotConfigDefaults} from './defaults';
import {PanelConfigContextProvider} from './PanelConfigContext';
import {PanelDataContextProvider} from './PanelDataContext';
import {PanelInteractionProvider} from './PanelInteractionContext';
import {PanelTimeProvider} from './PanelTimeContext';
import {PanelZoomContextProvider} from './PanelZoomContext';
import {
  RunsLinePlotContextProvider,
  useRunsLinePlotContext,
} from './RunsLinePlotContext/RunsLinePlotContext';
import {
  PanelGroupingContextProvider,
  usePanelGroupingSettings,
} from './RunsLinePlotContext/usePanelGroupingSettings';
import {RunsLinePlotPanelProps} from './types';
import {useRegexMetrics} from './useRegexMetrics';
import {getRunSets} from './utils';

const Wrapper = (props: RunsLinePlotPanelProps) => {
  const isSingleRun = checkIfSingleRun({pageQuery: props.pageQuery});

  const {isHistogram, primaryAggregation, setRenderError} =
    useRunsLinePlotContext();
  const {smoothingWeight, xAxis, xAxisMin, xAxisMax, yAxisMin, yAxisMax} =
    getLinePlotSettingsWithDefaults(props.config);

  const isFullFidelity = primaryAggregation !== 'SAMPLED' && !isHistogram;
  const {isGrouped} = usePanelGroupingSettings();
  // Notes on runsets: https://www.notion.so/wandbai/Run-set-67004423b75d4e5fbab4846ffdbc4ee9?pvs=4
  // Note: even on a single run workspace a runset is constructed
  const runSets = useMemo(() => getRunSets(props.pageQuery), [props.pageQuery]);

  const defaultLegendTemplate = getDefaultLegendTemplate({
    aggregateMetrics:
      props.config.aggregateMetrics ??
      runsLinePlotConfigDefaults.aggregateMetrics,
    isGrouped,
    isFullFidelity,
    isSmoothed: smoothingWeight != null && smoothingWeight !== 0,
    legendFields:
      props.config.legendFields ?? runsLinePlotConfigDefaults.legendFields,
    metrics: props.config.metrics ?? runsLinePlotConfigDefaults.metrics,
    singleRun: isSingleRun,
    type: props.config.plotType ?? runsLinePlotConfigDefaults.plotType,
    useRunsets: runSets != null && runSets.length > 1,
  });

  const zoomConfig = useMemo(() => {
    return {
      xAxisMin,
      xAxisMax,
      yAxisMin,
      yAxisMax,
    };
  }, [xAxisMin, xAxisMax, yAxisMin, yAxisMax]);

  useRegexMetrics(
    {
      metricRegex: props.config.metricRegex,
      metrics: props.config.metrics,
      useMetricRegex: props.config.useMetricRegex,
    },
    props.updateConfig
  );

  const errorContext = useMemo(
    () => ({
      config: props.config,
      // @ts-ignore panel settings exists but the types are wrong higher up
      panelSettings: props?.panelSettings ?? {},
      query: {
        entityName: props.query.entityName,
        projectName: props.query.projectName,
      },
    }),
    // @ts-ignore
    [props.config, props?.panelSettings, props.query]
  );

  const handleBucketingError = React.useCallback(
    (error: Error, info: React.ErrorInfo) => {
      setRenderError(true);
      logError(error, {
        // @ts-ignore can just log key value pairs to sentry
        componentStack: info.componentStack,
        panelType: 'bucketed-plot',
        ...errorContext,
      });
    },
    [errorContext, setRenderError]
  );

  return (
    <PanelDataContextProvider>
      <PanelInteractionProvider>
        <PanelTimeProvider>
          <PanelZoomContextProvider
            xAxisKey={xAxis}
            updateConfig={props.updateConfig}
            zoomConfig={zoomConfig}>
            <PanelConfigContextProvider
              config={props.config}
              data={props.data}
              defaultLegendTemplate={defaultLegendTemplate}
              isFullFidelity={isFullFidelity}
              isSingleRun={isSingleRun}>
              {isFullFidelity ? (
                <ErrorBoundary
                  onError={handleBucketingError}
                  renderError={() => <></>}>
                  <PanelOutliersRunsLinePlot {...props} />
                </ErrorBoundary>
              ) : (
                <PanelRunsLinePlot {...props} runSets={runSets} />
              )}
            </PanelConfigContextProvider>
          </PanelZoomContextProvider>
        </PanelTimeProvider>
      </PanelInteractionProvider>
    </PanelDataContextProvider>
  );
};

const WrapperWithContext = (props: RunsLinePlotPanelProps) => {
  const runSets = useMemo(() => getRunSets(props.pageQuery), [props.pageQuery]);
  const {useRunsTableGroupingInPanels} = useMemo(
    () => getLinePlotSettingsWithDefaults(props.config),
    [props.config]
  );

  return (
    <PanelGroupingContextProvider
      aggregate={props.config.aggregate ?? false}
      aggregateMetrics={props.config.aggregateMetrics ?? false}
      groupAgg={props.config.groupAgg}
      groupArea={props.config.groupArea}
      groupBy={props.config.groupBy ?? ''}
      runSets={runSets}
      useRunsTableGroupingInPanels={useRunsTableGroupingInPanels}>
      <RunsLinePlotContextProvider
        config={props.config}
        updateConfig={props.updateConfig}>
        <Wrapper {...props} />
      </RunsLinePlotContextProvider>
    </PanelGroupingContextProvider>
  );
};

export default WrapperWithContext;
