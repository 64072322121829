import React from 'react';

// The prop signature here is bad for using this as a general component, but right now it's just a one-off so I'm letting it be sloppy because it has no other use that requires a better interface
export const GradientOverlay = React.memo(
  ({position}: {position: 'top' | 'bottom'}) => {
    const styles: React.CSSProperties = React.useMemo(
      () => ({
        background:
          'linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.45) 100%)',
        left: 0,
        height: 36,
        position: 'absolute',
        right: 0,
        zIndex: 1,
        ...(position === 'top'
          ? {top: 0}
          : {bottom: 0, transform: 'rotate(180deg)'}),
      }),
      [position]
    );

    return <div style={styles} />;
  }
);
