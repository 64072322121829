import {WBIcon} from '@wandb/ui';
import React, {FC, memo, useContext} from 'react';

import {useViewerUserInfoQuery} from '../../../generated/graphql';
import {isInIframe} from '../../../setup';
import {useViewer} from '../../../state/viewer/hooks';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import AlertsManagementModal from '../../AlertsManagementModal';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useNavContext} from '../../NavContextProvider';
import {AccountSelectorContext} from './AccountSelectorContextProvider';
import InviteTeammatesButton from './InviteTeammatesButton';
import NavResources from './NavResources';
import NavSearchButton from './NavSearchButton';
import ProfileMenu from './ProfileMenu';
import * as S from './SearchNav.styles';
import {AccountType} from './types';

type NavRightProps = {
  isSignup: boolean;
};

const NavRightComp: FC<NavRightProps> = ({isSignup}) => {
  const {searchData: data} = useNavContext();
  const viewer = useViewer();
  const enableAccountSelector = useRampFlagAccountSelector();
  const {selectedAccount} = useContext(AccountSelectorContext);

  const viewerUserInfo = useViewerUserInfoQuery();
  const userInfo = viewerUserInfo?.data?.viewer?.userInfo;
  const shouldHidePersonalEntity = userInfo?.isPersonalEntityHidden ?? false;
  const isPersonalEntitySelectedAccount =
    selectedAccount !== null &&
    selectedAccount.accountType === AccountType.Personal;
  const canViewNavBarTools = !(
    shouldHidePersonalEntity && isPersonalEntitySelectedAccount
  );

  return isInIframe() ? (
    <S.OpenPageLink
      href={document.location.href.replace('jupyter=true', '')} // TODO: actually parse the query and handle other flags?
    >
      Open page <WBIcon style={{marginLeft: 5}} name="right-arrow" />
    </S.OpenPageLink>
  ) : (
    <>
      {canViewNavBarTools && (
        <>
          {enableAccountSelector &&
            selectedAccount?.accountType === AccountType.Personal && (
              <InviteTeammatesButton />
            )}
          {viewer != null && (
            <>
              {!isSignup && data != null && <NavSearchButton />}
              <AlertsManagementModal location="search nav" />
            </>
          )}
          <NavResources />
        </>
      )}
      <ProfileMenu isSignup={isSignup} />
    </>
  );
};

const NavRight = memo(NavRightComp);

export default NavRight;
