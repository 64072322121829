import {serverFlagNoNonMonotonicFallback} from './../../../config';

export function configSupportsBucketing(
  config: {
    aggregate?: boolean;
    aggregateMetrics?: boolean;
    expressions?: string[];
    groupBy?: string;
    isGrouped?: boolean;
    isMonotonic?: boolean;
    metrics?: string[];
    useMetricRegex?: boolean;
    useRunsTableGroupingInPanels?: boolean;
    xExpression?: string;
    xAxis?: string;
  },
  /**
   * This is an override which is added because non-monotonic detection is broken in V2 and forcing customers back to sampling.
   * https://weightsandbiases.slack.com/archives/G01BLMX3H61/p1728491743432009
   */
  serverFlag = serverFlagNoNonMonotonicFallback
) {
  const warnings = [];

  /**
   * When a panel comes in with previously configured grouping options we eject that back to the old RSDQ for now. Very few panels have this configuration, so we're delaying conversion of this for now.
   *
   * For how these properties work see comment in `derviedLinesConfiguration.ts` for more context
   */

  // non-monotonic x
  // _absolute_runtime is a constructed special case which we derive out of _timestamp and _runtime - as such it's not in the returned list of historykeys and so it fails the isMonotonic check when we look them up
  if (
    !config.isMonotonic &&
    config.xAxis !== '_absolute_runtime' &&
    !serverFlag()
  ) {
    warnings.push('non-monotonic x-axis');
  }

  return warnings;
}
