/**
 * When computing the _absolute_runtime for a run we need to know the start time of the run. This can be done using the first logged point on the run. At the time of logging, that point will have a _runtime value and a _timestamp value. The start time can be calculated by subtracting the _runtime from the _timestamp.
 */
export type RelativeWallPoint = {
  _timestampAvg: number;
  _timestampMin: number;
  _timestampMax: number;
  _runtimeAvg: number;
  _runtimeMin: number;
  _runtimeMax: number;
};

function calculateRunStart(runHistories: RelativeWallPoint[][]) {
  /**
   * A run can log multiple metrics, not every metric has to be logged every step, so the safest bet here is to take the first point on ANY history for any metric and compute the start of the run time from that. It's not guaranteed that we've returned a history that has the absolute earliest logged point, but it's a good enough approximation for our purposes.
   */
  return Math.min(
    ...runHistories.map(history => {
      return history[0]._timestampAvg - history[0]._runtimeAvg;
    })
  );
}

function validateRunHistory(
  runHistory: any[][]
): runHistory is RelativeWallPoint[][] {
  try {
    const firstPoint = runHistory[0][0];
    return (
      firstPoint._timestampAvg !== undefined &&
      firstPoint._runtimeAvg !== undefined
    );
  } catch (e) {
    return false;
  }
}

export function calculateStartTime(xAxis: string, runHistory: any[][]) {
  let startTime = 0;
  const isValidRunHistory = validateRunHistory(runHistory);
  if (xAxis === '_absolute_runtime' && isValidRunHistory) {
    try {
      startTime = calculateRunStart(runHistory);
    } catch (e) {
      console.warn('Unable to calculate start time of run');
    }
  }
  return startTime;
}
