import {WBIcon} from '@wandb/ui';
import React, {FC, memo, MouseEvent} from 'react';
import {useHistory} from 'react-router-dom';

const NavLeftInIframeComp: FC = () => {
  const history = useHistory();
  return (
    <>
      <h1>Weights &amp; Biases</h1>
      <WBIcon
        className="nav"
        title="Go backward"
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          history.goBack();
        }}
        useNewIconComponent
        name="chevron-left"
      />
      <WBIcon
        className="nav"
        title="Go forward"
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          history.goForward();
        }}
        useNewIconComponent
        name="chevron-right"
      />
    </>
  );
};

const NavLeftInIframe = memo(NavLeftInIframeComp);

export default NavLeftInIframe;
