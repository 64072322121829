export function padToLength(str: string, length: number, padStr = ' '): string {
  let padded = str;
  while (padded.length < length) {
    padded = padStr + padded;
  }
  return padded;
}

export function capitalizeFirstLetter(str: string): string {
  return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : '';
}

export function cleanTabName(tab: string): string {
  return capitalizeFirstLetter(tab.replaceAll('_', ' '));
}

/**
 * Split a string into an array of strings, with a maximum number of splits.
 * This is different than the second parameter to JavaScript's string.split
 * which limits the number of splits but excludes items after the limit.
 */
export function splitWithMaxSplits(
  str: string,
  separator: string,
  maxSplits: number
): string[] {
  const parts = str.split(separator);
  if (parts.length <= maxSplits + 1) {
    return parts;
  }
  const slicedParts = parts.slice(0, maxSplits);
  const remainder = parts.slice(maxSplits).join(separator);
  return [...slicedParts, remainder];
}

export function isInvalidString(s: string, regex: RegExp) {
  return !regex.test(s);
}
