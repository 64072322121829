import * as Panel2 from '@wandb/weave/components/Panel2/panel';
import {unEscapeDots} from '@wandb/weave/core';
import _ from 'lodash';
import React from 'react';

import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
import type {PanelSpec, PanelWithConfig} from '../../util/panelTypes';
import {Query} from '../../util/queryTypes';
import {panelViews} from '../PanelBank/types';
import {WeaveConfig} from './common';
export type {WeaveConfig};
export {weavePanelForWeaveKey} from './common';

export function getKeyOfWeavePanel(
  panel: PanelWithConfig<typeof panelViews.weave>
) {
  if (panel.config.defaultWorkspaceState != null) {
    if (
      _.isEqual(
        panel.config.defaultWorkspaceState.panel2Config,
        panel.config.panel2Config
      )
    ) {
      return panel.config.defaultWorkspaceState.key;
    }
  }
  // Legacy below:
  const expNodeFromOp = panel.config?.panel2Config?.exp?.fromOp;
  let res: string | null = null;
  if (expNodeFromOp?.name === 'pick') {
    const val: string | null = expNodeFromOp?.inputs?.key?.val;
    const expKey = val == null ? val : unEscapeDots(val);
    const expRunSummary = expNodeFromOp?.inputs?.obj?.fromOp;
    if (expRunSummary?.name === 'run-summary') {
      if (expRunSummary?.inputs?.run?.nodeType === 'var') {
        res = expKey ?? null;
      }
    }
  } else if (expNodeFromOp?.name === Panel2.panelIdToPanelOpName('merge')) {
    // This branch will be useful in the case we uncomment the above injection.
    // After automatically doing .merge
    res = expNodeFromOp?.inputs?.input?.fromOp?.inputs?.key?.val ?? null;
  }
  return res;
}

function transformQuery(query: Query, config: WeaveConfig): RunsDataQuery {
  // We always disable this query, and instead use our own internal RunsDataLoader.
  // We need to do this because the query we want to run actually depends on another
  // query (the vegaPanelQuery which asks for user configured views from the views
  // table), which depends on the current panel configuration.
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
}

export const Spec: PanelSpec<typeof panelViews.weave, WeaveConfig> = {
  type: panelViews.weave,
  noEditMode: true,
  Component: React.lazy(() => import('./Component')),
  transformQuery,
  getTitleFromConfig: config =>
    `Weave: ${
      config.defaultWorkspaceState?.key || (config as any).key || '(empty)'
    }`,
};
