import {get} from 'lodash';
import React, {isValidElement, ReactElement, ReactNode} from 'react';

import config from '../../config';
import {ActiveExperiment} from '../../generated/graphql';
import {captureError} from '../../integrations';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {VARIANT_COMPONENT_DISPLAY_NAME, VariantProps} from './Variant';

const handleExperimentMisconfiguration = (
  errorMsg: string,
  experimentId: string,
  activeExperiment?: ActiveExperiment
): void => {
  if (
    config.ENVIRONMENT_NAME === 'production' ||
    config.ENVIRONMENT_IS_PRIVATE
  ) {
    captureError(errorMsg, `VariantWrapper - experiment configuration`, {
      extra:
        activeExperiment == null
          ? {experimentId}
          : {
              experimentId: activeExperiment.id,
              experimentName: activeExperiment.name,
              experimentVariants: activeExperiment.activeVariants,
            },
    });
    return;
  }

  throw new Error(errorMsg);
};

export const validateChildrenAndStoreBuckets = (
  experimentId: string,
  children: ReactNode
): Set<number> => {
  let hasControlProp = false;

  // Get a mapping of variant component bucket values for quick access
  const variantComponentBuckets = new Set<number>();

  for (const child of React.Children.toArray(children)) {
    if (!isValidElement(child)) {
      handleExperimentMisconfiguration(
        getInvalidReactChildrenMsg(experimentId),
        experimentId
      );
      continue;
    }

    if (
      get(child, 'type.displayName', '' as string) !==
      VARIANT_COMPONENT_DISPLAY_NAME
    ) {
      handleExperimentMisconfiguration(
        geOnlyVariantChildrenMsg(experimentId),
        experimentId
      );
    }

    const bucket: number = child.props.bucket;
    if (variantComponentBuckets.has(bucket)) {
      handleExperimentMisconfiguration(
        geDuplicateBucketMsg(experimentId, bucket),
        experimentId
      );
    }

    if (child.props.control) {
      if (hasControlProp) {
        // More than one variant component has the control prop
        handleExperimentMisconfiguration(
          getControlRequiredMsg(experimentId),
          experimentId
        );
      }
      hasControlProp = true;
    }

    variantComponentBuckets.add(bucket);
  }

  if (!hasControlProp) {
    // No variant component has the control prop
    handleExperimentMisconfiguration(
      getControlRequiredMsg(experimentId),
      experimentId
    );
  }

  return variantComponentBuckets;
};

type VariantReactElement = ReactElement<VariantProps>;

export const getControlBucketValue = (
  experimentId: string,
  children: JSX.Element[]
): number => {
  const controlChild = React.Children.toArray(children).find(
    child => (child as VariantReactElement).props.control
  );
  const controlBucketValue: number | undefined = (
    controlChild as VariantReactElement
  )?.props.bucket;

  if (controlBucketValue === undefined) {
    // Capture error and continue to assign control bucket value to 0
    handleExperimentMisconfiguration(
      getInvalidControlBucketValueMsg(experimentId),
      experimentId
    );
    return 0;
  }

  return controlBucketValue;
};

// Report errors and use control bucket values if the admin UI's experiment config is
// mismatched from the experiment config that the code is expecting.
export const validateExperimentVariants = (
  activeExperiment: ActiveExperiment,
  variantComponentBuckets: Set<number>
) => {
  const {id: experimentId, activeVariants: experimentVariants} =
    activeExperiment;

  // This indicates that the test is misconfigured
  if (variantComponentBuckets.size !== experimentVariants.length) {
    handleExperimentMisconfiguration(
      getVariantMismatchMsg(experimentId),
      experimentId,
      activeExperiment
    );
  }

  // Make sure all buckets are defined
  for (const variant of experimentVariants) {
    if (!variantComponentBuckets.has(variant.bucket)) {
      handleExperimentMisconfiguration(
        getMissingVariantMsg(experimentId, variant.bucket),
        experimentId,
        activeExperiment
      );
    }
  }
};

const getInvalidReactChildrenMsg = (experimentId: string) =>
  `${experimentId} requires all children to be valid React elements.`;

const geOnlyVariantChildrenMsg = (experimentId: string) =>
  `${experimentId} requires all children to be Variant components.`;

const getControlRequiredMsg = (experimentId: string) =>
  `${experimentId} requires one control to be specified.`;

const geDuplicateBucketMsg = (experimentId: string, bucket: number) =>
  bucket === 0
    ? `${experimentId} can only have 1 control specified`
    : `${experimentId} cannot have duplicate bucket values of ${bucket}`;

const getInvalidControlBucketValueMsg = (experimentId: string) =>
  `${experimentId} has an invalid control bucket value, so defaulting to 0`;

const getVariantMismatchMsg = (experimentId: string) =>
  `${experimentId} requires the number of Variant components to match the number or experiment variants.`;

const getMissingVariantMsg = (experimentId: string, bucket: number) =>
  `${experimentId} requires a Variant child component with a bucket value of ${bucket}`;
