import {Key} from '../runTypes';
import {aggregateLines} from './aggregateLines';
import {BucketSpec} from './bucket';
import {Line} from './types';

export function convertLinesToArea(
  lines: Line[],
  {
    buckets,
    expressionKeys,
    useMedian,
    xToSum,
  }: {
    buckets: BucketSpec | null;
    expressionKeys: Key[];
    useMedian: boolean;
    xToSum: {[key: number]: number};
  }
) {
  return lines
    .filter(line => !line.aux)
    .map(line => {
      const aggLine = aggregateLines({
        lines: [line],
        bucketSpec: buckets,
        name: '',
        aggregateCalculations: {
          minmax: false,
          samples: false,
          stddev: false,
          stderr: false,
        },
        useMedian,
        extraVars: expressionKeys,
      }).meanLine;

      line.type = 'area';
      line.data = aggLine.data.map(point => {
        const newPoint = {
          ...point,
          y0: xToSum[point.x] ?? 0,
          y: point.y + (xToSum[point.x] ?? 0),
          legendData: {
            y: point.y,
            total: point.y + (xToSum[point.x] ?? 0),
          },
        };
        xToSum[point.x] = point.y + (xToSum[point.x] ?? 0);
        return newPoint;
      });
      return line;
    });
}

export function convertAreaToPercentArea(
  lines: Line[],
  xToSum: {[key: number]: number}
) {
  return lines.map(line => {
    line.data = line.data.map(({x, y, y0, legendData, ...rest}) => {
      const sum = xToSum[x];
      const yValue = Number(legendData?.y) ?? 0;
      const newY = y / sum;

      const newPoint = {
        ...rest,
        x,
        y0: (y0 ?? 0) / sum,
        y: newY,
        legendData: {
          y: yValue,
          percent: (yValue / sum) * 100,
          total: newY,
        },
      };
      return newPoint;
    });
    return line;
  });
}
