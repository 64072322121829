import {Select} from '@wandb/weave/components/Form/Select';
import React from 'react';
import {twMerge} from 'tailwind-merge';

import {ShowMinMaxOnHover} from '../types';

export const showMinMaxOnHoverOptions: Record<
  ShowMinMaxOnHover,
  {label: string; value: ShowMinMaxOnHover}
> = {
  [ShowMinMaxOnHover.NEVER]: {label: 'Never', value: ShowMinMaxOnHover.NEVER},
  [ShowMinMaxOnHover.ON_HOVER]: {
    label: 'On hover',
    value: ShowMinMaxOnHover.ON_HOVER,
  },
  [ShowMinMaxOnHover.ALWAYS]: {
    label: 'Always',
    value: ShowMinMaxOnHover.ALWAYS,
  },
};

export type ShowMinMaxOnHoverSelectProps = {
  className?: string;
  onChange: (
    value:
      | (typeof showMinMaxOnHoverOptions)[keyof typeof showMinMaxOnHoverOptions]
      | null
  ) => void;
  value: ShowMinMaxOnHover;
};

export const ShowMinMaxOnHoverSelect = ({
  className,
  onChange,
  value,
}: ShowMinMaxOnHoverSelectProps) => {
  return (
    <div className={twMerge('flex', className)}>
      <div
        className={twMerge(
          'flex flex-col',
          'border-l-2 border-moon-350',
          'ml-10 mr-14'
        )}
      />
      <div className={twMerge('flex items-center')}>
        <label
          className="text-sm text-moon-500"
          htmlFor="min-max-on-hover-switch">
          Show min/max values as a shaded area
        </label>
        <Select<
          (typeof showMinMaxOnHoverOptions)[keyof typeof showMinMaxOnHoverOptions]
        >
          className="ml-8 min-w-[120px]"
          options={Object.values(showMinMaxOnHoverOptions)}
          onChange={onChange}
          // this is a string version of the value options
          value={showMinMaxOnHoverOptions[value]}
        />
      </div>
    </div>
  );
};
