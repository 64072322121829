// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import * as QueryEditorDeleteButtonStyles from './QueryEditorDeleteButton.styles';

// export const Wrapper = styled.div`display: `;

export const ItemsWrapper = styled.div<{expanded?: boolean}>`
  ${props =>
    props.expanded
      ? css`
          padding-left: 16px;
          border-left: 1px solid ${props.theme.indenter};
        `
      : css`
          display: inline;
        `}
`;

export const ItemWrapper = styled.div<{expanded?: boolean}>`
  display: inline-block;
  position: relative;
  ${props =>
    props.expanded
      ? css`
          margin-left: -24px;
          padding-left: 24px;
          & > ${QueryEditorDeleteButtonStyles.Wrapper} {
            position: absolute;
            left: -2px;
            top: 3px;
            opacity: 0;
            transition: opacity 0.2s;
            pointer-events: none;
          }
          &:hover {
            & > ${QueryEditorDeleteButtonStyles.Wrapper} {
              /* transform: translateY(16px); */
              opacity: 1;
              pointer-events: auto;
            }
          }
        `
      : css`
          ${QueryEditorDeleteButtonStyles.Wrapper} {
            position: absolute;
            left: calc(50% - 10px);
            top: -20px;
            opacity: 0;
            transition: opacity 0.2s;
            pointer-events: none;
          }
          &:hover {
            padding-top: 16px;
            margin-top: -16px;
            ${QueryEditorDeleteButtonStyles.Wrapper} {
              top: -4px;
              /* transform: translateY(16px); */
              opacity: 1;
              pointer-events: auto;
            }
          }
        `}
`;
