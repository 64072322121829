export enum ReportEventNames {
  CreateReportStarted = 'Create Report Started',
  ReportWidthOptionClicked = 'Report Width Option Clicked',
  ReportTipsSidebarClicked = 'Report Tips Sidebar Clicked',
  ReportGrammarlyNoticeDisplayed = 'Report Grammarly Notice Displayed',
  ReportSubmitted = 'Report Submitted',
  ReportPageVisited = 'Report Page Visited',
  ReportPageRedirected = 'Report Page Redirected',
  EditReportStartedWithImportPanelOpen = 'Edit Report Started With Import Panel Open',
  ReportPageError = 'Report Page Error',

  ReportCloneClicked = 'Report Clone Clicked',
  ReportDownloadClicked = 'Report Download Clicked',
  ReportDiscardDraftClicked = 'Report Discard Draft Clicked',
  ReportDraftWarningModalAction = 'Report Draft Warning Modal Action',
  ReportSetPreviewImageSaved = 'Report Set Preivew Image Saved',
  ReportViewLikesClicked = 'Report View Likes Clicked',
  ReportHeartClicked = 'Report Heart Clicked',
  ReportNotificationsToggled = 'Report Notifications Toggled',
}

const REPORT_TYPES = [
  'gallery post edit',
  'report draft',
  'published report',
] as const;
export type ReportType = (typeof REPORT_TYPES)[number];

const REPORT_REDIRECT_PATH = ['no-access', 'reportlist', 'fc'] as const;
export type ReportRedirectPath = (typeof REPORT_REDIRECT_PATH)[number];

const REPORT_MODE = ['view', 'edit'] as const;
export type ReportMode = (typeof REPORT_MODE)[number];

const REPORT_DOWNLOAD_OPTION = ['pdf', 'latex'] as const;
export type ReportDownloadOption = (typeof REPORT_DOWNLOAD_OPTION)[number];

const REPORT_DOWNLOAD_MODE = [
  // pdf modes
  'portrait',
  'landscape',
  // latex modes
  'default template',
  'reproducibility challenge template',
] as const;
export type ReportDownloadMode = (typeof REPORT_DOWNLOAD_MODE)[number];

const REPORT_CLONE_LOCATIONS = ['report table', 'report page'] as const;
export type ReportCloneLocation = (typeof REPORT_CLONE_LOCATIONS)[number];

const REPORT_DRAFT_MODAL_ACTION = ['continue', 'discard'] as const;
export type ReportDraftModalAction = (typeof REPORT_DRAFT_MODAL_ACTION)[number];

const REPORT_HEART_ACTION = ['unheart', 'heart'] as const;
export type ReportHeartAction = (typeof REPORT_HEART_ACTION)[number];

const REPORT_NOTIFICATIONS_ACTION = ['unsubscribe', 'subscribe'] as const;
export type ReportNotificationsAction =
  (typeof REPORT_NOTIFICATIONS_ACTION)[number];
