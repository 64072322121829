import {getWorkspaceLayoutSettingWithDefaults} from '../../components/WorkspaceDrawer/Settings/workspaceLayout/getWorkspaceLayoutSettings';
import {RUN_VIEW_TYPE} from '../../pages/Run/types';
import {isAutoPanel} from '../../util/isAutoPanel';
import {PANEL_BANK_HIDDEN_SECTION_NAME} from '../../util/panelbankTypes';
import {getKey} from '../../util/panels';
import {compressFlowConfig} from '../../util/sectionFlowConfig';
import {REPORT_DRAFT_VIEW_TYPE} from '../reports/types';
import {REPORT_VIEW_TYPE} from './report/types';
import type {
  MultiRunWorkspaceViewType,
  RunWorkspaceViewType,
  ViewType,
  WholeFromType,
} from './types';

/**
 * Removes default/auto data from view spec so that the smallest,
 * most compact version of the spec can be persisted. Fields should
 * only be compressed if the app is set up to rehydrate that data
 * when the spec is loaded again.
 *
 * A smaller spec means faster read/write over the network, but may
 * result in additional processing time when rehydrating the data.
 * Be mindful of this tradeoff when compressing any fields.
 */
export function compressView<T extends ViewType>(
  spec: WholeFromType<T>,
  type: T
) {
  // report views don't get compressed
  if (type === REPORT_VIEW_TYPE || type === REPORT_DRAFT_VIEW_TYPE) {
    return spec;
  }

  if (type === RUN_VIEW_TYPE) {
    // Typescript is being uncooperative. If `type` of type `T` equals 'run-view',
    // then `spec` of type `WholeFromType<T>` should be `WholeFromType<'run-view'>`.
    // TS is not grokking that for some reason, so we're forced to type cast here.
    return compressRunWorkspace(spec as WholeFromType<RunWorkspaceViewType>);
  }

  return compressMultiRunWorkspace(
    spec as WholeFromType<MultiRunWorkspaceViewType>
  );
}

function compressRunWorkspace(spec: WholeFromType<RunWorkspaceViewType>) {
  const {panelBankConfig, workspaceSettings} = spec;
  return {
    ...spec,
    panelBankConfig: compressPanelBankConfig(
      panelBankConfig,
      workspaceSettings
    ),
  };
}

function compressMultiRunWorkspace(
  spec: WholeFromType<MultiRunWorkspaceViewType>
) {
  const {panelBankConfig, workspaceSettings} = spec.section;
  return {
    ...spec,
    section: {
      ...spec.section,
      panelBankConfig: compressPanelBankConfig(
        panelBankConfig,
        workspaceSettings
      ),
    },
  };
}

function compressPanelBankConfig(
  panelBankConfig: WholeFromType<'panel-bank-config'>,
  workspaceSettings?: WholeFromType<'workspace-settings'>
) {
  return {
    ...panelBankConfig,
    sections: panelBankConfig.sections.map(section =>
      compressPanelBankSectionConfig(section, workspaceSettings)
    ),
  };
}

function compressPanelBankSectionConfig(
  panelBankSectionConfig: WholeFromType<'panel-bank-section-config'>,
  workspaceSettings?: WholeFromType<'workspace-settings'>
) {
  const {name, flowConfig} = panelBankSectionConfig;
  return {
    ...panelBankSectionConfig,
    flowConfig: compressFlowConfig(name, flowConfig),
    panels: isSectionPanelsAuto(panelBankSectionConfig, workspaceSettings)
      ? []
      : panelBankSectionConfig.panels,
  };
}

/**
 * Determine whether it's safe to drop all panels in a section
 * and let them be regenerated at load time
 */
export function isSectionPanelsAuto(
  panelBankSectionConfig: WholeFromType<'panel-bank-section-config'>,
  workspaceSettings?: WholeFromType<'workspace-settings'>
): boolean {
  const {name, isPanelsAuto, panels} = panelBankSectionConfig;

  // If section is explicitly marked with isPanelsAuto flag, use that
  if (typeof isPanelsAuto === 'boolean') {
    return isPanelsAuto;
  }

  // Hidden section should never be compressed
  if (name === PANEL_BANK_HIDDEN_SECTION_NAME) {
    return false;
  }

  // If all panels in section are auto, it *might* be safe to compress.
  // It's still possible that some panels were deleted, in which case
  // we must ensure those don't get added back. We can only do this if
  // panel auto gen is enabled, since deleted panels will be tracked in
  // the Hidden Panels section and won't be added again.

  const {shouldAutoGeneratePanels, autoOrganizePrefix} =
    getWorkspaceLayoutSettingWithDefaults(workspaceSettings ?? {});

  if (!shouldAutoGeneratePanels) {
    return false;
  }

  return panels.every(panel => {
    return isAutoPanel(
      panel,
      name,
      getKey(panel),
      undefined,
      autoOrganizePrefix
    );
  });
}
