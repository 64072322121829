import './polyfills';
import './integrations';
import './globalStyleImports';

import {ConnectedRouter} from 'connected-react-router';
import React from 'react';
import {ApolloProvider} from 'react-apollo';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {enableBatching} from 'redux-batched-actions';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Loader} from 'semantic-ui-react';

import {apolloClient} from './apolloClient';
import config, {envIsIntegration} from './config';
import installServiceWorker from './installServiceWorker';
import {AppWithRoutes} from './routes/AppWithRoutes';
import {apolloClient2, debugCollector, history, store} from './setup';
import {Apollo2Context} from './util/apollo2-hooks';

if (config.ENVIRONMENT_IS_PRIVATE) {
  // this obfuscates the original log call locations, so only use it
  // in local, when Sentry et all aren't available
  debugCollector.instrument();
}

installServiceWorker();

async function fetchOverridesWithRetry(retries = 5): Promise<Response> {
  try {
    // eslint-disable-next-line wandb/no-unprefixed-urls
    const resp = await fetch(
      `${config.LOCAL_TEST_FIXTURE_SERVER_HOST}/app-config-override`,
      {
        method: 'GET',
      }
    );

    if (resp.ok) {
      return resp;
    }

    throw new Error(`Failed to fetch config overrides: ${resp.status}`);
  } catch (e) {
    if (retries > 0) {
      return fetchOverridesWithRetry(retries - 1);
    }
    throw e;
  }
}

const ConfigOverrideProvider: React.FC = props => {
  const [configOverridesLoading, setConfigOverridesLoading] =
    React.useState<boolean>(
      envIsIntegration && !!config.LOCAL_TEST_FIXTURE_SERVER_HOST
    );
  React.useEffect(() => {
    // This is used by integration tests to override config values
    if (configOverridesLoading) {
      // eslint-disable-next-line wandb/no-unprefixed-urls
      fetchOverridesWithRetry().then(async response => {
        const overrides = await response.json();
        Object.assign(config, overrides);
        setConfigOverridesLoading(false);
      });
    }
  }, [configOverridesLoading, setConfigOverridesLoading]);

  if (configOverridesLoading) {
    return <Loader name="config-override-loader" />;
  }

  return <>{props.children}</>;
};

const render = async (Component: React.FC) => {
  ReactDOM.render(
    <ConfigOverrideProvider>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <Apollo2Context.Provider value={apolloClient2}>
            <ConnectedRouter history={history}>
              <Component />
            </ConnectedRouter>
          </Apollo2Context.Provider>
        </ApolloProvider>
      </Provider>
    </ConfigOverrideProvider>,
    document.getElementById('root')
  );
};

render(AppWithRoutes);

const hot = import.meta.hot;
if (hot) {
  hot.accept('./reducers', updatedCreateRootReducer => {
    store.replaceReducer(enableBatching(updatedCreateRootReducer(history)));
  });
  hot.accept('./routes', async ({default: NextApp}) => {
    render(NextApp);
  });
}
