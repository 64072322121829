import {notEmpty} from '@wandb/weave/common/util/obj';
import * as _ from 'lodash';
import {omit} from 'lodash';

import {
  getOrganizedSettings,
  SUPPORTED_WORKSPACE_SPEC_VERSION,
} from '../components/WorkspaceDrawer/Settings/utils';
import {EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT} from './panelbankConfigs';
import * as Panels from './panels';
import {
  migratePanelBankSettingsToPanelSettings,
  migrateWorkspaceToPanelBank,
  panelBankSectionFromJSON,
  runSetConfigFromJSON,
} from './parseSpecUtils';
import * as Section from './section';
const DEFAULT_SECTION_NAME = '';

/**
 * This function will parse and return a well formed object for
 * view types 'group-view' | 'project-view' | 'sweep-view'.
 */
export function parsedWorkspaceSpec(
  spec: any,
  viewType: 'group-view' | 'project-view' | 'sweep-view'
) {
  return Object.assign({}, spec, {
    section: parsedWorkspaceSpecSection(spec.section, viewType),
  });
}

function parsedWorkspaceSpecSection(
  json: any,
  viewType: 'group-view' | 'project-view' | 'sweep-view'
): Section.Config | null {
  // WARNING!!: If you change how this works it will reset some user-defined run colors.
  // See comment in runSetConfigFromJSON.

  const sectionName = json.name || DEFAULT_SECTION_NAME;
  const runSets = _.isArray(json.runSets)
    ? json.runSets
        .map((rs: any, i: number) =>
          runSetConfigFromJSON(DEFAULT_SECTION_NAME, rs, i)
        )
        .filter(notEmpty)
    : undefined;
  const runSetCount = runSets != null ? runSets.length : 0;
  const panels =
    json.panels != null ? Panels.configFromJSON(json.panels) : null; // old spec (for 'Custom Visualizations' + pinnable panels)
  const panelBankConfig = migrateWorkspaceToPanelBank(
    viewType,
    json.panelBankConfig,
    panels
  );
  const panelBankSectionConfig = panelBankSectionFromJSON(
    json.panelBankSectionConfig || EMPTY_PANEL_BANK_SECTION_CONFIG_FOR_REPORT,
    viewType
  );

  const {panelBankSettings, panelSettings} =
    migratePanelBankSettingsToPanelSettings(
      panelBankConfig.settings,
      json.settings
    );
  panelBankConfig.settings = panelBankSettings;

  const currWorkspaceSettings = omit(json?.workspaceSettings, 'ref');
  const workspaceSettings =
    Object.keys(currWorkspaceSettings).length === 0
      ? getOrganizedSettings({
          ...panelBankSettings,
          ...json.settings,
        })
      : currWorkspaceSettings;

  const specVersion = json?.version ?? SUPPORTED_WORKSPACE_SPEC_VERSION;

  return {
    name: sectionName,
    version: specVersion,
    // configFromJSON is Panels|null, this coerces nulls to undefined
    panels: panels != null ? panels : undefined,
    workspaceSettings,
    panelBankConfig, // current spec (for PanelBank)
    panelBankSectionConfig,
    runSets,
    openRunSet:
      json.openRunSet != null && json.openRunSet < runSetCount
        ? json.openRunSet
        : undefined,
    openViz: json.openViz == null ? true : json.openViz,
    settings: panelSettings,
    customRunColors: json.customRunColors || {},
  };
}
