import {
  useEntityMemberRoleQuery,
  useProjectMemberQuery,
} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {useAdminModeActive} from './admin';

export enum EntityProjectPermissions {
  DeleteProject = 'project:delete',
  RenameProject = 'project:rename',
  UpdateProject = 'project:update',
}

export function useViewerHasPermissionForEntityProject(
  projectName: string,
  entityName: string,
  permission: string
) {
  const viewer = useViewer();
  const isAdminModeActive = useAdminModeActive();

  const {data: projectData, loading: projectLoading} = useProjectMemberQuery({
    variables: {
      entityName,
      projectName,
      memberId: viewer?.id ?? '',
    },
    skip: !projectName || !entityName || !viewer,
  });

  const {data: entityData, loading: entityLoading} = useEntityMemberRoleQuery({
    variables: {entityName, memberId: viewer?.id ?? ''},
    skip: !viewer || !entityName,
    fetchPolicy: 'cache-and-network',
  });

  if (isAdminModeActive) {
    return {loading: false, hasPermission: true};
  }

  const projectMember = projectData?.project?.member;
  const projectMemberRole = projectMember?.role;
  if (projectMemberRole) {
    // use viewer's project role if it exists
    const inheritedPermissions =
      projectMemberRole?.inheritedFrom?.permissions ?? [];
    const permissions = inheritedPermissions.concat(
      projectMemberRole?.permissions ?? []
    );

    const hasPermission = permissions.some(p => p.name === permission);

    return {
      loading: projectLoading,
      hasPermission,
    };
  } else {
    // if project role for the viewer does not exist, use entity role
    const role = entityData?.entity?.member?.memberRole;
    const inheritedPermissions = role?.inheritedFrom?.permissions ?? [];
    const permissions = inheritedPermissions.concat(role?.permissions ?? []);

    const hasPermission = permissions.some(p => p.name === permission) ?? false;

    return {
      loading: entityLoading,
      hasPermission,
    };
  }
}
