import {Table} from '@wandb/weave/common/util/csv';
import * as React from 'react';

import {RunsDataQuery} from '../containers/RunsDataLoader';
import type {PanelProps} from '../util/panelProps';
import type {LayedOutPanel, PanelSpec} from '../util/panelTypes';
import {Query} from '../util/queryTypes';
import * as RunFeed from '../util/runfeed';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import Export from './Export/Export';
import {
  captureUniqueError,
  getMaybeGroupedRunName,
  runsToTable,
} from './Export/utils';
import {useCsvExportRuns} from './RunSelector/hooks/useCsvExportRuns';
import {RunSelectorRunsPartialVars} from './RunSelector/types';

interface TableExportProps {
  pageQuery: Query;
  tableSettings: RunFeed.Config;
  trigger?: JSX.Element;
  open?: boolean;
  onClose?(): void;
}

const TableExport = (props: TableExportProps) => {
  return (
    <Export
      pageQuery={props.pageQuery}
      trigger={props.trigger}
      open={props.open}
      onClose={props.onClose}
      panel={
        {
          __id__: 'dummyPanel',
          viewType: 'Run Selector',
          config: {columnAccessors: props.tableSettings.columnOrder},
        } as LayedOutPanel
      }
    />
  );
};

export default TableExport;

const useTableData = (
  pageQuery: RunSelectorRunsPartialVars,
  config: PanelRunSelectorConfig
) => {
  const {isLoading, runs} = useCsvExportRuns(pageQuery);
  let table: Table = {cols: [], data: []};
  try {
    table = runsToTable(runs, config.columnAccessors, r =>
      getMaybeGroupedRunName(r, pageQuery.grouping?.[0])
    );
  } catch (e) {
    captureUniqueError(e);
  }

  return {table, loading: isLoading};
};

interface PanelRunSelectorConfig {
  columnAccessors: string[];
}

const RunSelectorPanel: React.FC<PanelProps<PanelRunSelectorConfig>> = () => {
  return null;
};

export const Spec: PanelSpec<
  'Run Selector',
  PanelRunSelectorConfig,
  RunSelectorRunsPartialVars
> = {
  type: 'Run Selector',
  Component: RunSelectorPanel as any,
  useTableData,
  transformQuery: () => ({} as RunsDataQuery),
};
