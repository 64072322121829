import * as _ from 'lodash';

import {RunHistoryRow} from '../../types/run';
import {makeHistogram} from './../histogram2';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {Line} from './types';

export function isHistoryArray(history: RunHistoryRow[], lineName: string) {
  return (
    history &&
    history[0] &&
    history[0][lineName] &&
    Array.isArray(history[0][lineName])
  );
}

export function isHistoryHistogram(history: RunHistoryRow[], lineName: string) {
  return (
    history &&
    history[0] &&
    history[0][lineName] &&
    history[0][lineName]._type === 'histogram'
  );
}
export function histogramFromHistory(
  history: RunHistoryRow[],
  xAxis: string,
  lineName: string
) {
  const line = {data: [] as Line['data'], title: ''} as Exclude<Line, 'meta'>;
  const validRows = history.filter(row => row[lineName] != null);
  /* Handle the case where history is an array or histogram */
  const min: number =
    _.min(
      validRows.map(row => {
        if (row[lineName]._type && row[lineName]._type === 'histogram') {
          return row[lineName].bins[0];
        }
        return _.min(row[lineName]);
      })
    ) || 0;
  const max: number =
    _.max(
      validRows.map(row => {
        if (row[lineName]._type && row[lineName]._type === 'histogram') {
          return _.last(row[lineName].bins);
        }
        return _.max(row[lineName]);
      })
    ) || 0;
  const numBuckets = 32;

  const visitedX = new Set<number>();
  let j = 0;
  for (const row of validRows) {
    // TODO: we were checking __index / _step here, seems unnecessary
    const x = (row[xAxis] as number) ?? j;
    // if same x exists in mulptiple rows with diff step values, show the first row
    if (visitedX.has(x)) {
      continue;
    }
    visitedX.add(x);
    const values = row[lineName];
    const {counts, binEdges} = makeHistogram(values, numBuckets, min, max);
    counts.forEach((count, k) => {
      if (binEdges[k] !== null && !_.isNaN(binEdges[k])) {
        const y: number = binEdges[k];
        line.data.push({
          x,
          y,
          color: count,
        });
      }
    });
    j++;
  }
  line.type = 'heatmap';
  return line;
}
