import {Slider} from '@wandb/weave/components';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';

export const SettingSlider = ({
  className,
  name,
  min,
  max,
  step,
  sliderValue,
  updateSetting,
}: {
  className?: string;
  name: string;
  min: number;
  max: number;
  step: number;
  sliderValue: number;
  updateSetting: (newVal: number) => void;
}) => {
  const [currValue, setCurrValue] = useState(sliderValue);
  useEffect(() => {
    setCurrValue(sliderValue);
  }, [sliderValue]);

  const debouncedUpdateSetting = debounce(updateSetting, 300);

  return (
    <div
      className={twMerge(
        'flex items-center justify-end',
        'grid grid-cols-[1fr_auto]',
        className
      )}>
      <Slider.Root
        name={name}
        min={min}
        max={max}
        step={step}
        orientation="horizontal"
        onValueChange={([newVal]) => {
          // If use is dragging slider, don't update config continuosly
          setCurrValue(newVal);
        }}
        onValueCommit={([newVal]) => {
          // Once user lets go of slider, we'll update config
          debouncedUpdateSetting(newVal);
        }}
        value={[currValue]}>
        <Slider.Track>
          <Slider.Range className="grow" />
        </Slider.Track>
        <Slider.Thumb />
      </Slider.Root>
      <Slider.Display
        isDirty={true}
        min={min}
        max={max}
        step={step}
        onChange={debouncedUpdateSetting}
        value={currValue}
      />
    </div>
  );
};
