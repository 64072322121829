import * as React from 'react';

const hardCodedSize: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
};
/**
 * The boundary of the plot. Used to stack plot layers internally
 */
export const PlotBoundary = ({children}: {children: React.ReactNode}) => (
  <div key="plot-boundary" style={hardCodedSize}>
    {children}
  </div>
);

/**
 * A positioned layer inside the plot boundary. Layers stack with the first one on bottom, but can be adjusted with z-indexing
 */
export const PositionedLayer = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    dim?: number;
    debugHide?: boolean; // used to hide layers while debugging
    zIndex?: number;
  }
>(function PositionedLayer({children, dim, debugHide, zIndex}, ref) {
  const styles: React.CSSProperties = React.useMemo(
    () => ({
      bottom: 0,
      left: 0,
      opacity: dim || 1, // cannot dim to 0
      position: 'absolute',
      right: 0,
      top: 0,
      visibility: debugHide === true ? 'hidden' : 'visible',
      zIndex: zIndex ?? 'auto',
    }),
    [debugHide, zIndex, dim]
  );

  return (
    <div key="positioned-layer" style={styles} ref={ref}>
      {children}
    </div>
  );
});
