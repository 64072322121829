import {makeTrackEvent} from './makeTrackEvent';

export type PanelControlsAction =
  | 'Add to report clicked'
  | 'Edit panel clicked'
  | 'Panel deleted'
  | 'Panel duplicated'
  | 'Panel exported'
  | 'Panel moved to another section'
  | 'Share panel clicked'
  | 'View full screen clicked';

export type PanelControlsEvent = {
  action: PanelControlsAction;
  page: 'report' | 'workspace';
  exportType?: 'csv' | 'pdf' | 'png' | 'svg';
  isNewlyCreatedSection?: boolean;
  sharedPanelReportId?: string;
};

export const trackPanelControls = makeTrackEvent<
  PanelControlsEvent,
  {
    action: {
      description: 'what action took place';
    };
    page: {
      description: 'what page where the panel is displayed';
    };
    exportType: {
      description: 'for "Panel exported" action, which export type was selected';
    };
    isNewlyCreatedSection: {
      description: 'for "Panel moved to another section" action, whether the section was newly created as part of the move';
    };
    sharedPanelReportId: {
      description: 'ID of the report that was created during "Share panel" action';
    };
    _description: 'Tracks panel actions';
    _location: 'workspaces, reports';
    _motivation: 'track UI usage';
  }
>('Panel actions');
