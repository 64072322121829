import {capitalizeFirst} from '@wandb/weave/common/util/string';
import {Button} from '@wandb/weave/components';
// eslint-disable-next-line wandb/no-deprecated-imports
import {format} from 'date-fns';
import React, {memo, useMemo} from 'react';
import {CellInfo} from 'react-table';

import WBReactTable from '../../../../components/WBReactTable';
import {
  StripeInvoiceStatus,
  useOrganizationSubscriptionInfoQuery,
} from '../../../../generated/graphql';
import {Analytics} from '../../../../services/analytics';
import * as S from './InvoicesTable.styles';

const InvoicesUnpaidTableComp = ({
  orgID,
  isACHCustomer = false,
}: {
  orgID: string;
  isACHCustomer?: boolean;
}) => {
  const organizationQuery = useOrganizationSubscriptionInfoQuery({
    variables: {organizationId: orgID},
  });

  const organization = organizationQuery.data?.organization;

  const unpaidStripeInvoices = organization?.stripeInvoices
    ?.filter(invoice => invoice.status !== StripeInvoiceStatus.Paid)
    ?.sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    );

  const moreThanOnePendingInvoice =
    unpaidStripeInvoices && unpaidStripeInvoices.length > 1;

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Date',
        accessor: 'date',
        minWidth: 80,
        Cell: (cellInfo: CellInfo) => {
          const date = cellInfo.value;
          try {
            const formatted = format(new Date(date), 'MM/dd/yyyy');
            return (
              <div style={{display: 'flex', justifyContent: 'left'}}>
                {formatted}
              </div>
            );
          } catch (e) {
            console.error(e);
            return null;
          }
        },
      },
      {
        Header: 'Plan',
        accessor: 'planNames',
        minWidth: 60,
        Cell: (cellInfo: CellInfo) => {
          const planNames = cellInfo.value;
          const displayPlanNames = planNames.join(', ');
          return (
            <div style={{display: 'flex', justifyContent: 'left'}}>
              {displayPlanNames}
            </div>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        minWidth: 80,
        Cell: (cellInfo: CellInfo) => {
          const {total, currency} = cellInfo.value;
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
          });

          return (
            <div style={{display: 'flex', justifyContent: 'left'}}>
              {formatter.format(total)} {currency.toUpperCase()}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 80,
        Cell: (cellInfo: CellInfo) => {
          const status = cellInfo.value;
          return (
            <div style={{display: 'flex', justifyContent: 'left'}}>
              <S.InvoiceStatusTag status={status}>
                {capitalizeFirst(status)}
              </S.InvoiceStatusTag>
            </div>
          );
        },
      },
      {
        Header: 'Download',
        accessor: 'actions',
        minWidth: 80,
        Cell: (cellInfo: CellInfo) => {
          const {invoicePDF, organizationName} = cellInfo.value;

          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <a
                href={invoicePDF}
                onClick={() => {
                  window.analytics?.track('Download Invoice Button Clicked', {
                    location: 'invoices table',
                    organizationName,
                  });
                }}
                download>
                <S.ActionIcon name="download-new" />
              </a>
            </div>
          );
        },
      },
    ];

    const actionColumn = {
      Header: 'Pay',
      accessor: 'pay',
      minWidth: 80,
      sortable: false,
      Cell: (cellInfo: CellInfo) => {
        const {hostedInvoiceURL, organizationName} = cellInfo.value;

        return (
          <div style={{display: 'flex', justifyContent: 'left'}}>
            <Button
              variant="primary"
              className="hover:text-moon-600"
              onClick={() => {
                Analytics.track('Add Payment Method Clicked', {
                  location: 'payment failed warning banner',
                  organizationName,
                });
                // eslint-disable-next-line wandb/no-unprefixed-urls
                window.location.href = hostedInvoiceURL;
              }}>
              Pay
            </Button>
          </div>
        );
      },
    };

    return isACHCustomer ? [...baseColumns, actionColumn] : baseColumns;
  }, [isACHCustomer]);

  const rows =
    unpaidStripeInvoices?.map(invoice => {
      const {
        number: invoiceNumber,
        created,
        total,
        status,
        currency,
        invoicePDF,
        hostedInvoiceURL,
        subscription,
      } = invoice;

      if (isACHCustomer) {
        return {
          searchString: '',
          row: {
            date: created,
            planNames:
              subscription?.organizationSubscriptions.map(
                organizationSubscription =>
                  organizationSubscription.plan.displayName
              ) ?? [],
            amount: {total, currency},
            status: status ?? '',
            actions: {
              invoicePDF,
              organizationName: organization?.name,
            },
            pay: {
              hostedInvoiceURL,
              organizationName: organization?.name,
            },
          },
        };
      } else {
        return {
          searchString: '',
          row: {
            date: created,
            planNames:
              subscription?.organizationSubscriptions.map(
                organizationSubscription =>
                  organizationSubscription.plan.displayName
              ) ?? [],
            amount: {total, currency},
            status: status ?? '',
            actions: {
              invoicePDF,
              organizationName: organization?.name,
            },
          },
        };
      }
    }) ?? [];

  if (organizationQuery.loading) {
    return <S.LoadingInvoicesTable />;
  }

  if (
    organization?.stripeInvoices == null ||
    organization?.stripeInvoices.length === 0
  ) {
    return <span>No invoices on file.</span>;
  }

  return (
    <div style={{color: 'black', width: '100%'}}>
      {!isACHCustomer && (
        <S.Header>
          <S.Title>
            Failed {moreThanOnePendingInvoice ? 'Payments' : 'Payment'} to be
            retried
          </S.Title>
        </S.Header>
      )}
      <WBReactTable
        dataTest="unpaid-invoices-table"
        data={rows}
        columns={columns}
        noSearch={true}
        noHeader={true}
      />
    </div>
  );
};

export const InvoicesUnpaidTable = memo(InvoicesUnpaidTableComp);
