import React, {PropsWithChildren, ReactElement} from 'react';

// This is copied straight from React.FC but excludes the additional properties like displayName.
// We need to isolate the function call signature because otherwise TypeScript higher-order type inference breaks.
// See https://github.com/microsoft/TypeScript/pull/30215#issue-258109340
// Specifically, this is one of the requirements for it to work:
// "the called function is a generic function that returns a function type with a single call signature"
type FC<P = {}> = (
  props: PropsWithChildren<P>,
  context?: any
) => ReactElement<any, any> | null;

type PropsAreEqual<T> = (
  prevProps: Readonly<PropsWithChildren<T>>,
  nextProps: Readonly<PropsWithChildren<T>>
) => boolean;

interface MakeCompOpts<T> {
  displayName: string;
  memo?: true | PropsAreEqual<T>;
  disablePofiler?: boolean;
}

export function makeComp<T>(
  Comp: FC<T>,
  {displayName, memo}: MakeCompOpts<T>
): FC<T> {
  // note that displayName does _not_ need to be re-applied after being wrapped in memo/Profiler
  (Comp as React.FC<T>).displayName = displayName;

  if (memo) {
    Comp = React.memo(
      Comp,
      // if the user passed in true, this will tell React.memo to use the default propsAreEqual,
      // but if they passed in a function, it will use that.
      memo !== true ? memo : undefined
    ) as unknown as typeof Comp;
  }
  return Comp;
}

export default makeComp;
