import Color from 'color';
import * as React from 'react';
import {ColorResult, SketchPicker} from 'react-color';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Icon, Popup} from 'semantic-ui-react';

import {COLORS16, getColorName} from '../util/colors';

export interface ColorPickerProps {
  activeColor: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  setRunColor?: (color: string) => void;
  readOnly?: boolean; // don't open on click
}

const ColorPicker = React.memo((props: ColorPickerProps) => {
  const {activeColor, style, onClick, setRunColor, readOnly} = props;

  const handleChangeComplete = (color: ColorResult) => {
    const {a, ...colorMinusAlpha} = color.rgb;
    const alpha = a || 0.8;
    const updatedColor = Color(colorMinusAlpha).alpha(alpha).string();
    if (setRunColor != null) {
      setRunColor(updatedColor);
    }
  };

  const effectiveReadOnly = readOnly ?? false;

  return (
    <Popup
      openOnTriggerClick={!effectiveReadOnly}
      trigger={
        <Icon
          style={{
            ...style,
            opacity: 1,
            color: activeColor,
            cursor: readOnly ? 'default' : 'pointer',
          }}
          name="circle"
          title={getColorName(activeColor) || activeColor}
          onClick={onClick}
        />
      }
      content={
        <SketchPicker
          color={activeColor}
          presetColors={COLORS16}
          onChangeComplete={handleChangeComplete}
        />
      }
      // needs higher zindex than body .ui.dimmer in Base.less
      style={{padding: 0, left: '-15px'}}
      on="click"
    />
  );
});

export default ColorPicker;
