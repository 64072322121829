import gql from 'graphql-tag';

export const EOTF_ORGANIZATION_SUBSCRIPTION_BASIC_INFO = gql`
  query EOTFOrganizationSubscriptionsBasicInfo($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      name
      subscriptions {
        id
        expiresAt
        plan {
          id
          name
          planType
        }
      }
    }
  }
`;
