export type ExperimentData = {
  graphqlExperimentId: string;
  treatment: number;
  log: boolean;
  experimentName: string;
  observationalUnitId: string;
  hash: number;
};

export type ExperimentQueryParams = {
  experimentIdParam: string;
  experimentBucketParam: string;
};

export const DEFAULT_EXPERIMENT_DATA: ExperimentData = {
  graphqlExperimentId: '',
  treatment: 0,
  log: false,
  experimentName: '',
  observationalUnitId: '',
  hash: 0,
};

export type ExperimentConfig = {
  /** The /admin/experiments graphqlId */
  experimentGraphqlId: string;
  /** which bucket should be treated as the control? */
  controlBucketValue: number;
  /** The set of buckets that the code is expecting for this experiment */
  bucketValues: Set<number>;
};
