import {OperationVariables, QueryHookOptions} from 'react-apollo';

import {propagateErrorsContext} from '../../util/errors';
import {usePollInterval} from '../polling/hooks';
import {useQuery as useApolloQuery} from './apolloWithAbort';

// Our version of useQuery. It returns a loading state that can
// be used in a type-guard to ensure we get non-null data values
// when loading is false.

export type UseQueryOptions<D = any, V = OperationVariables> = Omit<
  QueryHookOptions<D, V>,
  'pollInterval'
> & {
  enablePolling?: boolean;
  pollMultiplier?: number;
};

export function useQuery<D, V>(document: any, options: UseQueryOptions<D, V>) {
  const globalPollInterval = usePollInterval();

  let pollInterval = options.enablePolling
    ? globalPollInterval *
      (options.pollMultiplier != null ? options.pollMultiplier : 1)
    : 0;
  if (pollInterval === 0) {
    pollInterval = 365 * 24 * 60 * 60000;
  }

  const {loading, data, refetch, networkStatus, fetchMore, error} =
    useApolloQuery<D, V>(document, {
      ...options,
      context: {...propagateErrorsContext(), ...options.context},
      pollInterval,
    });

  return {
    loading,
    data,
    refetch,
    networkStatus,
    fetchMore,
    error,
    initialLoading: Boolean(networkStatus === 1 || options.skip),
  };
}
