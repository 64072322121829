import {useCombobox} from 'downshift';
import {DocumentNode} from 'graphql';
import * as React from 'react';
import {useEffect} from 'react';
import {useLazyQuery} from 'react-apollo';
import {FixedSizeList as List} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {useInfiniteItems, useInfinitePageDetails} from './InfiniteHooks';
import {ExtractPageInfo} from './types';
import {useInfiniteResults} from './useInfiniteResults';

type InfiniteDropdownProps = {
  Components: {
    Container: React.ComponentType<any>;
    Display: React.ComponentType<any>;
    ListItem: React.ComponentType<any>;
    ItemLabel: React.ComponentType<any>;
    ListWrapper: React.ComponentType<any>;
    Input: React.ComponentType<any>;
  };
  /**
   * The config properties allow for some independence from the returned shape of data from the query. The `extract-` functions are provided so the InfiniteDropdown can resolve the paginated items and the pagination info without being coupled to any one query
   *
   * There is a hard requirement that the query be paginated though
   */
  config: {
    extractItems: (data: any) => string[];
    extractPageInfo: ExtractPageInfo;
    query: DocumentNode;
    triggerExternally?: boolean;
  };
  forceExactMatch?: boolean;
  entityName: string;
  projectName: string;
  extraQueryVariables?: any;
  skip?: boolean;
  isMultiSelect: boolean;
  hookConfig?: {
    useQueryHook?: typeof useLazyQuery;
  };
  selections: string[];
  toggleSelection: (newSelection: string) => void;
};

/**
 * The Infinite Dropdown desires to do the following:
 * 1. handle paginated endpoints for HUGE lists by automatically fetching the next batch of items as a user scrolls toward the bottom
 * 2. allow filtering of items by providing a `pattern` to the endpoint
 * 3. virtualize the list so that a user scroll-loading many items doesn't experience render drag
 * 4. Use Downshift to make this as standards-compliant as possible
 *
 * Some notes on the design here:
 * - I tried to avoid coupling the core behavior from the display behavior. This is why there's a `Components` prop that takes in the elements that are to be rendered
 * - The syndication of data between React Virtual's List and Downshift gets hairy. The reason the `useInfiniteItems` hook returns functions mainly is to try to clarify the order of operations when mutating data and feeding it into each component's internal state
 * - this is also why the underlying query is lazy: this gives control of new fetches to the libraries instead of automatically fetching based on updated variables
 * - there's no debouncing currently: this is because of unsolved issues using the loadMore function in both the infinite loader and in the search input. The endpoints for OpenAI seem to be sufficiently fast
 *
 * WARNINGS: There are couplings between this componenent and its first use (tag filters). These will be uncovered and refactored out as we expand the use of this component.
 * UPDATE: the couplings got worse as this component grew to support single and multi-value filters. See note below.
 */

export const InfiniteDropdown = ({
  Components,
  config,
  entityName,
  projectName,
  extraQueryVariables = {},
  forceExactMatch = false,
  skip = false,
  isMultiSelect,
  selections,
  hookConfig,
  toggleSelection,
}: InfiniteDropdownProps) => {
  const {addListItems, clearListItems, isItemLoaded, listItems} =
    useInfiniteItems();
  const {pageDetails, updatePageDetails} = useInfinitePageDetails();
  const [searchString, setSearchString] = React.useState<string>('');
  const {fetchMore, isLoading} = useInfiniteResults(
    {
      entityName,
      projectName,
      addListItems,
      extractItems: config.extractItems,
      extractPageInfo: config.extractPageInfo,
      query: config.query,
      extraQueryVariables,
      updatePageDetails,
    },
    {
      useQueryHook: hookConfig?.useQueryHook ?? useLazyQuery,
    }
  );

  /**
   * Infinite loading behavior is mostly controlled here. It has to be separated to handle 2 things:
   * 1. if a user types in the input we need to loadMore values based on the updated state of that input
   * 2. the `loadMore` function goes into react-window-infinite-loader to trigger based on scroll
   *
   * NOTE: I wanted to debounce the loadMore() on just searchString changes but that has proven very, very difficult. Testing this live w/ GPT-4 (huge tag list) maintained acceptable performance so I elected to skip the debounce for now
   */
  const loadMore = React.useCallback(() => {
    if (skip) {
      return;
    }
    fetchMore({
      variables: {
        first: 30,
        pattern: searchString,
        valuePattern: searchString,
        after: pageDetails.endCursor,
        ...extraQueryVariables,
      },
    });
    // too many things here will cause this to fire more than we want
    // eslint-disable-next-line
  }, [pageDetails.endCursor, searchString, extraQueryVariables, skip]);

  React.useEffect(() => {
    loadMore();
    // too many things here will cause this to fire more than we want
    // eslint-disable-next-line
  }, [searchString, skip]);

  /**
   * This gets a little nasty because supporting single and multi-selection patterns for Downshift have different patterns in the docs. However, the Rules of Hooks keep me from dynamically changing the number of the hooks I call within a component, and it seemed easier to beat on this config than to make two infinite loading tag components and dynamically switch them out.
   *
   * Some notes:
   * 1. The `isOpen` is in a weird state where it's mostly controlled by Downshift with some escape hatches. I'm overwriting it with `stateReducer` so that I can dynamically change the auto close behavior based on single vs multiselects. Single selects close when you select a new value (because you can only do one at a time) but multi-selects force the `isOpen` prop to remain `true` so that you can select or deselect multiple values in one action.
   * 2. The other annoying thing about this is that Downshift is built to tightly couple the selected items state with the input state. Because I want to decouple these two things (so that you can search dynamically for tags by typing in strings and not have that trash your selection state) I'm doing a gross thing with a `ref` where I prevent the input value from changing under certain conditions as the default library UX is different from what I built. In idiomatic downshift, selecting an item from the list removes it from the list and places it into the display. I am not doing that because I want a user to be able to select/unselect out of the list (so you can do it easily with keyboard controls and not have to move focus all around). So I leave the items in the list but then change the styles to show that they're selected. But Downshift won't fire events if you (a) select and already selected item and (b) don't allow it to become the input value for filtering the list. That's why I have to imperatively fire `toggleSelection` down there in the reducer. Gross! But as an old boss said, "nobody plays your code".
   */

  const isSelecting = React.useRef({active: false, target: ''});
  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    toggleMenu,
    openMenu,
  } = useCombobox({
    items: listItems,
    onInputValueChange: ({inputValue: iVal}) => {
      if (!isSelecting.current.active) {
        clearListItems();
        updatePageDetails(true, '');
        setSearchString(iVal ?? '');
      } else {
        isSelecting.current.active = false;
      }
    },
    inputValue: searchString,
    // we have to listen to the onStateChange because dispatching the toggle fn
    // from inside the stateReducer itself violates the rules of hooks
    onStateChange: changes => {
      switch (changes.type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          if (isLoading) {
            return;
          }

          if (!changes.selectedItem && searchString) {
            const foundItem = listItems.find(e => e.includes(searchString));
            if (foundItem && forceExactMatch) {
              toggleSelection(foundItem);
            } else {
              toggleSelection(searchString);
            }
          } else {
            toggleSelection(changes.selectedItem || changes.inputValue || '');
          }
          return;
        }
        case useCombobox.stateChangeTypes.ItemClick: {
          toggleSelection(changes.selectedItem || changes.inputValue || '');
        }
      }
    },
    stateReducer: (_, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          // this ref is to force firing in `onInputValueChange`
          // see explanation above
          isSelecting.current = {
            active: true,
            target: actionAndChanges.changes.selectedItem ?? '',
          };

          return {
            ...actionAndChanges.changes,
            isOpen: Boolean(config.triggerExternally || isMultiSelect),
          };
        }
      }
      return actionAndChanges.changes;
    },
  });

  useEffect(() => {
    if (config.triggerExternally) {
      openMenu();
    }
  }, [config.triggerExternally, openMenu]);

  /**
   * This should probably be refactored out for coupling concerns but this pattern is from the docs and I'm not wanting to do the work to pull it out until I have a different use case to support
   */
  const Item = ({index, style}: {index: number; style: any}) => {
    const itemProps = getItemProps({
      index,
      item: listItems[index],
    });

    const curItem = listItems[index];
    const isSelected = selections.includes(curItem);
    /** This is one particularly egregious spot of coupling but I'll deal with this later */
    return (
      <Components.ListItem
        {...itemProps}
        style={style}
        isHighlighted={highlightedIndex === index}
        isSelected={isSelected}>
        <Components.ItemLabel
          isSelected={isSelected}
          label={isItemLoaded(index) ? curItem : 'Loading'}
        />
      </Components.ListItem>
    );
  };

  const itemCount = pageDetails.hasNextPage
    ? listItems.length + 1
    : listItems.length;

  return (
    <Components.Container>
      <Components.Display
        toggleMenu={toggleMenu}
        selectedItems={selections}
        isMultiSelect={isMultiSelect}
        removeFilter={(selItem: string) => {
          toggleSelection(selItem);
        }}
        isOpen={isOpen}
      />
      <Components.ListWrapper {...getMenuProps()} isOpen={isOpen}>
        <Components.Input
          {...getInputProps()}
          toggleSelection={toggleSelection}
        />
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMore}>
          {({onItemsRendered, ref}) => (
            <>
              <List
                height={Math.min(listItems.length * 44, 200)}
                itemCount={itemCount}
                itemSize={44}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width="auto">
                {Item}
              </List>
              {isLoading && (
                <li className="leading-18 item m-0 px-12 py-[13px]">
                  <div className="flex w-full justify-between">
                    <span className="text-base text-moon-500">Loading...</span>
                  </div>
                </li>
              )}
              {!itemCount && !isLoading && (
                <li className="leading-18 item m-0 px-12 py-[13px]">
                  <div className="flex w-full justify-between">
                    <span className="text-base text-moon-500">No Results</span>
                  </div>
                </li>
              )}
            </>
          )}
        </InfiniteLoader>
      </Components.ListWrapper>
    </Components.Container>
  );
};

/**
 * This exists so that we can create Infinite Dropdowns bound to a specific scope
 * This decouples the core logic from the Query, the display components
 *
 * UPDATE: the coupling here is much nastier than it used to be because of the need to support single and multi-value variants of the tags filter. In the interests of time I've not gone crazy trying to abstract all the couplings to the custom tag filter out from this component. In the future (when we extend this) we will need to:
 * a) spend some time fully uncoupling this thing so we can use
 * b) making a new version that supports a less extreme use case (if new uses are less complicated)
 */
export const makeInfiniteDropdown =
  (
    Components: InfiniteDropdownProps['Components'],
    config: InfiniteDropdownProps['config']
  ) =>
  (props: Omit<InfiniteDropdownProps, 'Components' | 'config'>) => {
    return (
      <InfiniteDropdown Components={Components} config={config} {...props} />
    );
  };
