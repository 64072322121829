import {isSettingActive} from './utils';

/**
 * Recursively determine which setting should be used.
 * @param settings is an array of values ordered by lowest to highest precedence
 * @param defaultVal is treated the same as if setting is not set. it has low precedence
 *
 * Example: ['_step', '_runtime', '_step'] => returns _runtime
 */
export const getCascadingSetting = (settings: any[], defaultVal?: any): any => {
  const settingsLen = settings?.length ?? 0;
  if (settingsLen === 0) {
    return defaultVal;
  }

  if (settingsLen === 1) {
    return settings ?? defaultVal;
  }

  const parentSetting = settings[0];
  const childSetting = settings[1];
  const cascadingValue = isSettingActive(childSetting, defaultVal)
    ? childSetting
    : parentSetting;

  const nextSettings = settings.slice(2);
  if (nextSettings.length === 0) {
    return cascadingValue ?? defaultVal;
  }

  return getCascadingSetting([cascadingValue].concat(nextSettings), defaultVal);
};
