import {Key} from '../runTypes';
import {RunSetInfo} from './types';

export function getGroupKeys(
  panelGroupKeys: Key[],
  useRunsTableGroupingInPanels: boolean,
  runSetInfo?: RunSetInfo
) {
  // Grouping can come from the panel in panelGroupKeys or from the runset
  // Runsets can have grouping set in reports or in the runs table.
  if (useRunsTableGroupingInPanels) {
    return panelGroupKeys.length > 0
      ? panelGroupKeys
      : runSetInfo?.grouping ?? [];
  } else {
    return panelGroupKeys.length > 0 ? panelGroupKeys : [];
  }
}
