import {difference} from '@wandb/weave/common/util/data';
import {shallowEqual} from '@wandb/weave/common/util/obj';
import produce from 'immer';
import _ from 'lodash';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

export * from './useOnInView';

export type DebounceStateResult<T> = [
  liveState: T,
  debounceState: T,
  setLiveState: (newVal: T) => void,
  setLiveAndDebounceState: (newVal: T) => void
];

// Returns [liveState, debounceState, setState, setLiveAndDebounceState]
export function useDebounceState<T>(
  initial: T,
  delay: number
): DebounceStateResult<T> {
  const [liveState, setLiveState] = useState(initial);
  const [debounceState, setDebounceState] = useState(initial);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceState(liveState);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [liveState, delay]);

  const setLiveAndDebounceState = useCallback((newVal: T) => {
    setLiveState(newVal);
    setDebounceState(newVal);
  }, []);

  return [liveState, debounceState, setLiveState, setLiveAndDebounceState];
}

export function useShallowEqualValue<TValue extends object>(v: TValue): TValue {
  const lastValue = useRef(v);

  if (v !== lastValue.current && !shallowEqual(v, lastValue.current)) {
    lastValue.current = v;
  }
  return lastValue.current;
}

export function useDeepEqualValue<TValue>(v: TValue): TValue {
  const lastValue = useRef(v);

  if (v !== lastValue.current && !_.isEqual(v, lastValue.current)) {
    lastValue.current = v;
  }
  return lastValue.current;
}

export function useTimer(fn: () => void, delay: number) {
  useEffect(() => {
    setTimeout(fn, delay);
    // eslint-disable-next-line
  }, []);
}

export function useBoundingClientRect(
  elRef: MutableRefObject<Element | null>,
  skip: boolean = false
) {
  const [, forceRender] = useState({});
  const rectRef = useRef<DOMRect | null>(
    elRef.current?.getBoundingClientRect() ?? null
  );
  const skipRef = useRef(skip);
  skipRef.current = skip;

  useLayoutEffect(() => {
    if (skip) {
      return;
    }
    const updateRect = () => {
      if (skipRef.current) {
        return;
      }

      if (elRef.current == null) {
        if (rectRef.current != null) {
          rectRef.current = null;
          forceRender({});
        }
        return;
      }

      const oldRect = rectRef.current;
      const newRect = elRef.current.getBoundingClientRect();

      if (
        oldRect == null ||
        oldRect.x !== newRect.x ||
        oldRect.y !== newRect.y ||
        oldRect.width !== newRect.width ||
        oldRect.height !== newRect.height
      ) {
        rectRef.current = newRect;
        forceRender({});
      }
    };
    updateRect();
    // there is no good way to detect element position shifts for arbitrary reasons.
    // a 50ms interval for this cheap function should be fine.
    // still, for performance concerns, we should ensure that skip === true whenever we don't need the update.
    const intervalID = setInterval(updateRect, 50);
    return () => clearInterval(intervalID);
    // eslint-disable-next-line
  }, [skip]);

  return rectRef.current;
}

export function usePoll(f: () => Promise<any>, interval: number) {
  if (interval === 0) {
    interval = 365 * 24 * 60 * 60000;
  }

  const mountedRef = useRef(true);
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  useEffect(() => {
    return () => {
      mountedRef.current = false;
      // if there is a running timer on unmount, remove it
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, [mountedRef, timerRef]);

  const [pollCount, setPollCount] = useState(0);
  const pollCountRef = useRef(pollCount);
  pollCountRef.current = pollCount;

  useEffect(() => {
    f().then(() => {
      // don't schedule after unmount
      if (!mountedRef.current) {
        return;
      }
      // don't schedule if something else triggered a re-poll
      if (pollCount !== pollCountRef.current) {
        return;
      }

      // clear any other lingering timeouts
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => setPollCount(pc => pc + 1), interval);
    });
  }, [
    f,
    interval,
    pollCount,
    setPollCount,
    pollCountRef,
    mountedRef,
    timerRef,
  ]);
}

export function useLogIfChanged(
  identifier: string,
  o: any,
  equalityFn = (a: any, b: any) => a === b
): boolean {
  const ref = useRef(o);
  const changed = !equalityFn(ref.current, o);
  if (changed) {
    console.group(`[useLogIfChanged]: ${identifier} changed`);
    console.log('prev', ref.current);
    console.log('next', o);
    console.log('diff', difference(ref.current, o));
    console.groupEnd();
  }
  ref.current = o;
  return changed;
}

export function useScrollPosition() {
  const [pos, setPos] = useState(0);
  useEffect(() => {
    // TODO: This probably needs to be debounced for performance.
    document.addEventListener('scroll', e => {
      setPos(document.scrollingElement?.scrollTop ?? 0);
    });
    // TODO: This needs to clean up the event listener on unmount!
  }, []);
  return pos;
}

export function useForceRender(): () => void {
  const [, setFakeState] = useState(false);
  const forceRender = useCallback(() => setFakeState(prev => !prev), []);
  return forceRender;
}

export type KeysHandler = {
  keys: string | string[];
  handler: () => void;
};

export function useKeyPressHandler(keysHandlers: KeysHandler[]) {
  const [keysPressed, setKeysPressed] = useState<string[]>([]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      keysHandlers.forEach(({keys, handler}) => {
        const singleHotKeyPressed = typeof keys === 'string' && e.key === keys;
        const newKeysPressed = [...keysPressed, e.key];
        const multiHotKeysPressed =
          typeof keys === 'object' &&
          keys.length === newKeysPressed.length &&
          keys.every((key, index) => key === newKeysPressed[index]);
        if (singleHotKeyPressed || multiHotKeysPressed) {
          handler();
          // reset the KeysPressed so it doesn't include the old keys pressed
          // even the handler fn closes the component
          setKeysPressed([]);
          return;
        }

        setKeysPressed(prev =>
          produce(prev, draft => {
            if (!draft.includes(e.key)) {
              draft.push(e.key);
            }
          })
        );
      });
    },
    [keysPressed, keysHandlers]
  );

  const onKeyUp = useCallback((e: React.KeyboardEvent) => {
    setKeysPressed(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(e.key, 0);
        if (index > -1) {
          draft.splice(index, 1);
        }
      })
    );
  }, []);

  return {onKeyDown, onKeyUp};
}

export function useEffectOnce(fn: () => void, condition: boolean): void {
  const triggeredRef = useRef(false);
  useEffect(() => {
    if (condition && !triggeredRef.current) {
      fn();
      triggeredRef.current = true;
    }
    // eslint-disable-next-line
  }, [condition]);
}

type ScrollIntoView<T> = {
  ref: MutableRefObject<T | null>;
  trigger: () => void;
};

export function useScrollIntoView<T extends Element>(
  offsetY = 0
): ScrollIntoView<T> {
  const ref = useRef<T | null>(null);

  const trigger = useCallback(() => {
    const reportsHeaderEl = ref.current;
    if (reportsHeaderEl == null) {
      return;
    }

    const scrollToY = Math.max(
      0,
      reportsHeaderEl.getBoundingClientRect().top + window.scrollY + offsetY
    );
    window.scrollTo({
      top: scrollToY,
      behavior: `smooth`,
    });
  }, [offsetY]);

  return {ref, trigger};
}

export function useIsMounted() {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return useCallback(() => isMountedRef.current, []);
}

export function useStaleValueWhileLoading<T>(value: T, loading: boolean): T {
  const oldValue = useRef(value);
  if (loading) {
    return oldValue.current;
  } else {
    oldValue.current = value;
  }
  return value;
}

export function useFirstNonEmptyValueWhileLoading<T>(
  value: T,
  loading: boolean,
  isEmpty: (value: T) => boolean = _.isEmpty
): T {
  const originalValue = useRef<T | undefined>(undefined);
  if (!loading && originalValue.current == null && !isEmpty(value)) {
    originalValue.current = value;
  }
  return originalValue.current ?? value;
}
