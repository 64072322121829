/* Puts a warning banner at the top of charts */
import classNames from 'classnames';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Popup} from 'semantic-ui-react';

type PlotWarningProps = {
  message: string;
  helpText?: string;
  disallowSelect?: boolean;
};

const PlotWarning: React.FC<PlotWarningProps> = React.memo(
  ({message, helpText, disallowSelect}) => {
    const span = (
      <span
        className={classNames('plot-warning', {
          'select-none': disallowSelect,
        })}>
        {message}
      </span>
    );

    if (helpText == null) {
      return span;
    }

    return (
      <Popup
        inverted
        size="mini"
        position="bottom center"
        content={helpText}
        flowing
        popperModifiers={{
          preventOverflow: {
            // prevent popper from erroneously constraining the popup to the
            // table header
            boundariesElement: 'viewport',
          },
        }}
        trigger={span}
      />
    );
  }
);

export default PlotWarning;
