import * as Normalize from './normalize';
import {ViewReducerState} from './reducerSupport';

export function handleUpdateViewSpec(
  state: ViewReducerState,
  viewId: string,
  newSpec: any
): ViewReducerState {
  const view = state.views[viewId];
  const viewType = view?.type;
  if (view == null || viewType == null) {
    throw new Error('invalid view');
  }

  const {ref: partRef, parts: newParts} = Normalize.addObjImmutable(
    state.parts,
    view.type,
    view.cid,
    newSpec
  );
  if (viewType !== partRef.type) {
    throw new Error('invalid action');
  }

  const newUpdatedAt = new Date();
  return {
    ...state,
    views: {
      ...state.views,
      [viewId]: {
        ...state.views[viewId],
        updatedAt: newUpdatedAt.toISOString(),
        partRef,
      },
    },
    parts: newParts,
  };
}
