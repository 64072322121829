import produce from 'immer';
import React from 'react';

import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
// eslint-disable-next-line import/no-cycle
import {useSampleAndQueryToTable} from '../../util/panelHelpers';
import type {PanelSpec} from '../../util/panelTypes';
import {Query} from '../../util/queryTypes';
import {parseExpressions} from '../PanelExpressionOptions';
import {getLinePlotSettingWithDefault} from '../WorkspaceDrawer/Settings/runLinePlots/linePlotDefaults';
import {getTitleFromConfig, runsLinePlotTransformQuery} from './common';
import {PANEL_TYPE, RunsLinePlotConfig} from './types';

// We don't want the panel infrastructure to do the query for us, since the query
// we make depends on internal state (the current zoom info). So we disable the
// outer query, and do our own query inside the panel.
const transformQuerySkip = (
  query: Query,
  config: RunsLinePlotConfig
): RunsDataQuery => {
  const result = toRunsDataQuery(query);
  result.disabled = true;
  return result;
};

const useTableData = (pageQuery: Query, config: RunsLinePlotConfig) => {
  const numRuns = getLinePlotSettingWithDefault(config.limit, 'limit');

  const query = runsLinePlotTransformQuery({
    query: pageQuery,
    runsLinePlotConfig: config,
    xStepRange: null,
    parsedExpressions: parseExpressions(config.expressions, config.xExpression),
    defaultMaxRuns: numRuns,
    isFullFidelity: false,
  });

  return useSampleAndQueryToTable(query, pageQuery, config);
};

const configSpec = {
  chartTitle: {
    editor: 'string' as const,
    displayName: 'Chart title',
  },
  smoothingWeight: {
    editor: 'slider' as const,
    displayName: 'Smoothing',
    min: 0,
    max: 0.99,
    step: 0.01,
    default: 0,
  },
  ignoreOutliers: {
    editor: 'checkbox' as const,
    displayName: 'Hide outliers',
    default: false,
  },
  xLogScale: {
    editor: 'checkbox' as const,
    displayName: 'X log scale',
    default: false,
  },
  yLogScale: {
    editor: 'checkbox' as const,
    displayName: 'Y log scale',
    default: false,
  },
  limit: {
    editor: 'dropdown' as const,
    displayName: 'Runs limit',
    default: 10,
    options: [
      {text: '1 run', value: 1},
      {text: '2 runs', value: 2},
      {text: '3 runs', value: 3},
      {text: '4 runs', value: 4},
      {text: '5 runs', value: 5},
      {text: '6 runs', value: 6},
      {text: '7 runs', value: 7},
      {text: '8 runs', value: 8},
      {text: '9 runs', value: 9},
      {text: '10 runs', value: 10},
      {text: '20 runs', value: 20},
      {text: '30 runs', value: 30},
      {text: '50 runs', value: 50},
      {text: '100 runs', value: 100},
    ],
  },
  plotType: {
    editor: 'icon-menu' as const,
    displayName: 'Plot type',
    options: [
      {icon: 'standard-line-plot', value: 'line'},
      {icon: 'area-line-plot', value: 'stacked-area'},
      {icon: 'percent-line-plot', value: 'pct-area'},
    ],
    default: 'line',
  },
};

function transformConfigUpdate(config: RunsLinePlotConfig): RunsLinePlotConfig {
  return produce(config, draft => {
    if (
      'metrics' in draft &&
      (draft.metrics == null || draft.metrics.length < 2)
    ) {
      draft.colorEachMetricDifferently = false;
    }
  });
}

export const Spec: PanelSpec<typeof PANEL_TYPE, RunsLinePlotConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./ComponentWrapper')),
  transformConfigUpdate,
  getTitleFromConfig,

  exportable: {
    image: true,
    csv: true,
    api: true,
  },

  configSpec,
  transformQuery: transformQuerySkip,
  useTableData,
  icon: 'panel-line-plot',
};
