import {Maybe} from 'graphql/jsutils/Maybe';
import {useEffect, useMemo} from 'react';
import {QueryResult} from 'react-apollo';

import {
  Exact,
  Organization,
  UserOrganizationsQuery,
} from '../generated/graphql';

export function useMatchingOrganizations(
  onNoMatches?: () => void,
  userOrganizationsQueryResult?: QueryResult<
    UserOrganizationsQuery,
    Exact<{
      emailDomain?: Maybe<string> | undefined;
    }>
  >
): Organization[] {
  useEffect(() => {
    if (
      userOrganizationsQueryResult?.data != null &&
      userOrganizationsQueryResult.loading === false
    ) {
      const hasMatchingTeams =
        userOrganizationsQueryResult.data.viewer?.openMatchingOrganizations?.some(
          ({teams}) => teams?.length
        );
      if (!hasMatchingTeams) {
        onNoMatches?.();
      }
    }
  }, [userOrganizationsQueryResult, onNoMatches]);

  const openMatchingOrganizations = useMemo(() => {
    return (
      userOrganizationsQueryResult?.data?.viewer?.openMatchingOrganizations ??
      []
    );
  }, [userOrganizationsQueryResult?.data]);
  return openMatchingOrganizations as Organization[];
}
